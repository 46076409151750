"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _getPrototypeOf = require("babel-runtime/core-js/object/get-prototype-of");

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require("babel-runtime/helpers/classCallCheck");

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require("babel-runtime/helpers/createClass");

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require("babel-runtime/helpers/possibleConstructorReturn");

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require("babel-runtime/helpers/inherits");

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _classnames = require("classnames");

var _classnames2 = _interopRequireDefault(_classnames);

var _CheckCircle = require("@material-ui/icons/CheckCircle");

var _CheckCircle2 = _interopRequireDefault(_CheckCircle);

var _Error = require("@material-ui/icons/Error");

var _Error2 = _interopRequireDefault(_Error);

var _Info = require("@material-ui/icons/Info");

var _Info2 = _interopRequireDefault(_Info);

var _green = require("@material-ui/core/colors/green");

var _green2 = _interopRequireDefault(_green);

var _amber = require("@material-ui/core/colors/amber");

var _amber2 = _interopRequireDefault(_amber);

var _Snackbar = require("@material-ui/core/Snackbar");

var _Snackbar2 = _interopRequireDefault(_Snackbar);

var _SnackbarContent = require("@material-ui/core/SnackbarContent");

var _SnackbarContent2 = _interopRequireDefault(_SnackbarContent);

var _Warning = require("@material-ui/icons/Warning");

var _Warning2 = _interopRequireDefault(_Warning);

var _styles = require("@material-ui/core/styles");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var variantIcon = {
  success: _CheckCircle2.default,
  warning: _Warning2.default,
  error: _Error2.default,
  info: _Info2.default
};

var snackStyles = function snackStyles(theme) {
  return {
    success: { backgroundColor: _green2.default[600] },
    error: { backgroundColor: theme.palette.error.dark },
    info: { backgroundColor: theme.palette.primary.dark },
    warning: { backgroundColor: _amber2.default[700] },
    icon: { fontSize: 20 },
    iconVariant: {
      opacity: 0.9,
      marginRight: theme.spacing.unit
    },
    message: {
      display: "flex",
      alignItems: "center"
    }
  };
};

function MySnackbarContent(props) {
  var classes = props.classes,
      className = props.className,
      message = props.message,
      variant = props.variant;

  var Icon = variantIcon[variant];

  return _react2.default.createElement(_SnackbarContent2.default, {
    className: (0, _classnames2.default)(classes[variant], className),
    "aria-describedby": "client-snackbar",
    message: _react2.default.createElement(
      "span",
      { id: "client-snackbar", className: classes.message },
      _react2.default.createElement(Icon, { className: (0, _classnames2.default)(classes.icon, classes.iconVariant) }),
      message
    ) });
}

MySnackbarContent.propTypes = {
  classes: _propTypes2.default.object.isRequired,
  className: _propTypes2.default.string,
  message: _propTypes2.default.node,
  variant: _propTypes2.default.oneOf(["success", "warning", "error", "info"]).isRequired
};

var MySnackbarContentWrapper = (0, _styles.withStyles)(snackStyles)(MySnackbarContent);

var styles = function styles(theme) {
  return {
    margin: {
      margin: theme.spacing.unit
    }
  };
};

var CustomSnackbar = function (_React$Component) {
  (0, _inherits3.default)(CustomSnackbar, _React$Component);

  function CustomSnackbar() {
    (0, _classCallCheck3.default)(this, CustomSnackbar);
    return (0, _possibleConstructorReturn3.default)(this, (CustomSnackbar.__proto__ || (0, _getPrototypeOf2.default)(CustomSnackbar)).apply(this, arguments));
  }

  (0, _createClass3.default)(CustomSnackbar, [{
    key: "render",
    value: function render() {

      return _react2.default.createElement(
        "div",
        null,
        _react2.default.createElement(
          _Snackbar2.default,
          {
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right"
            },
            autoHideDuration: this.props.autoHideDuration || 1850,
            onClose: this.props.handleClose,
            open: this.props.isOpen },
          _react2.default.createElement(MySnackbarContentWrapper, {
            variant: this.props.variant,
            message: this.props.messageText
          })
        )
      );
    }
  }]);
  return CustomSnackbar;
}(_react2.default.Component);

CustomSnackbar.propTypes = {
  classes: _propTypes2.default.object.isRequired
};

exports.default = (0, _styles.withStyles)(styles)(CustomSnackbar);
module.exports = exports["default"];