'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actions = undefined;
exports.default = reducer;
exports.getSubaccounts = getSubaccounts;
exports.getSubaccountList = getSubaccountList;
exports.refreshSubaccounts = refreshSubaccounts;
exports.postSubaccount = postSubaccount;
exports.getSubaccountCharge = getSubaccountCharge;
exports.addSubaccountCharge = addSubaccountCharge;
exports.putSubaccount = putSubaccount;
exports.putSubaccountDefault = putSubaccountDefault;
exports.subaccountsRefresh = subaccountsRefresh;
exports.subaccountSelect = subaccountSelect;
exports.subaccountUnselect = subaccountUnselect;
exports.subaccountUnselectAll = subaccountUnselectAll;

var _apiAction = require('../apiAction');

var _apiAction2 = _interopRequireDefault(_apiAction);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// ACTIONS


var actions = exports.actions = new _apiAction2.default('SUBACCOUNT', [_apiAction.ACTION_TYPES.DATA, _apiAction.ACTION_TYPES.SELECT, _apiAction.ACTION_TYPES.TRIM, _apiAction.ACTION_TYPES.APPEND]);

// REDUCER


var initialState = {
  loading: false,
  status: actions.APPEND,
  list: [],
  selected: []
};

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  return actions.getStateForAction(state, action);
}

// ACTION CREATORS


function getSubaccounts(params) {
  return {
    types: [actions.APPEND, actions.APPEND_SUCCESS, actions.APPEND_FAIL],
    promise: function promise(client) {
      return client.get('/reseller/subaccounts', {
        params: params
      });
    }
  };
}

function getSubaccountList(params) {
  return {
    types: [actions.APPEND, actions.APPEND_SUCCESS, actions.APPEND_FAIL],
    promise: function promise(client) {
      return client.get('/reseller/subaccounts_list', {
        params: params
      });
    }
  };
}

function refreshSubaccounts() {
  return {
    type: actions.TRIM,
    count: 0
  };
}

function postSubaccount(data) {
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.post('/reseller/subaccounts', { data: data });
    }
  };
}

function getSubaccountCharge(account_uid) {
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.get('/account/' + account_uid + '/balance/prepaid');
    }
  };
}

function addSubaccountCharge(data) {
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.post('/account/balance/prepaid', { data: data });
    }
  };
}

function putSubaccount(uid, data) {
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.put('/reseller/subaccounts/' + uid, { data: data });
    }
  };
}

function putSubaccountDefault(uid) {
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.put('/reseller/subaccounts/' + uid + '/default', { data: [] });
    }
  };
}

function subaccountsRefresh() {
  return {
    type: actions.TRIM,
    count: 0
  };
}

function subaccountSelect(index) {
  return {
    type: actions.SELECT,
    index: index
  };
}

function subaccountUnselect(index) {
  return {
    type: actions.SELECT_OFF,
    index: index
  };
}

function subaccountUnselectAll() {
  return {
    type: actions.SELECT_NONE
  };
}