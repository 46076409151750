'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _class, _class2, _temp;
// import S3Upload from 'react-s3-uploader/s3upload'


var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _reactRedux = require('react-redux');

var _variables = require('redux/modules/variables');

var _coreDecorators = require('core-decorators');

var _draftJs = require('draft-js');

var _reactDraftWysiwyg = require('react-draft-wysiwyg');

var _reactColor = require('react-color');

var _WYSIWYG = require('./WYSIWYG');

var _WYSIWYG2 = require('./WYSIWYG.scss');

var _WYSIWYG3 = _interopRequireDefault(_WYSIWYG2);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var md = {
  colorpicker: require('react-icons/lib/md/colorize')
};

var PlainText = (_dec = (0, _reactRedux.connect)(function (state) {
  return {
    variablesList: state.variables.list
  };
}, { getVariables: _variables.getVariables, variablesRefresh: _variables.variablesRefresh }), _dec(_class = (_temp = _class2 = function (_React$Component) {
  (0, _inherits3.default)(PlainText, _React$Component);

  function PlainText() {
    (0, _classCallCheck3.default)(this, PlainText);
    return (0, _possibleConstructorReturn3.default)(this, (PlainText.__proto__ || (0, _getPrototypeOf2.default)(PlainText)).apply(this, arguments));
  }

  (0, _createClass3.default)(PlainText, [{
    key: 'componentWillMount',
    value: function componentWillMount() {
      if (__CLIENT__) {
        this.props.variablesRefresh();
        this.props.getVariables();
      }
    }
  }, {
    key: 'render',
    value: function render() {
      if (__SERVER__) {
        return _react2.default.createElement('div', { key: 'plaintext', className: _WYSIWYG3.default.quill });
      }

      var variables = this.props.variablesList.map(function (v) {
        return {
          text: v.printable_value.text,
          value: v.printable_value.text + ']'
        };
      });

      var textLength = this.props.getQuestionLength();

      return _react2.default.createElement(
        'div',
        { key: 'plaintext', className: _WYSIWYG3.default.quill },
        _react2.default.createElement(
          'div',
          { className: _WYSIWYG3.default.toolbarAdvanced },
          _react2.default.createElement(
            'div',
            { className: _WYSIWYG3.default.toolsText },
            (0, _translations2.default)('TOOLS')
          )
        ),
        _react2.default.createElement(
          'div',
          { className: _WYSIWYG3.default.speech },
          _react2.default.createElement('div', { className: _WYSIWYG3.default.faketextarea, style: this.props.style }),
          _react2.default.createElement('div', { className: _WYSIWYG3.default.arrow + ' ' + _WYSIWYG3.default.bottom }),
          _react2.default.createElement(
            'div',
            { ref: this.props.optionsReference, className: _WYSIWYG3.default.options },
            this.props.children
          ),
          _react2.default.createElement(
            'div',
            { className: _WYSIWYG3.default.counter },
            textLength,
            this.props.hideCounter ? "" : "/160"
          )
        ),
        _react2.default.createElement(_reactDraftWysiwyg.Editor, {
          editorRef: this.props.editorReference,
          editorState: this.props.editorState,
          toolbarClassName: _WYSIWYG3.default.toolbar,
          wrapperClassName: _WYSIWYG3.default.wrapper,
          editorClassName: _WYSIWYG3.default.editor,
          editorStyle: this.props.style,
          placeholder: this.props.placeholder,
          onEditorStateChange: this.props.onChange,
          localization: {
            locale: 'es',
            translations: {}
          },
          toolbarCustomButtons: [_react2.default.createElement(_WYSIWYG.VariableInsert, {
            editorState: this.props.editorState,
            className: _WYSIWYG3.default.variable,
            variables: this.props.variablesList,
            onChange: this.props.onChange
          })],
          toolbar: {
            options: ['emoji'],
            list: {
              inDropdown: false,
              options: ['unordered', 'ordered'],
              unordered: {
                className: _WYSIWYG3.default.unordered
              },
              ordered: {
                className: _WYSIWYG3.default.ordered
              }
            },
            link: {
              options: ['link'],
              link: {
                className: _WYSIWYG3.default.link
              }
            },
            emoji: {
              className: _WYSIWYG3.default.emoji,
              emojis: ['^_^', 'u_u', 'x_x', '>_<', '*_*', 'T_T']
            }
          },
          mention: {
            separator: ' ',
            trigger: '[',
            suggestions: variables
          }
        })
      );
    }
  }]);
  return PlainText;
}(_react2.default.Component), _class2.propTypes = {
  variablesRefresh: _propTypes2.default.func.isRequired,
  getVariables: _propTypes2.default.func.isRequired,
  placeholder: _propTypes2.default.string,
  editorState: _propTypes2.default.object
}, _class2.defaultProps = {
  placeholder: '',
  editorState: null,
  hideCounter: false,
  children: []
}, _temp)) || _class);
exports.default = PlainText;
module.exports = exports['default'];