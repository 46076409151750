'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _extends3 = require('babel-runtime/helpers/extends');

var _extends4 = _interopRequireDefault(_extends3);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _class, _class2, _temp;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactRedux = require('react-redux');

var _reduxForm = require('redux-form');

var _reactRouter = require('react-router');

var _components = require('components');

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var md = {
  arrowBack: require('react-icons/lib/md/arrow-back')
};

var MessageScheduler = (_dec = (0, _reactRedux.connect)(function (state) {
  return {
    form: state.form.messageScheduler
  };
}, null), _dec(_class = (_temp = _class2 = function (_React$Component) {
  (0, _inherits3.default)(MessageScheduler, _React$Component);

  function MessageScheduler(props) {
    (0, _classCallCheck3.default)(this, MessageScheduler);

    var _this = (0, _possibleConstructorReturn3.default)(this, (MessageScheduler.__proto__ || (0, _getPrototypeOf2.default)(MessageScheduler)).call(this, props));

    _this.frequencies = {
      ONCE: 'ONCE',
      DAILY: 'DAILY',
      WEEKLY: 'WEEKLY',
      MONTHLY: 'MONTHLY',
      YEARLY: 'YEARLY'
    };

    _this.state = {
      frequency: _this.frequencies.DAILY,
      dates: {
        startDate: (0, _moment2.default)().format('YYYY-MM-DD'),
        finishDate: (0, _moment2.default)().format('YYYY-MM-DD'),
        hour: (0, _moment2.default)().format('HH:mm')
      }
    };
    return _this;
  }

  (0, _createClass3.default)(MessageScheduler, [{
    key: 'getDefaultValues',
    value: function getDefaultValues() {
      return {
        recurring: false,
        frequency: this.frequencies.ONCE,
        finish: false
      };
    }
  }, {
    key: 'showOnField',
    value: function showOnField(field) {
      var value = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

      return field === value ? { 'opacity': 1 } : { display: 'none', 'opacity': 0 };
    }
  }, {
    key: 'handleDateChange',
    value: function handleDateChange(whichDate, date) {
      var _extends2;

      this.setState((0, _extends4.default)({}, this.state, {
        dates: (0, _extends4.default)({}, this.state.dates, (_extends2 = {}, (0, _defineProperty3.default)(_extends2, whichDate, date.format('YYYY-MM-DD')), (0, _defineProperty3.default)(_extends2, 'hour', whichDate == "startDate" ? date.format('HH:mm') : this.state.dates.hour), _extends2))
      }));
    }
  }, {
    key: 'handleRecurringChange',
    value: function handleRecurringChange(event, value) {}
  }, {
    key: 'handleSubmit',
    value: function handleSubmit(data) {
      if (data.recurring && !data.frequency) {
        data.frequency = this.state.frequency;
      }
      if (this.state.dates.startDate) {
        this.props.onSubmit((0, _extends4.default)({}, this.getDefaultValues(), data, this.state.dates));
      } else {
        console.error('No start date defined');
      }
    }
  }, {
    key: 'render',
    value: function render() {
      var styles = require('./Message.scss');
      var handleSubmit = this.props.handleSubmit;

      var values = this.props.form && this.props.form.values ? this.props.form.values : this.getDefaultValues();

      //let seletedFrequency = !values.recurring ? this.frequencies.ONCE : values.frequency || this.frequencies.DAILY
      var seletedFrequency = this.frequencies.DAILY;
      return _react2.default.createElement(
        'div',
        { className: styles.scheduler },
        _react2.default.createElement(_components.MobileBar, { returnToAction: this.props.toReturn }),
        _react2.default.createElement(
          'nav',
          { className: styles.bar },
          _react2.default.createElement(
            _reactRouter.Link,
            { onClick: this.props.toReturn, className: styles.arrow },
            _react2.default.createElement(md.arrowBack, { size: 20, color: '#FFF' })
          )
        ),
        _react2.default.createElement(
          'form',
          { onSubmit: handleSubmit(this.handleSubmit.bind(this)), className: styles.messageForm },
          _react2.default.createElement(
            'div',
            { className: styles.recurring },
            _react2.default.createElement(
              'div',
              { className: styles.leftrow },
              _react2.default.createElement(
                'label',
                null,
                _react2.default.createElement(_reduxForm.Field, { name: 'recurring', component: 'input', type: 'checkbox', value: 'recurring', onChange: this.handleRecurringChange.bind(this) }),
                ' ',
                (0, _translations2.default)('RECURRING')
              ),
              _react2.default.createElement(
                _reduxForm.Field,
                { name: 'frequency', component: 'select', style: this.showOnField(values.recurring), value: this.state.frequency, className: styles.frequency },
                _react2.default.createElement(
                  'option',
                  { value: this.frequencies.DAILY },
                  (0, _translations2.default)('DAILY')
                ),
                _react2.default.createElement(
                  'option',
                  { value: this.frequencies.WEEKLY },
                  (0, _translations2.default)('WEEKLY')
                ),
                _react2.default.createElement(
                  'option',
                  { value: this.frequencies.MONTHLY },
                  (0, _translations2.default)('MONTHLY')
                ),
                _react2.default.createElement(
                  'option',
                  { value: this.frequencies.YEARLY },
                  (0, _translations2.default)('YEARLY')
                )
              ),
              _react2.default.createElement(
                'label',
                { style: this.showOnField(values.recurring), className: styles.fishdate },
                _react2.default.createElement(_reduxForm.Field, { name: 'finish', component: 'input', type: 'checkbox', value: 'finish' }),
                ' ',
                (0, _translations2.default)('FINISH DATE')
              )
            ),
            _react2.default.createElement(
              'div',
              { className: styles.centerrow + ' ' + styles.weekday, style: this.showOnField(values.frequency === this.frequencies.WEEKLY && values.recurring) },
              _react2.default.createElement(
                'label',
                null,
                _react2.default.createElement(_reduxForm.Field, { name: 'day_sunday', component: 'input', type: 'checkbox', value: 'day_sunday' }),
                ' ',
                (0, _translations2.default)('SUNDAY')
              ),
              _react2.default.createElement(
                'label',
                null,
                _react2.default.createElement(_reduxForm.Field, { name: 'day_monday', component: 'input', type: 'checkbox', value: 'day_monday' }),
                ' ',
                (0, _translations2.default)('MONDAY')
              ),
              _react2.default.createElement(
                'label',
                null,
                _react2.default.createElement(_reduxForm.Field, { name: 'day_tuesday', component: 'input', type: 'checkbox', value: 'day_tuesday' }),
                ' ',
                (0, _translations2.default)('TUESDAY')
              ),
              _react2.default.createElement(
                'label',
                null,
                _react2.default.createElement(_reduxForm.Field, { name: 'day_wednesday', component: 'input', type: 'checkbox', value: 'day_wednesday' }),
                ' ',
                (0, _translations2.default)('WEDNESDAY')
              ),
              _react2.default.createElement(
                'label',
                null,
                _react2.default.createElement(_reduxForm.Field, { name: 'day_thursday', component: 'input', type: 'checkbox', value: 'day_thursday' }),
                ' ',
                (0, _translations2.default)('THURSDAY')
              ),
              _react2.default.createElement(
                'label',
                null,
                _react2.default.createElement(_reduxForm.Field, { name: 'day_friday', component: 'input', type: 'checkbox', value: 'day_friday' }),
                ' ',
                (0, _translations2.default)('FRIDAY')
              ),
              _react2.default.createElement(
                'label',
                null,
                _react2.default.createElement(_reduxForm.Field, { name: 'day_saturday', component: 'input', type: 'checkbox', value: 'day_saturday' }),
                ' ',
                (0, _translations2.default)('SATURDAY')
              )
            )
          ),
          _react2.default.createElement(
            'div',
            { className: styles.row },
            _react2.default.createElement(
              'div',
              { className: styles.col },
              _react2.default.createElement(
                'label',
                { className: styles.datetitle },
                (0, _translations2.default)('START DATE')
              ),
              _react2.default.createElement(_components.Datepicker, { onChange: this.handleDateChange.bind(this, 'startDate'), compact: true })
            ),
            _react2.default.createElement(
              'div',
              { className: styles.col, style: this.showOnField(values.finish) },
              _react2.default.createElement(
                'label',
                { className: styles.datetitle },
                (0, _translations2.default)('FINISH DATE')
              ),
              _react2.default.createElement(_components.Datepicker, { onChange: this.handleDateChange.bind(this, 'finishDate'), compact: true, timeFormat: false })
            )
          ),
          _react2.default.createElement(
            'button',
            { className: styles.buttons + ' ' + (!this.state.dates.startDate && styles.disabled), type: 'submit' },
            (0, _translations2.default)('SCHEDULE FOR THIS DATE')
          )
        )
      );
    }
  }]);
  return MessageScheduler;
}(_react2.default.Component), _class2.propTypes = {
  handleSubmit: _propTypes2.default.func.isRequired,
  pristine: _propTypes2.default.bool.isRequired,
  reset: _propTypes2.default.func.isRequired,
  submitting: _propTypes2.default.bool.isRequired,
  form: _propTypes2.default.object,
  onSubmit: _propTypes2.default.func.isRequired,
  toReturn: _propTypes2.default.func.isRequired
}, _temp)) || _class);
exports.default = (0, _reduxForm.reduxForm)({
  form: 'messageScheduler'
})(MessageScheduler);
module.exports = exports['default'];