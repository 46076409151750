'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actions = undefined;
exports.default = fileReducer;
exports.getContactsFiles = getContactsFiles;
exports.getMessagesFiles = getMessagesFiles;
exports.getMessageFileDetail = getMessageFileDetail;
exports.filesRefresh = filesRefresh;

var _apiAction = require('../apiAction');

var _apiAction2 = _interopRequireDefault(_apiAction);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// ACTIONS

var actions = exports.actions = new _apiAction2.default('FILE', [_apiAction.ACTION_TYPES.DATA, _apiAction.ACTION_TYPES.APPEND, _apiAction.ACTION_TYPES.TRIM]);

// REDUCER

var initialState = {
  loading: false,
  status: actions.APPEND,
  list: [],
  data: {}
};

function fileReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  return actions.getStateForAction(state, action);
}

// ACTION CREATORS

function getContactsFiles(params) {
  return {
    types: [actions.APPEND, actions.APPEND_SUCCESS, actions.APPEND_FAIL],
    promise: function promise(client) {
      return client.get('/files/contacts', {
        params: params
      });
    }
  };
}

function getMessagesFiles(params) {
  return {
    types: [actions.APPEND, actions.APPEND_SUCCESS, actions.APPEND_FAIL],
    promise: function promise(client) {
      return client.get('/files/messages', {
        params: params
      });
    }
  };
}

function getMessageFileDetail(fileUid) {
  return {
    types: [actions.APPEND, actions.APPEND_SUCCESS, actions.APPEND_FAIL],
    promise: function promise(client) {
      return client.get('/files/messages/' + fileUid);
    }
  };
}

function filesRefresh() {
  return {
    type: actions.TRIM,
    count: 0
  };
}