'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.ACTION_TYPES = exports.NONE = exports.SINGLE = exports.OFF = exports.FAIL = exports.CLEAR = exports.SUCCESS = undefined;

var _extends3 = require('babel-runtime/helpers/extends');

var _extends4 = _interopRequireDefault(_extends3);

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _defineProperty4 = require('babel-runtime/core-js/object/define-property');

var _defineProperty5 = _interopRequireDefault(_defineProperty4);

var _keys = require('babel-runtime/core-js/object/keys');

var _keys2 = _interopRequireDefault(_keys);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _immutabilityHelper = require('immutability-helper');

var _immutabilityHelper2 = _interopRequireDefault(_immutabilityHelper);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SUCCESS = exports.SUCCESS = 'SUCCESS';
var CLEAR = exports.CLEAR = 'CLEAR';
var FAIL = exports.FAIL = 'FAIL';
var OFF = exports.OFF = 'OFF';
var SINGLE = exports.SINGLE = 'SINGLE';
var NONE = exports.NONE = 'NONE';

var ACTION_TYPES = exports.ACTION_TYPES = {
  DATA: 'DATA',
  APPEND: 'APPEND',
  PREPEND: 'PREPEND',
  REPLACE: 'REPLACE',
  TRIM: 'TRIM',
  SELECT: 'SELECT'
};

var ApiAction = function () {
  function ApiAction(label, actionTypes) {
    (0, _classCallCheck3.default)(this, ApiAction);

    this.label = label;
    this.actionTypes = actionTypes;
    this.actionsToProperties();
  }

  (0, _createClass3.default)(ApiAction, [{
    key: 'actionsToProperties',
    value: function actionsToProperties() {
      var _this = this;

      var actionTypesKeys = (0, _keys2.default)(this.actionTypes);
      actionTypesKeys.forEach(function (actionTypeIndex) {
        if ({}.hasOwnProperty.call(_this.actionTypes, actionTypeIndex)) {
          var actionType = _this.actionTypes[actionTypeIndex];
          var action = _this.label + '_' + actionType;
          (0, _defineProperty5.default)(_this, actionType, { value: '' + action });

          if (actionType === ACTION_TYPES.DATA || actionType === ACTION_TYPES.APPEND || actionType === ACTION_TYPES.PREPEND || actionType === ACTION_TYPES.REPLACE) {
            Object.defineProperty(_this, actionType + '_' + SUCCESS, { value: action + '_' + SUCCESS });
            Object.defineProperty(_this, actionType + '_' + FAIL, { value: action + '_' + FAIL });
            Object.defineProperty(_this, actionType + '_' + CLEAR, { value: action + '_' + CLEAR });
          }
          if (actionType === ACTION_TYPES.SELECT) {
            Object.defineProperty(_this, actionType + '_' + SINGLE, { value: action + '_' + SINGLE });
            Object.defineProperty(_this, actionType + '_' + OFF, { value: action + '_' + OFF });
            Object.defineProperty(_this, actionType + '_' + NONE, { value: action + '_' + NONE });
          }
        }
      });
    }
  }, {
    key: 'getStateForAction',
    value: function getStateForAction(state, action) {
      var _this2 = this;

      if (!this.actionTypes) {
        console.error('Instance is invalid. Actions must be assigned using "actionsToProperties".');
        return null;
      }

      var newState = state;
      this.actionTypes.every(function (actionType) {
        var actionName = _this2.label + '_' + actionType;

        if (actionType === ACTION_TYPES.DATA) {
          switch (action.type) {
            case actionName:
              // main action
              newState = (0, _immutabilityHelper2.default)(state, {
                status: { $set: action.type },
                loading: { $set: true },
                data: { $set: {} }
              });
              return false;
            case actionName + '_' + SUCCESS:
              if (state.loading && action.result) {
                newState = (0, _immutabilityHelper2.default)(state, {
                  status: { $set: action.type },
                  loading: { $set: false },
                  data: { $set: action.result }
                });
              }
              return false;
            case actionName + '_' + FAIL:
              newState = (0, _immutabilityHelper2.default)(state, {
                status: { $set: action.type },
                loading: { $set: false },
                data: { $set: {} },
                error: { $set: action.result }
              });
              return false;
            case actionName + '_' + CLEAR:
              newState = (0, _immutabilityHelper2.default)(state, {
                status: { $set: actionName },
                loading: { $set: false },
                data: { $set: {} },
                error: { $set: {} }
              });
              return false;
            default:
              return true;
          }
        }
        if (actionType === ACTION_TYPES.APPEND || actionType === ACTION_TYPES.PREPEND) {
          switch (action.type) {
            case actionName:
              // main action
              newState = (0, _immutabilityHelper2.default)(state, {
                status: { $set: action.type },
                loading: { $set: true }
              });
              return false;
            case actionName + '_' + SUCCESS:
              if (state.loading && action.result) {
                if (actionType === ACTION_TYPES.APPEND) {
                  newState = (0, _immutabilityHelper2.default)(state, {
                    status: { $set: action.type },
                    loading: { $set: false },
                    list: { $push: action.result }
                  });
                } else {
                  newState = (0, _immutabilityHelper2.default)(state, {
                    status: { $set: action.type },
                    loading: { $set: false },
                    list: { $set: action.result.concat(state.list) }
                  });
                }
              }
              return false;
            case actionName + '_' + FAIL:
              newState = (0, _immutabilityHelper2.default)(state, {
                status: { $set: action.type },
                loading: { $set: false },
                error: { $set: action.result }
              });
              return false;
            default:
              return true;
          }
        }
        if (actionType === ACTION_TYPES.REPLACE) {
          switch (action.type) {
            case actionName:
              // main action
              newState = (0, _immutabilityHelper2.default)(state, {
                status: { $set: action.type },
                loading: { $set: true }
              });
              return false;
            case actionName + '_' + SUCCESS:
              if (state.loading && action.result) {
                newState = (0, _immutabilityHelper2.default)(state, {
                  status: { $set: action.type },
                  loading: { $set: false },
                  list: { $set: action.result }
                });
              }
              return false;
            case actionName + '_' + FAIL:
              newState = (0, _immutabilityHelper2.default)(state, {
                status: { $set: action.type },
                loading: { $set: false },
                error: { $set: action.result }
              });
              return false;
            default:
              return true;
          }
        }
        if (actionType === ACTION_TYPES.SELECT) {
          switch (action.type) {
            case actionName:
              // main action
              newState = (0, _immutabilityHelper2.default)(state, {
                list: (0, _defineProperty3.default)({}, action.index, { active: { $set: true } }),
                selected: { $push: [action.index] }
              });
              return false;
            case actionName + '_' + OFF:
              newState = (0, _immutabilityHelper2.default)(state, {
                list: (0, _defineProperty3.default)({}, action.index, { active: { $set: false } }),
                selected: { $splice: [[state.selected.indexOf(action.index), 1]] }
              });
              return false;
            case actionName + '_' + SINGLE:
              newState = (0, _immutabilityHelper2.default)(state, {
                selected: { 0: { $set: action.index } }
              });
              return false;
            case actionName + '_' + NONE:
              newState = (0, _immutabilityHelper2.default)(state, {
                list: state.selected.reduce(function (listChanges, itemNum) {
                  return (0, _extends4.default)({}, listChanges, (0, _defineProperty3.default)({}, itemNum, { active: { $set: false } }));
                }, {}),
                selected: { $set: [] }
              });
              return false;
            default:
              return true;
          }
        }
        if (actionType === ACTION_TYPES.TRIM) {
          switch (action.type) {
            case actionName:
              // main action
              newState = (0, _immutabilityHelper2.default)(state, {
                list: { $set: state.list.slice(0, action.count) },
                selected: { $set: [] }
              });
              return false;
            default:
              {
                return true;
              }
          }
        }
        return true;
      });
      return newState;
    }
  }]);
  return ApiAction;
}();

exports.default = ApiAction;