'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.actions = undefined;
exports.getCountries = getCountries;

var _apiAction = require('../apiAction');

var _apiAction2 = _interopRequireDefault(_apiAction);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var actions = exports.actions = new _apiAction2.default('COUNTRIES', [_apiAction.ACTION_TYPES.DATA, _apiAction.ACTION_TYPES.APPEND, _apiAction.ACTION_TYPES.SELECT]);

var initialState = {
    loading: false,
    status: actions.DATA,
    data: {}
};

function getCountries() {
    return {
        types: [actions.APPEND, actions.APPEND_SUCCESS, actions.APPEND_FAIL],
        promise: function promise(client) {
            return client.get('/countries');
        }
    };
}