'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.ContactTabs = undefined;

var _getOwnPropertyDescriptor = require('babel-runtime/core-js/object/get-own-property-descriptor');

var _getOwnPropertyDescriptor2 = _interopRequireDefault(_getOwnPropertyDescriptor);

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _dec2, _class, _desc, _value, _class2, _class3, _temp;

var _components = require('components');

var _workflows = require('redux/modules/workflows');

var _profiling = require('redux/modules/profiling');

var _reactRouter = require('react-router');

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _reduxConnect = require('redux-connect');

var _coreDecorators = require('core-decorators');

var _reactRedux = require('react-redux');

var _reachHistory = require('../../helpers/reachHistory');

var _ContactsProfilesHistorical = require('./ContactsProfilesHistorical.scss');

var _ContactsProfilesHistorical2 = _interopRequireDefault(_ContactsProfilesHistorical);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
  var desc = {};
  Object['ke' + 'ys'](descriptor).forEach(function (key) {
    desc[key] = descriptor[key];
  });
  desc.enumerable = !!desc.enumerable;
  desc.configurable = !!desc.configurable;

  if ('value' in desc || desc.initializer) {
    desc.writable = true;
  }

  desc = decorators.slice().reverse().reduce(function (desc, decorator) {
    return decorator(target, property, desc) || desc;
  }, desc);

  if (context && desc.initializer !== void 0) {
    desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
    desc.initializer = undefined;
  }

  if (desc.initializer === void 0) {
    Object['define' + 'Property'](target, property, desc);
    desc = null;
  }

  return desc;
}

var Infinite = function Infinite(props) {
  return _react2.default.createElement(
    'div',
    null,
    props.children
  );
};
var md = {
  search: require('react-icons/lib/md/search'),
  add: require('react-icons/lib/md/add'),
  settings: require('react-icons/lib/md/settings')
};

var ContactTabs = exports.ContactTabs = function ContactTabs(props) {
  return _react2.default.createElement(_components.Tabs, {
    simple: true,
    options: [{
      href: '/contacts/',
      title: (0, _translations2.default)('CONTACTS'),
      is_index: true
    }, {
      href: '/contacts/tags',
      title: (0, _translations2.default)('MY TAGS')
    }, {
      href: '/contacts/profiles',
      title: (0, _translations2.default)('MY PROFILING')
    }, {
      href: '/contacts/files',
      title: (0, _translations2.default)('FILES')
    }]
  });
};
var ContactsProfilesHistorical = (_dec = (0, _reduxConnect.asyncConnect)([{
  key: 'list',
  promise: function promise(_ref) {
    var _ref$store = _ref.store,
        dispatch = _ref$store.dispatch,
        getState = _ref$store.getState;

    var state = getState();
    if (state.profiling.list.length === 0) {
      return dispatch((0, _profiling.getProfilingJobs)(0, 100));
    }
    return null;
  }
}]), _dec2 = (0, _reactRedux.connect)(function (state) {
  return {
    mobileMode: state.mobile.mobileMode,
    list: state.profiling.list,
    account: state.auth.current_account,
    selected: state.variables.selected
  };
}, {
  get: _profiling.getProfilingJobs,
  refresh: _profiling.profilingRefresh,
  getProfilingResults: _profiling.getProfilingResults,
  stopProfiling: _workflows.stopProfiling,
  push: _reachHistory.push
}), _dec(_class = _dec2(_class = (_class2 = (_temp = _class3 = function (_List) {
  (0, _inherits3.default)(ContactsProfilesHistorical, _List);

  function ContactsProfilesHistorical(props) {
    (0, _classCallCheck3.default)(this, ContactsProfilesHistorical);

    var _this = (0, _possibleConstructorReturn3.default)(this, (ContactsProfilesHistorical.__proto__ || (0, _getPrototypeOf2.default)(ContactsProfilesHistorical)).call(this, props));

    _this.title = (0, _translations2.default)('HISTORIC PROFILES TITLE');
    _this.statusTranslations = {
      ACTIVE: (0, _translations2.default)('IN_PROGRESS'),
      PAUSED: (0, _translations2.default)('PAUSED'),
      APPROVED: (0, _translations2.default)('APPROVED'),
      FINISHED: (0, _translations2.default)('FINISHED'),
      EXPIRED: (0, _translations2.default)('EXPIRED'),
      ABORTED: (0, _translations2.default)('ABORTED'),
      NOT_STARTED: (0, _translations2.default)('NOT_STARTED'),
      STOPPED: (0, _translations2.default)('STOPPED')
    };
    _this.isOpen = props.account.account_type == "Subaccount" && props.account.is_default_subaccount && props.account.reach_enabled || props.account.account_type != "Subaccount";
    return _this;
  }

  (0, _createClass3.default)(ContactsProfilesHistorical, [{
    key: 'renderTabs',
    value: function renderTabs() {
      return _react2.default.createElement(ContactTabs, null);
    }
  }, {
    key: 'renderNav',
    value: function renderNav(stickyprops) {
      return _react2.default.createElement(
        _components.Nav,
        { key: 'Nav', title: (0, _translations2.default)('MY PROFILING'), style: stickyprops.style },
        _react2.default.createElement(
          _reactRouter.Link,
          { className: 'action', to: {
              pathname: this.isOpen ? '/profiling/choose' : '/profiling/open',
              state: { modal: true, returnTo: '/profiling', fullModal: !this.isOpen, modalSize: { width: '300px', height: '526px' } }
            }, style: { marginLeft: 'auto' } },
          (0, _translations2.default)('PROFILING CONFIG'),
          _react2.default.createElement(md.settings, { size: 20 })
        )
      );
    }
  }, {
    key: 'getLink',
    value: function getLink(item) {
      var openIf = ['NOT_STARTED'];
      var resultsIf = ['ACTIVE', 'IN_PROGRESS', 'FINISHED', 'EXPIRED', 'ABORTED', 'STOPPED'];

      if (resultsIf.indexOf(item.status) > -1) {
        return {
          pathname: '/contacts/profiles/historical/results',
          query: { uid: item._id },
          state: {
            modal: true,
            returnTo: '/contacts/profiles/historical',
            modalSize: { width: '900px', height: '600px' }
          }
        };
      }
    }
  }, {
    key: 'renderExtra',
    value: function renderExtra() {
      return _react2.default.createElement(
        'div',
        { className: _ContactsProfilesHistorical2.default.stopButtons },
        _react2.default.createElement(
          _reactRouter.Link,
          (0, _defineProperty3.default)({ className: 'action', to: '/contacts/profiles' }, 'className', _ContactsProfilesHistorical2.default.profilesListButton),
          _react2.default.createElement(
            'div',
            { className: _ContactsProfilesHistorical2.default.profilesList },
            (0, _translations2.default)('BACK TO PROFILES')
          )
        )
      );
    }
  }, {
    key: 'renderInfiniteList',
    value: function renderInfiniteList() {
      var _this2 = this;

      if (__SERVER__) {
        return _react2.default.createElement(_components.VirtualListItemLoad, null);
      }

      var hidden = { display: 'none' };
      var visible = { display: 'block' };
      var infiniteStyle = this.props.isLoading || this.props.list.length > 0 ? visible : hidden;
      var linkstyle = this.props.isLoading || this.props.list.length > 0 ? hidden : visible;
      return _react2.default.createElement(
        'div',
        { className: _ContactsProfilesHistorical2.default.container },
        _react2.default.createElement(
          'div',
          { style: infiniteStyle },
          _react2.default.createElement(
            Infinite,
            {
              refresh: this.refreshRows,
              next: this.loadMoreRows,
              hasMore: this.state.hasMoreRows
            },
            this.props.list.map(function (item, index) {
              return _react2.default.createElement(_components.VirtualListItemStudy, {
                key: item._id,
                index: index,
                name: item.name,
                link: _this2.getLink(item),
                label: _this2.statusTranslations[item.status],
                labelClass: item.status
              });
            })
          )
        ),
        _react2.default.createElement(
          'div',
          { style: linkstyle },
          _react2.default.createElement(_components.Linkholder, {
            to: {
              pathname: this.isOpen ? '/profiling/choose' : '/profiling/open',
              state: { modal: true, returnTo: '/profiling/', fullModal: !this.isOpen, modalSize: { width: '300px', height: '526px' } }
            },
            src: '/static/empty/results.svg',
            text: (0, _translations2.default)('NO PROFILINGS RUNNING'),
            button: (0, _translations2.default)('CONFIGURE A NEW PROFILING')
          })
        )
      );
    }
  }]);
  return ContactsProfilesHistorical;
}(_components.List), _class3.propTypes = {
  mobileMode: _propTypes2.default.bool,
  selected: _propTypes2.default.array,
  getProfilingResults: _propTypes2.default.func.isRequired
}, _temp), (_applyDecoratedDescriptor(_class2.prototype, 'renderNav', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'renderNav'), _class2.prototype)), _class2)) || _class) || _class);
exports.default = ContactsProfilesHistorical;