'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.sessionPermissions = sessionPermissions;

var _clientStore = require('./clientStore.js');

var _clientStore2 = _interopRequireDefault(_clientStore);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function sessionPermissions(permission) {
    var result = false;

    if (__CLIENT__) {

        try {
            var store = (0, _clientStore2.default)();
            var permissions = store.getState().auth.data.permissions;
            if (permissions.includes(permission)) {
                result = true;
            }
        } catch (e) {}
    }
    return result;
}