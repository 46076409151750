'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Name = undefined;

var _Name2 = require('./Name');

var _Name3 = _interopRequireDefault(_Name2);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.Name = _Name3.default;