'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = undefined;

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _TextEdit = require('./TextEdit.scss');

var _TextEdit2 = _interopRequireDefault(_TextEdit);

var _components = require('components');

var _draftJs = require('draft-js');

var _draftJsExportHtml = require('draft-js-export-html');

var _draftjsToHtml = require('draftjs-to-html');

var _draftjsToHtml2 = _interopRequireDefault(_draftjsToHtml);

var _htmlToDraftjs = require('html-to-draftjs');

var _htmlToDraftjs2 = _interopRequireDefault(_htmlToDraftjs);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var TextEdit = function (_React$Component) {
    (0, _inherits3.default)(TextEdit, _React$Component);

    function TextEdit(props) {
        (0, _classCallCheck3.default)(this, TextEdit);

        var _this = (0, _possibleConstructorReturn3.default)(this, (TextEdit.__proto__ || (0, _getPrototypeOf2.default)(TextEdit)).call(this, props));

        _this.convertData = function () {
            var markup = _this.props.preservData[_this.props.propertyEdit];
            var contentBlock = (0, _htmlToDraftjs2.default)(markup);
            var contentState = _draftJs.ContentState.createFromBlockArray(contentBlock.contentBlocks);
            var editorState = _draftJs.EditorState.createWithContent(contentState);
            return editorState;
        };

        _this.setEditorReference = function (ref) {
            _this.editorReferece = ref;
        };

        _this.handleEditorStateChange = function (editorState) {

            var message = void 0;
            message = _this.editorReferece.editor.innerHTML;

            //           value={draftToHtml(convertToRaw(editorState.getCurrentContent())) }
            // convertToRaw(editorState.getCurrentContent())

            _this.setState({ editorState: editorState,
                editorContentHtml: (0, _draftjsToHtml2.default)((0, _draftJs.convertToRaw)(editorState.getCurrentContent())) }, function () {

                /*             console.log('editor message', message)
                
                            console.log('editor html --->', this.state.editorContentHtml) */

                _this.props.handleData(_this.state.editorContentHtml);
            });
        };

        _this.state = {
            editorState: _this.props.preservData[_this.props.propertyEdit] !== null ? _this.convertData() : _draftJs.EditorState.createEmpty(),
            editorContentHtml: ''
        };
        return _this;
    }

    (0, _createClass3.default)(TextEdit, [{
        key: 'handleSubjectChange',
        value: function handleSubjectChange(subject) {
            console.log('handleSubjectChange', subject);
        }
    }, {
        key: 'render',
        value: function render() {
            return _react2.default.createElement(
                'div',
                { className: _TextEdit2.default.emailContainer },
                _react2.default.createElement(_components.Email, {
                    editorReference: this.setEditorReference,
                    editorState: this.state.editorState,
                    onChange: this.handleEditorStateChange,
                    showSubject: false
                })
            );
        }
    }]);
    return TextEdit;
}(_react2.default.Component);

exports.default = TextEdit;
module.exports = exports['default'];