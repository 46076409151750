'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _stringify = require('babel-runtime/core-js/json/stringify');

var _stringify2 = _interopRequireDefault(_stringify);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _class, _class2, _temp;
// import Multiselect from './components/Multiselect'

// import HighchartsMap from './components/HighchartsMap'


var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactRedux = require('react-redux');

var _interaction = require('redux/modules/interaction');

var _components = require('components');

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Panel = (_dec = (0, _reactRedux.connect)(function (state) {
  return {
    panel_filter: state.interaction.panel_filter || '{}',
    panel_source: state.interaction.configuration.panel_source || 'DATA_STORE',
    shortname_key: state.interaction.configuration.public_web_access.shortname_key,
    keyword: state.interaction.configuration.keyword,
    base_url: state.interaction.configuration.public_web_access.base_url || 'http://csms-s.ubiquo.io/',
    interaction_channels: state.interaction.configuration.interaction_channels
  };
}, function (dispatch) {
  return {
    handleConfigChange: function handleConfigChange(property, value) {
      var val = property === 'reward' ? { enabled: value.target.value ? true : false } : value;
      dispatch((0, _interaction.updateInteractionConfig)(property, val));
    },
    handlePropertyChange: function handlePropertyChange(property, value) {
      dispatch((0, _interaction.updateInteraction)(property, value));
    }
  };
}), _dec(_class = (_temp = _class2 = function (_React$Component) {
  (0, _inherits3.default)(Panel, _React$Component);

  function Panel(props) {
    (0, _classCallCheck3.default)(this, Panel);

    var _this = (0, _possibleConstructorReturn3.default)(this, (Panel.__proto__ || (0, _getPrototypeOf2.default)(Panel)).call(this, props));

    _this.panelTranslations = {
      OPEN: (0, _translations2.default)('OPEN PANEL'),
      FILE: (0, _translations2.default)('FILE PANEL'),
      DATA_STORE: (0, _translations2.default)('DATA STORE PANEL')
    };

    _this.duration_days = Array(15).fill().map(function (e, i) {
      return i + 1;
    });
    // this.base_url = this.props.base_url.replace('%s', '')
    _this.base_url = 'http://www.tuempresa.com/';

    _this.state = {
      keywordErrors: ''
    };
    return _this;
  }

  (0, _createClass3.default)(Panel, [{
    key: 'handleVariableAdd',
    value: function handleVariableAdd(key, value) {
      var panelFilter = this.props.panel_filter;

      var panelFilterObj = JSON.parse(panelFilter);
      panelFilterObj[key] = value.split ? value.split(/[\s,]+/) : value;
      this.props.handlePropertyChange('panel_filter', (0, _stringify2.default)(panelFilterObj));
    }
  }, {
    key: 'handleVariableRemove',
    value: function handleVariableRemove(key) {
      var panelFilter = this.props.panel_filter;

      var panelFilterObj = JSON.parse(panelFilter);
      if (panelFilterObj[key]) {
        delete panelFilterObj[key];
      }
      this.props.handlePropertyChange('panel_filter', (0, _stringify2.default)(panelFilterObj));
    }
  }, {
    key: 'handlePanelFilter',
    value: function handlePanelFilter(items) {
      this.panelFilter.advanced = items.map(function (item) {
        return JSON.parse(item.key);
      });
    }
  }, {
    key: 'handleKeyword',
    value: function handleKeyword(event) {
      //if (/^[a-z0-9]+$/i.test(event.target.value)) {
      var correctedKeyword = event.target.value.replace(/[^a-zA-Z0-9]/gi, '_');
      var found = this.props.reservedKeywords.filter(function (r) {
        return r.keyword === '' + correctedKeyword;
      });
      var err = '';
      if (found.length > 0) {
        err = 'El keyword ya est\xE1 en uso para el estudio: ' + found[0].name;
      }
      this.setState((0, _extends3.default)({}, this.state, {
        keywordErrors: err
      }));
      this.props.handleConfigChange('keyword', correctedKeyword);
      //}
    }
  }, {
    key: 'handleOpen',
    value: function handleOpen(event) {
      this.props.handleConfigChange('public_web_access', {
        'enabled': true,
        'shortname_key': event.target.value,
        'shortname_enabled': true
      });
    }
  }, {
    key: 'renderOpenPanel',
    value: function renderOpenPanel() {
      return _react2.default.createElement(
        'div',
        { className: 'shareurl' },
        this.props.interaction_channels.indexOf('SMS') > -1 ? _react2.default.createElement(
          'div',
          { className: 'row input-field input-field-keyword col s12' },
          _react2.default.createElement(
            'label',
            { htmlFor: 'keyword' },
            '\'',
            (0, _translations2.default)('KEYWORD LABEL'),
            '\''
          ),
          _react2.default.createElement('input', { type: 'text', value: this.props.keyword, maxLength: '48', onChange: this.handleKeyword.bind(this), name: 'keyword' }),
          this.state.keywordErrors && _react2.default.createElement(
            'div',
            { className: 'keyword-error' },
            this.state.keywordErrors
          )
        ) : null
      );
    }
  }, {
    key: 'render',
    value: function render() {
      var panelFilter = this.props.panel_filter;


      return _react2.default.createElement(
        'div',
        { className: 'block edit' },
        _react2.default.createElement(
          'h5',
          null,
          this.props.panels === 'OPEN' ? null : (0, _translations2.default)('SEGMENTATION')
        ),
        this.props.panels === 'OPEN' ? this.renderOpenPanel() : _react2.default.createElement(
          'div',
          { className: 'segmentation' },
          _react2.default.createElement(_components.VariableSelect, {
            multi: true,
            variables: JSON.parse(panelFilter),
            addVariable: this.handleVariableAdd.bind(this),
            removeVariable: this.handleVariableRemove.bind(this)
          })
        )
      );
    }
  }]);
  return Panel;
}(_react2.default.Component), _class2.propTypes = {
  panel_filter: _propTypes2.default.string.isRequired,
  panel_source: _propTypes2.default.string.isRequired,
  base_url: _propTypes2.default.string.isRequired,
  shortname_key: _propTypes2.default.string,
  handleConfigChange: _propTypes2.default.func.isRequired,
  handlePropertyChange: _propTypes2.default.func.isRequired,
  keyword: _propTypes2.default.string.isRequired,
  interaction_channels: _propTypes2.default.array.isRequired,
  panels: _propTypes2.default.string,
  type: _propTypes2.default.string
}, _class2.defaultProps = {
  type: 'STUDY',
  shortname_key: 'default',
  keyword: ''
}, _temp)) || _class);
exports.default = Panel;
module.exports = exports['default'];