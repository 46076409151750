'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _class, _class2, _temp;

var _auth = require('redux/modules/auth');

var _reactHelmet = require('react-helmet');

var _reactHelmet2 = _interopRequireDefault(_reactHelmet);

var _reactRouter = require('react-router');

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactDom = require('react-dom');

var _reactDom2 = _interopRequireDefault(_reactDom);

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _config = require('config');

var _config2 = _interopRequireDefault(_config);

var _reactRedux = require('react-redux');

var _reachHistory = require('../../helpers/reachHistory');

var _Login = require('./Login.scss');

var _Login2 = _interopRequireDefault(_Login);

var _partner = require('../../helpers/partner');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var md = {
  eye: require('react-icons/lib/md/remove-red-eye'),
  cancel: require('react-icons/lib/md/cancel'),
  checkCircle: require('react-icons/lib/md/check-circle')
};

var VerifyPassword = (_dec = (0, _reactRedux.connect)(function (state) {
  return { auth: state.auth, isMobile: state.mobile.isMobile };
}, {
  pushState: _reachHistory.push,
  verifyPassword: _auth.verifyAccountUserToken,
  changePassword: _auth.changeAccountUserTokenPassword,
  logout: _auth.logout
}), _dec(_class = (_temp = _class2 = function (_React$Component) {
  (0, _inherits3.default)(VerifyPassword, _React$Component);

  function VerifyPassword(props) {
    (0, _classCallCheck3.default)(this, VerifyPassword);

    var _this = (0, _possibleConstructorReturn3.default)(this, (VerifyPassword.__proto__ || (0, _getPrototypeOf2.default)(VerifyPassword)).call(this, props));

    _this.state = {
      validPassword: false,
      hasUpperCase: false,
      hasLowerCase: false,
      hasNumber: false,
      hasSpecialChar: false,
      hasLenght: '',
      matchPass: false,
      message: false,
      loading: true,
      token: props.params.token,
      user: {}
    };
    _this.componentWillMount = _this.componentWillMount.bind(_this);
    _this.handleSubmit = _this.handleSubmit.bind(_this);
    return _this;
  }

  (0, _createClass3.default)(VerifyPassword, [{
    key: 'componentWillMount',
    value: function componentWillMount() {
      var _this2 = this;

      setTimeout(function () {
        _this2.props.verifyPassword(_this2.state.token).then(function (response) {
          _this2.setState({
            loading: false,
            user: response.user
          });
        }).catch(function () {
          alert('Token expirado');
          window.location = '/';
        });
      });
    }
  }, {
    key: 'handleSubmit',
    value: function handleSubmit() {
      var _this3 = this;

      if (this.state.validPassword && this.refs.password.value.trim() != null && this.refs.password.value.trim() != "" && this.refs.password2.value.trim() != null && this.refs.password2.value.trim() != "") {
        if (this.refs.password.value.trim() == this.refs.password2.value.trim()) {
          this.props.changePassword(this.state.token, this.refs.password.value.trim(), this.state.user._id).then(function (result) {
            _this3.props.logout().then(function () {
              return window.location = '/';
            });
          });
        } else {
          alert("Las contraseñas no coinciden");
        }
      } else {
        alert("Datos incorrectos, por favor verifique la contraseña ingresada");
      }
    }
  }, {
    key: 'handleLogout',
    value: function handleLogout() {
      this.props.logout();
    }
  }, {
    key: 'handleSubmitRecovery',
    value: function handleSubmitRecovery() {
      var username = this.refs.username.value;
      this.props.passwordRecover(username, (0, _partner.partnerByHost)());
    }
  }, {
    key: 'handleRecovery',
    value: function handleRecovery() {
      this.setState((0, _extends3.default)({}, this.state, {
        recovering: !this.state.recovering
      }));
    }
  }, {
    key: 'togglePassword',
    value: function togglePassword() {
      this.setState((0, _extends3.default)({}, this.state, {
        passwordType: this.state.passwordType === 'password' ? 'text' : 'password'
      }));
      _reactDom2.default.findDOMNode(this.refs.password).focus();
    }
  }, {
    key: 'validatePass',
    value: function validatePass(event) {
      var upperCase = /[A-Z]/.test(event.target.value) ? true : false;
      var lowerCase = /[a-z]/.test(event.target.value) ? true : false;
      var number = /[0-9]/.test(event.target.value) ? true : false;
      var specialChar = /[!@#\$%\^\&*\)\(+=.,:;'"<?>/{|}\]\[\\~`_-]/.test(event.target.value) ? true : false;
      var length = /.{15}/.test(event.target.value) ? true : false;

      this.setState({
        message: true,
        hasUpperCase: upperCase,
        hasLowerCase: lowerCase,
        hasNumber: number,
        hasSpecialChar: specialChar,
        hasLenght: length
      });
      if (upperCase && lowerCase && number && specialChar && length) {
        this.setState({
          validPassword: true
        });
      } else {
        this.setState({
          validPassword: false
        });
      }
    }
  }, {
    key: 'renderForm',
    value: function renderForm(styles) {
      var _this4 = this;

      return _react2.default.createElement(
        'div',
        { style: { display: 'flex' } },
        _react2.default.createElement(
          'div',
          { style: { margin: '100px' } },
          _react2.default.createElement(
            'p',
            { className: styles.title },
            (0, _translations2.default)('CHANGE PASSWORD'),
            ' '
          ),
          _react2.default.createElement(
            'div',
            { className: styles.formgroup },
            _react2.default.createElement(
              'p',
              { className: styles.password },
              _react2.default.createElement('input', { type: 'password', ref: 'password', placeholder: "Nueva Contraseña", onChange: function onChange(e) {
                  return _this4.validatePass(e);
                } })
            ),
            this.state.recovering ? null : _react2.default.createElement(
              'p',
              { className: styles.password },
              _react2.default.createElement('input', { type: 'password', ref: 'password2', placeholder: "Vuelva a Escribir la Contraseña" })
            )
          ),
          _react2.default.createElement(
            'p',
            null,
            _react2.default.createElement(
              'button',
              { className: styles.button, onClick: this.handleSubmit },
              "Guardar",
              ' '
            )
          )
        ),
        _react2.default.createElement('div', { style: { border: 'solid 1px #DDD' } }),
        _react2.default.createElement(
          'div',
          { style: { margin: '55px 0px 0px 100px' } },
          this.renderMessage()
        )
      );
    }
  }, {
    key: 'renderLoading',
    value: function renderLoading(styles) {
      return _react2.default.createElement(
        'div',
        { className: styles.loading },
        _react2.default.createElement('div', { className: styles.title }),
        _react2.default.createElement('div', { className: styles.input }),
        _react2.default.createElement('div', { className: styles.input }),
        _react2.default.createElement('div', { className: styles.button }),
        _react2.default.createElement('div', { className: styles.recovery })
      );
    }
  }, {
    key: 'renderMessage',
    value: function renderMessage() {
      var valid = _react2.default.createElement(md.checkCircle, { size: 20, color: '#1DB176' });
      var notValid = _react2.default.createElement(md.checkCircle, { size: 20, color: '#B9B9B9' });

      return _react2.default.createElement(
        'div',
        { className: _Login2.default.message },
        _react2.default.createElement(
          'h3',
          { style: { margin: '0px 150px 0px 10px', textAlign: 'left' } },
          'La contrase\xF1a debe contener al menos:'
        ),
        _react2.default.createElement(
          'div',
          { className: _Login2.default.validation },
          this.state.hasLenght ? valid : notValid,
          ' 15 Caracteres '
        ),
        _react2.default.createElement(
          'div',
          { className: _Login2.default.validation },
          this.state.hasLowerCase ? valid : notValid,
          ' una min\xFAscula '
        ),
        _react2.default.createElement(
          'div',
          { className: _Login2.default.validation },
          this.state.hasUpperCase ? valid : notValid,
          ' una may\xFAscula '
        ),
        _react2.default.createElement(
          'div',
          { className: _Login2.default.validation },
          this.state.hasNumber ? valid : notValid,
          ' un n\xFAmero '
        ),
        _react2.default.createElement(
          'div',
          { className: _Login2.default.validation },
          this.state.hasSpecialChar ? valid : notValid,
          ' un s\xEDmbolo '
        )
      );
    }
  }, {
    key: 'render',
    value: function render() {
      var _props = this.props,
          auth = _props.auth,
          data = _props.auth.data;

      var logo = '/static/logo.png';

      return _react2.default.createElement(
        'div',
        null,
        _react2.default.createElement(_reactHelmet2.default, { title: 'Login' }),
        _react2.default.createElement(
          'div',
          { className: _Login2.default.form },
          this.state.loading ? this.renderLoading(_Login2.default) : this.renderForm(_Login2.default)
        )
      );
    }
  }]);
  return VerifyPassword;
}(_react2.default.Component), _class2.propTypes = {
  auth: _propTypes2.default.object,
  isMobile: _propTypes2.default.bool,
  passwordRecover: _propTypes2.default.func,
  logout: _propTypes2.default.func,
  pushState: _propTypes2.default.func
}, _temp)) || _class);
exports.default = VerifyPassword;
module.exports = exports['default'];