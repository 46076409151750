'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = undefined;

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _CustomWizard = require('./CustomWizard.scss');

var _CustomWizard2 = _interopRequireDefault(_CustomWizard);

var _components = require('components');

var _reactHelmet = require('react-helmet');

var _reactHelmet2 = _interopRequireDefault(_reactHelmet);

var _NewTemplate = require('../../containers/Email/New/NewTemplate');

var _styles = require('@material-ui/core/styles');

var _Snackbar = require('@material-ui/core/Snackbar');

var _Snackbar2 = _interopRequireDefault(_Snackbar);

var _IconButton = require('@material-ui/core/IconButton');

var _IconButton2 = _interopRequireDefault(_IconButton);

var _Close = require('@material-ui/icons/Close');

var _Close2 = _interopRequireDefault(_Close);

var _Warning = require('@material-ui/icons/Warning');

var _Warning2 = _interopRequireDefault(_Warning);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var md = {
    build: require('react-icons/lib/md/navigate-next')
};

var CustomWizard = function (_React$Component) {
    (0, _inherits3.default)(CustomWizard, _React$Component);

    function CustomWizard(props) {
        (0, _classCallCheck3.default)(this, CustomWizard);

        var _this = (0, _possibleConstructorReturn3.default)(this, (CustomWizard.__proto__ || (0, _getPrototypeOf2.default)(CustomWizard)).call(this, props));

        _this.setDataToCreate = function (data) {
            _this.setState({ data: data });
        };

        _this.handleClose = function (event, reason) {
            if (reason === 'clickaway') {
                return;
            }

            _this.setState({ open: false });
        };

        _this.nextStep = function (index) {
            if (_this.props.completeComponent) {
                var _this$state = _this.state,
                    options = _this$state.options,
                    lastOne = _this$state.lastOne;

                var tabIndexSelected = index + 1;
                var nameComponent = _this.state.options[tabIndexSelected];
                _this.props.selectedComponent(nameComponent);
                lastOne = tabIndexSelected == options.length - 1 ? true : false;
                _this.setState({ tabIndexSelected: tabIndexSelected, lastOne: lastOne });
                _this.props.completeComponentFunction(false);
                if (_this.state.options[index] === 'Layouts') {
                    _this.props.createTemplate();
                }
            } else {
                console.log('completa todos los campos para continuar');
                _this.setState({ open: true });
            }
        };

        _this.prevStep = function (index) {
            var lastOne = _this.state.lastOne;

            var tabIndexSelected = index - 1;
            var nameComponent = _this.state.options[tabIndexSelected];
            _this.props.selectedComponent(nameComponent);
            lastOne = false;
            _this.setState({ tabIndexSelected: tabIndexSelected, lastOne: lastOne });
        };

        _this.getDataFromChildren = function (data) {
            _this.props.setData(data);
        };

        _this.state = {
            options: _this.props.options,
            tabIndexSelected: 0,
            lastOne: false,
            selectedComponent: _this.props.selectedComponent(_this.props.componentSelected),
            gridColumnsTemplates: '',
            data: null,
            componentSelected: _this.props.componentSelected,
            open: false
        };

        return _this;
    }

    (0, _createClass3.default)(CustomWizard, [{
        key: 'componentDidMount',
        value: function componentDidMount() {
            var gridColumnsTemplates = 'auto';
            for (var i = 0; i < this.state.options.length; i++) {
                gridColumnsTemplates += ' auto';
            }

            this.setState({ gridColumnsTemplates: gridColumnsTemplates });
        }
    }, {
        key: 'componentWillReceiveProps',
        value: function componentWillReceiveProps(nexProps) {
            if (nexProps.componentSelected && nexProps.componentSelected !== this.state.componentSelected) {
                var index = nexProps.options.indexOf(nexProps.componentSelected);
                this.setState({ tabIndexSelected: index });
            }
        }
    }, {
        key: 'renderOptions',
        value: function renderOptions() {
            var _this2 = this;

            return this.state.options.map(function (title, index) {
                if (_this2.state.tabIndexSelected > index) {
                    return _react2.default.createElement(
                        'li',
                        null,
                        _react2.default.createElement(
                            'div',
                            { className: _CustomWizard2.default.badgeSuccess },
                            _react2.default.createElement(
                                'div',
                                { className: _CustomWizard2.default.badge },
                                '\u2713'
                            )
                        ),
                        _react2.default.createElement('div', { className: _CustomWizard2.default.lineSuccess })
                    );
                } else {
                    if (_this2.state.tabIndexSelected === index) {
                        if (index === _this2.state.options.length - 1) {
                            return _react2.default.createElement(
                                'li',
                                null,
                                _react2.default.createElement(
                                    'div',
                                    { className: _CustomWizard2.default.badgeOutside },
                                    _react2.default.createElement(
                                        'div',
                                        { className: _CustomWizard2.default.badge },
                                        index + 1
                                    )
                                ),
                                _react2.default.createElement(
                                    'span',
                                    null,
                                    title
                                )
                            );
                        } else {
                            return _react2.default.createElement(
                                'li',
                                null,
                                _react2.default.createElement(
                                    'div',
                                    { className: _CustomWizard2.default.badgeOutside },
                                    _react2.default.createElement(
                                        'div',
                                        { className: _CustomWizard2.default.badge },
                                        index + 1
                                    )
                                ),
                                _react2.default.createElement('div', { className: _CustomWizard2.default.line }),
                                _react2.default.createElement(
                                    'span',
                                    null,
                                    title
                                )
                            );
                        }
                    } else {
                        //   console.log('conditinal wizard', (index === this.state.options.length - 1));
                        if (index === _this2.state.options.length - 1) {
                            return _react2.default.createElement(
                                'li',
                                null,
                                _react2.default.createElement('div', { className: _CustomWizard2.default.badgePending })
                            );
                        } else {
                            return _react2.default.createElement(
                                'li',
                                { className: _CustomWizard2.default.pending },
                                _react2.default.createElement('div', { className: _CustomWizard2.default.badgePending }),
                                _react2.default.createElement('div', { className: _CustomWizard2.default.linePending })
                            );
                        }
                    }
                }
            });
        }
    }, {
        key: 'render',
        value: function render() {
            var _this3 = this;

            var tabIndexSelected = this.state.tabIndexSelected;
            var lastOne = this.state.lastOne;
            var children = _react2.default.Children.map(this.props.children[this.state.tabIndexSelected][this.props.componentSelected], function (child) {
                if (child) {
                    return _react2.default.cloneElement(child, {
                        handleDataTwo: _this3.getDataFromChildren
                    });
                }
            });
            var mainContainer = {
                float: 'left',
                height: '100%',
                width: '100%',
                background: '#fff'
            };

            return _react2.default.createElement(
                'div',
                { className: _CustomWizard2.default.mainContainer },
                _react2.default.createElement(_reactHelmet2.default, { title: "Envio por correo" }),
                _react2.default.createElement(_Snackbar2.default, {
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left'
                    },
                    open: this.state.open,
                    autoHideDuration: 1000,
                    onClose: this.handleClose,
                    message: _react2.default.createElement(
                        'span',
                        { id: 'message-id' },
                        'Completa todos los campos para continuar'
                    ),
                    action: [_react2.default.createElement(
                        _IconButton2.default,
                        {
                            key: 'close',
                            'aria-label': 'Close',
                            color: 'inherit',
                            onClick: this.handleClose
                        },
                        _react2.default.createElement(_Close2.default, null)
                    )]
                }),
                _react2.default.createElement(
                    'div',
                    { className: _CustomWizard2.default.progressBar },
                    _react2.default.createElement(
                        'ul',
                        null,
                        this.renderOptions()
                    )
                ),
                _react2.default.createElement(
                    'div',
                    { style: mainContainer },
                    this.props.children[this.state.tabIndexSelected][this.props.componentSelected]
                ),
                _react2.default.createElement(
                    'div',
                    { className: _CustomWizard2.default.footer },
                    tabIndexSelected >= 1 ? _react2.default.createElement(_components.Button, { className: _CustomWizard2.default.left, color: 'blue',
                        name: 'Regresar', onClick: this.prevStep.bind(this, tabIndexSelected) }) : _react2.default.createElement('div', null),
                    tabIndexSelected >= 0 ? _react2.default.createElement(_components.Button, { className: _CustomWizard2.default.right, color: 'blue',
                        name: 'Siguiente', onClick: function onClick() {
                            return _this3.nextStep(tabIndexSelected);
                        } }) : _react2.default.createElement('div', null)
                )
            );
        }
    }]);
    return CustomWizard;
}(_react2.default.Component);

exports.default = CustomWizard;
module.exports = exports['default'];