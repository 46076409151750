'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = undefined;

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _NewTemplate = require('./NewTemplate.scss');

var _NewTemplate2 = _interopRequireDefault(_NewTemplate);

var _Default = require('../Templates/Default/Default');

var _Default2 = _interopRequireDefault(_Default);

var _Custom = require('../Templates/Custom/Custom');

var _Custom2 = _interopRequireDefault(_Custom);

var _TwoColumn = require('../Templates/TwoColumn/TwoColumn');

var _TwoColumn2 = _interopRequireDefault(_TwoColumn);

var _ThreeColumn = require('../Templates/ThreeColumn/ThreeColumn');

var _ThreeColumn2 = _interopRequireDefault(_ThreeColumn);

var _ListTemplates = require('../ListTemplates/ListTemplates');

var _ListTemplates2 = _interopRequireDefault(_ListTemplates);

var _useBasename = require('../../../helpers/useBasename');

var _useBasename2 = _interopRequireDefault(_useBasename);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var md = {
    close: require('react-icons/lib/md/clear')
};

var NewTemplate = function (_React$Component) {
    (0, _inherits3.default)(NewTemplate, _React$Component);

    function NewTemplate(props) {
        (0, _classCallCheck3.default)(this, NewTemplate);

        var _this = (0, _possibleConstructorReturn3.default)(this, (NewTemplate.__proto__ || (0, _getPrototypeOf2.default)(NewTemplate)).call(this, props));

        _this.clickEvent = function (name) {
            _this.props.selectLayout(name);
            _this.props.changeStatusShowLayout();
            _this.setState({ new: true });
            _this.forceUpdate();
        };

        _this.renderForStatusLayout = function () {
            if (!_this.props.showLayout) {
                return _react2.default.createElement(
                    'div',
                    { className: _NewTemplate2.default.main },
                    _react2.default.createElement(
                        'div',
                        { className: _NewTemplate2.default.svgClass, onClick: function onClick() {
                                return _this.clickEvent('default');
                            } },
                        _react2.default.createElement('img', { src: (0, _useBasename2.default)('/static/oneColumn.svg') }),
                        _react2.default.createElement(
                            'div',
                            { className: _NewTemplate2.default.chip },
                            'Basica'
                        )
                    ),
                    _react2.default.createElement(
                        'div',
                        { className: _NewTemplate2.default.svgClass, onClick: function onClick() {
                                return _this.clickEvent('twoColumn');
                            } },
                        _react2.default.createElement('img', { src: (0, _useBasename2.default)('/static/twoColumn.svg') }),
                        _react2.default.createElement(
                            'div',
                            { className: _NewTemplate2.default.chip },
                            '2 Columnas'
                        )
                    ),
                    _react2.default.createElement(
                        'div',
                        { className: _NewTemplate2.default.svgClass, onClick: function onClick() {
                                return _this.clickEvent('threeColumn');
                            } },
                        _react2.default.createElement('img', { src: (0, _useBasename2.default)('/static/threeColumn.svg') }),
                        _react2.default.createElement(
                            'div',
                            { className: _NewTemplate2.default.chip },
                            '3 Columnas'
                        )
                    ),
                    _react2.default.createElement(
                        'div',
                        { className: _NewTemplate2.default.svgClass, onClick: function onClick() {
                                return _this.clickEvent('custom');
                            } },
                        _react2.default.createElement('img', { src: (0, _useBasename2.default)('/static/blankColumn.svg') }),
                        _react2.default.createElement(
                            'div',
                            { className: _NewTemplate2.default.chip },
                            'Personalizado'
                        )
                    )
                );
            } else {
                return _react2.default.createElement(
                    'div',
                    { className: _NewTemplate2.default.mainEditor },
                    _this.returnComponentToRender(_this.props.layoutSelected)
                );
            }
        };

        _this.returnComponentToRender = function (layouSelected) {
            switch (layouSelected) {
                case 'default':
                    _this.props.setLayoutUid('5d5c760be9d2e2e930e07754');
                    return _react2.default.createElement(_Default2.default, { accountUI: _this.props.accountUI,
                        completeComponentFunction: _this.props.completeComponentFunction,
                        setData: _this.props.setData,
                        account_uid: _this.props.account_uid,
                        setDataToSend: _this.props.setDataToSend,
                        duplicate: _this.props.duplicate,
                        handleDataTwo: _this.props.setData,
                        draggedFileData: _this.props.draggedFileData });
                case 'custom':
                    _this.props.setLayoutUid('5d75636020a93d0c61d7dd55');
                    _this.state.margen = true;
                    return _react2.default.createElement(_Custom2.default, { accountUI: _this.props.accountUI,
                        setData: _this.props.setData,
                        duplicate: _this.props.duplicate,
                        completeComponentFunction: _this.props.completeComponentFunction,
                        account_uid: _this.props.account_uid,
                        setDataToSend: _this.props.setDataToSend,
                        handleDataTwo: _this.props.setData,
                        draggedFileData: _this.props.draggedFileData });
                case 'twoColumn':
                    _this.props.setLayoutUid('5d759d2cd4ef2344d2fe3d99');
                    return _react2.default.createElement(_TwoColumn2.default, { accountUI: _this.props.accountUI,
                        completeComponentFunction: _this.props.completeComponentFunction,
                        setData: _this.props.setData,
                        duplicate: _this.props.duplicate,
                        account_uid: _this.props.account_uid,
                        setDataToSend: _this.props.setDataToSend,
                        handleDataTwo: _this.props.setData,
                        draggedFileData: _this.props.draggedFileData });
                case 'threeColumn':
                    _this.props.setLayoutUid('5d759d59d4ef2344d2fe3d9a');
                    return _react2.default.createElement(_ThreeColumn2.default, { accountUI: _this.props.accountUI,
                        completeComponentFunction: _this.props.completeComponentFunction,
                        setData: _this.props.setData,
                        duplicate: _this.props.duplicate,
                        account_uid: _this.props.account_uid,
                        setDataToSend: _this.props.setDataToSend,
                        handleDataTwo: _this.props.setData,
                        draggedFileData: _this.props.draggedFileData });
                case 'back':

                    return _react2.default.createElement(_ListTemplates2.default, null);
            }
        };

        _this.renderHeader = function () {
            return _react2.default.createElement(
                'div',
                { className: _NewTemplate2.default.headerNclose },
                _react2.default.createElement(
                    'div',
                    { className: _NewTemplate2.default.headerTitle },
                    'Seleccione el dise\xF1o de la nueva plantilla'
                ),
                _react2.default.createElement(
                    'div',
                    { className: _NewTemplate2.default.close, onClick: function onClick() {
                            return _this.Back();
                        } },
                    _react2.default.createElement(md.close, { size: 45 })
                )
            );
        };

        _this.state = {
            new: false,
            layouts: [{ name: 'default', id: '5d5c760be9d2e2e930e07754' }, { name: 'custom', id: '5d75636020a93d0c61d7dd55' }, { name: 'twoColumn', id: '5d759d2cd4ef2344d2fe3d99' }, { name: 'threeColumn', id: '5d759d59d4ef2344d2fe3d9a' }, { name: 'back', id: null }],
            layoutSelected: null,
            margen: false
        };
        return _this;
    }

    (0, _createClass3.default)(NewTemplate, [{
        key: 'componentDidMount',
        value: function componentDidMount() {
            var _this2 = this;

            if (this.props.duplicate && this.props.duplicate !== null) {
                var layout = this.state.layouts.find(function (element) {
                    return element.id === _this2.props.duplicate.layout_uid;
                });
                this.clickEvent(layout.name);
            }
        }
    }, {
        key: 'Back',
        value: function Back() {
            window.location.reload();
        }
    }, {
        key: 'render',
        value: function render() {
            return [_react2.default.createElement(
                'div',
                { className: this.state.new ? _NewTemplate2.default.newOne : _NewTemplate2.default.mainAction },
                this.state.new ? _react2.default.createElement('div', null) : this.renderHeader(),
                this.renderForStatusLayout()
            )];
        }
    }]);
    return NewTemplate;
}(_react2.default.Component);

exports.default = NewTemplate;
module.exports = exports['default'];