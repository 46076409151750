'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = numberFormat;

var _numbro = require('numbro');

var _numbro2 = _interopRequireDefault(_numbro);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function numberFormat(number) {
  var mantissa = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;

  var format = '0,0';
  for (var i = 0; i < mantissa; i += 1) {
    format += i === 0 ? '.0' : '0';
  }
  return (0, _numbro2.default)(number).format(format);
}
module.exports = exports['default'];