'use strict';

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _reactRouter = require('react-router');

var _ApiClient = require('./helpers/ApiClient');

var _ApiClient2 = _interopRequireDefault(_ApiClient);

var _reactHotLoader = require('react-hot-loader');

var _reactRedux = require('react-redux');

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactDom = require('react-dom');

var _reactDom2 = _interopRequireDefault(_reactDom);

var _reactGa = require('react-ga4');

var _reactGa2 = _interopRequireDefault(_reactGa);

var _reduxConnect = require('redux-connect');

var _reachHistory = require('./helpers/reachHistory');

var _config = require('./config');

var _config2 = _interopRequireDefault(_config);

var _history = require('history');

var _clientStore = require('./helpers/clientStore.js');

var _clientStore2 = _interopRequireDefault(_clientStore);

var _routes = require('./routes');

var _routes2 = _interopRequireDefault(_routes);

var _reactDeviceDetect = require('react-device-detect');

var _reactRouterRedux = require('react-router-redux');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

_reactGa2.default.initialize(_config2.default.analytics);
// move this to a better place

// import 'babel-polyfill'
/* eslint react/jsx-filename-extension:0 */

function logPageView() {
  _reactGa2.default.send({
    hitType: "pageview",
    page: window.location.pathname + window.location.search,
    title: window.location.pathname + window.location.search
  });
}

var client = new _ApiClient2.default(_config2.default.basename + '/api');

/* const browserHistory = useRouterHistory(createHistory)({
  basename: config.basename,  
}); */
var dest = document.getElementById('content');

// eslint-disable-next-line no-underscore-dangle
//const store = createStore(browserHistory, client, window.__data)

var store = (0, _clientStore2.default)();
var history = (0, _reactRouterRedux.syncHistoryWithStore)(_reachHistory.browserHistory, store);

var assync = function assync(props) {
  return _react2.default.createElement(_reduxConnect.ReduxAsyncConnect, (0, _extends3.default)({}, props, {
    helpers: { client: client },
    filter: function filter(item) {
      return !item.deferred;
    }
  }));
};

var component = _react2.default.createElement(_reactRouter.Router, { render: assync, history: history, routes: (0, _routes2.default)(store), onUpdate: logPageView, basename: _config2.default.basename });

var render = function render() {
  _reactDom2.default.hydrate(_react2.default.createElement(
    _reactHotLoader.AppContainer,
    null,
    _react2.default.createElement(
      _reactRedux.Provider,
      { store: store, key: 'provider' },
      component
    )
  ), dest);
};

if (_reactDeviceDetect.isMobile && window.location.host === 'reach.tools') {
  window.location.replace('/v5');
} else {
  render();
}

/*
if (module.hot) {
  module.hot.accept('.', () => {
    render()
  });
  module.hot.accept('./routes.js', () => {
    render()
  });
  module.hot.accept();
} else {
}
*/

if (process.env.NODE_ENV !== 'production') {
  window.React = _react2.default;
}

if (process.env.NODE_ENV === 'production') {
  require('offline-plugin/runtime').install();
}