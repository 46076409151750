'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actions = undefined;
exports.default = reducer;
exports.createUser = createUser;
exports.getAccountUsers = getAccountUsers;
exports.deleteUsers = deleteUsers;
exports.usersRefresh = usersRefresh;
exports.usersUnselectAll = usersUnselectAll;
exports.userUnselect = userUnselect;
exports.userSelect = userSelect;
exports.updateUser = updateUser;
exports.updateUserRoles = updateUserRoles;

var _apiAction = require('../apiAction');

var _apiAction2 = _interopRequireDefault(_apiAction);

var _propTypes = require('prop-types');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// ACTIONS


var actions = exports.actions = new _apiAction2.default('USERS', [_apiAction.ACTION_TYPES.DATA, _apiAction.ACTION_TYPES.APPEND, _apiAction.ACTION_TYPES.TRIM, _apiAction.ACTION_TYPES.SELECT]);

// REDUCER


var initialState = {
  selected: [],
  loading: false,
  status: actions.APPEND,
  list: [],
  data: {}
};

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  return actions.getStateForAction(state, action);
}

function createUser(user, accountUid, permissions) {
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.post('/users', {
        data: {
          user: user,
          permissions: permissions
        },
        params: {
          account_uid: accountUid
        }
      });
    }
  };
}

function getAccountUsers() {
  return {
    types: [actions.APPEND, actions.APPEND_SUCCESS, actions.APPEND_FAIL],
    promise: function promise(client) {
      return client.get('/users/list/');
    }
  };
}

function deleteUsers(userId, accountUid) {
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.del('user/' + userId + '/' + accountUid);
    }
  };
}

function usersRefresh() {
  return {
    type: actions.TRIM,
    count: 0
  };
}

function usersUnselectAll() {
  return {
    type: actions.SELECT_NONE
  };
}

function userUnselect(index) {
  return {
    type: actions.SELECT_OFF,
    index: index
  };
}

function userSelect(index) {
  return {
    type: actions.SELECT,
    index: index
  };
}

function updateUser(userUid, data) {
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.put('user/' + userUid, { data: data });
    }
  };
}

function updateUserRoles(account_uid, user_uid, roles) {
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.put('user/permissions/' + account_uid + '/' + user_uid, {
        data: {
          roles: roles
        }
      });
    }
  };
}