'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Queued = exports.Fail = exports.Notice = exports.Save = exports.default = undefined;

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _class, _class2, _temp, _dec2, _class3, _class4, _temp2, _dec3, _class5, _class6, _temp3, _dec4, _class7, _class8, _temp4, _dec5, _class9, _class10, _temp5;

var _reactRouter = require('react-router');

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _reactRedux = require('react-redux');

var _reports = require('redux/modules/reports');

var _themeEnabled = require('helpers/themeEnabled');

var _reachHistory = require('../../helpers/reachHistory');

var _useBasename = require('../../helpers/useBasename');

var _useBasename2 = _interopRequireDefault(_useBasename);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var md = {
  save: require('react-icons/lib/md/save'),
  warning: require('react-icons/lib/md/warning'),
  actors: require('react-icons/lib/md/recent-actors'),
  email: require('react-icons/lib/md/email'),
  event: require('react-icons/lib/md/event-available')
};

var Success = (_dec = (0, _reactRedux.connect)(null, { push: _reachHistory.push }), _dec(_class = (_temp = _class2 = function (_React$Component) {
  (0, _inherits3.default)(Success, _React$Component);

  function Success(props) {
    (0, _classCallCheck3.default)(this, Success);

    var _this = (0, _possibleConstructorReturn3.default)(this, (Success.__proto__ || (0, _getPrototypeOf2.default)(Success)).call(this, props));

    _this.leave = null;
    return _this;
  }

  (0, _createClass3.default)(Success, [{
    key: 'componentWillMount',
    value: function componentWillMount() {
      var _this2 = this;

      this.leave = setTimeout(function () {
        return _this2.props.push({
          pathname: _this2.props.location.state.goTo,
          query: _this2.props.location.state.goToQuery || {},
          state: { modal: _this2.props.location.state.goToModal, returnTo: _this2.props.location.state.returnTo }
        });
      }, 1300);
    }
  }, {
    key: 'componentWillUnmount',
    value: function componentWillUnmount() {
      clearTimeout(this.leave);
    }
  }, {
    key: 'render',
    value: function render() {
      var location = this.props.location;


      var styles = require('./Success.scss');
      var classesTheme = (0, _themeEnabled.getThemeEnabledStyle)(styles);

      return _react2.default.createElement(
        'div',
        { className: classesTheme },
        _react2.default.createElement(
          'div',
          { className: styles.success },
          _react2.default.createElement(
            'div',
            { className: styles.container },
            _react2.default.createElement('img', { className: styles.check, src: (0, _useBasename2.default)('/static/check.svg'), alt: '' }),
            _react2.default.createElement(
              'h1',
              { className: styles.message },
              location.state ? location.state.message : ''
            )
          )
        )
      );
    }
  }]);
  return Success;
}(_react2.default.Component), _class2.propTypes = {
  location: _propTypes2.default.object.isRequired,
  push: _propTypes2.default.func.isRequired
}, _temp)) || _class);
exports.default = Success;
var Save = exports.Save = (_dec2 = (0, _reactRedux.connect)(null, { push: _reachHistory.push }), _dec2(_class3 = (_temp2 = _class4 = function (_React$Component2) {
  (0, _inherits3.default)(Save, _React$Component2);

  function Save(props) {
    (0, _classCallCheck3.default)(this, Save);

    var _this3 = (0, _possibleConstructorReturn3.default)(this, (Save.__proto__ || (0, _getPrototypeOf2.default)(Save)).call(this, props));

    _this3.leave = null;
    return _this3;
  }

  (0, _createClass3.default)(Save, [{
    key: 'componentWillMount',
    value: function componentWillMount() {
      var _this4 = this;

      this.leave = setTimeout(function () {
        return _this4.props.push({
          pathname: _this4.props.location.state.goTo,
          query: _this4.props.location.state.goToQuery || {},
          state: { modal: _this4.props.location.state.goToModal, returnTo: _this4.props.location.state.returnTo }
        });
      }, 5000);
    }
  }, {
    key: 'componentWillUnmount',
    value: function componentWillUnmount() {
      clearTimeout(this.leave);
    }
  }, {
    key: 'render',
    value: function render() {
      var location = this.props.location;


      var styles = require('./Success.scss');
      return _react2.default.createElement(
        'div',
        { className: styles.save },
        _react2.default.createElement(
          'div',
          { className: styles.container },
          _react2.default.createElement(md.save, { size: 120, color: '#FFF' }),
          _react2.default.createElement(
            'h1',
            { className: styles.message },
            location.state ? location.state.message : ''
          )
        )
      );
    }
  }]);
  return Save;
}(_react2.default.Component), _class4.propTypes = {
  location: _propTypes2.default.object.isRequired,
  push: _propTypes2.default.func.isRequired
}, _temp2)) || _class3);
var Notice = exports.Notice = (_dec3 = (0, _reactRedux.connect)(null, { push: _reachHistory.push, getBalance: _reports.getBalance }), _dec3(_class5 = (_temp3 = _class6 = function (_React$Component3) {
  (0, _inherits3.default)(Notice, _React$Component3);

  function Notice(props) {
    (0, _classCallCheck3.default)(this, Notice);

    var _this5 = (0, _possibleConstructorReturn3.default)(this, (Notice.__proto__ || (0, _getPrototypeOf2.default)(Notice)).call(this, props));

    _this5.state = {
      percent: 0,
      image: _this5.props.location.state.image
    };
    return _this5;
  }

  (0, _createClass3.default)(Notice, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      this.getPercent();
    }
  }, {
    key: 'getPercent',
    value: function getPercent() {
      var _this6 = this;

      var percent = 0;
      var spent = 0;
      var total = 100;
      var size = this.props.location.state.messages;
      if (size > 0) {
        this.props.getBalance().then(function (balance) {
          if (typeof balance.current_package.balance !== 'undefined') {
            total = balance.current_package.credit_base_limit + balance.current_package.credit_extra_limit;
            spent = (balance.current_package.balance / total * 100).toFixed(2);
            percent = (size / balance.current_package.balance * 100).toFixed(2);
          }
          _this6.setState({
            percent: isNaN(percent) ? 0 : percent,
            spent: spent,
            image: _this6.props.location.state.image
          });
        });
      }
    }
  }, {
    key: 'render',
    value: function render() {
      var styles = require('./Success.scss');
      return _react2.default.createElement(
        'div',
        { className: styles.notice },
        _react2.default.createElement('img', { src: this.props.location.state.image, className: styles.noticeImage }),
        _react2.default.createElement(
          'div',
          { className: styles.text },
          _react2.default.createElement(
            'div',
            { className: styles.title },
            (0, _translations2.default)('NOTICE SENDING'),
            ' ',
            _react2.default.createElement(
              'div',
              { className: styles.lowercase },
              ' ',
              this.props.location.state.type,
              ' ',
              (0, _translations2.default)('NOTICE WILL CONSUME')
            )
          ),
          _react2.default.createElement(
            'div',
            { className: styles.subtitle },
            this.state.percent === 0 ? (0, _translations2.default)('NOTICE SEGMENT MESSAGE') : (0, _translations2.default)('NOTICE QUANTITY') + this.props.location.state.messages + (0, _translations2.default)('NOTICE MESSAGES'),
            this.state.percent === 0 ? '' : _react2.default.createElement(
              'div',
              { className: styles.minigauge, style: { background: "rgba(253, 86, 33, 0.3)" } },
              _react2.default.createElement(
                'div',
                { className: styles.inner, style: { background: "#FD5621", width: this.state.spent + '%' } },
                _react2.default.createElement('div', { className: styles.inner2, style: { background: "#4CAF50", width: this.state.spent - this.state.percent + '%' } })
              )
            ),
            _react2.default.createElement(
              'div',
              { className: styles.smallText },
              this.state.percent === 0 ? '' : (0, _translations2.default)('NOTICE REPRESENT') + this.state.percent + (0, _translations2.default)('NOTICE PERCENT')
            )
          ),
          _react2.default.createElement(
            'div',
            { className: styles.buttons },
            _react2.default.createElement(
              _reactRouter.Link,
              { className: styles.clean,
                onClick: this.props.router.goBack
              },
              (0, _translations2.default)('NOTICE MODIFY')
            ),
            _react2.default.createElement(
              'div',
              { onClick: this.props.location.state.ok, className: styles.buttonModal },
              (0, _translations2.default)('NOTICE OK')
            )
          )
        )
      );
    }
  }]);
  return Notice;
}(_react2.default.Component), _class6.propTypes = {
  location: _propTypes2.default.object.isRequired,
  push: _propTypes2.default.func.isRequired
}, _temp3)) || _class5);
var Fail = exports.Fail = (_dec4 = (0, _reactRedux.connect)(null, { push: _reachHistory.push }), _dec4(_class7 = (_temp4 = _class8 = function (_React$Component4) {
  (0, _inherits3.default)(Fail, _React$Component4);

  function Fail(props) {
    (0, _classCallCheck3.default)(this, Fail);

    var _this7 = (0, _possibleConstructorReturn3.default)(this, (Fail.__proto__ || (0, _getPrototypeOf2.default)(Fail)).call(this, props));

    _this7.leave = null;
    return _this7;
  }

  (0, _createClass3.default)(Fail, [{
    key: 'componentWillMount',
    value: function componentWillMount() {
      var _this8 = this;

      this.leave = setTimeout(function () {
        return _this8.props.push({
          pathname: _this8.props.location.state.goTo,
          query: _this8.props.location.state.goToQuery || {},
          state: { modal: _this8.props.location.state.goToModal, returnTo: _this8.props.location.state.returnTo }
        });
      }, 5000);
    }
  }, {
    key: 'componentWillUnmount',
    value: function componentWillUnmount() {
      clearTimeout(this.leave);
    }
  }, {
    key: 'render',
    value: function render() {
      var location = this.props.location;


      var styles = require('./Success.scss');
      var classesTheme = (0, _themeEnabled.getThemeEnabledStyle)(styles);

      return _react2.default.createElement(
        'div',
        { className: classesTheme },
        _react2.default.createElement(
          'div',
          { className: styles.fail },
          _react2.default.createElement(
            'div',
            { className: styles.container },
            _react2.default.createElement(md.warning, { size: 120, color: '#FFF' }),
            _react2.default.createElement(
              'h1',
              { className: styles.message },
              location.state ? location.state.message : ''
            )
          )
        )
      );
    }
  }]);
  return Fail;
}(_react2.default.Component), _class8.propTypes = {
  location: _propTypes2.default.object.isRequired,
  push: _propTypes2.default.func.isRequired
}, _temp4)) || _class7);
var Queued = exports.Queued = (_dec5 = (0, _reactRedux.connect)(null, { push: _reachHistory.push }), _dec5(_class9 = (_temp5 = _class10 = function (_React$Component5) {
  (0, _inherits3.default)(Queued, _React$Component5);

  function Queued(props) {
    (0, _classCallCheck3.default)(this, Queued);

    var _this9 = (0, _possibleConstructorReturn3.default)(this, (Queued.__proto__ || (0, _getPrototypeOf2.default)(Queued)).call(this, props));

    _this9.leave = null;
    return _this9;
  }

  (0, _createClass3.default)(Queued, [{
    key: 'render',
    value: function render() {
      var location = this.props.location;


      var styles = require('./Success.scss');
      return _react2.default.createElement(
        'div',
        { className: styles.queued },
        _react2.default.createElement(
          'div',
          { className: styles.container },
          location.state && location.state.icon === 'CALENDAR' ? _react2.default.createElement(md.event, { size: 130, color: '#FFF' }) : _react2.default.createElement(md.actors, { size: 130, color: '#FFF' }),
          _react2.default.createElement(
            'h1',
            { className: styles.message },
            location.state ? location.state.message : ''
          )
        )
      );
    }
  }]);
  return Queued;
}(_react2.default.Component), _class10.propTypes = {
  location: _propTypes2.default.object.isRequired,
  push: _propTypes2.default.func.isRequired
}, _temp5)) || _class9);