'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actions = exports.reportTypes = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

exports.reportsReducer = reportsReducer;
exports.getAllBalanceByDate = getAllBalanceByDate;
exports.getTrafficKPIs = getTrafficKPIs;
exports.getBalance = getBalance;
exports.getBalanceByDate = getBalanceByDate;
exports.getTotalContacts = getTotalContacts;
exports.getContactsCancelled = getContactsCancelled;
exports.getContactsCancelledCount = getContactsCancelledCount;
exports.getReachStats = getReachStats;
exports.getResellerBalanceReport = getResellerBalanceReport;
exports.clearReportsData = clearReportsData;
exports.getTrafficDaily = getTrafficDaily;
exports.getHarassmentTraffic = getHarassmentTraffic;
exports.getTrafficByUser = getTrafficByUser;
exports.getBalanceHistory = getBalanceHistory;
exports.sendTransactionReportRequest = sendTransactionReportRequest;
exports.sendTransactionReportLogRequest = sendTransactionReportLogRequest;

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _apiAction = require('../apiAction');

var _apiAction2 = _interopRequireDefault(_apiAction);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var reportTypes = exports.reportTypes = {
  CAMPAIGN: 'campaigns',
  PROMOTION: 'promotion'

  // ACTIONS

};var actions = exports.actions = new _apiAction2.default('REPORTS', [_apiAction.ACTION_TYPES.DATA, _apiAction.ACTION_TYPES.APPEND, _apiAction.ACTION_TYPES.TRIM]);

// REDUCER


var initialState = {
  loading: false,
  status: actions.DATA,
  data: {},
  list: []
};

function reportsReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  return actions.getStateForAction(state, action);
}

// ACTION CREATORS

function getAllBalanceByDate(params) {
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.get('/account/router/balances', { params: params });
    }
  };
}

function getTrafficKPIs(startDate, endDate) {
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.get('/reports/traffic/kpis', {
        params: {
          from: startDate.valueOf(),
          to: endDate.valueOf()
        }
      });
    }
  };
}

function getBalance() {
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.get('/account/status');
    }
  };
}

function getBalanceByDate(query_date) {
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.get('account/balance', {
        params: {
          cycle_date: query_date
        }
      });
    }
  };
}

function getTotalContacts() {
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.get('/contacts/count/active');
    }
  };
}

function getContactsCancelled(startDate, endDate) {
  return {
    types: [actions.APPEND, actions.APPEND_SUCCESS, actions.APPEND_FAIL],
    promise: function promise(client) {
      return client.get('/cancelled_profiles/', {
        params: {
          from: startDate.format(),
          to: endDate.format()
        }
      });
    }
  };
}

function getContactsCancelledCount(startDate, endDate) {
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.get('/cancelled_profiles/count', {
        params: {
          from: startDate.format(),
          to: endDate.format()
        }
      });
    }
  };
}

function getReachStats(startDate, endDate) {
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.get('/reports/reach/kpis', {
        params: {
          frame: 'DAILY',
          from: startDate.format(),
          to: endDate.format()
        }
      });
    }
  };
}

function getResellerBalanceReport(startDate, endDate) {
  return {
    types: [actions.APPEND, actions.APPEND_SUCCESS, actions.APPEND_FAIL],
    promise: function promise(client) {
      return client.get('/reports/subaccounts_use_per_date', {
        params: {
          from: startDate.format(),
          to: endDate.format()
        }
      });
    }
  };
}

function clearReportsData() {
  return {
    type: actions.TRIM,
    count: 0
  };
}

function getTrafficDaily(startDate, endDate) {
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.get('/reports/traffic/daily', {
        params: {
          from: startDate.valueOf(),
          to: endDate.valueOf()
        }
      });
    }
  };
}

function getHarassmentTraffic(params) {

  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.post('/reports/traffic/harassmentTraffic', {
        data: {
          account_type: params.account_type,
          from: params.valid_since.valueOf(),
          to: params.valid_thru.valueOf(),
          accounts: params.accounts,
          excluded: params.excluded,
          kpi_value: params.kpi_value
        }
      });
    }
  };
}

function getTrafficByUser(params) {

  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.get('/reports/traffic/byUser', {
        params: {
          from: params.valid_since.valueOf(),
          to: params.valid_thru.valueOf()
        }
      });
    }
  };
}

function getBalanceHistory(accountId) {
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.get('/backoffice/account/' + accountId + '/balance_history');
    }
  };
}

function sendTransactionReportRequest(startDate, endDate) {
  var tag = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
  var msisdn = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';
  var direction = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : '';
  var showErrors = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : false;
  var template_uid = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : '';
  var router_source_uid = arguments.length > 7 && arguments[7] !== undefined ? arguments[7] : '';
  var accounts = arguments.length > 8 && arguments[8] !== undefined ? arguments[8] : [];
  var type = arguments.length > 9 && arguments[9] !== undefined ? arguments[9] : '';
  var user = arguments.length > 10 && arguments[10] !== undefined ? arguments[10] : '';
  var createdBy = arguments.length > 11 && arguments[11] !== undefined ? arguments[11] : '';
  var source = arguments.length > 12 && arguments[12] !== undefined ? arguments[12] : '';

  var extraParams = {};
  if (tag) {
    extraParams.tag = tag;
  }
  if (msisdn) {
    extraParams.destination_identifier = msisdn;
  }
  if (direction) {
    extraParams.direction = direction;
  }

  extraParams.showErrors = showErrors;

  if (template_uid) {
    extraParams.template_uid = template_uid;
  }
  if (router_source_uid) {
    extraParams.router_source_uid = router_source_uid;
  }
  if (accounts.length > 0) {
    extraParams.accounts_uid = accounts;
  }
  if (type) {
    extraParams.type = type;
  }
  if (user) {
    extraParams.user_uid = user;
  }
  if (createdBy) {
    extraParams.created_by = createdBy;
  }
  if (source) {
    extraParams.source = source;
  }
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.post('/transactions/log', {
        data: (0, _extends3.default)({
          start_date: (0, _moment2.default)(startDate).utc().format(),
          end_date: (0, _moment2.default)(endDate).utc().format()
        }, extraParams)
      });
    }

  };
}

function sendTransactionReportLogRequest(startDate) {
  var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var type = arguments[2];

  var urltype = "";
  if (type != undefined) {
    urltype = '/' + type;
  }
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.post(urltype + '/transactions/log', {
        data: (0, _extends3.default)({
          start_date: (0, _moment2.default)(startDate).format("YYYY-MM-DD")
        }, params)
      });
    }
  };
}