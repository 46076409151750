'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});
exports.default = undefined;

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _components = require('components');

var _WhatsappSingle = require('./WhatsappSingle/WhatsappSingle');

var _WhatsappSingle2 = _interopRequireDefault(_WhatsappSingle);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = require('./Whatsapp.scss');

var Whatsapp = function (_Component) {
	(0, _inherits3.default)(Whatsapp, _Component);

	function Whatsapp(props) {
		(0, _classCallCheck3.default)(this, Whatsapp);

		var _this = (0, _possibleConstructorReturn3.default)(this, (Whatsapp.__proto__ || (0, _getPrototypeOf2.default)(Whatsapp)).call(this, props));

		_this.state = {};
		return _this;
	}

	(0, _createClass3.default)(Whatsapp, [{
		key: 'render',
		value: function render() {
			return _react2.default.createElement(
				'div',
				null,
				_react2.default.createElement(
					'p',
					{ style: { marginBottom: '4px', fontSize: "20px", marginTop: '0', color: styles.primary } },
					'Nuevo Mensaje'
				),
				_react2.default.createElement(
					'div',
					{ className: styles.container },
					_react2.default.createElement(_WhatsappSingle2.default, null)
				)
			);
		}
	}]);
	return Whatsapp;
}(_react.Component);

exports.default = Whatsapp;
module.exports = exports['default'];