'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actions = undefined;
exports.default = reducer;
exports.getContacts = getContacts;
exports.deleteContacts = deleteContacts;
exports.contactSelect = contactSelect;
exports.contactUnselect = contactUnselect;
exports.contactsUnselectAll = contactsUnselectAll;
exports.contactsTrim = contactsTrim;
exports.contactsRefresh = contactsRefresh;

var _apiAction = require('../apiAction');

var _apiAction2 = _interopRequireDefault(_apiAction);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// ACTIONS


var actions = exports.actions = new _apiAction2.default('CONTACTS', [_apiAction.ACTION_TYPES.DATA, _apiAction.ACTION_TYPES.TRIM, _apiAction.ACTION_TYPES.APPEND, _apiAction.ACTION_TYPES.SELECT]);

// REDUCER


var initialState = {
  loading: false,
  status: actions.APPEND,
  list: [],
  selected: []
};

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  return actions.getStateForAction(state, action);
}

// ACTION CREATORS


function getContacts(params) {
  return {
    types: [actions.APPEND, actions.APPEND_SUCCESS, actions.APPEND_FAIL],
    promise: function promise(client) {
      return client.get('/contacts', {
        params: params
      });
    }
  };
}

function deleteContacts(data) {
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.del('/contacts', {
        data: data
      });
    }
  };
}

function contactSelect(index) {
  return {
    type: actions.SELECT,
    index: index
  };
}

function contactUnselect(index) {
  return {
    type: actions.SELECT_OFF,
    index: index
  };
}

function contactsUnselectAll() {
  return {
    type: actions.SELECT_NONE
  };
}

function contactsTrim(count) {
  return {
    type: actions.TRIM,
    count: count
  };
}

function contactsRefresh() {
  return {
    type: actions.TRIM,
    count: 0
  };
}