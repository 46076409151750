'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.resultActions = exports.fetchActions = exports.messageTypes = undefined;
exports.campaignsReducer = campaignsReducer;
exports.campaignReducer = campaignReducer;
exports.getCampaigns = getCampaigns;
exports.campaignsRefresh = campaignsRefresh;
exports.getCampaignResults = getCampaignResults;

var _apiAction = require('../apiAction');

var _apiAction2 = _interopRequireDefault(_apiAction);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var messageTypes = exports.messageTypes = {
  FINISHED: 'Finished',
  ACTIVE: 'Active'

  // ACTIONS


};var fetchActions = exports.fetchActions = new _apiAction2.default('CAMPAIGNS', [_apiAction.ACTION_TYPES.DATA, _apiAction.ACTION_TYPES.APPEND, _apiAction.ACTION_TYPES.TRIM]);

var resultActions = exports.resultActions = new _apiAction2.default('CAMPAIGN', [_apiAction.ACTION_TYPES.DATA]);

// REDUCER


var initialState = function initialState(actions) {
  return {
    loading: false,
    status: actions.APPEND,
    list: [],
    data: {}
  };
};

function campaignsReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState(fetchActions);
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  return fetchActions.getStateForAction(state, action);
}

function campaignReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState(resultActions);
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  return resultActions.getStateForAction(state, action);
}

// ACTION CREATORS


function getCampaigns(params) {
  // statusList
  // status: statusList.join(','),
  var actions = fetchActions;
  return {
    types: [actions.APPEND, actions.APPEND_SUCCESS, actions.APPEND_FAIL],
    promise: function promise(client) {
      return client.get('/campaigns', {
        params: params
      });
    }
  };
}

function campaignsRefresh() {
  return {
    type: fetchActions.TRIM,
    count: 0
  };
}

function getCampaignResults(studyUid) {
  var actions = resultActions;
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.get('/campagins/' + studyUid + '/stats');
    }
  };
}