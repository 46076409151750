'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _class, _class2, _temp;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reduxConnect = require('redux-connect');

var _contact = require('redux/modules/contact');

var _components = require('components');

var _Chat = require('containers/Chat/Chat');

var _Chat2 = _interopRequireDefault(_Chat);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var MobileChat = (_dec = (0, _reduxConnect.asyncConnect)([{
  key: 'contact',
  promise: function promise(_ref) {
    var id = _ref.params.id,
        _ref$store = _ref.store,
        dispatch = _ref$store.dispatch,
        getState = _ref$store.getState;

    var state = getState();
    if (!state.contact || state.contact.id !== id) {
      return dispatch((0, _contact.getContactDetail)(id));
    }
    return state.contact;
  }
}]), _dec(_class = (_temp = _class2 = function (_React$Component) {
  (0, _inherits3.default)(MobileChat, _React$Component);

  function MobileChat(props) {
    (0, _classCallCheck3.default)(this, MobileChat);

    var _this = (0, _possibleConstructorReturn3.default)(this, (MobileChat.__proto__ || (0, _getPrototypeOf2.default)(MobileChat)).call(this, props));

    _this.styles = require('./Chat.scss');
    return _this;
  }

  (0, _createClass3.default)(MobileChat, [{
    key: 'render',
    value: function render() {
      var styles = this.styles;
      var contact = this.props.contact;
      var id = this.props.params.id;


      return _react2.default.createElement(
        'div',
        { className: styles.container },
        _react2.default.createElement(_components.MobileBar, { returnTo: '/contacts/' + id }),
        contact.loading ? 'LOADING...' : _react2.default.createElement(_Chat2.default, { contact: contact })
      );
    }
  }]);
  return MobileChat;
}(_react2.default.Component), _class2.propTypes = {
  params: _propTypes2.default.object.isRequired,
  contact: _propTypes2.default.object.isRequired
}, _temp)) || _class);
exports.default = MobileChat;
module.exports = exports['default'];