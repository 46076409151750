'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _featureEnabled = require('../../helpers/featureEnabled');

var _sessionPermissions = require('../../helpers/sessionPermissions');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Allow = function (_React$Component) {
  (0, _inherits3.default)(Allow, _React$Component);

  function Allow(props) {
    (0, _classCallCheck3.default)(this, Allow);

    var _this = (0, _possibleConstructorReturn3.default)(this, (Allow.__proto__ || (0, _getPrototypeOf2.default)(Allow)).call(this, props));

    _this.state = {
      permission: null,
      feature: null,
      allPermissions: null,
      allFeatures: null
    };
    return _this;
  }

  (0, _createClass3.default)(Allow, [{
    key: 'check',
    value: function check() {
      var permissions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      var features = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
      var allPermissions = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
      var allFeatures = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;

      var hasPermissions = true;
      var hasFeatureEnabled = true;

      hasPermissions = allPermissions ? permissions.every(_sessionPermissions.sessionPermissions) : permissions.some(_sessionPermissions.sessionPermissions);

      hasFeatureEnabled = allFeatures ? features.every(_featureEnabled.featureEnabled) : features.some(_featureEnabled.featureEnabled);

      var authorized = hasPermissions && hasFeatureEnabled;

      return authorized;
    }
  }, {
    key: 'render',
    value: function render() {
      if (this.check(this.props.permissions, this.props.features, this.props.allPermissions, this.props.allFeatures)) return this.props.children;else return this.props.else || null;
    }
  }]);
  return Allow;
}(_react2.default.Component);

exports.default = Allow;
module.exports = exports['default'];