'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _dec2, _class, _class2, _temp;
// import { getVariables, variablesSelect, variablesUnselect, variablesUnselectAll } from 'redux/modules/variables'


var _components = require('components');

var _campaigns = require('redux/modules/campaigns');

var _auth = require('redux/modules/auth');

var _Allow = require('../Allow/Allow');

var _Allow2 = _interopRequireDefault(_Allow);

var _Messages = require('../Messages/Messages');

var _reactHelmet = require('react-helmet');

var _reactHelmet2 = _interopRequireDefault(_reactHelmet);

var _reactRouter = require('react-router');

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _reduxConnect = require('redux-connect');

var _reactRedux = require('react-redux');

var _account = require('redux/modules/account');

var _themeEnabled = require('helpers/themeEnabled');

var _reachHistory = require('../../helpers/reachHistory');

var _sessionPermissions = require('../../helpers/sessionPermissions');

var _Campaigns = require('./Campaigns.scss');

var _Campaigns2 = _interopRequireDefault(_Campaigns);

var _useBasename = require('../../helpers/useBasename');

var _useBasename2 = _interopRequireDefault(_useBasename);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Infinite = function Infinite(props) {
  return _react2.default.createElement(
    'div',
    null,
    props.children
  );
};

var md = {
  search: require('react-icons/lib/md/search'),
  add: require('react-icons/lib/md/add')
};

var Campaigns = (_dec = (0, _reduxConnect.asyncConnect)([{
  key: 'account',
  promise: function promise(_ref) {
    var _ref$store = _ref.store,
        dispatch = _ref$store.dispatch,
        getState = _ref$store.getState;

    var state = getState();
    if (!state.account.data.reach) {
      return dispatch((0, _account.getAccount)());
    }
    return null;
  }
}, {
  key: "auth",
  promise: function promise(_ref2) {
    var _ref2$store = _ref2.store,
        dispatch = _ref2$store.dispatch,
        getState = _ref2$store.getState;

    if (!(0, _auth.isLoaded)(getState())) {
      return dispatch((0, _auth.load)());
    }
    return null;
  }
}]), _dec2 = (0, _reactRedux.connect)(function (state) {
  return {
    mobileMode: state.mobile.mobileMode,
    list: state.campaigns.list,
    isLoading: state.campaigns.loading,
    account: state.auth.current_account
  };
}, {
  get: _campaigns.getCampaigns,
  refresh: _campaigns.campaignsRefresh
}), _dec(_class = _dec2(_class = (_temp = _class2 = function (_List) {
  (0, _inherits3.default)(Campaigns, _List);

  function Campaigns(props) {
    (0, _classCallCheck3.default)(this, Campaigns);

    var _this = (0, _possibleConstructorReturn3.default)(this, (Campaigns.__proto__ || (0, _getPrototypeOf2.default)(Campaigns)).call(this, props));

    _this.title = (0, _translations2.default)('CAMPAIGNS');
    _this.statusTranslations = {
      IN_PROGRESS: (0, _translations2.default)('IN_PROGRESS'),
      PAUSED: (0, _translations2.default)('PAUSED'),
      APPROVED: (0, _translations2.default)('APPROVED'),
      FINISHED: (0, _translations2.default)('FINISHED'),
      EXPIRED: (0, _translations2.default)('EXPIRED'),
      ABORTED: (0, _translations2.default)('ABORTED'),
      NOT_STARTED: (0, _translations2.default)('NOT_STARTED'),
      STOPPED: (0, _translations2.default)('STOPPED')
    };
    return _this;
  }

  (0, _createClass3.default)(Campaigns, [{
    key: 'getLink',
    value: function getLink(item) {
      var openIf = ['NOT_STARTED'];
      var resultsIf = ['IN_PROGRESS', 'FINISHED', 'EXPIRED', 'ABORTED', 'STOPPED'];
      if (resultsIf.indexOf(item.status) > -1) {
        return {
          pathname: '/campaigns/results',
          query: { uid: item._id },
          state: {
            modal: true,
            returnTo: '/campaigns',
            modalSize: {
              width: '730px', height: '425px'
            },
            resultType: 'CAMPAIGN'
          }
        };
      } else if (openIf.indexOf(item.status) > -1) {
        return {
          pathname: '/campaigns/campaign/edit',
          query: { uid: item._id },
          state: {
            modal: true,
            returnTo: '/campaigns',
            fullModal: true
          }
        };
      }
      return null;
    }
  }, {
    key: 'renderNav',
    value: function renderNav() {
      var search = this.props.location.query.search;

      var props = this.props;

      if (!props.account) {
        return null;
      }

      return _react2.default.createElement(
        _components.Nav,
        { key: 'Nav', title: (0, _translations2.default)('MY CAMPAIGNS'), alt: true },
        _react2.default.createElement(
          'div',
          { className: 'search' },
          _react2.default.createElement('input', {
            type: 'text',
            placeholder: (0, _translations2.default)('SEARCH PLACEHOLDER'),
            value: this.state.search || '',
            ref: 'search',
            onChange: this.handleSearch,
            onKeyPress: this.handleSearch
          }),
          _react2.default.createElement(md.search, null),
          _react2.default.createElement(
            'span',
            { className: 'clear', onClick: this.handleClear, style: this.state.search ? {} : { display: 'none' } },
            '\xD7'
          )
        )
      );
    }
  }, {
    key: 'renderListHeader',
    value: function renderListHeader() {
      var classesTheme = (0, _themeEnabled.getThemeEnabledStyle)(_Campaigns2.default);
      return _react2.default.createElement(
        'div',
        { className: classesTheme },
        _react2.default.createElement(
          'div',
          { className: _Campaigns2.default.listheader },
          _react2.default.createElement('div', { className: _Campaigns2.default.handle }),
          _react2.default.createElement(
            'div',
            { className: _Campaigns2.default.name },
            'Nombre'
          ),
          _react2.default.createElement(
            'div',
            { className: _Campaigns2.default.search },
            this.renderNav()
          )
        )
      );
    }
  }, {
    key: 'renderTabs',
    value: function renderTabs() {
      return _react2.default.createElement(_Messages.MessageTabs, null);
    }
  }, {
    key: 'renderExtra',
    value: function renderExtra() {
      var style = {
        backgroundColor: '#FF205E',
        padding: '5px 10px',
        fontSize: '10px',
        color: '#FFF',
        marginBottom: '10px',
        borderRadius: '3px'
      };
      if (this.props.location.state && this.props.location.state.msg) {
        return _react2.default.createElement(
          'div',
          { style: style },
          ' ',
          this.props.location.state.msg,
          ' '
        );
      }
      return null;
    }
  }, {
    key: 'renderInfiniteList',
    value: function renderInfiniteList() {
      var _this2 = this;

      var hidden = { display: 'none' };
      var visible = { display: 'block' };
      var infiniteStyle = this.props.isLoading || this.props.list.length > 0 ? visible : hidden;
      var linkstyle = this.props.isLoading || this.props.list.length > 0 ? hidden : visible;

      if (__SERVER__) {
        return _react2.default.createElement(_components.VirtualListItemLoad, null);
      }

      return _react2.default.createElement(
        'div',
        null,
        _react2.default.createElement(_reactHelmet2.default, { title: 'Administrar campa\xF1as' }),
        _react2.default.createElement(
          'div',
          { style: infiniteStyle },
          _react2.default.createElement(
            Infinite,
            {
              refresh: this.refreshRows,
              next: this.loadMoreRows,
              hasMore: this.state.hasMoreRows
            },
            this.props.list.map(function (item, index) {
              return _react2.default.createElement(_components.VirtualListItemStudy, {
                key: item._id,
                index: index,
                name: item.name,
                link: (0, _sessionPermissions.sessionPermissions)('campaign:view') ? _this2.getLink(item) : null,
                label: _this2.statusTranslations[item.status],
                labelClass: item.status
              });
            })
          )
        ),
        _react2.default.createElement(_components.Linkholder, {
          style: linkstyle,
          to: {
            pathname: '/campaigns/campaign',
            state: { modal: !this.props.mobileMode, returnTo: this.props.location.pathname, fullModal: true }
          },
          src: (0, _useBasename2.default)('/static/empty/campaigns.svg'),
          text: (0, _translations2.default)('NO CAMPAIGNS FOUND'),
          button: (0, _translations2.default)('CREATE A NEW CAMPAIGN')
        })
      );
    }
  }]);
  return Campaigns;
}(_components.List), _class2.propTypes = {
  mobileMode: _propTypes2.default.bool,
  isLoading: _propTypes2.default.bool.isRequired,
  get: _propTypes2.default.func.isRequired,
  list: _propTypes2.default.array.isRequired
}, _class2.defaultProps = {
  selected: []
}, _temp)) || _class) || _class);
exports.default = Campaigns;
module.exports = exports['default'];