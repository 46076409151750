'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ScheduleWizardLoader = exports.SchedulerCustomizer = undefined;

var _getOwnPropertyDescriptor = require('babel-runtime/core-js/object/get-own-property-descriptor');

var _getOwnPropertyDescriptor2 = _interopRequireDefault(_getOwnPropertyDescriptor);

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _class, _desc, _value, _class2;

var _components = require('components');

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Wizard = require('./Wizard');

var _Wizard2 = _interopRequireDefault(_Wizard);

var _Summary = require('./Summary');

var _Summary2 = _interopRequireDefault(_Summary);

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _coreDecorators = require('core-decorators');

var _reactRedux = require('react-redux');

var _reachHistory = require('../../helpers/reachHistory');

var _Modal = require('./Modal.scss');

var _Modal2 = _interopRequireDefault(_Modal);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
  var desc = {};
  Object['ke' + 'ys'](descriptor).forEach(function (key) {
    desc[key] = descriptor[key];
  });
  desc.enumerable = !!desc.enumerable;
  desc.configurable = !!desc.configurable;

  if ('value' in desc || desc.initializer) {
    desc.writable = true;
  }

  desc = decorators.slice().reverse().reduce(function (desc, decorator) {
    return decorator(target, property, desc) || desc;
  }, desc);

  if (context && desc.initializer !== void 0) {
    desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
    desc.initializer = undefined;
  }

  if (desc.initializer === void 0) {
    Object['define' + 'Property'](target, property, desc);
    desc = null;
  }

  return desc;
}

var SchedulerCustomizer = exports.SchedulerCustomizer = (_dec = (0, _reactRedux.connect)(function (state) {
  return {};
}, {}), _dec(_class = (_class2 = function (_React$Component) {
  (0, _inherits3.default)(SchedulerCustomizer, _React$Component);

  function SchedulerCustomizer(props) {
    (0, _classCallCheck3.default)(this, SchedulerCustomizer);

    var _this = (0, _possibleConstructorReturn3.default)(this, (SchedulerCustomizer.__proto__ || (0, _getPrototypeOf2.default)(SchedulerCustomizer)).call(this, props));

    _this.state = {
      tabSelected: 1,
      showTabs: false,
      data: _this.props.data
    };
    return _this;
  }

  (0, _createClass3.default)(SchedulerCustomizer, [{
    key: 'handleComponentChange',
    value: function handleComponentChange(tabSelected, data) {
      this.setState((0, _extends3.default)({}, this.state, {
        tabSelected: tabSelected,
        data: data
      }));
    }
  }, {
    key: 'renderTabs',
    value: function renderTabs(components) {
      var _this2 = this;

      var result = [];
      if (components) {
        var generateLinks = function generateLinks(element, index) {
          result.push(_react2.default.createElement(
            'a',
            {
              href: '#' + element.props.displayName,
              style: {
                borderBottomStyle: _this2.state.tabSelected === index ? 'solid' : 'solid'
              },
              onClick: _this2.handleComponentChange.bind(_this2, index)
            },
            element.props.displayName
          ));
        };

        for (var i = 0; i < components.length; i++) {
          generateLinks(components[i], i);
        }
      }

      return this.state.showTabs ? result : null;
    }
  }, {
    key: 'renderTemplate',
    value: function renderTemplate() {
      var Tab = this.props.components[this.state.tabSelected - 1];
      return _react2.default.createElement(Tab, { data: this.state.data, handleComponentChange: this.handleComponentChange });
    }
  }, {
    key: 'render',
    value: function render() {
      return _react2.default.createElement(
        'div',
        null,
        _react2.default.createElement('div', { className: _Modal2.default.flexRow }),
        _react2.default.createElement(
          'div',
          {
            id: 'header',
            className: _Modal2.default.mainTab,
            style: {
              display: this.state.tabSelected === 1 ? 'block' : 'flex'
            }
          },
          _react2.default.createElement(
            'div',
            { className: _Modal2.default.wrapper },
            this.renderTemplate()
          )
        )
      );
    }
  }]);
  return SchedulerCustomizer;
}(_react2.default.Component), (_applyDecoratedDescriptor(_class2.prototype, 'handleComponentChange', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'handleComponentChange'), _class2.prototype)), _class2)) || _class);

var ScheduleWizardLoader = exports.ScheduleWizardLoader = function (_React$Component2) {
  (0, _inherits3.default)(ScheduleWizardLoader, _React$Component2);

  function ScheduleWizardLoader() {
    (0, _classCallCheck3.default)(this, ScheduleWizardLoader);
    return (0, _possibleConstructorReturn3.default)(this, (ScheduleWizardLoader.__proto__ || (0, _getPrototypeOf2.default)(ScheduleWizardLoader)).apply(this, arguments));
  }

  (0, _createClass3.default)(ScheduleWizardLoader, [{
    key: 'render',
    value: function render() {

      var loadComponents = [_Wizard2.default, _Summary2.default];
      return _react2.default.createElement(SchedulerCustomizer, { components: loadComponents, data: this.props.location.state.data });
    }
  }]);
  return ScheduleWizardLoader;
}(_react2.default.Component);