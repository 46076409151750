'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _getOwnPropertyNames = require('babel-runtime/core-js/object/get-own-property-names');

var _getOwnPropertyNames2 = _interopRequireDefault(_getOwnPropertyNames);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _dec2, _class, _class2, _temp;

var _interaction = require('redux/modules/interaction');

var _reports = require('redux/modules/reports');

var _Allow = require('../Allow/Allow');

var _Allow2 = _interopRequireDefault(_Allow);

var _components = require('components');

var _reactRouter = require('react-router');

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _reduxConnect = require('redux-connect');

var _reactRedux = require('react-redux');

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _reachHistory = require('../../helpers/reachHistory');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var md = {
  assignment: require('react-icons/lib/md/face'),
  stop: require('react-icons/lib/md/stop'),
  copy: require('react-icons/lib/md/note-add'),
  download: require('react-icons/lib/md/cloud-download')
};

var Results = (_dec = (0, _reduxConnect.asyncConnect)([{
  key: 'interaction',
  promise: function promise(_ref) {
    var query = _ref.location.query,
        dispatch = _ref.store.dispatch;
    var uid = query.uid;

    return dispatch((0, _interaction.getInteraction)(uid, 'CAMPAIGN'));
  }
}, {
  key: 'results',
  promise: function promise(_ref2) {
    var query = _ref2.location.query,
        dispatch = _ref2.store.dispatch;
    var uid = query.uid;

    return dispatch((0, _interaction.getInteractionResults)(uid, 'CAMPAIGN'));
  }
}]), _dec2 = (0, _reactRedux.connect)(function (state) {
  return {
    mobileMode: state.mobile.mobileMode,
    interaction: state.interaction
  };
}, { requestStop: _interaction.requestStop, sendTransactionReportLogRequest: _reports.sendTransactionReportLogRequest, pushState: _reachHistory.push }), _dec(_class = _dec2(_class = (_temp = _class2 = function (_React$Component) {
  (0, _inherits3.default)(Results, _React$Component);

  function Results() {
    (0, _classCallCheck3.default)(this, Results);
    return (0, _possibleConstructorReturn3.default)(this, (Results.__proto__ || (0, _getPrototypeOf2.default)(Results)).apply(this, arguments));
  }

  (0, _createClass3.default)(Results, [{
    key: 'getMessage',
    value: function getMessage(messageType) {
      var units = this.props.results.real_time.workflow_units;
      return units.find(function (s) {
        return s.action.action_type === 'message' && s.action.name === messageType;
      });
    }
  }, {
    key: 'getQuestions',
    value: function getQuestions() {
      var units = this.props.results.real_time.workflow_units;
      return units.filter(function (s) {
        return s.action.action_type === 'question';
      }).map(function (s) {
        return {
          type: s.stats.type,
          content: s.action.content,
          stats: s.stats
        };
      });
    }
  }, {
    key: 'getInvitationMessage',
    value: function getInvitationMessage() {
      if (this.props.interaction && this.props.interaction.configuration && this.props.interaction.configuration.messages && this.props.interaction.configuration.messages.invitation.enabled) {
        return {
          action: {
            action_type: 'message',
            content: this.props.interaction.configuration.messages.invitation.body,
            jumps: [],
            name: 'invitation',
            stats: {
              alternative_type: '',
              reminders_count: 0,
              sent_count: 0,
              type: ''
            }
          },
          name: 'invitation',
          stats: {
            alternative_type: '',
            reminders_count: 0,
            sent_count: 0,
            type: ''
          }
        };
      }
      return null;
    }
  }, {
    key: 'handleStop',
    value: function handleStop() {
      var _this2 = this;

      this.props.requestStop(this.props.interaction._id, _interaction.CAMPAIGN).then(function () {
        _this2.props.pushState({
          pathname: '/success',
          state: {
            goTo: '/campaigns/',
            message: (0, _translations2.default)('CAMPAIGN STOP SUCCESS'),
            modal: true,
            returnTo: '/campaigns/'
          }
        });
      });
    }
  }, {
    key: 'handleDownload',
    value: function handleDownload() {
      var _this3 = this;

      return this.props.sendTransactionReportLogRequest(this.props.interaction.created_on, { interaction_uid: this.props.interaction._id }, _reports.reportTypes.CAMPAIGN).then(function () {
        _this3.props.pushState({
          pathname: '/success',
          state: {
            goTo: '/campaigns/',
            message: (0, _translations2.default)('REPORT REQUESTED'),
            modal: true,
            returnTo: '/campaigns/'
          }
        });
      }).catch(function (error) {
        _this3.props.pushState({
          pathname: '/fail',
          state: {
            goTo: '/campaigns/',
            message: (0, _translations2.default)('DOWNLOAD FILE ERROR'),
            modal: true,
            returnTo: '/campaigns/'
          }
        });
      });
    }
  }, {
    key: 'renderMessage',
    value: function renderMessage(messageUnit) {
      if (!messageUnit) {
        return null;
      }
      var style = require('./Campaign.scss');
      return _react2.default.createElement(
        'div',
        { key: messageUnit.name, className: style.result + ' ' + style.message },
        _react2.default.createElement(
          'div',
          { className: style.questionName },
          messageUnit.action.content
        )
      );
    }
  }, {
    key: 'render',
    value: function render() {
      var styles = require('./Campaign.scss');
      var interaction = this.props.interaction;

      if (!this.props.results) {
        return 'Error';
      }
      var sentMessages = this.props.results.external_stats.sent_messages;
      // const receivedMessages = this.props.results.external_stats.received_messages

      var totalAnswers = this.props.results.real_time.workflow_units.map(function (u) {
        return u.stats.sent_count || 0;
      }).reduce(function (a, b) {
        return a + b;
      }, 0);
      var variables = interaction.configuration.messages.welcome.message.match(/[^[\]]+(?=])/g) || [];
      var content = interaction.configuration.messages.welcome.message || (0, _translations2.default)('CAMPAIGN WITH NO TEXT');
      var panelFilter = JSON.parse(interaction.panel_filter);
      var segmentation = (0, _getOwnPropertyNames2.default)(panelFilter).reduce(function (r, p) {
        if (p !== 'accounts' && p !== 'channel_filter') r.push(_react2.default.createElement(
          'div',
          { key: p },
          p,
          ': ',
          panelFilter[p]
        ));
        return r;
      }, []);
      var isHtml = content.trim().startsWith('<');

      return _react2.default.createElement(
        'div',
        { className: styles.results },
        _react2.default.createElement(
          'div',
          { className: styles.bar },
          _react2.default.createElement(
            'div',
            { className: styles.icon },
            _react2.default.createElement(md.assignment, { size: 20, color: '#FFF' })
          ),
          _react2.default.createElement(
            'div',
            { className: styles.title },
            _react2.default.createElement(
              'h1',
              null,
              interaction.name
            ),
            _react2.default.createElement(
              'h2',
              null,
              (0, _translations2.default)('CREATED ON') + ' ' + (0, _moment2.default)(interaction.created_on).format('MM/DD')
            )
          ),
          _react2.default.createElement(
            'div',
            { className: styles.main },
            _react2.default.createElement(
              'div',
              { className: styles.actions },
              _react2.default.createElement(
                _Allow2.default,
                { permissions: ['campaign:copy'] },
                _react2.default.createElement(
                  _reactRouter.Link,
                  {
                    className: styles.action,
                    to: {
                      pathname: '/campaigns/campaign/copy',
                      query: { copy: interaction._id },
                      state: { modal: true, returnTo: '/campaigns/', fullModal: true }
                    }
                  },
                  _react2.default.createElement(md.copy, { size: 15, color: '#FFF' }),
                  _react2.default.createElement(
                    'span',
                    { className: styles.sub },
                    (0, _translations2.default)('COPY')
                  )
                )
              ),
              _react2.default.createElement(
                _Allow2.default,
                { permissions: ['campaign:download:transactions'] },
                _react2.default.createElement(
                  _reactRouter.Link,
                  { className: styles.action, onClick: this.handleDownload.bind(this) },
                  _react2.default.createElement(md.download, { size: 25, color: '#FFF' }),
                  _react2.default.createElement(
                    'span',
                    { className: styles.sub },
                    (0, _translations2.default)('DOWNLOAD')
                  )
                )
              ),
              _react2.default.createElement(
                _Allow2.default,
                { permissions: ['campaign:stop'] },
                interaction.status === 'IN_PROGRESS' && _react2.default.createElement(
                  _reactRouter.Link,
                  { className: styles.action, onClick: this.handleStop.bind(this) },
                  _react2.default.createElement(md.stop, { size: 25, color: '#FFF' }),
                  _react2.default.createElement(
                    'span',
                    { className: styles.sub },
                    (0, _translations2.default)('STOP')
                  )
                )
              )
            )
          )
        ),
        _react2.default.createElement(
          'div',
          { className: styles.resultsReport },
          _react2.default.createElement(
            'div',
            { className: styles.content },
            isHtml ? _react2.default.createElement(_components.HtmlPreview, { html: content }) : content
          ),
          _react2.default.createElement(
            'table',
            { className: styles.table },
            _react2.default.createElement(
              'tbody',
              null,
              _react2.default.createElement(
                'tr',
                null,
                _react2.default.createElement(
                  'td',
                  null,
                  (0, _translations2.default)('CREATED ON')
                ),
                _react2.default.createElement(
                  'td',
                  null,
                  (0, _moment2.default)(interaction.created_on).format('YYYY-MM-DD HH:mm')
                )
              ),
              _react2.default.createElement(
                'tr',
                null,
                _react2.default.createElement(
                  'td',
                  null,
                  (0, _translations2.default)('CREATED BY')
                ),
                _react2.default.createElement(
                  'td',
                  null,
                  interaction.created_by
                )
              ),
              _react2.default.createElement(
                'tr',
                null,
                _react2.default.createElement(
                  'td',
                  null,
                  (0, _translations2.default)('SEGMENTATION')
                ),
                _react2.default.createElement(
                  'td',
                  null,
                  segmentation.length ? segmentation : (0, _translations2.default)('NO SEGMENTATION USED')
                )
              ),
              _react2.default.createElement(
                'tr',
                null,
                _react2.default.createElement(
                  'td',
                  null,
                  (0, _translations2.default)('VARIABLES')
                ),
                _react2.default.createElement(
                  'td',
                  null,
                  variables.length > 0 ? variables.join(', ') : (0, _translations2.default)('NO VARIABLES USED')
                )
              ),
              _react2.default.createElement(
                'tr',
                null,
                _react2.default.createElement(
                  'td',
                  null,
                  (0, _translations2.default)('SENT')
                ),
                _react2.default.createElement(
                  'td',
                  null,
                  sentMessages
                )
              )
            )
          )
        )
      );
    }
  }]);
  return Results;
}(_react2.default.Component), _class2.propTypes = {
  interaction: _propTypes2.default.object.isRequired,
  results: _propTypes2.default.object.isRequired,
  requestStop: _propTypes2.default.func.isRequired,
  pushState: _propTypes2.default.func.isRequired
}, _temp)) || _class) || _class);
exports.default = Results;
module.exports = exports['default'];