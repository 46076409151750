'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _getOwnPropertyNames = require('babel-runtime/core-js/object/get-own-property-names');

var _getOwnPropertyNames2 = _interopRequireDefault(_getOwnPropertyNames);

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _class, _class2, _temp;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactRedux = require('react-redux');

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _interaction = require('redux/modules/interaction');

var _SendingChannels = require('./SendingChannels.scss');

var _SendingChannels2 = _interopRequireDefault(_SendingChannels);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SendingChannels = (_dec = (0, _reactRedux.connect)(function (state) {
  return {
    channels: state.interaction.configuration.sending_channels,
    channelsEnabled: state.interaction.sending_channels_enabled,
    interactionChannels: state.interaction.configuration.interaction_channels
  };
}, { updateInteractionConfig: _interaction.updateInteractionConfig }), _dec(_class = (_temp = _class2 = function (_React$Component) {
  (0, _inherits3.default)(SendingChannels, _React$Component);

  function SendingChannels(props) {
    (0, _classCallCheck3.default)(this, SendingChannels);

    var _this = (0, _possibleConstructorReturn3.default)(this, (SendingChannels.__proto__ || (0, _getPrototypeOf2.default)(SendingChannels)).call(this, props));

    _this.state = {};
    _this.channelSVGs = {
      sms: require('./svg/sms.svg'),
      html: require('./svg/html.svg'),
      app: require('./svg/apps.svg'),
      email: require('./svg/email.svg'),
      none: require('./svg/copy.svg'),
      facebook: require('./svg/facebook.svg'),
      twitter: require('./svg/twitter.svg'),
      whatsapp: require('./svg/whatsapp.svg'),
      telegram: require('./svg/telegram.svg')
    };
    _this.channelTranslations = {
      SMS: (0, _translations2.default)('SMS'),
      HTML: (0, _translations2.default)('WEB'),
      IM: (0, _translations2.default)('IM'),
      APP: (0, _translations2.default)('APP'),
      EMAIL: (0, _translations2.default)('EMAIL'),
      FACEBOOK: (0, _translations2.default)('FACEBOOK'),
      TWITTER: (0, _translations2.default)('TWITTER'),
      WHATSAPP: (0, _translations2.default)('WHATSAPP'),
      TELEGRAM: (0, _translations2.default)('TELEGRAM'),
      NONE: (0, _translations2.default)('NO CHANNEL')
    };
    _this.selectedChannels = {
      SMS: false,
      EMAIL: false,
      FACEBOOK: false,
      TWITTER: false,
      WHATSAPP: false,
      TELEGRAM: false,
      NONE: false
      // get this from interaction
    };_this.enabledChannels = {
      SMS: false,
      EMAIL: false,
      FACEBOOK: false,
      TWITTER: false,
      WHATSAPP: false,
      TELEGRAM: false,
      NONE: true
    };
    props.channelsEnabled.forEach(function (channelEnabled) {
      _this.enabledChannels[channelEnabled.type] = true;
    });

    _this.state = {
      enabledChannels: _this.enabledChannels
    };
    return _this;
  }

  (0, _createClass3.default)(SendingChannels, [{
    key: 'componentWillReceiveProps',
    value: function componentWillReceiveProps(newProps) {
      if (newProps.interactionChannels.indexOf('SMS') > -1 && !this.props.isCampaign) {
        this.setState({
          enabledChannels: (0, _extends3.default)({}, this.state.enabledChannels, {
            EMAIL: false,
            SMS: true
          })
        });
      } else {
        this.setState({
          enabledChannels: (0, _extends3.default)({}, this.state.enabledChannels, {
            EMAIL: this.enabledChannels.EMAIL
          })
        });
      }
    }
  }, {
    key: 'handleChannelChange',
    value: function handleChannelChange(clickedChannel, event) {
      var _this2 = this;

      event.preventDefault();
      var channels = [];
      (0, _getOwnPropertyNames2.default)(this.selectedChannels).forEach(function (channelType) {
        if (channelType === clickedChannel) {
          _this2.selectedChannels[channelType] = true;
          channels.push(channelType);
          /*if (channelType !== 'NONE') {
            channels.push(channelType)
          }*/
        } else {
          _this2.selectedChannels[channelType] = false;
        }
      });
      this.props.updateInteractionConfig('sending_channels', channels);
    }
  }, {
    key: 'channel',
    value: function channel(channelType) {
      if (this.state.enabledChannels[channelType]) {
        var href = '#channel-' + channelType;
        if (this.props.channels.indexOf('HTML') > -1 && channelType === 'NONE' || this.props.channels.indexOf('HTML') > -1 && channelType === 'SMS') {
          this.selectedChannels[channelType] = true;
        }
        if (this.props.channels.indexOf(channelType) < 0) {
          this.selectedChannels[channelType] = false;
        }
        return _react2.default.createElement(
          'a',
          {
            href: href,
            'data-channel': channelType,
            className: this.selectedChannels[channelType] ? '' + _SendingChannels2.default.active : '' + _SendingChannels2.default.cells,
            onClick: this.handleChannelChange.bind(this, channelType) },
          _react2.default.createElement('div', { className: _SendingChannels2.default.img, dangerouslySetInnerHTML: { __html: this.channelSVGs[channelType.toLowerCase()] } }),
          _react2.default.createElement(
            'div',
            { className: _SendingChannels2.default.txt },
            this.channelTranslations[channelType]
          )
        );
      } else {
        if (this.props.channels.indexOf(channelType) < 0) {
          this.selectedChannels[channelType] = false;
        }
      }
      return _react2.default.createElement(
        'a',
        { className: _SendingChannels2.default.disabled },
        _react2.default.createElement('div', { className: _SendingChannels2.default.imgDisabled, dangerouslySetInnerHTML: { __html: this.channelSVGs[channelType.toLowerCase()] } }),
        _react2.default.createElement(
          'div',
          { className: _SendingChannels2.default.txtDisabled },
          channelType
        )
      );
    }
  }, {
    key: 'render',
    value: function render() {
      var _this3 = this;

      var _props = this.props,
          channels = _props.channels,
          channelTypes = _props.channelTypes;

      for (var c = 0; c < channels.length; c = +1) {
        this.selectedChannels[channels[c]] = true;
      }

      return _react2.default.createElement(
        'div',
        { className: _SendingChannels2.default.main },
        _react2.default.createElement(
          'h5',
          null,
          (0, _translations2.default)('SENDING CHANNELS'),
          ' '
        ),
        _react2.default.createElement(
          'section',
          { className: _SendingChannels2.default.sendingChannels },
          _react2.default.createElement(
            'ul',
            { className: _SendingChannels2.default.row },
            channelTypes.map(function (channelType, index) {
              return _react2.default.createElement(
                'li',
                { key: index, className: _SendingChannels2.default.channelsCell },
                _this3.channel(channelType)
              );
            })
          )
        )
      );
    }
  }]);
  return SendingChannels;
}(_react2.default.Component), _class2.propTypes = {
  updateInteractionConfig: _propTypes2.default.func.isRequired,
  channels: _propTypes2.default.array.isRequired,
  channelTypes: _propTypes2.default.array.isRequired,
  channelsEnabled: _propTypes2.default.array.isRequired
}, _class2.defaultProps = {
  isCampaign: false
}, _temp)) || _class);
exports.default = SendingChannels;
module.exports = exports['default'];