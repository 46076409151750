'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.AccountsTabs = undefined;

var _getOwnPropertyDescriptor = require('babel-runtime/core-js/object/get-own-property-descriptor');

var _getOwnPropertyDescriptor2 = _interopRequireDefault(_getOwnPropertyDescriptor);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _class, _desc, _value, _class2, _class3, _temp; // import { Infinite } from 'components'


var _components = require('components');

var _subaccounts = require('redux/modules/subaccounts');

var _Allow = require('../Allow/Allow');

var _Allow2 = _interopRequireDefault(_Allow);

var _Button = require('../../components/Button/Button');

var _Button2 = _interopRequireDefault(_Button);

var _reactRouter = require('react-router');

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _coreDecorators = require('core-decorators');

var _reactRedux = require('react-redux');

var _themeEnabled = require('helpers/themeEnabled');

var _mobile = require('redux/modules/mobile');

var _reachHistory = require('../../helpers/reachHistory');

var _sessionPermissions = require('../../helpers/sessionPermissions');

var _Reseller = require('./Reseller.scss');

var _Reseller2 = _interopRequireDefault(_Reseller);

var _useBasename = require('../../helpers/useBasename');

var _useBasename2 = _interopRequireDefault(_useBasename);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
  var desc = {};
  Object['ke' + 'ys'](descriptor).forEach(function (key) {
    desc[key] = descriptor[key];
  });
  desc.enumerable = !!desc.enumerable;
  desc.configurable = !!desc.configurable;

  if ('value' in desc || desc.initializer) {
    desc.writable = true;
  }

  desc = decorators.slice().reverse().reduce(function (desc, decorator) {
    return decorator(target, property, desc) || desc;
  }, desc);

  if (context && desc.initializer !== void 0) {
    desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
    desc.initializer = undefined;
  }

  if (desc.initializer === void 0) {
    Object['define' + 'Property'](target, property, desc);
    desc = null;
  }

  return desc;
}

var Infinite = function Infinite(props) {
  return _react2.default.createElement(
    'div',
    { className: _Reseller2.default.mainList },
    props.children
  );
};

var md = {
  search: require('react-icons/lib/md/search'),
  person: require('react-icons/lib/md/person'),
  personAdd: require('react-icons/lib/md/person-add'),
  message: require('react-icons/lib/md/email'),
  delete: require('react-icons/lib/md/delete'),
  tag: require('react-icons/lib/md/label'),
  upload: require('react-icons/lib/md/file-upload'),
  moreVert: require('react-icons/lib/md/more-vert'),
  gesture: require('react-icons/lib/md/gesture'),
  inbox: require('react-icons/lib/md/inbox')
};

var AccountsTabs = exports.AccountsTabs = function AccountsTabs() {
  return _react2.default.createElement(_components.Tabs, {
    simple: true,
    alt: true,
    options: [{
      href: '/reseller/',
      title: (0, _translations2.default)('SUBACCOUNT LIST'),
      is_index: true
    }]
  });
};

var AccountList = (_dec = (0, _reactRedux.connect)(function (state) {
  return {
    mobileMode: state.mobile.mobileMode,
    selected: state.subaccounts.selected,
    list: state.subaccounts.list,
    isLoading: state.subaccounts.loading
  };
}, {
  get: _subaccounts.getSubaccounts,
  select: _subaccounts.subaccountSelect,
  unselect: _subaccounts.subaccountUnselect,
  refresh: _subaccounts.subaccountsRefresh,
  subaccountUnselectAll: _subaccounts.subaccountUnselectAll,
  putSubaccountDefault: _subaccounts.putSubaccountDefault,
  modal: _mobile.modal,
  push: _reachHistory.push
}), _dec(_class = (_class2 = (_temp = _class3 = function (_AdminList) {
  (0, _inherits3.default)(AccountList, _AdminList);

  function AccountList(props) {
    (0, _classCallCheck3.default)(this, AccountList);

    var _this = (0, _possibleConstructorReturn3.default)(this, (AccountList.__proto__ || (0, _getPrototypeOf2.default)(AccountList)).call(this, props));

    _this.title = (0, _translations2.default)('SUBACCOUNTS');
    return _this;
  }

  (0, _createClass3.default)(AccountList, [{
    key: 'deleteSelected',
    value: function deleteSelected() {
      var _this2 = this;

      var ids = this.props.selected.map(function (pos) {
        return _this2.props.list[pos].id;
      });
      this.props.deleteContacts(ids).then(function () {
        _this2.beginSearch('');
      });
    }
  }, {
    key: 'sendSelected',
    value: function sendSelected() {
      var _this3 = this;

      var subAccounts = this.props.selected.map(function (index) {
        return _this3.props.list[index];
      });
      this.props.putSubaccountDefault(subAccounts[0].reach._id).then(function () {
        _this3.props.push({
          pathname: '/success',
          state: {
            goTo: '/reseller',
            goToModal: false,
            message: (0, _translations2.default)('SUBACCOUNT DEFAULT ASIGNED'),
            modal: true,
            returnTo: '/reseller/'
          }
        });
      });
    }
  }, {
    key: 'addTagsToSelected',
    value: function addTagsToSelected() {
      var _this4 = this;

      this.props.push({
        pathname: '/contacts/addTags',
        state: {
          modal: true,
          modalSize: { height: '500px', width: '830px' },
          returnTo: '/contacts/',
          // contactsSelected: this.props.list.filter((contact, index) => (this.props.selected.indexOf(index) > -1))
          contactsSelected: this.props.selected.map(function (index) {
            return _this4.props.list[index];
          })
        }
      });
    }
  }, {
    key: 'renderNav',
    value: function renderNav() {
      if (this.isSelecting()) {
        var countMessage = this.props.selected.length === 1 ? (0, _translations2.default)('SUBACCOUNT SELECTED') : (0, _translations2.default)('SUBACCOUNT SELECTED');
        return _react2.default.createElement(
          _components.ContextNav,
          {
            key: 'ContextNav',
            selectedElementsCount: this.props.selected.length + '  ' + countMessage,
            unselect: this.props.subaccountUnselectAll
          },
          _react2.default.createElement(
            _reactRouter.Link,
            { className: 'action right', onClick: this.sendSelected, style: { border: '1px solid white', borderRadius: '5px' } },
            _react2.default.createElement(md.message, { size: 15 }),
            (0, _translations2.default)('SELECT DEFAULT')
          ),
          _react2.default.createElement(
            _reactRouter.Link,
            { to: '/user/sub/new', className: 'action right', style: { border: '1px solid white', borderRadius: '5px' } },
            _react2.default.createElement(md.personAdd, { size: 15 }),
            (0, _translations2.default)('USER USERNAME')
          )
        );
      }

      var classesTheme = (0, _themeEnabled.getThemeEnabledStyle)(_Reseller2.default);

      return _react2.default.createElement(
        'div',
        { className: classesTheme },
        _react2.default.createElement(
          'div',
          { className: _Reseller2.default.containerTitle },
          _react2.default.createElement(
            'div',
            { className: _Reseller2.default.columnTitle },
            _react2.default.createElement(
              'div',
              null,
              (0, _translations2.default)('ACCOUNT')
            ),
            _react2.default.createElement(
              'div',
              null,
              (0, _translations2.default)('CONSUMED')
            ),
            _react2.default.createElement(
              _Allow2.default,
              { permissions: ['reseller:prepaid:subaccount:create'] },
              _react2.default.createElement(
                'div',
                null,
                (0, _translations2.default)('TYPE')
              )
            )
          ),
          _react2.default.createElement(
            _Allow2.default,
            { permissions: ['reseller:subaccount:create'] },
            _react2.default.createElement(
              _components.Nav,
              { key: 'Nav', title: (0, _translations2.default)('RESELLER'), style: { 'padding': '5px' } },
              _react2.default.createElement(
                _reactRouter.Link,
                {
                  to: {
                    pathname: '/reseller/subaccount/new',
                    state: {
                      modal: true, returnTo: '/reseller', modalSize: { width: '390px', height: '620px' }
                    }
                  }
                },
                _react2.default.createElement(
                  'button',
                  { className: _Reseller2.default.addButton },
                  _react2.default.createElement(md.inbox, { size: 20, className: _Reseller2.default.iconAccount }),
                  (0, _translations2.default)('ADD SUBACCOUNT')
                )
              )
            )
          )
        )
      );
    }
  }, {
    key: 'renderTabs',
    value: function renderTabs() {
      return _react2.default.createElement(AccountsTabs, null);
    }
  }, {
    key: 'renderInfiniteList',
    value: function renderInfiniteList() {
      var _this5 = this;

      var hidden = { display: 'none' };
      var visible = { display: 'block' };
      var infiniteStyle = this.props.isLoading || this.props.list.length > 0 ? visible : hidden;
      var linkstyle = this.props.isLoading || this.props.list.length > 0 ? hidden : visible;

      if (__SERVER__) {
        return _react2.default.createElement(_components.VirtualListItemLoad, null);
      }
      return _react2.default.createElement(
        'div',
        { className: _Reseller2.default.mainList },
        _react2.default.createElement(
          'div',
          { style: infiniteStyle },
          _react2.default.createElement(
            Infinite,
            {
              refresh: this.refreshRows,
              next: this.loadMoreRows,
              hasMore: this.state.hasMoreRows
            },
            this.props.list.map(function (item, index) {
              return _react2.default.createElement(
                _Allow2.default,
                { permissions: ['reseller:subaccount:list'] },
                _react2.default.createElement(_components.VirtualListItemAccount, {
                  key: item.reach._id,
                  index: index,
                  style: { 'grid-template-columns': '10% 20% 20% 25% 20% 5%' },
                  is_default: item.status.is_default_subaccount,
                  account_name: item.status.account_name + (item.status.is_default_subaccount ? ' (Default)' : '') + (item.status.account_status === 'INACTIVE' ? ' (Inactiva)' : ''),
                  sms_limit: (item.status.current_package ? item.csm.subaccount_sms_limit - item.status.current_package.balance : 0) + '/' + item.csm.subaccount_sms_limit,
                  charge_type: item.reach.reach.billing.charge_type,
                  mobileMode: _this5.props.mobileMode,
                  active: item.active || false,
                  setActive: _this5.setActive.bind(_this5),
                  link: (0, _sessionPermissions.sessionPermissions)('reseller:subaccount:view') ? {
                    pathname: '/reseller/subaccount',
                    state: {
                      modal: true,
                      returnTo: _this5.props.location.pathname,
                      modalSize: { width: '390px', height: '540px' },
                      account: item
                    }
                  } : null,
                  selecting: _this5.isSelecting()
                })
              );
            })
          )
        ),
        _react2.default.createElement(_components.Linkholder, {
          style: linkstyle,
          to: {
            pathname: '/contacts/choose',
            state: { modal: true, modalSize: { width: '400px' } }
          },
          src: (0, _useBasename2.default)('/static/empty/contacts.svg'),
          text: (0, _translations2.default)('NO SUBACCOUNTS FOUND'),
          button: (0, _translations2.default)('ADD A NEW SUBACCOUNT')
        })
      );
    }
  }]);
  return AccountList;
}(_components.AdminList), _class3.propTypes = {
  mobileMode: _propTypes2.default.bool,
  selected: _propTypes2.default.array.isRequired,
  list: _propTypes2.default.array.isRequired,
  isLoading: _propTypes2.default.bool.isRequired,
  get: _propTypes2.default.func.isRequired,
  select: _propTypes2.default.func.isRequired,
  unselect: _propTypes2.default.func.isRequired,
  refresh: _propTypes2.default.func.isRequired,
  subaccountUnselectAll: _propTypes2.default.func.isRequired,
  location: _propTypes2.default.object.isRequired,
  push: _propTypes2.default.func.isRequired
}, _temp), (_applyDecoratedDescriptor(_class2.prototype, 'deleteSelected', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'deleteSelected'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, 'sendSelected', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'sendSelected'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, 'addTagsToSelected', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'addTagsToSelected'), _class2.prototype)), _class2)) || _class);
exports.default = AccountList;