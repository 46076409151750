'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Cancelled = exports.DailyMessages = undefined;

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _class, _dec2, _class2;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _reactRouter = require('react-router');

var _reactRedux = require('react-redux');

var _components = require('components');

var _reports = require('redux/modules/reports');

var _themeEnabled = require('helpers/themeEnabled');

var _Report = require('./Report.scss');

var _Report2 = _interopRequireDefault(_Report);

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _partner = require('../../helpers/partner');

var _useBasename = require('../../helpers/useBasename');

var _useBasename2 = _interopRequireDefault(_useBasename);

var _numberFormat = require('../../helpers/numberFormat');

var _numberFormat2 = _interopRequireDefault(_numberFormat);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var md = {
  pieChart: require('react-icons/lib/md/pie-chart'),
  arrowBack: require('react-icons/lib/md/arrow-back')
};
var DailyMessages = exports.DailyMessages = (_dec = (0, _reactRedux.connect)(function (state) {
  return {
    reports: state.reports
  };
}, { getContactsCancelled: _reports.getContactsCancelled }), _dec(_class = function (_React$Component) {
  (0, _inherits3.default)(DailyMessages, _React$Component);

  function DailyMessages(props) {
    (0, _classCallCheck3.default)(this, DailyMessages);

    var _this = (0, _possibleConstructorReturn3.default)(this, (DailyMessages.__proto__ || (0, _getPrototypeOf2.default)(DailyMessages)).call(this, props));

    _this.state = {
      list: []
    };
    props.location.state.data.result.map(function (item) {
      item.errors = (0, _numberFormat2.default)(item.errors, 0);
      item.sms_out_billable = (0, _numberFormat2.default)(item.sms_out_billable, 0);
      item.sms_out_non_billable = (0, _numberFormat2.default)(item.sms_out_non_billable, 0);
    });
    _this.data = props.location.state.data;
    _this.label = props.location.state.label;
    _this.accessor = props.location.state.accessor;
    return _this;
  }

  (0, _createClass3.default)(DailyMessages, [{
    key: 'componentWillMount',
    value: function componentWillMount() {
      var _this2 = this;

      var _props$location$state = this.props.location.state,
          startDate = _props$location$state.startDate,
          endDate = _props$location$state.endDate;

      this.props.getContactsCancelled((0, _moment2.default)(startDate), (0, _moment2.default)(endDate)).then(function (result) {
        return _this2.setState({
          list: result
        });
      });
    }
  }, {
    key: 'renderReport',
    value: function renderReport() {
      var _this3 = this;

      var series = [];
      if (Array.isArray(this.accessor)) {
        this.accessor.map(function (serie) {
          if (serie == 'errors') {
            series.push({
              name: (0, _translations2.default)("ERROR TRANSACTIONS"),
              pointInterval: 24 * 3600 * 1000,
              color: "#DE1A1A",
              pointStart: _this3.data.result[0].key,
              data: _this3.data.result.map(function (value) {
                return value[serie];
              })
            });
          }

          if (serie == 'sms_out_billable') {
            series.push({
              name: (0, _translations2.default)('SENT TRANSACTIONS'),
              pointInterval: 24 * 3600 * 1000,
              color: "#1A8EDE",
              pointStart: _this3.data.result[0].key,
              data: _this3.data.result.map(function (value) {
                return value[serie];
              })
            });
          }
          if (serie == 'sms_out_non_billable') {
            series.push({
              name: (0, _translations2.default)('MESSAGES SENT NONBILLABLE'),
              pointInterval: 24 * 3600 * 1000,
              pointStart: _this3.data.result[0].key,
              color: "#9B9B9B",
              data: _this3.data.result.map(function (value) {
                return value[serie];
              })
            });
          }
          if (serie == 'email_out_billable') {
            series.push({
              name: (0, _translations2.default)('SENT EMAIL'),
              pointInterval: 24 * 3600 * 1000,
              color: "#1A8EDE",
              pointStart: _this3.data.result[0].key,
              data: _this3.data.result.map(function (value) {
                return value[serie];
              })
            });
          }
          if (serie == 'push_out_billable') {
            series.push({
              name: (0, _translations2.default)('SENT PUSH'),
              pointInterval: 24 * 3600 * 1000,
              color: "#1A8EDE",
              pointStart: _this3.data.result[0].key,
              data: _this3.data.result.map(function (value) {
                return value[serie];
              })
            });
          }
          if (serie == 'rcs_out_billable') {
            series.push({
              name: (0, _translations2.default)('RCS Enviados'),
              pointInterval: 24 * 3600 * 1000,
              color: "#1A8EDE",
              pointStart: _this3.data.result[0].key,
              data: _this3.data.result.map(function (value) {
                return value[serie];
              })
            });
          }
        });
      }

      return _react2.default.createElement(
        'div',
        { className: _Report2.default.chart },
        _react2.default.createElement(
          'div',
          { className: _Report2.default.title },
          'Tr\xE1fico diario '
        ),
        _react2.default.createElement(
          'div',
          { className: _Report2.default.content },
          _react2.default.createElement(_components.ReportChart, { config: { series: series }, series: series, height: 240 })
        )
      );
    }
  }, {
    key: 'renderTable',
    value: function renderTable() {
      var number_width = 200;

      var columns = [{
        Header: (0, _translations2.default)('DATE'),
        accessor: 'key_as_string',
        width: number_width,
        Cell: function Cell(props) {
          return _react2.default.createElement(
            'span',
            null,
            (0, _moment2.default)(props.value).format('YYYY/MM/DD')
          );
        }
      }];

      this.accessor.map(function (serie) {
        if (serie == 'errors') columns.push({
          Header: (0, _translations2.default)("ERROR TRANSACTIONS"),
          accessor: serie,
          width: '1f'
        });
        if (serie == 'sms_out_billable') columns.push({
          Header: (0, _translations2.default)('SENT TRANSACTIONS'),
          accessor: serie,
          width: '1f'
        });
        if (serie == 'sms_out_non_billable') columns.push({
          Header: (0, _translations2.default)('MESSAGES SENT NONBILLABLE'),
          accessor: serie,
          width: '1f'
        });
        if (serie == 'email_out_billable') columns.push({
          Header: (0, _translations2.default)('SENT EMAIL'),
          accessor: serie,
          width: '1f'
        });
        if (serie == 'push_out_billable') columns.push({
          Header: (0, _translations2.default)('SENT PUSH'),
          accessor: serie,
          width: '1f'
        });
        if (serie == 'rcs_out_billable') columns.push({
          Header: (0, _translations2.default)('SENT PUSH'),
          accessor: serie,
          width: '1f'
        });
      });

      return _react2.default.createElement(
        'div',
        { className: _Report2.default.table },
        _react2.default.createElement(_components.Table, {
          className: _Report2.default.table,
          data: this.data.result,
          columns: columns,
          isLoading: false
        })
      );
    }
  }, {
    key: 'renderDownload',
    value: function renderDownload() {
      var _props$location$state2 = this.props.location.state,
          startDate = _props$location$state2.startDate,
          endDate = _props$location$state2.endDate;

      var partner = (0, _partner.partnerByHost)();
      var color = 'white';
      if (partner == 'claro') color = 'black';
      return _react2.default.createElement(
        _reactRouter.Link,
        { style: { color: color }, to: {
            pathname: '/reports/requestTransactions', state: {
              modal: true, returnTo: '/reports',
              startDate: startDate, endDate: endDate, modalSize: { width: '830px', height: '430px' }
            }
          } },
        'Solicitar Reportes ',
        _react2.default.createElement('img', { style: { paddingLeft: '5px' }, src: (0, _useBasename2.default)('/static/download_icon.svg'), alt: '' })
      );
    }
  }, {
    key: 'render',
    value: function render() {
      if (!this.props.location.state) {
        return null;
      }
      var _props$location$state3 = this.props.location.state,
          startDate = _props$location$state3.startDate,
          endDate = _props$location$state3.endDate;

      var startDateFormatted = startDate ? (0, _moment2.default)(startDate).format('YYYY-MM-DD') : (0, _moment2.default)().format('YYYY-MM-DD');
      var endDateFormatted = endDate ? (0, _moment2.default)(endDate).format('YYYY-MM-DD') : (0, _moment2.default)().format('YYYY-MM-DD');
      var classesTheme = (0, _themeEnabled.getThemeEnabledStyle)(_Report2.default);

      return _react2.default.createElement(
        'div',
        { className: _Report2.default.report },
        _react2.default.createElement(
          'div',
          { className: classesTheme },
          _react2.default.createElement(
            'div',
            { className: _Report2.default.bar },
            _react2.default.createElement(
              'div',
              { className: _Report2.default.goBack, onClick: this.props.router.goBack },
              _react2.default.createElement(md.arrowBack, { size: 20, color: '#FFF' })
            ),
            _react2.default.createElement(
              'div',
              { className: _Report2.default.icon },
              _react2.default.createElement(md.pieChart, { size: 20, color: '#FFF' })
            ),
            _react2.default.createElement(
              'div',
              { className: _Report2.default.title },
              this.label,
              ' ',
              _react2.default.createElement(
                'span',
                { className: _Report2.default.lower },
                (0, _translations2.default)('FROM'),
                ' ',
                startDateFormatted,
                ' ',
                (0, _translations2.default)('TO'),
                ' ',
                endDateFormatted
              )
            ),
            _react2.default.createElement(
              'div',
              { style: { padding: '20px' } },
              this.renderDownload()
            )
          ),
          _react2.default.createElement(
            'div',
            { className: _Report2.default.body },
            this.renderReport(),
            this.renderTable()
          )
        )
      );
    }
  }]);
  return DailyMessages;
}(_react2.default.Component)) || _class);
var Cancelled = exports.Cancelled = (_dec2 = (0, _reactRedux.connect)(function (state) {
  return {
    reports: state.reports
  };
}, { getContactsCancelled: _reports.getContactsCancelled }), _dec2(_class2 = function (_React$Component2) {
  (0, _inherits3.default)(Cancelled, _React$Component2);

  function Cancelled(props) {
    (0, _classCallCheck3.default)(this, Cancelled);

    var _this4 = (0, _possibleConstructorReturn3.default)(this, (Cancelled.__proto__ || (0, _getPrototypeOf2.default)(Cancelled)).call(this, props));

    _this4.state = {
      list: []
    };
    return _this4;
  }

  (0, _createClass3.default)(Cancelled, [{
    key: 'componentWillMount',
    value: function componentWillMount() {
      var _this5 = this;

      var _props$location$state4 = this.props.location.state,
          startDate = _props$location$state4.startDate,
          endDate = _props$location$state4.endDate;


      if (__CLIENT__) {
        setTimeout(function () {
          _this5.props.getContactsCancelled((0, _moment2.default)(startDate), (0, _moment2.default)(endDate)).then(function (result) {
            return _this5.setState({
              list: result
            });
          });
        }, 500);
      }
    }
  }, {
    key: 'renderTable',
    value: function renderTable() {
      if (!this.state.list) {
        return null;
      }
      var number_width = 200;
      var columns = [{
        Header: (0, _translations2.default)('DATE'),
        accessor: 'created_on',
        width: number_width,
        Cell: function Cell(props) {
          return _react2.default.createElement(
            'span',
            null,
            (0, _moment2.default)(props.value).format('YYYY/MM/DD')
          );
        }
      }, {
        Header: (0, _translations2.default)('USER PHONE'),
        accessor: 'msisdn',
        width: '1f'
      }];

      return _react2.default.createElement(
        'div',
        { className: _Report2.default.table },
        _react2.default.createElement(_components.Table, {
          data: this.state.list,
          columns: columns,
          isLoading: false
        })
      );
    }
  }, {
    key: 'render',
    value: function render() {
      var _props$location$state5 = this.props.location.state,
          startDate = _props$location$state5.startDate,
          endDate = _props$location$state5.endDate;

      var startDateFormatted = startDate ? (0, _moment2.default)(startDate).format('YYYY-MM-DD') : (0, _moment2.default)().format('YYYY-MM-DD');
      var endDateFormatted = endDate ? (0, _moment2.default)(endDate).format('YYYY-MM-DD') : (0, _moment2.default)().format('YYYY-MM-DD');
      var classesTheme = (0, _themeEnabled.getThemeEnabledStyle)(_Report2.default);

      return _react2.default.createElement(
        'div',
        { className: _Report2.default.report },
        _react2.default.createElement(
          'div',
          { className: classesTheme },
          _react2.default.createElement(
            'div',
            { className: _Report2.default.bar },
            _react2.default.createElement(
              'div',
              { className: _Report2.default.icon },
              _react2.default.createElement(md.pieChart, { size: 20, color: '#FFF' })
            ),
            _react2.default.createElement(
              'div',
              { className: _Report2.default.title },
              (0, _translations2.default)('CANCELLED CONTACTS'),
              ' ',
              _react2.default.createElement(
                'span',
                { className: _Report2.default.lower },
                (0, _translations2.default)('FROM'),
                ' ',
                startDateFormatted,
                ' ',
                (0, _translations2.default)('TO'),
                ' ',
                endDateFormatted
              )
            )
          ),
          _react2.default.createElement(
            'div',
            { className: _Report2.default.body },
            this.renderTable()
          )
        )
      );
    }
  }]);
  return Cancelled;
}(_react2.default.Component)) || _class2);