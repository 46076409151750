'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _redux = require('redux');

var _reactRouterRedux = require('react-router-redux');

var _reduxConnect = require('redux-connect');

var _reduxForm = require('redux-form');

var _reactReduxLoadingBar = require('react-redux-loading-bar');

var _auth = require('./auth');

var _auth2 = _interopRequireDefault(_auth);

var _mobile = require('./mobile');

var _mobile2 = _interopRequireDefault(_mobile);

var _contacts = require('./contacts');

var _contacts2 = _interopRequireDefault(_contacts);

var _contact = require('./contact');

var _contact2 = _interopRequireDefault(_contact);

var _account = require('./account');

var _account2 = _interopRequireDefault(_account);

var _channel = require('./channel');

var _channel2 = _interopRequireDefault(_channel);

var _status = require('./status');

var _status2 = _interopRequireDefault(_status);

var _users = require('./users');

var _users2 = _interopRequireDefault(_users);

var _subaccounts = require('./subaccounts');

var _subaccounts2 = _interopRequireDefault(_subaccounts);

var _policy = require('./policy');

var _policy2 = _interopRequireDefault(_policy);

var _messages = require('./messages');

var _reports = require('./reports');

var _schedulerTasks = require('./schedulerTasks');

var _tags = require('./tags');

var _tags2 = _interopRequireDefault(_tags);

var _variables = require('./variables');

var _variables2 = _interopRequireDefault(_variables);

var _interaction = require('./interaction');

var _interaction2 = _interopRequireDefault(_interaction);

var _workflows = require('./workflows');

var _workflows2 = _interopRequireDefault(_workflows);

var _profiling = require('./profiling');

var _studies = require('./studies');

var _files = require('./files');

var _files2 = _interopRequireDefault(_files);

var _unread = require('./unread');

var _unread2 = _interopRequireDefault(_unread);

var _campaigns = require('./campaigns');

var _subaccountUsers = require('./subaccountUsers');

var _subaccountUsers2 = _interopRequireDefault(_subaccountUsers);

var _promotion = require('./promotion');

var _promotion2 = _interopRequireDefault(_promotion);

var _shortLink = require('./shortLink');

var _shortLink2 = _interopRequireDefault(_shortLink);

var _qrs = require('./qrs');

var _qrs2 = _interopRequireDefault(_qrs);

var _questionRegex = require('./questionRegex');

var _questionRegex2 = _interopRequireDefault(_questionRegex);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = (0, _redux.combineReducers)({
  routing: _reactRouterRedux.routerReducer,
  loadingBar: _reactReduxLoadingBar.loadingBarReducer,
  reduxAsyncConnect: _reduxConnect.reducer,
  auth: _auth2.default,
  account: _account2.default,
  channel: _channel2.default,
  status: _status2.default,
  mobile: _mobile2.default,
  contacts: _contacts2.default,
  contact: _contact2.default,
  interaction: _interaction2.default,
  messagesSent: _messages.sentReducer,
  messagesReceived: _messages.receivedReducer,
  chatMessages: _messages.chatReducer,
  messagesScheduled: _messages.schedulerReducer,
  tags: _tags2.default,
  variables: _variables2.default,
  subaccounts: _subaccounts2.default,
  policy: _policy2.default,
  unread: _unread2.default,
  schedulerRecurrences: _schedulerTasks.getSchedulerRecurrences,
  studies: _studies.studiesReducer,
  campaigns: _campaigns.campaignsReducer,
  campaignReducer: _campaigns.campaignReducer,
  workflows: _workflows2.default,
  profiling: _profiling.profilingReducer,
  profilingResults: _profiling.profilingStatsReducer,
  reports: _reports.reportsReducer,
  tasks: _schedulerTasks.tasksReducer,
  files: _files2.default,
  form: _reduxForm.reducer,
  users: _users2.default,
  subAccountUsers: _subaccountUsers2.default,
  promotions: _promotion2.default,
  qrs: _qrs2.default,
  shortlink: _shortLink2.default,
  questionregex: _questionRegex2.default
});
module.exports = exports['default'];