'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actions = undefined;
exports.tasksReducer = tasksReducer;
exports.getTasks = getTasks;
exports.newTask = newTask;
exports.updateTask = updateTask;
exports.getSingleTask = getSingleTask;
exports.deleteTask = deleteTask;
exports.newEvent = newEvent;
exports.updateEventTask = updateEventTask;
exports.deleteEvent = deleteEvent;
exports.getSchedulerRecurrences = getSchedulerRecurrences;
exports.GetSchedulerStates = GetSchedulerStates;
exports.GetSchedulerTypes = GetSchedulerTypes;

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _apiAction = require('../apiAction');

var _apiAction2 = _interopRequireDefault(_apiAction);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// ACTIONS

var actions = exports.actions = new _apiAction2.default('TASKS', [_apiAction.ACTION_TYPES.DATA, _apiAction.ACTION_TYPES.APPEND, _apiAction.ACTION_TYPES.TRIM]);

// REDUCER


var initialState = {
  loading: false,
  status: actions.DATA,
  data: {},
  list: []
};

function tasksReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  return actions.getStateForAction(state, action);
}

// ACTION CREATORS

function getTasks() {
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.get('/scheduler/tasks', {
        params: ''
      });
    }
  };
}

function newTask(task) {
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.post('/scheduler/tasks', { data: task });
    }
  };
}

function updateTask(taskId, task) {
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.put('/scheduler/tasks/' + taskId, { data: task });
    }
  };
}

function getSingleTask(taskId) {
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.get('/scheduler/tasks/', {
        taskId: taskId
      });
    }
  };
}

function deleteTask(taskId, event_date, event_uid) {
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.del('/scheduler/tasks/' + taskId, {
        params: { event_date: event_date, event_uid: event_uid }
      });
    }
  };
}

function newEvent(taskId, event) {
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.post('/scheduler/tasks/' + taskId + '/event', { data: event });
    }
  };
}

function updateEventTask(taskId, eventId, event) {
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.put('/scheduler/tasks/' + taskId + '/event/' + eventId, { data: event });
    }
  };
}

function deleteEvent(taskId, eventId) {
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.del('/scheduler/tasks/' + taskId + '/event/' + eventId);
    }
  };
}

/// get fill data


function getSchedulerRecurrences() {
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.get('/scheduler/tasks/recurrence/values');
    }
  };
}

function GetSchedulerStates() {
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.get('/scheduler/tasks/status/values');
    }
  };
}

function GetSchedulerTypes() {
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.get('/scheduler/tasks/types/values');
    }
  };
}