'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MiniGauge = exports.default = undefined;

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Charts = require('./Charts.scss');

var _Charts2 = _interopRequireDefault(_Charts);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ELEMENTS_COUNT = 35;

var Gauge = function (_React$Component) {
  (0, _inherits3.default)(Gauge, _React$Component);

  function Gauge() {
    (0, _classCallCheck3.default)(this, Gauge);
    return (0, _possibleConstructorReturn3.default)(this, (Gauge.__proto__ || (0, _getPrototypeOf2.default)(Gauge)).apply(this, arguments));
  }

  (0, _createClass3.default)(Gauge, [{
    key: 'render',
    value: function render() {
      // const percent = 0.75
      var percent = this.props.percent;

      var elements = [];
      for (var i = 0; i < ELEMENTS_COUNT; i++) {
        if (i < Math.floor(ELEMENTS_COUNT * percent)) {
          elements.push(_react2.default.createElement('li', { key: i }));
        }
      }
      return _react2.default.createElement(
        'div',
        { className: _Charts2.default.outer },
        _react2.default.createElement(
          'ul',
          { className: _Charts2.default.gauge },
          elements
        ),
        _react2.default.createElement('div', { className: _Charts2.default.inner })
      );
    }
  }]);
  return Gauge;
}(_react2.default.Component);

exports.default = Gauge;

var MiniGauge = exports.MiniGauge = function (_React$Component2) {
  (0, _inherits3.default)(MiniGauge, _React$Component2);

  function MiniGauge() {
    (0, _classCallCheck3.default)(this, MiniGauge);
    return (0, _possibleConstructorReturn3.default)(this, (MiniGauge.__proto__ || (0, _getPrototypeOf2.default)(MiniGauge)).apply(this, arguments));
  }

  (0, _createClass3.default)(MiniGauge, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          color = _props.color,
          background = _props.background;

      var percent = this.props.percent;
      var style = { width: percent + '%', background: color };
      var styleLight = { background: background };

      if (percent > 97 && percent < 100) {
        percent = 97;
      }
      if (percent == 100) {
        style.borderRadius = '5px';
      }

      return _react2.default.createElement(
        'div',
        { className: _Charts2.default.minigauge, style: styleLight },
        _react2.default.createElement('div', { className: _Charts2.default.inner, style: style })
      );
    }
  }]);
  return MiniGauge;
}(_react2.default.Component);