'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _getOwnPropertyDescriptor = require('babel-runtime/core-js/object/get-own-property-descriptor');

var _getOwnPropertyDescriptor2 = _interopRequireDefault(_getOwnPropertyDescriptor);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _class, _desc, _value, _class2;

var _reactRouter = require('react-router');

var _components = require('components');

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _coreDecorators = require('core-decorators');

var _reactRedux = require('react-redux');

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _reachHistory = require('../../helpers/reachHistory');

var _Wizard = require('./Wizard.scss');

var _Wizard2 = _interopRequireDefault(_Wizard);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
  var desc = {};
  Object['ke' + 'ys'](descriptor).forEach(function (key) {
    desc[key] = descriptor[key];
  });
  desc.enumerable = !!desc.enumerable;
  desc.configurable = !!desc.configurable;

  if ('value' in desc || desc.initializer) {
    desc.writable = true;
  }

  desc = decorators.slice().reverse().reduce(function (desc, decorator) {
    return decorator(target, property, desc) || desc;
  }, desc);

  if (context && desc.initializer !== void 0) {
    desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
    desc.initializer = undefined;
  }

  if (desc.initializer === void 0) {
    Object['define' + 'Property'](target, property, desc);
    desc = null;
  }

  return desc;
}

var Wizard = (_dec = (0, _reactRedux.connect)(null, { push: _reachHistory.push }), _dec(_class = (_class2 = function (_React$Component) {
  (0, _inherits3.default)(Wizard, _React$Component);

  function Wizard(props) {
    (0, _classCallCheck3.default)(this, Wizard);

    var _this = (0, _possibleConstructorReturn3.default)(this, (Wizard.__proto__ || (0, _getPrototypeOf2.default)(Wizard)).call(this, props));

    _this.state = {
      image: null
    };
    return _this;
  }

  (0, _createClass3.default)(Wizard, [{
    key: 'componentWillMount',
    value: function componentWillMount() {
      var _this2 = this;

      setTimeout(function () {
        _this2.setState({
          image: parseInt(_this2.props.params.image, 10)
        });
      });
    }
  }, {
    key: 'handleNext',
    value: function handleNext() {
      if (this.state.image + 1 <= 6) {
        this.setState({
          image: this.state.image + 1
        });
      } else {
        window.location = '/contacts/';
      }
    }
  }, {
    key: 'handleBack',
    value: function handleBack() {
      if (this.state.image > 1 && this.state.image <= 6) {
        this.setState({
          image: this.state.image - 1
        });
      } else {
        window.location = '/contacts/';
      }
    }
  }, {
    key: 'handleExit',
    value: function handleExit() {
      this.props.push('/contacts');
    }
  }, {
    key: 'render',
    value: function render() {
      var style = require('./Wizard.scss');
      if (!this.state.image) {
        return null;
      }

      var image = require('./' + this.state.image + '.png');

      return _react2.default.createElement(
        'div',
        null,
        _react2.default.createElement('img', { className: _Wizard2.default.wizard, src: image }),
        _react2.default.createElement(
          'div',
          { className: style.footer },
          _react2.default.createElement(
            'div',
            { className: style.left },
            this.state.image > 1 && _react2.default.createElement(
              'a',
              { className: style.button, onClick: this.handleBack },
              (0, _translations2.default)('WIZARD BACK')
            ),
            this.state.image == 1 && _react2.default.createElement(
              'a',
              { className: style.button + ' ' + style.exit, onClick: this.handleExit },
              (0, _translations2.default)('WIZARD EXIT')
            )
          ),
          _react2.default.createElement(
            'div',
            { className: style.right },
            _react2.default.createElement(
              'a',
              { className: style.button, onClick: this.handleNext },
              (0, _translations2.default)('WIZARD NEXT')
            )
          )
        )
      );
    }
  }]);
  return Wizard;
}(_react2.default.Component), (_applyDecoratedDescriptor(_class2.prototype, 'handleNext', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'handleNext'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, 'handleBack', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'handleBack'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, 'handleExit', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'handleExit'), _class2.prototype)), _class2)) || _class);
exports.default = Wizard;
module.exports = exports['default'];