'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ContactFileDetail = exports.default = undefined;

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _dec2, _class, _class2, _temp;

var _components = require('components');

var _Chat = require('containers/Chat/Chat');

var _Chat2 = _interopRequireDefault(_Chat);

var _reactHelmet = require('react-helmet');

var _reactHelmet2 = _interopRequireDefault(_reactHelmet);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _reduxConnect = require('redux-connect');

var _reactRedux = require('react-redux');

var _contact = require('redux/modules/contact');

var _reachHistory = require('../../helpers/reachHistory');

var _sessionPermissions = require('../../helpers/sessionPermissions');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var md = {
  arrowBack: require('react-icons/lib/md/arrow-back'),
  chat: require('react-icons/lib/md/chat')
};

var Contact = (_dec = (0, _reduxConnect.asyncConnect)([{
  key: 'contact',
  promise: function promise(_ref) {
    var id = _ref.params.id,
        _ref$store = _ref.store,
        dispatch = _ref$store.dispatch,
        getState = _ref$store.getState;

    var state = getState();
    return dispatch((0, _contact.getContactDetail)(id));
  }
}]), _dec2 = (0, _reactRedux.connect)(function (state) {
  return {
    mobileMode: state.mobile.mobileMode
  };
}, { push: _reachHistory.push }), _dec(_class = _dec2(_class = (_temp = _class2 = function (_React$Component) {
  (0, _inherits3.default)(Contact, _React$Component);

  function Contact(props) {
    (0, _classCallCheck3.default)(this, Contact);

    var _this = (0, _possibleConstructorReturn3.default)(this, (Contact.__proto__ || (0, _getPrototypeOf2.default)(Contact)).call(this, props));

    _this.handleConversationChange = function (event) {
      // this.setState({ [event.target.name] : true, [!event.target.name]: false })
      if (event.target.name == 'sms') {
        _this.setState({ sms: true, whatsapp: false });
      } else if (event.target.name == 'whatsapp') {
        _this.setState({ sms: false, whatsapp: true });
      }
    };

    _this.styles = require('./Contact.scss');
    _this.state = {
      sms: true,
      whatsapp: false
    };
    return _this;
  }

  (0, _createClass3.default)(Contact, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      if (!this.props.contact || !(this.props.contact.id === this.props.params.id || this.props.contact.msisdn === this.props.params.id || this.props.contact.email === this.props.params.id || this.props.contact.messenger_id === this.props.params.id)) {
        this.props.push({
          pathname: '/fail',
          state: {
            goTo: '/messages/',
            goToModal: false,
            message: (0, _translations2.default)('COULD NOT FIND CONTACT'),
            modal: true,
            returnTo: '/messages/'
          }
        });
      }
    }
  }, {
    key: 'renderTabs',
    value: function renderTabs() {
      var id = this.props.params.id;


      var indexTab = {
        'href': {
          pathname: '/contacts/' + id + '/',
          state: { modal: true, returnTo: '/contacts/' }
        },
        'title': (0, _translations2.default)('INDEX'),
        'is_index': true
      };

      var tagsTab = {
        'href': {
          pathname: '/contacts/' + id + '/tags',
          state: { modal: true, returnTo: '/contacts/' }
        },
        'title': (0, _translations2.default)('TAGS')
      };

      var varTab = {
        'href': {
          pathname: '/contacts/' + id + '/vars',
          state: { modal: true, returnTo: '/contacts/' }
        },
        'title': (0, _translations2.default)('VARS')
      };

      var permissionTab = [];

      if ((0, _sessionPermissions.sessionPermissions)('contacts:view')) {
        permissionTab.push(indexTab);
      }
      if ((0, _sessionPermissions.sessionPermissions)('contacts:tags:view')) {
        permissionTab.push(tagsTab);
      }
      if ((0, _sessionPermissions.sessionPermissions)('contacts:edit')) {
        permissionTab.push(varTab);
      }

      return _react2.default.createElement(_components.Tabs, { options: permissionTab });
    }
  }, {
    key: 'renderChat',
    value: function renderChat() {
      if (this.state.sms) {
        return _react2.default.createElement(_Chat2.default, { active: 'SMS' });
      } else if (this.state.whatsapp) {
        return _react2.default.createElement(_Chat2.default, { active: 'WHATSAPP' });
      }
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      var styles = this.styles;
      var _props = this.props,
          mobileMode = _props.mobileMode,
          contact = _props.contact;
      var id = this.props.params.id;


      if (!contact) {
        return null;
      }

      return _react2.default.createElement(
        'div',
        { className: styles.container },
        _react2.default.createElement(_components.MobileBar, { returnTo: '/contacts/', extra: '/chat/' + id, extraIcon: md.chat }),
        _react2.default.createElement(_reactHelmet2.default, { title: 'Interaccion Contacto' }),
        this.renderTabs(),
        _react2.default.createElement(
          'div',
          { className: styles.columns },
          !mobileMode ? _react2.default.createElement(
            'div',
            { className: styles.left },
            _react2.default.createElement(_components.ContactCard, { profile: contact.profile, msisdn: contact.msisdn, onChange: function onChange() {
                return _this2.handleConversationChange;
              }, active: this.state }),
            this.renderChat()
          ) : null,
          _react2.default.createElement(
            'div',
            { className: styles.right },
            this.props.children
          )
        )
      );
    }
  }]);
  return Contact;
}(_react2.default.Component), _class2.propTypes = {
  params: _propTypes2.default.object,
  children: _propTypes2.default.any.isRequired,
  mobileMode: _propTypes2.default.bool.isRequired
}, _temp)) || _class) || _class);
exports.default = Contact;

var ContactFileDetail = exports.ContactFileDetail = function (_React$Component2) {
  (0, _inherits3.default)(ContactFileDetail, _React$Component2);

  function ContactFileDetail() {
    (0, _classCallCheck3.default)(this, ContactFileDetail);
    return (0, _possibleConstructorReturn3.default)(this, (ContactFileDetail.__proto__ || (0, _getPrototypeOf2.default)(ContactFileDetail)).apply(this, arguments));
  }

  (0, _createClass3.default)(ContactFileDetail, [{
    key: 'render',
    value: function render() {
      return _react2.default.createElement(_components.Detail, this.props);
    }
  }]);
  return ContactFileDetail;
}(_react2.default.Component);