'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var getTimeFromMinutes = function getTimeFromMinutes(minutes) {
    var hours = Math.floor(minutes / 60);
    var roundedMinutes = Math.round(minutes % 60);
    var hoursText = hours === 1 ? '' : 's';
    var minText = roundedMinutes === 1 ? '' : 's';
    return hours > 0 ? hours + ' ' + (0, _translations2.default)('HOUR').toLowerCase() + hoursText + ' ' + roundedMinutes + ' ' + (0, _translations2.default)('MINUTES_FULL').toLowerCase() + minText : roundedMinutes + ' ' + (0, _translations2.default)('MINUTES_FULL').toLowerCase() + minText;
};

exports.default = getTimeFromMinutes;
module.exports = exports['default'];