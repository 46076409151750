'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _getOwnPropertyDescriptor = require('babel-runtime/core-js/object/get-own-property-descriptor');

var _getOwnPropertyDescriptor2 = _interopRequireDefault(_getOwnPropertyDescriptor);

var _promise = require('babel-runtime/core-js/promise');

var _promise2 = _interopRequireDefault(_promise);

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _desc, _value, _class, _class2, _temp;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _coreDecorators = require('core-decorators');

var _components = require('components');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
  var desc = {};
  Object['ke' + 'ys'](descriptor).forEach(function (key) {
    desc[key] = descriptor[key];
  });
  desc.enumerable = !!desc.enumerable;
  desc.configurable = !!desc.configurable;

  if ('value' in desc || desc.initializer) {
    desc.writable = true;
  }

  desc = decorators.slice().reverse().reduce(function (desc, decorator) {
    return decorator(target, property, desc) || desc;
  }, desc);

  if (context && desc.initializer !== void 0) {
    desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
    desc.initializer = undefined;
  }

  if (desc.initializer === void 0) {
    Object['define' + 'Property'](target, property, desc);
    desc = null;
  }

  return desc;
}

var styles = require('./Button.scss');

var Button = (_class = (_temp = _class2 = function (_React$Component) {
  (0, _inherits3.default)(Button, _React$Component);

  function Button(props) {
    (0, _classCallCheck3.default)(this, Button);

    var _this = (0, _possibleConstructorReturn3.default)(this, (Button.__proto__ || (0, _getPrototypeOf2.default)(Button)).call(this, props));

    _this.state = {
      pressed: false
      // pressed: (Math.random() > 0.5) ? true : false
    };
    return _this;
  }

  (0, _createClass3.default)(Button, [{
    key: 'handleClick',
    value: function handleClick() {
      var _this2 = this;

      if (!this.state.pressed) {
        this.setState((0, _extends3.default)({}, this.state, {
          pressed: true
        }));
        var promise = this.props.onClick();
        if (promise instanceof _promise2.default) {
          promise.then(function () {
            _this2.setState((0, _extends3.default)({}, _this2.state, {
              pressed: false
            }));
          });
        } else {
          this.setState((0, _extends3.default)({}, this.state, {
            pressed: false
          }));
        }
      }
    }
  }, {
    key: 'render',
    value: function render() {
      return _react2.default.createElement(
        'button',
        {
          className: styles.button + ' ' + styles[this.props.color] + ' ' + (this.state.pressed ? styles.active : '') + ' ' + this.props.className,
          onClick: this.handleClick,
          disabled: this.props.disabled
        },
        this.state.pressed ? _react2.default.createElement(_components.Loading, { className: styles.loading }) : this.props.name
      );
    }
  }]);
  return Button;
}(_react2.default.Component), _class2.propTypes = {
  color: _propTypes2.default.string,
  name: _propTypes2.default.string.isRequired,
  onClick: _propTypes2.default.func.isRequired,
  className: _propTypes2.default.string,
  disabled: _propTypes2.default.bool
}, _class2.defaultProps = {
  color: 'blue'
}, _temp), (_applyDecoratedDescriptor(_class.prototype, 'handleClick', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class.prototype, 'handleClick'), _class.prototype)), _class);
exports.default = Button;
module.exports = exports['default'];