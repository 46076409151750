'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _getOwnPropertyDescriptor = require('babel-runtime/core-js/object/get-own-property-descriptor');

var _getOwnPropertyDescriptor2 = _interopRequireDefault(_getOwnPropertyDescriptor);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _class, _desc, _value, _class2, _class3, _temp;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactRedux = require('react-redux');

var _coreDecorators = require('core-decorators');

var _interaction = require('redux/modules/interaction');

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _Allow = require('../../containers/Allow/Allow');

var _Allow2 = _interopRequireDefault(_Allow);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
  var desc = {};
  Object['ke' + 'ys'](descriptor).forEach(function (key) {
    desc[key] = descriptor[key];
  });
  desc.enumerable = !!desc.enumerable;
  desc.configurable = !!desc.configurable;

  if ('value' in desc || desc.initializer) {
    desc.writable = true;
  }

  desc = decorators.slice().reverse().reduce(function (desc, decorator) {
    return decorator(target, property, desc) || desc;
  }, desc);

  if (context && desc.initializer !== void 0) {
    desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
    desc.initializer = undefined;
  }

  if (desc.initializer === void 0) {
    Object['define' + 'Property'](target, property, desc);
    desc = null;
  }

  return desc;
}

var Configuration = (_dec = (0, _reactRedux.connect)(function (state) {
  return {
    questions: state.interaction.questions || [],
    goal: state.interaction.goal || {},
    files: state.interaction.files || [],
    max_sessions_per_profile: state.interaction.configuration.max_sessions_per_profile || 1,
    shortname_key: state.interaction.configuration.public_web_access.shortname_key,
    panel_source: state.interaction.configuration.panel_source || 'DATA_STORE',
    reward: state.interaction.configuration.reward || {}
  };
}, function (dispatch) {
  return {
    onInputStateUpdated: function onInputStateUpdated(property, event) {
      var value = event.target.value;
      if (value === parseInt(value, 10)) {
        value = parseInt(value, 10);
      }
      dispatch((0, _interaction.updateInteractionConfig)(property, value, 'goal'));
    },
    handleConfigChange: function handleConfigChange(property, value) {
      var val = property === 'reward' ? { enabled: value.target.value ? true : false } : value;
      dispatch((0, _interaction.updateInteractionConfig)(property, val));
    },
    handlePropertyChange: function handlePropertyChange(property, value) {
      dispatch((0, _interaction.updateInteraction)(property, value));
    },
    rewardConfigChange: function rewardConfigChange(property, initial_value, event) {
      var value = initial_value;
      value[property] = property === 'enabled' ? !value[property] : event.target.value;
      value.type = 'pin';
      dispatch((0, _interaction.updateInteractionConfig)('reward', value));
    }
  };
}), _dec(_class = (_class2 = (_temp = _class3 = function (_React$Component) {
  (0, _inherits3.default)(Configuration, _React$Component);

  function Configuration(props) {
    (0, _classCallCheck3.default)(this, Configuration);

    var _this = (0, _possibleConstructorReturn3.default)(this, (Configuration.__proto__ || (0, _getPrototypeOf2.default)(Configuration)).call(this, props));

    _this.panelTranslations = {
      OPEN: (0, _translations2.default)('OPEN PANEL'),
      FILE: (0, _translations2.default)('FILE PANEL'),
      DATA_STORE: (0, _translations2.default)('DATA STORE PANEL')
    };

    _this.duration_days = Array(15).fill().map(function (e, i) {
      return i + 1;
    });
    _this.duration_days.unshift(null);
    return _this;
  }

  (0, _createClass3.default)(Configuration, [{
    key: 'handleOpen',
    value: function handleOpen(event) {
      this.props.handleConfigChange('public_web_access', {
        enabled: true,
        shortname_key: event.target.value,
        shortname_enabled: true
      });
    }
  }, {
    key: 'handleKeywordChange',
    value: function handleKeywordChange(event) {
      this.props.handleConfigChange('keyword', event.target.value);
    }
  }, {
    key: 'handleMaxSessionsChange',
    value: function handleMaxSessionsChange(event) {
      this.props.handleConfigChange('max_sessions_per_profile', this.props.max_sessions_per_profile === 1000 ? 1 : 1000);
    }
  }, {
    key: 'renderOpenPanel',
    value: function renderOpenPanel() {
      return _react2.default.createElement(
        'div',
        { className: 'row name shareurl' },
        _react2.default.createElement(
          'div',
          { className: 'input-field col s12' },
          _react2.default.createElement(
            'span',
            null,
            (0, _translations2.default)('SHARE URL PREFIX')
          ),
          _react2.default.createElement('input', { type: 'text', value: this.props.shortname_key, maxLength: '48', onChange: this.handleOpen.bind(this), name: 'share-url' }),
          _react2.default.createElement(
            'label',
            { htmlFor: 'share-url' },
            (0, _translations2.default)('SHARE URL LABEL')
          )
        )
      );
    }
  }, {
    key: 'render',
    value: function render() {
      var styles = require('./Study.scss');
      var _props = this.props,
          questions = _props.questions,
          goal = _props.goal,
          onInputStateUpdated = _props.onInputStateUpdated,
          max_sessions_per_profile = _props.max_sessions_per_profile,
          batches = _props.batches,
          reward = _props.reward;

      var questionsToFilter = [_react2.default.createElement(
        'option',
        { key: 'NOFILTER', value: 'NOFILTER' },
        (0, _translations2.default)('NO FILTER')
      )];
      var batchesOptions = [];
      batchesOptions.push(_react2.default.createElement(
        'option',
        { value: '' },
        (0, _translations2.default)('SELECT BATCH')
      ));
      questions.forEach(function (question, index) {
        if (index < questions.length - 1) {
          questionsToFilter.push(_react2.default.createElement(
            'option',
            { key: index, value: question._id },
            (0, _translations2.default)('QUESTION'),
            ' ',
            question.question_number
          ));
        }
      });
      batches.forEach(function (batch, index) {
        batchesOptions.push(_react2.default.createElement(
          'option',
          { key: index, value: batch._id },
          batch.name
        ));
      });

      if (this.props.type === 'CAMPAIGN') {
        return _react2.default.createElement(
          'div',
          { className: 'block edit material-shadow' },
          _react2.default.createElement(
            'h5',
            { className: styles.title },
            (0, _translations2.default)('CONFIGURATION')
          ),
          _react2.default.createElement(
            'div',
            { className: styles.col },
            _react2.default.createElement('input', { id: 'questions-target', type: 'number', min: '1', step: '1', value: goal.questionnaires_target, onChange: onInputStateUpdated.bind(this, 'questionnaires_target') }),
            _react2.default.createElement(
              'label',
              { htmlFor: 'questions-target', className: styles.label },
              (0, _translations2.default)('GOAL QUESTIONS TARGET')
            )
          )
        );
      }
      return _react2.default.createElement(
        'div',
        { className: 'block edit material-shadow' },
        _react2.default.createElement(
          'h5',
          { className: styles.title },
          (0, _translations2.default)('CONFIGURATION')
        ),
        _react2.default.createElement(
          'div',
          { className: styles.row },
          _react2.default.createElement(
            'div',
            { className: styles.col + ' ' + styles.s4 },
            _react2.default.createElement(
              'div',
              { className: styles.select },
              _react2.default.createElement(
                'select',
                { className: styles.default, value: goal.duration || null, onChange: onInputStateUpdated.bind(this, 'duration') },
                this.duration_days.map(function (dd, index) {
                  return _react2.default.createElement(
                    'option',
                    { key: index, value: dd ? dd * 24 * 60 * 60 * 1000 : '' },
                    dd,
                    ' ',
                    dd ? (0, _translations2.default)('DAYS') : 'Sin expiración'
                  );
                })
              )
            ),
            _react2.default.createElement(
              'label',
              { htmlFor: 'questions-duration' },
              (0, _translations2.default)('GOAL DURATION TARGET')
            )
          ),
          _react2.default.createElement(
            'div',
            { className: styles.col },
            _react2.default.createElement('input', { id: 'questions-target', type: 'number', min: '1', step: '1', value: goal.questionnaires_target, onChange: onInputStateUpdated.bind(this, 'questionnaires_target') }),
            _react2.default.createElement(
              'label',
              { htmlFor: 'questions-target', className: styles.label },
              (0, _translations2.default)('GOAL QUESTIONS TARGET')
            )
          ),
          _react2.default.createElement(
            'div',
            { className: styles.col },
            _react2.default.createElement(
              'div',
              { className: styles.select },
              _react2.default.createElement('input', { type: 'checkbox', defaultChecked: this.props.max_sessions_per_profile === 1000, onChange: this.handleMaxSessionsChange })
            ),
            _react2.default.createElement(
              'label',
              { htmlFor: 'questions-filter', className: styles.label },
              (0, _translations2.default)('MAX SESSIONS TEXT')
            )
          )
        ),
        _react2.default.createElement(
          _Allow2.default,
          { features: ['reward-pin'] },
          _react2.default.createElement(
            'h5',
            { className: styles.title },
            (0, _translations2.default)('REWARDS')
          ),
          _react2.default.createElement(
            'div',
            { className: styles.row },
            _react2.default.createElement(
              'div',
              { className: styles.col + ' ' + styles.s4 },
              _react2.default.createElement(
                'div',
                { className: styles.col },
                _react2.default.createElement(
                  'div',
                  { className: styles.select },
                  _react2.default.createElement('input', { type: 'checkbox', defaultChecked: reward.enabled === true, value: reward.enabled || false, onChange: this.props.rewardConfigChange.bind(this, 'enabled', this.props.reward) })
                ),
                _react2.default.createElement(
                  'label',
                  { htmlFor: 'questions-filter', className: styles.label },
                  (0, _translations2.default)('ENABLE REWARD')
                )
              )
            ),
            _react2.default.createElement(
              'div',
              { className: styles.col + ' ' + styles.s4 },
              _react2.default.createElement(
                'div',
                { className: styles.select },
                _react2.default.createElement(
                  'select',
                  { className: styles.default, value: reward.batch_uid || null, onChange: this.props.rewardConfigChange.bind(this, 'batch_uid', this.props.reward) },
                  batchesOptions
                )
              ),
              _react2.default.createElement(
                'label',
                { htmlFor: 'questions-duration' },
                (0, _translations2.default)('SELECT BATCH')
              )
            )
          )
        )
      );
    }
  }]);
  return Configuration;
}(_react2.default.Component), _class3.propTypes = {
  questions: _propTypes2.default.array.isRequired,
  goal: _propTypes2.default.object.isRequired,
  files: _propTypes2.default.array.isRequired,
  keyword: _propTypes2.default.string,
  shortname_key: _propTypes2.default.string,
  handleConfigChange: _propTypes2.default.func.isRequired,
  handlePropertyChange: _propTypes2.default.func.isRequired,
  onInputStateUpdated: _propTypes2.default.func.isRequired,
  panels: _propTypes2.default.string,
  type: _propTypes2.default.string,
  reward: _propTypes2.default.object.isRequired,
  batches: _propTypes2.default.array
}, _class3.defaultProps = {
  type: 'STUDY',
  batches: []
}, _temp), (_applyDecoratedDescriptor(_class2.prototype, 'handleMaxSessionsChange', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'handleMaxSessionsChange'), _class2.prototype)), _class2)) || _class);
exports.default = Configuration;
module.exports = exports['default'];