'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _getOwnPropertyDescriptor = require('babel-runtime/core-js/object/get-own-property-descriptor');

var _getOwnPropertyDescriptor2 = _interopRequireDefault(_getOwnPropertyDescriptor);

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _dec2, _class, _desc, _value, _class2, _class3, _temp;
// import { getAccountStatus } from 'redux/modules/status'


var _auth = require('redux/modules/auth');

var _Allow = require('../Allow/Allow');

var _Allow2 = _interopRequireDefault(_Allow);

var _reactRouter = require('react-router');

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactSelect = require('react-select');

var _reactSelect2 = _interopRequireDefault(_reactSelect);

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _reduxConnect = require('redux-connect');

var _coreDecorators = require('core-decorators');

var _config = require('../../config');

var _config2 = _interopRequireDefault(_config);

var _reactRedux = require('react-redux');

var _featureEnabled = require('helpers/featureEnabled');

var _account = require('redux/modules/account');

var _themeEnabled = require('helpers/themeEnabled');

var _reachHistory = require('../../helpers/reachHistory');

var _sessionPermissions = require('../../helpers/sessionPermissions');

var _Account = require('./Account.scss');

var _Account2 = _interopRequireDefault(_Account);

var _partner = require('../../helpers/partner');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
  var desc = {};
  Object['ke' + 'ys'](descriptor).forEach(function (key) {
    desc[key] = descriptor[key];
  });
  desc.enumerable = !!desc.enumerable;
  desc.configurable = !!desc.configurable;

  if ('value' in desc || desc.initializer) {
    desc.writable = true;
  }

  desc = decorators.slice().reverse().reduce(function (desc, decorator) {
    return decorator(target, property, desc) || desc;
  }, desc);

  if (context && desc.initializer !== void 0) {
    desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
    desc.initializer = undefined;
  }

  if (desc.initializer === void 0) {
    Object['define' + 'Property'](target, property, desc);
    desc = null;
  }

  return desc;
}

var Account = (_dec = (0, _reduxConnect.asyncConnect)([{
  key: 'account',
  promise: function promise(_ref) {
    var _ref$store = _ref.store,
        dispatch = _ref$store.dispatch,
        getState = _ref$store.getState;

    var state = getState();
    if (!state.account.data.reach) {
      return dispatch((0, _account.getAccount)());
    }
    return null;
  }
}, {
  key: "auth",
  promise: function promise(_ref2) {
    var _ref2$store = _ref2.store,
        dispatch = _ref2$store.dispatch,
        getState = _ref2$store.getState;

    if (!(0, _auth.isLoaded)(getState())) {
      return dispatch((0, _auth.load)());
    }
    return null;
  }
}]), _dec2 = (0, _reactRedux.connect)(function (state) {
  return {
    auth: state.auth,
    account: state.account.data,
    currentAccount: state.auth.current_account
  };
}, { push: _reachHistory.push, logout: _auth.logout, changeAccount: _auth.changeAccount, getAccount: _account.getAccount }), _dec(_class = _dec2(_class = (_class2 = (_temp = _class3 = function (_React$Component) {
  (0, _inherits3.default)(Account, _React$Component);

  function Account() {
    (0, _classCallCheck3.default)(this, Account);
    return (0, _possibleConstructorReturn3.default)(this, (Account.__proto__ || (0, _getPrototypeOf2.default)(Account)).apply(this, arguments));
  }

  (0, _createClass3.default)(Account, [{
    key: 'handleLogout',
    value: function handleLogout() {
      this.props.push('/');
      this.props.logout().then(function () {
        window.location = '/';
      });
    }
  }, {
    key: 'navigateAfterChange',
    value: function navigateAfterChange(accountType) {
      if (accountType === 'Reseller') {
        window.location = _config2.default.basename + '/reseller';
        this.props.push('/reseller');
      } else {
        //this.props.push('/dashboard')
        window.location = _config2.default.basename + '/dashboard';
      }
    }
  }, {
    key: 'changeAccount',
    value: function changeAccount(element) {
      var _this2 = this;

      var accountType = this.props.currentAccount.account_type;
      this.props.changeAccount(element.value).then(function () {
        _this2.navigateAfterChange(accountType);
      }).catch(function () {
        _this2.navigateAfterChange(accountType);
      });
    }
  }, {
    key: 'render',
    value: function render() {
      var _props$location = this.props.location,
          pathname = _props$location.pathname,
          state = _props$location.state;


      if (!this.props.account.reach || !this.props.auth) {
        return null;
      }

      // Should after state check to prevent undefined account error
      var channels = this.props.account.reach.channels;

      var hasEmail = !!channels.find(function (channel) {
        return channel.type === 'EMAIL' && channel.status === 'ACTIVE';
      });

      var linkprops = {
        state: {
          modal: true,
          returnTo: pathname,
          modalSize: { width: '950px', height: '600px' }
        }
      };

      var classesTheme = (0, _themeEnabled.getThemeEnabledStyle)(_Account2.default);
      return _react2.default.createElement(
        'div',
        { className: classesTheme },
        _react2.default.createElement(
          'div',
          { className: _Account2.default.account },
          _react2.default.createElement(
            'div',
            { className: _Account2.default.menu },
            (0, _partner.partnerByHost)() !== 'tigo' && _react2.default.createElement(
              'div',
              { className: _Account2.default.user },
              _react2.default.createElement(
                'div',
                { className: _Account2.default.name },
                this.props.auth.data.user.first_name,
                ' ',
                this.props.auth.data.user.last_name
              ),
              _react2.default.createElement(
                'div',
                { className: _Account2.default.accountname, title: this.props.account.name },
                this.props.auth.data.account_list.name
              ),
              this.props.auth.data.account_list.length > 0 && _react2.default.createElement(
                'div',
                { className: _Account2.default.accountselector },
                _react2.default.createElement(_reactSelect2.default, {
                  className: _Account2.default.selector,
                  value: this.props.currentAccount.account_uid,
                  onChange: this.changeAccount,
                  clearable: false,
                  options: this.props.auth.data.account_list.map(function (a) {
                    return { value: a.account_uid, label: a.name + ' ' + (a.account_type === 'Reseller' ? a.account_type : '') };
                  })
                })
              )
            ),
            _react2.default.createElement(
              'div',
              { className: _Account2.default.itemlist },
              (0, _partner.partnerByHost)() !== 'tigo' ? _react2.default.createElement(
                _reactRouter.Link,
                { to: (0, _extends3.default)({}, linkprops, { pathname: '/account' }), className: _Account2.default.item, activeClassName: _Account2.default.active, onlyActiveOnIndex: true },
                (0, _translations2.default)('ACCOUNT DETAIL')
              ) : null,
              (0, _featureEnabled.featureEnabled)('channel-email') && hasEmail ? _react2.default.createElement(
                _Allow2.default,
                { permissions: ['account:configs:channels:sending:view'] },
                _react2.default.createElement(
                  _reactRouter.Link,
                  { to: (0, _extends3.default)({}, linkprops, { pathname: '/account/emailcustomizer' }), className: _Account2.default.item, activeClassName: _Account2.default.active },
                  (0, _translations2.default)('SENDING CHANNELS CONFIGURATION')
                )
              ) : null,
              (0, _featureEnabled.featureEnabled)('channel-web') ? _react2.default.createElement(
                _Allow2.default,
                { permissions: ['account:configs:channels:interaction'] },
                _react2.default.createElement(
                  _reactRouter.Link,
                  { to: (0, _extends3.default)({}, linkprops, { pathname: '/account/customizer' }), className: _Account2.default.item, activeClassName: _Account2.default.active },
                  (0, _translations2.default)('INTERACTION CHANNELS CONFIGURATION')
                )
              ) : null,
              _react2.default.createElement(
                _Allow2.default,
                { permissions: ['account:configs:api:view'] },
                _react2.default.createElement(
                  _reactRouter.Link,
                  { to: (0, _extends3.default)({}, linkprops, { pathname: '/account/apidetail' }), className: _Account2.default.item, activeClassName: _Account2.default.active },
                  (0, _translations2.default)('ACCOUNT API DETAIL')
                )
              ),
              _react2.default.createElement(
                _Allow2.default,
                { features: ['whatsapp'], permissions: ['account:configs:api:chat_api'] },
                _react2.default.createElement(
                  _reactRouter.Link,
                  { to: (0, _extends3.default)({}, linkprops, { pathname: '/account/whatsapp' }), className: _Account2.default.item, activeClassName: _Account2.default.active },
                  (0, _translations2.default)('CONFIG_CHAT_API')
                )
              ),
              (0, _featureEnabled.featureEnabled)('webhooks') ? _react2.default.createElement(
                _Allow2.default,
                { permissions: ['account:configs:callback:view'] },
                _react2.default.createElement(
                  _reactRouter.Link,
                  { to: (0, _extends3.default)({}, linkprops, { pathname: '/account/callback' }), className: _Account2.default.item, activeClassName: _Account2.default.active },
                  (0, _translations2.default)('ACCOUNT CALLBACK')
                )
              ) : null
            ),
            _react2.default.createElement(
              'div',
              { className: _Account2.default.logoutcontainer },
              _react2.default.createElement(
                'button',
                { onClick: this.handleLogout, className: _Account2.default.logout },
                (0, _translations2.default)('LOGOUT')
              )
            )
          ),
          _react2.default.createElement(
            'div',
            { className: _Account2.default.content },
            this.props.children
          )
        )
      );
    }
  }]);
  return Account;
}(_react2.default.Component), _class3.propTypes = {
  auth: _propTypes2.default.object.isRequired,
  account: _propTypes2.default.object.isRequired,
  push: _propTypes2.default.func.isRequired,
  logout: _propTypes2.default.func.isRequired,
  changeAccount: _propTypes2.default.func.isRequired,
  currentAccount: _propTypes2.default.object.isRequired
}, _temp), (_applyDecoratedDescriptor(_class2.prototype, 'handleLogout', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'handleLogout'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, 'changeAccount', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'changeAccount'), _class2.prototype)), _class2)) || _class) || _class);
exports.default = Account;
module.exports = exports['default'];