'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actions = undefined;

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

exports.default = reducer;
exports.getUnits = getUnits;
exports.setUnits = setUnits;
exports.stopProfiling = stopProfiling;
exports.unitSelect = unitSelect;
exports.unitUnselect = unitUnselect;
exports.getProfiles = getProfiles;

var _immutabilityHelper = require('immutability-helper');

var _immutabilityHelper2 = _interopRequireDefault(_immutabilityHelper);

var _apiAction = require('../apiAction');

var _apiAction2 = _interopRequireDefault(_apiAction);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// ACTIONS


var actions = exports.actions = new _apiAction2.default('WORKFLOWS', [_apiAction.ACTION_TYPES.DATA, _apiAction.ACTION_TYPES.APPEND, _apiAction.ACTION_TYPES.REPLACE]);

actions.ENABLE_UNIT = 'ENABLE_UNIT';
actions.DISABLE_UNIT = 'DISABLE_UNIT';

// REDUCER


var initialState = {
  loading: true,
  status: actions.REPLACE,
  data: {},
  list: [],
  selected: []
};

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case actions.ENABLE_UNIT:
      return (0, _immutabilityHelper2.default)(state, {
        list: (0, _defineProperty3.default)({}, action.index, { variable: { is_enabled: { $set: true } } })
      });
    case actions.DISABLE_UNIT:
      return (0, _immutabilityHelper2.default)(state, {
        list: (0, _defineProperty3.default)({}, action.index, { variable: { is_enabled: { $set: false } } })
      });
    default:
      return actions.getStateForAction(state, action);
  }
}

// ACTION CREATORS


function getUnits() {
  var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'CLOSED';

  return {
    types: [actions.REPLACE, actions.REPLACE_SUCCESS, actions.REPLACE_FAIL],
    // types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.get('/workflow/units/profiling?type=' + type);
    }
  };
}

function setUnits(name, keyword, panelFilter, units, message, sendingChannels) {
  var type = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : 'CLOSED';
  var subject = arguments[7];

  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.post('/profiling/job_descriptions?type=' + type, {
        data: {
          name: name,
          panel_filter: panelFilter,
          sending_channels: [sendingChannels],
          units: units,
          keyword: keyword,
          messages: {
            welcome: {
              message: message,
              config: {
                email_subject: subject
              }
            }
          }
        }
      });
    }
  };
}

function stopProfiling() {
  var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'CLOSED';

  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.post('/profiling/close?type=' + type, {
        data: {}
      });
    }
  };
}

function unitSelect(index) {
  return {
    type: actions.ENABLE_UNIT,
    index: index
  };
}

function unitUnselect(index) {
  return {
    type: actions.DISABLE_UNIT,
    index: index
  };
}

function getProfiles() {
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.get('/reports/reach/profiles');
    }
  };
}