'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _getOwnPropertyDescriptor = require('babel-runtime/core-js/object/get-own-property-descriptor');

var _getOwnPropertyDescriptor2 = _interopRequireDefault(_getOwnPropertyDescriptor);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _assign = require('babel-runtime/core-js/object/assign');

var _assign2 = _interopRequireDefault(_assign);

var _dec, _class, _desc, _value, _class2, _class3, _temp;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactRedux = require('react-redux');

var _reactSlider = require('react-slider');

var _reactSlider2 = _interopRequireDefault(_reactSlider);

var _coreDecorators = require('core-decorators');

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _interaction = require('redux/modules/interaction');

var _Question = require('./components/Question');

var _Question2 = _interopRequireDefault(_Question);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
  var desc = {};
  Object['ke' + 'ys'](descriptor).forEach(function (key) {
    desc[key] = descriptor[key];
  });
  desc.enumerable = !!desc.enumerable;
  desc.configurable = !!desc.configurable;

  if ('value' in desc || desc.initializer) {
    desc.writable = true;
  }

  desc = decorators.slice().reverse().reduce(function (desc, decorator) {
    return decorator(target, property, desc) || desc;
  }, desc);

  if (context && desc.initializer !== void 0) {
    desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
    desc.initializer = undefined;
  }

  if (desc.initializer === void 0) {
    Object['define' + 'Property'](target, property, desc);
    desc = null;
  }

  return desc;
}

var CONFIGURATION = {
  minQuestions: 1,
  maxQuestions: 30
};

var Questionnaire = (_dec = (0, _reactRedux.connect)(function (state) {
  return { questions: state.interaction.questions };
}, function (dispatch) {
  return {
    addQuestion: function addQuestion(questions) {
      var currentQuestions = questions.length;
      dispatch((0, _interaction.updateInteractionQuestions)(currentQuestions + 1));
    },
    changeQuestions: function changeQuestions(questions, event) {
      var numQuestions = 0;
      var currentQuestions = questions.length;

      if (event === parseInt(event, 10)) {
        numQuestions = parseInt(event, 10);
      } else {
        numQuestions = parseInt(event.target.value, 10);
        event.stopPropagation();
      }

      if (numQuestions < currentQuestions) {
        // we are deleting some questions
        var realNumQuestions = 0;
        questions.forEach(function (question) {
          if (question.text !== '' && question.options.length > 0) {
            realNumQuestions += 1;
          }
        });
        if (realNumQuestions <= numQuestions) {
          // deleting empty questions
          dispatch((0, _interaction.updateInteractionQuestions)(numQuestions));
        } else if (confirm((0, _translations2.default)('WARNING REMOVING %s QUESTIONS').replace('%s', realNumQuestions - numQuestions))) {
          // deleting filled questions
          // call to remove the last questions
          dispatch((0, _interaction.updateInteractionQuestions)(numQuestions));
        } else {
          // do nothing
        }
      } else {
        // call to add padding questions
        dispatch((0, _interaction.updateInteractionQuestions)(numQuestions));
      }
    }
  };
}, function (stateProps, dispatchProps, ownProps) {
  return (0, _assign2.default)({}, stateProps, ownProps, dispatchProps, {
    changeQuestions: dispatchProps.changeQuestions.bind(null, stateProps.questions)
  });
}), _dec(_class = (_class2 = (_temp = _class3 = function (_React$Component) {
  (0, _inherits3.default)(Questionnaire, _React$Component);

  function Questionnaire() {
    (0, _classCallCheck3.default)(this, Questionnaire);
    return (0, _possibleConstructorReturn3.default)(this, (Questionnaire.__proto__ || (0, _getPrototypeOf2.default)(Questionnaire)).apply(this, arguments));
  }

  (0, _createClass3.default)(Questionnaire, [{
    key: 'addQuestion',
    value: function addQuestion() {
      var questions = this.props.questions;

      this.props.addQuestion(questions);
    }
  }, {
    key: 'render',
    value: function render() {
      var _props = this.props,
          questions = _props.questions,
          changeQuestions = _props.changeQuestions;
      var minQuestions = CONFIGURATION.minQuestions,
          maxQuestions = CONFIGURATION.maxQuestions;

      var numQuestions = questions.length < maxQuestions ? questions.length : maxQuestions;
      var questionsComponent = [];
      var questionSlider = [];
      for (var i = minQuestions; i <= maxQuestions; i += 1) {
        questionSlider.push(_react2.default.createElement(
          'option',
          { value: i, key: i },
          i
        ));
      }
      questions.forEach(function (questionData) {
        return questionsComponent.push(_react2.default.createElement(_Question2.default, {
          key: questionData._id,
          question: questionData
        }));
      });
      return _react2.default.createElement(
        'div',
        { className: 'edit' },
        _react2.default.createElement(
          'div',
          { className: 'block question-slider material-shadow' },
          _react2.default.createElement(
            'h5',
            null,
            (0, _translations2.default)('QUESTIONNAIRE')
          ),
          _react2.default.createElement(
            'div',
            { className: 'row questions-selector' },
            _react2.default.createElement(
              'div',
              { className: 'col s2 select' },
              _react2.default.createElement(
                'select',
                { className: 'browser-default', value: numQuestions, onChange: changeQuestions },
                questionSlider
              )
            ),
            _react2.default.createElement(
              'div',
              { className: 'col s10 noselect' },
              _react2.default.createElement(_reactSlider2.default, { value: numQuestions, min: 1, max: maxQuestions, onChange: changeQuestions })
            )
          )
        ),
        _react2.default.createElement(
          'div',
          { className: 'questions-container' },
          _react2.default.createElement(
            'ul',
            { className: 'edit-items' },
            questionsComponent
          ),
          questions.length < maxQuestions ? _react2.default.createElement(
            'button',
            { className: 'block add-question', onClick: this.addQuestion },
            (0, _translations2.default)('ADD QUESTION')
          ) : null
        )
      );
    }
  }]);
  return Questionnaire;
}(_react2.default.Component), _class3.propTypes = {
  questions: _propTypes2.default.array.isRequired,
  changeQuestions: _propTypes2.default.func.isRequired,
  addQuestion: _propTypes2.default.func.isRequired
}, _temp), (_applyDecoratedDescriptor(_class2.prototype, 'addQuestion', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'addQuestion'), _class2.prototype)), _class2)) || _class);
exports.default = Questionnaire;
module.exports = exports['default'];