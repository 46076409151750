'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _getOwnPropertyDescriptor = require('babel-runtime/core-js/object/get-own-property-descriptor');

var _getOwnPropertyDescriptor2 = _interopRequireDefault(_getOwnPropertyDescriptor);

var _stringify = require('babel-runtime/core-js/json/stringify');

var _stringify2 = _interopRequireDefault(_stringify);

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _dec2, _class, _desc, _value, _class2, _class3, _temp;

var _components = require('components');

var _profiling = require('redux/modules/profiling');

var _variables = require('redux/modules/variables');

var _Contacts = require('./Contacts');

var _reactRouter = require('react-router');

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactHighcharts = require('react-highcharts');

var _reactHighcharts2 = _interopRequireDefault(_reactHighcharts);

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _reduxConnect = require('redux-connect');

var _coreDecorators = require('core-decorators');

var _reactRedux = require('react-redux');

var _featureEnabled = require('helpers/featureEnabled');

var _workflows = require('redux/modules/workflows');

var _reachHistory = require('../../helpers/reachHistory');

var _Contacts2 = require('./Contacts.scss');

var _Contacts3 = _interopRequireDefault(_Contacts2);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
  var desc = {};
  Object['ke' + 'ys'](descriptor).forEach(function (key) {
    desc[key] = descriptor[key];
  });
  desc.enumerable = !!desc.enumerable;
  desc.configurable = !!desc.configurable;

  if ('value' in desc || desc.initializer) {
    desc.writable = true;
  }

  desc = decorators.slice().reverse().reduce(function (desc, decorator) {
    return decorator(target, property, desc) || desc;
  }, desc);

  if (context && desc.initializer !== void 0) {
    desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
    desc.initializer = undefined;
  }

  if (desc.initializer === void 0) {
    Object['define' + 'Property'](target, property, desc);
    desc = null;
  }

  return desc;
}

var md = {
  search: require('react-icons/lib/md/search'),
  moreVert: require('react-icons/lib/md/more-vert'),
  gesture: require('react-icons/lib/md/label-outline'),
  tab: require('react-icons/lib/md/launch'),
  settings: require('react-icons/lib/md/settings')
};

var defaultChartConfig = {
  colors: ['#86D0FA', '#2EB0F6', '#139CF4', '#0771AB', '#599ABD', '#033A58', '#C7EBFF'],
  chart: {
    plotShadow: false,
    type: 'pie',
    style: { margin: '0 auto' }
  },
  title: {
    text: ''
  },
  tooltip: {
    pointFormat: '{series.name}: <b>{point.y}</b>'
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      dataLabels: {
        enabled: false
      }
    }
  },
  legend: {
    enabled: false
  },
  credits: {
    enabled: false
  }
};

var variablesIcons = {
  gender: require('./variables/gender.svg'),
  has_children: require('./variables/has_children.svg'),
  size: require('./variables/size.svg'),
  interests: require('./variables/interests.svg'),
  marital_status: require('./variables/marital_status.svg'),
  interaction_channel: require('./variables/interaction_channel.svg'),
  internet: require('./variables/internet.svg'),
  social_networks: require('./variables/social_networks.svg'),
  occupation: require('./variables/occupation.svg'),
  education: require('./variables/education.svg'),
  type_house: require('./variables/type_house.svg'),
  payment_method: require('./variables/payment_method.svg'),
  transport: require('./variables/transport.svg'),
  insurance_type: require('./variables/insurance_type.svg'),
  events: require('./variables/events.svg'),
  hobbys: require('./variables/hobbys.svg'),
  sports: require('./variables/sports.svg'),
  traveler: require('./variables/traveler.svg'),
  first_name: require('./variables/first_name.svg'),
  last_name: require('./variables/last_name.svg'),
  msisdn: require('./variables/phone.svg'),
  email: require('./variables/email.svg'),
  month_of_birth: require('./variables/birthday.svg')
};

var ContactsVariables = (_dec = (0, _reduxConnect.asyncConnect)([{
  key: 'profilings',
  promise: function promise(_ref) {
    var _ref$store = _ref.store,
        dispatch = _ref$store.dispatch,
        getState = _ref$store.getState;

    dispatch((0, _profiling.profilingRefresh)());
    return dispatch((0, _profiling.getProfilingJobs)(0, 5));
  }
}]), _dec2 = (0, _reactRedux.connect)(function (state) {
  return {
    mobileMode: state.mobile.mobileMode,
    selected: state.variables.selected,
    list: state.variables.list,
    workflows: state.workflows.data,
    account: state.auth.current_account,
    isLoading: state.variables.loading
  };
}, {
  get: _variables.getVariables,
  variablesSelectSingle: _variables.variablesSelectSingle,
  pushState: _reachHistory.push,
  variablesUnselectAll: _variables.variablesUnselectAll,
  getProfiles: _workflows.getProfiles,
  profilingRefresh: _profiling.profilingRefresh,
  stopProfiling: _workflows.stopProfiling
}), _dec(_class = _dec2(_class = (_class2 = (_temp = _class3 = function (_List) {
  (0, _inherits3.default)(ContactsVariables, _List);

  function ContactsVariables(props) {
    (0, _classCallCheck3.default)(this, ContactsVariables);

    var _this = (0, _possibleConstructorReturn3.default)(this, (ContactsVariables.__proto__ || (0, _getPrototypeOf2.default)(ContactsVariables)).call(this, props));

    _this.title = (0, _translations2.default)('MY VARIABLES');

    _this.state = {
      search: ''
    };
    return _this;
  }

  (0, _createClass3.default)(ContactsVariables, [{
    key: 'componentWillMount',
    value: function componentWillMount() {
      if (__CLIENT__) {
        this.props.profilingRefresh();
        if (this.props.list.length === 0) {
          this.props.get();
        }
        this.props.getProfiles();
      }
    }
  }, {
    key: 'stopProfiling',
    value: function stopProfiling(open) {
      var _this2 = this;

      var t = open ? 'OPEN' : 'CLOSED';
      this.props.stopProfiling(t).then(function () {
        _this2.props.pushState({
          pathname: '/success',
          state: {
            goTo: '/contacts/profiles',
            goToModal: false,
            message: (0, _translations2.default)('PROFILING STOP SUCCESS'),
            modal: true,
            returnTo: '/contacts/profiles'
          }
        });
      });
    }
  }, {
    key: 'setActive',
    value: function setActive(index) {
      if (this.props.selected.indexOf(index) > -1) {
        this.props.variablesUnselectAll();
      } else {
        this.props.variablesSelectSingle(index);
      }
    }
  }, {
    key: 'isSelecting',
    value: function isSelecting() {
      return false;
    }
  }, {
    key: 'handleSearch',
    value: function handleSearch(event) {
      this.setState({
        search: event.target.value
      });
    }
  }, {
    key: 'handleClear',
    value: function handleClear() {
      this.beginSearch('');
    }
  }, {
    key: 'renderNav',
    value: function renderNav(stickyprops) {
      var props = this.props;
      var is_open = props.account.account_type == "Subaccount" && props.account.is_default_subaccount && props.account.reach_enabled || props.account.account_type != "Subaccount";

      return _react2.default.createElement(
        _components.Nav,
        { key: 'Nav', title: (0, _translations2.default)('MY PROFILING'), style: stickyprops.style },
        (0, _featureEnabled.featureEnabled)('profilings') ? _react2.default.createElement(
          _reactRouter.Link,
          { className: 'action', to: {
              pathname: is_open ? '/profiling/choose' : '/profiling/open',
              state: { modal: true, returnTo: '/contacts/profiles', fullModal: !is_open, modalSize: { width: '300px', height: '555px' } }
            }, style: { marginLeft: 'auto' } },
          (0, _translations2.default)('PROFILING CONFIG'),
          _react2.default.createElement(md.settings, { size: 20 })
        ) : null
      );
    }
  }, {
    key: 'renderTabs',
    value: function renderTabs() {
      return _react2.default.createElement(_Contacts.ContactTabs, null);
    }
  }, {
    key: 'renderExtra',
    value: function renderExtra() {
      var _this3 = this;

      if (!this.props.profilings) {
        return null;
      }

      this.activeProfilings = this.props.profilings.filter(function (item) {
        return item.status === 'ACTIVE';
      });
      return _react2.default.createElement(
        'div',
        { className: _Contacts3.default.stopButtons },
        (0, _featureEnabled.featureEnabled)('profilings') ? _react2.default.createElement(
          _reactRouter.Link,
          (0, _defineProperty3.default)({ className: 'action', to: '/contacts/profiles/historical' }, 'className', _Contacts3.default.profilesListButton),
          _react2.default.createElement(
            'div',
            { className: _Contacts3.default.profilesList },
            (0, _translations2.default)('HISTORIC PROFILES')
          )
        ) : null,
        this.activeProfilings.map(function (p, index) {
          if (p.panel_source === 'OPEN') {
            return _react2.default.createElement(
              'div',
              { className: _Contacts3.default.url, key: 'stop-' + index },
              _react2.default.createElement(
                'div',
                { className: _Contacts3.default.running },
                (0, _translations2.default)('OPEN PROFILING RUNNING'),
                ': ',
                p.name
              ),
              p.public_web_access.enabled && p.public_web_access.short_uri ? _react2.default.createElement(
                'div',
                { className: _Contacts3.default.shareLink },
                (0, _translations2.default)('PUBLIC URL FOR PROFILING') + ':',
                ' ',
                _react2.default.createElement(
                  'a',
                  { className: _Contacts3.default.TestLink, target: '_blank', href: p.public_web_access.short_uri },
                  p.public_web_access.short_uri
                ),
                ' ',
                _react2.default.createElement(md.tab, { size: 20, color: '#009BF7' })
              ) : null,
              p.by_keyword && p.by_keyword.allow_join_by_keyword ? _react2.default.createElement(
                'div',
                { className: _Contacts3.default.shareLink },
                (0, _translations2.default)('PUBLIC KEYWORD FOR PROFILING') + ':',
                ' ',
                _react2.default.createElement(
                  'strong',
                  null,
                  p.by_keyword.keyword
                )
              ) : null,
              _react2.default.createElement(
                'a',
                { className: _Contacts3.default.stop, onClick: _this3.stopProfiling.bind(_this3, true) },
                (0, _translations2.default)('STOP OPEN PROFILING RUNNING')
              )
            );
          }
          return _react2.default.createElement(
            'div',
            { className: _Contacts3.default.url, key: 'stop-' + index },
            _react2.default.createElement(
              'div',
              { className: _Contacts3.default.running },
              (0, _translations2.default)('CLOSED PROFILING RUNNING'),
              ': ',
              p.name
            ),
            _react2.default.createElement(
              'a',
              { className: _Contacts3.default.stop, onClick: _this3.stopProfiling.bind(_this3, false) },
              (0, _translations2.default)('STOP CLOSED PROFILING RUNNING')
            )
          );
        })
      );
    }
  }, {
    key: 'renderInfiniteList',
    value: function renderInfiniteList() {
      var _this4 = this;

      if (__SERVER__) {
        return _react2.default.createElement(_components.VirtualListItemLoad, null);
      }

      var variablesList = this.props.list.filter(function (item) {
        if (!item.printable_value) return false;
        return item.printable_value.text.toUpperCase().indexOf(_this4.state.search.toUpperCase()) > -1;
      });

      var hidden = { display: 'none' };
      var visible = { display: 'block' };
      var infiniteStyle = this.props.isLoading || variablesList.length > 0 ? visible : hidden;
      var linkstyle = this.props.isLoading || variablesList.length > 0 ? hidden : visible;
      var randomColors = ['#BDE2EA', '#449AA7', '#79C268', '#717171', '#F5D63D', '#F18C32', '#DB4437', '#BF63A6', '#6633CC'];

      return [_react2.default.createElement(
        'div',
        { key: 'infinite-list', style: infiniteStyle },
        variablesList.reduce(function (prevValue, itemClass, indexClass) {
          var newValue = [];
          if (itemClass.rule.type !== 'OPTION' && itemClass.name !== 'first_name' && itemClass.name !== 'last_name' && itemClass.name !== 'msisdn' && itemClass.name !== 'email') {
            return prevValue;
          }
          var workflows = _this4.props.workflows || {};
          var values = {};
          var total = 0;

          if (workflows[itemClass.name] && workflows[itemClass.name].buckets) {
            workflows[itemClass.name].buckets.forEach(function (bucket) {
              values[bucket.key] = bucket.doc_count;
              total += bucket.doc_count;
            });
          }

          newValue.push(_react2.default.createElement(_components.VirtualListItemVariable, {
            key: indexClass + '.0',
            index: indexClass,
            icon: variablesIcons[itemClass.name] ? _react2.default.createElement('div', { className: _Contacts3.default.variableicon, dangerouslySetInnerHTML: { __html: variablesIcons[itemClass.name] } }) : null,
            text: '' + itemClass.printable_value.text,
            count: total || 0,
            handleicon: itemClass.name !== 'first_name' && itemClass.name !== 'last_name' && itemClass.name !== 'msisdn' && itemClass.name !== 'email' ? _react2.default.createElement(md.moreVert, { size: 30 }) : null,
            mobileMode: _this4.props.mobileMode,
            display: true,
            setActive: _this4.setActive,
            onClick: function onClick(event) {
              event.preventDefault();
              if (itemClass.values) {
                _this4.setActive(indexClass);
              }
              // else {
              //  this.props.pushState(`/contacts?search=${itemClass.name}:${itemClass.value}`)
              // }
            }
          }));
          if (itemClass.rule && itemClass.rule.type === 'OPTION' && Array.isArray(itemClass.values)) {
            var display = _this4.props.selected.indexOf(indexClass) > -1;
            var padding = 50 * itemClass.values.length;
            if (padding > 250) {
              padding = 250;
            }
            var colors = randomColors;
            var chartWidth = padding - 1 + 'px'; // -1 for border
            var chartConfig = JSON.parse((0, _stringify2.default)(defaultChartConfig));
            chartConfig.colors = randomColors;
            chartConfig.chart.width = padding;
            chartConfig.chart.height = padding;
            chartConfig.series = [{
              showInLegend: false,
              name: 'perfiles',
              data: itemClass.values.map(function (item, index) {
                return { y: values[item.value] || 0, name: item.printable_value.text };
              })
            }];
            newValue.push(_react2.default.createElement(
              'div',
              { key: indexClass + '-chart', className: _Contacts3.default.chartContainer, style: { display: display ? 'flex' : 'none', width: chartWidth, height: 50 * itemClass.values.length } },
              _react2.default.createElement(_reactHighcharts2.default, { className: _Contacts3.default.chart, config: chartConfig })
            ));
            newValue.push.apply(newValue, itemClass.values.map(function (item, index) {
              return _react2.default.createElement(_components.VirtualListItemVariableOption, {
                key: indexClass + '-option-' + index,
                index: index,
                count: values[item.key],
                percent: total && values[item.value] ? values[item.value] / total : 0,
                text: item.printable_value.text,
                color: colors[index % colors.length],
                display: display,
                marginLeft: chartWidth
              });
            }));
          }
          return prevValue.concat(newValue);
        }, [])
      ), _react2.default.createElement(_components.Linkholder, {
        key: 'linkholder',
        style: linkstyle,
        to: {
          pathname: '/contacts/add',
          state: { modal: true, returnTo: '/contacts/' }
        },
        src: '/static/empty/tags.svg',
        text: this.state.search === '' ? (0, _translations2.default)('NO VARIABLES FOUND') : (0, _translations2.default)('NO VARIABLES FOUND SEARCH'),
        button: (0, _translations2.default)('ADD A NEW CONTACT VARIABLES')
      })];
    }
  }]);
  return ContactsVariables;
}(_components.List), _class3.propTypes = {
  selected: _propTypes2.default.array.isRequired,
  list: _propTypes2.default.array.isRequired,
  isLoading: _propTypes2.default.bool.isRequired,
  get: _propTypes2.default.func.isRequired,
  variablesSelectSingle: _propTypes2.default.func.isRequired,
  variablesUnselectAll: _propTypes2.default.func.isRequired
}, _temp), (_applyDecoratedDescriptor(_class2.prototype, 'setActive', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'setActive'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, 'handleSearch', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'handleSearch'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, 'handleClear', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'handleClear'), _class2.prototype)), _class2)) || _class) || _class);
exports.default = ContactsVariables;
module.exports = exports['default'];