'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _dec2, _class, _class2, _temp;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactRedux = require('react-redux');

var _reduxConnect = require('redux-connect');

var _reactRouter = require('react-router');

var _numbro = require('numbro');

var _numbro2 = _interopRequireDefault(_numbro);

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _components = require('components');

var _variables = require('redux/modules/variables');

var _reports = require('redux/modules/reports');

var _shortLink = require('redux/modules/shortLink');

var _status = require('redux/modules/status');

var _account = require('redux/modules/account');

var _auth = require('redux/modules/auth');

var _featureEnabled = require('helpers/featureEnabled');

var _themeEnabled = require('helpers/themeEnabled');

var _Reports = require('./Reports1.scss');

var _Reports2 = _interopRequireDefault(_Reports);

var _BalanceInfo = require('./BalanceInfo');

var _BalanceInfo2 = _interopRequireDefault(_BalanceInfo);

var _Allow = require('../Allow/Allow');

var _Allow2 = _interopRequireDefault(_Allow);

var _ContactsProfilesHistorical = require('../Contacts/ContactsProfilesHistorical');

var _ContactsProfilesHistorical2 = _interopRequireDefault(_ContactsProfilesHistorical);

var _useBasename = require('../../helpers/useBasename');

var _useBasename2 = _interopRequireDefault(_useBasename);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var md = {
  pieChart: require('react-icons/lib/md/pie-chart')
};

var DefaultReport = (_dec = (0, _reduxConnect.asyncConnect)([{
  key: 'account',
  promise: function promise(_ref) {
    var _ref$store = _ref.store,
        dispatch = _ref$store.dispatch,
        getState = _ref$store.getState;

    var state = getState();
    if (!state.account.data.account_id) {
      return dispatch((0, _status.getAccountStatus)());
    }
    return null;
  }
}, {
  key: "auth",
  promise: function promise(_ref2) {
    var _ref2$store = _ref2.store,
        dispatch = _ref2$store.dispatch,
        getState = _ref2$store.getState;

    if (!(0, _auth.isLoaded)(getState())) {
      return dispatch((0, _auth.load)());
    }
    return null;
  }
}]), _dec2 = (0, _reactRedux.connect)(function (state) {
  return {
    mobileMode: state.mobile.mobileMode,
    reports: state.reports,
    accountIsLoading: state.account.loading,
    account: state.status.data,
    currentAccount: state.account.data,
    reach: state.account.data.reach,
    auth: state.auth.current_account,
    status: state.status
  };
}, {
  getTrafficKPIs: _reports.getTrafficKPIs, getBalanceByDate: _reports.getBalanceByDate, getReachStats: _reports.getReachStats, getVariables: _variables.getVariables, getTotalContacts: _reports.getTotalContacts, getContactsCancelledCount: _reports.getContactsCancelledCount, getAccountStatus: _status.getAccountStatus,
  getTrafficDaily: _reports.getTrafficDaily, getAllBalanceByDate: _reports.getAllBalanceByDate, getAccount: _account.getAccount, getShortLinks: _shortLink.getShortLinks

}), _dec(_class = _dec2(_class = (_temp = _class2 = function (_ReportList) {
  (0, _inherits3.default)(DefaultReport, _ReportList);

  function DefaultReport(props) {
    (0, _classCallCheck3.default)(this, DefaultReport);

    var _this = (0, _possibleConstructorReturn3.default)(this, (DefaultReport.__proto__ || (0, _getPrototypeOf2.default)(DefaultReport)).call(this, props));

    _this.handleChange = function (event, value) {
      _this.setState({ value: value });
    };

    _this.updateBalance = function () {
      _this.props.getBalanceByDate((0, _moment2.default)(_this.state.startDate).format('YYYY-MM-DD HH:mm:ss')).then(function (balance) {
        var data = {};
        if (balance) {
          if (balance) {
            var credit_base_limit = balance.credit_base_limit,
                credit_limit = balance.credit_limit,
                credit_extra_limit = balance.credit_extra_limit;

            var cycle = 'del ' + (0, _moment2.default)(balance.billing_cycle.valid_since).format('YYYY-MM-DD') + ' al ' + (0, _moment2.default)(balance.billing_cycle.valid_thru).format('YYYY-MM-DD');
            var packageMessages = credit_base_limit;
            var usedMessages = credit_limit - balance.balance;
            var availableMessages = usedMessages < credit_base_limit ? balance.balance - credit_extra_limit : 0;
            var availableExtras = usedMessages > credit_base_limit ? credit_limit - usedMessages : credit_extra_limit;
            var base_percent = credit_base_limit ? Math.floor(availableMessages / credit_base_limit * 100).toFixed(2) : 0;
            var extras_percent = credit_extra_limit ? Math.floor(availableExtras / credit_extra_limit * 100).toFixed(2) : 0;
            var sms = {
              messages: {
                percent: base_percent,
                remaining: _this.f(availableMessages),
                package: _this.f(packageMessages)
              },
              extras: {
                percent: extras_percent,
                consumed: _this.f(credit_extra_limit - availableExtras),
                remaining: _this.f(availableExtras),
                limit: _this.f(credit_extra_limit)
              },
              cycle: cycle
            };
            var email = balance.email;
            data.sms = sms;
            data.email = email;
          }
        }
        _this.setState((0, _extends3.default)({}, _this.state, {
          balance: balance,
          data: data
        }));
      }).catch(function (e) {
        console.error('Could not get balance data', e);
        _this.setState((0, _extends3.default)({}, _this.state, {
          balance: false,
          data: {}
        }));
      });
    };

    _this.f = function (n) {
      return (0, _numbro2.default)(n).format(_this.format);
    };

    _this.state = (0, _extends3.default)({}, _this.state, {
      dailyData: [],
      traffic: true,
      balance: true,
      reach: true,
      variables: null,
      contacts: null,
      cancelled: null,
      data: {},
      reachAccount: null,
      account_balances: {},
      shortlink_list: [],
      charge_type: null,
      filtering: false,
      cycle: 'Ciclo actual'
    });

    if (_this.props.account && _this.props.account.current_package) {
      _this.state.startDate = (0, _moment2.default)(_this.props.account.current_package.valid_since);
      _this.state.endDate = (0, _moment2.default)(_this.props.account.current_package.valid_thru);
    }

    _this.format = '';
    return _this;
  }

  (0, _createClass3.default)(DefaultReport, [{
    key: 'formatNumber',
    value: function formatNumber(n) {
      return (0, _numbro2.default)(n).format(this.format);
    }
  }, {
    key: 'componentDidMount',
    value: function componentDidMount() {
      try {
        if (this.props.reach.billing.charge_type) {
          this.setState({ charge_type: this.props.reach.billing.charge_type });
        }
      } catch (e) {
        console.log('could no get prepaid info', e);
      }
      //this.props.reach.billing.charge_type
      if (__CLIENT__) {
        this.updateReport();
        this.updateBalance();
      }
      this.getAllBalances();
    }
  }, {
    key: 'onDateChange',
    value: function onDateChange() {
      var _this2 = this;

      this.setState((0, _extends3.default)({}, this.state, {
        filtering: false
      }), function () {
        _this2.updateReport(), _this2.updateBalance();
        _this2.getAllBalances();
      });
    }
  }, {
    key: 'getAllBalances',
    value: function getAllBalances() {
      var _this3 = this;

      var params = {};
      this.props.getAccount().then(function (reachAccount) {
        if (reachAccount) {
          _this3.setState({ reachAccount: reachAccount });
        }
      }).then(function () {
        var routerDate = (0, _moment2.default)(); // actual
        if (_this3.state.cycle === (0, _translations2.default)('LAST WEEK')) {
          // ultimo
          routerDate = (0, _moment2.default)().subtract(1, 'month');
        } else if (_this3.state.cycle === (0, _translations2.default)('LAST MONTH')) {
          // penultimo
          routerDate = (0, _moment2.default)().subtract(2, 'month');
        } else if (_this3.state.cycle === (0, _translations2.default)('LAST YEAR')) {
          // antepenultimo
          routerDate = (0, _moment2.default)().subtract(3, 'month');
        } else if (_this3.state.cycle === 'Personalizado') {
          // antepenultimo
          routerDate = _this3.state.startDate;
        }
        params = {
          charge_type: _this3.props.reach.billing.charge_type,
          account_uid: _this3.state.reachAccount._id,
          date: routerDate.format('YYYY-MM-DD')
        };
        _this3.props.getShortLinks().then(function (list) {
          _this3.setState({
            shortlink_list: list
          });
        });
        _this3.props.getAllBalanceByDate(params).then(function (account_balances) {
          _this3.setState({ account_balances: account_balances });
        });
      }).catch(function () {
        return Alert.alert("error");
      });
    }
  }, {
    key: 'updateReport',
    value: function updateReport() {
      var _this4 = this;

      this.props.getTrafficKPIs(this.state.startDate, this.state.endDate).then(function (traffic) {
        _this4.setState((0, _extends3.default)({}, _this4.state, {
          traffic: traffic
        }));
      }).catch(function (e) {
        console.error('Could not get traffic data', e);
        _this4.setState((0, _extends3.default)({}, _this4.state, {
          traffic: false
        }));
      });
      this.props.getReachStats(this.state.startDate, this.state.endDate).then(function (reach) {
        _this4.setState((0, _extends3.default)({}, _this4.state, {
          reach: reach
        }));
      }).catch(function (e) {
        console.error('Could not get reach data', e);
        _this4.setState((0, _extends3.default)({}, _this4.state, {
          reach: false
        }));
      });
      this.props.getVariables().then(function (variables) {
        _this4.setState((0, _extends3.default)({}, _this4.state, {
          variables: variables.length
        }));
      });
      this.props.getTotalContacts(this.state.startDate, this.state.endDate).then(function (response) {
        _this4.props.getContactsCancelledCount(_this4.state.startDate, _this4.state.endDate).then(function (response2) {
          _this4.setState((0, _extends3.default)({}, _this4.state, {
            contacts: response.total,
            cancelled: response2.count
          }));
        });
      });
      this.props.getTrafficDaily(this.state.startDate, this.state.endDate).then(function (response) {
        _this4.setState((0, _extends3.default)({}, _this4.state, {
          dailyData: response
        }));
      });
    }
  }, {
    key: 'handleDateChange',
    value: function handleDateChange(cycle, startDate, endDate) {
      if (startDate && endDate) {
        this.setState((0, _extends3.default)({}, this.state, {
          startDate: startDate,
          endDate: endDate,
          cycle: cycle
        }), this.onDateChange);
      } else if (startDate) {
        this.setState((0, _extends3.default)({}, this.state, {
          startDate: startDate,
          endDate: startDate,
          cycle: 'Personalizado'
        }), this.onDateChange);
      } else if (endDate) {
        this.setState((0, _extends3.default)({}, this.state, {
          endDate: endDate,
          cycle: 'Personalizado'
        }), this.onDateChange);
      }
    }
  }, {
    key: 'handleNav',
    value: function handleNav() {
      this.setState({
        filtering: true
      });
    }
  }, {
    key: 'renderNav',
    value: function renderNav() {
      var startDate = (0, _moment2.default)(this.state.startDate).format('DD/MM/YYYY');
      var endDate = (0, _moment2.default)(this.state.endDate).format('DD/MM/YYYY');
      var classesTheme = (0, _themeEnabled.getThemeEnabledStyle)(_Reports2.default);
      return _react2.default.createElement(
        'div',
        { className: classesTheme },
        _react2.default.createElement(
          'div',
          { className: _Reports2.default.buttonFilter, onClick: this.handleNav.bind(this) },
          _react2.default.createElement(
            'div',
            { className: _Reports2.default.icon },
            _react2.default.createElement(md.pieChart, { size: 20, color: '#FFF', onClick: this.download })
          ),
          _react2.default.createElement(
            'div',
            null,
            'Filtro activo: ' + this.state.cycle + ' (' + startDate + ' - ' + endDate + ')'
          ),
          _react2.default.createElement(
            'div',
            null,
            'Mostrar m\xE1s filtros'
          )
        )
      );
    }
  }, {
    key: 'renderFilter',
    value: function renderFilter() {
      if (this.state.filtering) {
        return _react2.default.createElement(_components.ReportFilter, { type: 'balance', startDate: this.state.startDate, endDate: this.state.endDate, onDateChange: this.handleDateChange.bind(this) });
      }
      return null;
    }
  }, {
    key: 'renderLoading',
    value: function renderLoading() {
      return _react2.default.createElement(_components.Loading, { className: _Reports2.default.loading });
    }
  }, {
    key: 'renderIfBool',
    value: function renderIfBool(obj) {
      if (obj === true) {
        return _react2.default.createElement(
          'article',
          { className: 'tile is-child notification' },
          this.renderLoading()
        );
      }

      if (obj === false) {
        return _react2.default.createElement(
          'article',
          { className: 'tile is-child notification ' + _Reports2.default.error },
          _react2.default.createElement(md.error, { size: 40, color: 'red' }),
          _react2.default.createElement(
            'h1',
            null,
            ' Error '
          )
        );
      }
    }
  }, {
    key: 'renderSummary',
    value: function renderSummary(title, graphic, kpis, color) {

      return _react2.default.createElement(
        'div',
        { className: _Reports2.default.kpiComponent },
        _react2.default.createElement(
          'div',
          { className: _Reports2.default.title },
          _react2.default.createElement(
            'div',
            { className: _Reports2.default.icon, style: { backgroundColor: color } },
            title.icon
          ),
          _react2.default.createElement(
            'div',
            { className: _Reports2.default.text },
            title.text
          )
        ),
        _react2.default.createElement(
          'div',
          { className: _Reports2.default.kpiContainer, style: { border: 'solid 1px ' + color } },
          _react2.default.createElement(
            'div',
            { className: _Reports2.default.graphic },
            graphic ? graphic : 'No graphic'
          ),
          _react2.default.createElement(
            'div',
            { className: _Reports2.default.kpi },
            kpis.kpi_name_1
          ),
          _react2.default.createElement(
            'div',
            { className: _Reports2.default.value },
            kpis.kpi_name_1 ? this.f(kpis.value_1) : ''
          ),
          _react2.default.createElement(
            'div',
            { className: _Reports2.default.kpi },
            kpis.kpi_name_2
          ),
          _react2.default.createElement(
            'div',
            { className: _Reports2.default.value },
            kpis.kpi_name_2 ? this.f(kpis.value_2) : ''
          ),
          _react2.default.createElement(
            'div',
            { className: _Reports2.default.kpi },
            kpis.kpi_name_3
          ),
          _react2.default.createElement(
            'div',
            { className: _Reports2.default.value },
            kpis.kpi_name_3 ? this.f(kpis.value_3) : ''
          ),
          title.link
        )
      );
    }
  }, {
    key: 'renderLink',
    value: function renderLink(path, accessor) {

      return _react2.default.createElement(
        _reactRouter.Link,
        { className: _Reports2.default.buttonField, to: {
            pathname: path,
            state: {
              modal: false,
              returnTo: '/reports',
              startDate: this.state.startDate,
              endDate: this.state.endDate,
              data: this.state.dailyData,
              accessor: accessor
            }
          } },
        _react2.default.createElement(
          'div',
          { className: _Reports2.default.button },
          'Ver mas detalles'
        )
      );
    }
  }, {
    key: 'capitalize',
    value: function capitalize(word) {
      return word.toLowerCase().replace(/\w/, function (firstLetter) {
        return firstLetter.toUpperCase();
      });
    }
  }, {
    key: 'renderChannelSummary',
    value: function renderChannelSummary(type, name, kpis, graphic) {

      var title = {
        icon: kpis.IconColor.icon,
        text: '' + (name ? this.capitalize(name) : ''),
        link: this.renderLink(kpis.path, kpis.accessor)
      };
      return _react2.default.createElement(
        'div',
        null,
        this.renderSummary(title, graphic, kpis, kpis.IconColor.color)
      );
    }
  }, {
    key: 'renderChannelDistribution',
    value: function renderChannelDistribution(channels) {
      var values = channels.map(function (channel) {
        if ((0, _featureEnabled.featureEnabled)('sms') && channel.channel_type == 'SMS') {
          return { name: channel.channel_type, y: channel.kpis.value_1, color: channel.kpis.IconColor.color, desc: 'SMS enviados' };
        } else if ((0, _featureEnabled.featureEnabled)('channel-email') && channel.channel_type == 'EMAIL') {
          return { name: channel.channel_type, y: channel.kpis.value_1, color: channel.kpis.IconColor.color, desc: 'Correos enviados' };
        } else if ((0, _featureEnabled.featureEnabled)('push') && channel.channel_type == 'PUSH') {
          return { name: channel.channel_type, y: channel.kpis.value_1, color: channel.kpis.IconColor.color, desc: 'Push enviados' };
        } else if ((0, _featureEnabled.featureEnabled)('rcs') && channel.channel_type == 'RCS') {
          return { name: channel.channel_type, y: channel.kpis.value_1, color: channel.kpis.IconColor.color, desc: 'RCS enviados' };
        } else {
          return null;
        }
      }).filter(function (elem) {
        return elem != null;
      });

      var pieChartValues = values.map(function (values) {
        return { name: values.name, y: values.y, color: values.color };
      });
      var series = [{
        "showInLegend": false,
        "data": pieChartValues,
        "dataLabels": {
          "enabled": false,
          "format": '{point.y:,.0f}',
          "style": {
            "fontSize": '16px',
            "fontFamily": 'Verdana, sans-serif'
          }
        }
      }];
      var valuesCount = pieChartValues.reduce(function (acc, el) {
        return acc + el.y;
      }, 0);
      if (valuesCount > 0) {
        return _react2.default.createElement(
          'div',
          { className: _Reports2.default.kpiComponent },
          _react2.default.createElement(
            'div',
            { className: _Reports2.default.title },
            _react2.default.createElement(
              'div',
              { className: _Reports2.default.icon, style: { backgroundColor: 'black' } },
              _react2.default.createElement('img', { src: (0, _useBasename2.default)('/static/channelDist.svg'), alt: '' })
            ),
            _react2.default.createElement(
              'div',
              { className: _Reports2.default.text },
              'Distribución por canal'
            )
          ),
          _react2.default.createElement(
            'div',
            { className: _Reports2.default.distribution },
            _react2.default.createElement(
              'div',
              { className: _Reports2.default.pie },
              _react2.default.createElement(_components.CustomPieChart, { series: series, name: '', tooltip: false, labels: false, height: 200 })
            ),
            _react2.default.createElement(
              'div',
              null,
              values.map(function (channel) {
                return _react2.default.createElement(
                  'div',
                  { className: _Reports2.default.pieKpi },
                  _react2.default.createElement(
                    'div',
                    { className: _Reports2.default.channelIcon },
                    '*'
                  ),
                  _react2.default.createElement(
                    'div',
                    { className: _Reports2.default.channelName, style: { color: channel.color } },
                    channel.desc
                  ),
                  _react2.default.createElement(
                    'div',
                    { className: _Reports2.default.channelValue },
                    channel.y
                  )
                );
              })
            )
          )
        );
      }
    }
  }, {
    key: 'renderLineChart',
    value: function renderLineChart(serie) {
      var histogram = [{
        "showInLegend": false,
        pointInterval: 24 * 3600 * 1000,
        color: "#1A8EDE",
        pointStart: this.state.dailyData.result[0].key,
        data: this.state.dailyData.result.map(function (value) {
          return value[serie];
        })
      }];
      return _react2.default.createElement(_components.GenericLineChart, { series: histogram, height: 240 });
    }
  }, {
    key: 'renderContent',
    value: function renderContent() {
      var _SHORTLINK,
          _this5 = this;

      var _state = this.state,
          account_balances = _state.account_balances,
          balance = _state.balance,
          reachAccount = _state.reachAccount;

      var reachBalance = account_balances.reachBalance;
      var _state$traffic = this.state.traffic,
          transactions_sent_whatsapp = _state$traffic.transactions_sent_whatsapp,
          transactions_sent_push = _state$traffic.transactions_sent_push,
          errors = _state$traffic.errors,
          transactions_sent_web = _state$traffic.transactions_sent_web,
          transactions_sent_all = _state$traffic.transactions_sent_all,
          transactions_sent_sms = _state$traffic.transactions_sent_sms,
          transactions_sent_rcs = _state$traffic.transactions_sent_rcs,
          transactions_sent_sms_free = _state$traffic.transactions_sent_sms_free,
          transactions_sent_email = _state$traffic.transactions_sent_email;


      var kpis = {
        'GENERAL': {
          type: '',
          accessor: ['errors', 'sms_out_billable', 'sms_out_non_billable'],
          path: '/reports/dailyTransactions',
          IconColor: { icon: _react2.default.createElement('img', { src: (0, _useBasename2.default)('/static/sms2.svg'), alt: '' }), color: 'black' },
          kpi_name_1: "Transacciones cobradas",
          value_1: transactions_sent_all,
          kpi_name_2: "Transacciones con error",
          value_2: errors,
          kpi_name_3: "",
          value_3: ''
        },
        'SMS': {
          type: 'Mensajes SMS',
          accessor: ['errors', 'sms_out_billable', 'sms_out_non_billable'],
          path: '/reports/dailyTransactions',
          IconColor: { icon: _react2.default.createElement('img', { src: (0, _useBasename2.default)('/static/sms2.svg'), alt: '' }), color: '#6881af' },
          kpi_name_1: (0, _translations2.default)("MESSAGES_SENT"),
          value_1: 15,
          kpi_name_2: "Mensajes con error",
          value_2: 5,
          kpi_name_3: "",
          value_3: 0
        },
        'SHORTLINK': (_SHORTLINK = {
          type: '',
          accessor: ['errors', 'sms_out_billable', 'sms_out_non_billable'],
          path: '/short_link/'
        }, (0, _defineProperty3.default)(_SHORTLINK, 'accessor', ['errors', 'sms_out_billable', 'sms_out_non_billable']), (0, _defineProperty3.default)(_SHORTLINK, 'IconColor', { icon: _react2.default.createElement('img', { src: (0, _useBasename2.default)('/static/shortlink.svg'), alt: '' }), color: '#01bb69' }), (0, _defineProperty3.default)(_SHORTLINK, 'kpi_name_1', "Cantidad de links creados"), (0, _defineProperty3.default)(_SHORTLINK, 'value_1', this.state.shortlink_list.length), (0, _defineProperty3.default)(_SHORTLINK, 'kpi_name_2', "Cantidad de links activos"), (0, _defineProperty3.default)(_SHORTLINK, 'value_2', this.state.shortlink_list.filter(function (link) {
          return link.status == 'ACTIVE';
        }).length), (0, _defineProperty3.default)(_SHORTLINK, 'kpi_name_3', "Total general de visitas"), (0, _defineProperty3.default)(_SHORTLINK, 'value_3', this.state.shortlink_list.reduce(function (acc, el) {
          return acc + el.visits;
        }, 0)), _SHORTLINK),
        'EMAIL': {
          type: 'Email',
          accessor: ['email_out_billable'],
          path: '/reports/dailyTransactions',
          IconColor: { icon: _react2.default.createElement('img', { src: (0, _useBasename2.default)('/static/email2.svg'), alt: '' }), color: '#d25a80' },
          kpi_name_1: "Correos enviados",
          value_1: "",
          kpi_name_2: "Correos con error",
          value_2: "",
          kpi_name_3: "Disponibles",
          value_3: ""
        },
        'PUSH': {
          type: 'PUSH',
          accessor: ['push_out_billable'],
          path: '/reports/dailyTransactions',
          IconColor: { icon: _react2.default.createElement('img', { src: (0, _useBasename2.default)('/static/sms2.svg'), alt: '' }), color: '#6881af' },
          kpi_name_1: "Enviados",
          value_1: transactions_sent_push,
          kpi_name_2: "Error",
          value_2: '',
          kpi_name_3: "Disponibles",
          value_3: ''
        },
        'RCS': {
          type: 'RCS',
          accessor: ['rcs_out_billable'],
          path: '/reports/dailyTransactions',
          IconColor: { icon: _react2.default.createElement('img', { src: (0, _useBasename2.default)('/static/sms2.svg'), alt: '' }), color: '#6881af' },
          kpi_name_1: "Enviados",
          value_1: '',
          kpi_name_2: "Error",
          value_2: '',
          kpi_name_3: "Disponibles",
          value_3: ''
        }
      };

      var channelsContainer = [{
        name: 'Balance general',
        consumed: balance ? balance.charges : '',
        available: balance ? balance.balance : '',
        limit: balance ? balance.credit_limit : '',
        valid_thru: balance.billing_cycle ? (0, _moment2.default)(balance.billing_cycle.valid_thru).format('LL') : '',
        kpis: kpis['GENERAL'],
        channel_type: "GENERAL"
      }];

      if (account_balances) {
        try {
          if (reachAccount) {
            reachAccount.router.router_sources.map(function (element) {
              account_balances.routerBalance.find(function (router) {
                if (router && element._id === router.source_uid) {
                  router['name'] = element.name;
                  router['channel_type'] = element.channel_type;
                  router['kpis'] = kpis[element.channel_type];
                }
              });
            });
          }
        } catch (error) {
          console.error(error);
        }

        if (reachAccount) {
          try {

            account_balances.routerBalance.forEach(function (router, index) {
              if (router) {
                var consumed_router = router.charges - router.refunds;
                var valid_thru = (0, _moment2.default)(router.billing_cycle.valid_thru).format('LL');

                channelsContainer.push({
                  name: router.name,
                  consumed: consumed_router,
                  available: router.balance,
                  limit: router.credit_limit,
                  valid_thru: valid_thru,
                  kpis: router.kpis,
                  refunds: router.refunds,
                  channel_type: router.channel_type
                });
              }
            });
          } catch (error) {
            console.error(error);
          }
        }
      }
      channelsContainer.push({
        name: 'Short Links',
        consumed: "",
        available: "",
        limit: "",
        valid_thru: "",
        kpis: kpis['SHORTLINK'],
        channel_type: "SHORTLINK"
      });

      var channels = channelsContainer.map(function (channel) {
        var sms_data = {
          balance: channel.available,
          limit: channel.limit,
          valid_thru: channel.valid_thru

          //agregar aca los valores de balance de push, email, y rcs
        };var links = _this5.state.shortlink_list.map(function (link) {
          return { "name": (0, _moment2.default)(link.created_on).format('l'), "y": link.visits };
        });
        console.log("Links", links);
        var assets = (0, _themeEnabled.getAssetStyle)();
        var values = [{
          "showInLegend": false,
          "data": links.reverse(),
          "dataLabels": {
            "enabled": true,
            "color": 'black',
            "format": '{point.y:,.0f}',
            "style": {
              "fontSize": '12px',
              "fontFamily": 'Verdana, sans-serif'
            }
          },
          "color": assets.colorChart
        }];

        if ((0, _featureEnabled.featureEnabled)('sms') && channel.channel_type == 'GENERAL') {
          return _react2.default.createElement(
            'div',
            null,
            _this5.renderChannelSummary(channel.kpis.type, channel.name, channel.kpis, _react2.default.createElement(_BalanceInfo2.default, { balance: sms_data }))
          );
        } else if ((0, _featureEnabled.featureEnabled)('channel-email') && channel.channel_type == 'EMAIL') {
          channel.kpis.value_1 = channel.consumed;
          channel.kpis.value_2 = channel.refunds;
          channel.kpis.value_3 = channel.available;
          return _react2.default.createElement(
            'div',
            null,
            _this5.renderChannelSummary(channel.kpis.type, channel.name, channel.kpis, _this5.renderLineChart('email_out_billable'))
          );
        } else if ((0, _featureEnabled.featureEnabled)('push') && channel.channel_type == 'PUSH') {
          channel.kpis.value_1 = channel.consumed;
          channel.kpis.value_2 = channel.refunds;
          channel.kpis.value_3 = channel.available;
          return _react2.default.createElement(
            'div',
            null,
            _this5.renderChannelSummary(channel.kpis.type, channel.name, channel.kpis, _this5.renderLineChart('push_out_billable'))
          );
        } else if ((0, _featureEnabled.featureEnabled)('rcs') && channel.channel_type == 'RCS') {
          channel.kpis.value_1 = channel.consumed;
          channel.kpis.value_2 = channel.refunds;
          channel.kpis.value_3 = channel.available;
          return _react2.default.createElement(
            'div',
            null,
            _this5.renderChannelSummary(channel.kpis.type, channel.name, channel.kpis, _this5.renderLineChart('rcs_out_billable'))
          );
        } else if ((0, _featureEnabled.featureEnabled)('sms') && channel.channel_type == 'SMS') {
          channel.kpis.value_1 = channel.consumed;
          channel.kpis.value_2 = channel.refunds;
          return _react2.default.createElement(
            'div',
            null,
            _this5.renderChannelSummary(channel.kpis.type, channel.name, channel.kpis, _react2.default.createElement(_BalanceInfo2.default, { balance: sms_data }))
          );
        } else if ((0, _featureEnabled.featureEnabled)('shortlink') && channel.channel_type == 'SHORTLINK') {
          return _react2.default.createElement(
            'div',
            null,
            _this5.renderChannelSummary(channel.kpis.type, channel.name, channel.kpis, _react2.default.createElement(_components.GenericBarChart, { series: values, height: 180, title: "", titlex: 'Fecha de creación', titley: "Visitas", tooltip: false }))
          );
        } else {
          return null;
        }
      }).filter(function (elem) {
        return elem != null;
      });

      return _react2.default.createElement(
        'div',
        { className: _Reports2.default.reportsContainer },
        _react2.default.createElement(
          'div',
          { className: _Reports2.default.reports },
          _react2.default.createElement(
            'div',
            { className: _Reports2.default.channelsContainer },
            this.state.dailyData ? channels.length > 0 ? channels : 'Sin datos' : ''
          ),
          this.renderChannelDistribution(channelsContainer)
        )
      );
    }
  }]);
  return DefaultReport;
}(_components.ReportList), _class2.propTypes = {
  mobileMode: _propTypes2.default.bool
}, _temp)) || _class) || _class);
exports.default = DefaultReport;
module.exports = exports['default'];