'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.WhatsappConfig = undefined;

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _dec2, _class;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _components = require('components');

var _account = require('redux/modules/account');

var _chat_api = require('redux/modules/chat_api');

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _reduxConnect = require('redux-connect');

var _useBasename = require('../../helpers/useBasename');

var _useBasename2 = _interopRequireDefault(_useBasename);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = require('./Whatsapp.scss');
var WhatsappConfig = exports.WhatsappConfig = (_dec = (0, _reduxConnect.asyncConnect)([{
    key: 'account',
    promise: function promise(_ref) {
        var _ref$store = _ref.store,
            dispatch = _ref$store.dispatch,
            getState = _ref$store.getState;

        var state = getState();
        if (!state.account.data.reach) {
            return dispatch((0, _account.getAccount)());
        }
        return null;
    }
}]), _dec2 = (0, _reactRedux.connect)(function (state) {
    return { account: state.account.data };
}, { getAccount: _account.getAccount, getChatApi: _chat_api.getChatApi, getSettings: _chat_api.getSettings, putSettings: _chat_api.putSettings, postChatApiLogout: _chat_api.postChatApiLogout, refreshChatApi: _chat_api.refreshChatApi, rebootChatApi: _chat_api.rebootChatApi }), _dec(_class = _dec2(_class = function (_React$Component) {
    (0, _inherits3.default)(WhatsappConfig, _React$Component);

    function WhatsappConfig(props) {
        (0, _classCallCheck3.default)(this, WhatsappConfig);

        var _this = (0, _possibleConstructorReturn3.default)(this, (WhatsappConfig.__proto__ || (0, _getPrototypeOf2.default)(WhatsappConfig)).call(this, props));

        _this.logoutQr = function () {
            _this.props.postChatApiLogout({ channel_uid: _this.state.channel_uid }).then(function (response) {
                console.log("Logout qr", response);
                _this.refreshQr();
            }).catch(function (error) {
                console.error("error in logout", error);
            });
        };

        _this.refreshQr = function () {
            var status = _this.state.status;
            status.qrCode = "cargando...";
            _this.setState({ status: status });
            _this.props.getChatApi({ channel_uid: _this.state.channel_uid }).then(function (response) {
                _this.setState({ status: response });
            }).catch(function (error) {
                console.error("error in refresh qr", error);
            });
        };

        _this.expiryQr = function () {
            _this.props.refreshQr({ channel_uid: _this.state.channel_uid }).then(function (response) {
                return _this.props.getChatApi({ channel_uid: _this.state.channel_uid });
            }).then(function (response) {
                _this.setState({ status: response });
            }).catch(function (error) {
                console.error("error in expiry qr", error);
            });
        };

        _this.changeSettings = function (_ref2) {
            var name = _ref2.name;

            var settings = _this.state.settings;
            settings[name] = settings[name] === null ? true : !settings[name];
            _this.setState({ settings: settings });
        };

        _this.saveSettings = function () {
            var settings = _this.state.settings;
            if (settings.webhookUrl === null) {
                settings.webhookUrl = 'https://chatapi.reach.tools/whatsapp/chat-api/web-hook';
            }
            if (settings.ackNotificationsOn === null) {
                settings.ackNotificationsOn = true;
            }
            _this.props.putSettings({ channel_uid: _this.state.channel_uid, data: settings }).then(function (response) {
                console.log("save settings", response);
            }).catch(function (error) {
                console.error("Error in save settings", error);
            });
        };

        _this.renderOptions = function () {
            switch (_this.state.status.accountStatus) {
                case "AUTHENTICATED":
                    return _react2.default.createElement(
                        'div',
                        { className: styles.optionsContainer, onClick: _this.logoutQr },
                        _react2.default.createElement('img', { src: (0, _useBasename2.default)('/static/logout.svg'), alt: '' }),
                        _react2.default.createElement(
                            'div',
                            null,
                            'Salir'
                        )
                    );
                case "GOT_QR_CODE":
                    return _react2.default.createElement(
                        'div',
                        { className: styles.optionsContainer, onClick: _this.refreshQr },
                        _react2.default.createElement('img', { src: (0, _useBasename2.default)('/static/refresh.svg'), alt: '' }),
                        _react2.default.createElement(
                            'div',
                            null,
                            'Refrescar Qr'
                        )
                    );
            }
        };

        _this.state = {
            requesting: false,
            dataUpdated: false,
            account: props.account,
            status: {},
            settings: {},
            channel_uid: undefined
        };
        return _this;
    }

    (0, _createClass3.default)(WhatsappConfig, [{
        key: 'componentWillMount',
        value: function componentWillMount() {
            var _this2 = this;

            if (this.props.account && this.props.account.reach) {
                try {
                    var channels = this.props.account.reach.channels;
                    var whatsapp = channels.filter(function (channel) {
                        return channel.type === "WHATSAPP";
                    });
                    var found = false;

                    whatsapp.forEach(function (channel) {
                        _this2.props.getChatApi({ channel_uid: channel._id }).then(function (response) {
                            console.log("response chat api..", response);
                            _this2.setState({ status: response, channel_uid: channel._id });
                            found = true;
                            return _this2.props.getSettings({ channel_uid: channel._id });
                        }).then(function (response) {
                            console.log("settings of chat api", response);
                            _this2.setState({ settings: response });
                        }).catch(function (error) {
                            console.error("error in call chat api", error);
                        });
                    });
                } catch (error) {
                    console.error("Error in call status", error);
                }
            }
        }
    }, {
        key: 'render',
        value: function render() {
            var _state = this.state,
                account = _state.account,
                settings = _state.settings,
                status = _state.status;

            if (!account || !settings) {
                return _react2.default.createElement(
                    'div',
                    { className: styles.loading },
                    _react2.default.createElement(_components.Loading, null)
                );
            }

            if (!this.state.requesting) {
                return _react2.default.createElement(
                    'div',
                    { className: styles.detailWrapper },
                    _react2.default.createElement(
                        'h1',
                        { className: styles.title },
                        (0, _translations2.default)('WHATSAPP INFORMATION')
                    ),
                    _react2.default.createElement(
                        'span',
                        { className: styles.spanState },
                        'En esta secci\xF3n podra configurar Whatsapp.'
                    ),
                    _react2.default.createElement(
                        'h1',
                        { className: styles.title },
                        (0, _translations2.default)('WHATSAPP STATUS')
                    ),
                    _react2.default.createElement(
                        'span',
                        { className: styles.spanState },
                        'El estado es: ',
                        _react2.default.createElement(
                            'span',
                            { className: styles.stateChatApi },
                            (0, _translations2.default)(status.accountStatus)
                        ),
                        ' ',
                        this.renderOptions()
                    ),
                    status.qrCode ? null : _react2.default.createElement(
                        _react2.default.Fragment,
                        null,
                        _react2.default.createElement(
                            'h1',
                            { className: styles.title },
                            (0, _translations2.default)('WHATSAPP SETTINGS')
                        ),
                        _react2.default.createElement(
                            'div',
                            { className: styles.tableDiv },
                            _react2.default.createElement(
                                'table',
                                { className: styles.userTableApi },
                                _react2.default.createElement(
                                    'tbody',
                                    null,
                                    _react2.default.createElement(
                                        'tr',
                                        null,
                                        _react2.default.createElement(
                                            'td',
                                            { className: styles.td },
                                            (0, _translations2.default)('WHATSAPP VIDEO')
                                        ),
                                        _react2.default.createElement(
                                            'td',
                                            { className: styles.td2 },
                                            _react2.default.createElement(_components.RadioButton, { checked: settings.videoUploadOn, name: 'videoUploadOn', handleChange: this.changeSettings })
                                        )
                                    ),
                                    _react2.default.createElement(
                                        'tr',
                                        null,
                                        _react2.default.createElement(
                                            'td',
                                            { className: styles.td },
                                            (0, _translations2.default)('WHATSAPP OLD MESSAGE')
                                        ),
                                        _react2.default.createElement(
                                            'td',
                                            { className: styles.td2 },
                                            _react2.default.createElement(_components.RadioButton, { checked: settings.ignoreOldMessages, name: 'ignoreOldMessages', handleChange: this.changeSettings })
                                        )
                                    )
                                )
                            )
                        )
                    ),
                    _react2.default.createElement(
                        'div',
                        { className: styles.containerQrApi },
                        status.qrCode ? _react2.default.createElement('img', { src: '' + status.qrCode }) : _react2.default.createElement(_components.Button, {
                            className: styles.save,
                            onClick: this.saveSettings,
                            name: (0, _translations2.default)('SAVE')
                        })
                    )
                );
            }
        }
    }]);
    return WhatsappConfig;
}(_react2.default.Component)) || _class) || _class);