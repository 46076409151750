'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actions = undefined;
exports.default = reducer;
exports.getAccountQuestionRegex = getAccountQuestionRegex;
exports.refreshQuestionRegex = refreshQuestionRegex;

var _apiAction = require('../apiAction');

var _apiAction2 = _interopRequireDefault(_apiAction);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// ACTIONS


var actions = exports.actions = new _apiAction2.default('QUESTIONREGEX', [_apiAction.ACTION_TYPES.DATA, _apiAction.ACTION_TYPES.APPEND, _apiAction.ACTION_TYPES.TRIM, _apiAction.ACTION_TYPES.SELECT]);

// REDUCER


var initialState = {
  loading: false,
  status: actions.APPEND,
  list: [],
  selected: [],
  data: {}
};

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  return actions.getStateForAction(state, action);
}

// ACTION CREATORS
function getAccountQuestionRegex() {
  return {
    types: [actions.APPEND, actions.APPEND_SUCCESS, actions.APPEND_FAIL],
    promise: function promise(client) {
      return client.get('/question/regex');
    }
  };
}

function refreshQuestionRegex() {
  return {
    type: actions.TRIM,
    count: 0
  };
}