'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.actions = undefined;
exports.default = reducer;
exports.getQrs = getQrs;
exports.selectQrs = selectQrs;
exports.unselectQrs = unselectQrs;
exports.unselectAllQrs = unselectAllQrs;
exports.trimQrs = trimQrs;
exports.refreshQrs = refreshQrs;

var _apiAction = require('../apiAction');

var _apiAction2 = _interopRequireDefault(_apiAction);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var actions = exports.actions = new _apiAction2.default('QRS', [_apiAction.ACTION_TYPES.DATA, _apiAction.ACTION_TYPES.APPEND, _apiAction.ACTION_TYPES.TRIM, _apiAction.ACTION_TYPES.SELECT]);

var initialState = {
    loading: false,
    status: actions.APPEND,
    list: [],
    selected: [],
    data: {}
};

function reducer() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
    var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    var response = actions.getStateForAction(state, action);
    return response;
}

function getQrs(params) {
    return {
        types: [actions.APPEND, actions.APPEND_SUCCESS, actions.APPEND_FAIL],
        promise: function promise(client) {
            return client.get('/qr', { params: params });
        }
    };
}

function selectQrs(index) {
    return {
        type: actions.SELECT,
        index: index
    };
}

function unselectQrs(index) {
    return {
        type: actions.SELECT_OFF,
        index: index
    };
}

function unselectAllQrs() {
    return {
        type: actions.SELECT_NONE
    };
}

function trimQrs(count) {
    return {
        type: actions.TRIM,
        count: count
    };
}

function refreshQrs() {
    return {
        type: actions.TRIM,
        count: 0
    };
}