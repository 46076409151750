'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actions = undefined;
exports.default = reducer;
exports.getPolicies = getPolicies;
exports.getSubAccountPolicies = getSubAccountPolicies;
exports.asignSubAccountPolicies = asignSubAccountPolicies;
exports.getPolicy = getPolicy;
exports.postPolicy = postPolicy;
exports.putPolicy = putPolicy;
exports.deletePolicy = deletePolicy;
exports.policiesRefresh = policiesRefresh;
exports.policiesSelect = policiesSelect;
exports.policiesUnselect = policiesUnselect;
exports.policiesUnselectAll = policiesUnselectAll;

var _apiAction = require('../apiAction');

var _apiAction2 = _interopRequireDefault(_apiAction);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// ACTIONS


var actions = exports.actions = new _apiAction2.default('POLICY', [_apiAction.ACTION_TYPES.DATA, _apiAction.ACTION_TYPES.SELECT, _apiAction.ACTION_TYPES.TRIM, _apiAction.ACTION_TYPES.APPEND]);

// REDUCER


var initialState = {
  loading: false,
  status: actions.APPEND,
  list: [],
  selected: []
};

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  return actions.getStateForAction(state, action);
}

// ACTION CREATORS


function getPolicies(params) {
  return {
    types: [actions.APPEND, actions.APPEND_SUCCESS, actions.APPEND_FAIL],
    promise: function promise(client) {
      return client.get('/reseller/policies', {
        params: params
      });
    }
  };
}

function getSubAccountPolicies(params) {
  return {
    types: [actions.APPEND, actions.APPEND_SUCCESS, actions.APPEND_FAIL],
    promise: function promise(client) {
      return client.get('/reseller/policies/subaccounts', {
        params: params
      });
    }
  };
}

function asignSubAccountPolicies(asignation) {
  return {
    types: [actions.APPEND, actions.APPEND_SUCCESS, actions.APPEND_FAIL],
    promise: function promise(client) {
      return client.post('/reseller/policies/subaccounts', {
        data: asignation
      });
    }
  };
}

function getPolicy(uid) {
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.get('/reseller/policies/' + uid);
    }
  };
}

function postPolicy(policy) {
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.post('/reseller/policies', { data: policy });
    }
  };
}

function putPolicy(uid, policy) {
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.put('/reseller/policies/' + uid, { data: policy });
    }
  };
}

function deletePolicy(uid) {
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.del('/reseller/policies/' + uid);
    }
  };
}

function policiesRefresh() {
  return {
    type: actions.TRIM,
    count: 0
  };
}

function policiesSelect(index) {
  return {
    type: actions.SELECT,
    index: index
  };
}

function policiesUnselect(index) {
  return {
    type: actions.SELECT_OFF,
    index: index
  };
}

function policiesUnselectAll() {
  return {
    type: actions.SELECT_NONE
  };
}