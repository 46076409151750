'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _useBasename = require('../../helpers/useBasename');

var _useBasename2 = _interopRequireDefault(_useBasename);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Icon = function Icon(props) {
  var styles = require('./Card.scss');
  var profile = props.profile;

  var image = (0, _useBasename2.default)('/static/contact/default.svg');

  // email or sms

  if (profile.email) {
    image = (0, _useBasename2.default)('/static/contact/email.svg');
  }

  if (profile.msisdn && profile.email) {
    image = (0, _useBasename2.default)('/static/contact/phoneMail.svg');
  }

  if (profile.first_name) {
    image = (0, _useBasename2.default)('/static/contact/person.svg');
  }

  if (profile.gender && (profile.gender.value === '1' || profile.gender.raw_value === '1')) {
    image = (0, _useBasename2.default)('/static/contact/female.svg');
  } else if (profile.gender && (profile.gender.value === '2' || profile.gender.raw_value === '2')) {
    image = (0, _useBasename2.default)('/static/contact/male.svg');
  }
  if (profile.profile_pic) {
    image = profile.profile_pic.value;
  }

  if (props.name) {
    var name = profile.first_name ? profile.first_name.value + ' ' + profile.last_name.value : profile.msisdn;

    return _react2.default.createElement(
      'div',
      { className: styles.largeIcon },
      _react2.default.createElement('img', { src: image, className: styles.image, alt: '' }),
      _react2.default.createElement(
        'p',
        { className: styles.name },
        name
      )
    );
  }
  return _react2.default.createElement('img', { src: image, className: styles.icon, alt: '' });
};

Icon.propTypes = {
  profile: _propTypes2.default.object.isRequired,
  name: _propTypes2.default.bool
};

Icon.defaultProps = {
  name: false
};

exports.default = Icon;
module.exports = exports['default'];