'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _dec2, _class;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _reactRedux = require('react-redux');

var _reduxConnect = require('redux-connect');

var _reactReduxLoadingBar = require('react-redux-loading-bar');

var _profiling = require('redux/modules/profiling');

var _workflows = require('redux/modules/workflows');

var _ProfilingConfig = require('containers/Profiling/ProfilingConfig');

var _ProfilingConfig2 = _interopRequireDefault(_ProfilingConfig);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var MerakiProfiling = (_dec = (0, _reduxConnect.asyncConnect)([{
  key: 'units',
  promise: function promise(_ref) {
    var _ref$store = _ref.store,
        dispatch = _ref$store.dispatch,
        getState = _ref$store.getState;

    var state = getState();
    dispatch((0, _reactReduxLoadingBar.showLoading)());
    return dispatch((0, _workflows.getUnits)('OPEN'));
  }
}]), _dec2 = (0, _reactRedux.connect)(function (state) {
  return {
    units: state.workflows.list,
    mobileMode: state.mobile.mobileMode
  };
}, { setMerakiUnits: _profiling.setMerakiUnits }), _dec(_class = _dec2(_class = function (_React$Component) {
  (0, _inherits3.default)(MerakiProfiling, _React$Component);

  function MerakiProfiling() {
    (0, _classCallCheck3.default)(this, MerakiProfiling);
    return (0, _possibleConstructorReturn3.default)(this, (MerakiProfiling.__proto__ || (0, _getPrototypeOf2.default)(MerakiProfiling)).apply(this, arguments));
  }

  (0, _createClass3.default)(MerakiProfiling, [{
    key: 'render',
    value: function render() {
      return _react2.default.createElement(_ProfilingConfig2.default, (0, _extends3.default)({}, this.props, { open: true, type: 'MERAKI' }));
    }
  }]);
  return MerakiProfiling;
}(_react2.default.Component)) || _class) || _class);
exports.default = MerakiProfiling;
module.exports = exports['default'];