'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _getOwnPropertyDescriptor = require('babel-runtime/core-js/object/get-own-property-descriptor');

var _getOwnPropertyDescriptor2 = _interopRequireDefault(_getOwnPropertyDescriptor);

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _stringify = require('babel-runtime/core-js/json/stringify');

var _stringify2 = _interopRequireDefault(_stringify);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _class, _desc, _value, _class2, _class3, _temp;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactRedux = require('react-redux');

var _variables = require('redux/modules/variables');

var _interaction = require('redux/modules/interaction');

var _coreDecorators = require('core-decorators');

var _draftJs = require('draft-js');

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _components = require('components');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
  var desc = {};
  Object['ke' + 'ys'](descriptor).forEach(function (key) {
    desc[key] = descriptor[key];
  });
  desc.enumerable = !!desc.enumerable;
  desc.configurable = !!desc.configurable;

  if ('value' in desc || desc.initializer) {
    desc.writable = true;
  }

  desc = decorators.slice().reverse().reduce(function (desc, decorator) {
    return decorator(target, property, desc) || desc;
  }, desc);

  if (context && desc.initializer !== void 0) {
    desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
    desc.initializer = undefined;
  }

  if (desc.initializer === void 0) {
    Object['define' + 'Property'](target, property, desc);
    desc = null;
  }

  return desc;
}

var CONFIGURATION = {
  maxMessageLength: 160
};

var Messages = (_dec = (0, _reactRedux.connect)(function (state) {
  return {
    messages: state.interaction.configuration.messages || {},
    keyword: state.interaction.configuration.keyword,
    shortname_key: state.interaction.configuration.public_web_access.shortname_key,
    channels: state.interaction.configuration.interaction_channels,
    sendingChannel: state.interaction.configuration.sending_channels,
    interaction: state.interaction
  };
}, {
  updateInteractionMessage: _interaction.updateInteractionMessage,
  updateInteractionConfig: _interaction.updateInteractionConfig,
  getVariables: _variables.getVariables,
  variablesRefresh: _variables.variablesRefresh
}), _dec(_class = (_class2 = (_temp = _class3 = function (_React$Component) {
  (0, _inherits3.default)(Messages, _React$Component);

  function Messages(props) {
    (0, _classCallCheck3.default)(this, Messages);

    var _this = (0, _possibleConstructorReturn3.default)(this, (Messages.__proto__ || (0, _getPrototypeOf2.default)(Messages)).call(this, props));

    _this.state = {
      keywordErrors: '',
      editorState: _draftJs.EditorState.createEmpty()
    };

    _this.messageLabels = {
      invitation: (0, _translations2.default)('INVITATION MESSAGE'),
      welcome: (0, _translations2.default)('WELCOME MESSAGE'),
      completed: (0, _translations2.default)('CLOSING MESSAGE'),
      reminder: (0, _translations2.default)('REMINDER MESSAGE')
    };
    return _this;
  }

  (0, _createClass3.default)(Messages, [{
    key: 'componentWillMount',
    value: function componentWillMount() {
      var _this2 = this;

      this.props.defineInvitation(null, this.props.keyword);
      if (__CLIENT__) {
        this.props.variablesRefresh();
        this.props.getVariables();

        setTimeout(function () {
          if (_this2.props.interaction.configuration.messages.invitation && _this2.props.interaction.configuration.messages.invitation.config && _this2.props.interaction.configuration.messages.invitation.config.rawContent) {
            var rawContent = JSON.parse(_this2.props.interaction.configuration.messages.invitation.config.rawContent);
            var editorState = _draftJs.EditorState.createWithContent((0, _draftJs.convertFromRaw)(rawContent));
            _this2.setState({
              editorState: editorState
            });
          } else {
            var _rawContent = {
              entityMap: {},
              blocks: [{
                key: '18ql2',
                text: _this2.props.interaction.configuration.messages.invitation.body,
                type: 'unstyled',
                depth: 0,
                inlineStyleRanges: [],
                entityRanges: []
              }]
            };
            var _editorState = _draftJs.EditorState.createWithContent((0, _draftJs.convertFromRaw)(_rawContent));
            _this2.setState({
              editorState: _editorState
            });
          }
        }, 500);
      }
    }
  }, {
    key: 'setEditorReference',
    value: function setEditorReference(ref) {
      this.editorReferece = ref;
    }
  }, {
    key: 'handleSubjectChange',
    value: function handleSubjectChange(subject) {
      var message = this.props.interaction.configuration.messages.invitation;
      if (!message.config) {
        message.config = {};
      }
      message.config.email_subject = subject;
      this.props.updateInteractionMessage('invitation', message);
    }
  }, {
    key: 'handleEditorStateChange',
    value: function handleEditorStateChange(editorState) {
      var _this3 = this;

      this.setState({
        editorState: editorState
      }, function () {
        var contentState = _this3.state.editorState.getCurrentContent();
        var rawContent = (0, _stringify2.default)((0, _draftJs.convertToRaw)(contentState));

        if (_this3.editorReferece) {
          var text = '';
          if (_this3.props.sendingChannel.indexOf('EMAIL') > -1) {
            text = _this3.editorReferece.editor.innerHTML;
          } else {
            text = _this3.editorReferece.editor.innerText.replace(/^\s+|\s+$/g, '');
          }
          var message = _this3.props.interaction.configuration.messages.invitation;
          var center = 'class="rdw-center-aligned-block" ';
          var left = 'class="rdw-left-aligned-block"';
          var right = 'class="rdw-right-aligned-block"';
          text = text.split(center).join(center + ' style="text-align: center" ');
          text = text.split(left).join(left + ' style="text-align: left" ');
          text = text.split(right).join(right + ' style="text-align: right" ');
          var imgregex = /<span\ class\=\"rdw-image-imagewrapper\"(?:(?!img).)*img(?:(?!alt).)*alt=\"([^\"]*)(?:(?!span).)*span(?:(?!span).)*span>/gm;
          text = text.replace(imgregex, function (a, b) {
            return '<a href=' + b + ' target=_blank>' + a + '</a>';
          });
          _this3.props.updateInteractionMessage('invitation', {
            enabled: true,
            body: text,
            config: (0, _extends3.default)({}, message.config, {
              rawContent: rawContent
            })
          });
        }
      });
    }
  }, {
    key: 'handleKeyword',
    value: function handleKeyword(event) {
      if (/^[a-z0-9]*$/i.test(event.target.value)) {
        var found = this.props.reservedKeywords.filter(function (r) {
          return r.keyword === '' + event.target.value;
        });
        var err = '';
        if (found.length > 0) {
          err = 'El keyword ya est\xE1 en uso para el estudio: ' + found[0].name;
        }
        this.setState((0, _extends3.default)({}, this.state, {
          keywordErrors: err
        }));
        this.props.updateInteractionConfig('keyword', event.target.value);
        this.props.defineInvitation(null, event.target.value);
      }
    }
  }, {
    key: 'handleOpen',
    value: function handleOpen(event) {
      this.props.updateInteractionConfig('public_web_access', {
        enabled: true,
        shortname_key: event.target.value,
        shortname_enabled: true
      });
    }
  }, {
    key: 'handleMessagechange',
    value: function handleMessagechange(messageType, message, property, event) {
      var val = null;
      if (property === 'enabled') {
        val = event.target.checked;
      } else {
        val = event.target.value;
      }
      message[property] = val;
      if (property === "body") {
        this.props.defineInvitation(message, this.props.keyword);
      }
      this.props.updateInteractionMessage(messageType, message);
    }
  }, {
    key: 'message',
    value: function message(messageType, _message) {
      if (messageType === 'invitation') {
        return _react2.default.createElement(
          'li',
          { className: 'material-shadow--light', key: messageType },
          _react2.default.createElement(
            'div',
            { className: 'num material-shadow--light message-num' },
            _react2.default.createElement(
              'div',
              { className: 'txt' },
              this.messageLabels[messageType]
            )
          ),
          _message.enabled ? _react2.default.createElement(
            'div',
            { className: 'message invitation' },
            _react2.default.createElement(
              'div',
              { className: 'question-edit' },
              _react2.default.createElement(
                'div',
                { className: 'second second--large' },
                this.props.interaction.configuration.sending_channels.indexOf('EMAIL') > -1 ? _react2.default.createElement(_components.Email, {
                  editorReference: this.setEditorReference,
                  editorState: this.state.editorState,
                  onChange: this.handleEditorStateChange,
                  onSubjectChange: this.handleSubjectChange
                }) : _react2.default.createElement(
                  'div',
                  { className: 'message-container' },
                  _react2.default.createElement(
                    'div',
                    { className: 'input-field' },
                    _react2.default.createElement('textarea', {
                      spellCheck: false,
                      className: 'message materialize-textarea',
                      maxLength: CONFIGURATION.maxMessageLength - (this.props.invitation.fullInvitationWeb.length + this.props.invitation.fullInvitationSms.length + "\n\n".length),
                      value: _message.body,
                      onChange: messageType === 'invitation' ? this.handleMessagechange.bind(this, messageType, _message, 'body') : this.handleMessagechange.bind(this, messageType, _message, 'message') })
                  ),
                  _react2.default.createElement(
                    'div',
                    { className: 'optins' },
                    this.props.channels.indexOf('HTML') > -1 ? _react2.default.createElement(
                      'div',
                      { className: 'row name shareurl' },
                      _react2.default.createElement(
                        'div',
                        { className: 'input-field col s12' },
                        (0, _translations2.default)("VISIT"),
                        '\xA0 ',
                        _react2.default.createElement(
                          'span',
                          null,
                          (0, _translations2.default)('SHARE URL PREFIX'),
                          (0, _translations2.default)("UNKNOWN")
                        )
                      )
                    ) : null,
                    this.state.keywordErrors && _react2.default.createElement(
                      'div',
                      { className: 'keyword-error-messages' },
                      this.state.keywordErrors
                    ),
                    this.props.channels.indexOf('SMS') > -1 ? _react2.default.createElement(
                      'div',
                      { className: 'input-field col s12 new-keyword' },
                      this.props.channels.indexOf('HTML') > -1 ? _react2.default.createElement(
                        _react2.default.Fragment,
                        null,
                        (0, _translations2.default)("OR"),
                        ' '
                      ) : null,
                      (0, _translations2.default)("RESPOND"),
                      _react2.default.createElement('input', { type: 'text', ref: 'keyword', value: this.props.keyword, onChange: this.handleKeyword.bind(this) }),
                      (0, _translations2.default)("TO START")
                    ) : null
                  )
                )
              ),
              _react2.default.createElement(
                'div',
                { className: 'character_counter' },
                this.props.invitation.fullInvitation ? this.props.invitation.fullInvitation.length : 0,
                '/',
                CONFIGURATION.maxMessageLength
              ),
              _react2.default.createElement('div', { spellCheck: false, className: 'pre', disabled: 'disabled' })
            ),
            _react2.default.createElement(
              'p',
              { style: { "opacity": 0.4, "font-size": 14 } },
              (0, _translations2.default)("TOTAL CHARACTERS DEPENDS ON") + (0, _translations2.default)("PLACED VARIABLES"),
              this.props.channels.indexOf('HTML') > -1 ? (0, _translations2.default)("AND") + (0, _translations2.default)("GENERATED URL") : null
            )
          ) : null
        );
      }
      return _react2.default.createElement(
        'li',
        { className: 'material-shadow--light', key: messageType },
        _react2.default.createElement(
          'div',
          { className: 'num material-shadow--light message-num' },
          _react2.default.createElement(
            'div',
            { className: 'txt' },
            this.messageLabels[messageType]
          )
        ),
        _message.enabled ? _react2.default.createElement(
          'div',
          { className: 'message' },
          _react2.default.createElement(
            'div',
            { className: 'question-edit' },
            _react2.default.createElement(
              'div',
              { className: 'second second--large' },
              _react2.default.createElement(
                'div',
                { className: 'message-container' },
                _react2.default.createElement(
                  'div',
                  { className: 'input-field' },
                  _react2.default.createElement('textarea', { spellCheck: false, className: 'message materialize-textarea',
                    maxLength: CONFIGURATION.maxMessageLength,
                    value: _message.message,
                    onChange: messageType === 'invitation' ? this.handleMessagechange.bind(this, messageType, _message, 'body') : this.handleMessagechange.bind(this, messageType, _message, 'message') })
                )
              )
            ),
            _react2.default.createElement(
              'div',
              { className: 'character_counter' },
              _message.message ? _message.message.length : 0,
              '/',
              CONFIGURATION.maxMessageLength
            ),
            _react2.default.createElement('div', { spellCheck: false, className: 'pre', disabled: 'disabled' })
          )
        ) : null
      );
    }
  }, {
    key: 'render',
    value: function render() {
      var _this4 = this;

      return _react2.default.createElement(
        'div',
        { className: 'edit' },
        !this.props.noTitle ? _react2.default.createElement(
          'h5',
          null,
          (0, _translations2.default)('MESSAGES CONFIGURATION')
        ) : null,
        _react2.default.createElement(
          'ul',
          { className: 'edit-items-message' },
          this.props.messageTypes.map(function (messageType) {
            return _this4.message(messageType, _this4.props.messages[messageType] || {});
          })
        )
      );
    }
  }]);
  return Messages;
}(_react2.default.Component), _class3.propTypes = {
  keyword: _propTypes2.default.string.isRequired,
  messages: _propTypes2.default.object.isRequired,
  updateInteractionMessage: _propTypes2.default.func.isRequired,
  updateInteractionConfig: _propTypes2.default.func.isRequired,
  messageTypes: _propTypes2.default.array.isRequired,
  channels: _propTypes2.default.array.isRequired,
  sendingChannel: _propTypes2.default.array.isRequired,
  reservedKeywords: _propTypes2.default.array.isRequired,
  getVariables: _propTypes2.default.func.isRequired,
  variablesRefresh: _propTypes2.default.func.isRequired,
  interaction: _propTypes2.default.object.isRequired
}, _temp), (_applyDecoratedDescriptor(_class2.prototype, 'setEditorReference', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'setEditorReference'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, 'handleSubjectChange', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'handleSubjectChange'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, 'handleEditorStateChange', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'handleEditorStateChange'), _class2.prototype)), _class2)) || _class);
exports.default = Messages;
module.exports = exports['default'];