'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _getOwnPropertyDescriptor = require('babel-runtime/core-js/object/get-own-property-descriptor');

var _getOwnPropertyDescriptor2 = _interopRequireDefault(_getOwnPropertyDescriptor);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _class, _desc, _value, _class2, _class3, _temp;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactSelect = require('react-select');

var _reactSelect2 = _interopRequireDefault(_reactSelect);

var _reactRedux = require('react-redux');

var _coreDecorators = require('core-decorators');

var _tags = require('redux/modules/tags');

var _Tags = require('./Tags.scss');

var _Tags2 = _interopRequireDefault(_Tags);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
  var desc = {};
  Object['ke' + 'ys'](descriptor).forEach(function (key) {
    desc[key] = descriptor[key];
  });
  desc.enumerable = !!desc.enumerable;
  desc.configurable = !!desc.configurable;

  if ('value' in desc || desc.initializer) {
    desc.writable = true;
  }

  desc = decorators.slice().reverse().reduce(function (desc, decorator) {
    return decorator(target, property, desc) || desc;
  }, desc);

  if (context && desc.initializer !== void 0) {
    desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
    desc.initializer = undefined;
  }

  if (desc.initializer === void 0) {
    Object['define' + 'Property'](target, property, desc);
    desc = null;
  }

  return desc;
}

var TagsSelect = (_dec = (0, _reactRedux.connect)(function (state) {
  return { tags: state.tags.list };
}, { getTagsRefactor: _tags.getTagsRefactor, tagsRefresh: _tags.tagsRefresh }), _dec(_class = (_class2 = (_temp = _class3 = function (_React$Component) {
  (0, _inherits3.default)(TagsSelect, _React$Component);

  function TagsSelect(props) {
    (0, _classCallCheck3.default)(this, TagsSelect);

    var _this = (0, _possibleConstructorReturn3.default)(this, (TagsSelect.__proto__ || (0, _getPrototypeOf2.default)(TagsSelect)).call(this, props));

    _this.state = {
      multiValue: props.value.constructor === Array ? props.value : [],
      value: typeof props.value === "string" ? props.value : undefined
    };
    return _this;
  }

  (0, _createClass3.default)(TagsSelect, [{
    key: 'componentWillMount',
    value: function componentWillMount() {
      var _this2 = this;

      this.props.tagsRefresh();
      setTimeout(function () {
        return _this2.props.getTagsRefactor();
      }, 500);
    }
  }, {
    key: 'handleChange',
    value: function handleChange(value) {
      var multi = this.props.multi;

      if (multi) {
        this.setState({ multiValue: value });
      } else {
        this.setState({ value: value });
      }
      if (this.props.onChange) {
        return this.props.onChange(value);
      }
    }
  }, {
    key: 'render',
    value: function render() {
      var _state = this.state,
          multiValue = _state.multiValue,
          value = _state.value;
      var _props = this.props,
          multi = _props.multi,
          tags = _props.tags,
          creatable = _props.creatable;


      var SelectComponent = creatable ? _reactSelect2.default.Creatable : _reactSelect2.default;

      return _react2.default.createElement(
        'div',
        { className: _Tags2.default.tagsselect },
        _react2.default.createElement(SelectComponent, {
          multi: multi,
          options: tags.map(function (t) {
            return { label: t.name, value: t.name, id: t._id };
          }),
          onChange: this.handleChange,
          value: multi ? multiValue : value,
          placeholder: this.props.placeholder
        })
      );
    }
  }]);
  return TagsSelect;
}(_react2.default.Component), _class3.defaultProps = {
  multi: true,
  creatable: true,
  tags: [],
  value: [],
  placeholder: "Select..."
}, _temp), (_applyDecoratedDescriptor(_class2.prototype, 'handleChange', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'handleChange'), _class2.prototype)), _class2)) || _class);
exports.default = TagsSelect;
module.exports = exports['default'];