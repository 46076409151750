'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _dec2, _class, _class2, _temp;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactRedux = require('react-redux');

var _reduxForm = require('redux-form');

var _reduxConnect = require('redux-connect');

var _featureEnabled = require('helpers/featureEnabled');

var _string = require('helpers/string');

var _reactTextareaAutosize = require('react-textarea-autosize');

var _reactTextareaAutosize2 = _interopRequireDefault(_reactTextareaAutosize);

var _messages = require('redux/modules/messages');

var _email = require('redux/modules/email');

var _account = require('redux/modules/account');

var _status = require('redux/modules/status');

var _auth = require('redux/modules/auth');

var _Message = require('./Message');

var _uid = require('uid');

var _uid2 = _interopRequireDefault(_uid);

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var pageSize = 1000;

var md = {
  send: require('react-icons/lib/md/send')
};

var Chat = (_dec = (0, _reduxConnect.asyncConnect)([{
  key: "auth",
  promise: function promise(_ref) {
    var _ref$store = _ref.store,
        dispatch = _ref$store.dispatch,
        getState = _ref$store.getState;

    if (!(0, _auth.isLoaded)(getState())) {
      return dispatch((0, _auth.load)());
    }
    return null;
  }
}]), _dec2 = (0, _reactRedux.connect)(function (state) {
  return {
    messagesList: state.chatMessages.list,
    isLoading: state.chatMessages.loading,
    account: state.auth.current_account,
    contact: state.contact,
    auth: state.auth
  };
}, { getChatMessages: _messages.getChatMessages, messageSendMultiple: _messages.messageSendMultiple, chatMessagesRefresh: _messages.chatMessagesRefresh, sentMessagesRefresh: _messages.sentMessagesRefresh, sendWhatsappMessage: _email.sendEmail, getAccount: _account.getAccount, getAccountStatus: _status.getAccountStatus }), _dec(_class = _dec2(_class = (_temp = _class2 = function (_React$Component) {
  (0, _inherits3.default)(Chat, _React$Component);

  function Chat(props) {
    (0, _classCallCheck3.default)(this, Chat);

    var _this = (0, _possibleConstructorReturn3.default)(this, (Chat.__proto__ || (0, _getPrototypeOf2.default)(Chat)).call(this, props));

    _this.styles = require('./Chat.scss');
    _this.fetchRecentInterval = null;
    _this.componentHasUnmounted = false;
    _this.account_uid = props.account.account_uid;
    _this.state = {
      reachAccount: null,
      accountStatus: null
    };
    return _this;
  }

  (0, _createClass3.default)(Chat, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      var _this2 = this;

      this.props.getAccount().then(function (reachAccount) {
        return _this2.setState({ reachAccount: reachAccount });
      });
      this.props.getAccountStatus().then(function (accountStatus) {
        _this2.setState({ accountStatus: accountStatus });
      });
      if (!this.noChat()) {
        this.props.chatMessagesRefresh();
        if (!this.fetchRecentInterval && this.props.contact.data.msisdn) {
          this.loadMoreRows();
        }
      }
    }
  }, {
    key: 'componentWillUnmount',
    value: function componentWillUnmount() {
      clearInterval(this.fetchRecentInterval);
      this.props.sentMessagesRefresh();
      this.componentHasUnmounted = true;
    }
  }, {
    key: 'noChat',
    value: function noChat() {
      return !(this.props.account.account_type == "Subaccount" && this.props.account.is_default_subaccount && this.props.account.reach_enabled || this.props.account.account_type != "Subaccount");
    }
  }, {
    key: 'getMostRecentDate',
    value: function getMostRecentDate() {
      var messagesList = this.props.messagesList;
      if (!messagesList.length) {
        return null;
      }
      var sorted = messagesList.sort(function (a, b) {
        return b.created_on < a.created_on ? -1 : b.created_on > a.created_on ? 1 : 0;
      });
      var date = (0, _moment2.default)(sorted[0].created_on).add(1, 's');
      return date.format('YYYY-MM-DD HH:mm:ss');
    }
  }, {
    key: 'getOldestDate',
    value: function getOldestDate() {
      var messagesList = this.props.messagesList;
      if (!messagesList) return null;
      return messagesList.slice(-1)[0].created_on;
    }
  }, {
    key: 'loadRecent',
    value: function loadRecent() {
      this.props.chatMessagesRefresh();
      if (this.props.contact.data.msisdn && !this.props.isLoading) {
        this.props.getChatMessages({
          account_uid: this.account_uid,
          destination_identifier: this.props.contact.data.msisdn,
          start: 0,
          limit: pageSize
          // start_date: this.getMostRecentDate()
        });
      }
    }
  }, {
    key: 'loadMoreRows',
    value: function loadMoreRows() {
      var _this3 = this;

      this.props.chatMessagesRefresh();
      return this.props.getChatMessages({
        account_uid: this.account_uid,
        destination_identifier: this.props.contact.data.msisdn,
        start: 0,
        limit: pageSize
        // start_date: this.getMostRecentDate(),
      }).then(function () {
        if (!_this3.componentHasUnmounted) {
          _this3.fetchRecentInterval = setTimeout(_this3.loadMoreRows.bind(_this3), 15000);
        }
        if (_this3.refs.chat) {
          var element = _this3.refs.chat;
          if (element.scrollTop === 0 || element.scrollHeight === Math.floor(element.scrollTop + element.offsetHeight)) {
            element.scrollTop = element.scrollHeight;
          }
        }
      });
    }
  }, {
    key: 'postMessage',
    value: function postMessage(form, action, formController) {
      var _this4 = this;

      var message = form.newMessage;
      var msisdn = this.props.contact.data.msisdn;

      var id = (0, _uid2.default)(); // if this is made as a function of message and msisdn the duplicate finder will work
      var channels = this.state.reachAccount.reach.channels;

      var whatsapp_channel = null;
      if (this.props.active == 'SMS') {
        this.props.messageSendMultiple([msisdn], message, null, id).then(function () {
          return _this4.loadRecent();
        });
      } else if (this.props.active == 'WHATSAPP') {
        whatsapp_channel = channels.find(function (channel) {
          return channel.type == 'WHATSAPP';
        });
        var dataToSend = {
          text: message,
          event: 'SINGLE',
          message_type: 'WHATSAPP',
          profile_alias: msisdn,
          channel_type: 'WHATSAPP',
          created_by: this.props.auth.data.user.email,
          channel_identifier: whatsapp_channel.identifier,
          recipients: [msisdn],
          account_uid: this.props.account.account_uid
        };
        this.props.sendWhatsappMessage(dataToSend).then(function () {
          return _this4.loadRecent();
        });
      }
      formController.reset();
    }
  }, {
    key: 'renderLoading',
    value: function renderLoading() {
      var styles = this.styles;

      return _react2.default.createElement(
        'div',
        { className: styles.chat + ' ' + styles.loading },
        _react2.default.createElement(
          'div',
          { className: styles.messages },
          _react2.default.createElement(_Message.MessageLoading, { direction: 'in', width: 200 }),
          _react2.default.createElement(_Message.MessageLoading, { direction: 'out', width: 250 }),
          _react2.default.createElement(_Message.MessageLoading, { direction: 'out', width: 200 }),
          _react2.default.createElement(_Message.MessageLoading, { direction: 'in', width: 50 }),
          _react2.default.createElement(_Message.MessageLoading, { direction: 'out', width: 230 }),
          _react2.default.createElement(_Message.MessageLoading, { direction: 'out', width: 255 }),
          _react2.default.createElement(_Message.MessageLoading, { direction: 'out', width: 220 }),
          _react2.default.createElement(_Message.MessageLoading, { direction: 'in', width: 250 })
        ),
        _react2.default.createElement(
          'div',
          { className: styles.compose },
          _react2.default.createElement('div', { className: styles.textarea })
        )
      );
    }
  }, {
    key: 'renderChat',
    value: function renderChat() {
      var _this5 = this;

      // ficha <- en Detail
      // Recepción de transacciones (que pueda cargar más...)
      if (this.noChat()) {
        return _react2.default.createElement('div', { className: this.styles.chat });
      }
      var shortName = '';
      var accountStatus = this.state.accountStatus;
      if (accountStatus && accountStatus.csm && accountStatus.csm.api_use_short_name === true) {
        shortName = accountStatus.sms_short_name + ': ';
      }

      return _react2.default.createElement(
        'div',
        { className: this.styles.chat },
        _react2.default.createElement(
          'div',
          { className: this.styles.messages, ref: 'chat' },
          this.props.messagesList.map(function (message, index) {
            return _react2.default.createElement(_Message.Message, {
              message: message,
              key: index,
              direction: message.direction,
              channel_type: message.channel_type,
              active: _this5.props.active });
          }).reverse()
        ),
        (0, _featureEnabled.featureEnabled)(this.props.active.toLowerCase()) && _react2.default.createElement(MessageForm, { postMessage: this.postMessage.bind(this), shortName: shortName, active: this.props.active })
      );
    }
  }, {
    key: 'render',
    value: function render() {
      var loading = this.props.contact.loading;

      return loading ? this.renderLoading() : this.renderChat();
    }
  }]);
  return Chat;
}(_react2.default.Component), _class2.propTypes = {
  contact: _propTypes2.default.object.isRequired,
  messagesList: _propTypes2.default.array.isRequired,
  isLoading: _propTypes2.default.bool.isRequired,
  messageSendMultiple: _propTypes2.default.func.isRequired,
  chatMessagesRefresh: _propTypes2.default.func.isRequired,
  sentMessagesRefresh: _propTypes2.default.func.isRequired
}, _temp)) || _class) || _class);
exports.default = Chat;


var MessageTextarea = function MessageTextarea(props) {
  var styles = require('./Chat.scss');
  var messageLength = 160;
  var messageLengthMultiple = 153;
  var maxLength = 612 - props.shortName.length;

  var _getSmsPreview = (0, _string.getSmsPreview)(props.shortName, props.input.value, messageLength, messageLengthMultiple),
      _getSmsPreview2 = (0, _slicedToArray3.default)(_getSmsPreview, 2),
      previewText = _getSmsPreview2[0],
      length = _getSmsPreview2[1];

  return _react2.default.createElement(
    'div',
    { className: styles.messageInput },
    props.active === 'SMS' ? _react2.default.createElement(
      'div',
      { className: styles.previewContainer },
      _react2.default.createElement(
        'label',
        { className: styles.previewLabel },
        (0, _translations2.default)('PREVIEW'),
        ':'
      ),
      _react2.default.createElement('div', { className: styles.previewText, dangerouslySetInnerHTML: { __html: previewText } })
    ) : null,
    _react2.default.createElement(_reactTextareaAutosize2.default, (0, _extends3.default)({}, props.input, { className: props.className, placeholder: (0, _translations2.default)('MESSAGE PLACEHOLDER'), maxLength: maxLength })),
    _react2.default.createElement(
      'span',
      { className: styles.counter + ' ' + (props.meta.pristine ? styles.pristine : '') },
      (0, _translations2.default)('CHARACTERS'),
      ': ',
      length,
      '\xA0',
      (0, _translations2.default)('MESSAGES'),
      ': ',
      Math.ceil(length / (length > messageLength ? messageLengthMultiple : messageLength))
    )
  );
};

MessageTextarea.propTypes = {
  input: _propTypes2.default.object.isRequired,
  meta: _propTypes2.default.object.isRequired,
  className: _propTypes2.default.string.isRequired
};

var MessageFormContainer = function MessageFormContainer(props) {
  // TODO: Move to own component?
  var handleSubmit = props.handleSubmit,
      pristine = props.pristine,
      reset = props.reset,
      submitting = props.submitting,
      postMessage = props.postMessage;

  var styles = require('./Chat.scss');
  var pristineClass = pristine ? styles.pristine : '';
  var submittingClass = submitting ? styles.submitting : '';

  return _react2.default.createElement(
    'form',
    { onSubmit: handleSubmit(postMessage), className: styles.compose },
    _react2.default.createElement(_reduxForm.Field, { name: 'newMessage', className: styles.message, shortName: props.shortName, active: props.active, component: MessageTextarea }),
    _react2.default.createElement(
      'button',
      { type: 'submit',
        disabled: pristine || submitting,
        className: styles.submit + ' ' + pristineClass + ' ' + submittingClass },
      _react2.default.createElement(md.send, { size: 30 })
    ),
    _react2.default.createElement(
      'button',
      { type: 'button',
        disabled: pristine || submitting,
        onClick: reset,
        className: styles.reset + ' ' + pristineClass + ' ' + submittingClass },
      'Clear Values'
    )
  );
};

MessageFormContainer.propTypes = {
  postMessage: _propTypes2.default.func.isRequired,
  handleSubmit: _propTypes2.default.func.isRequired,
  pristine: _propTypes2.default.bool.isRequired,
  reset: _propTypes2.default.func.isRequired,
  submitting: _propTypes2.default.bool.isRequired
};

var MessageForm = (0, _reduxForm.reduxForm)({
  form: 'chat'
})(MessageFormContainer);
module.exports = exports['default'];