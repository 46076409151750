'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.REWARD_BATCHES_GET = exports.UPDATE_INTERACTION_QUESTIONS_SMS = exports.INTERACTION_RESULTS_GET_FAIL = exports.INTERACTION_RESULTS_GET_SUCCESS = exports.INTERACTION_RESULTS_GET = exports.INTERACTION_RESULTS_CLEAR = exports.INTERACTION_GET_FAIL = exports.INTERACTION_GET_SUCCESS = exports.INTERACTION_GET = exports.INTERACTION_SAVE_FAILED = exports.INTERACTION_SAVE_SUCCESS = exports.INTERACTION_SAVE = exports.REMOVE_INTERACTION_OPTION = exports.ADD_INTERACTION_OPTION = exports.DELETE_INTERACTION_QUESTION = exports.UPDATE_INTERACTION_MESSAGE = exports.UPDATE_INTERACTION_OPTIONS = exports.UPDATE_INTERACTION_OPTION = exports.UPDATE_INTERACTION_TYPE = exports.UPDATE_INTERACTION_QUESTION = exports.UPDATE_INTERACTION_QUESTIONS = exports.UPDATE_INTERACTION_CONFIGURATION = exports.UPDATE_INTERACTION = exports.CAMPAIGN = exports.STUDY_INVITATION = exports.STUDY_API = exports.STUDY_OPEN = exports.STUDY = undefined;

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

exports.default = interaction;
exports.updateInteraction = updateInteraction;
exports.updateInteractionConfig = updateInteractionConfig;
exports.updateInteractionMessage = updateInteractionMessage;
exports.updateInteractionQuestions = updateInteractionQuestions;
exports.updateInteractionQuestionsSms = updateInteractionQuestionsSms;
exports.deleteQuestion = deleteQuestion;
exports.updateInteractionQuestion = updateInteractionQuestion;
exports.updateInteractionOption = updateInteractionOption;
exports.addOption = addOption;
exports.deleteOption = deleteOption;
exports.updateInteractionOptions = updateInteractionOptions;
exports.updateInteractionType = updateInteractionType;
exports.requestSave = requestSave;
exports.requestStart = requestStart;
exports.requestStop = requestStop;
exports.requestDelete = requestDelete;
exports.getNewInteraction = getNewInteraction;
exports.getInteraction = getInteraction;
exports.getInteractionCopy = getInteractionCopy;
exports.getInteractionResults = getInteractionResults;
exports.clearResults = clearResults;
exports.getReservedKeywords = getReservedKeywords;
exports.getRewardBatches = getRewardBatches;

var _immutabilityHelper = require('immutability-helper');

var _immutabilityHelper2 = _interopRequireDefault(_immutabilityHelper);

var _reactAddonsUpdate = require('react-addons-update');

var _reactAddonsUpdate2 = _interopRequireDefault(_reactAddonsUpdate);

var _bsonObjectid = require('bson-objectid');

var _bsonObjectid2 = _interopRequireDefault(_bsonObjectid);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/*
 * interaction types
 */

var STUDY = exports.STUDY = 'STUDY';
var STUDY_OPEN = exports.STUDY_OPEN = 'STUDY OPEN';
var STUDY_API = exports.STUDY_API = 'STUDY API';
var STUDY_INVITATION = exports.STUDY_INVITATION = 'STUDY INVITATION';
var CAMPAIGN = exports.CAMPAIGN = 'CAMPAIGN';

/*
 * action types
 */

var UPDATE_INTERACTION = exports.UPDATE_INTERACTION = 'UPDATE_INTERACTION';
var UPDATE_INTERACTION_CONFIGURATION = exports.UPDATE_INTERACTION_CONFIGURATION = 'UPDATE_INTERACTION_CONFIGURATION';
var UPDATE_INTERACTION_QUESTIONS = exports.UPDATE_INTERACTION_QUESTIONS = 'UPDATE_INTERACTION_QUESTIONS';
var UPDATE_INTERACTION_QUESTION = exports.UPDATE_INTERACTION_QUESTION = 'UPDATE_INTERACTION_QUESTION';
var UPDATE_INTERACTION_TYPE = exports.UPDATE_INTERACTION_TYPE = 'UPDATE_INTERACTION_TYPE';
var UPDATE_INTERACTION_OPTION = exports.UPDATE_INTERACTION_OPTION = 'UPDATE_INTERACTION_OPTION';
var UPDATE_INTERACTION_OPTIONS = exports.UPDATE_INTERACTION_OPTIONS = 'UPDATE_INTERACTION_OPTIONS';
var UPDATE_INTERACTION_MESSAGE = exports.UPDATE_INTERACTION_MESSAGE = 'UPDATE_INTERACTION_MESSAGE';
var DELETE_INTERACTION_QUESTION = exports.DELETE_INTERACTION_QUESTION = 'DELETE_INTERACTION_QUESTION';
var ADD_INTERACTION_OPTION = exports.ADD_INTERACTION_OPTION = 'ADD_INTERACTION_OPTION';
var REMOVE_INTERACTION_OPTION = exports.REMOVE_INTERACTION_OPTION = 'REMOVE_INTERACTION_OPTION';
var INTERACTION_SAVE = exports.INTERACTION_SAVE = 'INTERACTION_SAVE_DATA';
var INTERACTION_SAVE_SUCCESS = exports.INTERACTION_SAVE_SUCCESS = 'INTERACTION_SAVE_SUCCESS';
var INTERACTION_SAVE_FAILED = exports.INTERACTION_SAVE_FAILED = 'INTERACTION_SAVE_FAIL';
var INTERACTION_GET = exports.INTERACTION_GET = 'INTERACTION_DATA';
var INTERACTION_GET_SUCCESS = exports.INTERACTION_GET_SUCCESS = 'INTERACTION_DATA_SUCCESS';
var INTERACTION_GET_FAIL = exports.INTERACTION_GET_FAIL = 'INTERACTION_DATA_FAIL';
var INTERACTION_RESULTS_CLEAR = exports.INTERACTION_RESULTS_CLEAR = 'INTERACTION_RESULTS_CLEAR';
var INTERACTION_RESULTS_GET = exports.INTERACTION_RESULTS_GET = 'INTERACTION_RESULTS_DATA';
var INTERACTION_RESULTS_GET_SUCCESS = exports.INTERACTION_RESULTS_GET_SUCCESS = 'INTERACTION_RESULTS_DATA_SUCCESS';
var INTERACTION_RESULTS_GET_FAIL = exports.INTERACTION_RESULTS_GET_FAIL = 'INTERACTION_RESULTS_DATA_FAIL';
var UPDATE_INTERACTION_QUESTIONS_SMS = exports.UPDATE_INTERACTION_QUESTIONS_SMS = 'UPDATE_INTERACTION_QUESTIONS_SMS';
var REWARD_BATCHES_GET = exports.REWARD_BATCHES_GET = 'REWARD_BATCHES_DATA';

var initialState = {
  loading: false,
  error: ''
};

function interaction() {
  var _update, _action$parentPropert;

  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments[1];

  var cutQuestions = [];
  switch (action.type) {
    case INTERACTION_GET_SUCCESS:
      {
        var _interaction = action.result.interaction;
        _interaction.sending_channels_enabled = action.result.sending_channels;
        _interaction.interaction_channels_enabled = action.result.interacion_channels;
        return _interaction;
      }
    case INTERACTION_SAVE:
      // main action
      return (0, _immutabilityHelper2.default)(state, {
        loading: { $set: true },
        error: { $set: '' }
      });
    case INTERACTION_SAVE_SUCCESS:
      return (0, _immutabilityHelper2.default)(state, {
        loading: { $set: false },
        error: { $set: '' }
      });
    case INTERACTION_SAVE_FAILED:
      return (0, _immutabilityHelper2.default)(state, {
        loading: { $set: false },
        error: { $set: action.error.field ? action.error.field : action.error }
      });
    case UPDATE_INTERACTION:
      return (0, _immutabilityHelper2.default)(state, (_update = {}, (0, _defineProperty3.default)(_update, action.property, { $set: action.value }), (0, _defineProperty3.default)(_update, 'error', { $set: '' }), _update));
    case UPDATE_INTERACTION_CONFIGURATION:
      return (0, _immutabilityHelper2.default)(state, (0, _defineProperty3.default)({}, action.parentProperty, (_action$parentPropert = {}, (0, _defineProperty3.default)(_action$parentPropert, action.property, { $set: action.value }), (0, _defineProperty3.default)(_action$parentPropert, 'error', { $set: '' }), _action$parentPropert)));
    case UPDATE_INTERACTION_QUESTIONS:
      if (state.questions) {
        cutQuestions = state.questions.slice(0, action.numQuestions);
      }
      for (var i = cutQuestions.length; i < action.numQuestions; i += 1) {
        cutQuestions.push({
          age_max: null,
          shuffle_options: null,
          options: [],
          response_type: 'OPEN',
          response_alternative_type: '',
          text: '',
          question_number: i + 1,
          age_min: null,
          range_max: null,
          range_min: null,
          question_regex_uid: null,
          _id: _bsonObjectid2.default.generate()
        });
      }
      return (0, _immutabilityHelper2.default)(state, {
        questions: { $set: cutQuestions }
      });
    case UPDATE_INTERACTION_QUESTION:
      return (0, _immutabilityHelper2.default)(state, {
        questions: (0, _defineProperty3.default)({}, action.index, (0, _defineProperty3.default)({}, action.property, { $set: action.value }))
      });
    case DELETE_INTERACTION_QUESTION:
      return (0, _immutabilityHelper2.default)(state, {
        questions: { $apply: function $apply(question) {
            return question.filter(function (element, index) {
              return index != action.index;
            }).map(function (q, i) {
              q.question_number = i + 1;return q;
            });
          } }
      });
    case UPDATE_INTERACTION_TYPE:
      return (0, _immutabilityHelper2.default)(state, {
        questions: (0, _defineProperty3.default)({}, action.index, {
          response_type: { $set: action.response_type },
          response_alternative_type: { $set: action.response_alternative_type },
          options: { $set: [] },
          $unset: ['next']
        })
      });
    case UPDATE_INTERACTION_OPTION:
      return (0, _immutabilityHelper2.default)(state, {
        questions: (0, _defineProperty3.default)({}, action.index, { options: (0, _defineProperty3.default)({}, action.optionNum, (0, _defineProperty3.default)({}, action.property, { $set: action.value })) })
      });
    case ADD_INTERACTION_OPTION:
      return (0, _immutabilityHelper2.default)(state, {
        questions: (0, _defineProperty3.default)({}, action.index, { options: { $push: [{ text: '' }] } })
      });
    case REMOVE_INTERACTION_OPTION:
      return (0, _immutabilityHelper2.default)(state, {
        questions: (0, _defineProperty3.default)({}, action.index, { options: { $splice: [[action.optionNum, 1]] } })
      });
    case UPDATE_INTERACTION_OPTIONS:
      return (0, _immutabilityHelper2.default)(state, {
        questions: (0, _defineProperty3.default)({}, action.index, { options: { $set: action.options } })
      });
    case UPDATE_INTERACTION_MESSAGE:
      return (0, _reactAddonsUpdate2.default)(state, {
        configuration: {
          messages: (0, _defineProperty3.default)({}, action.messageType, { $set: action.value })
        }
      });
    case UPDATE_INTERACTION_QUESTIONS_SMS:
      return (0, _immutabilityHelper2.default)(state, {
        questions: { $apply: function $apply(question) {
            return question.map(function (q) {
              if (q.response_alternative_type === "HTML") {
                q.response_type = "OPEN";q.response_alternative_type = null;q.text = "";q.config.rawContent = "";return q;
              } else {
                return q;
              }
            });
          } }
      });
    case INTERACTION_RESULTS_GET:
      return (0, _immutabilityHelper2.default)(state, {
        results: { $set: action.value }
      });
    case INTERACTION_RESULTS_GET_SUCCESS:
    case INTERACTION_RESULTS_GET_FAIL:
    default:
      return state;
  }
}

/*
 * action creators
 */

function updateInteraction(property, value) {
  return {
    type: UPDATE_INTERACTION,
    property: property,
    value: value
  };
}

function updateInteractionConfig(property, value) {
  var parentProperty = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'configuration';

  return {
    type: UPDATE_INTERACTION_CONFIGURATION,
    property: property,
    value: value,
    parentProperty: parentProperty
  };
}

function updateInteractionMessage(messageType, value) {
  return {
    type: UPDATE_INTERACTION_MESSAGE,
    messageType: messageType,
    value: value
  };
}

function updateInteractionQuestions(numQuestions) {
  return {
    type: UPDATE_INTERACTION_QUESTIONS,
    numQuestions: numQuestions
  };
}

function updateInteractionQuestionsSms() {
  return {
    type: UPDATE_INTERACTION_QUESTIONS_SMS
  };
}

function deleteQuestion(index) {
  return {
    type: DELETE_INTERACTION_QUESTION,
    index: index
  };
}

function updateInteractionQuestion(questionNum, property, value) {
  return {
    type: UPDATE_INTERACTION_QUESTION,
    index: questionNum - 1,
    property: property,
    value: value
  };
}

function updateInteractionOption(questionNum, optionNum, property, value) {
  return {
    type: UPDATE_INTERACTION_OPTION,
    index: questionNum - 1,
    optionNum: optionNum,
    property: property,
    value: value
  };
}

function addOption(questionNum) {
  return {
    type: ADD_INTERACTION_OPTION,
    index: questionNum - 1
  };
}

function deleteOption(questionNum, optionNum) {
  return {
    type: REMOVE_INTERACTION_OPTION,
    index: questionNum - 1,
    optionNum: optionNum
  };
}

function updateInteractionOptions(questionNum, options) {
  return {
    type: UPDATE_INTERACTION_OPTIONS,
    index: questionNum - 1,
    options: options
  };
}

function updateInteractionType(questionNum, responseType, responseAlternativeType) {
  return {
    type: UPDATE_INTERACTION_TYPE,
    index: questionNum - 1,
    response_type: responseType,
    response_alternative_type: responseAlternativeType
  };
}

function requestSave(interaction) {
  var interactionType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : STUDY;
  var copy = arguments[2];
  var study_type = arguments[3];
  // STUDY || CAMPAIGN
  var url = '/campaigns';
  if (interactionType === STUDY) {
    url = '/studies';
  }

  if (copy) {
    return {
      types: [INTERACTION_SAVE, INTERACTION_SAVE_SUCCESS, INTERACTION_SAVE_FAILED],
      promise: function promise(client) {
        return client.post(url + '/copy', { data: { interaction: interaction } });
      }
    };
  } else if (interaction._id) {
    return {
      types: [INTERACTION_SAVE, INTERACTION_SAVE_SUCCESS, INTERACTION_SAVE_FAILED],
      promise: function promise(client) {
        return client.put(url + '/' + interaction._id, { data: { interaction: interaction } });
      }
    };
  }

  switch (study_type) {
    case STUDY_OPEN:
      url += "/open";break;
    case STUDY_API:
      url += "/api";break;
    default:
      break;
  }

  return {
    types: [INTERACTION_SAVE, INTERACTION_SAVE_SUCCESS, INTERACTION_SAVE_FAILED],
    promise: function promise(client) {
      return client.post(url, { data: { interaction: interaction } });
    }
  };
}

function requestStart(uid) {
  var interactionType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : STUDY;
  // STUDY || CAMPAIGN
  var url = '/campaigns';
  if (interactionType === STUDY) {
    url = '/studies';
  }
  return {
    types: [INTERACTION_SAVE, INTERACTION_SAVE_SUCCESS, INTERACTION_SAVE_FAILED],
    promise: function promise(client) {
      return client.post(url + '/' + uid + '/start');
    }
  };
}

function requestStop(uid) {
  var interactionType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : STUDY;
  // STUDY || CAMPAIGN
  var url = '/campaigns';
  if (interactionType === STUDY) {
    url = '/studies';
  }
  return {
    types: [INTERACTION_SAVE, INTERACTION_SAVE_SUCCESS, INTERACTION_SAVE_FAILED],
    promise: function promise(client) {
      return client.post(url + '/' + uid + '/stop');
    }
  };
}

function requestDelete(uid) {
  var interactionType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : STUDY;
  // STUDY || CAMPAIGN
  var url = '/campaigns';
  if (interactionType === STUDY) {
    url = '/studies';
  }
  return {
    types: [INTERACTION_SAVE, INTERACTION_SAVE_SUCCESS, INTERACTION_SAVE_FAILED],
    promise: function promise(client) {
      return client.del(url + '/' + uid);
    }
  };
}

function getNewInteraction() {
  var interactionType = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : STUDY;
  var panelSource = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'DATA_STORE';
  // STUDY || CAMPAIGN
  var url = '/campaigns';
  if (interactionType === STUDY) {
    url = '/studies';
  }
  return {
    types: [INTERACTION_GET, INTERACTION_GET_SUCCESS, INTERACTION_GET_FAIL],
    promise: function promise(client) {
      return client.get(url + '/new', {
        params: {
          source: panelSource
        }
      });
    }
  };
}

function getInteraction(uid) {
  var interactionType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : STUDY;
  // STUDY || CAMPAIGN
  var url = '/campaigns';
  if (interactionType === STUDY) {
    url = '/studies';
  }
  return {
    types: [INTERACTION_GET, INTERACTION_GET_SUCCESS, INTERACTION_GET_FAIL],
    promise: function promise(client) {
      return client.get(url + '/' + uid);
    }
  };
}

function getInteractionCopy(uid) {
  var interactionType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : STUDY;
  // STUDY || CAMPAIGN
  var url = '/campaigns';
  if (interactionType === STUDY) {
    url = '/studies';
  }
  return {
    types: [INTERACTION_GET, INTERACTION_GET_SUCCESS, INTERACTION_GET_FAIL],
    promise: function promise(client) {
      return client.get(url + '/copy', {
        params: { interaction_uid: uid }
      });
    }
  };
}

function getInteractionResults(studyUid, interactionType) {
  var url = 'campaigns';
  if (interactionType === STUDY) {
    url = 'studies';
  }
  return {
    types: [INTERACTION_RESULTS_GET, INTERACTION_RESULTS_GET_SUCCESS, INTERACTION_RESULTS_GET_FAIL],
    promise: function promise(client) {
      return client.get('/' + url + '/' + studyUid + '/stats');
    }
  };
}

function clearResults() {
  return {
    type: INTERACTION_RESULTS_CLEAR
  };
}

function getReservedKeywords() {
  return {
    types: [INTERACTION_RESULTS_GET, INTERACTION_RESULTS_GET_SUCCESS, INTERACTION_RESULTS_GET_FAIL],
    promise: function promise(client) {
      return client.get('/keywords/reserved');
    }
  };
}

function getRewardBatches() {
  return {
    types: [REWARD_BATCHES_GET],
    promise: function promise(client) {
      return client.get('/reward/batches');
    }
  };
}