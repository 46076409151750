"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.push = exports.browserHistory = undefined;

var _config = require("../config");

var _config2 = _interopRequireDefault(_config);

var _history = require("history");

var _reactRouter = require("react-router");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var browserHistory = function browserHistory() {};
var push = function push() {};

if (__SERVER__) {} else {
    exports.browserHistory = browserHistory = (0, _reactRouter.useRouterHistory)(_history.createHistory)({
        basename: _config2.default.basename
    });

    exports.push = push = browserHistory.push;
}

exports.browserHistory = browserHistory;
exports.push = push;