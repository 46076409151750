'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _typeof2 = require('babel-runtime/helpers/typeof');

var _typeof3 = _interopRequireDefault(_typeof2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _class, _temp;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactTextareaAutosize = require('react-textarea-autosize');

var _reactTextareaAutosize2 = _interopRequireDefault(_reactTextareaAutosize);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = {
  container: {
    display: 'inline-block',
    position: 'relative',
    overflow: 'hidden'
  },
  textarea: {
    display: 'block',
    position: 'relative',
    margin: 0,
    padding: 0,
    borderRadius: 0,
    font: 'inherit',
    overflowX: 'hidden',
    overflowY: 'auto',
    border: 'none',
    background: 'none',
    width: '100%',
    height: '100%'
  },
  highlights: {
    width: 'auto',
    height: 'auto',
    borderColor: 'transparent',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    color: 'transparent',
    overflow: 'hidden'
  },
  backdrop: {
    position: 'absolute',
    top: 0,
    right: '-99px',
    bottom: 0,
    left: 0,
    paddingRight: '99px',
    overflowX: 'hidden',
    overflowY: 'auto',
    width: '100%',
    height: '100%'
  }
};

var stringStyles = {
  mark: {
    color: 'transparent',
    'background-color': '#BCE0FB'
  }
};

var HighlightingTextarea = (_temp = _class = function (_React$Component) {
  (0, _inherits3.default)(HighlightingTextarea, _React$Component);

  function HighlightingTextarea() {
    (0, _classCallCheck3.default)(this, HighlightingTextarea);
    return (0, _possibleConstructorReturn3.default)(this, (HighlightingTextarea.__proto__ || (0, _getPrototypeOf2.default)(HighlightingTextarea)).apply(this, arguments));
  }

  (0, _createClass3.default)(HighlightingTextarea, [{
    key: 'stylesToString',
    value: function stylesToString(style) {
      var stringStyle = '';
      for (var s in style) {
        if (style.hasOwnProperty(s)) {
          stringStyle += s + ': ' + style[s] + '; ';
        }
      }
      return stringStyle;
    }
  }, {
    key: 'searchRegExp',
    value: function searchRegExp(f) {
      return f.map(function (item) {
        if (typeof item === 'string') {
          return new RegExp(item.replace(/[.?*+^$[\]\\(){}|-]/g, '\\$&'), 'g');
        } else if ((typeof item === 'undefined' ? 'undefined' : (0, _typeof3.default)(item)) === 'object' && item instanceof RegExp) {
          return item;
        } else if ((typeof item === 'undefined' ? 'undefined' : (0, _typeof3.default)(item)) === 'object' && item instanceof Array) {
          return new RegExp('(' + item.map(function (i) {
            i.replace(/[.?*+^$[\]\\(){}|-]/g, '\\$&');
          }).join('|') + ')');
        }
      });
    }
  }, {
    key: 'applyHighlights',
    value: function applyHighlights(text, match) {
      var _this2 = this;

      var highlightedText = text.replace(/\n$/g, '\n\n');
      var search = this.searchRegExp(match);
      search.forEach(function (reg) {
        highlightedText = highlightedText.replace(reg, '<mark style="' + _this2.stylesToString(stringStyles.mark) + '">$&</mark>');
      });
      return highlightedText;
    }
  }, {
    key: 'render',
    value: function render() {
      var _props = this.props,
          matches = _props.matches,
          text = _props.text,
          onChange = _props.onChange,
          className = _props.className,
          placeholder = _props.placeholder;

      var highlightedText = this.applyHighlights(text, matches);

      return _react2.default.createElement(
        'div',
        { style: styles.container, className: className },
        _react2.default.createElement(
          'div',
          { style: styles.backdrop },
          _react2.default.createElement('div', { style: styles.highlights, dangerouslySetInnerHTML: { __html: highlightedText } })
        ),
        _react2.default.createElement(_reactTextareaAutosize2.default, {
          spellCheck: false,
          value: text,
          onChange: onChange,
          style: styles.textarea,
          placeholder: placeholder
        })
      );
    }
  }]);
  return HighlightingTextarea;
}(_react2.default.Component), _class.propTypes = {
  matches: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.arrayOf(_propTypes2.default.string)]),
  text: _propTypes2.default.string.isRequired,
  className: _propTypes2.default.string.isRequired,
  placeholder: _propTypes2.default.string.isRequired,
  onChange: _propTypes2.default.func.isRequired
}, _class.defaultProps = {
  matches: []
}, _temp);
exports.default = HighlightingTextarea;
module.exports = exports['default'];