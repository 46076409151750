'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fetchActions = exports.messageTypes = undefined;
exports.studiesReducer = studiesReducer;
exports.getStudies = getStudies;
exports.studiesRefresh = studiesRefresh;

var _apiAction = require('../apiAction');

var _apiAction2 = _interopRequireDefault(_apiAction);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var messageTypes = exports.messageTypes = {
  FINISHED: 'Finished',
  ACTIVE: 'Active'

  // ACTIONS


};var fetchActions = exports.fetchActions = new _apiAction2.default('STUDIES', [_apiAction.ACTION_TYPES.DATA, _apiAction.ACTION_TYPES.APPEND, _apiAction.ACTION_TYPES.TRIM]);

// REDUCER


var initialState = function initialState(actions) {
  return {
    loading: false,
    status: actions.APPEND,
    list: []
  };
};

function studiesReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState(fetchActions);
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  return fetchActions.getStateForAction(state, action);
}

// ACTION CREATORS


function getStudies(params) {
  // statusList
  // status: statusList.join(','),
  var actions = fetchActions;
  return {
    types: [actions.APPEND, actions.APPEND_SUCCESS, actions.APPEND_FAIL],
    promise: function promise(client) {
      return client.get('/studies', {
        params: params
      });
    }
  };
}

function studiesRefresh() {
  return {
    type: fetchActions.TRIM,
    count: 0
  };
}