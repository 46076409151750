'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _class, _class2, _temp;

var _components = require('components');

var _reactRouter = require('react-router');

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _reachHistory = require('../../helpers/reachHistory');

var _Modal = require('./Modal.scss');

var _Modal2 = _interopRequireDefault(_Modal);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var md = {
  close: require('react-icons/lib/md/close')
};

var Modal = (_dec = (0, _reactRedux.connect)(null, { push: _reachHistory.push }), _dec(_class = (_temp = _class2 = function (_React$Component) {
  (0, _inherits3.default)(Modal, _React$Component);

  function Modal() {
    (0, _classCallCheck3.default)(this, Modal);
    return (0, _possibleConstructorReturn3.default)(this, (Modal.__proto__ || (0, _getPrototypeOf2.default)(Modal)).apply(this, arguments));
  }

  (0, _createClass3.default)(Modal, [{
    key: 'handleKeyDown',
    value: function handleKeyDown(e) {
      if (e.which == 27) {
        this.props.push(this.props.returnTo);
      }
    }
  }, {
    key: 'render',
    value: function render() {
      var modalSize = this.props.modalSize;
      if (!modalSize) {
        // default modalSize
        modalSize = { width: '830px', height: '530px' };
      }
      if (this.props.fullModal) {
        modalSize = { width: '95vw', height: '95vh' };
      }
      var style = { opacity: this.props.opacity / 60 };

      return _react2.default.createElement(
        'div',
        { className: _Modal2.default.container },
        _react2.default.createElement(_components.MenuContainer, null),
        _react2.default.createElement(
          'div',
          { className: _Modal2.default.modaltitle },
          this.renderTitle()
        ),
        _react2.default.createElement(
          'div',
          { className: _Modal2.default.modalmain },
          this.props.children
        )
      );
    }
  }]);
  return Modal;
}(_react2.default.Component), _class2.propTypes = {
  returnTo: _propTypes2.default.string,
  children: _propTypes2.default.any,
  modalSize: _propTypes2.default.object,
  fullModal: _propTypes2.default.bool,
  opacity: _propTypes2.default.number
}, _temp)) || _class);
exports.default = Modal;
module.exports = exports['default'];