'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ApiDetail = exports.AccountDetail = exports.UserDetail = exports.default = undefined;

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _getOwnPropertyDescriptor = require('babel-runtime/core-js/object/get-own-property-descriptor');

var _getOwnPropertyDescriptor2 = _interopRequireDefault(_getOwnPropertyDescriptor);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _class, _class2, _temp, _dec2, _class3, _desc, _value, _class4, _class5, _temp2, _dec3, _class6, _desc2, _value2, _class7, _class8, _temp3, _dec4, _class9, _desc3, _value3, _class10, _class11, _temp4;

var _components = require('components');

var _auth = require('redux/modules/auth');

var _status = require('redux/modules/status');

var _partner = require('../../helpers/partner');

var _themeEnabled = require('helpers/themeEnabled');

var _account = require('redux/modules/account');

var _Allow = require('../Allow/Allow');

var _Allow2 = _interopRequireDefault(_Allow);

var _reactRouter = require('react-router');

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _reduxConnect = require('redux-connect');

var _coreDecorators = require('core-decorators');

var _reactRedux = require('react-redux');

var _users = require('redux/modules/users');

var _reachHistory = require('../../helpers/reachHistory');

var _useBasename = require('../../helpers/useBasename');

var _useBasename2 = _interopRequireDefault(_useBasename);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
  var desc = {};
  Object['ke' + 'ys'](descriptor).forEach(function (key) {
    desc[key] = descriptor[key];
  });
  desc.enumerable = !!desc.enumerable;
  desc.configurable = !!desc.configurable;

  if ('value' in desc || desc.initializer) {
    desc.writable = true;
  }

  desc = decorators.slice().reverse().reduce(function (desc, decorator) {
    return decorator(target, property, desc) || desc;
  }, desc);

  if (context && desc.initializer !== void 0) {
    desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
    desc.initializer = undefined;
  }

  if (desc.initializer === void 0) {
    Object['define' + 'Property'](target, property, desc);
    desc = null;
  }

  return desc;
}

var User = (_dec = (0, _reactRedux.connect)(function (state) {
  return {
    mobileMode: state.mobile.mobileMode
  };
}, null), _dec(_class = (_temp = _class2 = function (_React$Component) {
  (0, _inherits3.default)(User, _React$Component);

  function User() {
    (0, _classCallCheck3.default)(this, User);
    return (0, _possibleConstructorReturn3.default)(this, (User.__proto__ || (0, _getPrototypeOf2.default)(User)).apply(this, arguments));
  }

  (0, _createClass3.default)(User, [{
    key: 'renderTabs',
    value: function renderTabs() {
      return _react2.default.createElement(_components.Tabs, {
        options: [{
          href: {
            pathname: '/user',
            state: { modal: true, returnTo: '/contacts/',
              modalSize: { width: '900px', height: '650px' }
            }
          },
          title: (0, _translations2.default)('USER DETAIL'),
          is_index: true
        }, {
          href: {
            pathname: '/account',
            state: { modal: true, returnTo: '/contacts/',
              modalSize: { width: '900px', height: '650px' }
            }
          },
          title: (0, _translations2.default)('ACCOUNT DETAIL'),
          is_index: true
        }, {
          href: {
            pathname: '/accountcustomizer/',
            state: { modal: true, returnTo: '/contacts/',
              modalSize: { width: '900px', height: '650px' }
            }
          },
          title: (0, _translations2.default)('WEB CHANNEL'),
          is_index: true
        }, {
          href: {
            pathname: '/apiDetail',
            state: { modal: true, returnTo: '/contacts/',
              modalSize: { width: '900px', height: '650px' }
            }
          },
          title: (0, _translations2.default)('ACCOUNT API DETAIL'),
          is_index: true
        }]
      });
    }
  }, {
    key: 'render',
    value: function render() {
      var styles = require('./User.scss');
      return _react2.default.createElement(
        'article',
        { className: styles.user },
        this.renderTabs(),
        _react2.default.createElement(
          'section',
          { className: styles.main },
          this.props.children
        )
      );
    }
  }]);
  return User;
}(_react2.default.Component), _class2.propTypes = {
  mobileMode: _propTypes2.default.bool.isRequired,
  children: _propTypes2.default.any.isRequired
}, _temp)) || _class);
exports.default = User;
var UserDetail = exports.UserDetail = (_dec2 = (0, _reactRedux.connect)(function (state) {
  return {
    user: state.auth.data.user,
    accounts: state.auth.data.account_list
  };
}, { logout: _auth.logout, load: _auth.load, pushState: _reachHistory.push, changeAccount: _auth.changeAccount, changePassword: _auth.changePassword }), _dec2(_class3 = (_class4 = (_temp2 = _class5 = function (_React$Component2) {
  (0, _inherits3.default)(UserDetail, _React$Component2);

  function UserDetail() {
    (0, _classCallCheck3.default)(this, UserDetail);
    return (0, _possibleConstructorReturn3.default)(this, (UserDetail.__proto__ || (0, _getPrototypeOf2.default)(UserDetail)).apply(this, arguments));
  }

  (0, _createClass3.default)(UserDetail, [{
    key: 'handleLogout',
    value: function handleLogout() {
      this.props.pushState('/');
      this.props.logout().then(function () {
        window.location = window.location;
      });
    }
  }, {
    key: 'navigateAfterChange',
    value: function navigateAfterChange(account_type) {
      if (account_type == "Reseller") {
        window.location = '/reseller';
      } else {
        window.location = '/contacts';
      }
    }
  }, {
    key: 'changeAccount',
    value: function changeAccount(event) {
      var _this3 = this;

      var element = event.target;
      var account_type = element.options[element.selectedIndex].dataset.accounttype;
      this.props.changeAccount(element.value).then(function () {
        _this3.navigateAfterChange(account_type);
      }).catch(function () {
        _this3.navigateAfterChange(account_type);
      });
    }
  }, {
    key: 'render',
    value: function render() {
      var styles = require('./User.scss');
      var user = this.props.user;


      if (!this.props.user || !this.props.accounts) {
        return _react2.default.createElement(
          'h1',
          null,
          ' Cambiando cuenta... '
        );
      }
      // ACCOUNTSELECTOR
      var account = this.props.accounts.find(function (a) {
        return a.is_active;
      });
      var accounts = this.props.accounts;
      var name = '';
      if (user.first_name) {
        name = user.first_name;
      }
      if (user.last_name) {
        name = name + ' ' + user.last_name;
      }

      return _react2.default.createElement(
        'div',
        { className: styles.detail },
        _react2.default.createElement(
          'table',
          { className: styles.userDetailTable },
          _react2.default.createElement(
            'tbody',
            null,
            _react2.default.createElement(
              'h1',
              { className: styles.title },
              (0, _translations2.default)('USER INFORMATION')
            ),
            _react2.default.createElement(
              'tr',
              { className: styles.tr },
              _react2.default.createElement(
                'td',
                { className: styles.td },
                (0, _translations2.default)('USER NAME')
              ),
              _react2.default.createElement(
                'td',
                { className: styles.td2 },
                name
              )
            ),
            _react2.default.createElement(
              'tr',
              null,
              _react2.default.createElement(
                'td',
                { className: styles.td },
                (0, _translations2.default)('USER EMAIL')
              ),
              _react2.default.createElement(
                'td',
                { className: styles.td2 },
                user.email
              )
            )
          )
        )
      );
    }
  }]);
  return UserDetail;
}(_react2.default.Component), _class5.propTypes = {
  user: _propTypes2.default.object,
  accounts: _propTypes2.default.array,
  logout: _propTypes2.default.func.isRequired,
  pushState: _propTypes2.default.func.isRequired
}, _temp2), (_applyDecoratedDescriptor(_class4.prototype, 'handleLogout', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class4.prototype, 'handleLogout'), _class4.prototype)), _class4)) || _class3);
var AccountDetail = exports.AccountDetail = (_dec3 = (0, _reactRedux.connect)(function (state) {
  return {
    isLoading: state.account.loading,
    account: state.account.data,
    status: state.status.data,
    auth: state.auth,
    user: state.auth.data.user,
    accounts: state.auth.data.account_list };
}, { logout: _auth.logout, load: _auth.load, getAccountStatus: _status.getAccountStatus, createUser: _users.createUser,
  pushState: _reachHistory.push, changePassword: _auth.changePassword
}), _dec3(_class6 = (_class7 = (_temp3 = _class8 = function (_React$Component3) {
  (0, _inherits3.default)(AccountDetail, _React$Component3);

  function AccountDetail(props) {
    (0, _classCallCheck3.default)(this, AccountDetail);

    var _this4 = (0, _possibleConstructorReturn3.default)(this, (AccountDetail.__proto__ || (0, _getPrototypeOf2.default)(AccountDetail)).call(this, props));

    _this4.state = {
      requesting: false,
      userCreated: false,
      usertags: [],
      userrole: 'admin'
    };
    _this4.account_uid = props.auth.data.account_list.find(function (a) {
      return a.is_active;
    }).account_uid;
    _this4.federated_user = props.auth.data.account_list.find(function (a) {
      return a.is_active;
    }).federated_user;
    _this4.isStaff = props.auth.current_account.roles.indexOf('staff') > -1;
    return _this4;
  }

  (0, _createClass3.default)(AccountDetail, [{
    key: 'componentWillMount',
    value: function componentWillMount() {
      var _this5 = this;

      setTimeout(function () {
        _this5.props.getAccountStatus();
      }, 500);
    }
  }, {
    key: 'handleChange',
    value: function handleChange() {
      var _this6 = this;

      this.props.changePassword().then(function () {
        _this6.props.pushState('/');
        setTimeout(function () {
          _this6.props.logout().then(function () {
            window.location = '/';
          });
        }, 500);
      });
    }
  }, {
    key: 'handleTagsChange',
    value: function handleTagsChange(tags) {
      this.setState((0, _extends3.default)({}, this.state, {
        usertags: tags.map(function (t) {
          return t.value;
        })
      }));
    }
  }, {
    key: 'handleUserRequest',
    value: function handleUserRequest() {
      this.props.pushState('/users');
    }
  }, {
    key: 'handleRoleChange',
    value: function handleRoleChange(e) {
      this.setState((0, _extends3.default)({}, this.state, {
        userrole: e.target.value
      }));
    }
  }, {
    key: 'handleUserSubmit',
    value: function handleUserSubmit() {
      var _this7 = this;

      var userdata = {
        first_name: this.refs.first_name.value,
        last_name: this.refs.last_name.value,
        username: this.refs.email.value,
        email: this.refs.email.value,
        msisdn: this.refs.phone.value,
        roles: [this.refs.role.value],
        tags: this.state.usertags
      };
      var account_uid = this.account_uid;
      var permissions = null;

      this.props.createUser(userdata, account_uid, permissions).then(function () {
        _this7.setState((0, _extends3.default)({}, _this7.state, {
          requesting: false,
          userCreated: true
        }));
      });
    }
  }, {
    key: 'render',
    value: function render() {
      var styles = require('./User.scss');
      var account = this.props.status;

      if (!account || !account.account_name) {
        return _react2.default.createElement(
          'div',
          { className: styles.loading },
          _react2.default.createElement(_components.Loading, null)
        );
      }

      var classesTheme = (0, _themeEnabled.getThemeEnabledStyle)(styles);

      var partner = (0, _partner.getPartnerByHost)();
      if (this.props.auth.current_account && !partner) {
        partner = this.props.auth.current_account.partner;
      }

      if (!this.state.requesting) {
        return _react2.default.createElement(
          'div',
          { className: classesTheme },
          _react2.default.createElement(
            'div',
            { className: styles.account },
            _react2.default.createElement(
              'div',
              { className: styles.tables },
              _react2.default.createElement(
                'h1',
                { className: styles.title },
                (0, _translations2.default)('ACCOUNT INFORMATION')
              ),
              this.state.userCreated && _react2.default.createElement(
                'div',
                { className: styles.info },
                (0, _translations2.default)('USER CREATION REQUESTED')
              ),
              _react2.default.createElement(
                'table',
                { className: styles.userTable },
                _react2.default.createElement(
                  'tbody',
                  null,
                  _react2.default.createElement(
                    'tr',
                    null,
                    _react2.default.createElement(
                      'td',
                      { className: styles.td },
                      (0, _translations2.default)('ACCOUNT NAME')
                    ),
                    _react2.default.createElement(
                      'td',
                      { className: styles.td2 },
                      account.account_name
                    )
                  ),
                  _react2.default.createElement(
                    'tr',
                    null,
                    _react2.default.createElement(
                      'td',
                      { className: styles.td },
                      (0, _translations2.default)('ACCOUNT VALID SINCE')
                    ),
                    _react2.default.createElement(
                      'td',
                      { className: styles.td2 },
                      account.valid_since
                    )
                  ),
                  _react2.default.createElement(
                    'tr',
                    null,
                    _react2.default.createElement(
                      'td',
                      { className: styles.td },
                      (0, _translations2.default)('ACCOUNT SMS SHORT NAME')
                    ),
                    _react2.default.createElement(
                      'td',
                      { className: styles.td2 },
                      account.sms_short_name
                    )
                  ),
                  _react2.default.createElement(
                    'tr',
                    null,
                    _react2.default.createElement(
                      'td',
                      { className: styles.td },
                      (0, _translations2.default)('ACCOUNT PHONE')
                    ),
                    _react2.default.createElement(
                      'td',
                      { className: styles.td2 },
                      account.channel && account.channel.identifier
                    )
                  ),
                  _react2.default.createElement(
                    'tr',
                    null,
                    _react2.default.createElement(
                      'td',
                      { className: styles.td },
                      (0, _translations2.default)('ACCOUNT PACKAGE')
                    ),
                    _react2.default.createElement(
                      'td',
                      { className: styles.td2 },
                      account.package
                    )
                  ),
                  _react2.default.createElement(
                    'tr',
                    null,
                    _react2.default.createElement(
                      'td',
                      { className: styles.td },
                      (0, _translations2.default)('ACCOUNT CYCLE')
                    ),
                    _react2.default.createElement(
                      'td',
                      { className: styles.td2 },
                      account.cycle
                    )
                  ),
                  _react2.default.createElement(
                    'tr',
                    null,
                    _react2.default.createElement(
                      'td',
                      { className: styles.td },
                      (0, _translations2.default)('ACCOUNT MESSAGES')
                    ),
                    _react2.default.createElement(
                      'td',
                      { className: styles.td2 },
                      account.current_package && account.current_package.credit_base_limit
                    )
                  ),
                  _react2.default.createElement(
                    'tr',
                    null,
                    _react2.default.createElement(
                      'td',
                      { className: styles.td },
                      (0, _translations2.default)('ACCOUNT EXTRAS')
                    ),
                    _react2.default.createElement(
                      'td',
                      { className: styles.td2 },
                      account.current_package && account.current_package.credit_extra_limit
                    )
                  ),
                  _react2.default.createElement(
                    'tr',
                    null,
                    _react2.default.createElement(
                      'td',
                      { className: styles.td },
                      (0, _translations2.default)('ACCOUNT SUSCRIPTION')
                    ),
                    _react2.default.createElement(
                      'td',
                      { className: styles.td2 },
                      account.subscription_type
                    )
                  ),
                  _react2.default.createElement(
                    'tr',
                    null,
                    _react2.default.createElement(
                      'td',
                      { className: styles.td },
                      (0, _translations2.default)('Horario')
                    ),
                    _react2.default.createElement(
                      'td',
                      { className: styles.td2 },
                      account.csm.allowed_service_hour_start,
                      ':00 - ',
                      account.csm.allowed_service_hour_end,
                      ':59 Horas'
                    )
                  )
                )
              ),
              _react2.default.createElement(UserDetail, { className: styles.user }),
              !this.isStaff && _react2.default.createElement(
                'div',
                { className: styles.createUser },
                _react2.default.createElement(
                  _Allow2.default,
                  { permissions: ['account:user:list'] },
                  _react2.default.createElement(
                    'button',
                    { onClick: this.handleUserRequest, className: styles.button + ' ' + styles.blue },
                    (0, _translations2.default)('REQUEST NEW USER')
                  )
                ),
                _react2.default.createElement(
                  _Allow2.default,
                  { permissions: ['account:password:request'] },
                  !this.federated_user && _react2.default.createElement(
                    'div',
                    { className: styles.changePassword },
                    _react2.default.createElement(
                      'a',
                      { onClick: this.handleChange, className: styles.button },
                      ' ',
                      (0, _translations2.default)('CHANGE PASSWORD'),
                      ' '
                    )
                  )
                )
              )
            ),
            _react2.default.createElement('img', { src: partner == "tigo" ? (0, _useBasename2.default)('/static/usersTigo.svg') : (0, _useBasename2.default)('/static/users.svg'), className: styles.artwork2 })
          )
        );
      } else {
        return _react2.default.createElement(
          'div',
          { className: classesTheme },
          _react2.default.createElement(
            'div',
            { className: styles.detail },
            _react2.default.createElement(
              'h1',
              { className: styles.title },
              (0, _translations2.default)('NEW USER')
            ),
            _react2.default.createElement(
              'table',
              { className: styles.userTable },
              _react2.default.createElement(
                'tbody',
                null,
                _react2.default.createElement(
                  'tr',
                  null,
                  _react2.default.createElement(
                    'td',
                    { className: styles.td },
                    (0, _translations2.default)('USER FIRST NAME')
                  ),
                  _react2.default.createElement(
                    'td',
                    { className: styles.td2 },
                    _react2.default.createElement('input', { className: styles.textstyle, ref: 'first_name' })
                  )
                ),
                _react2.default.createElement(
                  'tr',
                  null,
                  _react2.default.createElement(
                    'td',
                    { className: styles.td },
                    (0, _translations2.default)('USER LAST NAME')
                  ),
                  _react2.default.createElement(
                    'td',
                    { className: styles.td2 },
                    _react2.default.createElement('input', { className: styles.textstyle, ref: 'last_name' })
                  )
                ),
                _react2.default.createElement(
                  'tr',
                  null,
                  _react2.default.createElement(
                    'td',
                    { className: styles.td },
                    (0, _translations2.default)('USER EMAIL')
                  ),
                  _react2.default.createElement(
                    'td',
                    { className: styles.td2 },
                    _react2.default.createElement('input', { className: styles.textstyle, ref: 'email' })
                  )
                ),
                _react2.default.createElement(
                  'tr',
                  null,
                  _react2.default.createElement(
                    'td',
                    { className: styles.td },
                    (0, _translations2.default)('USER PHONE')
                  ),
                  _react2.default.createElement(
                    'td',
                    { className: styles.td2 },
                    _react2.default.createElement('input', { className: styles.textstyle, ref: 'phone' })
                  )
                ),
                _react2.default.createElement(
                  'tr',
                  null,
                  _react2.default.createElement(
                    'td',
                    { className: styles.td },
                    (0, _translations2.default)('USER ROLE')
                  ),
                  _react2.default.createElement(
                    'td',
                    { className: styles.td2 },
                    _react2.default.createElement(
                      'select',
                      { value: this.state.userrole, ref: 'role', onChange: this.handleRoleChange },
                      _react2.default.createElement(
                        'option',
                        { value: 'admin' },
                        'admin'
                      ),
                      _react2.default.createElement(
                        'option',
                        { value: 'staff' },
                        'staff'
                      )
                    )
                  )
                ),
                this.state.userrole === 'staff' && _react2.default.createElement(
                  'tr',
                  null,
                  _react2.default.createElement(
                    'td',
                    { className: styles.td },
                    (0, _translations2.default)('USER TAGS')
                  ),
                  _react2.default.createElement(
                    'td',
                    { className: styles.td2 },
                    _react2.default.createElement(_components.TagsSelect, {
                      onChange: this.handleTagsChange
                    })
                  )
                )
              )
            ),
            _react2.default.createElement(
              'div',
              { className: styles.logout },
              _react2.default.createElement(
                'button',
                { onClick: this.handleUserSubmit, className: styles.button },
                (0, _translations2.default)('ADD NEW USER')
              )
            )
          )
        );
      }
    }
  }]);
  return AccountDetail;
}(_react2.default.Component), _class8.propTypes = {
  account: _propTypes2.default.object.isRequired,
  user: _propTypes2.default.object.isRequired,
  logout: _propTypes2.default.func.isRequired,
  pushState: _propTypes2.default.func.isRequired
}, _temp3), (_applyDecoratedDescriptor(_class7.prototype, 'handleChange', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class7.prototype, 'handleChange'), _class7.prototype), _applyDecoratedDescriptor(_class7.prototype, 'handleTagsChange', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class7.prototype, 'handleTagsChange'), _class7.prototype), _applyDecoratedDescriptor(_class7.prototype, 'handleUserRequest', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class7.prototype, 'handleUserRequest'), _class7.prototype), _applyDecoratedDescriptor(_class7.prototype, 'handleRoleChange', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class7.prototype, 'handleRoleChange'), _class7.prototype), _applyDecoratedDescriptor(_class7.prototype, 'handleUserSubmit', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class7.prototype, 'handleUserSubmit'), _class7.prototype)), _class7)) || _class6);
var ApiDetail = exports.ApiDetail = (_dec4 = (0, _reactRedux.connect)(function (state) {
  return { isLoading: state.account.loading, credentials: state.account.data };
}, { getCredentials: _status.getCredentials, updateCredentials: _status.updateCredentials, getCallback: _account.getCallback, postCallback: _account.postCallback, getAccountStatus: _status.getAccountStatus, push: _reachHistory.push }), _dec4(_class9 = (_class10 = (_temp4 = _class11 = function (_React$Component4) {
  (0, _inherits3.default)(ApiDetail, _React$Component4);

  function ApiDetail(props) {
    (0, _classCallCheck3.default)(this, ApiDetail);

    var _this8 = (0, _possibleConstructorReturn3.default)(this, (ApiDetail.__proto__ || (0, _getPrototypeOf2.default)(ApiDetail)).call(this, props));

    _this8.state = {
      requesting: false,
      dataUpdated: false,
      api_callback_url: '',
      api_callback_custom_header: '',
      api_callback_envelope: '',
      csm: {},
      credentials: {}
    };
    return _this8;
  }

  (0, _createClass3.default)(ApiDetail, [{
    key: 'pathUpdateData',
    value: function pathUpdateData() {
      this.props.push({
        pathname: '/success',
        state: {
          goTo: '/account/apidetail/',
          message: (0, _translations2.default)('Datos Actualizados'),
          modal: true,
          returnTo: '/account/apidetail/'
        }
      });
    }
  }, {
    key: 'componentWillMount',
    value: function componentWillMount() {
      var _this9 = this;

      this.props.getCredentials().then(function (credentials) {
        _this9.setState({
          credentials: credentials
        });
      });
      this.props.getAccountStatus().then(function (account) {
        _this9.setState({
          csm: account.csm,
          api_callback_url: account.csm.api_callback_url,
          api_callback_custom_header: account.csm.api_callback_custom_header,
          api_callback_envelope: account.csm.api_callback_envelope,
          api_callback_email_notification: account.csm.api_callback_email_notification,
          error_messages_api_callback: account.csm.error_messages_api_callback,
          error_messages_fail_email: account.csm.error_messages_fail_email

        });
      });
    }
  }, {
    key: 'handleUserRequest',
    value: function handleUserRequest() {
      this.setState((0, _extends3.default)({}, this.state, {
        requesting: !this.state.requesting
      }));
    }
  }, {
    key: 'handleChangeCredentials',
    value: function handleChangeCredentials() {
      var _this10 = this;

      this.props.updateCredentials().then(function (credentials) {
        _this10.setState({
          credentials: credentials
        });
      });
    }
  }, {
    key: 'handleApiSubmit',
    value: function handleApiSubmit() {
      var _this11 = this;

      var apidata = {
        api_callback_url: this.state.api_callback_url,
        api_callback_custom_header: this.state.api_callback_custom_header,
        api_callback_envelope: this.state.api_callback_envelope,
        api_callback_email_notification: this.state.api_callback_email_notification,
        error_messages_api_callback: this.state.error_messages_api_callback,
        error_messages_fail_email: this.state.error_messages_fail_email
      };
      this.props.postCallback(apidata).then(function (response) {
        console.log(response);
        _this11.pathUpdateData();
      });
    }
  }, {
    key: 'render',
    value: function render() {
      var _this12 = this;

      var styles = require('./User.scss');
      var credentials = this.state.credentials;

      var partner = this.props.credentials.reach.partner || (0, _partner.getPartnerByHost)();
      var apiHost = (0, _partner.getApiHostByPartner)(partner);

      if (!credentials || !credentials.api_key) {
        return _react2.default.createElement(
          'div',
          { className: styles.loading },
          _react2.default.createElement(_components.Loading, null)
        );
      }

      var classesTheme = (0, _themeEnabled.getThemeEnabledStyle)(styles);

      var objAsset = (0, _themeEnabled.getAssetStyle)();
      var apiRest = objAsset.apiRest;
      var apiHttp = objAsset.apiHttp;
      var urlHost = process.env.REACT_APP_HOST;
      if (!this.state.requesting) {
        return _react2.default.createElement(
          'div',
          { className: classesTheme },
          _react2.default.createElement(
            'div',
            { className: styles.detailWrapper },
            _react2.default.createElement(
              'h1',
              { className: styles.title },
              (0, _translations2.default)('API INFORMATION')
            ),
            this.state.dataUpdated ? _react2.default.createElement(
              'div',
              { className: styles.info },
              (0, _translations2.default)('API DATA UPDATED')
            ) : _react2.default.createElement(
              'div',
              { className: styles.help },
              'Para instrucciones de c\xF3mo integrar tu aplicaci\xF3n empleando nuestro API, ',
              _react2.default.createElement('br', null),
              'visita la secci\xF3nes: \xA0 ',
              _react2.default.createElement('br', null),
              _react2.default.createElement(
                'ul',
                null,
                _react2.default.createElement(
                  'li',
                  null,
                  _react2.default.createElement(
                    'a',
                    { href: apiRest },
                    'Documentaci\xF3n del API REST'
                  )
                ),
                _react2.default.createElement(
                  'li',
                  null,
                  _react2.default.createElement(
                    'a',
                    { href: apiHttp },
                    'Documentaci\xF3n del API HTTP'
                  )
                )
              )
            ),
            _react2.default.createElement(
              'div',
              { className: styles.tableDiv },
              _react2.default.createElement(
                'table',
                { className: styles.userTableApi },
                _react2.default.createElement(
                  'tbody',
                  null,
                  _react2.default.createElement(
                    'tr',
                    null,
                    _react2.default.createElement(
                      'td',
                      { className: styles.td },
                      (0, _translations2.default)('URL API HTTP')
                    ),
                    _react2.default.createElement(
                      'td',
                      { className: styles.td2 },
                      '' + urlHost + (0, _useBasename2.default)() + '/api/http/'
                    )
                  ),
                  _react2.default.createElement(
                    'tr',
                    null,
                    _react2.default.createElement(
                      'td',
                      { className: styles.td },
                      (0, _translations2.default)('URL API REST')
                    ),
                    _react2.default.createElement(
                      'td',
                      { className: styles.td2 },
                      '' + urlHost + (0, _useBasename2.default)() + '/api/rest/'
                    )
                  ),
                  _react2.default.createElement(
                    'tr',
                    null,
                    _react2.default.createElement(
                      'td',
                      { className: styles.td },
                      (0, _translations2.default)('API KEY')
                    ),
                    _react2.default.createElement(
                      'td',
                      { className: styles.td2 },
                      credentials.api_key
                    )
                  ),
                  _react2.default.createElement(
                    'tr',
                    null,
                    _react2.default.createElement(
                      'td',
                      { className: styles.td },
                      (0, _translations2.default)('API SECRET')
                    ),
                    _react2.default.createElement(
                      'td',
                      { className: styles.td2 },
                      credentials.api_secret
                    )
                  ),
                  _react2.default.createElement(
                    _Allow2.default,
                    { permissions: ['account:configs:api:edit'] },
                    _react2.default.createElement(
                      'tr',
                      null,
                      _react2.default.createElement(
                        'td',
                        { className: styles.td },
                        (0, _translations2.default)('CREDENTIALS')
                      ),
                      _react2.default.createElement(
                        'td',
                        { className: styles.td2 },
                        _react2.default.createElement(
                          'button',
                          { onClick: this.handleChangeCredentials, className: styles.buttonApi + ' ' + styles.blue + ' ' + styles.buttonCredentials },
                          (0, _translations2.default)('CHANGE CREDENTIALS')
                        )
                      )
                    ),
                    _react2.default.createElement(
                      'tr',
                      null,
                      _react2.default.createElement(
                        'td',
                        { className: styles.td + ' ' + styles.td2, colSpan: 2 },
                        'Advertencia, al generar nuevas credenciales se debe actualizar inmediatamente cualquier aplicaci\xF3n o dejara de funcionar.'
                      )
                    )
                  )
                )
              ),
              _react2.default.createElement(
                _Allow2.default,
                { permissions: ['account:configs:api:edit'] },
                _react2.default.createElement(
                  'button',
                  { onClick: this.handleUserRequest, className: styles.buttonAdvanceConfig + ' ' + styles.blue },
                  (0, _translations2.default)('API ADVANCED SETTINGS')
                )
              )
            ),
            _react2.default.createElement(
              'span',
              { className: styles.TextApi },
              ' Para facilitar la integraci\xF3n utilizando la interfaz REST, se pone a disposici\xF3n una serie de librer\xEDas que permiten ejecutar las operaciones sin tener que implementar todo el protocolo REST. El uso del SDK no es requisito para utilizar la interfaz REST. Para ver los ejemplos de uso, favor referirse a la operaci\xF3n REST correspondiente. '
            ),
            _react2.default.createElement(
              'button',
              { className: styles.buttonApi + ' ' + styles.blue },
              ' ',
              _react2.default.createElement(
                'a',
                (0, _defineProperty3.default)({ className: styles.white, target: '_blank', href: 'https://github.com/interactuamovil/im-contactosms-sdk-java/releases/tag/im-contactosms-sdk-java-3.3.1' }, 'target', '_blank'),
                'SDK para Java '
              ),
              ' '
            ),
            _react2.default.createElement(
              'button',
              { className: styles.buttonApi + ' ' + styles.blue },
              ' ',
              _react2.default.createElement(
                'a',
                { className: styles.white, target: '_blank', href: 'https://github.com/interactuamovil/im-contactosms-sdk-dotnet/releases/tag/v2.0' },
                'SDK para C# '
              ),
              ' '
            ),
            _react2.default.createElement(
              'button',
              { className: styles.buttonApiRight + ' ' + styles.blue },
              ' ',
              _react2.default.createElement(
                'a',
                { className: styles.white, target: '_blank', href: 'https://github.com/interactuamovil/im-contactosms-sdk-php/releases/tag/v3.0' },
                ' SDK para PHP '
              ),
              '  '
            )
          )
        );
      } else {
        return _react2.default.createElement(
          'div',
          { className: classesTheme },
          _react2.default.createElement(
            'div',
            { className: styles.detailWrapper },
            _react2.default.createElement(
              'h1',
              { className: styles.title },
              (0, _translations2.default)('API SETTINGS')
            ),
            _react2.default.createElement(
              'table',
              { className: styles.userTable },
              _react2.default.createElement(
                'tbody',
                null,
                _react2.default.createElement(
                  'tr',
                  null,
                  _react2.default.createElement(
                    'td',
                    { className: styles.td },
                    (0, _translations2.default)('API CALLBACK URL')
                  ),
                  _react2.default.createElement(
                    'td',
                    { className: styles.td2 },
                    _react2.default.createElement('textarea', { placeholder: 'http://foo.bar', className: styles.textstyle,
                      value: this.state.api_callback_url, onChange: function onChange(e) {
                        return _this12.setState({ api_callback_url: e.target.value });
                      } })
                  )
                ),
                _react2.default.createElement(
                  'tr',
                  null,
                  _react2.default.createElement(
                    'td',
                    { className: styles.td },
                    (0, _translations2.default)('API CUSTOM HEADER')
                  ),
                  _react2.default.createElement(
                    'td',
                    { className: styles.td2 },
                    _react2.default.createElement('textarea', { placeholder: 'Content-Type: application/x-www-form-urlencoded', className: styles.textstyle,
                      value: this.state.api_callback_custom_header, onChange: function onChange(e) {
                        return _this12.setState({ api_callback_custom_header: e.target.value });
                      } })
                  )
                ),
                _react2.default.createElement(
                  'tr',
                  null,
                  _react2.default.createElement(
                    'td',
                    { className: styles.td },
                    (0, _translations2.default)('API ENVELOPE')
                  ),
                  _react2.default.createElement(
                    'td',
                    { className: styles.td2 },
                    _react2.default.createElement('textarea', { placeholder: '', className: styles.textstyle,
                      value: this.state.api_callback_envelope, onChange: function onChange(e) {
                        return _this12.setState({ api_callback_envelope: e.target.value });
                      } })
                  )
                ),
                _react2.default.createElement(
                  'tr',
                  null,
                  _react2.default.createElement(
                    'td',
                    { className: styles.td },
                    (0, _translations2.default)('EMAIL')
                  ),
                  _react2.default.createElement(
                    'td',
                    { className: styles.td2 },
                    _react2.default.createElement('textarea', { placeholder: 'ejemplo@mail.com', className: styles.textstyle,
                      value: this.state.api_callback_email_notification, onChange: function onChange(e) {
                        return _this12.setState({ api_callback_email_notification: e.target.value });
                      } })
                  )
                )
              )
            ),
            _react2.default.createElement(
              'h1',
              { className: styles.title },
              (0, _translations2.default)('API ERROR NOTIFICATION')
            ),
            _react2.default.createElement(
              'table',
              { className: styles.userTable },
              _react2.default.createElement(
                'tbody',
                null,
                _react2.default.createElement(
                  'tr',
                  null,
                  _react2.default.createElement(
                    'td',
                    { className: styles.td },
                    (0, _translations2.default)('CALLBACK URL')
                  ),
                  _react2.default.createElement(
                    'td',
                    { className: styles.td2 },
                    _react2.default.createElement('textarea', { placeholder: 'http://foo.bar', className: styles.textstyle,
                      value: this.state.error_messages_api_callback, onChange: function onChange(e) {
                        return _this12.setState({ error_messages_api_callback: e.target.value });
                      } })
                  )
                ),
                _react2.default.createElement(
                  'tr',
                  null,
                  _react2.default.createElement(
                    'td',
                    { className: styles.td },
                    (0, _translations2.default)('EMAIL')
                  ),
                  _react2.default.createElement(
                    'td',
                    { className: styles.td2 },
                    _react2.default.createElement('textarea', { placeholder: 'ejemplo@mail.com', className: styles.textstyle,
                      value: this.state.error_messages_fail_email, onChange: function onChange(e) {
                        return _this12.setState({ error_messages_fail_email: e.target.value });
                      } })
                  )
                )
              )
            ),
            _react2.default.createElement(
              'div',
              { className: styles.logout },
              _react2.default.createElement(
                'button',
                { onClick: this.handleApiSubmit, className: styles.button },
                (0, _translations2.default)('UPDATE SETTINGS')
              )
            )
          )
        );
      }
    }
  }]);
  return ApiDetail;
}(_react2.default.Component), _class11.propTypes = {
  credentials: _propTypes2.default.object.isRequired
}, _temp4), (_applyDecoratedDescriptor(_class10.prototype, 'handleUserRequest', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class10.prototype, 'handleUserRequest'), _class10.prototype), _applyDecoratedDescriptor(_class10.prototype, 'handleChangeCredentials', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class10.prototype, 'handleChangeCredentials'), _class10.prototype), _applyDecoratedDescriptor(_class10.prototype, 'handleApiSubmit', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class10.prototype, 'handleApiSubmit'), _class10.prototype)), _class10)) || _class9);