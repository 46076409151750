'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _getOwnPropertyDescriptor = require('babel-runtime/core-js/object/get-own-property-descriptor');

var _getOwnPropertyDescriptor2 = _interopRequireDefault(_getOwnPropertyDescriptor);

var _stringify = require('babel-runtime/core-js/json/stringify');

var _stringify2 = _interopRequireDefault(_stringify);

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _dec2, _class, _desc, _value, _class2, _class3, _temp;

var _components = require('components');

var _interaction = require('redux/modules/interaction');

var _Study = require('components/Study');

var _draftJs = require('draft-js');

var _variables = require('redux/modules/variables');

var _auth = require('redux/modules/auth');

var _Allow = require('../Allow/Allow');

var _Allow2 = _interopRequireDefault(_Allow);

var _reactHelmet = require('react-helmet');

var _reactHelmet2 = _interopRequireDefault(_reactHelmet);

var _Campaign = require('components/Campaign');

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _reduxConnect = require('redux-connect');

var _coreDecorators = require('core-decorators');

var _campaigns = require('redux/modules/campaigns');

var _reactRedux = require('react-redux');

var _account = require('redux/modules/account');

var _status = require('redux/modules/status');

var _themeEnabled = require('helpers/themeEnabled');

var _reports = require('redux/modules/reports');

var _reachHistory = require('../../helpers/reachHistory');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
  var desc = {};
  Object['ke' + 'ys'](descriptor).forEach(function (key) {
    desc[key] = descriptor[key];
  });
  desc.enumerable = !!desc.enumerable;
  desc.configurable = !!desc.configurable;

  if ('value' in desc || desc.initializer) {
    desc.writable = true;
  }

  desc = decorators.slice().reverse().reduce(function (desc, decorator) {
    return decorator(target, property, desc) || desc;
  }, desc);

  if (context && desc.initializer !== void 0) {
    desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
    desc.initializer = undefined;
  }

  if (desc.initializer === void 0) {
    Object['define' + 'Property'](target, property, desc);
    desc = null;
  }

  return desc;
}

var Campaign = (_dec = (0, _reduxConnect.asyncConnect)([{
  key: 'interaction',
  promise: function promise(_ref) {
    var query = _ref.location.query,
        dispatch = _ref.store.dispatch;

    try {
      var copy = query.copy,
          uid = query.uid;

      if (copy) {
        return dispatch((0, _interaction.getInteractionCopy)(copy, _interaction.CAMPAIGN));
      } else if (uid) {
        return dispatch((0, _interaction.getInteraction)(uid, _interaction.CAMPAIGN));
      }
      return dispatch((0, _interaction.getNewInteraction)(_interaction.CAMPAIGN));
    } catch (error) {
      console.log("error", error);
    }
  }
}, {
  key: 'account',
  promise: function promise(_ref2) {
    var _ref2$store = _ref2.store,
        dispatch = _ref2$store.dispatch,
        getState = _ref2$store.getState;

    var state = getState();
    if (!state.account.data._id) {
      return dispatch((0, _account.getAccount)());
    }
  }
}, {
  key: "auth",
  promise: function promise(_ref3) {
    var _ref3$store = _ref3.store,
        dispatch = _ref3$store.dispatch,
        getState = _ref3$store.getState;

    if (!(0, _auth.isLoaded)(getState())) {
      return dispatch((0, _auth.load)());
    }
    return null;
  }
}]), _dec2 = (0, _reactRedux.connect)(function (state) {
  return {
    mobileMode: state.mobile.mobileMode,
    interaction: state.interaction,
    variablesList: state.variables.list,
    account: state.account.data
  };
}, {
  getAccountStatus: _status.getAccountStatus,
  getTotalContacts: _reports.getTotalContacts,
  updateInteractionMessage: _interaction.updateInteractionMessage,
  getVariables: _variables.getVariables,
  requestStart: _interaction.requestStart,
  requestSave: _interaction.requestSave,
  pushState: _reachHistory.push,
  campaignsRefresh: _campaigns.campaignsRefresh,
  variablesRefresh: _variables.variablesRefresh
}), _dec(_class = _dec2(_class = (_class2 = (_temp = _class3 = function (_React$Component) {
  (0, _inherits3.default)(Campaign, _React$Component);

  function Campaign(props) {
    (0, _classCallCheck3.default)(this, Campaign);

    var _this = (0, _possibleConstructorReturn3.default)(this, (Campaign.__proto__ || (0, _getPrototypeOf2.default)(Campaign)).call(this, props));

    _this.state = {
      notification: '',
      editorState: _draftJs.EditorState.createEmpty(),
      copy: _this.props.location.query.copy
    };
    return _this;
  }

  (0, _createClass3.default)(Campaign, [{
    key: 'componentWillMount',
    value: function componentWillMount() {
      var _this2 = this;

      if (__CLIENT__) {
        this.props.variablesRefresh();
        this.props.getVariables();

        setTimeout(function () {
          if (_this2.props.interaction.configuration.messages.welcome && _this2.props.interaction.configuration.messages.welcome.config && _this2.props.interaction.configuration.messages.welcome.config.rawContent) {
            var rawContent = JSON.parse(_this2.props.interaction.configuration.messages.welcome.config.rawContent);
            var editorState = _draftJs.EditorState.createWithContent((0, _draftJs.convertFromRaw)(rawContent));
            _this2.setState({
              editorState: editorState
            });
          } else {
            var _rawContent = {
              entityMap: {},
              blocks: [{
                key: '18ql2',
                text: _this2.props.interaction.configuration.messages.welcome.text || '',
                type: 'unstyled',
                depth: 0,
                inlineStyleRanges: [],
                entityRanges: []
              }]
            };
            var _editorState = _draftJs.EditorState.createWithContent((0, _draftJs.convertFromRaw)(_rawContent));
            _this2.setState({
              editorState: _editorState
            });
          }
        }, 500);
      }
    }
  }, {
    key: 'getTitle',
    value: function getTitle() {
      var title = (0, _translations2.default)('NEW CAMPAIGN');
      if (this.props.interaction.name) {
        title = this.props.interaction.name;
      }
      if (this.props.params.copy) {
        title = (0, _translations2.default)('CAMPAIGN COPY');
      }
      return title;
    }
  }, {
    key: 'setEditorReference',
    value: function setEditorReference(ref) {
      this.editorReferece = ref;
    }
  }, {
    key: 'isValid',
    value: function isValid() {
      if (this.props.interaction.configuration.sending_channels.indexOf('EMAIL') > -1 && !this.props.interaction.configuration.messages.welcome.config.email_subject) {
        this.setState((0, _extends3.default)({}, this.state, {
          notification: {
            field: 'subject'
          }
        }));
        return false;
      }
      return true;
    }
  }, {
    key: 'save',
    value: function save() {
      var _this3 = this;

      var interaction = (0, _extends3.default)({}, this.props.interaction, { questions: [] });
      return this.props.requestSave(interaction, _interaction.CAMPAIGN, this.state.copy).then(function () {
        _this3.props.pushState({
          pathname: '/save',
          state: {
            goTo: '/campaigns/',
            message: (0, _translations2.default)('CAMPAIGN SAVE SUCCESS'),
            goToModal: false,
            modal: true,
            returnTo: '/campaigns/'
          }
        });
      }).catch(function (e) {
        console.error('Error saving campaign');
        _this3.setState((0, _extends3.default)({}, _this3.state, {
          notification: e
        }));
      });
    }
  }, {
    key: 'start',
    value: function start() {
      var _this4 = this;

      if (this.isValid()) {
        var interaction = (0, _extends3.default)({}, this.props.interaction, { questions: [] });
        return this.props.requestSave(interaction, _interaction.CAMPAIGN, this.state.copy).then(function (result) {
          return _this4.props.requestStart(result.interaction._id, _interaction.CAMPAIGN).then(function () {
            _this4.props.pushState({
              pathname: '/success',
              state: {
                goTo: '/campaigns/',
                message: (0, _translations2.default)('CAMPAIGN START SUCCESS'),
                goToModal: false,
                modal: true,
                returnTo: '/campaigns/'
              }
            });
          });
        }).catch(function (e) {
          console.error('Error saving campaign');
          _this4.setState((0, _extends3.default)({}, _this4.state, {
            notification: e
          }));
          ReactDOM.findDOMNode(_this4).scrollTop = 0;
        });
      }
      ReactDOM.findDOMNode(this).scrollTop = 0;
    }
  }, {
    key: 'insertAtEnd',
    value: function insertAtEnd(variableText) {
      var text = this.props.interaction.configuration.messages.welcome.message;

      this.props.updateInteractionMessage('welcome', {
        enabled: true,
        message: text + ('[' + variableText + ']')
      });
    }
  }, {
    key: 'handleChange',
    value: function handleChange(event) {
      this.props.updateInteractionMessage('welcome', {
        enabled: true,
        message: event.target.value
      });
    }
  }, {
    key: 'handleSubjectChange',
    value: function handleSubjectChange(subject) {
      var message = this.props.interaction.configuration.messages.welcome;
      if (!message.config) {
        message.config = {};
      }
      message.config.email_subject = subject;
      this.props.updateInteractionMessage('welcome', message);
    }
  }, {
    key: 'handleEditorStateChange',
    value: function handleEditorStateChange(editorState) {
      var _this5 = this;

      this.setState({
        editorState: editorState
      }, function () {
        var message = _this5.props.interaction.configuration.messages.welcome;
        var contentState = _this5.state.editorState.getCurrentContent();
        var rawContent = (0, _stringify2.default)((0, _draftJs.convertToRaw)(contentState));

        var text = '';
        if (_this5.props.interaction.configuration.sending_channels.indexOf('EMAIL') > -1) {
          text = _this5.editorReferece.editor.innerHTML;
        } else {
          text = _this5.editorReferece.editor.innerText.replace(/^\s+|\s+$/g, '');
        }

        // cambia a inline el estilo de alinear
        // TODO: don't do this every letter!
        var center = 'class="rdw-center-aligned-block" ';
        var left = 'class="rdw-left-aligned-block"';
        var right = 'class="rdw-right-aligned-block"';
        text = text.split(center).join(center + ' style="text-align: center" ');
        text = text.split(left).join(left + ' style="text-align: left" ');
        text = text.split(right).join(right + ' style="text-align: right" ');
        var imgregex = /<span\ class\=\"rdw-image-imagewrapper\"(?:(?!img).)*img(?:(?!alt).)*alt=\"([^\"]*)(?:(?!span).)*span(?:(?!span).)*span>/gm;
        text = text.replace(imgregex, function (a, b) {
          return '<a href=' + b + ' target=_blank>' + a + '</a>';
        });

        _this5.props.updateInteractionMessage('welcome', {
          enabled: true,
          message: text,
          config: (0, _extends3.default)({}, message.config, {
            rawContent: rawContent
          })
        });
      });
    }
  }, {
    key: 'renderHeader',
    value: function renderHeader() {
      return !this.props.mobileMode ? _react2.default.createElement(_components.GlobalTabs, { activeStyle: {} }) : null;
    }
  }, {
    key: 'renderNav',
    value: function renderNav(stickyprops) {
      return _react2.default.createElement(_components.Nav, { key: 'Nav', title: this.getTitle(), style: stickyprops.style });
    }
  }, {
    key: 'renderRight',
    value: function renderRight() {
      return null;
    }
  }, {
    key: 'render',
    value: function render() {
      var _this6 = this;

      var styles = require('./Campaign.scss');
      var text = this.props.interaction.configuration.messages.welcome.message;

      try {
        if (document) {
          var tempDiv = document.createElement("div");
          tempDiv.innerHTML = text;
          text = tempDiv.innerText;
        }
      } catch (error) {
        console.error("DOM document dont exists");
      }
      if (!this.props.account || !this.props.account.reach) {
        return null;
      }
      var channels = this.props.account.reach.channels;

      var hasEmail = !!channels.find(function (channel) {
        return channel.type === 'EMAIL' && channel.status === 'ACTIVE';
      });
      var channelsEnabled = ['SMS']; //  TODO: disable sms
      if (hasEmail) {
        channelsEnabled.push('EMAIL');
      }

      var errorMessage = (0, _translations2.default)('GENERIC INTERACTION ERROR');
      switch (this.state.notification.field) {
        case 'name':
          errorMessage = (0, _translations2.default)('ERROR IN NAME FIELD');
          break;
        case 'subject':
          errorMessage = (0, _translations2.default)('ERROR IN SUBJECT FIELD');
          break;
        default:
          if (this.state.notification.message) {
            errorMessage = this.state.notification.message;
          } else {
            // console.error('Error message from api was blank')
          }
      }
      var variablesLi = [];
      var variablesMatch = [];
      var availableVariables = ['first_name', 'last_name', 'gender', 'year_of_birth', 'department', 'municipality', 'email', 'marital_status', 'education', 'occupation'];
      var variables = this.props.variablesList;
      variables.filter(function (v) {
        return availableVariables.indexOf(v.name) > -1;
      }).forEach(function (variable, index) {
        var variableValue = variable.printable_value.text;
        variablesLi.push(_react2.default.createElement(
          'li',
          { key: index, className: styles.li, title: variable.printable_value.text, onClick: _this6.insertAtEnd.bind(_this6, variableValue) },
          variable.printable_value.text
        ));
        variablesMatch.push('[' + variableValue + ']');
      });

      var classesTheme = (0, _themeEnabled.getThemeEnabledStyle)(styles);

      return _react2.default.createElement(
        'div',
        { className: classesTheme },
        _react2.default.createElement(
          'article',
          { className: styles.campaign },
          _react2.default.createElement(_reactHelmet2.default, { title: (0, _translations2.default)('NEW CAMPAIGN') }),
          this.state.notification ? _react2.default.createElement(
            'label',
            { className: styles.error },
            errorMessage
          ) : null,
          _react2.default.createElement(
            'section',
            { className: styles.section + ' ' + styles.name },
            _react2.default.createElement(_Campaign.Name, null)
          ),
          _react2.default.createElement(
            'section',
            { className: styles.section + ' ' + styles.name },
            _react2.default.createElement(_Study.Panel, { type: 'CAMPAIGN' })
          ),
          _react2.default.createElement(
            'section',
            { className: styles.section + ' ' + styles.name },
            _react2.default.createElement(_Study.Configuration, { type: 'CAMPAIGN' })
          ),
          _react2.default.createElement(
            'section',
            { className: styles.section2 + ' ' + styles.userInput },
            this.props.interaction.configuration.sending_channels.indexOf('EMAIL') > -1 ? _react2.default.createElement(_components.Email, {
              editorReference: this.setEditorReference,
              editorState: this.state.editorState,
              onChange: this.handleEditorStateChange,
              onSubjectChange: this.handleSubjectChange
            }) : _react2.default.createElement(
              'section',
              { className: styles.section + ' ' + styles.userInput },
              _react2.default.createElement('div', { className: styles.sep }),
              _react2.default.createElement(
                'div',
                { className: styles.variables },
                _react2.default.createElement(
                  'ul',
                  { className: styles.ul },
                  variablesLi
                )
              ),
              _react2.default.createElement(
                'div',
                { className: styles.textareaArea },
                _react2.default.createElement(
                  'div',
                  { className: styles.textareaAreaArea },
                  _react2.default.createElement(_components.HighlightingTextarea, {
                    matches: variablesMatch,
                    text: text,
                    onChange: this.handleChange,
                    className: styles.textarea,
                    placeholder: (0, _translations2.default)('CAMPAIGN TEXTAREA')
                  })
                )
              )
            )
          ),
          _react2.default.createElement(
            'section',
            { className: styles.section2 + ' ' + styles.userInput },
            _react2.default.createElement(_Study.SendingChannels, { channelTypes: channelsEnabled, isCampaign: true })
          ),
          _react2.default.createElement(
            'div',
            { className: styles.footer },
            _react2.default.createElement(
              _Allow2.default,
              { permissions: ['campaign:edit'] },
              _react2.default.createElement(_components.Button, { onClick: this.save, name: (0, _translations2.default)('CAMPAIGN SAVE'), className: styles.buttonSave })
            ),
            _react2.default.createElement(
              _Allow2.default,
              { permissions: ['campaign:start'] },
              _react2.default.createElement(_components.Button, { onClick: this.start, name: (0, _translations2.default)('CAMPAIGN START'), className: styles.buttonStart })
            )
          )
        )
      );
    }
  }]);
  return Campaign;
}(_react2.default.Component), _class3.propTypes = {
  params: _propTypes2.default.object.isRequired,
  interaction: _propTypes2.default.object.isRequired,
  location: _propTypes2.default.object.isRequired,
  mobileMode: _propTypes2.default.bool.isRequired,
  variablesList: _propTypes2.default.array.isRequired,
  getVariables: _propTypes2.default.func.isRequired,
  updateInteractionMessage: _propTypes2.default.func.isRequired,
  requestStart: _propTypes2.default.func.isRequired,
  requestSave: _propTypes2.default.func.isRequired,
  pushState: _propTypes2.default.func.isRequired,
  variablesRefresh: _propTypes2.default.func.isRequired
}, _temp), (_applyDecoratedDescriptor(_class2.prototype, 'setEditorReference', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'setEditorReference'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, 'save', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'save'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, 'start', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'start'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, 'handleChange', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'handleChange'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, 'handleSubjectChange', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'handleSubjectChange'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, 'handleEditorStateChange', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'handleEditorStateChange'), _class2.prototype)), _class2)) || _class) || _class);
exports.default = Campaign;
module.exports = exports['default'];