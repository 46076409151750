'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StaffMenuContainer = exports.AdminMenuContainer = exports.FooterContainer = exports.MenuContainer = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _getOwnPropertyDescriptor = require('babel-runtime/core-js/object/get-own-property-descriptor');

var _getOwnPropertyDescriptor2 = _interopRequireDefault(_getOwnPropertyDescriptor);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _dec2, _class2, _desc, _value, _class3, _class4, _temp2, _dec3, _dec4, _class6, _desc2, _value2, _class7;

var _featureEnabled = require('helpers/featureEnabled');

var _auth = require('redux/modules/auth');

var _Allow = require('../../containers/Allow/Allow');

var _Allow2 = _interopRequireDefault(_Allow);

var _Button = require('@material-ui/core/Button');

var _Button2 = _interopRequireDefault(_Button);

var _Chip = require('@material-ui/core/Chip');

var _Chip2 = _interopRequireDefault(_Chip);

var _Divider = require('@material-ui/core/Divider');

var _Divider2 = _interopRequireDefault(_Divider);

var _reactRouter = require('react-router');

var _Menu = require('@material-ui/core/Menu');

var _Menu2 = _interopRequireDefault(_Menu);

var _MenuItem = require('@material-ui/core/MenuItem');

var _MenuItem2 = _interopRequireDefault(_MenuItem);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _reduxConnect = require('redux-connect');

var _coreDecorators = require('core-decorators');

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _config = require('../../config');

var _config2 = _interopRequireDefault(_config);

var _reactRedux = require('react-redux');

var _index = require('../../../node_modules/immutability-helper/index');

var _account = require('redux/modules/account');

var _reports = require('redux/modules/reports');

var _themeEnabled = require('helpers/themeEnabled');

var _numbro = require('numbro');

var _numbro2 = _interopRequireDefault(_numbro);

var _reachHistory = require('../../helpers/reachHistory');

var _Menu3 = require('./Menu.scss');

var _Menu4 = _interopRequireDefault(_Menu3);

var _withStyles = require('@material-ui/core/styles/withStyles');

var _withStyles2 = _interopRequireDefault(_withStyles);

var _reactSelect = require('react-select');

var _reactSelect2 = _interopRequireDefault(_reactSelect);

var _redirectToUrlBeforeReload = require('../../helpers/redirectToUrlBeforeReload');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
  var desc = {};
  Object['ke' + 'ys'](descriptor).forEach(function (key) {
    desc[key] = descriptor[key];
  });
  desc.enumerable = !!desc.enumerable;
  desc.configurable = !!desc.configurable;

  if ('value' in desc || desc.initializer) {
    desc.writable = true;
  }

  desc = decorators.slice().reverse().reduce(function (desc, decorator) {
    return decorator(target, property, desc) || desc;
  }, desc);

  if (context && desc.initializer !== void 0) {
    desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
    desc.initializer = undefined;
  }

  if (desc.initializer === void 0) {
    Object['define' + 'Property'](target, property, desc);
    desc = null;
  }

  return desc;
}

var md = {
  person: require('react-icons/lib/md/person'),
  mail: require('react-icons/lib/md/mail'),
  assignment: require('react-icons/lib/md/assignment'),
  assignmentInd: require('react-icons/lib/md/face'),
  assessment: require('react-icons/lib/md/assessment'),
  headset: require('react-icons/lib/md/headset-mic'),
  arrowBack: require('react-icons/lib/md/arrow-back'),
  pieChart: require('react-icons/lib/md/pie-chart'),
  file: require('react-icons/lib/md/attachment'),
  help: require('react-icons/lib/md/help'),
  add: require('react-icons/lib/md/add'),
  integrations: require('react-icons/lib/md/developer-board'),
  arrowForward: require('react-icons/lib/md/chevron-right') //MdChevronRight

  // const icons = {
  //   sms: require('./icons/sms.svg'),
  //   conversations:require('./icons/conversations.svg'),
  //   home: require('./icons/home.svg'),
  //   contacts: require('./icons/contacts.svg'),
  //   reports:require('./icons/reports.svg'),
  //   landings:require('./icons/landings.svg'),
  // }

};var icons = {
  sms: require('../../../static/notificame/menu/sms.svg'),
  conversations: require('../../../static/notificame/menu/sms.svg'),
  home: require('../../../static/notificame/menu/home.svg'),
  contacts: require('../../../static/notificame/menu/contacts.svg'),
  reports: require('../../../static/notificame/menu/reports.svg'),
  user: require('../../../static/notificame/menu/user.svg'),
  landings: require('../../../static/notificame/menu/landings.svg'),
  link: require('../../../static/notificame/menu/link.svg')
};

var logo = require('./logo_reach.svg');
var logoNoti = require('../../../static/notificame/logo-rojo.svg');
var logoTigo = require('../../../static/tigo/tigo-blanco.svg');
var logoQuickly = require('../../../static/quickly/quickly.svg');

var MenuItemParent = function (_React$Component) {
  (0, _inherits3.default)(MenuItemParent, _React$Component);

  function MenuItemParent() {
    var _ref;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, MenuItemParent);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref = MenuItemParent.__proto__ || (0, _getPrototypeOf2.default)(MenuItemParent)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      anchorEl: null
    }, _this.handleClick = function (event) {
      _this.setState({ anchorEl: event.currentTarget });
    }, _this.handleClose = function () {
      _this.setState({ anchorEl: null });
    }, _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(MenuItemParent, [{
    key: 'render',
    value: function render() {
      var _this2 = this;

      var children = _react2.default.Children.map(this.props.children, function (child) {
        return _react2.default.cloneElement(child, {
          handleClose: _this2.handleClose
        });
      });

      var anchorEl = this.state.anchorEl;

      var props = this.props;

      var buttonWidth = this.props.width || "110px";

      return _react2.default.createElement(
        _react2.default.Fragment,
        null,
        _react2.default.createElement(
          _Button2.default,
          {
            style: { color: '#FFF', textTransform: 'none', width: buttonWidth, fontSize: '12px' },
            'aria-owns': anchorEl ? 'simple-menu' : undefined,
            'aria-haspopup': 'true',
            onClick: this.handleClick
          },
          props.label
        ),
        _react2.default.createElement(
          _Menu2.default,
          {
            id: 'simple-menu',
            anchorEl: anchorEl,
            open: Boolean(anchorEl),
            onClose: this.handleClose,
            style: { marginTop: '20px', color: '#000' }
          },
          children
        )
      );
    }
  }]);
  return MenuItemParent;
}(_react2.default.Component);

var MenuItemLogout = (_dec = (0, _reduxConnect.asyncConnect)([{
  key: 'account',
  promise: function promise(_ref2) {
    var _ref2$store = _ref2.store,
        dispatch = _ref2$store.dispatch,
        getState = _ref2$store.getState;

    var state = getState();
    if (!state.account.data.reach) {
      return dispatch((0, _account.getAccount)());
    }
    return null;
  }
}, {
  key: "auth",
  promise: function promise(_ref3) {
    var _ref3$store = _ref3.store,
        dispatch = _ref3$store.dispatch,
        getState = _ref3$store.getState;

    if (!(0, _auth.isLoaded)(getState())) {
      return dispatch((0, _auth.load)());
    }
    return null;
  }
}]), _dec2 = (0, _reactRedux.connect)(function (state) {
  return {
    auth: state.auth,
    reach: state.account.data.reach
  };
}, { push: _reachHistory.push, logout: _auth.logout }), _dec(_class2 = _dec2(_class2 = (_class3 = (_temp2 = _class4 = function (_React$Component2) {
  (0, _inherits3.default)(MenuItemLogout, _React$Component2);

  function MenuItemLogout(props) {
    (0, _classCallCheck3.default)(this, MenuItemLogout);

    var _this3 = (0, _possibleConstructorReturn3.default)(this, (MenuItemLogout.__proto__ || (0, _getPrototypeOf2.default)(MenuItemLogout)).call(this, props));

    _this3.state = {
      charge_type: null
    };
    return _this3;
  }

  (0, _createClass3.default)(MenuItemLogout, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      try {
        if (this.props.reach.billing.charge_type) {
          this.setState({ charge_type: this.props.reach.billing.charge_type });
        }
      } catch (e) {
        console.log('could no get prepaid info', e);
      }
    }
  }, {
    key: 'handeLogout',
    value: function handeLogout() {
      this.props.push('/');
      this.props.logout().then(function () {
        window.location = '/';
      });
    }
  }, {
    key: 'prepaidInfo',
    value: function prepaidInfo() {
      return _react2.default.createElement(
        'div',
        { style: { paddingLeft: '10px', paddingTop: '5px' } },
        _react2.default.createElement(_Chip2.default, { style: { height: '25px', background: '#eaeaea',
            color: '#6d6d6d', fontSize: '10px' },
          label: (0, _translations2.default)('PREPAID ACCOUNT') })
      );
    }
  }, {
    key: 'render',
    value: function render() {
      return _react2.default.createElement(
        'div',
        null,
        _react2.default.createElement(
          _MenuItem2.default,
          { onClick: this.handeLogout, style: { fontSize: '12px' } },
          _react2.default.createElement(
            'div',
            { className: _Menu4.default.menuItemHelp },
            (0, _translations2.default)('LOGOUT')
          )
        ),
        process.env.REACT_APP_HELP_URL && _react2.default.createElement(
          _MenuItem2.default,
          {
            onClick: function onClick() {
              return window.open(process.env.REACT_APP_HELP_URL, '_blank');
            },
            style: { fontSize: '10px', height: '15px', padding: '5px 16px' }
          },
          _react2.default.createElement(
            'div',
            { className: _Menu4.default.menuItemHelp },
            (0, _translations2.default)('HELP')
          )
        ),
        this.state.charge_type == 'PREPAID' ? this.prepaidInfo() : null
      );
    }
  }]);
  return MenuItemLogout;
}(_react2.default.Component), _class4.propTypes = {
  push: _propTypes2.default.func.isRequired,
  logout: _propTypes2.default.func.isRequired
}, _temp2), (_applyDecoratedDescriptor(_class3.prototype, 'handeLogout', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class3.prototype, 'handeLogout'), _class3.prototype)), _class3)) || _class2) || _class2);


var MenuItemChild = function MenuItemChild(props) {
  return _react2.default.createElement(
    _reactRouter.Link,
    { to: props.to, className: _Menu4.default.menuItem },
    _react2.default.createElement(
      _MenuItem2.default,
      { onClick: props.handleClose },
      props.label
    )
  );
};

var MenuItem = function MenuItem(props) {
  return _react2.default.createElement(
    _MenuItem2.default,
    { style: { color: '#FFF' }, onClick: props.handleClose },
    _react2.default.createElement(
      _reactRouter.Link,
      {
        to: props.to,
        className: _Menu4.default.item,
        activeClassName: _Menu4.default.active,
        style: props.style
      },
      props.label
    )
  );
};

var stylesSubmenu = {
  subMenuItem: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: '12px !important',
    fontWeight: '400 !important'
  }
};

var SubMenuItem = function (_React$Component3) {
  (0, _inherits3.default)(SubMenuItem, _React$Component3);

  function SubMenuItem() {
    var _ref4;

    var _temp3, _this4, _ret2;

    (0, _classCallCheck3.default)(this, SubMenuItem);

    for (var _len2 = arguments.length, args = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      args[_key2] = arguments[_key2];
    }

    return _ret2 = (_temp3 = (_this4 = (0, _possibleConstructorReturn3.default)(this, (_ref4 = SubMenuItem.__proto__ || (0, _getPrototypeOf2.default)(SubMenuItem)).call.apply(_ref4, [this].concat(args))), _this4), _this4.state = {
      menuOpen: false,
      anchorElement: null
    }, _this4.setAnchorElement = function (node) {
      _this4.setState({ anchorElement: node });
    }, _this4.handleItemClick = function (event) {
      _this4.setAnchorElement(event.currentTarget);
      _this4.setState({ menuOpen: !_this4.state.menuOpen });
    }, _this4.handleSubMenuClose = function () {
      _this4.setState({ menuOpen: false });
    }, _temp3), (0, _possibleConstructorReturn3.default)(_this4, _ret2);
  }

  (0, _createClass3.default)(SubMenuItem, [{
    key: 'render',
    value: function render() {
      var _this5 = this;

      var label = this.props.label;

      var children = _react2.default.Children.map(this.props.children, function (child) {
        return _react2.default.cloneElement(child, {
          handleSubMenuClose: _this5.handleSubMenuClose
        });
      });

      return _react2.default.createElement(
        _react2.default.Fragment,
        null,
        _react2.default.createElement(
          _MenuItem2.default,
          {
            onClick: this.handleItemClick,
            style: { fontSize: '12px' } },
          label,
          _react2.default.createElement(md.arrowForward, { size: 20, style: { top: '14px', right: '0', position: 'absolute' } })
        ),
        _react2.default.createElement(
          _Menu2.default,
          {
            open: this.state.menuOpen,
            anchorEl: this.state.anchorElement,
            onClose: this.handleSubMenuClose,
            anchorOrigin: { vertical: "bottom", horizontal: "right" },
            transformOrigin: { vertical: "top", horizontal: "left" },
            className: _Menu4.default.testShit
          },
          children
        )
      );
    }
  }]);
  return SubMenuItem;
}(_react2.default.Component);

exports.default = (0, _withStyles2.default)(stylesSubmenu)(SubMenuItem);


var Account = function Account(_ref5) {
  var name = _ref5.name,
      email = _ref5.email,
      shortCode = _ref5.shortCode;
  return _react2.default.createElement(
    'div',
    { className: _Menu4.default.account },
    _react2.default.createElement(
      'div',
      { className: _Menu4.default.container },
      _react2.default.createElement(
        'div',
        { title: name, className: _Menu4.default.title },
        name
      ),
      _react2.default.createElement(
        'div',
        { className: _Menu4.default.email },
        email
      ),
      _react2.default.createElement(
        'div',
        { className: _Menu4.default.shortCode, title: (0, _translations2.default)('ACCOUNT PHONE') },
        shortCode
      )
    ),
    _react2.default.createElement('div', { className: _Menu4.default.userIconUser, dangerouslySetInnerHTML: { __html: icons['user'] } })
  );
};

var UserIcon = function UserIcon(_ref6) {
  var name = _ref6.name,
      email = _ref6.email,
      shortCode = _ref6.shortCode,
      accountSelect = _ref6.accountSelect;
  return _react2.default.createElement(
    MenuItemParent,
    { id: 'menu-account', width: '100%', label: _react2.default.createElement(Account, { name: name, email: email, shortCode: shortCode }) },
    accountSelect,
    _react2.default.createElement(MenuItemChild, { to: '/account', label: (0, _translations2.default)('ACCOUNT DETAIL') }),
    _react2.default.createElement(
      _Allow2.default,
      { permissions: ['account:configs:sso'], features: ['application_sso'] },
      _react2.default.createElement(
        'a',
        { className: _Menu4.default.menuItem, onClick: function onClick() {
            return window.location.replace('/v5/ssoConfig');
          } },
        _react2.default.createElement(
          _MenuItem2.default,
          null,
          'Configuración de SSO'
        )
      )
    ),
    _react2.default.createElement(
      _Allow2.default,
      { permissions: ['account:configs:api:view'] },
      _react2.default.createElement(MenuItemChild, { to: '/account/apidetail/', label: (0, _translations2.default)('API CONFIG') })
    ),
    _react2.default.createElement(
      _Allow2.default,
      { permissions: ['account:user:list'] },
      _react2.default.createElement(MenuItemChild, { to: '/users/', label: (0, _translations2.default)('USER CONFIG') })
    ),
    _config2.default.allow2FA ? _react2.default.createElement(
      'a',
      { className: _Menu4.default.menuItem, onClick: function onClick() {
          return window.location.replace('/v5/authenticationConfig');
        } },
      _react2.default.createElement(
        _MenuItem2.default,
        null,
        (0, _translations2.default)('AUTH CONFIG')
      )
    ) : _react2.default.createElement('a', null),
    _react2.default.createElement(
      _Allow2.default,
      { permissions: ['notification:admin'] },
      _react2.default.createElement(
        'a',
        { className: _Menu4.default.menuItem, onClick: function onClick() {
            return window.location.replace('/v5/notificationAdmin');
          } },
        _react2.default.createElement(
          _MenuItem2.default,
          null,
          (0, _translations2.default)('Notificaciones')
        )
      )
    ),
    _react2.default.createElement(MenuItemLogout, null)
  );
};

var Data = function Data(_ref7) {
  var data = _ref7.data;
  return _react2.default.createElement(
    'div',
    { className: _Menu4.default.availabe },
    _react2.default.createElement(
      _reactRouter.Link,
      { to: '/reports' },
      _react2.default.createElement(
        'div',
        null,
        ' ',
        _react2.default.createElement(
          'span',
          null,
          'Mensajes '
        ),
        data.sms.messages.remaining,
        '/',
        data.sms.messages.package
      ),
      (0, _featureEnabled.featureEnabled)('email') && data.email.balance && data.email.credit_base_limit ? _react2.default.createElement(
        'div',
        null,
        _react2.default.createElement(
          'span',
          null,
          'Email '
        ),
        data.email.balance,
        '/',
        data.email.credit_base_limit
      ) : _react2.default.createElement(_react2.default.Fragment, null)
    )
  );
};

var MenuElement = function MenuElement(params) {
  return _react2.default.createElement(
    _reactRouter.Link,
    { to: params.path },
    _react2.default.createElement(
      'div',
      { className: _Menu4.default.iconContainer },
      _react2.default.createElement('div', { className: _Menu4.default.icon, dangerouslySetInnerHTML: { __html: icons[params.icon] } }),
      _react2.default.createElement(
        'span',
        { className: _Menu4.default.iconText },
        params.title
      )
    )
  );
};

var checkAuthorization = function checkAuthorization(permissions, features, allPermissions, allFeatures, item) {
  if (permissions && !features) {
    return _react2.default.createElement(
      _Allow2.default,
      { permissions: permissions, allPermissions: allPermissions, allFeatures: allFeatures },
      item
    );
  } else if (!permissions && features) {
    return _react2.default.createElement(
      _Allow2.default,
      { features: features, allPermissions: allPermissions, allFeatures: allFeatures },
      item
    );
  } else if (permissions && features) {
    return _react2.default.createElement(
      _Allow2.default,
      { features: features, permissions: permissions, allPermissions: allPermissions, allFeatures: allFeatures },
      item
    );
  }
  return item;
};

var Child = function Child(data) {
  var _data$child = data.child,
      title = _data$child.title,
      path = _data$child.path,
      handleClick = _data$child.handleClick,
      separator = _data$child.separator;
  var _data$child2 = data.child,
      permissions = _data$child2.permissions,
      features = _data$child2.features,
      _data$child2$allPermi = _data$child2.allPermissions,
      allPermissions = _data$child2$allPermi === undefined ? true : _data$child2$allPermi,
      _data$child2$allFeatu = _data$child2.allFeatures,
      allFeatures = _data$child2$allFeatu === undefined ? true : _data$child2$allFeatu;

  var child = _react2.default.createElement(
    'li',
    { className: separator && _Menu4.default.menuSeparator },
    _react2.default.createElement(
      _reactRouter.Link,
      { to: path },
      title
    )
  );
  if (handleClick) {
    child = _react2.default.createElement(
      'li',
      null,
      _react2.default.createElement(
        _reactRouter.Link,
        { to: '', onClick: function onClick() {
            return window.location.replace(path);
          } },
        title
      )
    );
  }
  return checkAuthorization(permissions, features, allPermissions, allFeatures, child);
};

var SubItem = function SubItem(data) {
  var _data$items = data.items,
      title = _data$items.title,
      path = _data$items.path,
      child = _data$items.child;
  var _data$items2 = data.items,
      permissions = _data$items2.permissions,
      features = _data$items2.features,
      _data$items2$allPermi = _data$items2.allPermissions,
      allPermissions = _data$items2$allPermi === undefined ? true : _data$items2$allPermi,
      _data$items2$allFeatu = _data$items2.allFeatures,
      allFeatures = _data$items2$allFeatu === undefined ? true : _data$items2$allFeatu;


  var subItem = _react2.default.createElement(
    'ul',
    { className: 'ul-reset' },
    _react2.default.createElement(
      _reactRouter.Link,
      { className: _Menu4.default.ItemTitle, to: path },
      title
    ),
    child.map(function (item, index) {
      return _react2.default.createElement(Child, { key: index, child: item });
    })
  );
  return checkAuthorization(permissions, features, allPermissions, allFeatures, subItem);
};

var Item = function Item(data) {
  var _data$item = data.item,
      title = _data$item.title,
      subItems = _data$item.subItems,
      icon = _data$item.icon,
      path = _data$item.path;
  var _data$item2 = data.item,
      permissions = _data$item2.permissions,
      features = _data$item2.features,
      _data$item2$allPermis = _data$item2.allPermissions,
      allPermissions = _data$item2$allPermis === undefined ? true : _data$item2$allPermis,
      _data$item2$allFeatur = _data$item2.allFeatures,
      allFeatures = _data$item2$allFeatur === undefined ? true : _data$item2$allFeatur;


  var Items = subItems.length > 0 ? subItems.map(function (item, index) {
    return _react2.default.createElement(SubItem, { key: index, items: item });
  }) : '';

  var item = _react2.default.createElement(
    'li',
    { className: _Menu4.default.droppable },
    _react2.default.createElement(MenuElement, { icon: icon, title: title, path: path ? path : "" }),
    _react2.default.createElement(
      'div',
      { className: _Menu4.default.megaMenu },
      _react2.default.createElement(
        'div',
        { className: _Menu4.default.containerCf },
        Items
      )
    )
  );
  return checkAuthorization(permissions, features, allPermissions, allFeatures, item);
};

var ItemsMenu = function ItemsMenu() {
  var singleMessageFeatures = (0, _featureEnabled.getAmountFeaturesEnabled)(['sms', 'email', 'push', 'whatsapp']);
  var fileMessageFeatures = (0, _featureEnabled.getAmountFeaturesEnabled)(['sms', 'email', 'push']);

  var menu = [{ title: "Inicio", icon: "home", path: '/dashboard', subItems: [] }];

  if (singleMessageFeatures > 0) menu.push({
    title: (0, _translations2.default)('MY MESSAGES'), icon: 'sms', subItems: [{
      features: ['sms'], allFeatures: false, permissions: ['channels:sms:send:single'], allPermissions: false, title: "SMS", path: '', child: [{ features: ['sms'], permissions: ['channels:sms:send:single'], title: "Nuevo Mensaje", path: "/messages/add" }, { features: ['sms'], permissions: ['channels:sms:send:file'], title: "Nuevo Mensaje por Archivo", path: "/messages/addFile" }, { features: ['sms'], permissions: ['channels:sms:send:single'], title: "", separator: true, path: "" }, { permissions: ['transactions:list:incoming', 'transactions:list:outgoing'], allPermissions: false, title: "Bitácora de mensajes", path: '/v5/messagelogs', handleClick: true, type: 'v5' }, { permissions: ['transactions:files:list'], title: "Listado de archivos", path: '/v5/messages/files', handleClick: true, type: 'v5' }, { permissions: ['transactions:scheduled:list'], title: "Mensajes Programados", path: '/messages/calendar' }, { features: ['dashboardotp'], permissions: ['transactions:list:incoming', 'transactions:list:outgoing'], allPermissions: false, title: "Monitoreo de mensajes", path: '/v5/messages/dashboard', handleClick: true, type: 'v5' }, { permissions: ['transactions:list:incoming'], title: (0, _translations2.default)('MESSAGES_RECEIVED'), path: '/messages/received' }, { permissions: ['transactions:list:outgoing'], title: (0, _translations2.default)('MESSAGES_SENT'), path: '/messages/' }]
    }, {
      features: ['rcs'], allFeatures: false, permissions: ['channels:rcs:send:single'], allPermissions: false, title: "RCS", path: '', child: [{ features: ['rcs'], permissions: ['channels:rcs:send:single'], title: "Nuevo Mensaje", path: "/v5/rcs/add?submenuItem=add", handleClick: true, type: 'v5' }, { features: ['rcs'], permissions: ['channels:rcs:send:file'], title: "Nuevo Mensaje por Archivo", path: "/v5/rcs/file?submenuItem=file", handleClick: true, type: 'v5' }, { features: ['rcs'], permissions: ['channels:rcs:send:single'], title: "", separator: true, path: "" }, { permissions: ['transactions:list:incoming', 'transactions:list:outgoing'], allPermissions: false, title: "Bitácora de mensajes", path: '/v5/rcs/messagelogs?submenuItem=messagelogs', handleClick: true, type: 'v5' }, { permissions: ['transactions:files:list'], title: "Listado de archivos", path: '/v5/rcs/messages/files?submenuItem=messages/files', handleClick: true, type: 'v5' }]
    }, {
      features: ['template-sms', 'template-email'], allFeatures: false, permissions: ['channels:sms:template:list', 'channels:email:template:create'], allPermissions: false, title: "Listado de plantillas", path: '', child: [{ permissions: ['channels:sms:template:list'], title: "Plantillas SMS", path: "/messages/templates" }, { permissions: ['channels:email:template:create'], title: "Plantillas Email", path: "/v5/email/templates", handleClick: true }]
    }, {
      features: ['promotions'], permissions: ['promotions:list'], title: "Administrar promociones", path: '/promotions', child: []
    }]
  });

  menu.push({
    permissions: ['contacts:view'], title: (0, _translations2.default)('MY CONTACTS'), icon: 'contacts', subItems: [{
      permissions: ['contacts:list'], title: (0, _translations2.default)('MANAGE_CONTACTS'), path: '/contacts', child: []
    }, {
      permissions: ['contacts:create:individual'], title: "Agregar contactos", path: '/contacts/add', child: []
    }, {
      permissions: ['contacts:create:file'], title: "Agregar por archivo", path: '/contacts/upload', child: []
    }, {
      permissions: ['contacts:list'], title: "Listado de archivos", path: '/contacts/files', child: []
    },
    // {
    //   permissions: ['tags:list'], title: "Listado de etiquetas", path: '/contacts/tags', child: []
    // },
    {
      permissions: ['tags:list'], allPermissions: false, title: "Etiquetas", path: '', child: [{ permissions: ['tags:list'], title: "Listado de etiquetas", path: "/v5/tags", handleClick: true, type: 'v5' }]
    }]
  });

  if ((0, _featureEnabled.featureEnabled)('studies') || (0, _featureEnabled.featureEnabled)('shortlink') || (0, _featureEnabled.featureEnabled)('campaigns')) menu.push({
    title: (0, _translations2.default)('MY STUDIES'), icon: 'landings', subItems: [{
      features: ['studies'], permissions: ['study:list'], title: "Administrar flujos", path: '/studies', child: [{ permissions: ['study:create:private'], title: "Nuevo flujo por invitación", path: '/studies/study' }, { permissions: ['study:create:public'], title: "Nuevo flujo público", path: '/studies/open' }, { permissions: ['study:create:api'], title: "Nuevo flujo por api", path: '/studies/api' }]
    }, {
      features: ['campaigns'], permissions: ['campaign:list'], title: "Administrar campañas", path: '/campaigns', child: [{ permissions: ['campaign:create'], title: "Nueva campaña", path: '/campaigns/campaign' }]
    }, {
      features: ['shortlink'], permissions: ['short:link:list'], title: "Administrar links", path: '/short_link', child: [{ permissions: ['short:link:report'], title: "Monitoreo de links", path: '/v5/shortlinks/dashboard', handleClick: true, type: 'v5' }]
    }, {
      features: ['landingpage_v5'], allFeatures: false, permissions: ['landingpage:v5:list', 'landingpage:v5:create'], allPermissions: false, title: "Landing Pages", path: '', child: [{ permissions: ['landingpage:v5:list'], title: "Landing Pages", path: "/v5/landing-pages", handleClick: true }, { permissions: ['landingpage:v5:create'], title: "Nueva landing page", path: "/v5/landing-pages/landing", handleClick: true }, { permissions: ['landingpage:v5:report'], title: "Monitoreo de landing pages", path: "/v5/landing-pages/dashboard", handleClick: true }]
    }, {
      features: ['multimedia_files'], allFeatures: true, permissions: ['multimediafiles:v5:list', 'multimediafiles:v5:create', 'multimediafiles:v5:report'], allPermissions: false, title: (0, _translations2.default)("MULTIMEDIA_FILES"), path: '', child: [{ permissions: ['multimediafiles:v5:list'], title: (0, _translations2.default)("MULTIMEDIA_FILES"), path: "/v5/multimedia-files", handleClick: true }, { permissions: ['multimediafiles:v5:create'], title: (0, _translations2.default)("NEW_MULTIMEDIA_FILE"), path: "/v5/multimedia-files/file", handleClick: true }, { permissions: ['multimediafiles:v5:report'], title: (0, _translations2.default)("MULTIMEDIA_FILES_MONITOR"), path: '/v5/multimedia-files/dashboard', handleClick: true }]
    }]
  });

  menu.push({
    permissions: ['account:reports:balance:view'], title: (0, _translations2.default)('MY REPORTS'), icon: 'reports', subItems: [{
      title: "Reportes de Hostigamiento", path: '/reports/dailyHarassment', child: []
    }, {
      features: ['reportes_csms'], permissions: ['account:reports:balance:view'], allPermissions: false, title: "Facturación", path: '', child: [{ permissions: [], title: "Reporte de Historial de Balance", path: '/v5/reports/balanceHistory', handleClick: true, type: 'v5' }, { permissions: [], title: "Historial de Consumo por Mes Calendario", path: '/v5/reports/MonthlyConsumptionHistory', handleClick: true, type: 'v5' }, { permissions: [], title: "Historial de Consumo por Día", path: '/v5/reports/DailyConsumptionHistory', handleClick: true, type: 'v5' }, { permissions: [], title: "Historial de Consumo de usuario por Mes", path: '/v5/reports/monthlyConsumptionByUser', handleClick: true, type: 'v5' }, { permissions: [], title: "Historial de Consumo de usuario por Día", path: '/v5/reports/DailyConsumptionByUser', handleClick: true, type: 'v5' }]
    }, {
      permissions: ['account:reports:balance:view'], allPermissions: false, title: "Reporte de Tráfico Diario", path: '', child: [{ permissions: [], title: "Historial de Tráfico por Día", path: '/v5/reports/DailyTraffic', handleClick: true, type: 'v5' }, { permissions: [], title: "Monitoreo de Errores", path: '/v5/reports/ErrorMonitoring', handleClick: true, type: 'v5' }, { permissions: [], title: "Reporte general", path: '/v5/reports/Generals', handleClick: true, type: 'v5' }]
    }]
  });
  return _react2.default.createElement(
    'ul',
    { className: _Menu4.default.subItemContainer },
    menu.map(function (item, index) {
      if (item) return _react2.default.createElement(Item, { key: index, item: item });
    }),
    _react2.default.createElement(
      _Allow2.default,
      { features: ['conversation'] },
      _react2.default.createElement(
        'li',
        { className: _Menu4.default.droppable },
        _react2.default.createElement(
          'a',
          { onClick: function onClick() {
              return window.location.replace('/v5/conversations');
            } },
          _react2.default.createElement(
            'div',
            { className: _Menu4.default.iconContainer },
            _react2.default.createElement('div', { className: _Menu4.default.icon, dangerouslySetInnerHTML: { __html: icons['conversations'] } }),
            _react2.default.createElement(
              'span',
              { className: _Menu4.default.iconText },
              'Conversaciones'
            )
          )
        )
      )
    )
  );
};

var ResellerItemsMenu = function ResellerItemsMenu() {

  var menu = [{ permissions: ['reseller:subaccount:list'], title: (0, _translations2.default)('SUBACCOUNTS'), icon: "home", path: '/reseller', subItems: [] }, {
    permissions: ['reseller:files:report'], title: "Mensajes", icon: 'sms', path: '', subItems: [{
      permissions: ['reseller:files:report'], title: "Administración de Archivos", path: '', child: [{ permissions: ['reseller:files:report'], title: "Monitoreo de archivos", path: '/v5/messages/files/dashboard', handleClick: true, type: 'v5' }, { permissions: ['transactions:files:list'], title: "Listado de archivos", path: '/v5/messages/files', handleClick: true, type: 'v5' }]
    }]
  }, { permissions: ['reseller:policies:antispam:list'], title: (0, _translations2.default)('POLICIES'), icon: "landings", path: '/reseller/policies/', subItems: [] }, {
    permissions: ['reseller:subaccount:reports:balance:view'], title: "Reportes", icon: "reports", path: '', subItems: [{
      title: (0, _translations2.default)('BALANCE'), path: '/reseller/balance/', child: []
    }, {
      title: (0, _translations2.default)('HARASSMENT'), path: '/reseller/dailyHarassment/', child: []
    }, {
      features: ['reportes_csms'],
      permissions: ['account:reports:balance:view'],
      allPermissions: false,
      title: 'Facturación',
      path: '',
      child: [{ permissions: [], title: (0, _translations2.default)('BALANCE HISTORY'), path: '/v5/reports/balanceHistory', handleClick: true, type: 'v5' }, { permissions: [], title: "Historial de Consumo por Mes Calendario", path: '/v5/reports/MonthlyConsumptionHistory', handleClick: true, type: 'v5' }, { permissions: [], title: "Historial de Consumo por Día", path: '/v5/reports/DailyConsumptionHistory', handleClick: true, type: 'v5' }, { permissions: [], title: "Historial de Consumo de usuario por Mes", path: '/v5/reports/monthlyConsumptionByUser', handleClick: true, type: 'v5' }, { permissions: [], title: "Historial de Consumo de usuario por Día", path: '/v5/reports/DailyConsumptionByUser', handleClick: true, type: 'v5' }]
    }, {
      permissions: ['account:reports:balance:view'],
      allPermissions: false,
      title: 'Reporte de Tráfico Diario',
      path: '',
      child: [{ permissions: [], title: "Historial de Tráfico por Día", path: '/v5/reports/DailyTraffic', handleClick: true, type: 'v5' }, { permissions: [], title: "Monitoreo de Errores", path: '/v5/reports/ErrorMonitoring', handleClick: true, type: 'v5' }]
    }]
  }, { permissions: ['reseller:subaccount:user:list'], title: (0, _translations2.default)('USERS'), icon: "contacts", path: '/reseller/users/', subItems: [] }, { permissions: [], title: (0, _translations2.default)('MY STUDIES'), icon: "landings", path: '', subItems: [{
      permissions: ['short:link:report'], title: "Administración de links", path: '', child: [{ permissions: ['short:link:report'], title: "Monitoreo de links", path: '/v5/shortlinks/dashboard', handleClick: true, type: 'v5' }]
    }, {
      permissions: ['landingpage:v5:report'], title: "Administración de landing pages", path: '', child: [{ permissions: ['landingpage:v5:report'], title: "Monitoreo de landing pages", path: "/v5/landing-pages/dashboard", handleClick: true, type: 'v5' }]
    }, {
      permissions: ['multimediafiles:v5:report'], title: "Administración de archivos multimedia", path: '', child: [{ permissions: ['multimediafiles:v5:report'], title: (0, _translations2.default)("MULTIMEDIA_FILES_MONITOR"), path: "/v5/multimedia-files/dashboard", handleClick: true, type: 'v5' }]
    }] }];

  return _react2.default.createElement(
    'ul',
    { className: _Menu4.default.subItemContainer },
    menu.map(function (item, index) {
      if (item) return _react2.default.createElement(Item, { key: index, item: item });
    })
  );
};
var MenuContainer = exports.MenuContainer = (_dec3 = (0, _reduxConnect.asyncConnect)([{
  key: "auth",
  promise: function promise(_ref8) {
    var _ref8$store = _ref8.store,
        dispatch = _ref8$store.dispatch,
        getState = _ref8$store.getState;

    if (!(0, _auth.isLoaded)(getState())) {
      return dispatch((0, _auth.load)());
    }
    return null;
  }
}]), _dec4 = (0, _reactRedux.connect)(function (state) {
  return {
    auth: state.auth,
    account: state.account.data,
    currentAccount: state.auth.current_account
  };
}, { getBalance: _reports.getBalance, v5Token: _auth.v5Token, changeAccount: _auth.changeAccount }), _dec3(_class6 = _dec4(_class6 = (_class7 = function (_React$Component4) {
  (0, _inherits3.default)(MenuContainer, _React$Component4);

  function MenuContainer() {
    var _ref9;

    var _temp4, _this6, _ret3;

    (0, _classCallCheck3.default)(this, MenuContainer);

    for (var _len3 = arguments.length, args = Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
      args[_key3] = arguments[_key3];
    }

    return _ret3 = (_temp4 = (_this6 = (0, _possibleConstructorReturn3.default)(this, (_ref9 = MenuContainer.__proto__ || (0, _getPrototypeOf2.default)(MenuContainer)).call.apply(_ref9, [this].concat(args))), _this6), _this6.state = {
      balance: false,
      data: {},
      user: {},
      account: {},
      status: {},
      shortCode: null
    }, _this6.f = function (n) {
      return (0, _numbro2.default)(n).format(_this6.format);
    }, _this6.v5Redirect = function (url) {
      var account_uid = void 0;
      _this6.props.v5Token().then(function (result) {
        window.location.href = window.location.origin + ('/v5/login/' + result.token + '?url=' + url + '&account_uid=' + account_uid);
      });
      return false;
    }, _this6.updateReport = function () {
      var accountShortCode = void 0;
      _this6.props.getBalance().then(function (balance) {
        var data = {};
        if (balance) {
          if (balance.channel) {
            accountShortCode = balance.channel.identifier;
          }
          if (balance.current_package) {
            var _balance$current_pack = balance.current_package,
                credit_base_limit = _balance$current_pack.credit_base_limit,
                credit_limit = _balance$current_pack.credit_limit,
                credit_extra_limit = _balance$current_pack.credit_extra_limit;

            var cycle = balance.cycle || '';
            var packageMessages = credit_base_limit;
            var usedMessages = credit_limit - balance.current_package.balance;
            var availableMessages = usedMessages < credit_base_limit ? balance.current_package.balance - credit_extra_limit : 0;
            var availableExtras = usedMessages > credit_base_limit ? credit_limit - usedMessages : credit_extra_limit;
            var base_percent = credit_base_limit ? Math.floor(availableMessages / credit_base_limit * 100).toFixed(2) : 0;
            var extras_percent = credit_extra_limit ? Math.floor(availableExtras / credit_extra_limit * 100).toFixed(2) : 0;

            var sms = {
              messages: {
                percent: base_percent,
                remaining: _this6.f(availableMessages),
                package: _this6.f(packageMessages)
              },
              extras: {
                percent: extras_percent,
                consumed: _this6.f(credit_extra_limit - availableExtras),
                remaining: _this6.f(availableExtras),
                limit: _this6.f(credit_extra_limit)
              },
              cycle: cycle
            };
            var email = balance.email;
            email.balance = email.balance ? _this6.f(email.balance) : null;
            email.credit_base_limit = email.credit_limit ? _this6.f(email.credit_limit) : null;
            data.sms = sms;
            data.email = email;
          }
        }
        _this6.setState((0, _extends3.default)({}, _this6.state, {
          balance: balance,
          data: data,
          user: _this6.props.auth.data.user,
          account: _this6.props.auth.data.account_list.find(function (a) {
            return a.is_active;
          }),
          status: balance,
          shortCode: accountShortCode
        }));
      }).catch(function (e) {
        console.error('Could not get balance data', e);
        _this6.setState((0, _extends3.default)({}, _this6.state, {
          balance: false,
          data: {},
          user: _this6.props.auth.data.user,
          account: _this6.props.auth.data.account_list.find(function (a) {
            return a.is_active;
          }),
          status: null,
          shortCode: null
        }));
      });
    }, _temp4), (0, _possibleConstructorReturn3.default)(_this6, _ret3);
  }

  (0, _createClass3.default)(MenuContainer, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      if (this.props.auth.data) {
        this.updateReport();
      }
      (0, _redirectToUrlBeforeReload.redirectToUrlBeforeReload)(this.props.currentAccount);
    }
  }, {
    key: 'navigateAfterChange',
    value: function navigateAfterChange(accountType) {
      if (accountType === 'Reseller') {
        sessionStorage.removeItem("urlBeforeReload");
        window.location = _config2.default.basename + '/reseller';
        this.props.push('/reseller');
      } else {
        window.location = _config2.default.basename + '/dashboard';
      }
    }
  }, {
    key: 'changeAccount',
    value: function changeAccount(element) {
      var _this7 = this;

      var accountType = this.props.currentAccount.account_type;
      sessionStorage.setItem("urlBeforeReload", window.location.href);
      this.props.changeAccount(element.value).then(function () {
        _this7.navigateAfterChange(accountType);
      }).catch(function () {
        _this7.navigateAfterChange(accountType);
      });
    }
  }, {
    key: 'render',
    value: function render() {
      var _state = this.state,
          data = _state.data,
          account = _state.account,
          user = _state.user,
          shortCode = _state.shortCode;

      if (account.account_list === undefined && user.username === undefined) {
        return _react2.default.createElement('div', null);
      }
      var singleMessageFeatures = (0, _featureEnabled.getAmountFeaturesEnabled)(['sms', 'email', 'push', 'whatsapp']);
      var fileMessageFeatures = (0, _featureEnabled.getAmountFeaturesEnabled)(['sms', 'email', 'push']);

      // let classesTheme = (themeEnabled() == "claro"?styles.notiTheme:styles.reachTheme); //IF THEME
      var classesTheme = (0, _themeEnabled.getThemeEnabledStyle)(_Menu4.default);
      var accountSelect = _config2.default.switchAccountMenu && this.props.auth.data.account_list.length > 1 ? _react2.default.createElement(
        'div',
        { className: _Menu4.default.accountselector },
        _react2.default.createElement(_reactSelect2.default, {
          id: 'current-account-selector',
          className: _Menu4.default.selector,
          value: this.props.currentAccount.account_uid,
          onChange: this.changeAccount,
          clearable: false,
          options: this.props.auth.data.account_list.map(function (a) {
            return { value: a.account_uid, label: a.name + ' ' + (a.account_type === 'Reseller' ? a.account_type : '') };
          })
        }),
        _react2.default.createElement('hr', null)
      ) : _react2.default.createElement('div', null);

      if (account.account_type === 'Reseller') {
        return _react2.default.createElement(
          'div',
          { className: classesTheme },
          _react2.default.createElement(
            'div',
            { className: _Menu4.default.menuContainer },
            _react2.default.createElement(
              'div',
              { className: _Menu4.default.format },
              _react2.default.createElement('div', { className: _Menu4.default.logo, dangerouslySetInnerHTML: { __html: logo } }),
              _react2.default.createElement('div', { className: _Menu4.default.logoTigo, dangerouslySetInnerHTML: { __html: logoTigo } }),
              _react2.default.createElement('div', { className: _Menu4.default.logoNoti, dangerouslySetInnerHTML: { __html: logoNoti } }),
              _react2.default.createElement('div', { className: _Menu4.default.logoQuickly, dangerouslySetInnerHTML: { __html: logoQuickly } }),
              _react2.default.createElement(
                'div',
                { className: _Menu4.default.menu },
                _react2.default.createElement(ResellerItemsMenu, null)
              ),
              _react2.default.createElement(
                'div',
                { className: _Menu4.default.detailUser },
                data.sms ? _react2.default.createElement(Data, { data: data }) : _react2.default.createElement('div', null),
                _react2.default.createElement(UserIcon, { name: account.name, email: user.username, shortCode: shortCode, accountSelect: accountSelect })
              )
            )
          )
        );
      }
      return _react2.default.createElement(
        'div',
        { className: classesTheme },
        _react2.default.createElement(
          'div',
          { className: _Menu4.default.menuContainer },
          _react2.default.createElement(
            'div',
            { className: _Menu4.default.format },
            _react2.default.createElement('div', { className: _Menu4.default.logo, dangerouslySetInnerHTML: { __html: logo } }),
            _react2.default.createElement('div', { className: _Menu4.default.logoNoti, dangerouslySetInnerHTML: { __html: logoNoti } }),
            _react2.default.createElement('div', { className: _Menu4.default.logoTigo, dangerouslySetInnerHTML: { __html: logoTigo } }),
            _react2.default.createElement('div', { className: _Menu4.default.logoQuickly, dangerouslySetInnerHTML: { __html: logoQuickly } }),
            _react2.default.createElement(
              'div',
              null,
              _react2.default.createElement(
                'nav',
                { className: _Menu4.default.menu },
                _react2.default.createElement(ItemsMenu, null)
              )
            ),
            _react2.default.createElement(
              'div',
              { className: _Menu4.default.detailUser },
              _react2.default.createElement(
                'div',
                { className: _Menu4.default.messagesInfo },
                data.sms ? _react2.default.createElement(Data, { data: data }) : _react2.default.createElement('div', null)
              ),
              _react2.default.createElement(
                'div',
                { className: _Menu4.default.userIconClass },
                _react2.default.createElement(UserIcon, { name: account.name, email: user.username, shortCode: shortCode, accountSelect: accountSelect })
              )
            )
          )
        )
      );
    }
  }]);
  return MenuContainer;
}(_react2.default.Component), (_applyDecoratedDescriptor(_class7.prototype, 'changeAccount', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class7.prototype, 'changeAccount'), _class7.prototype)), _class7)) || _class6) || _class6);
var FooterContainer = exports.FooterContainer = function FooterContainer(props) {
  return _react2.default.createElement(
    'div',
    { className: _Menu4.default.footerContainer },
    _react2.default.createElement(
      'div',
      { className: _Menu4.default.footer },
      _react2.default.createElement(MenuItem, { label: (0, _translations2.default)('HOME') }),
      _react2.default.createElement(
        MenuItemParent,
        { id: 'menu-messages', label: (0, _translations2.default)('MY MESSAGES') },
        _react2.default.createElement(MenuItemChild, { to: '/messages/add', label: 'Nuevo mensaje' }),
        _react2.default.createElement(
          _Allow2.default,
          { permissions: ['transactions:list:icoming'] },
          _react2.default.createElement(MenuItemChild, { to: '/messages/received', label: (0, _translations2.default)('MESSAGES_RECEIVED') })
        ),
        _react2.default.createElement(MenuItemChild, { to: '/messages/', label: (0, _translations2.default)("MESSAGES_SENT") }),
        _react2.default.createElement(MenuItemChild, { to: '/messages/calendar', label: 'Mensajes programados' }),
        _react2.default.createElement(MenuItemChild, { to: '/messages/files', label: 'Mensajes a archivos' })
      ),
      _react2.default.createElement(
        MenuItemParent,
        { id: 'menu-contacts', label: (0, _translations2.default)('MY CONTACTS') },
        _react2.default.createElement(MenuItemChild, { to: '/contacts/add', label: 'Agregar contactos' }),
        _react2.default.createElement(MenuItemChild, { to: '/contacts/upload', label: 'Agregar por archivo' }),
        _react2.default.createElement(MenuItemChild, { to: '/contacts/', label: (0, _translations2.default)('MANAGE_CONTACTS') }),
        _react2.default.createElement(MenuItemChild, { to: '/contacts/tags', label: 'Administrar grupos' })
      ),
      _react2.default.createElement(MenuItem, { label: (0, _translations2.default)('MY REPORTS') }),
      _react2.default.createElement(
        MenuItemParent,
        { label: 'Ayuda' },
        _react2.default.createElement(MenuItemChild, { to: '/contacts/', label: 'Manuales' }),
        _react2.default.createElement(MenuItemChild, { to: '/contacts/', label: 'Tel\xE9fonos de contacto' }),
        _react2.default.createElement(MenuItemChild, { to: '/contacts/', label: 'Atenci\xF3n de reclamos' })
      )
    )
  );
};

var AdminMenuContainer = exports.AdminMenuContainer = function AdminMenuContainer(props) {
  return _react2.default.createElement(
    'div',
    { className: _Menu4.default.menuReseller },
    _react2.default.createElement(
      'div',
      { className: _Menu4.default.list },
      props.children
    )
  );
};

var StaffMenuContainer = exports.StaffMenuContainer = function StaffMenuContainer(props) {
  return _react2.default.createElement(
    'div',
    { className: _Menu4.default.menu, style: (0, _extends3.default)({}, props.style) },
    _react2.default.createElement(MenuItem, { to: '/staff/messages/', Icon: md.mail, label: (0, _translations2.default)('MY MESSAGES') })
  );
};