'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _class, _temp;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactRouter = require('react-router');

var _components = require('components');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var md = {
  arrowBack: require('react-icons/lib/md/arrow-back')
};

var MobileMenu = (_temp = _class = function (_React$Component) {
  (0, _inherits3.default)(MobileMenu, _React$Component);

  function MobileMenu() {
    (0, _classCallCheck3.default)(this, MobileMenu);
    return (0, _possibleConstructorReturn3.default)(this, (MobileMenu.__proto__ || (0, _getPrototypeOf2.default)(MobileMenu)).apply(this, arguments));
  }

  (0, _createClass3.default)(MobileMenu, [{
    key: 'render',
    value: function render() {
      var styles = require('./MobileMenu.scss');

      var query = this.props.location.query;


      return _react2.default.createElement(
        'div',
        { className: styles.menu },
        _react2.default.createElement(
          'nav',
          { className: styles.nav },
          _react2.default.createElement(
            _reactRouter.Link,
            { className: styles.arrow, to: query.from },
            _react2.default.createElement(md.arrowBack, { size: 30 })
          )
        ),
        _react2.default.createElement(_components.Menu, { forceActive: query.from })
      );
    }
  }]);
  return MobileMenu;
}(_react2.default.Component), _class.propTypes = {
  location: _propTypes2.default.object
}, _class.defaultProps = {
  location: { query: {} }
}, _temp);
exports.default = MobileMenu;
module.exports = exports['default'];