'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = undefined;

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _ckeditor4React = require('ckeditor4-react');

var _ckeditor4React2 = _interopRequireDefault(_ckeditor4React);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

_ckeditor4React2.default.editorUrl = '/static/ckeditor/ckeditor.js';

var CustomCKEditor = function (_React$Component) {
    (0, _inherits3.default)(CustomCKEditor, _React$Component);

    function CustomCKEditor(props) {
        (0, _classCallCheck3.default)(this, CustomCKEditor);

        var _this = (0, _possibleConstructorReturn3.default)(this, (CustomCKEditor.__proto__ || (0, _getPrototypeOf2.default)(CustomCKEditor)).call(this, props));

        _this.headerHandler = function (event) {
            _this.props.parentCallBack(event.editor.getData());
        };

        _this.bodyHandler = function (event) {
            _this.props.parentCallBack(event.editor.getData());
        };

        _this.footerHandler = function (event) {
            _this.props.parentCallBack(event.editor.getData());
        };

        _this.renderTitle = function () {
            return _react2.default.createElement(_ckeditor4React2.default, { style: { 'font-size': '12px' },
                onChange: _this.headerHandler,
                data: _this.props.data,
                type: 'inline',
                config: {
                    title: 'Encabezado',
                    extraPlugins: ['sourcedialog', 'confighelper', 'placeholder', 'placeholder_select', 'richcombo'],
                    placeholder_select: {
                        placeholders: _this.props.variables },
                    placeholder: 'Haz click aquí para escribir el titulo del correo',
                    toolbar: [['Styles', 'Format', 'Font', 'FontSize'], '/', ['Bold', 'Italic', 'Underline', '-', 'TextColor', 'BGColor', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'SpecialChar', '-', 'Link', 'Unlink', '-', 'NewPage'], '/', ['Cut', 'Copy', 'Paste', 'PasteText', '-', 'Undo', 'Redo', '-', 'SelectAll', '-', 'Uploadimage', 'Sourcedialog', 'placeholder_select']]
                }
            });
        };

        _this.renderBody = function () {
            return _react2.default.createElement(_ckeditor4React2.default, { style: { bottom: '2px', height: '95%', 'font-size': '12px' },
                onChange: _this.bodyHandler,
                data: _this.props.data,
                type: 'inline',
                config: {
                    title: 'Cuerpo',
                    extraPlugins: ['sourcedialog', 'confighelper', 'placeholder', 'placeholder_select', 'richcombo', 'simplebutton', 'dialogadvtab', 'tableresize', 'inserthtmlfile'],
                    image_previewText: 'Preview de la imagen',
                    filebrowserImageUploadUrl: '/upload',
                    removeDialogTabs: 'image:advanced',
                    placeholder_select: {
                        placeholders: _this.props.variables },
                    placeholder: 'Haz click aquí para escribir el cuerpo del correo',
                    toolbar: [['Styles', 'Format', 'Font', 'FontSize'], '/', ['Bold', 'Italic', 'Underline', '-', 'TextColor', 'BGColor', 'RemoveFormat', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'SpecialChar', '-', 'Undo', 'Redo'], '/', ['Cut', 'Copy', 'Paste', 'PasteText', '-', 'Link', 'Unlink', '-', 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'HorizontalRule', 'Table', '-', 'Templates', 'NewPage'], '/', ['SelectAll', 'ShowBlocks', 'Image', '-', 'Sourcedialog', '-', 'simplebutton', '-', 'placeholder_select']]
                }
            });
        };

        _this.renderFooter = function () {
            return _react2.default.createElement(_ckeditor4React2.default, { style: { height: '75%', 'font-size': '12px' },
                onChange: _this.footerHandler,
                data: _this.props.data,
                type: 'inline',
                config: {
                    title: 'Pie',
                    extraPlugins: ['sourcedialog', 'confighelper', 'placeholder', 'placeholder_select', 'richcombo', 'dialogadvtab', 'tableresize'],
                    image_previewText: 'Preview de la imagen',
                    placeholder_select: {
                        placeholders: _this.props.variables },
                    filebrowserImageUploadUrl: '/upload',
                    placeholder: 'Haz click aquí para escribir el cuerpo del correo',
                    removeDialogTabs: 'image:advanced',
                    toolbar: [['Styles', 'Format', 'Font', 'FontSize'], '/', ['Bold', 'Italic', 'Underline', '-', 'TextColor', 'BGColor', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'SpecialChar', '-', 'Link', 'Unlink', '-', 'NewPage'], '/', ['Cut', 'Copy', 'Paste', 'PasteText', '-', 'Undo', 'Redo', '-', 'SelectAll', '-', 'Table', '-', 'Image', '-', 'Sourcedialog', '-', 'placeholder_select', 'Quicktable']]
                }
            });
        };

        return _this;
    }

    (0, _createClass3.default)(CustomCKEditor, [{
        key: 'render',
        value: function render() {
            if (this.props.type == 'title') {
                return this.renderTitle();
            } else if (this.props.type == 'body') {
                return this.renderBody();
            } else if (this.props.type == 'footer') {
                return this.renderFooter();
            }
        }
    }]);
    return CustomCKEditor;
}(_react2.default.Component);

exports.default = CustomCKEditor;
module.exports = exports['default'];