'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _getOwnPropertyDescriptor = require('babel-runtime/core-js/object/get-own-property-descriptor');

var _getOwnPropertyDescriptor2 = _interopRequireDefault(_getOwnPropertyDescriptor);

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _extends3 = require('babel-runtime/helpers/extends');

var _extends4 = _interopRequireDefault(_extends3);

var _promise = require('babel-runtime/core-js/promise');

var _promise2 = _interopRequireDefault(_promise);

var _stringify = require('babel-runtime/core-js/json/stringify');

var _stringify2 = _interopRequireDefault(_stringify);

var _keys = require('babel-runtime/core-js/object/keys');

var _keys2 = _interopRequireDefault(_keys);

var _getOwnPropertyNames = require('babel-runtime/core-js/object/get-own-property-names');

var _getOwnPropertyNames2 = _interopRequireDefault(_getOwnPropertyNames);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _class, _desc, _value, _class2, _class3, _temp;

var _components = require('components');

var _variables = require('redux/modules/variables');

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _coreDecorators = require('core-decorators');

var _reactRedux = require('react-redux');

var _contact = require('redux/modules/contact');

var _reachHistory = require('../../helpers/reachHistory');

var _Contact = require('./Contact.scss');

var _Contact2 = _interopRequireDefault(_Contact);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
  var desc = {};
  Object['ke' + 'ys'](descriptor).forEach(function (key) {
    desc[key] = descriptor[key];
  });
  desc.enumerable = !!desc.enumerable;
  desc.configurable = !!desc.configurable;

  if ('value' in desc || desc.initializer) {
    desc.writable = true;
  }

  desc = decorators.slice().reverse().reduce(function (desc, decorator) {
    return decorator(target, property, desc) || desc;
  }, desc);

  if (context && desc.initializer !== void 0) {
    desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
    desc.initializer = undefined;
  }

  if (desc.initializer === void 0) {
    Object['define' + 'Property'](target, property, desc);
    desc = null;
  }

  return desc;
}

var ContactVars = (_dec = (0, _reactRedux.connect)(function (state) {
  return {
    contact: state.contact
  };
}, {
  addVarToContact: _variables.addVarToContact, deleteVarFromContact: _variables.deleteVarFromContact, getContactDetail: _contact.getContactDetail, push: _reachHistory.push
}), _dec(_class = (_class2 = (_temp = _class3 = function (_React$Component) {
  (0, _inherits3.default)(ContactVars, _React$Component);

  function ContactVars(props) {
    (0, _classCallCheck3.default)(this, ContactVars);

    var _this = (0, _possibleConstructorReturn3.default)(this, (ContactVars.__proto__ || (0, _getPrototypeOf2.default)(ContactVars)).call(this, props));

    var profile = {};
    (0, _getOwnPropertyNames2.default)(props.contact.data.profile).forEach(function (b) {
      profile[b] = props.contact.data.profile[b] ? [props.contact.data.profile[b].value] : null;
    });
    if (props.contact.data.monitoring) {
      profile['monitoring'] = ["Si"];
    } else {
      profile['monitoring'] = ["No"];
    }

    _this.state = {
      invalidData: null,
      displayInvalidData: false,
      thereIsChanges: false,
      savedSuccessfully: false,
      profile: profile
    };
    return _this;
  }

  (0, _createClass3.default)(ContactVars, [{
    key: 'handleSave',
    value: function handleSave() {
      var _this2 = this;

      if (!this.state.invalidData) {
        var id = this.props.contact.data.id;

        var newProfile = (0, _keys2.default)(this.state.profile);
        var oldProfile = (0, _keys2.default)(this.props.contact.data.profile);

        var toDelete = oldProfile.filter(function (n) {
          return newProfile.indexOf(n) === -1;
        });
        var toUpdate = [];
        newProfile.forEach(function (np) {
          if (!_this2.props.contact.data.profile[np] || (0, _stringify2.default)(_this2.state.profile[np][0]) !== (0, _stringify2.default)(_this2.props.contact.data.profile[np].value)) {
            toUpdate.push(np);
          }
        });
        _promise2.default.all([toDelete.map(function (d) {
          return _this2.props.deleteVarFromContact(id, d, _this2.props.contact.data.profile[d]);
        })]).then(function () {
          _promise2.default.all([toUpdate.map(function (u) {
            return _this2.props.addVarToContact(id, u, _this2.state.profile[u][0]);
          })]).then(function () {
            _this2.setState({
              thereIsChanges: false,
              savedSuccessfully: true
            }, function () {
              return _this2.forceUpdate();
            });
            // this.props.push(this.props.location)
          });
        });
      } else {
        this.setState({
          displayInvalidData: true
        });
      }
    }
  }, {
    key: 'validateEmail',
    value: function validateEmail(email) {
      var pattern = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
      var validEmail = false;

      if (pattern.test(String(email).toLowerCase())) {
        validEmail = true;
      }
      return validEmail;
    }
  }, {
    key: 'handleVariableAdd',
    value: function handleVariableAdd(key, value) {
      var invalidData = null;
      if (key === 'email' && !this.validateEmail(value)) {
        invalidData = 'email';
      }
      if (key === 'msisdn' && isNaN(Number(value))) {
        invalidData = 'msisdn';
      }

      this.setState({
        thereIsChanges: true,
        savedSuccessfully: false,
        displayInvalidData: false,
        invalidData: invalidData,
        profile: (0, _extends4.default)({}, this.state.profile, (0, _defineProperty3.default)({}, key, [value]))
      });
    }
  }, {
    key: 'handleVariableRemove',
    value: function handleVariableRemove(key) {
      var profile = this.state.profile;

      delete profile[key];

      var invalidData = null;
      if (!profile['msisdn'] && !profile['email']) {
        invalidData = 'both';
      }

      this.setState({
        thereIsChanges: true,
        savedSuccessfully: false,
        invalidData: invalidData,
        profile: profile
      });
    }
  }, {
    key: 'render',
    value: function render() {
      if (!this.props.contact.loading) {
        return _react2.default.createElement(
          'div',
          { className: _Contact2.default.content },
          _react2.default.createElement(
            'span',
            { className: _Contact2.default.title },
            (0, _translations2.default)('CONTACT VARIABLES')
          ),
          _react2.default.createElement(
            'div',
            { className: _Contact2.default.variableselect },
            _react2.default.createElement(_components.VariableSelect, {
              variables: this.state.profile,
              addVariable: this.handleVariableAdd,
              removeVariable: this.handleVariableRemove
            })
          ),
          _react2.default.createElement(
            'div',
            { className: _Contact2.default.variablechanges + ' ' + (this.state.thereIsChanges && _Contact2.default.unsaved) },
            this.state.thereIsChanges && !this.state.displayInvalidData && (0, _translations2.default)('CONTACT VARIABLES USAVED CHANGES'),
            this.state.displayInvalidData && this.state.invalidData === 'msisdn' && (0, _translations2.default)('CONTACT VARIABLES INVALID MSISDN'),
            this.state.displayInvalidData && this.state.invalidData === 'email' && (0, _translations2.default)('CONTACT VARIABLES INVALID EMAIL'),
            this.state.displayInvalidData && this.state.invalidData === 'both' && (0, _translations2.default)('CONTACT VARIABLES INVALID BOTH')
          ),
          this.state.savedSuccessfully && _react2.default.createElement(
            'div',
            { className: _Contact2.default.savedSuccessfully },
            (0, _translations2.default)('CONTACT SAVED SUCCESSFULLY')
          ),
          _react2.default.createElement(
            'div',
            { className: _Contact2.default.variablebutton },
            this.state.thereIsChanges ? _react2.default.createElement(_components.Button, { color: 'blue', onClick: this.handleSave, name: (0, _translations2.default)('CONTACT VARIABLES SUBMIT'), className: _Contact2.default.button }) : null
          )
        );
      }
      return _react2.default.createElement('div', null);
    }
  }]);
  return ContactVars;
}(_react2.default.Component), _class3.propTypes = {
  contact: _propTypes2.default.object.isRequired,
  addVarToContact: _propTypes2.default.func.isRequired,
  deleteVarFromContact: _propTypes2.default.func.isRequired,
  push: _propTypes2.default.func.isRequired,
  location: _propTypes2.default.object.isRequired
}, _temp), (_applyDecoratedDescriptor(_class2.prototype, 'handleSave', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'handleSave'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, 'handleVariableAdd', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'handleVariableAdd'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, 'handleVariableRemove', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'handleVariableRemove'), _class2.prototype)), _class2)) || _class);
exports.default = ContactVars;
module.exports = exports['default'];