'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactRouter = require('react-router');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = require('./Breadcrumbs.scss');

var Breadcrumbs = function Breadcrumbs(props) {
  return _react2.default.createElement(
    'ul',
    { className: styles.breadcrumbs },
    props.items.map(function (item, index) {
      return _react2.default.createElement(
        'li',
        { key: index, className: styles.item },
        _react2.default.createElement(
          _reactRouter.Link,
          { to: item.to, className: styles.link, activeClassName: styles.active },
          item.text
        )
      );
    })
  );
};

Breadcrumbs.propTypes = {
  items: _propTypes2.default.array.isRequired
};

exports.default = Breadcrumbs;
module.exports = exports['default'];