"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getPartnerByHost = getPartnerByHost;
exports.partnerByHost = partnerByHost;
exports.getApiHostByPartner = getApiHostByPartner;
/* eslint-disable */
var HOST = {
  "ec.tigobusiness.hn": "tigo",
  "claro.gt.reach.tools": "claro",
  "claro.cr.reach.tools": "claro",
  "claro.reach.tools": "claro",
  "notificame.claro.com.gt": "claro",
  "notificame.claro.com.sv": "claro",
  "notificame.claro.com.hn": "claro",
  "notificame.claro.com.ni": "claro",
  "notificame.claro.com.cr": "claro",
  "localhost": "reach"
};

var REACT_APP_HOST = process.env.REACT_APP_HOST;

var APIHOST = {
  tigo: "ec.tigobusiness.hn",
  claro: "claro.reach.tools",
  clarogt: "notificame.claro.com.gt",
  clarosv: "notificame.claro.com.sv",
  clarohn: "notificame.claro.com.hn",
  claroni: "notificame.claro.com.ni",
  clarocr: "notificame.claro.com.cr"
};

function getPartnerByHost(hostname) {
  var result = void 0;

  if (__CLIENT__) {
    if (!hostname) hostname = window.location.hostname;
    result = HOST[hostname];
  } else {
    if (hostname != null) {
      result = HOST[hostname];
    }
  }

  return result;
}

function partnerByHost() {
  if (REACT_APP_HOST.includes('claro') || REACT_APP_HOST.includes('notificame')) {
    return 'claro';
  } else if (REACT_APP_HOST.includes('tigo') || REACT_APP_HOST.includes('tigobusiness')) {
    return 'tigo';
  } else if (REACT_APP_HOST.includes('quickly') || REACT_APP_HOST.includes('quickly')) {
    return 'quickly';
  } else {
    return 'reach';
  }
}

function getApiHostByPartner(partner) {
  return APIHOST[partner] || "reach.tools";
}