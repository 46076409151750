'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _components = require('components');

var _ProfilingResults = require('./ProfilingResults.scss');

var _ProfilingResults2 = _interopRequireDefault(_ProfilingResults);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var md = {
  assignment: require('react-icons/lib/md/assignment'),
  stop: require('react-icons/lib/md/stop'),
  copy: require('react-icons/lib/md/content-copy'),
  link: require('react-icons/lib/md/link'),
  download: require('react-icons/lib/md/cloud-download')
};

var ProfilingResults = function (_React$Component) {
  (0, _inherits3.default)(ProfilingResults, _React$Component);

  function ProfilingResults() {
    (0, _classCallCheck3.default)(this, ProfilingResults);
    return (0, _possibleConstructorReturn3.default)(this, (ProfilingResults.__proto__ || (0, _getPrototypeOf2.default)(ProfilingResults)).apply(this, arguments));
  }

  (0, _createClass3.default)(ProfilingResults, [{
    key: 'getMessage',
    value: function getMessage(messageType) {
      var units = this.props.results.workflow_units;
      return units.find(function (s) {
        return s.action.name === messageType;
      });
    }
  }, {
    key: 'getQuestions',
    value: function getQuestions() {
      var units = this.props.results.workflow_units;
      return units.filter(function (s) {
        return s.action.action_type === 'question';
      }).map(function (s) {
        return {
          type: s.stats.type,
          alternative_type: s.stats.alternative_type,
          content: s.action.content,
          stats: s.stats
        };
      });
    }
  }, {
    key: 'renderMessage',
    value: function renderMessage(messageUnit) {
      if (!messageUnit) {
        return null;
      }
      return _react2.default.createElement(
        'tr',
        { key: messageUnit.name, className: _ProfilingResults2.default.message },
        _react2.default.createElement(
          'td',
          { className: _ProfilingResults2.default.questionName },
          _react2.default.createElement(_components.HtmlPreview, { html: messageUnit.action.content })
        ),
        _react2.default.createElement(
          'td',
          { className: _ProfilingResults2.default.subtitle },
          (0, _translations2.default)('SENT MESSAGES'),
          ': ',
          messageUnit.stats.sent_count
        )
      );
    }
  }, {
    key: 'renderQuestion',
    value: function renderQuestion(question, index) {
      if (question.type === 'OPEN') {
        return _react2.default.createElement(
          'tr',
          { key: index, className: _ProfilingResults2.default.result },
          _react2.default.createElement(
            'td',
            { className: _ProfilingResults2.default.questionName },
            _react2.default.createElement(
              'div',
              { className: _ProfilingResults2.default.flex },
              _react2.default.createElement(
                'h1',
                null,
                '#',
                index
              ),
              question.alternative_type === 'WHATSAPP' || question.alternative_type === 'HTML' ? _react2.default.createElement(_components.HtmlPreview, { html: question.content }) : _react2.default.createElement(
                'h2',
                null,
                question.content
              )
            )
          ),
          _react2.default.createElement(
            'td',
            null,
            _react2.default.createElement(_components.TagCloudChart, { key: index, question: question })
          )
        );
      }
      var plotType = void 0;
      var colors = void 0;
      switch (question.type) {
        case 'OPTIONS':
          plotType = 'pie';
          switch (question.stats.alternative_type) {
            case 'YES_NO':
              colors = ['#9AEA9A', '#EA9A9A'];
              break;
            case 'GENDER':
              colors = ['#139CF4', '#EF41A6'];
              break;
            default:
              break;
          }
          break;
        case 'RANGE':
          plotType = 'column';
          colors = ['#0b6868', '#10963d', '#279e12', '#8fc415', '#9dd836', '#7ef784'];
          break;
        case 'AGE':
          plotType = 'area';
          break;
        case 'MULTIPLE_CHOICE':
          plotType = 'pie';
          break;
        default:
          break;
      }

      switch (plotType) {
        case 'pie':
          return _react2.default.createElement(
            'tr',
            { key: index, className: _ProfilingResults2.default.result },
            _react2.default.createElement(
              'td',
              { className: _ProfilingResults2.default.questionName },
              _react2.default.createElement(
                'div',
                { className: _ProfilingResults2.default.flex },
                _react2.default.createElement(
                  'h1',
                  null,
                  '#',
                  index
                ),
                _react2.default.createElement(
                  'h2',
                  null,
                  question.content
                )
              )
            ),
            _react2.default.createElement(
              'td',
              null,
              _react2.default.createElement(_components.PieChart, { question: question, plotType: plotType, colors: colors })
            )
          );
        case 'area':
          return _react2.default.createElement(
            'tr',
            { key: index, className: _ProfilingResults2.default.result },
            _react2.default.createElement(
              'td',
              { className: _ProfilingResults2.default.questionName },
              _react2.default.createElement(
                'div',
                { className: _ProfilingResults2.default.flex },
                _react2.default.createElement(
                  'h1',
                  null,
                  '#',
                  index
                ),
                _react2.default.createElement(
                  'h2',
                  null,
                  question.content
                )
              )
            ),
            _react2.default.createElement(
              'td',
              null,
              _react2.default.createElement(_components.AreaChart, { question: question, plotType: plotType })
            )
          );
        case 'column':
          return _react2.default.createElement(
            'tr',
            { key: index, className: _ProfilingResults2.default.result },
            _react2.default.createElement(
              'td',
              { className: _ProfilingResults2.default.questionName },
              _react2.default.createElement(
                'div',
                { className: _ProfilingResults2.default.flex },
                _react2.default.createElement(
                  'h1',
                  null,
                  '#',
                  index
                ),
                _react2.default.createElement(
                  'h2',
                  null,
                  question.content
                )
              )
            ),
            _react2.default.createElement(
              'td',
              null,
              _react2.default.createElement(_components.PieChart, { question: question, plotType: plotType, colors: colors, showOptions: false, size: 250 })
            )
          );
        default:
          return null;
      }
    }
  }, {
    key: 'renderQuestions',
    value: function renderQuestions() {
      var _this2 = this;

      return this.getQuestions().map(function (s, index) {
        return _this2.renderQuestion(s, index);
      });
    }
  }, {
    key: 'renderResults',
    value: function renderResults() {
      var values = [this.renderMessage(this.getMessage('Invitación'))].concat(this.renderMessage(this.getMessage('Bienvenida'))).concat(this.renderQuestions()).concat([this.renderMessage(this.getMessage('Despedida'))]).filter(function (item) {
        return item !== null;
      });
      return _react2.default.createElement(
        'table',
        { className: _ProfilingResults2.default.table },
        _react2.default.createElement(
          'tbody',
          { className: _ProfilingResults2.default.tbody },
          values
        )
      );
    }
  }, {
    key: 'render',
    value: function render() {
      if (!this.props.results) {
        return null;
      }
      var interaction = this.props.interaction;

      var hidden = { display: 'none' };
      var visible = { display: 'block' };
      var scheduledFor = (0, _moment2.default)(this.props.results.scheduled_for);
      var runningUntil = (0, _moment2.default)(scheduledFor);
      var totalAnswers = this.props.results.workflow_units.map(function (u) {
        return u.name !== 'Invitación' && u.stats.answers ? u.stats.answers.total : 0;
      }).reduce(function (a, b) {
        return a + b;
      }, 0);

      return _react2.default.createElement(
        'div',
        { className: _ProfilingResults2.default.results },
        _react2.default.createElement(
          'div',
          { className: _ProfilingResults2.default.bar },
          _react2.default.createElement(
            'div',
            { className: _ProfilingResults2.default.icon },
            _react2.default.createElement(md.assignment, { size: 25, color: '#FFF' })
          ),
          _react2.default.createElement(
            'div',
            { className: _ProfilingResults2.default.title },
            _react2.default.createElement(
              'h1',
              null,
              this.props.results.job_description.name
            ),
            _react2.default.createElement(
              'h2',
              null,
              (0, _translations2.default)('CREATED ON') + ' ' + (0, _moment2.default)(interaction).format('MM/DD')
            )
          )
        ),
        _react2.default.createElement(
          'div',
          { className: _ProfilingResults2.default.main },
          _react2.default.createElement('div', { className: _ProfilingResults2.default.actions }),
          _react2.default.createElement(
            'div',
            { className: _ProfilingResults2.default.dates },
            _react2.default.createElement(
              'div',
              { className: _ProfilingResults2.default.date },
              _react2.default.createElement(
                'h1',
                null,
                (0, _translations2.default)('END DATE'),
                ': ',
                runningUntil === null ? (0, _translations2.default)('NO DATE') : runningUntil.format('DD/MM HH:mm')
              )
            )
          ),
          _react2.default.createElement(
            'div',
            { className: _ProfilingResults2.default.mainResults },
            this.renderResults()
          )
        )
      );
    }
  }]);
  return ProfilingResults;
}(_react2.default.Component);

exports.default = ProfilingResults;
module.exports = exports['default'];