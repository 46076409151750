'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.actions = undefined;
exports.default = reducer;
exports.getTemplates = getTemplates;
exports.createTemplate = createTemplate;
exports.duplicateTemplate = duplicateTemplate;
exports.sendEmail = sendEmail;

var _apiAction = require('../apiAction');

var _apiAction2 = _interopRequireDefault(_apiAction);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var actions = exports.actions = new _apiAction2.default('EMAILS', [_apiAction.ACTION_TYPES.DATA, _apiAction.ACTION_TYPES.TRIM, _apiAction.ACTION_TYPES.APPEND, _apiAction.ACTION_TYPES.SELECT]);

var initialState = {
    loading: false,
    status: actions.DATA,
    data: {}
};

function reducer() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
    var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    return actions.getStateForAction(state, action);
}

function getTemplates(params) {
    return {
        types: [actions.APPEND, actions.APPEND_SUCCESS, actions.APPEND_FAIL],
        promise: function promise(client) {
            return client.get('template/config/email', { params: params });
        }
    };
}

function createTemplate(params) {
    return {
        types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
        promise: function promise(client) {
            return client.post('template/config/email', { data: params });
        }
    };
}

function duplicateTemplate(params) {
    return {
        types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
        promise: function promise(client) {
            return client.post('template/duplicate/config/email', { data: params });
        }
    };
}

function sendEmail(params) {
    return {
        types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
        promise: function promise(client) {
            return client.post('email/individual', { data: params });
        }
    };
}