'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _i18nReact = require('i18n-react');

var _i18nReact2 = _interopRequireDefault(_i18nReact);

var _config = require('../config');

var _config2 = _interopRequireDefault(_config);

var _partner = require('../helpers/partner');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Translations = function () {
  function Translations(lang) {
    (0, _classCallCheck3.default)(this, Translations);

    // eslint-disable-next-line import/no-dynamic-require
    _i18nReact2.default.setTexts(require('./' + lang + ((0, _partner.partnerByHost)() === 'tigo' ? '-tigo' : '') + '.json'));
  }

  (0, _createClass3.default)(Translations, [{
    key: 'translate',
    value: function translate(key) {
      return _i18nReact2.default.translate(key);
    }
  }]);
  return Translations;
}();

var translator = new Translations(_config2.default.lang);

exports.default = function (key) {
  return translator.translate(key);
};

module.exports = exports['default'];