'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actions = undefined;
exports.default = reducer;
exports.getEmailChannel = getEmailChannel;
exports.updateEmailChannel = updateEmailChannel;

var _apiAction = require('../apiAction');

var _apiAction2 = _interopRequireDefault(_apiAction);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// ACTIONS


var actions = exports.actions = new _apiAction2.default('CHANNEL', [_apiAction.ACTION_TYPES.DATA]);

// REDUCER


var initialState = {
  loading: false,
  status: actions.DATA,
  data: {}
};

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  return actions.getStateForAction(state, action);
}

// ACTION CREATORS

function getEmailChannel(channelId) {
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.get('/account/channel/' + channelId);
    }
  };
}

function updateEmailChannel(channelData) {
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.put('/account/email/' + channelData._id + '/' + channelData.lastEmail, {
        data: channelData
      });
    }

  };
}