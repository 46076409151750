'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _class, _class2, _temp;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactRedux = require('react-redux');

var _numbro = require('numbro');

var _numbro2 = _interopRequireDefault(_numbro);

var _components = require('components');

var _reports = require('redux/modules/reports');

var _Reports = require('./Reports.scss');

var _Reports2 = _interopRequireDefault(_Reports);

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _featureEnabled = require('../../helpers/featureEnabled');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var md = {
  error: require('react-icons/lib/md/error')
};

var BalanceReport = (_dec = (0, _reactRedux.connect)(function (state) {
  return {
    mobileMode: state.mobile.mobileMode,
    reports: state.reports,
    account: state.account.data
  };
}, { getBalance: _reports.getBalance }), _dec(_class = (_temp = _class2 = function (_React$Component) {
  (0, _inherits3.default)(BalanceReport, _React$Component);

  function BalanceReport(props) {
    (0, _classCallCheck3.default)(this, BalanceReport);

    var _this = (0, _possibleConstructorReturn3.default)(this, (BalanceReport.__proto__ || (0, _getPrototypeOf2.default)(BalanceReport)).call(this, props));

    _this.state = (0, _extends3.default)({}, _this.state, {
      balance: true
    });
    _this.format = '';
    return _this;
  }

  (0, _createClass3.default)(BalanceReport, [{
    key: 'componentWillMount',
    value: function componentWillMount() {
      if (__CLIENT__) {
        this.updateReport();
      }
    }
  }, {
    key: 'formatNumber',
    value: function formatNumber(n) {
      return (0, _numbro2.default)(n).format(this.format);
    }
  }, {
    key: 'updateReport',
    value: function updateReport() {
      var _this2 = this;

      this.props.getBalance().then(function (balance) {
        _this2.setState((0, _extends3.default)({}, _this2.state, {
          balance: balance
        }));
      }).catch(function (e) {
        console.error('Could not get balance data', e);
        _this2.setState((0, _extends3.default)({}, _this2.state, {
          balance: false
        }));
      });
    }
  }, {
    key: 'renderLoading',
    value: function renderLoading() {
      return _react2.default.createElement(
        'div',
        { style: { textAlign: 'center' } },
        _react2.default.createElement(_components.Loading, null)
      );
    }
  }, {
    key: 'renderIfBool',
    value: function renderIfBool(obj) {
      if (obj === true) {
        return this.renderLoading();
      }

      if (obj === false) {
        return _react2.default.createElement(md.error, { size: 40, color: 'red' });
      }
    }
  }, {
    key: 'render',
    value: function render() {
      if (typeof this.state.balance === "boolean") {
        return this.renderIfBool(this.state.balance);
      }
      if (!this.state.balance) {
        return 'Error';
      }

      var f = this.formatNumber.bind(this);
      var data = {};
      var email = this.state.balance.email;
      var email_enabled = (0, _featureEnabled.featureEnabled)('channel-email');

      if (email_enabled && email && email.credit_base_limit) {
        var dataEmail = {
          percent: email.credit_base_limit ? Math.floor(email.balance / email.credit_base_limit * 100).toFixed(2) : 0,
          remaining: f(email.credit_limit - (email.charges - email.refunds)),
          package: f(email.credit_limit)
        };
        data.email = dataEmail;
      }

      if (this.state.balance.current_package) {
        var _state$balance$curren = this.state.balance.current_package,
            balance = _state$balance$curren.balance,
            charges = _state$balance$curren.charges,
            credit_base_limit = _state$balance$curren.credit_base_limit,
            credit_limit = _state$balance$curren.credit_limit,
            credit_extra_limit = _state$balance$curren.credit_extra_limit,
            refunds = _state$balance$curren.refunds;

        var cycle = this.state.balance.cycle || '';
        var packageMessages = credit_base_limit;
        var usedMessages = credit_limit - balance;
        var availableMessages = usedMessages < credit_base_limit ? balance - credit_extra_limit : 0;
        var availableExtras = usedMessages > credit_base_limit ? credit_limit - usedMessages : credit_extra_limit;
        var consumedMessages = charges - refunds;
        var base_percent = credit_base_limit ? Math.floor(availableMessages / credit_base_limit * 100).toFixed(2) : 0;
        var extras_percent = credit_extra_limit ? Math.floor(availableExtras / credit_extra_limit * 100).toFixed(2) : 0;

        var sms = {
          messages: {
            percent: base_percent,
            remaining: f(availableMessages),
            package: f(packageMessages)
          },
          extras: {
            percent: extras_percent,
            consumed: f(credit_extra_limit - availableExtras),
            remaining: f(availableExtras),
            limit: f(credit_extra_limit)
          },
          cycle: cycle
        };
        data.sms = sms;
      }
      return _react2.default.createElement(
        'article',
        { className: _Reports2.default.mainKpi },
        _react2.default.createElement(SimpleTabs, { data: data })
      );
    }
  }]);
  return BalanceReport;
}(_react2.default.Component), _class2.propTypes = {
  mobileMode: _propTypes2.default.bool,
  account: _propTypes2.default.object
}, _temp)) || _class);
exports.default = BalanceReport;

var SimpleTabs = function (_React$Component2) {
  (0, _inherits3.default)(SimpleTabs, _React$Component2);

  function SimpleTabs(props) {
    (0, _classCallCheck3.default)(this, SimpleTabs);

    var _this3 = (0, _possibleConstructorReturn3.default)(this, (SimpleTabs.__proto__ || (0, _getPrototypeOf2.default)(SimpleTabs)).call(this, props));

    _this3.renderLabels = _this3.renderLabels.bind(_this3);
    _this3.state = {
      active: 'SMS'
    };
    return _this3;
  }

  (0, _createClass3.default)(SimpleTabs, [{
    key: 'handleTabChange',
    value: function handleTabChange(activeTabs) {
      this.setState({
        active: activeTabs
      });
    }
  }, {
    key: 'renderEmail',
    value: function renderEmail() {
      if (this.props.data.email) {
        return _react2.default.createElement(
          'div',
          { className: _Reports2.default.kpis },
          _react2.default.createElement(_components.MiniGauge, { percent: this.props.data.email.percent, color: '#00BB7C', background: '#dcefe9' }),
          _react2.default.createElement(
            'div',
            { className: _Reports2.default.kpi },
            _react2.default.createElement(
              'h2',
              null,
              (0, _translations2.default)('AVAILABLE EXTRAS')
            ),
            _react2.default.createElement(
              'h1',
              { className: _Reports2.default.orange },
              this.props.data.email.remaining
            )
          ),
          _react2.default.createElement(
            'div',
            { className: _Reports2.default.kpi },
            _react2.default.createElement(
              'h2',
              null,
              (0, _translations2.default)('PACKET MESSAGES')
            ),
            _react2.default.createElement(
              'h1',
              { className: _Reports2.default.orange },
              this.props.data.email.package
            )
          )
        );
      }
      return null;
    }
  }, {
    key: 'renderSms',
    value: function renderSms() {
      if (this.props.data.sms) {
        return _react2.default.createElement(
          'div',
          { className: _Reports2.default.kpis },
          _react2.default.createElement(_components.MiniGauge, { percent: this.props.data.sms.messages.percent, color: '#00BB7C', background: '#dcefe9' }),
          _react2.default.createElement(
            'div',
            { className: _Reports2.default.kpi },
            _react2.default.createElement(
              'h2',
              null,
              (0, _translations2.default)('AVAILABLE MESSAGES')
            ),
            _react2.default.createElement(
              'h1',
              { title: (0, _numbro2.default)(this.props.data.sms.messages.remaining).format(), className: _Reports2.default.green },
              this.props.data.sms.messages.remaining
            )
          ),
          _react2.default.createElement(
            'div',
            { className: _Reports2.default.kpi },
            _react2.default.createElement(
              'h2',
              null,
              (0, _translations2.default)('PACKET MESSAGES')
            ),
            _react2.default.createElement(
              'h1',
              { title: (0, _numbro2.default)(this.props.data.sms.messages.package).format() },
              this.props.data.sms.messages.package
            )
          ),
          _react2.default.createElement(
            'h1',
            { className: _Reports2.default.title + ' ' + _Reports2.default.blue },
            (0, _translations2.default)('EXTRAS')
          ),
          _react2.default.createElement(_components.MiniGauge, { percent: this.props.data.sms.extras.percent, color: '#6357A6', background: '#e6e5ed' }),
          _react2.default.createElement(
            'div',
            { className: _Reports2.default.kpi },
            _react2.default.createElement(
              'h2',
              null,
              (0, _translations2.default)('USED EXTRAS')
            ),
            _react2.default.createElement(
              'h1',
              { className: _Reports2.default.blue },
              this.props.data.sms.extras.consumed
            )
          ),
          _react2.default.createElement(
            'div',
            { className: _Reports2.default.kpi },
            _react2.default.createElement(
              'h2',
              null,
              (0, _translations2.default)('AVAILABLE EXTRAS')
            ),
            _react2.default.createElement(
              'h1',
              { className: _Reports2.default.blue },
              this.props.data.sms.extras.remaining
            )
          ),
          _react2.default.createElement(
            'div',
            { className: _Reports2.default.kpi },
            _react2.default.createElement(
              'h2',
              null,
              (0, _translations2.default)('EXTRA MESSAGES')
            ),
            _react2.default.createElement(
              'h1',
              null,
              this.props.data.sms.extras.limit
            )
          ),
          _react2.default.createElement(
            'div',
            { className: _Reports2.default.kpi },
            _react2.default.createElement(
              'h1',
              { className: _Reports2.default.cycle },
              this.props.data.sms.cycle
            )
          )
        );
      }
      return null;
    }
  }, {
    key: 'renderLabels',
    value: function renderLabels() {
      return _react2.default.createElement(
        _react2.default.Fragment,
        null,
        this.props.data.sms && _react2.default.createElement(
          'li',
          { className: this.state.active === 'SMS' ? _Reports2.default.active : null, onClick: this.handleTabChange.bind(this, 'SMS') },
          (0, _translations2.default)('TRANSACTIONS')
        ),
        this.props.data.email && _react2.default.createElement(
          'li',
          { className: this.state.active === 'EMAIL' ? _Reports2.default.active : null, onClick: this.handleTabChange.bind(this, 'EMAIL') },
          (0, _translations2.default)('EMAIL')
        )
      );
    }
  }, {
    key: 'render',
    value: function render() {
      var content = null;
      if (this.state.active === 'SMS') {
        content = this.renderSms();
      } else {
        content = this.renderEmail();
      }

      return _react2.default.createElement(
        'div',
        { className: _Reports2.default.simpleTabs },
        _react2.default.createElement(
          'ul',
          { className: _Reports2.default.labels },
          this.renderLabels()
        ),
        content
      );
    }
  }]);
  return SimpleTabs;
}(_react2.default.Component);

module.exports = exports['default'];