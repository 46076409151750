'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = undefined;

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactDropzoneS3Uploader = require('react-dropzone-s3-uploader');

var _reactDropzoneS3Uploader2 = _interopRequireDefault(_reactDropzoneS3Uploader);

var _ApiClient = require('../../../../helpers/ApiClient');

var _ApiClient2 = _interopRequireDefault(_ApiClient);

var _Upload = require('./Upload.scss');

var _Upload2 = _interopRequireDefault(_Upload);

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _useBasename = require('../../../../helpers/useBasename');

var _useBasename2 = _interopRequireDefault(_useBasename);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function getSignedUrl(file, callback) {
    var client = new _ApiClient2.default((0, _useBasename2.default)('/s3'));
    var params = {
        objectName: file.name,
        contentType: file.type
    };

    client.get('/sign', { params: params }).then(function (data) {
        callback(data);
    }).catch(function (error) {
        console.error(error);
    });
}

var Upload = function (_React$Component) {
    (0, _inherits3.default)(Upload, _React$Component);

    function Upload(props) {
        (0, _classCallCheck3.default)(this, Upload);

        var _this = (0, _possibleConstructorReturn3.default)(this, (Upload.__proto__ || (0, _getPrototypeOf2.default)(Upload)).call(this, props));

        _this.handlePreview = function (uploadedFile) {
            if (uploadedFile) {
                _this.setState({ uploadedImage: uploadedFile.fileUrl }, function () {
                    return _this.props.handleData(uploadedFile.fileUrl);
                });
            }
        };

        _this.state = {
            uploadedImage: null
        };
        return _this;
    }

    (0, _createClass3.default)(Upload, [{
        key: 'render',
        value: function render() {
            var _this2 = this;

            var s3Url = 'https://reach-public.s3.amazonaws.com/';

            return _react2.default.createElement(
                'div',
                { className: _Upload2.default.mainContainer },
                _react2.default.createElement(_reactDropzoneS3Uploader2.default, {
                    className: this.state.uploadedImage === null ? _Upload2.default.dragAndDrop : _Upload2.default.onSuccess,
                    s3Url: s3Url,
                    upload: {
                        getSignedUrl: getSignedUrl,
                        accept: 'image/*',
                        uploadRequestHeaders: {},
                        contentDisposition: 'attachment'
                    },
                    onProgress: function onProgress(e) {
                        return console.info('onprogress', e);
                    },
                    onError: function onError(e) {
                        return console.info('onError', e);
                    },
                    onFinish: function onFinish(wtf) {
                        return _this2.handlePreview(wtf);
                    }
                }),
                _react2.default.createElement(
                    'div',
                    { className: _Upload2.default.textAside3 },
                    _react2.default.createElement(
                        'a',
                        { style: { fontSize: '20px' } },
                        (0, _translations2.default)('ACCOUNT HEADER LOGO UPLOAD')
                    )
                )
            );
        }
    }]);
    return Upload;
}(_react2.default.Component);

exports.default = Upload;

var UploadDisplay = function (_React$Component2) {
    (0, _inherits3.default)(UploadDisplay, _React$Component2);

    function UploadDisplay() {
        (0, _classCallCheck3.default)(this, UploadDisplay);
        return (0, _possibleConstructorReturn3.default)(this, (UploadDisplay.__proto__ || (0, _getPrototypeOf2.default)(UploadDisplay)).apply(this, arguments));
    }

    return UploadDisplay;
}(_react2.default.Component);

module.exports = exports['default'];