'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TransactionsRequest = undefined;

var _getOwnPropertyDescriptor = require('babel-runtime/core-js/object/get-own-property-descriptor');

var _getOwnPropertyDescriptor2 = _interopRequireDefault(_getOwnPropertyDescriptor);

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _class, _desc, _value, _class2;

var _Allow = require('../Allow/Allow');

var _Allow2 = _interopRequireDefault(_Allow);

var _components = require('components');

var _reactDatetime = require('react-datetime');

var _reactDatetime2 = _interopRequireDefault(_reactDatetime);

var _reactHelmet = require('react-helmet');

var _reactHelmet2 = _interopRequireDefault(_reactHelmet);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _coreDecorators = require('core-decorators');

var _reactRedux = require('react-redux');

var _account = require('redux/modules/account');

var _users = require('redux/modules/users');

var _themeEnabled = require('helpers/themeEnabled');

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _reachHistory = require('../../helpers/reachHistory');

var _reports = require('redux/modules/reports');

var _querystring = require('querystring');

var _Report = require('./Report.scss');

var _Report2 = _interopRequireDefault(_Report);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
  var desc = {};
  Object['ke' + 'ys'](descriptor).forEach(function (key) {
    desc[key] = descriptor[key];
  });
  desc.enumerable = !!desc.enumerable;
  desc.configurable = !!desc.configurable;

  if ('value' in desc || desc.initializer) {
    desc.writable = true;
  }

  desc = decorators.slice().reverse().reduce(function (desc, decorator) {
    return decorator(target, property, desc) || desc;
  }, desc);

  if (context && desc.initializer !== void 0) {
    desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
    desc.initializer = undefined;
  }

  if (desc.initializer === void 0) {
    Object['define' + 'Property'](target, property, desc);
    desc = null;
  }

  return desc;
}

var md = {
  pieChart: require('react-icons/lib/md/pie-chart'),
  arrowBack: require('react-icons/lib/md/arrow-back')
};

var styles = require('./Reports.scss');

var TransactionsRequest = exports.TransactionsRequest = (_dec = (0, _reactRedux.connect)(function (state) {
  return {
    account: state.account.data,
    list: state.users.list
  };
}, { sendTransactionReportRequest: _reports.sendTransactionReportRequest, push: _reachHistory.push, getAccount: _account.getAccount, getAccountUsers: _users.getAccountUsers }), _dec(_class = (_class2 = function (_React$Component) {
  (0, _inherits3.default)(TransactionsRequest, _React$Component);

  function TransactionsRequest(props) {
    (0, _classCallCheck3.default)(this, TransactionsRequest);

    var _this = (0, _possibleConstructorReturn3.default)(this, (TransactionsRequest.__proto__ || (0, _getPrototypeOf2.default)(TransactionsRequest)).call(this, props));

    _this.handle_router_change = function (value) {
      _this.setState({ routerInfo: value });
    };

    _this.handle_user_change = function (value) {
      _this.setState({
        user: value
      });
    };

    _this.state = {
      advanced: false,
      direction: '',
      showErrors: false,
      msisdn: '',
      tag: '',
      user: "",
      routerInfo: '',
      template_uid: ''
    };
    return _this;
  }

  (0, _createClass3.default)(TransactionsRequest, [{
    key: 'handleSubmit',
    value: function handleSubmit() {
      var _this2 = this;

      var _props$location$state = this.props.location.state,
          startDate = _props$location$state.startDate,
          endDate = _props$location$state.endDate;

      return this.props.sendTransactionReportRequest(startDate, endDate, this.state.tag, this.state.msisdn, this.state.direction, this.state.showErrors, this.state.template_uid, this.state.routerInfo, '', '', '', this.state.user).then(function () {
        _this2.props.push({
          pathname: '/success',
          state: {
            goTo: '/reports/',
            goToModal: false,
            message: (0, _translations2.default)('REPORT REQUESTED'),
            modal: true,
            returnTo: '/reports/'
          }
        });
      });
    }
  }, {
    key: 'handleInput',
    value: function handleInput(field) {
      this.setState((0, _defineProperty3.default)({}, field, event.target.value));
    }
  }, {
    key: 'handleAdvanced',
    value: function handleAdvanced() {
      this.setState({
        advanced: !this.state.advanced
      });
    }
  }, {
    key: 'handleDateChange',
    value: function handleDateChange(startDate, endDate) {
      if (startDate) this.props.location.state.startDate = (0, _moment2.default)(startDate).startOf('day');
      this.setState({
        startDate: (0, _moment2.default)(startDate).startOf('day')
      });
      if (endDate) this.props.location.state.endDate = (0, _moment2.default)(endDate).endOf('day');
      this.setState({
        endDate: (0, _moment2.default)(endDate).endOf('day')
      });
    }
  }, {
    key: 'render',
    value: function render() {
      var _this3 = this;

      if (!this.props.location.state) {
        return null;
      }
      if (this.props.list.length == 0) this.props.getAccountUsers().then(function (userList) {
        _this3.setState({
          user_list: userList
        });
      });
      var startDate = this.props.location.state.startDate ? (0, _moment2.default)(this.props.location.state.startDate).startOf('day') : (0, _moment2.default)().startOf('day');
      var endDate = this.props.location.state.endDate ? (0, _moment2.default)(this.props.location.state.endDate).endOf('day') : (0, _moment2.default)().endOf('day');
      var startDateFormatted = startDate ? (0, _moment2.default)(startDate).format('YYYY-MM-DD') : (0, _moment2.default)().format('YYYY-MM-DD');
      var endDateFormatted = endDate ? (0, _moment2.default)(endDate).format('YYYY-MM-DD') : (0, _moment2.default)().format('YYYY-MM-DD');
      var classesTheme = (0, _themeEnabled.getThemeEnabledStyle)(_Report2.default);
      return _react2.default.createElement(
        'div',
        { className: _Report2.default.report },
        _react2.default.createElement(_reactHelmet2.default, { title: "Reportes" }),
        _react2.default.createElement(
          'div',
          { className: classesTheme },
          _react2.default.createElement(
            'div',
            { className: _Report2.default.bar },
            _react2.default.createElement(
              'div',
              { className: _Report2.default.goBack, onClick: this.props.router.goBack },
              _react2.default.createElement(md.arrowBack, { size: 20, color: '#FFF' })
            ),
            _react2.default.createElement(
              'div',
              { className: _Report2.default.icon },
              _react2.default.createElement(md.pieChart, { size: 20, color: '#FFF' })
            ),
            _react2.default.createElement(
              'div',
              { className: _Report2.default.title },
              (0, _translations2.default)('TRANSACTIONS REPORT'),
              '  ',
              _react2.default.createElement(
                'span',
                { className: _Report2.default.lower },
                (0, _translations2.default)('FROM'),
                ' ',
                startDateFormatted,
                ' ',
                (0, _translations2.default)('TO'),
                ' ',
                endDateFormatted
              )
            )
          )
        ),
        _react2.default.createElement(
          'div',
          { className: styles.body },
          _react2.default.createElement(
            'div',
            { className: styles.text },
            (0, _translations2.default)('TRANSACTIONS REQUEST TEXT')
          ),
          _react2.default.createElement(
            'table',
            { className: styles.userTable },
            _react2.default.createElement(
              'tbody',
              null,
              _react2.default.createElement(
                'tr',
                { className: styles.tr },
                _react2.default.createElement(
                  'td',
                  { className: styles.td },
                  (0, _translations2.default)('START DATE')
                ),
                _react2.default.createElement(
                  'td',
                  { className: styles.td2 },
                  _react2.default.createElement(
                    'div',
                    { className: styles.date },
                    _react2.default.createElement(_reactDatetime2.default, { closeOnSelect: true, value: startDate, onChange: this.handleDateChange.bind(this) })
                  )
                )
              ),
              _react2.default.createElement(
                'tr',
                { className: styles.tr },
                _react2.default.createElement(
                  'td',
                  { className: styles.td },
                  (0, _translations2.default)('END DATE')
                ),
                _react2.default.createElement(
                  'td',
                  { className: styles.td2 },
                  _react2.default.createElement(
                    'div',
                    { className: styles.date },
                    _react2.default.createElement(_reactDatetime2.default, { closeOnSelect: true, value: endDate, onChange: this.handleDateChange.bind(this, null) })
                  )
                )
              )
            )
          ),
          !this.state.advanced && _react2.default.createElement(
            'a',
            { className: styles.advanced, onClick: this.handleAdvanced },
            (0, _translations2.default)('ADVANCED FILTERS'),
            '...'
          ),
          this.state.advanced && _react2.default.createElement(
            'table',
            { className: styles.userTable },
            _react2.default.createElement(
              'tbody',
              null,
              _react2.default.createElement(
                'tr',
                { className: styles.tr },
                _react2.default.createElement(
                  'td',
                  { className: styles.td },
                  (0, _translations2.default)('DIRECTION')
                ),
                _react2.default.createElement(
                  'td',
                  { className: styles.td2 },
                  _react2.default.createElement(
                    'select',
                    { value: this.state.direction, onChange: this.handleInput.bind(this, 'direction') },
                    _react2.default.createElement(
                      'option',
                      { value: '' },
                      '' + (0, _translations2.default)('IN OUT')
                    ),
                    _react2.default.createElement(
                      'option',
                      { value: 'OUT' },
                      (0, _translations2.default)('OUT')
                    ),
                    _react2.default.createElement(
                      'option',
                      { value: 'IN' },
                      (0, _translations2.default)('IN')
                    )
                  )
                )
              ),
              _react2.default.createElement(
                'tr',
                { className: styles.tr },
                _react2.default.createElement(
                  'td',
                  { className: styles.td },
                  (0, _translations2.default)('DESTINATION') + ' / ' + (0, _translations2.default)('SOURCE')
                ),
                _react2.default.createElement(
                  'td',
                  { className: styles.td2 },
                  _react2.default.createElement('input', { type: 'text', value: this.state.msisdn, onChange: this.handleInput.bind(this, 'msisdn') })
                )
              ),
              _react2.default.createElement(
                'tr',
                { className: styles.tr },
                _react2.default.createElement(
                  'td',
                  { className: styles.td },
                  (0, _translations2.default)('DESTINATION TAG')
                ),
                _react2.default.createElement(
                  'td',
                  { className: styles.td2 },
                  _react2.default.createElement('input', { type: 'text', value: this.state.tag, onChange: this.handleInput.bind(this, 'tag') })
                )
              ),
              _react2.default.createElement(
                'tr',
                { className: styles.tr },
                _react2.default.createElement(
                  'td',
                  { className: styles.td },
                  (0, _translations2.default)('SHOW ERRORS')
                ),
                _react2.default.createElement(
                  'td',
                  { className: styles.td2 },
                  _react2.default.createElement(
                    'select',
                    { value: this.state.showErrors, onChange: this.handleInput.bind(this, 'showErrors') },
                    _react2.default.createElement(
                      'option',
                      { value: 'false' },
                      (0, _translations2.default)('OPTION2')
                    ),
                    _react2.default.createElement(
                      'option',
                      { value: 'true' },
                      (0, _translations2.default)('OPTION1')
                    )
                  )
                )
              ),
              _react2.default.createElement(
                'tr',
                { className: styles.tr },
                _react2.default.createElement(
                  'td',
                  { className: styles.td },
                  (0, _translations2.default)('USERS')
                ),
                _react2.default.createElement(
                  'td',
                  { className: styles.td2 },
                  _react2.default.createElement(
                    'select',
                    { value: this.state.user,
                      onChange: function onChange(event) {
                        return _this3.handle_user_change(event.target.value);
                      } },
                    _react2.default.createElement(
                      'option',
                      { value: '' },
                      (0, _translations2.default)('ALL USERS')
                    ),
                    this.props.list.map(function (data, index) {
                      return _react2.default.createElement(
                        'option',
                        { key: index, value: data.username },
                        data.username
                      );
                    })
                  )
                )
              ),
              _react2.default.createElement(
                'tr',
                { className: styles.tr },
                _react2.default.createElement(
                  'td',
                  { className: styles.td },
                  (0, _translations2.default)('ROUTER')
                ),
                _react2.default.createElement(
                  'td',
                  { className: styles.td2 },
                  _react2.default.createElement(
                    'select',
                    { value: this.state.routerInfo,
                      onChange: function onChange(event) {
                        return _this3.handle_router_change(event.target.value);
                      } },
                    _react2.default.createElement(
                      'option',
                      { value: '' },
                      (0, _translations2.default)('ALL ROUTER')
                    ),
                    this.props.account.router.router_sources.map(function (data, index) {
                      return _react2.default.createElement(
                        'option',
                        { key: index, value: data._id },
                        data.name
                      );
                    })
                  )
                )
              ),
              _react2.default.createElement(
                _Allow2.default,
                { permissions: ['channels:sms:template:list'], features: ['template-sms'] },
                _react2.default.createElement(
                  'tr',
                  { className: styles.tr },
                  _react2.default.createElement(
                    'td',
                    { className: styles.td },
                    (0, _translations2.default)('TEMPLATE SMS')
                  ),
                  _react2.default.createElement(
                    'td',
                    { className: styles.td2 },
                    _react2.default.createElement('input', { type: 'text', value: this.state.template_uid, onChange: this.handleInput.bind(this, 'template_uid') })
                  )
                )
              )
            )
          ),
          _react2.default.createElement(
            'div',
            { className: styles.text },
            (0, _translations2.default)('TRANSACTIONS REQUEST TEXT 3')
          ),
          _react2.default.createElement(
            'div',
            { className: styles.requestButton },
            _react2.default.createElement(_components.Button, { color: 'green', name: (0, _translations2.default)('REQUEST REPORT'), onClick: this.handleSubmit })
          )
        )
      );
    }
  }]);
  return TransactionsRequest;
}(_react2.default.Component), (_applyDecoratedDescriptor(_class2.prototype, 'handleSubmit', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'handleSubmit'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, 'handleInput', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'handleInput'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, 'handleAdvanced', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'handleAdvanced'), _class2.prototype)), _class2)) || _class);