'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = undefined;

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _class;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactPhoneInput = require('react-phone-input-2');

var _reactPhoneInput2 = _interopRequireDefault(_reactPhoneInput);

var _StyledPhoneInput = require('./StyledPhoneInput.scss');

var _StyledPhoneInput2 = _interopRequireDefault(_StyledPhoneInput);

var _config = require('../../config');

var _config2 = _interopRequireDefault(_config);

var _countries = require('../../redux/modules/countries');

var _reactRedux = require('react-redux');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var StyledPhoneInput = (_dec = (0, _reactRedux.connect)(function (state) {
    return {};
}, {
    get: _countries.getCountries
}), _dec(_class = function (_React$Component) {
    (0, _inherits3.default)(StyledPhoneInput, _React$Component);

    function StyledPhoneInput(props) {
        (0, _classCallCheck3.default)(this, StyledPhoneInput);

        var _this = (0, _possibleConstructorReturn3.default)(this, (StyledPhoneInput.__proto__ || (0, _getPrototypeOf2.default)(StyledPhoneInput)).call(this, props));

        _this.state = {
            onlyCountries: props.countries ? props.countries.map(function (element) {
                return element.toLowerCase();
            }) : [],
            defaultCountry: props.countries ? props.countries[0].toLowerCase() : ''
        };
        return _this;
    }

    (0, _createClass3.default)(StyledPhoneInput, [{
        key: 'componentDidMount',
        value: function componentDidMount() {
            var _this2 = this;

            if (_config2.default.regionalInstance && !this.props.isAddContact) {
                this.props.get().then(function (response) {
                    _this2.setState({
                        onlyCountries: response.map(function (country) {
                            return country.iso_code2.toLowerCase();
                        })
                    });
                }).catch(function (error) {
                    console.error("Error fetching countries:", error);
                });
            }
        }
    }, {
        key: 'render',
        value: function render() {
            var _props = this.props,
                value = _props.value,
                onChange = _props.onChange,
                placeholder = _props.placeholder,
                error = _props.error,
                inputClass = _props.inputClass,
                disabled = _props.disabled;

            return _react2.default.createElement(_reactPhoneInput2.default, {
                key: this.state.onlyCountries,
                country: this.state.defaultCountry,
                onlyCountries: this.state.onlyCountries,
                value: value,
                onChange: onChange,
                placeholder: placeholder,
                error: error,
                specialLabel: ' ',
                inputClass: _StyledPhoneInput2.default.inputphone + ' ' + inputClass,
                dropdownClass: _StyledPhoneInput2.default.dropdowninputphone,
                buttonClass: _StyledPhoneInput2.default.buttonClassinputphone,
                containerClass: _StyledPhoneInput2.default.containerinputphone + ' ' + (disabled ? _StyledPhoneInput2.default.noclick : ''),
                countryCodeEditable: false
            });
        }
    }]);
    return StyledPhoneInput;
}(_react2.default.Component)) || _class);
exports.default = StyledPhoneInput;
module.exports = exports['default'];