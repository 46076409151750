'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = undefined;

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _LinearProgress = require('@material-ui/core/LinearProgress');

var _LinearProgress2 = _interopRequireDefault(_LinearProgress);

var _numbro = require('numbro');

var _numbro2 = _interopRequireDefault(_numbro);

var _BalanceInfo = require('./BalanceInfo.scss');

var _BalanceInfo2 = _interopRequireDefault(_BalanceInfo);

var _themeEnabled = require('helpers/themeEnabled');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var BalanceInfo = function (_Component) {
    (0, _inherits3.default)(BalanceInfo, _Component);

    function BalanceInfo(props) {
        (0, _classCallCheck3.default)(this, BalanceInfo);

        var _this = (0, _possibleConstructorReturn3.default)(this, (BalanceInfo.__proto__ || (0, _getPrototypeOf2.default)(BalanceInfo)).call(this, props));

        _this.balance = props.balance;
        return _this;
    }

    (0, _createClass3.default)(BalanceInfo, [{
        key: 'f',
        value: function f(n) {
            return (0, _numbro2.default)(n).format();
        }
    }, {
        key: 'render',
        value: function render() {

            var balance = this.props.balance.balance ? this.props.balance.balance : 0;
            var limit = this.props.balance.limit ? this.props.balance.limit : 0;
            var valid_thru = this.props.balance.valid_thru ? this.props.balance.valid_thru : 0;
            if (typeof balance != 'number') balance = parseInt(balance);
            if (typeof limit != 'number') limit = parseInt(limit);

            var consumed = limit - balance;
            var percentConsumed = consumed / limit * 100 || 0;
            var percentBalance = 100 - percentConsumed || 0;

            //Agrega tema a graficas
            var classesTheme = (0, _themeEnabled.getThemeEnabledStyle)(_BalanceInfo2.default);

            return _react2.default.createElement(
                'div',
                { className: classesTheme },
                _react2.default.createElement(
                    'div',
                    { className: _BalanceInfo2.default.balanceInfoContainer },
                    _react2.default.createElement(
                        'div',
                        { className: _BalanceInfo2.default.r1c1 },
                        'Disponibles'
                    ),
                    _react2.default.createElement(
                        'div',
                        { className: _BalanceInfo2.default.r1c2 },
                        'Consumidos'
                    ),
                    _react2.default.createElement(
                        'div',
                        { className: _BalanceInfo2.default.r2c1 },
                        this.f(balance)
                    ),
                    _react2.default.createElement(
                        'div',
                        { className: _BalanceInfo2.default.r2c2 },
                        this.f(consumed)
                    ),
                    _react2.default.createElement(
                        'div',
                        { className: _BalanceInfo2.default.r3c1 },
                        percentBalance.toFixed(2),
                        '%'
                    ),
                    _react2.default.createElement(
                        'div',
                        { className: _BalanceInfo2.default.r3c2 },
                        percentConsumed.toFixed(2),
                        '%'
                    ),
                    _react2.default.createElement(
                        'div',
                        { className: _BalanceInfo2.default.stacked_bar },
                        _react2.default.createElement(_LinearProgress2.default, {
                            variant: 'determinate',
                            classes: {
                                root: _BalanceInfo2.default.progressBarBackground,
                                bar: _BalanceInfo2.default.progressBar
                            },
                            value: percentBalance
                        })
                    ),
                    _react2.default.createElement(
                        'div',
                        { className: _BalanceInfo2.default.r4c1 },
                        'Fecha de expiraci\xF3n: '
                    ),
                    _react2.default.createElement(
                        'div',
                        { className: _BalanceInfo2.default.r4c2 },
                        'Contratados: ',
                        this.f(limit)
                    ),
                    _react2.default.createElement(
                        'div',
                        { className: _BalanceInfo2.default.r5c1 },
                        valid_thru
                    ),
                    _react2.default.createElement(
                        'div',
                        { className: _BalanceInfo2.default.r5c2 },
                        'L\xEDmite de cr\xE9dito: ',
                        this.f(limit)
                    )
                )
            );
        }
    }]);
    return BalanceInfo;
}(_react.Component);

exports.default = BalanceInfo;
module.exports = exports['default'];