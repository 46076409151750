'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var RadioButton = function RadioButton(_ref) {
    var checked = _ref.checked,
        handleChange = _ref.handleChange,
        name = _ref.name;

    try {
        var styles = require('./RadioButton.scss');
        return _react2.default.createElement(
            'div',
            { className: styles.radioButtonContainer },
            _react2.default.createElement('input', { type: 'checkbox', id: name, defaultChecked: checked, onChange: function onChange(event) {
                    return handleChange({ name: event.target.id });
                } }),
            _react2.default.createElement(
                'label',
                { 'for': name },
                _react2.default.createElement(
                    'div',
                    { className: styles.switch },
                    _react2.default.createElement('div', { className: styles.dot })
                )
            )
        );
    } catch (error) {
        console.error(error);
    }
};

exports.default = RadioButton;
module.exports = exports['default'];