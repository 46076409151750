'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _getOwnPropertyDescriptor = require('babel-runtime/core-js/object/get-own-property-descriptor');

var _getOwnPropertyDescriptor2 = _interopRequireDefault(_getOwnPropertyDescriptor);

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _desc, _value, _class;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Report = require('./Report.scss');

var _Report2 = _interopRequireDefault(_Report);

var _coreDecorators = require('core-decorators');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
  var desc = {};
  Object['ke' + 'ys'](descriptor).forEach(function (key) {
    desc[key] = descriptor[key];
  });
  desc.enumerable = !!desc.enumerable;
  desc.configurable = !!desc.configurable;

  if ('value' in desc || desc.initializer) {
    desc.writable = true;
  }

  desc = decorators.slice().reverse().reduce(function (desc, decorator) {
    return decorator(target, property, desc) || desc;
  }, desc);

  if (context && desc.initializer !== void 0) {
    desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
    desc.initializer = undefined;
  }

  if (desc.initializer === void 0) {
    Object['define' + 'Property'](target, property, desc);
    desc = null;
  }

  return desc;
}

var md = {
  table: require('react-icons/lib/md/grid-on')
};

var Report = (_class = function (_React$Component) {
  (0, _inherits3.default)(Report, _React$Component);

  function Report(props) {
    (0, _classCallCheck3.default)(this, Report);

    var _this = (0, _possibleConstructorReturn3.default)(this, (Report.__proto__ || (0, _getPrototypeOf2.default)(Report)).call(this, props));

    _this.state = {
      chart: true
    };

    _this.kibana = 'es01-csm.beta.im.local';
    _this.refreshIntervalMillis = 60000;

    var accountUid = props.accountUid,
        startDate = props.startDate,
        endDate = props.endDate,
        chartId = props.chartId,
        tableId = props.tableId,
        customQuery = props.customQuery;

    var time = 'time:(from:\'' + startDate.format() + '\',mode:absolute,to:\'' + endDate.format() + '\')';
    // enable autorefresh of charts
    // const refreshInterval = `refreshInterval:('$$hashKey':'${chartId}:${accountUid}',pause:!f,value:${this.refreshIntervalMillis})`
    var refreshInterval = 'refreshInterval:(\'$$hashKey\':\'object:555\',display:Off,pause:!f,section:0,value:0)';

    var g = 'g=(' + time + ',' + refreshInterval + ')';
    // enable account filtering. THIS MUST BE ENABLED FOR PRODUCTION, ALSO THIS NEEDS SECURITY OF SOME SORT
    // idea, use the internal express proxy to filter requests
    // const a = `a=(query:(query_string:(analyze_wildcard:!t,query:'account_uid: ${accountUid} ${customQuery}')`
    var a = 'a=()';
    _this.chart = 'http://' + _this.kibana + '/app/kibana#/visualize/edit/' + chartId + '?embed=true&_' + g + '&_' + a;
    _this.table = 'http://' + _this.kibana + '/app/kibana#/visualize/edit/' + tableId + '?embed=true&_' + g + '&_' + a;
    return _this;
  }

  (0, _createClass3.default)(Report, [{
    key: 'stopPropagation',
    value: function stopPropagation(event) {
      event.preventDefault();
      event.stopPropagation();
    }
  }, {
    key: 'handleDisplayChange',
    value: function handleDisplayChange() {
      this.setState((0, _extends3.default)({}, this.state, {
        chart: !this.state.chart
      }));
    }
  }, {
    key: 'renderChart',
    value: function renderChart() {
      return _react2.default.createElement('iframe', { src: this.chart });
    }
  }, {
    key: 'renderTable',
    value: function renderTable() {
      return _react2.default.createElement('iframe', { src: this.table });
    }
  }, {
    key: 'render',
    value: function render() {
      return _react2.default.createElement(
        'div',
        { className: _Report2.default.report },
        _react2.default.createElement(
          'div',
          { onClick: this.handleDisplayChange.bind(this) },
          _react2.default.createElement(md.table, { size: 30, color: '#848E96' })
        ),
        _react2.default.createElement(
          'div',
          { onClick: this.stopPropagation.bind(this), className: _Report2.default.iframes },
          this.state.chart && this.renderChart(),
          !this.state.chart && this.renderTable()
        )
      );
    }
  }]);
  return Report;
}(_react2.default.Component), (_applyDecoratedDescriptor(_class.prototype, 'renderChart', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class.prototype, 'renderChart'), _class.prototype), _applyDecoratedDescriptor(_class.prototype, 'renderTable', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class.prototype, 'renderTable'), _class.prototype)), _class);
exports.default = Report;
module.exports = exports['default'];