'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = undefined;

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _dec2, _class, _class2, _temp;

var _components = require('components');

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _reduxConnect = require('redux-connect');

var _reactRedux = require('react-redux');

var _status = require('redux/modules/status');

var _themeEnabled = require('helpers/themeEnabled');

var _reports = require('redux/modules/reports');

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _numbro = require('numbro');

var _numbro2 = _interopRequireDefault(_numbro);

var _reachHistory = require('../../helpers/reachHistory');

var _sessionPermissions = require('../../helpers/sessionPermissions');

var _MessageByUsers = require('./MessageByUsers.scss');

var _MessageByUsers2 = _interopRequireDefault(_MessageByUsers);

var _numberFormat = require('../../helpers/numberFormat');

var _numberFormat2 = _interopRequireDefault(_numberFormat);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var md = {
    pieChart: require('react-icons/lib/md/pie-chart'),
    download: require('react-icons/lib/md/arrow-downward')
};
var TrafficByUser = (_dec = (0, _reduxConnect.asyncConnect)([{
    key: 'account_status',
    promise: function promise(_ref) {
        var _ref$store = _ref.store,
            dispatch = _ref$store.dispatch,
            getState = _ref$store.getState;

        var state = getState();
        if (!state.status.data.account_id) {
            return dispatch((0, _status.getAccountStatus)());
        }
        return null;
    }
}]), _dec2 = (0, _reactRedux.connect)(function (state) {
    return {
        account_status: state.status.data,
        account: state.auth

    };
}, {
    getTrafficByUser: _reports.getTrafficByUser,
    push: _reachHistory.push
}), _dec(_class = _dec2(_class = (_temp = _class2 = function (_React$Component) {
    (0, _inherits3.default)(TrafficByUser, _React$Component);

    function TrafficByUser(props) {
        (0, _classCallCheck3.default)(this, TrafficByUser);

        var _this = (0, _possibleConstructorReturn3.default)(this, (TrafficByUser.__proto__ || (0, _getPrototypeOf2.default)(TrafficByUser)).call(this, props));

        _this.state = {
            subaccounts: [{ _id: _this.props.account.account_uid, name: _this.props.account.name }],
            data: [],
            cycle_buttons: [{ value: 0, name: "Ciclo actual", status: "active" }, { value: -1, name: "Último ciclo", status: "" }, { value: -2, name: "Penúltimo ciclo", status: "" }, { value: -3, name: "Antepenúltimo ciclo", status: "" }],
            accountStatus: _this.props.account_status,
            account_type: _this.props.account_status.account_type,
            startDate: _this.props.account_status.current_package.valid_since,
            endDate: _this.props.account_status.current_package.valid_thru
        };
        return _this;
    }

    (0, _createClass3.default)(TrafficByUser, [{
        key: 'setButtonActive',
        value: function setButtonActive(value) {
            var buttons = this.state.cycle_buttons;
            buttons.forEach(function (button) {
                if (button.value == value) {
                    button.status = "active";
                } else {
                    button.status = "";
                }
            });
            this.setState({
                cycle_buttons: buttons
            });
        }
    }, {
        key: 'updateReport',
        value: function updateReport(cycle) {
            var _props$account_status = this.props.account_status.current_package,
                valid_since = _props$account_status.valid_since,
                valid_thru = _props$account_status.valid_thru;

            var start = (0, _moment2.default)(valid_since).add(cycle, 'months');
            var end = (0, _moment2.default)(valid_thru).add(cycle, 'months');

            this.getTrafficByUser(start, end, cycle);
        }
    }, {
        key: 'getTrafficByUser',
        value: function getTrafficByUser(dateStart, dateEnd, cycle) {
            var _this2 = this;

            var params = {
                valid_since: (0, _moment2.default)(dateStart),
                valid_thru: (0, _moment2.default)(dateEnd),
                accounts: this.state.subaccounts.map(function (ids) {
                    return ids._id;
                }),
                account_type: this.props.account_status.account_type
            };
            this.props.getTrafficByUser(params).then(function (values) {
                values.map(function (user) {
                    user.delivered.value = (0, _numberFormat2.default)(user.delivered.value, 0);
                    user.error.value = (0, _numberFormat2.default)(user.error.value, 0);
                    user.doc_count = (0, _numberFormat2.default)(user.doc_count, 0);
                });
                _this2.setState({ data: values });
                _this2.setButtonActive(cycle);
            });
        }
    }, {
        key: 'handleInformation',
        value: function handleInformation() {
            console.log("los props", this.props);

            var _props$account_status2 = this.props.account_status.current_package,
                valid_since = _props$account_status2.valid_since,
                valid_thru = _props$account_status2.valid_thru;


            this.getTrafficByUser(valid_since, valid_thru, 0);
        }
    }, {
        key: 'componentWillMount',
        value: function componentWillMount() {
            this.handleInformation();
        }
    }, {
        key: 'renderCycleButtons',
        value: function renderCycleButtons() {
            var _this3 = this;

            return _react2.default.createElement(
                'div',
                { className: _MessageByUsers2.default.cycleContainer },
                this.state.cycle_buttons.map(function (button) {
                    return _react2.default.createElement(
                        'div',
                        { key: button.value, className: button.status == "active" ? _MessageByUsers2.default.cycleButtonActive : _MessageByUsers2.default.cycleButtonNormal,
                            onClick: function onClick() {
                                return _this3.updateReport(button.value);
                            } },
                        button.name
                    );
                })
            );
        }
    }, {
        key: 'formatNumber',
        value: function formatNumber(n) {
            return (0, _numbro2.default)(n).format('3a');
        }
    }, {
        key: 'getLink',
        value: function getLink(item) {
            return {
                pathname: '/short_link/' + item._id,
                state: { modal: true, returnTo: '/short_link' }
            };
        }
    }, {
        key: 'renderChart',
        value: function renderChart() {
            var assets = (0, _themeEnabled.getAssetStyle)();
            var kpis = this.state.data.map(function (value) {
                return { name: value.key, y: parseInt(value.doc_count.replace(',', '')) };
            });
            var values = [{
                "showInLegend": false,
                "data": kpis,
                "dataLabels": {
                    "enabled": true,
                    "color": 'black',
                    "format": '{point.y: ,.0f}',
                    "style": {
                        "fontSize": '16px',
                        "fontFamily": 'Verdana, sans-serif'
                    }
                },
                "color": assets.colorChart
            }];
            return _react2.default.createElement(
                'div',
                { className: _MessageByUsers2.default.chart },
                _react2.default.createElement(_components.GenericBarChart, { series: values, height: 240, title: '', titley: "Total Enviados", tooltip: false })
            );
        }
    }, {
        key: 'renderTable',
        value: function renderTable() {
            var columns = [{
                Header: "Usuario",
                accessor: 'key',
                width: '1f'
            }, {
                Header: "Entregados",
                accessor: 'delivered.value',
                width: '1f'
            }, {
                Header: "No Entregados",
                accessor: 'error.value',
                width: '1f'
            }, {
                Header: "Total Enviados",
                accessor: 'total.value',
                width: '1f'
            }];

            return _react2.default.createElement(
                'div',
                { className: _MessageByUsers2.default.table },
                _react2.default.createElement(_components.Table, {
                    className: _MessageByUsers2.default.table,
                    data: this.state.data,
                    columns: columns,
                    isLoading: false
                })
            );
        }
    }, {
        key: 'renderContent',
        value: function renderContent() {
            return _react2.default.createElement(
                'div',
                { className: _MessageByUsers2.default.report },
                _react2.default.createElement(
                    'div',
                    { className: _MessageByUsers2.default.reportContent },
                    _react2.default.createElement(
                        'div',
                        { className: _MessageByUsers2.default.content },
                        this.renderChart()
                    )
                ),
                _react2.default.createElement(
                    'div',
                    { className: _MessageByUsers2.default.reportContent },
                    _react2.default.createElement(
                        'div',
                        { className: _MessageByUsers2.default.content },
                        this.renderTable()
                    )
                )
            );
        }
    }, {
        key: 'render',
        value: function render() {

            return _react2.default.createElement(
                'div',
                null,
                _react2.default.createElement(
                    'div',
                    { className: _MessageByUsers2.default.bar },
                    _react2.default.createElement(
                        'div',
                        { className: _MessageByUsers2.default.icon },
                        _react2.default.createElement(md.pieChart, { size: 20, color: '#FFF' })
                    ),
                    _react2.default.createElement(
                        'div',
                        { className: _MessageByUsers2.default.title },
                        'Reporte General de env\xEDos por usuario'
                    )
                ),
                _react2.default.createElement(
                    'div',
                    { className: _MessageByUsers2.default.body },
                    this.renderCycleButtons(),
                    this.renderContent()
                )
            );
        }
    }]);
    return TrafficByUser;
}(_react2.default.Component), _class2.propTypes = {
    account: _propTypes2.default.object
}, _temp)) || _class) || _class);
exports.default = TrafficByUser;
module.exports = exports['default'];