'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ContactsFiles = exports.ContactsTags = exports.default = exports.ContactTabs = undefined;

var _getOwnPropertyDescriptor = require('babel-runtime/core-js/object/get-own-property-descriptor');

var _getOwnPropertyDescriptor2 = _interopRequireDefault(_getOwnPropertyDescriptor);

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _class, _desc, _value, _class2, _class3, _temp, _dec2, _class4, _class5, _temp2, _dec3, _class6, _class7, _temp3, _dec4, _class8, _desc2, _value2, _class9;

var _components = require('components');

var _contacts = require('redux/modules/contacts');

var _files = require('redux/modules/files');

var _unread = require('redux/modules/unread');

var _tags = require('redux/modules/tags');

var _variables = require('redux/modules/variables');

var _Allow = require('../Allow/Allow');

var _Allow2 = _interopRequireDefault(_Allow);

var _reactHelmet = require('react-helmet');

var _reactHelmet2 = _interopRequireDefault(_reactHelmet);

var _reactRouter = require('react-router');

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _coreDecorators = require('core-decorators');

var _reactRedux = require('react-redux');

var _themeEnabled = require('helpers/themeEnabled');

var _mobile = require('redux/modules/mobile');

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _reachHistory = require('../../helpers/reachHistory');

var _Contacts = require('./Contacts.scss');

var _Contacts2 = _interopRequireDefault(_Contacts);

var _useBasename = require('../../helpers/useBasename');

var _useBasename2 = _interopRequireDefault(_useBasename);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
  var desc = {};
  Object['ke' + 'ys'](descriptor).forEach(function (key) {
    desc[key] = descriptor[key];
  });
  desc.enumerable = !!desc.enumerable;
  desc.configurable = !!desc.configurable;

  if ('value' in desc || desc.initializer) {
    desc.writable = true;
  }

  desc = decorators.slice().reverse().reduce(function (desc, decorator) {
    return decorator(target, property, desc) || desc;
  }, desc);

  if (context && desc.initializer !== void 0) {
    desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
    desc.initializer = undefined;
  }

  if (desc.initializer === void 0) {
    Object['define' + 'Property'](target, property, desc);
    desc = null;
  }

  return desc;
}

var md = {
  search: require('react-icons/lib/md/search'),
  person: require('react-icons/lib/md/person'),
  personAdd: require('react-icons/lib/md/person-add'),
  message: require('react-icons/lib/md/email'),
  send: require('react-icons/lib/md/send'),
  delete: require('react-icons/lib/md/delete'),
  tag: require('react-icons/lib/md/label'),
  upload: require('react-icons/lib/md/file-upload'),
  moreVert: require('react-icons/lib/md/more-vert'),
  gesture: require('react-icons/lib/md/label-outline'),
  file: require('react-icons/lib/md/attachment'),
  carretClose: require('react-icons/lib/md/keyboard-arrow-right'),
  carretOpen: require('react-icons/lib/md/keyboard-arrow-down')
  // filter: require('react-icons/lib/md/filter-list')
};

var ContactTabs = exports.ContactTabs = function ContactTabs(props) {
  return _react2.default.createElement(_components.Tabs, {
    simple: true,
    options: [{
      href: '/contacts/',
      title: (0, _translations2.default)('CONTACTS'),
      is_index: true
    }, {
      href: '/contacts/tags',
      title: (0, _translations2.default)('MY TAGS')
    }, {
      href: '/contacts/profiles/',
      title: (0, _translations2.default)('MY PROFILING')
    }, {
      href: '/contacts/files',
      title: (0, _translations2.default)('FILES')
    }]
  });
};

var Contacts = (_dec = (0, _reactRedux.connect)(function (state) {
  return {
    mobileMode: state.mobile.mobileMode,
    selected: state.contacts.selected,
    list: state.contacts.list,
    isLoading: state.contacts.loading,
    unread: state.unread
  };
}, {
  get: _contacts.getContacts,
  select: _contacts.contactSelect,
  unselect: _contacts.contactUnselect,
  refresh: _contacts.contactsRefresh,
  contactsUnselectAll: _contacts.contactsUnselectAll,
  deleteContacts: _contacts.deleteContacts,
  modal: _mobile.modal,
  push: _reachHistory.push
}), _dec(_class = (_class2 = (_temp = _class3 = function (_List) {
  (0, _inherits3.default)(Contacts, _List);

  function Contacts(props) {
    (0, _classCallCheck3.default)(this, Contacts);

    var _this = (0, _possibleConstructorReturn3.default)(this, (Contacts.__proto__ || (0, _getPrototypeOf2.default)(Contacts)).call(this, props));

    _this.title = (0, _translations2.default)('MY CONTACTS');
    if (__CLIENT__) {
      var lastShown = window.localStorage.getItem('wizard.contacts');
      if (!lastShown) {
        window.localStorage.setItem('wizard.contacts', (0, _moment2.default)().valueOf());

        setTimeout(function () {
          _this.props.push({
            pathname: '/contacts/',
            state: {
              modal: true,
              modalSize: { width: '846px', height: '580px' },
              returnTo: '/contacts/'
            }
          });
        }, 500);
      }
    }
    return _this;
  }

  (0, _createClass3.default)(Contacts, [{
    key: 'deleteSelected',
    value: function deleteSelected() {
      var _this2 = this;

      var ids = this.props.selected.map(function (pos) {
        return _this2.props.list[pos].id;
      });
      this.props.deleteContacts(ids).then(function () {
        _this2.beginSearch('');
      });
    }
  }, {
    key: 'sendSelected',
    value: function sendSelected() {
      var _this3 = this;

      this.props.push({
        pathname: '/contacts/addMessage',
        state: {
          modal: true,
          modalSize: { height: '500px', width: '575px' },
          returnTo: '/contacts/',
          contactsSelected: this.props.selected.map(function (index) {
            return _this3.props.list[index];
          })
        }
      });
    }
  }, {
    key: 'addTagsToSelected',
    value: function addTagsToSelected() {
      var _this4 = this;

      this.props.push({
        pathname: '/contacts/addTags',
        state: {
          modal: true,
          modalSize: { height: '500px', width: '830px' },
          returnTo: '/contacts/',
          // contactsSelected: this.props.list.filter((contact, index) => (this.props.selected.indexOf(index) > -1))
          contactsSelected: this.props.selected.map(function (index) {
            return _this4.props.list[index];
          })
        }
      });
    }
  }, {
    key: 'renderNav',
    value: function renderNav() {
      if (this.isSelecting()) {
        var countMessage = this.props.selected.length === 1 ? (0, _translations2.default)('CONTACT SELECTED') : (0, _translations2.default)('CONTACTS SELECTED');
        return _react2.default.createElement(
          _components.ContextNav,
          {
            key: 'ContextNav',
            selectedElementsCount: '' + this.props.selected.length,
            unselect: this.props.contactsUnselectAll,
            style: { height: 'auto', marginTop: '2px;', marginBottom: '2px;' }
          },
          _react2.default.createElement(
            _reactRouter.Link,
            { className: 'action left', onClick: this.sendSelected, style: { border: '1px solid white', borderRadius: '5px' } },
            _react2.default.createElement(md.send, { size: 15 }),
            (0, _translations2.default)('MESSAGE')
          ),
          _react2.default.createElement(
            _reactRouter.Link,
            { className: 'action left', onClick: this.addTagsToSelected,
              style: { border: '1px solid white', borderRadius: '5px' } },
            _react2.default.createElement(md.tag, { size: 15 }),
            (0, _translations2.default)('TAG')
          ),
          _react2.default.createElement(
            _Allow2.default,
            { permissions: ['contacts:delete'] },
            _react2.default.createElement(
              _reactRouter.Link,
              { className: 'action left', onClick: this.deleteSelected, style: { border: '1px solid white', borderRadius: '5px' } },
              _react2.default.createElement(md.delete, { size: 15 }),
              (0, _translations2.default)('DELETE')
            )
          )
        );
      }
      return _react2.default.createElement(
        _components.Nav,
        { key: 'Nav', title: (0, _translations2.default)('MY CONTACTS') },
        _react2.default.createElement(
          'div',
          { className: 'search' },
          _react2.default.createElement('input', {
            type: 'text',
            placeholder: (0, _translations2.default)('SEARCH PLACEHOLDER'),
            value: this.state.search || '',
            ref: 'search',
            onChange: this.handleSearch,
            onKeyPress: this.handleSearch
          }),
          !this.state.search && _react2.default.createElement(md.search, { size: 16 }),
          _react2.default.createElement(
            'span',
            { className: 'clear', onClick: this.handleClear, style: this.state.search ? {} : { display: 'none' } },
            '\xD7'
          )
        )
      );
    }
  }, {
    key: 'renderTabs',
    value: function renderTabs() {
      return _react2.default.createElement(ContactTabs, null);
    }
  }, {
    key: 'renderExtra',
    value: function renderExtra() {
      if (!this.props.unread.list.length && !this.props.unread.data.unread) {
        return null;
      }
      return [_react2.default.createElement(ContactsWithUnreads, (0, _extends3.default)({ key: 'contacts-with-unreads' }, this.props)), _react2.default.createElement('br', { key: 'br1' }), _react2.default.createElement('br', { key: 'br2' }), _react2.default.createElement(
        'div',
        { key: 'fakeheader', className: _Contacts2.default.minititle },
        (0, _translations2.default)("ALL CONTACTS")
      )];
    }
  }, {
    key: 'renderListHeader',
    value: function renderListHeader() {
      var classesTheme = (0, _themeEnabled.getThemeEnabledStyle)(_Contacts2.default);
      return _react2.default.createElement(
        'div',
        { className: classesTheme },
        _react2.default.createElement(
          'div',
          { className: _Contacts2.default.listheader },
          _react2.default.createElement('div', { className: _Contacts2.default.handle }),
          _react2.default.createElement(
            'div',
            { className: _Contacts2.default.added },
            'Agregado'
          ),
          _react2.default.createElement(
            'div',
            { className: _Contacts2.default.msisdn },
            'Identificadores'
          ),
          _react2.default.createElement(
            'div',
            { className: _Contacts2.default.name },
            'Nombre'
          ),
          _react2.default.createElement(
            'div',
            { className: _Contacts2.default.token },
            'Token'
          ),
          _react2.default.createElement(
            'div',
            { className: _Contacts2.default.tag },
            'Etiquetas'
          ),
          _react2.default.createElement(
            'div',
            { className: _Contacts2.default.search },
            this.renderNav()
          )
        )
      );
    }
  }, {
    key: 'renderInfiniteList',
    value: function renderInfiniteList() {
      var _this5 = this;

      var hidden = { display: 'none' };
      var visible = { display: 'block' };
      var infiniteStyle = this.props.isLoading || this.props.list.length > 0 ? visible : hidden;
      var linkstyle = this.props.isLoading || this.props.list.length > 0 ? hidden : visible;

      if (__SERVER__) {
        return null; // <VirtualListItemLoad key="virtual-list-item-load"/>
      }

      return [_react2.default.createElement(
        'div',
        { key: 'contacts-infinite', style: infiniteStyle },
        _react2.default.createElement(_reactHelmet2.default, { title: (0, _translations2.default)('MANAGE_CONTACTS') }),
        _react2.default.createElement(
          _components.Infinite,
          {
            refresh: this.refreshRows,
            next: this.loadMoreRows,
            hasMore: this.state.hasMoreRows
          },
          this.props.list.map(function (item, index) {
            return _react2.default.createElement(_components.VirtualListItemContact, {
              profile: item.profile,
              key: item.id,
              index: index,
              sms: false,
              name: function () {
                var name = item.profile.first_name ? item.profile.first_name.value : '';
                var lastname = item.profile.last_name ? item.profile.last_name.value : '';
                return name + ' ' + lastname;
              }(),
              createdOn: item.created_on,
              msisdn: { msisdn: item.msisdn, carrier: item.carrier },
              token: item.push_tokens,
              tags: item.tags,
              mobileMode: _this5.props.mobileMode,
              icon: _react2.default.createElement(_components.ContactIcon, { profile: item.profile }),
              active: item.active || false,
              setActive: _this5.setActive.bind(_this5),
              link: {
                pathname: '/contacts/' + item.id,
                state: { modal: !_this5.props.mobileMode, returnTo: _this5.props.location.pathname }
              },
              onClick: function onClick(tag, event) {
                // actually, onTagClick
                event.preventDefault();
                _this5.props.push('/contacts?search=tags:' + tag);
                _this5.beginSearch('tags:' + tag);
              },
              selecting: _this5.isSelecting()
            });
          })
        )
      ), _react2.default.createElement(
        _Allow2.default,
        { permissions: ['contacts:create:individual'] },
        _react2.default.createElement(_components.Linkholder, {
          key: 'contacts-linkholder',
          style: linkstyle,
          to: {
            pathname: '/contacts/choose',
            state: { modal: true, modalSize: { width: '400px' }, returnTo: "/contacts" }
          },
          src: '/static/empty/contacts.svg',
          text: this.state.search === '' ? (0, _translations2.default)('NO CONTACTS FOUND') : (0, _translations2.default)('NO CONTACTS FOUND SEARCH'),
          button: (0, _translations2.default)('ADD A NEW CONTACT')
        })
      )];
    }
  }]);
  return Contacts;
}(_components.List), _class3.propTypes = {
  mobileMode: _propTypes2.default.bool,
  selected: _propTypes2.default.array.isRequired,
  list: _propTypes2.default.array.isRequired,
  isLoading: _propTypes2.default.bool.isRequired,
  get: _propTypes2.default.func.isRequired,
  select: _propTypes2.default.func.isRequired,
  unselect: _propTypes2.default.func.isRequired,
  refresh: _propTypes2.default.func.isRequired,
  contactsUnselectAll: _propTypes2.default.func.isRequired,
  deleteContacts: _propTypes2.default.func.isRequired,
  location: _propTypes2.default.object.isRequired,
  push: _propTypes2.default.func.isRequired
}, _temp), (_applyDecoratedDescriptor(_class2.prototype, 'deleteSelected', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'deleteSelected'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, 'sendSelected', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'sendSelected'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, 'addTagsToSelected', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'addTagsToSelected'), _class2.prototype)), _class2)) || _class);
exports.default = Contacts;
var ContactsTags = exports.ContactsTags = (_dec2 = (0, _reactRedux.connect)(function (state) {
  return {
    mobileMode: state.mobile.mobileMode,
    selected: state.tags.selected,
    list: state.tags.list,
    isLoading: state.tags.loading
  };
}, {
  get: _tags.getTagsRefactor,
  select: _tags.tagsSelect,
  unselect: _tags.tagsUnselect,
  refresh: _tags.tagsRefresh,
  tagsUnselectAll: _tags.tagsUnselectAll,
  modal: _mobile.modal,
  pushState: _reachHistory.push
}), _dec2(_class4 = (_temp2 = _class5 = function (_List2) {
  (0, _inherits3.default)(ContactsTags, _List2);

  function ContactsTags(props) {
    (0, _classCallCheck3.default)(this, ContactsTags);

    var _this6 = (0, _possibleConstructorReturn3.default)(this, (ContactsTags.__proto__ || (0, _getPrototypeOf2.default)(ContactsTags)).call(this, props));

    _this6.title = (0, _translations2.default)('MY TAGS');
    return _this6;
  }

  (0, _createClass3.default)(ContactsTags, [{
    key: 'sort',
    value: function sort(a, b) {
      if (a.name < b.name) {
        return -1;
      } else if (a.name > b.name) {
        return 1;
      }
      return 0;
    }
  }, {
    key: 'renderNav',
    value: function renderNav() {
      if (this.isSelecting()) {
        return _react2.default.createElement(
          _components.ContextNav,
          { key: 'ContextNav',
            selectedElementsCount: this.props.selected.length + ' ' + (0, _translations2.default)('TAGS SELECTED'),
            unselect: this.props.tagsUnselectAll
          },
          _react2.default.createElement('div', null)
        );
      }
      return _react2.default.createElement(
        _components.Nav,
        { key: 'Nav', title: (0, _translations2.default)('MY TAGS') },
        _react2.default.createElement(
          'div',
          { className: 'search' },
          _react2.default.createElement('input', { type: 'text',
            placeholder: (0, _translations2.default)('SEARCH PLACEHOLDER'),
            ref: 'search',
            value: this.state.search,
            onChange: this.handleSearch
          }),
          _react2.default.createElement(md.search, null),
          _react2.default.createElement(
            'span',
            { className: 'clear', onClick: this.handleClear.bind(this), style: this.state.search ? {} : { display: 'none' } },
            '\xD7'
          )
        )
      );
    }
  }, {
    key: 'renderTabs',
    value: function renderTabs() {
      return _react2.default.createElement(ContactTabs, null);
    }
  }, {
    key: 'renderListHeader',
    value: function renderListHeader() {
      var classesTheme = (0, _themeEnabled.getThemeEnabledStyle)(_Contacts2.default);
      return _react2.default.createElement(
        'div',
        { className: classesTheme },
        _react2.default.createElement(
          'div',
          { className: _Contacts2.default.listheader },
          _react2.default.createElement(_reactHelmet2.default, { title: "Listado de etiquetas" }),
          _react2.default.createElement('div', { className: _Contacts2.default.handle }),
          _react2.default.createElement(
            'div',
            { className: _Contacts2.default.tag },
            'Etiqueta'
          ),
          _react2.default.createElement(
            'div',
            { className: _Contacts2.default.name },
            'Contactos'
          ),
          _react2.default.createElement(
            'div',
            { className: _Contacts2.default.search },
            this.renderNav()
          )
        )
      );
    }
  }, {
    key: 'renderInfiniteList',
    value: function renderInfiniteList() {
      var _this7 = this;

      // actually not infinite
      var tagsList = this.props.list.filter(function (item) {
        if (!item.name) return false;
        return item.name.toUpperCase().indexOf(_this7.state.search.toUpperCase()) > -1;
      });
      var hidden = { display: 'none' };
      var visible = { display: 'block' };
      var infiniteStyle = this.props.isLoading || tagsList.length > 0 ? visible : hidden;
      var linkstyle = this.props.isLoading || tagsList.length > 0 ? hidden : visible;

      return [_react2.default.createElement(
        'div',
        { key: 'infinite-list', style: infiniteStyle },
        tagsList.sort(this.sort).map(function (item, index) {
          return _react2.default.createElement(_components.VirtualListItemTag, {
            key: index,
            index: index,
            icon: _react2.default.createElement(
              'span',
              null,
              'P'
            ),
            tag: item.name,
            count: item.count,
            mobileMode: _this7.props.mobileMode,
            active: item.active || false,
            setActive: _this7.setActive.bind(_this7),
            onClick: function onClick(tag, event) {
              // actually, onTagClick
              event.preventDefault();
              _this7.props.pushState('/contacts?search=tags:' + tag);
            }
          });
        })
      ), _react2.default.createElement(_components.Linkholder, {
        key: 'linkholder',
        style: linkstyle,
        to: {
          pathname: '/contacts/add',
          state: { modal: true, returnTo: '/contacts/' }
        },
        src: '/static/empty/tags.svg',
        text: this.state.search === '' ? (0, _translations2.default)('NO TAGS FOUND') : (0, _translations2.default)('NO TAGS FOUND SEARCH'),
        button: (0, _translations2.default)('ADD A NEW CONTACT TAGS')
      })];
    }
  }]);
  return ContactsTags;
}(_components.List), _class5.propTypes = {
  mobileMode: _propTypes2.default.bool,
  selected: _propTypes2.default.array.isRequired,
  list: _propTypes2.default.array.isRequired,
  isLoading: _propTypes2.default.bool.isRequired,
  get: _propTypes2.default.func.isRequired,
  select: _propTypes2.default.func.isRequired,
  unselect: _propTypes2.default.func.isRequired,
  tagsUnselectAll: _propTypes2.default.func.isRequired,
  refresh: _propTypes2.default.func.isRequired,
  pushState: _propTypes2.default.func.isRequired
}, _temp2)) || _class4);
var ContactsFiles = exports.ContactsFiles = (_dec3 = (0, _reactRedux.connect)(function (state) {
  return {
    mobileMode: state.mobile.mobileMode,
    list: state.files.list,
    isLoading: state.files.loading
  };
}, {
  get: _files.getContactsFiles,
  refresh: _files.filesRefresh,
  modal: _mobile.modal,
  push: _reachHistory.push
}), _dec3(_class6 = (_temp3 = _class7 = function (_List3) {
  (0, _inherits3.default)(ContactsFiles, _List3);

  function ContactsFiles() {
    (0, _classCallCheck3.default)(this, ContactsFiles);
    return (0, _possibleConstructorReturn3.default)(this, (ContactsFiles.__proto__ || (0, _getPrototypeOf2.default)(ContactsFiles)).apply(this, arguments));
  }

  (0, _createClass3.default)(ContactsFiles, [{
    key: 'renderNav',
    value: function renderNav() {
      return _react2.default.createElement(
        _components.Nav,
        { key: 'Nav', title: (0, _translations2.default)('CONTACT FILES') },
        _react2.default.createElement(
          'div',
          { className: 'search' },
          _react2.default.createElement('input', { type: 'text',
            placeholder: (0, _translations2.default)('SEARCH PLACEHOLDER'),
            value: this.state.search || '',
            ref: 'search',
            onChange: this.handleSearch,
            onKeyPress: this.handleSearch
          }),
          _react2.default.createElement(md.search, { size: 18 }),
          _react2.default.createElement(
            'span',
            { className: 'clear', onClick: this.handleClear, style: this.state.search ? {} : { display: 'none' } },
            '\xD7'
          )
        ),
        _react2.default.createElement(
          _reactRouter.Link,
          { className: 'action',
            to: {
              pathname: '/contacts/upload',
              state: { modal: true, returnTo: '/contacts/files', modalSize: { width: '830px', height: '500px' } }
            }
          },
          _react2.default.createElement(
            'div',
            { className: _Contacts2.default.txtImport },
            (0, _translations2.default)('IMPORT CONTACTS')
          ),
          _react2.default.createElement(md.person, { size: 20 })
        )
      );
    }
  }, {
    key: 'renderTabs',
    value: function renderTabs() {
      return _react2.default.createElement(ContactTabs, null);
    }
  }, {
    key: 'renderListHeader',
    value: function renderListHeader() {
      var classesTheme = (0, _themeEnabled.getThemeEnabledStyle)(_Contacts2.default);
      return _react2.default.createElement(
        'div',
        { className: classesTheme },
        _react2.default.createElement(
          'div',
          { className: _Contacts2.default.listheader },
          _react2.default.createElement('div', { className: _Contacts2.default.handle }),
          _react2.default.createElement(
            'div',
            { className: _Contacts2.default.added },
            'Estado'
          ),
          _react2.default.createElement(
            'div',
            { className: _Contacts2.default.msisdn },
            'Fecha'
          ),
          _react2.default.createElement(
            'div',
            { className: _Contacts2.default.name },
            'Identificador'
          ),
          _react2.default.createElement(
            'div',
            { className: _Contacts2.default.token },
            'Tipo'
          ),
          _react2.default.createElement(
            'div',
            { className: _Contacts2.default.tag },
            'Nombre del archivo'
          ),
          _react2.default.createElement(
            'div',
            { className: _Contacts2.default.search },
            this.renderNav()
          )
        )
      );
    }
  }, {
    key: 'renderInfiniteList',
    value: function renderInfiniteList() {
      if (__SERVER__) {
        return _react2.default.createElement(_components.VirtualListItemLoad, null);
      }

      return _react2.default.createElement(
        _components.Infinite,
        {
          refresh: this.refreshRows,
          next: this.loadMoreRows,
          hasMore: false
        },
        this.props.list.map(function (item, index) {
          console.log("ITEM", item);
          var original_file = undefined;
          var cleaned_file = undefined;
          var error_file = undefined;

          if (item.meta.cleaned_path && item.meta.error_path) {
            original_file = item.meta.path.substring(item.meta.path.lastIndexOf("/") + 1);
            cleaned_file = item.meta.cleaned_path.substring(item.meta.path.lastIndexOf("/") + 1);
            error_file = item.meta.error_path.substring(item.meta.path.lastIndexOf("/") + 1);
          }

          var values = [[(0, _translations2.default)('FILE CREATED ON'), (0, _moment2.default)(item.created_on).format('YYYY-MM-DD HH:mm')], [(0, _translations2.default)('CREATED BY'), item.created_by], [(0, _translations2.default)('ROWS FOUND'), item.stats ? item.stats.rows_found : null], [(0, _translations2.default)('ROWS PROCESSED'), item.stats ? item.stats.rows_processed : null], [(0, _translations2.default)('ROWS OK'), item.stats ? item.stats.rows_processed - item.stats.rows_duplicated - item.stats.rows_invalid : null], [(0, _translations2.default)('ROWS DUPLICATED'), item.stats ? item.stats.rows_duplicated : null], [(0, _translations2.default)('ROWS ERROR'), item.stats ? item.stats.rows_invalid : null], [(0, _translations2.default)('FILE STATUS'), item.status]];

          if (cleaned_file && error_file) {
            values.push([(0, _translations2.default)('DOWNLOAD FILE'), item.meta ? item._id ? item.name : (0, _translations2.default)("FILE NOT FOUND") : (0, _translations2.default)("FILE NOT FOUND"), item.meta ? false : true, (0, _useBasename2.default)() + '/downloads/message-files/' + (item ? item._id ? original_file : (0, _translations2.default)("FILE NOT FOUND") : null)], [(0, _translations2.default)('ERROR FILE'), (0, _translations2.default)('CLICK HERE TO DOWNLOAD ERROR FILE'), item ? item._id ? false : true : true, (0, _useBasename2.default)() + '/downloads/message-files/' + (item ? item._id ? error_file : null : null)], [(0, _translations2.default)('PROCESS FILE'), (0, _translations2.default)('CLICK HERE TO DOWNLOAD ERROR FILE'), item ? item._id ? false : true : true, (0, _useBasename2.default)() + '/downloads/message-files/' + (item ? item._id ? cleaned_file : null : null)]);
          }

          return _react2.default.createElement(_components.VirtualListItemFile, {
            key: index,
            index: index,
            name: item.name,
            date: item.created_on,
            status: item.status,
            fileType: item.identifier,
            link: {
              pathname: '/contacts/fileDetail',
              state: {
                modal: true,
                returnTo: '/contacts/files/',
                detail: {
                  title: (0, _translations2.default)('CONTACT FILE'),
                  subtitle: (0, _translations2.default)('LOADED ON') + ' ' + (0, _moment2.default)(item.created_on).format('DD/MM'),
                  content: item.name,
                  Icon: md.file,
                  values: values
                }
              }
            }
          });
        })
      );
    }
  }]);
  return ContactsFiles;
}(_components.List), _class7.propTypes = {
  mobileMode: _propTypes2.default.bool,
  list: _propTypes2.default.array.isRequired,
  isLoading: _propTypes2.default.bool.isRequired,
  get: _propTypes2.default.func.isRequired,
  refresh: _propTypes2.default.func.isRequired,
  location: _propTypes2.default.object.isRequired,
  push: _propTypes2.default.func.isRequired
}, _class7.defaultProps = {
  selected: []
}, _temp3)) || _class6);


var showNumMessages = 3;

var ContactsWithUnreads = (_dec4 = (0, _reactRedux.connect)(function (state) {
  return {
    isLoading: state.unread.loading
  };
}, {
  get: _unread.getContactsWithUnreads,
  refresh: _unread.unreadsRefresh,
  push: _reachHistory.push
}), _dec4(_class8 = (_class9 = function (_React$Component) {
  (0, _inherits3.default)(ContactsWithUnreads, _React$Component);

  function ContactsWithUnreads(props) {
    (0, _classCallCheck3.default)(this, ContactsWithUnreads);

    var _this9 = (0, _possibleConstructorReturn3.default)(this, (ContactsWithUnreads.__proto__ || (0, _getPrototypeOf2.default)(ContactsWithUnreads)).call(this, props));

    _this9.state = (0, _extends3.default)({}, _this9.state, {
      open: true,
      list: []
    });
    return _this9;
  }

  (0, _createClass3.default)(ContactsWithUnreads, [{
    key: 'componentWillMount',
    value: function componentWillMount() {
      var _this10 = this;

      setTimeout(function () {
        _this10.props.get().then(function (result) {
          _this10.setState((0, _extends3.default)({}, _this10.state, {
            list: result
          }));
        });
      });
    }
  }, {
    key: 'handleAccordeonToggle',
    value: function handleAccordeonToggle() {
      this.setState((0, _extends3.default)({}, this.state, {
        open: !this.state.open
      }));
    }
  }, {
    key: 'render',
    value: function render() {
      if (__SERVER__ || this.props.list.length === 0) {
        return null;
      }

      if (!this.state.open) {
        return _react2.default.createElement(
          'div',
          { className: _Contacts2.default.miniaccordeon, onClick: this.handleAccordeonToggle },
          _react2.default.createElement(
            'div',
            null,
            _react2.default.createElement(md.carretClose, null)
          ),
          _react2.default.createElement(
            'div',
            null,
            'Chats'
          )
        );
      }

      return [_react2.default.createElement(
        'div',
        { key: 'contacts-accordeon-open', className: _Contacts2.default.miniaccordeon, onClick: this.handleAccordeonToggle },
        _react2.default.createElement(
          'div',
          null,
          _react2.default.createElement(md.carretOpen, null)
        ),
        _react2.default.createElement(
          'div',
          null,
          'Chats'
        ),
        this.state.list.length > showNumMessages ? _react2.default.createElement(
          _reactRouter.Link,
          { to: '/messages/received', className: _Contacts2.default.more },
          'Ver todos los mensajes'
        ) : null
      ), _react2.default.createElement(
        'div',
        { className: _Contacts2.default.hasOwnShadow, key: 'messages-lsst' },
        this.state.list.slice(0, showNumMessages).map(function (item, index) {
          return _react2.default.createElement(_components.VirtualListItemInbox, {
            key: 'unread_' + item.profile._id,
            display: true,
            index: index,
            text: item.profile.msisdn,
            text2: (item.profile.first_name || '') + ' ' + (item.profile.last_name || ''),
            icon: _react2.default.createElement(md.message, { size: 30 }),
            num: item.unread,
            link: {
              pathname: '/contacts/' + item.profile.msisdn,
              state: { modal: true, returnTo: '/contacts/' }
            }
          });
        })
      )];
    }
  }]);
  return ContactsWithUnreads;
}(_react2.default.Component), (_applyDecoratedDescriptor(_class9.prototype, 'handleAccordeonToggle', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class9.prototype, 'handleAccordeonToggle'), _class9.prototype)), _class9)) || _class8);