'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ContactProfilesResults = undefined;

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _dec2, _class;

var _ProfilingResults2 = require('../Profiling/ProfilingResults');

var _ProfilingResults3 = _interopRequireDefault(_ProfilingResults2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _reduxConnect = require('redux-connect');

var _reactRedux = require('react-redux');

var _profiling = require('redux/modules/profiling');

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _reachHistory = require('../../helpers/reachHistory');

var _ProfilingResults4 = require('../Profiling/ProfilingResults.scss');

var _ProfilingResults5 = _interopRequireDefault(_ProfilingResults4);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var md = {
  assignment: require('react-icons/lib/md/assignment'),
  stop: require('react-icons/lib/md/stop'),
  copy: require('react-icons/lib/md/content-copy'),
  link: require('react-icons/lib/md/link'),
  download: require('react-icons/lib/md/cloud-download')
};

var ContactProfilesResults = exports.ContactProfilesResults = (_dec = (0, _reduxConnect.asyncConnect)([{
  key: 'results',
  promise: function promise(_ref) {
    var query = _ref.location.query,
        dispatch = _ref.store.dispatch;
    var uid = query.uid;

    if (uid) {
      return dispatch((0, _profiling.getProfilingResults)(uid));
    } else {
      return dispatch((0, _reachHistory.push)('/contacts/profiles/'));
    }
  }
}]), _dec2 = (0, _reactRedux.connect)(null, { push: _reachHistory.push }), _dec(_class = _dec2(_class = function (_ProfilingResults) {
  (0, _inherits3.default)(ContactProfilesResults, _ProfilingResults);

  function ContactProfilesResults(props) {
    (0, _classCallCheck3.default)(this, ContactProfilesResults);
    return (0, _possibleConstructorReturn3.default)(this, (ContactProfilesResults.__proto__ || (0, _getPrototypeOf2.default)(ContactProfilesResults)).call(this, props));
  }

  (0, _createClass3.default)(ContactProfilesResults, [{
    key: 'render',
    value: function render() {
      var interaction = this.props.interaction;


      var hidden = { display: 'none' };
      var visible = { display: 'block' };

      var scheduledFor = (0, _moment2.default)(this.props.results.scheduled_for);
      var runningUntil = (0, _moment2.default)(scheduledFor);
      var totalAnswers = this.props.results.workflow_units.map(function (u) {
        return u.name !== 'Invitación' && u.stats.answers ? u.stats.answers.total : 0;
      }).reduce(function (a, b) {
        return a + b;
      }, 0);

      return _react2.default.createElement(
        'div',
        { className: _ProfilingResults5.default.results },
        _react2.default.createElement(
          'div',
          { className: _ProfilingResults5.default.bar },
          _react2.default.createElement(
            'div',
            { className: _ProfilingResults5.default.icon },
            _react2.default.createElement(md.assignment, { size: 25, color: '#FFF' })
          ),
          _react2.default.createElement(
            'div',
            { className: _ProfilingResults5.default.title },
            _react2.default.createElement(
              'h1',
              null,
              this.props.results.job_description.name
            ),
            _react2.default.createElement(
              'h2',
              null,
              (0, _translations2.default)('CREATED ON') + ' ' + (0, _moment2.default)(interaction).format('MM/DD')
            )
          )
        ),
        _react2.default.createElement(
          'div',
          { className: _ProfilingResults5.default.main },
          _react2.default.createElement('div', { className: _ProfilingResults5.default.actions }),
          _react2.default.createElement(
            'div',
            { className: _ProfilingResults5.default.dates },
            _react2.default.createElement('div', { className: _ProfilingResults5.default.date }),
            _react2.default.createElement(
              'div',
              { className: _ProfilingResults5.default.date },
              _react2.default.createElement(
                'h1',
                null,
                (0, _translations2.default)('END DATE'),
                ': ',
                runningUntil === null ? (0, _translations2.default)('NO DATE') : runningUntil.format('DD/MM HH:mm')
              )
            )
          ),
          _react2.default.createElement(
            'div',
            { className: _ProfilingResults5.default.mainResults },
            this.renderResults()
          )
        )
      );
    }
  }]);
  return ContactProfilesResults;
}(_ProfilingResults3.default)) || _class) || _class);