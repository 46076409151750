'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _class, _class2, _temp;

var _auth = require('redux/modules/auth');

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _reactRedux = require('react-redux');

var _Login = require('./Login.scss');

var _Login2 = _interopRequireDefault(_Login);

var _components = require('components');

var _reachHistory = require('../../helpers/reachHistory');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var CallBack = (_dec = (0, _reactRedux.connect)(function (state) {
    return { auth: state.auth, isMobile: state.mobile.isMobile };
}, {
    loginOIDC: _auth.loginOIDC,
    loginSAML: _auth.loginSAML,
    pushState: _reachHistory.push
}), _dec(_class = (_temp = _class2 = function (_React$Component) {
    (0, _inherits3.default)(CallBack, _React$Component);

    function CallBack(props) {
        (0, _classCallCheck3.default)(this, CallBack);

        var _this = (0, _possibleConstructorReturn3.default)(this, (CallBack.__proto__ || (0, _getPrototypeOf2.default)(CallBack)).call(this, props));

        _this.state = {
            message: ''
        };
        _this.componentDidMount = _this.componentDidMount.bind(_this);
        return _this;
    }

    (0, _createClass3.default)(CallBack, [{
        key: 'componentDidMount',
        value: function componentDidMount() {
            var _this2 = this;

            var location = this.props.location;

            var code = new URLSearchParams(location.search).get('code');
            var samlResponse = new URLSearchParams(location.search).get('SAMLResponse');
            if (code) {
                this.props.loginOIDC(code).then(function (response) {
                    var current_account = response.account_list.find(function (a) {
                        return a.is_active;
                    });
                    if (current_account.account_type == "Reseller") {
                        _this2.props.pushState('/reseller/');
                    } else {
                        if (current_account.roles.indexOf('staff') > -1) {
                            _this2.props.pushState('/staff/messages/');
                        }
                        _this2.props.pushState('/dashboard');
                    }
                }).catch(function (e) {
                    _this2.setState((0, _extends3.default)({}, _this2.state, {
                        message: (0, _translations2.default)('USER NOT FOUND')
                    }));
                });
            } else if (samlResponse) {
                this.props.loginSAML(samlResponse).then(function (response) {
                    var current_account = response.account_list.find(function (a) {
                        return a.is_active;
                    });
                    if (current_account.account_type == "Reseller") {
                        _this2.props.pushState('/reseller/');
                    } else {
                        if (current_account.roles.indexOf('staff') > -1) {
                            _this2.props.pushState('/staff/messages/');
                        }
                        _this2.props.pushState('/dashboard');
                    }
                }).catch(function (e) {
                    _this2.setState((0, _extends3.default)({}, _this2.state, {
                        message: (0, _translations2.default)('USER NOT FOUND')
                    }));
                });
            }
        }
    }, {
        key: 'renderLoading',
        value: function renderLoading(styles) {
            return _react2.default.createElement(
                'div',
                { className: styles.loading },
                _react2.default.createElement(_components.Loading, null)
            );
        }
    }, {
        key: 'render',
        value: function render() {
            var auth = this.props.auth;

            return _react2.default.createElement(
                'div',
                { className: _Login2.default.loadingContainer },
                auth.loading ? this.renderLoading(_Login2.default) : ""
            );
        }
    }]);
    return CallBack;
}(_react2.default.Component), _class2.propTypes = {
    auth: _propTypes2.default.object,
    isMobile: _propTypes2.default.bool,
    loginSso: _propTypes2.default.func,
    pushState: _propTypes2.default.func
}, _temp)) || _class);
exports.default = CallBack;
module.exports = exports['default'];