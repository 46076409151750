'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = useBasename;

var _config = require('../config');

var _config2 = _interopRequireDefault(_config);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function useBasename() {
    var location = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';

    /*   if (!__DEVELOPMENT__) {
        return config.basename;
      }
      return '' */
    return !__DEVELOPMENT__ ? _config2.default.basename + location : location;
}
module.exports = exports['default'];