'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// import { Link } from 'react-router'


var ButtonModal = function ButtonModal(params) {
  var styles = require('./ButtonModal.scss');
  return _react2.default.createElement(
    'div',
    { className: styles.buttonModal },
    params.children
  );
};

ButtonModal.propTypes = {
  children: _propTypes2.default.array.isRequired
};

exports.default = ButtonModal;
module.exports = exports['default'];