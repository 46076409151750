'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = undefined;

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Templates = require('./Templates.scss');

var _Templates2 = _interopRequireDefault(_Templates);

var _reactRouter = require('react-router');

var _reactTable = require('react-table');

var _reactTable2 = _interopRequireDefault(_reactTable);

var _components = require('components');

var _CustomModal = require('./CustomModal');

var _CustomModal2 = _interopRequireDefault(_CustomModal);

var _Template = require('./Template1');

var _Template2 = _interopRequireDefault(_Template);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var md = {
    search: require('react-icons/lib/md/search'),
    delete: require('react-icons/lib/md/delete'),
    edit: require('react-icons/lib/md/edit')
};

var Templates = function (_React$Component) {
    (0, _inherits3.default)(Templates, _React$Component);

    function Templates(props) {
        (0, _classCallCheck3.default)(this, Templates);

        var _this = (0, _possibleConstructorReturn3.default)(this, (Templates.__proto__ || (0, _getPrototypeOf2.default)(Templates)).call(this, props));

        _this.actionsHandler = function (rowValue, type) {
            if (type == 'edit') {
                console.log('EDITAR');
            } else if (type == 'preview') {
                console.log('PREVIEW');
            } else if (type == 'delete') {
                console.log('ELIMINAR');
            }
        };

        _this.state = {
            templates: [],
            newTemplate: false
        };
        return _this;
    }

    (0, _createClass3.default)(Templates, [{
        key: 'componentDidMount',
        value: function componentDidMount() {
            this.renderTemplates();
        }
    }, {
        key: 'renderTemplates',
        value: function renderTemplates() {

            var temporal = this.state.templates;
            var data = ['TEMPLATE_BASICO', 'TEMPLATE_AVANZADO', 'TEMPLATE_PRO'];

            for (var key in data) {
                temporal.push(_react2.default.createElement(
                    'div',
                    { key: key, className: _Templates2.default.element },
                    _react2.default.createElement(
                        'h1',
                        null,
                        data[key]
                    ),
                    _react2.default.createElement(
                        _reactRouter.Link,
                        { to: '/dashboard' },
                        _react2.default.createElement('img', { src: 'https://notificame.claro.com.gt/static/img/nuevo-mensaje.png' })
                    )
                ));
            }
            this.setState({ templates: temporal });
        }
    }, {
        key: 'changeView',
        value: function changeView() {
            this.setState({ newTemplate: true });
        }
    }, {
        key: 'renderNewTemplate',
        value: function renderNewTemplate() {
            return _react2.default.createElement(_Template2.default, null);
        }
    }, {
        key: 'renderTemplateTable',
        value: function renderTemplateTable() {
            var _this2 = this;

            var columns = [{
                Header: 'Plantilla',
                accessor: 'name'
            }, {
                Header: 'Opciones',
                accesor: 'edit',
                Cell: function Cell(value) {
                    return _react2.default.createElement(
                        'div',
                        { className: _Templates2.default.cellGrid },
                        _react2.default.createElement(
                            'span',
                            { title: 'Editar' },
                            _react2.default.createElement(md.edit, { className: _Templates2.default.options, size: 25,
                                onClick: _this2.actionsHandler.bind(_this2, value.original, 'edit') })
                        ),
                        _react2.default.createElement(_CustomModal2.default, null),
                        _react2.default.createElement(
                            'span',
                            { title: 'Eliminar' },
                            _react2.default.createElement(md.delete, { className: _Templates2.default.options, size: 25,
                                onClick: _this2.actionsHandler.bind(_this2, value.original, 'delete') })
                        )
                    );
                }
            }];

            var data = [{
                id: '1',
                name: 'Plantilla 1'
            }, {
                id: '2',
                name: 'Plantilla 2'
            }, {
                id: '3',
                name: 'Plantilla 3'
            }, {
                id: '4',
                name: 'Plantilla 4'
            }, {
                id: '5',
                name: 'Plantilla 5'
            }, {
                id: '6',
                name: 'Plantilla 6'
            }];

            return _react2.default.createElement(
                'div',
                { className: _Templates2.default.table },
                _react2.default.createElement(
                    'div',
                    { className: _Templates2.default.caption },
                    'Mis Plantillas'
                ),
                _react2.default.createElement(_components.Button, { className: _Templates2.default.newButton, color: 'blue', name: 'Nuevo Template',
                    onClick: this.changeView.bind(this) }),
                _react2.default.createElement(_reactTable2.default, {
                    data: data,
                    columns: columns,
                    defaultPageSize: 5 })
            );
        }
    }, {
        key: 'render',
        value: function render() {

            return _react2.default.createElement(
                'div',
                { className: _Templates2.default.gridContainer },
                this.state.newTemplate ? this.renderNewTemplate() : this.renderTemplateTable()
            );
        }
    }]);
    return Templates;
}(_react2.default.Component);

exports.default = Templates;
module.exports = exports['default'];