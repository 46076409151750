'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MessageLoading = exports.Message = undefined;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _components = require('components');

var _Chat = require('./Chat.scss');

var _Chat2 = _interopRequireDefault(_Chat);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var md = {
  check: require('react-icons/lib/md/check')
};
// import _ from 'translations'


var MessageText = function MessageText(props) {
  var isHtml = props.content.trim().startsWith('<'); // TODO: make this less simplistic

  return _react2.default.createElement(
    'div',
    { className: _Chat2.default.text },
    isHtml ? _react2.default.createElement(_components.HtmlPreview, { html: props.content }) : props.content,
    _react2.default.createElement(
      'div',
      { className: _Chat2.default.date },
      (0, _moment2.default)(props.datetime).format('MM/DD HH:mm')
    )
  );
};

MessageText.defaultProps = {
  message: ''
};

MessageText.propTypes = {
  message: _propTypes2.default.string
};

var Message = exports.Message = function Message(props) {
  return _react2.default.createElement(
    'div',
    { className: _Chat2.default.message },
    props.direction === 'OUT' && props.channel_type === 'WHATSAPP' && props.active === 'WHATSAPP' && _react2.default.createElement(
      'div',
      { className: _Chat2.default.outgoing, key: '2m' },
      _react2.default.createElement(MessageText, props.message)
    ),
    props.direction === 'IN' && props.channel_type === 'WHATSAPP' && props.active === 'WHATSAPP' && [_react2.default.createElement('div', { className: _Chat2.default.circle, key: '1m' }), _react2.default.createElement(
      'div',
      { className: _Chat2.default.incoming, key: '2' },
      _react2.default.createElement(MessageText, props.message)
    )],
    props.direction === 'OUT' && props.channel_type === 'SMS' && props.active === 'SMS' && _react2.default.createElement(
      'div',
      { className: _Chat2.default.outgoing, key: '2m' },
      _react2.default.createElement(MessageText, props.message)
    ),
    props.direction === 'IN' && props.channel_type === 'SMS' && props.active === 'SMS' && [_react2.default.createElement('div', { className: _Chat2.default.circle, key: '1m' }), _react2.default.createElement(
      'div',
      { className: _Chat2.default.incoming, key: '2' },
      _react2.default.createElement(MessageText, props.message)
    )]
  );
};

Message.propTypes = {
  direction: _propTypes2.default.string.isRequired,
  message: _propTypes2.default.object.isRequired
};

var MessageLoading = exports.MessageLoading = function MessageLoading(props) {
  return _react2.default.createElement(
    'div',
    { className: _Chat2.default.message + ' ' + _Chat2.default.loading },
    props.direction === 'in' ? [_react2.default.createElement('div', { className: _Chat2.default.circle, key: '1' }), _react2.default.createElement(
      'div',
      { className: _Chat2.default.incoming, key: '2' },
      _react2.default.createElement('div', { className: _Chat2.default.text, style: { width: props.width + 'px' || '100px' } })
    )] : null,
    props.direction === 'out' ? _react2.default.createElement(
      'div',
      { className: _Chat2.default.outgoing },
      _react2.default.createElement('div', { className: _Chat2.default.text, style: { width: props.width + 'px' || '100px' } })
    ) : null
  );
};

MessageLoading.propTypes = {
  direction: _propTypes2.default.string.isRequired,
  width: _propTypes2.default.number.isRequired
};