'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.actions = undefined;
exports.default = reducer;
exports.getChatApi = getChatApi;
exports.postChatApiLogout = postChatApiLogout;
exports.getSettings = getSettings;
exports.putSettings = putSettings;
exports.refreshChatApi = refreshChatApi;
exports.rebootChatApi = rebootChatApi;

var _apiAction = require('../apiAction');

var _apiAction2 = _interopRequireDefault(_apiAction);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var actions = exports.actions = new _apiAction2.default('CHAT_API', [_apiAction.ACTION_TYPES.DATA, _apiAction.ACTION_TYPES.TRIM, _apiAction.ACTION_TYPES.APPEND]);

var initialState = {
    loading: false,
    status: actions.APPEND,
    data: {}
};

function reducer() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
    var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    var response = actions.getStateForAction(state, action);
    return response;
}

function getChatApi(_ref) {
    var channel_uid = _ref.channel_uid;

    return {
        types: [actions.APPEND, actions.APPEND_SUCCESS, actions.APPEND_FAIL],
        promise: function promise(client) {
            return client.get('/chat-api/status/' + channel_uid);
        }
    };
}

function postChatApiLogout(_ref2) {
    var channel_uid = _ref2.channel_uid;

    return {
        types: [actions.APPEND, actions.APPEND_SUCCESS, actions.APPEND_FAIL],
        promise: function promise(client) {
            return client.post('/chat-api/status/' + channel_uid);
        }
    };
}

function getSettings(_ref3) {
    var channel_uid = _ref3.channel_uid;

    return {
        types: [actions.APPEND, actions.APPEND_SUCCESS, actions.APPEND_FAIL],
        promise: function promise(client) {
            return client.get('/chat-api/settings/' + channel_uid);
        }
    };
}

function putSettings(_ref4) {
    var channel_uid = _ref4.channel_uid,
        data = _ref4.data;

    return {
        types: [actions.APPEND, actions.APPEND_SUCCESS, actions.APPEND_FAIL],
        promise: function promise(client) {
            return client.put('/chat-api/settings/' + channel_uid, { data: data });
        }
    };
}

function refreshChatApi(_ref5) {
    var channel_uid = _ref5.channel_uid;

    return {
        types: [actions.APPEND, actions.APPEND_SUCCESS, actions.APPEND_FAIL],
        promise: function promise(client) {
            return client.post('/chat-api/refresh/' + channel_uid);
        }
    };
}

function rebootChatApi(_ref6) {
    var channel_uid = _ref6.channel_uid;

    return {
        types: [actions.APPEND, actions.APPEND_SUCCESS, actions.APPEND_FAIL],
        promise: function promise(client) {
            return client.post('/chat-api/reboot/' + channel_uid);
        }
    };
}