'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _getOwnPropertyDescriptor = require('babel-runtime/core-js/object/get-own-property-descriptor');

var _getOwnPropertyDescriptor2 = _interopRequireDefault(_getOwnPropertyDescriptor);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _class, _desc, _value, _class2, _class3, _temp;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactRedux = require('react-redux');

var _components = require('components');

var _coreDecorators = require('core-decorators');

var _tags = require('redux/modules/tags');

var _contact = require('redux/modules/contact');

var _contacts = require('redux/modules/contacts');

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
  var desc = {};
  Object['ke' + 'ys'](descriptor).forEach(function (key) {
    desc[key] = descriptor[key];
  });
  desc.enumerable = !!desc.enumerable;
  desc.configurable = !!desc.configurable;

  if ('value' in desc || desc.initializer) {
    desc.writable = true;
  }

  desc = decorators.slice().reverse().reduce(function (desc, decorator) {
    return decorator(target, property, desc) || desc;
  }, desc);

  if (context && desc.initializer !== void 0) {
    desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
    desc.initializer = undefined;
  }

  if (desc.initializer === void 0) {
    Object['define' + 'Property'](target, property, desc);
    desc = null;
  }

  return desc;
}

var ContactTags = (_dec = (0, _reactRedux.connect)(function (state) {
  return { contact: state.contact };
}, {
  addTagToContact: _tags.addTagToContact, deleteTagFromContact: _tags.deleteTagFromContact, getContactDetail: _contact.getContactDetail, tagsRefresh: _tags.tagsRefresh, contactsRefresh: _contacts.contactsRefresh, createTagsRefactor: _tags.createTagsRefactor, decrementTag: _tags.decrementTag, incrementTag: _tags.incrementTag, getTagsRefactor: _tags.getTagsRefactor
}), _dec(_class = (_class2 = (_temp = _class3 = function (_React$Component) {
  (0, _inherits3.default)(ContactTags, _React$Component);

  function ContactTags(props) {
    (0, _classCallCheck3.default)(this, ContactTags);

    var _this = (0, _possibleConstructorReturn3.default)(this, (ContactTags.__proto__ || (0, _getPrototypeOf2.default)(ContactTags)).call(this, props));

    _this.styles = require('./Contact.scss');
    return _this;
  }

  (0, _createClass3.default)(ContactTags, [{
    key: 'componentWillUnmount',
    value: function componentWillUnmount() {
      this.props.tagsRefresh();
    }
  }, {
    key: 'handleChange',
    value: function handleChange(tags) {
      var _this2 = this;

      console.log("TAGS : ", tags);
      var oldTags = this.props.contact.data.tags.text || this.props.contact.data.tags.value;
      var newTags = tags.map(function (t) {
        return t.value;
      });
      var newTagsId = tags.map(function (t) {
        return t.id;
      });
      var id = this.props.contact.data.id;

      console.log("newTagsId : ", newTagsId);
      console.log("oldTags : ", oldTags);

      if (oldTags.length > newTags.length) {
        var tagsToDelete = oldTags.filter(function (i) {
          return newTags.indexOf(i) < 0;
        });
        if (tagsToDelete[0] && typeof tagsToDelete[0] === 'string') {
          var tag = tagsToDelete[0]; // assuming only one tag changed
          console.log("tagsToDelete: ", tag);
          this.props.deleteTagFromContact(id, tag).then(function () {
            _this2.props.getTagsRefactor().then(function (res) {
              console.log(res);
              var matchTag = null;
              for (var i = 0; i < res.length; i++) {
                if (res[i].name === tagsToDelete[0]) {
                  matchTag = res[i];
                  break;
                }
              }
              console.log(matchTag);
              _this2.props.decrementTag(tag, matchTag._id);
              _this2.props.getContactDetail(id);
            });
          });
        }
      } else {
        var tagsToAdd = newTags.filter(function (i) {
          return oldTags.indexOf(i) < 0;
        });
        console.log("tagsToAdd: ", tagsToAdd);
        if (tagsToAdd[0] && typeof tagsToAdd[0] === 'string') {
          var _tag = tagsToAdd[0];
          this.props.addTagToContact(id, _tag).then(function () {
            _this2.props.getContactDetail(id);
          });
          var dataSend = {
            name: _tag,
            enable: true,
            stats: {
              contacts_count: 1,
              conversations_count: 0,
              files_count: 0
            }
          };
          this.props.createTagsRefactor(dataSend).then(function (res) {
            _this2.props.getContactDetail(id);
          }).catch(function (error) {
            console.error(error);
            if (error.code == 400) {
              var _tag2 = tagsToAdd[0];
              var matchTag = null;
              for (var i = 0; i < tags.length; i++) {
                if (tags[i].label === tagsToAdd[0]) {
                  matchTag = tags[i];
                  break;
                }
              }
              console.log(matchTag);
              _this2.props.incrementTag(_tag2, matchTag.id).then(function (res) {
                _this2.props.getContactDetail(id);
              }).catch(function (error) {
                console.error(error);
              });
            }
          });
        }
      }
    }
  }, {
    key: 'renderLoading',
    value: function renderLoading() {
      return _react2.default.createElement(
        'div',
        null,
        'PLACEHOLDER LOADING TAGS'
      );
    }
  }, {
    key: 'render',
    value: function render() {
      var styles = this.styles;

      var tags = [];
      if (this.props.contact.data && this.props.contact.data.tags) {
        tags = this.props.contact.data.tags.text || this.props.contact.data.tags.value;
      }

      return _react2.default.createElement(
        'div',
        { className: styles.content },
        _react2.default.createElement(
          'span',
          { className: styles.title },
          (0, _translations2.default)('CONTACT TAGS')
        ),
        _react2.default.createElement(_components.TagsSelect, {
          value: tags.filter(function (e) {
            return e;
          }),
          onChange: this.handleChange,
          className: styles['react-tagsinput'],
          focusedClassName: styles['react-tagsinput--focused'],
          tagProps: { className: styles['react-tagsinput-tag'], classNameRemove: styles['react-tagsinput-remove'] },
          inputProps: { className: styles['react-tagsinput-input'], placeholder: (0, _translations2.default)('ADD A NEW TAG') }
        })
      );
    }
  }]);
  return ContactTags;
}(_react2.default.Component), _class3.propTypes = {
  contact: _propTypes2.default.object.isRequired,
  addTagToContact: _propTypes2.default.func.isRequired,
  deleteTagFromContact: _propTypes2.default.func.isRequired,
  getContactDetail: _propTypes2.default.func.isRequired,
  tagsRefresh: _propTypes2.default.func.isRequired,
  createTagsRefactor: _propTypes2.default.func.isRequired,
  decrementTag: _propTypes2.default.func.isRequired,
  incrementTag: _propTypes2.default.func.isRequired,
  getTagsRefactor: _propTypes2.default.func.isRequired
  // contactsRefresh: PropTypes.func.isRequired
}, _temp), (_applyDecoratedDescriptor(_class2.prototype, 'handleChange', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'handleChange'), _class2.prototype)), _class2)) || _class);
exports.default = ContactTags;
module.exports = exports['default'];