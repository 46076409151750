'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.schedulerActions = exports.chatActions = exports.receivedActions = exports.sentActions = exports.channelTypes = exports.messageTypes = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

exports.sentReducer = sentReducer;
exports.schedulerReducer = schedulerReducer;
exports.receivedReducer = receivedReducer;
exports.chatReducer = chatReducer;
exports.getChatMessages = getChatMessages;
exports.getTransactions = getTransactions;
exports.getErrors = getErrors;
exports.deleteScheduledMessage = deleteScheduledMessage;
exports.messageSendMultiple = messageSendMultiple;
exports.messageSendTags = messageSendTags;
exports.messageSendFile = messageSendFile;
exports.messageConfirmSendFile = messageConfirmSendFile;
exports.messageUpdateFileStats = messageUpdateFileStats;
exports.messageSelect = messageSelect;
exports.messageUnselect = messageUnselect;
exports.messagesUnselectAll = messagesUnselectAll;
exports.chatMessagesRefresh = chatMessagesRefresh;
exports.sentMessagesRefresh = sentMessagesRefresh;
exports.receivedMessagesRefresh = receivedMessagesRefresh;
exports.getEvents = getEvents;
exports.getFileStats = getFileStats;
exports.getFileReport = getFileReport;
exports.getAgenda = getAgenda;
exports.getMessagesTemplates = getMessagesTemplates;
exports.createTemplateSms = createTemplateSms;
exports.duplicateTemplate = duplicateTemplate;
exports.shareTemplate = shareTemplate;
exports.getTemplateReport = getTemplateReport;
exports.getDlrStatusCode = getDlrStatusCode;
exports.getSmppStatusCode = getSmppStatusCode;

var _immutabilityHelper = require('immutability-helper');

var _immutabilityHelper2 = _interopRequireDefault(_immutabilityHelper);

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _apiAction = require('../apiAction');

var _apiAction2 = _interopRequireDefault(_apiAction);

var _propTypes = require('prop-types');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var messageTypes = exports.messageTypes = {
  SENT: 'sent',
  RECEIVED: 'received',
  CHAT: 'chat'
};

var channelTypes = exports.channelTypes = {
  SMS: 'messages',
  EMAIL: 'email'

  // ACTIONS


};var sentActions = exports.sentActions = new _apiAction2.default('SENT_MESSAGES', [_apiAction.ACTION_TYPES.APPEND, _apiAction.ACTION_TYPES.SELECT, _apiAction.ACTION_TYPES.DATA, _apiAction.ACTION_TYPES.TRIM]);

sentActions.EVENTS = 'EVENTS';
sentActions.EVENTS_SUCCESS = 'EVENTS_SUCCESS';
sentActions.EVENTS_FAIL = 'EVENTS_FAIL';
sentActions.EVENTS_CLEAR = 'EVENTS_CLEAR';

var receivedActions = exports.receivedActions = new _apiAction2.default('RECEIVED_MESSAGES', [_apiAction.ACTION_TYPES.APPEND, _apiAction.ACTION_TYPES.SELECT, _apiAction.ACTION_TYPES.TRIM]);

var chatActions = exports.chatActions = new _apiAction2.default('CHAT_MESSAGES', [_apiAction.ACTION_TYPES.DATA, _apiAction.ACTION_TYPES.APPEND, _apiAction.ACTION_TYPES.PREPEND, _apiAction.ACTION_TYPES.TRIM
// ,ACTION_TYPES.SELECT
]);

var schedulerActions = exports.schedulerActions = new _apiAction2.default('SCHEDULER_ACTIONS', [_apiAction.ACTION_TYPES.SELECT, _apiAction.ACTION_TYPES.DATA]);

schedulerActions.SCHEDULER = 'SCHEDULER';
schedulerActions.SCHEDULER_SUCCESS = 'SCHEDULER_SUCCESS';
schedulerActions.SCHEDULER_FAIL = 'SCHEDULER_FAIL';
schedulerActions.SCHEDULER_CLEAR = 'SCHEDULER_CLEAR';

// REDUCER


var initialState = function initialState(actions) {
  return {
    loading: false,
    status: actions.APPEND,
    list: [],
    selected: [],
    events: [],
    data: {}
  };
};

function sentReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState(sentActions);
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

  switch (action.type) {
    case sentActions.EVENTS_SUCCESS:
      return (0, _immutabilityHelper2.default)(state, {
        events: {
          $set: action.result.map(function (event) {
            return {
              id: event.id,
              title: event.name ? event.name : event.message,
              start: (0, _moment2.default)(event.execution).toDate(), // .format('YYYY-MM-DD HH:mm:ss'),
              end: (0, _moment2.default)(event.execution).add(1, 'hour').toDate(), // .format('YYYY-MM-DD HH:mm:ss'),
              color: event.status === 'PROJECTED' ? '#d02e10' : '#aaa',
              status: event.status
            };
          })
        }
      });
    case sentActions.EVENTS_TRIM:
      return (0, _immutabilityHelper2.default)(state, {
        events: { $set: state.events.slice(0, action.count) }
      });
    default:
      return sentActions.getStateForAction(state, action);
  }
}

function schedulerReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState(schedulerActions);
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

  switch (action.type) {
    case schedulerActions.EVENTS_SUCCESS:
      return action.result;
    default:
      return schedulerActions.getStateForAction(state, action);
  }
}

function receivedReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState(receivedActions);
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  return receivedActions.getStateForAction(state, action);
}

function chatReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState(chatActions);
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  return chatActions.getStateForAction(state, action);
}

// ACTION CREATORS


function getChatMessages(queryParams) {
  var actions = chatActions;
  var params = (0, _extends3.default)({}, queryParams, {
    mark_as_read: true
  });
  return {
    types: [actions.APPEND, actions.APPEND_SUCCESS, actions.APPEND_FAIL],
    promise: function promise(client) {
      return client.get('/transactions', {
        params: params
      });
    }
  };
}

function getTransactions(queryParams) {
  var messageType = queryParams.messageType;

  var params = queryParams;
  var actions = void 0;
  switch (messageType) {
    case messageTypes.RECEIVED:
      actions = receivedActions;
      params.direction = 'IN';
      break;
    case messageTypes.SENT:
      actions = sentActions;
      params.direction = 'OUT';
      break;
    default:
      break;
  }

  var url_direction = "";
  if (params.direction) {
    url_direction = "/" + params.direction.toLowerCase();
  }

  return {
    types: [actions.APPEND, actions.APPEND_SUCCESS, actions.APPEND_FAIL],
    promise: function promise(client) {
      return client.get('/transactions/elastic' + url_direction, {
        params: params
      });
    }
  };
}

function getErrors(transactionId) {
  // const { transactionId } = props
  var actions = sentActions;
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.get('/transactions/' + transactionId);
    }
  };
}

function deleteScheduledMessage(params) {
  var actions = chatActions;
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.del('/messages', {
        params: params
      });
    }
  };
}

function messageSendMultiple(msisdnList, message) {
  var scheduledFor = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  var id = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;

  var actions = sentActions;
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.post('/messages', {
        data: (0, _extends3.default)({
          id: id,
          msisdns: msisdnList,
          message: message,
          scheduled: !!scheduledFor
        }, scheduledFor)
      });
    }
  };
}

function messageSendTags(tags, message, scheduledFor) {
  var actions = sentActions;
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.post('/messages/tags', {
        data: (0, _extends3.default)({
          tags: tags,
          message: message,
          scheduled: !!scheduledFor
        }, scheduledFor)
      });
    }
  };
}

function messageSendFile(data, channelType) {
  var actions = sentActions;
  return {

    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.post('/' + channelType + '/send_to_file', {
        data: data
      });
    }
  };
}

function messageConfirmSendFile(data, channelType) {
  var actions = sentActions;
  return {

    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.post('/' + channelType + '/send_to_file/' + data._id + '/send', {
        data: data
      });
    }
  };
}

function messageUpdateFileStats(data, channelType) {
  var actions = sentActions;
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.post('/' + channelType + '/send_to_file/' + data._id + '/stats', {
        data: data,
        timeout: 10 * 60 * 1000
      });
    }
  };
}

function messageSelect(messageType, index) {
  var actions = messageType === messageTypes.RECEIVED ? receivedActions : sentActions;
  return {
    type: actions.SELECT,
    index: index
  };
}

function messageUnselect(messageType, index) {
  var actions = messageType === messageTypes.RECEIVED ? receivedActions : sentActions;
  return {
    type: actions.SELECT_OFF,
    index: index
  };
}

function messagesUnselectAll(messageType) {
  var actions = messageType === messageTypes.RECEIVED ? receivedActions : sentActions;
  return {
    type: actions.SELECT_NONE
  };
}

function chatMessagesRefresh() {
  var actions = chatActions;
  return {
    type: actions.TRIM,
    count: 0
  };
}

function sentMessagesRefresh() {
  var actions = sentActions;
  return {
    type: actions.TRIM,
    count: 0
  };
}

function receivedMessagesRefresh() {
  var actions = receivedActions;
  return {
    type: actions.TRIM,
    count: 0
  };
}

function getEvents(start_date, end_date) {
  return {
    types: [schedulerActions.DATA, schedulerActions.DATA_SUCCESS, schedulerActions.DATA_FAIL],
    promise: function promise(client) {
      return client.get('/scheduler/calendar', {
        params: {
          type: 'CALENDAR',
          start_date: start_date,
          end_date: end_date
        }
      });
    }
  };
}

function getFileStats(fileUid) {
  return {
    types: [schedulerActions.DATA, schedulerActions.DATA_SUCCESS, schedulerActions.DATA_FAIL],
    promise: function promise(client) {
      return client.get('files/messages/' + fileUid);
    }
  };
}

function getFileReport(fileUid, from, to) {
  return {
    types: [schedulerActions.DATA, schedulerActions.DATA_SUCCESS, schedulerActions.DATA_FAIL],
    promise: function promise(client) {
      return client.get('files/messages/report/' + fileUid, { params: { from: from, to: to } });
    }
  };
}

function getAgenda(start_date, end_date) {
  return {
    types: [schedulerActions.DATA, schedulerActions.DATA_SUCCESS, schedulerActions.DATA_FAIL],
    promise: function promise(client) {
      return client.get('/scheduler/calendar', {
        params: {
          type: 'AGENDA',
          start_date: start_date,
          end_date: end_date
        }
      });
    }
  };
}

function getMessagesTemplates(account_uid, name) {
  return {
    types: [schedulerActions.DATA, schedulerActions.DATA_SUCCESS, schedulerActions.DATA_FAIL],
    promise: function promise(client) {
      return client.get('/template/config/sms', {
        params: {
          account_uid: account_uid,
          name: name
        }
      });
    }
  };
}

function createTemplateSms(data) {
  var actions = sentActions;
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.post('/template/config/sms', {
        data: data
      });
    }
  };
}

function duplicateTemplate(data) {
  var actions = sentActions;
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.post('/template/duplicate/config/sms', {
        data: data
      });
    }
  };
}

function shareTemplate(data, template_uid) {
  var actions = sentActions;
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.post('/template/share/config/sms/' + template_uid, {
        data: data
      });
    }
  };
}

function getTemplateReport(template_uid, from, to) {
  var actions = sentActions;
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.get('/template/report/config/sms/' + template_uid, {
        params: {
          from: from,
          to: to
        }
      });
    }
  };
}

function getDlrStatusCode() {
  var actions = sentActions;
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.get('/dlr_status_code', {});
    }
  };
}

function getSmppStatusCode() {
  var actions = sentActions;
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.get('/smpp_status_code', {});
    }
  };
}