'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.IntegrationsTabs = undefined;

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _dec2, _class, _class2, _temp;

var _components = require('components');

var _profiling = require('redux/modules/profiling');

var _workflows = require('redux/modules/workflows');

var _reactReduxLoadingBar = require('react-redux-loading-bar');

var _reactCopyToClipboard = require('react-copy-to-clipboard');

var _reactCopyToClipboard2 = _interopRequireDefault(_reactCopyToClipboard);

var _reactRouter = require('react-router');

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _reduxConnect = require('redux-connect');

var _reactRedux = require('react-redux');

var _reachHistory = require('../../../helpers/reachHistory');

var _Meraki = require('./Meraki.scss');

var _Meraki2 = _interopRequireDefault(_Meraki);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Infinite = function Infinite(props) {
  return _react2.default.createElement(
    'div',
    null,
    props.children
  );
};

var md = {
  settings: require('react-icons/lib/md/settings'),
  copy: require('react-icons/lib/md/assignment')
};

var IntegrationsTabs = exports.IntegrationsTabs = function IntegrationsTabs() {
  return _react2.default.createElement(_components.Tabs, {
    simple: true,
    options: [{
      href: '/integrations/meraki',
      title: (0, _translations2.default)('MERAKI'),
      is_index: true
    }]
  });
};
var MerakiList = (_dec = (0, _reduxConnect.asyncConnect)([{
  key: 'list',
  promise: function promise(_ref) {
    var dispatch = _ref.store.dispatch;
    return dispatch((0, _profiling.getMerakiProfilingJobs)(0, 100));
  }
}]), _dec2 = (0, _reactRedux.connect)(function (state) {
  return {
    mobileMode: state.mobile.mobileMode,
    list: state.profiling.list,
    account: state.auth.current_account,
    selected: state.variables.selected
  };
}, {
  get: _profiling.getMerakiProfilingJobs,
  refresh: _profiling.profilingRefresh,
  getMerakiProfilingJobs: _profiling.getMerakiProfilingJobs,
  stopMerakiProfiling: _profiling.stopMerakiProfiling,
  getMeraki: _profiling.getMeraki,
  hideLoading: _reactReduxLoadingBar.hideLoading,
  push: _reachHistory.push
}), _dec(_class = _dec2(_class = (_temp = _class2 = function (_List) {
  (0, _inherits3.default)(MerakiList, _List);

  function MerakiList(props) {
    (0, _classCallCheck3.default)(this, MerakiList);

    var _this = (0, _possibleConstructorReturn3.default)(this, (MerakiList.__proto__ || (0, _getPrototypeOf2.default)(MerakiList)).call(this, props));

    _this.statusTranslations = {
      ACTIVE: (0, _translations2.default)('IN_PROGRESS'),
      PAUSED: (0, _translations2.default)('PAUSED'),
      APPROVED: (0, _translations2.default)('APPROVED'),
      FINISHED: (0, _translations2.default)('FINISHED'),
      EXPIRED: (0, _translations2.default)('EXPIRED'),
      ABORTED: (0, _translations2.default)('ABORTED'),
      NOT_STARTED: (0, _translations2.default)('NOT_STARTED'),
      STOPPED: (0, _translations2.default)('STOPPED')
    };

    _this.state = {
      merakiEnabled: null
    };
    return _this;
  }

  (0, _createClass3.default)(MerakiList, [{
    key: 'componentWillMount',
    value: function componentWillMount() {
      var _this2 = this;

      this.props.getMeraki().then(function () {
        _this2.props.hideLoading();
        _this2.setState({
          merakiEnabled: true
        });
      }).catch(function () {
        _this2.props.hideLoading();
        _this2.setState({
          merakiEnabled: false
        });
      });
    }
  }, {
    key: 'getLink',
    value: function getLink(item) {
      var resultsIf = ['ACTIVE', 'IN_PROGRESS', 'FINISHED', 'EXPIRED', 'ABORTED', 'STOPPED'];
      if (resultsIf.indexOf(item.status) > -1) {
        return {
          pathname: '/integrations/meraki/results',
          query: { uid: item._id },
          state: {
            modal: true,
            returnTo: '/integrations/meraki',
            modalSize: { width: '900px', height: '600px' }
          }
        };
      }
    }
  }, {
    key: 'stopProfiling',
    value: function stopProfiling(jobDescriptionUid) {
      this.props.stopMerakiProfiling(jobDescriptionUid).then(function () {
        if (document) {
          document.body.style.cursor = 'wait';
        }
        setTimeout(function () {
          window.location = '/integrations/meraki/';
        }, 1500);
      });
    }
  }, {
    key: 'renderNoIntegration',
    value: function renderNoIntegration() {
      var image = '/static/integrations/meraki.svg';

      return _react2.default.createElement(
        'div',
        { className: _Meraki2.default.noIntegration },
        _react2.default.createElement(
          'div',
          { className: _Meraki2.default.title },
          (0, _translations2.default)('INTEGRATIONS TITLE')
        ),
        _react2.default.createElement(
          'div',
          { className: _Meraki2.default.explanation },
          _react2.default.createElement(
            'div',
            { className: _Meraki2.default.text },
            'Provee acceso a internet en tus establecimientos por medio de Cisco-Meraki.',
            _react2.default.createElement('br', null),
            _react2.default.createElement('br', null),
            'Segmenta y conoce mejor a tus clientes desde el momento en que se conectan a tu red inal\xE1mbrica.',
            _react2.default.createElement('br', null),
            _react2.default.createElement('br', null),
            'Con Tigo la data me llega.',
            _react2.default.createElement(
              'div',
              { className: _Meraki2.default.request },
              _react2.default.createElement(
                'a',
                { href: 'http://www.tigobusiness.com.gt/', className: _Meraki2.default.button, target: '_blank' },
                (0, _translations2.default)('MORE INFO')
              ),
              _react2.default.createElement(
                'a',
                { className: _Meraki2.default.button },
                (0, _translations2.default)('INTEGRATIONS SOLICITUDE')
              )
            )
          ),
          _react2.default.createElement(
            'div',
            { className: _Meraki2.default.image },
            _react2.default.createElement('img', { src: image, alt: '' })
          )
        )
      );
    }
  }, {
    key: 'renderEmpty',
    value: function renderEmpty() {
      return _react2.default.createElement(_components.Linkholder, {
        to: {
          pathname: '/integrations/meraki/profiling',
          state: { modal: true, returnTo: '/integrations/meraki', fullModal: true }
        },
        src: '/static/empty/results.svg',
        text: (0, _translations2.default)('NO MERAKI PROFILINGS RUNNING'),
        button: (0, _translations2.default)('CONFIGURE A NEW PROFILING')
      });
    }
  }, {
    key: 'renderTabs',
    value: function renderTabs() {
      return _react2.default.createElement(IntegrationsTabs, null);
    }
  }, {
    key: 'renderNav',
    value: function renderNav(stickyprops) {
      return _react2.default.createElement(
        _components.Nav,
        { title: (0, _translations2.default)('MY PROFILING'), style: stickyprops.style },
        this.state.merakiEnabled && _react2.default.createElement(
          _reactRouter.Link,
          {
            className: 'action',
            to: {
              pathname: '/integrations/meraki/profiling',
              state: { modal: true, returnTo: '/integrations/meraki', fullModal: true }
            },
            style: { marginLeft: 'auto' }
          },
          (0, _translations2.default)('MERAKI PROFILING CONFIG'),
          _react2.default.createElement(md.settings, { size: 20 })
        )
      );
    }
  }, {
    key: 'renderExtra',
    value: function renderExtra() {
      var _this3 = this;

      if (!this.props.list) {
        return 'Error';
      }

      this.activeProfilings = this.props.list.filter(function (item) {
        return item.status === 'ACTIVE';
      });
      return _react2.default.createElement(
        'div',
        { className: _Meraki2.default.stopButtons },
        this.activeProfilings.map(function (p, index) {
          return _react2.default.createElement(
            'div',
            { className: _Meraki2.default.url, key: 'stop-' + index },
            _react2.default.createElement(
              'div',
              { className: _Meraki2.default.running },
              (0, _translations2.default)('MERAKI OPEN PROFILING RUNNING'),
              ' - ',
              p.name
            ),
            _react2.default.createElement(
              'div',
              { className: _Meraki2.default.toClipboard },
              p.public_web_access.enabled && p.public_web_access.full_uri ? _react2.default.createElement(
                _reactCopyToClipboard2.default,
                {
                  text: p.public_web_access.full_uri,
                  onCopy: function onCopy() {
                    return alert((0, _translations2.default)('COPIED TO CLIPBOARD'));
                  } },
                _react2.default.createElement(
                  'div',
                  { className: _Meraki2.default.copyicon },
                  p.public_web_access.full_uri,
                  '\xA0',
                  _react2.default.createElement(md.copy, { size: 20 })
                )
              ) : null
            ),
            _react2.default.createElement(
              'div',
              { className: _Meraki2.default.copyExplain },
              (0, _translations2.default)('MERAKI EXPLAIN')
            ),
            _react2.default.createElement(
              'a',
              { className: _Meraki2.default.copyLink, href: 'https://comunicador.tigo.com.gt/tutoriales/meraki.html' },
              'Tutorial'
            ),
            _react2.default.createElement(
              'a',
              { className: _Meraki2.default.stop, onClick: _this3.stopProfiling.bind(_this3, p['_id']) },
              (0, _translations2.default)('MERAKI STOP RUNNING')
            )
          );
        })
      );
    }
  }, {
    key: 'renderInfiniteList',
    value: function renderInfiniteList() {
      var _this4 = this;

      if (__SERVER__) {
        return _react2.default.createElement(_components.VirtualListItemLoad, null);
      }

      if (this.state.merakiEnabled === null) {
        return _react2.default.createElement(_components.VirtualListItemLoad, null);
      }

      if (this.state.merakiEnabled === false) {
        return this.renderNoIntegration();
      }

      if (this.state.merakiEnabled === true && this.props.list.length === 0) {
        return this.renderEmpty();
      }

      return _react2.default.createElement(
        Infinite,
        {
          refresh: this.refreshRows,
          next: this.loadMoreRows,
          hasMore: this.state.hasMoreRows
        },
        this.props.list.map(function (item, index) {
          return _react2.default.createElement(_components.VirtualListItemStudy, {
            key: item._id,
            index: index,
            name: item.name,
            link: _this4.getLink(item),
            label: _this4.statusTranslations[item.status],
            labelClass: item.status
          });
        })
      );
    }
  }]);
  return MerakiList;
}(_components.List), _class2.propTypes = {
  mobileMode: _propTypes2.default.bool,
  selected: _propTypes2.default.array,
  getMerakiProfilingJobs: _propTypes2.default.func.isRequired
}, _temp)) || _class) || _class);
exports.default = MerakiList;