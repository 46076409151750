'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _class, _temp;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactInfiniteScrollComponent = require('react-infinite-scroll-component');

var _reactInfiniteScrollComponent2 = _interopRequireDefault(_reactInfiniteScrollComponent);

var _components = require('components');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Infinite = (_temp = _class = function (_React$Component) {
  (0, _inherits3.default)(Infinite, _React$Component);

  function Infinite() {
    (0, _classCallCheck3.default)(this, Infinite);
    return (0, _possibleConstructorReturn3.default)(this, (Infinite.__proto__ || (0, _getPrototypeOf2.default)(Infinite)).apply(this, arguments));
  }

  (0, _createClass3.default)(Infinite, [{
    key: 'render',
    value: function render() {
      return _react2.default.createElement(
        _reactInfiniteScrollComponent2.default
        // pullDownToRefresh // to enable the feature
        // pullDownToRefreshContent={<h3 style={{textAlign: 'center'}}>&#8595; Pull down to refresh</h3>}
        // releaseToRefreshContent={<h3 style={{textAlign: 'center'}}>&#8593; Release to refresh</h3>}
        ,
        { style: { display: 'block', overflow: 'hidden' },
          endMessage: _react2.default.createElement('span', null),
          refreshFunction: this.props.refresh,
          next: this.props.next,
          hasMore: this.props.hasMore,
          className: this.props.className,
          loader: _react2.default.createElement(_components.VirtualListItemLoad, null) },
        this.props.children
      );
    }
  }]);
  return Infinite;
}(_react2.default.Component), _class.propTypes = {
  refresh: _propTypes2.default.func.isRequired,
  next: _propTypes2.default.func.isRequired,
  hasMore: _propTypes2.default.bool.isRequired
}, _temp);
exports.default = Infinite;

_components.VirtualListItemLoad;
module.exports = exports['default'];