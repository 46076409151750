'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _getOwnPropertyDescriptor = require('babel-runtime/core-js/object/get-own-property-descriptor');

var _getOwnPropertyDescriptor2 = _interopRequireDefault(_getOwnPropertyDescriptor);

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _dec2, _class, _desc, _value, _class2;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _coreDecorators = require('core-decorators');

var _reactRedux = require('react-redux');

var _account = require('redux/modules/account');

var _channel = require('redux/modules/channel');

var _reduxConnect = require('redux-connect');

var _themeEnabled = require('helpers/themeEnabled');

var _ApiClient = require('../../helpers/ApiClient');

var _ApiClient2 = _interopRequireDefault(_ApiClient);

var _EmailCustomizer = require('./EmailCustomizer.scss');

var _EmailCustomizer2 = _interopRequireDefault(_EmailCustomizer);

var _components = require('components');

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _Allow = require('../Allow/Allow');

var _Allow2 = _interopRequireDefault(_Allow);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
  var desc = {};
  Object['ke' + 'ys'](descriptor).forEach(function (key) {
    desc[key] = descriptor[key];
  });
  desc.enumerable = !!desc.enumerable;
  desc.configurable = !!desc.configurable;

  if ('value' in desc || desc.initializer) {
    desc.writable = true;
  }

  desc = decorators.slice().reverse().reduce(function (desc, decorator) {
    return decorator(target, property, desc) || desc;
  }, desc);

  if (context && desc.initializer !== void 0) {
    desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
    desc.initializer = undefined;
  }

  if (desc.initializer === void 0) {
    Object['define' + 'Property'](target, property, desc);
    desc = null;
  }

  return desc;
}

var emailStatus = {
  REQUESTED: (0, _translations2.default)('EMAIL STATE REQUESTED'),
  APROVED: (0, _translations2.default)('EMAIL STATE APROVED'),
  REJECTED: (0, _translations2.default)('EMAIL STATE REJECTED'),
  ERROR: (0, _translations2.default)('EMAIL STATE ERROR')
};

var md = {
  close: require('react-icons/lib/md/clear')
};

var EmailCustomizer = (_dec = (0, _reduxConnect.asyncConnect)([{
  key: 'account',
  promise: function promise(_ref) {
    var _ref$store = _ref.store,
        dispatch = _ref$store.dispatch,
        getState = _ref$store.getState;

    var state = getState();
    if (!state.account.data._id) {
      dispatch((0, _account.getAccount)());
    }
  }
}]), _dec2 = (0, _reactRedux.connect)(function (state) {
  return { account: state.account.data };
}, { updateAccount: _account.updateAccount, getEmailChannel: _channel.getEmailChannel, updateEmailChannel: _channel.updateEmailChannel }), _dec(_class = _dec2(_class = (_class2 = function (_React$Component) {
  (0, _inherits3.default)(EmailCustomizer, _React$Component);

  function EmailCustomizer(props) {
    (0, _classCallCheck3.default)(this, EmailCustomizer);

    var _this = (0, _possibleConstructorReturn3.default)(this, (EmailCustomizer.__proto__ || (0, _getPrototypeOf2.default)(EmailCustomizer)).call(this, props));

    _this.state = {
      email: '',
      enableChange: false,
      lastEmail: '',
      validEmail: true
    };
    return _this;
  }

  (0, _createClass3.default)(EmailCustomizer, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      var _this2 = this;

      var channels = this.props.account.reach.channels;

      var emailChannel = channels.filter(function (channel) {
        return channel.type === 'EMAIL';
      });
      if (emailChannel.length > 0) {
        this.props.getEmailChannel(emailChannel[0]._id).then(function (data) {
          _this2.setState((0, _extends3.default)({}, _this2.state, {
            email: data.email.email,
            lastEmail: data.email.email,
            emailStatus: data.email.email_status,
            channelData: data
          }));
        });
      }
    }
  }, {
    key: 'handleChange',
    value: function handleChange(event) {
      var email = event.target.value;
      var pattern = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
      var validEmail = false;

      if (pattern.test(String(email).toLowerCase())) {
        validEmail = true;
      }

      this.setState((0, _extends3.default)({}, this.state, {
        validEmail: validEmail,
        email: event.target.value
      }));
    }
  }, {
    key: 'handleClick',
    value: function handleClick() {
      var _this3 = this;

      if (this.state.enableChange) {
        if (this.state.email !== '' && this.state.validEmail) {
          var newchannelData = this.state.channelData;
          newchannelData.email.email = this.state.email;
          newchannelData.lastEmail = this.state.lastEmail;

          this.props.updateEmailChannel(newchannelData).then(function (data) {
            _this3.setState((0, _extends3.default)({}, _this3.state, {
              email: data.email.email,
              lastEmail: data.email.email,
              emailStatus: data.email.email_status,
              channelData: data,
              enableChange: false
            }));
          });
        }
      } else {
        this.setState((0, _extends3.default)({}, this.state, {
          enableChange: true
        }));
      }
    }
  }, {
    key: 'closeEdit',
    value: function closeEdit() {
      if (this.state.enableChange) {
        var newEmail = this.state.lastEmail;
        this.setState({
          email: newEmail,
          enableChange: false,
          validEmail: true
        });
      }
    }
  }, {
    key: 'render',
    value: function render() {

      var classesTheme = (0, _themeEnabled.getThemeEnabledStyle)(_EmailCustomizer2.default); //IF THEME
      var objAsset = (0, _themeEnabled.getAssetStyle)();
      var iconIlustration = objAsset.form;

      return _react2.default.createElement(
        'div',
        { className: classesTheme },
        _react2.default.createElement(
          'div',
          { className: _EmailCustomizer2.default.mainTab },
          _react2.default.createElement(
            'div',
            { className: _EmailCustomizer2.default.flexRow },
            _react2.default.createElement(
              'p',
              { href: '#highlights', className: _EmailCustomizer2.default.chosen },
              (0, _translations2.default)('EMAIL')
            )
          ),
          _react2.default.createElement(
            'div',
            { className: _EmailCustomizer2.default.wrapper },
            _react2.default.createElement(
              'div',
              { className: _EmailCustomizer2.default.aside },
              _react2.default.createElement(
                'div',
                { className: _EmailCustomizer2.default.asideTop },
                _react2.default.createElement(
                  'h3',
                  null,
                  (0, _translations2.default)('ACCOUNT EMAIL TITLE')
                )
              ),
              _react2.default.createElement(
                'div',
                { className: _EmailCustomizer2.default.inputMain },
                _react2.default.createElement(
                  'div',
                  { className: _EmailCustomizer2.default.inputText },
                  (0, _translations2.default)('ACCOUNT EMAIL')
                ),
                _react2.default.createElement(
                  'div',
                  { className: _EmailCustomizer2.default.emailStatus },
                  _react2.default.createElement(
                    'div',
                    { className: _EmailCustomizer2.default.column },
                    _react2.default.createElement('input', { type: 'text', disabled: !this.state.enableChange, className: this.state.validEmail ? this.state.enableChange ? _EmailCustomizer2.default.inputWhiteBackground : _EmailCustomizer2.default.emailInput : _EmailCustomizer2.default.invalidEmailPattern, onChange: this.handleChange, value: this.state.email }),
                    this.state.enableChange ? _react2.default.createElement(
                      'span',
                      { className: _EmailCustomizer2.default.clear },
                      _react2.default.createElement(md.close, { className: _EmailCustomizer2.default.closeIcon, onClick: this.closeEdit })
                    ) : null
                  ),
                  this.state.emailStatus && !this.state.enableChange ? _react2.default.createElement(
                    'div',
                    { className: _EmailCustomizer2.default.column },
                    _react2.default.createElement('span', { className: this.state.emailStatus === 'APROVED' ? _EmailCustomizer2.default.emailAproved : this.state.emailStatus === 'REQUESTED' ? _EmailCustomizer2.default.emailRequested : _EmailCustomizer2.default.emailRejected }),
                    _react2.default.createElement(
                      'span',
                      { className: this.state.emailStatus === 'APROVED' ? _EmailCustomizer2.default.statusText : this.state.emailStatus === 'REQUESTED' ? _EmailCustomizer2.default.statusText : _EmailCustomizer2.default.textRejected },
                      emailStatus[this.state.emailStatus]
                    )
                  ) : null
                ),
                _react2.default.createElement(
                  'h3',
                  { className: _EmailCustomizer2.default.text },
                  ' ',
                  (0, _translations2.default)('ACCOUNT EMAIL TEXT')
                )
              ),
              _react2.default.createElement(
                'div',
                { className: _EmailCustomizer2.default.footer },
                _react2.default.createElement(
                  _Allow2.default,
                  { permissions: ['account:configs:channels:sending:edit'] },
                  _react2.default.createElement(_components.Button, {
                    className: this.state.emailStatus === 'REQUESTED' ? _EmailCustomizer2.default.disabled : _EmailCustomizer2.default.enabled,
                    onClick: this.handleClick,
                    disabled: this.state.emailStatus === 'REQUESTED',
                    name: !this.state.enableChange ? (0, _translations2.default)('ACCOUNT EMAIL BUTTON EDIT') : (0, _translations2.default)('ACCOUNT EMAIL BUTTON VERIFY')
                  })
                ),
                _react2.default.createElement(
                  'h3',
                  { className: _EmailCustomizer2.default.text },
                  (0, _translations2.default)('ACCOUNT EMAIL VERIFY TEXT')
                )
              )
            ),
            _react2.default.createElement(
              'div',
              { className: _EmailCustomizer2.default.aside2 },
              _react2.default.createElement('img', { className: _EmailCustomizer2.default.svg, src: iconIlustration })
            )
          )
        )
      );
    }
  }]);
  return EmailCustomizer;
}(_react2.default.Component), (_applyDecoratedDescriptor(_class2.prototype, 'handleChange', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'handleChange'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, 'handleClick', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'handleClick'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, 'closeEdit', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'closeEdit'), _class2.prototype)), _class2)) || _class) || _class);
exports.default = EmailCustomizer;
module.exports = exports['default'];