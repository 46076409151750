'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.HttpHelp = exports.RestHelp = exports.default = undefined;

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactPdfJs = require('react-pdf-js');

var _reactPdfJs2 = _interopRequireDefault(_reactPdfJs);

var _components = require('components');

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _Help3 = require('./Help.scss');

var _Help4 = _interopRequireDefault(_Help3);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Help = function (_ReportList) {
  (0, _inherits3.default)(Help, _ReportList);

  function Help() {
    var _ref;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, Help);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref = Help.__proto__ || (0, _getPrototypeOf2.default)(Help)).call.apply(_ref, [this].concat(args))), _this), _this.state = {}, _this.onDocumentComplete = function (pages) {
      _this.setState({ page: 1, pages: pages });
    }, _this.onPageComplete = function (page) {
      _this.setState({ page: page });
    }, _this.handlePrevious = function () {
      _this.setState({ page: _this.state.page - 1 });
    }, _this.handleNext = function () {
      _this.setState({ page: _this.state.page + 1 });
    }, _this.renderPagination = function (page, pages) {
      var previousButton = _react2.default.createElement(
        'li',
        { className: 'previous', onClick: _this.handlePrevious },
        _react2.default.createElement(
          'a',
          { href: '#' },
          _react2.default.createElement('i', { className: 'fa fa-arrow-left' }),
          ' Previous'
        )
      );
      if (page === 1) {
        previousButton = _react2.default.createElement(
          'li',
          { className: 'previous disabled' },
          _react2.default.createElement(
            'a',
            { href: '#' },
            _react2.default.createElement('i', { className: 'fa fa-arrow-left' }),
            ' Anterior'
          )
        );
      }
      var nextButton = _react2.default.createElement(
        'li',
        { className: 'next', onClick: _this.handleNext },
        _react2.default.createElement(
          'a',
          { href: '#' },
          'Siguiente ',
          _react2.default.createElement('i', { className: 'fa fa-arrow-right' })
        )
      );
      if (page === pages) {
        nextButton = _react2.default.createElement(
          'li',
          { className: 'next disabled' },
          _react2.default.createElement(
            'a',
            { href: '#' },
            'Next ',
            _react2.default.createElement('i', { className: 'fa fa-arrow-right' })
          )
        );
      }
      return _react2.default.createElement(
        'nav',
        null,
        _react2.default.createElement(
          'ul',
          { className: _Help4.default.pager },
          previousButton,
          nextButton
        )
      );
    }, _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(Help, [{
    key: 'renderNav',
    value: function renderNav(stickyprops) {
      return _react2.default.createElement(_components.Nav, { style: stickyprops.style });
    }
  }, {
    key: 'renderPDF',
    value: function renderPDF() {
      return _react2.default.createElement(_reactPdfJs2.default, { className: _Help4.default.pdf, file: '/static/csmsv4.pdf', onDocumentComplete: this.onDocumentComplete, onPageComplete: this.onPageComplete, page: this.state.page });
    }
  }, {
    key: 'renderContent',
    value: function renderContent() {
      var pagination = null;
      if (this.state.pages) {
        pagination = this.renderPagination(this.state.page, this.state.pages);
      }
      return _react2.default.createElement(
        'div',
        null,
        _react2.default.createElement(
          'h1',
          { className: _Help4.default.title },
          (0, _translations2.default)('USER MANUAL')
        ),
        _react2.default.createElement(
          'div',
          null,
          pagination,
          this.renderPDF()
        )
      );
    }
  }]);
  return Help;
}(_components.ReportList);

exports.default = Help;

var RestHelp = exports.RestHelp = function (_Help) {
  (0, _inherits3.default)(RestHelp, _Help);

  function RestHelp() {
    (0, _classCallCheck3.default)(this, RestHelp);
    return (0, _possibleConstructorReturn3.default)(this, (RestHelp.__proto__ || (0, _getPrototypeOf2.default)(RestHelp)).apply(this, arguments));
  }

  (0, _createClass3.default)(RestHelp, [{
    key: 'renderPDF',
    value: function renderPDF() {
      return _react2.default.createElement(_reactPdfJs2.default, { className: _Help4.default.pdf, file: '/static/manualREST.pdf', onDocumentComplete: this.onDocumentComplete, onPageComplete: this.onPageComplete, page: this.state.page });
    }
  }]);
  return RestHelp;
}(Help);

var HttpHelp = exports.HttpHelp = function (_Help2) {
  (0, _inherits3.default)(HttpHelp, _Help2);

  function HttpHelp() {
    (0, _classCallCheck3.default)(this, HttpHelp);
    return (0, _possibleConstructorReturn3.default)(this, (HttpHelp.__proto__ || (0, _getPrototypeOf2.default)(HttpHelp)).apply(this, arguments));
  }

  (0, _createClass3.default)(HttpHelp, [{
    key: 'renderPDF',
    value: function renderPDF() {
      return _react2.default.createElement(_reactPdfJs2.default, { className: _Help4.default.pdf, file: '/static/manualHTTP.pdf', onDocumentComplete: this.onDocumentComplete, onPageComplete: this.onPageComplete, page: this.state.page });
    }
  }]);
  return HttpHelp;
}(Help);