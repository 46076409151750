'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _class, _class2, _temp;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactRedux = require('react-redux');

var _reactRouter = require('react-router');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var md = {
  arrowBack: require('react-icons/lib/md/arrow-back')
};

var MobileBar = (_dec = (0, _reactRedux.connect)(function (state) {
  return { mobileMode: state.mobile.mobileMode };
}), _dec(_class = (_temp = _class2 = function (_React$Component) {
  (0, _inherits3.default)(MobileBar, _React$Component);

  function MobileBar(props) {
    (0, _classCallCheck3.default)(this, MobileBar);

    var _this = (0, _possibleConstructorReturn3.default)(this, (MobileBar.__proto__ || (0, _getPrototypeOf2.default)(MobileBar)).call(this, props));

    _this.styles = require('./MobileBar.scss');
    return _this;
  }

  (0, _createClass3.default)(MobileBar, [{
    key: 'render',
    value: function render() {
      if (this.props.mobileMode || this.props.force) {
        return _react2.default.createElement(
          'nav',
          { className: this.styles.mobileBar },
          this.props.returnTo || this.props.returnToAction ? _react2.default.createElement(
            _reactRouter.Link,
            { className: this.styles.arrow, to: this.props.returnTo, onClick: this.props.returnToAction },
            _react2.default.createElement(md.arrowBack, { size: 30 })
          ) : null,
          this.props.title ? _react2.default.createElement(
            'h1',
            { className: this.styles.title },
            this.props.title
          ) : null,
          this.props.extra || this.props.extraAction ? _react2.default.createElement(
            _reactRouter.Link,
            { className: this.styles.extra, to: this.props.extra, onClick: this.props.extraAction },
            _react2.default.createElement(this.props.extraIcon, { size: 30 })
          ) : null
        );
      }
      return null;
    }
  }]);
  return MobileBar;
}(_react2.default.Component), _class2.propTypes = {
  mobileMode: _propTypes2.default.bool.isRequired,
  returnTo: _propTypes2.default.string,
  returnToAction: _propTypes2.default.func,
  extra: _propTypes2.default.string,
  extraAction: _propTypes2.default.func,
  title: _propTypes2.default.string,
  force: _propTypes2.default.bool
}, _temp)) || _class);
exports.default = MobileBar;
module.exports = exports['default'];