'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _getOwnPropertyDescriptor = require('babel-runtime/core-js/object/get-own-property-descriptor');

var _getOwnPropertyDescriptor2 = _interopRequireDefault(_getOwnPropertyDescriptor);

var _typeof2 = require('babel-runtime/helpers/typeof');

var _typeof3 = _interopRequireDefault(_typeof2);

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _extends3 = require('babel-runtime/helpers/extends');

var _extends4 = _interopRequireDefault(_extends3);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _class, _desc, _value, _class2, _class3, _temp;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactDom = require('react-dom');

var _reactDom2 = _interopRequireDefault(_reactDom);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactRedux = require('react-redux');

var _coreDecorators = require('core-decorators');

var _reactSelect = require('react-select');

var _reactSelect2 = _interopRequireDefault(_reactSelect);

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _variables = require('redux/modules/variables');

var _components = require('components');

var _VariableSelect = require('./VariableSelect.scss');

var _VariableSelect2 = _interopRequireDefault(_VariableSelect);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
  var desc = {};
  Object['ke' + 'ys'](descriptor).forEach(function (key) {
    desc[key] = descriptor[key];
  });
  desc.enumerable = !!desc.enumerable;
  desc.configurable = !!desc.configurable;

  if ('value' in desc || desc.initializer) {
    desc.writable = true;
  }

  desc = decorators.slice().reverse().reduce(function (desc, decorator) {
    return decorator(target, property, desc) || desc;
  }, desc);

  if (context && desc.initializer !== void 0) {
    desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
    desc.initializer = undefined;
  }

  if (desc.initializer === void 0) {
    Object['define' + 'Property'](target, property, desc);
    desc = null;
  }

  return desc;
}

var md = {
  add: require('react-icons/lib/md/add-circle'),
  delete: require('react-icons/lib/md/delete')
};

var VariableSelect = (_dec = (0, _reactRedux.connect)(function (state) {
  return {
    variablesList: state.variables.list,
    variablesSelected: state.variables.selected
  };
}, { getVariables: _variables.getVariables, variablesSelectSingle: _variables.variablesSelectSingle, variablesRefresh: _variables.variablesRefresh }), _dec(_class = (_class2 = (_temp = _class3 = function (_React$Component) {
  (0, _inherits3.default)(VariableSelect, _React$Component);

  function VariableSelect(props) {
    (0, _classCallCheck3.default)(this, VariableSelect);

    var _this = (0, _possibleConstructorReturn3.default)(this, (VariableSelect.__proto__ || (0, _getPrototypeOf2.default)(VariableSelect)).call(this, props));

    _this.state = {
      selectedIndex: -1,
      selectedVariable: null,
      selectedValue: '',
      profileVariables: props.variables,
      validEmail: true,
      validMsisdn: true
    };
    return _this;
  }

  (0, _createClass3.default)(VariableSelect, [{
    key: 'componentWillMount',
    value: function componentWillMount() {
      if (__CLIENT__) {
        this.props.variablesRefresh();
        this.props.getVariables();
      }
    }
  }, {
    key: 'handleClickVariable',
    value: function handleClickVariable(name) {
      var value = this.props.variablesList.findIndex(function (variable) {
        return variable.name === name;
      });
      this.handleSelectVariable({ value: value });
    }
  }, {
    key: 'handleSelectVariable',
    value: function handleSelectVariable(event) {
      var _this2 = this;

      if (event) {
        var variable = this.props.variablesList[event.value];
        var profileValue = null;
        if (variable) {
          if (variable.rule.type === 'OPTION') {
            var rawValue = this.state.profileVariables[variable.name];
            if (rawValue) {
              variable.values.forEach(function (v) {
                if (v.printable_value.text === rawValue[0]) {
                  profileValue = { label: v.printable_value.text, value: v.value };
                }
              });
            }
          } else {
            profileValue = this.state.profileVariables[variable.name] || '';
          }
        }

        this.props.variablesSelectSingle(event.value);
        this.setState((0, _extends4.default)({}, this.state, {
          selectedIndex: event.value,
          selectedVariable: variable,
          selectedValue: profileValue
        }), function () {
          var elements = _reactDom2.default.findDOMNode(_this2).getElementsByClassName('variableInput');
          if (elements.length > 0) {
            elements[0].focus();
          }
        });
      } else {
        this.props.variablesSelectSingle(null);
        this.setState((0, _extends4.default)({}, this.state, {
          selectedIndex: -1,
          selectedVariable: null,
          selectedValue: ''
        }));
      }
    }
  }, {
    key: 'validateEmail',
    value: function validateEmail(email) {
      var pattern = /^(([^<>()[\].,;:\s@"]+(.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
      var validEmail = false;

      if (pattern.test(String(email).toLowerCase())) {
        validEmail = true;
      }
      return validEmail;
    }
  }, {
    key: 'handleSelectValue',
    value: function handleSelectValue(value) {
      if (!value) {
        return;
      }
      var variableValue = void 0;
      if (value.target) {
        variableValue = value.target.value;
      } else if (value.label) {
        variableValue = value.label;
      } else {
        variableValue = value;
      }

      this.setState({
        profileVariables: (0, _extends4.default)({}, this.state.profileVariables, (0, _defineProperty3.default)({}, this.state.selectedVariable.name, variableValue ? variableValue : undefined)),
        selectedValue: variableValue
      });

      if (!variableValue) {
        this.props.removeVariable(this.state.selectedVariable.name);
      } else if (variableValue instanceof Array) {
        this.props.addVariable(this.state.selectedVariable.name, variableValue.map(function (v) {
          return v.value;
        }));
      } else if (variableValue !== null && variableValue.value && (typeof variableValue === 'undefined' ? 'undefined' : (0, _typeof3.default)(variableValue)) === 'object') {
        this.props.addVariable(this.state.selectedVariable.name, variableValue.value);
      } else {
        var variableName = this.state.selectedVariable.name;
        switch (variableName) {
          case 'email':
            this.props.addVariable(variableName, variableValue);
            value.target.className = this.validateEmail(variableValue) ? _VariableSelect2.default.input : _VariableSelect2.default.invalidValue;
            break;
          case 'msisdn':
            value.target.className = isNaN(Number(variableValue)) ? _VariableSelect2.default.invalidValue : _VariableSelect2.default.input;
            this.props.addVariable(variableName, variableValue);
            break;
          default:
            this.props.addVariable(variableName, variableValue);
        }
      }
    }
  }, {
    key: 'handleTagsChange',
    value: function handleTagsChange(tags) {
      this.setState((0, _extends4.default)({}, this.state, {
        tags: tags
      }));
      this.handleSelectValue(tags);
    }
  }, {
    key: 'handleDelete',
    value: function handleDelete(variable, event) {
      event.stopPropagation();
      var profileVariables = this.state.profileVariables;

      this.props.removeVariable(variable, profileVariables[variable]);

      delete profileVariables[variable];

      this.setState({
        profileVariables: profileVariables
      });
    }
  }, {
    key: 'render',
    value: function render() {
      var _this3 = this;

      var selectedVariable = this.state.selectedVariable;


      var selectedVariableInput = null;
      if (selectedVariable) {
        if (selectedVariable.name === 'tags') {
          selectedVariableInput = _react2.default.createElement(_components.TagsSelect, { onChange: this.handleTagsChange, value: this.state.tags, creatable: false });
        } else {
          selectedVariableInput = _react2.default.createElement('input', { className: _VariableSelect2.default.input + ' variableInput', onChange: this.handleSelectValue, value: this.state.selectedValue });
        }
      }

      var selectedVariableOptions = [];
      if (selectedVariable && selectedVariable.rule && selectedVariable.rule.type === 'OPTION') {
        selectedVariableOptions = selectedVariable.values.map(function (variable) {
          return { label: variable.printable_value.text, value: variable.value };
        });
      }

      return _react2.default.createElement(
        'div',
        { className: _VariableSelect2.default.advanced },
        _react2.default.createElement(
          'div',
          { className: _VariableSelect2.default.editor },
          _react2.default.createElement(
            'div',
            { className: _VariableSelect2.default.td1 + ' ' + _VariableSelect2.default.tagsselect },
            _react2.default.createElement(_reactSelect2.default, {
              placeholder: 'Variable',
              className: _VariableSelect2.default.select,
              value: this.state.selectedIndex,
              onChange: this.handleSelectVariable,
              options: this.props.variablesList.map(function (variable, index) {
                return { value: index, label: variable.printable_value.text };
              })
            })
          ),
          _react2.default.createElement(
            'div',
            { className: _VariableSelect2.default.td2 },
            selectedVariableOptions.length > 0 ? _react2.default.createElement(
              'div',
              { className: _VariableSelect2.default.tagsselect },
              _react2.default.createElement(_reactSelect2.default, {
                placeholder: 'Valor',
                multi: this.props.multi,
                className: _VariableSelect2.default.select,
                value: this.state.selectedValue,
                options: selectedVariableOptions,
                onChange: this.handleSelectValue
              })
            ) : this.state.selectedIndex !== -1 && selectedVariableInput
          )
        ),
        _react2.default.createElement(
          'div',
          { className: _VariableSelect2.default.varsTable },
          this.props.variablesList.filter(function (variable) {
            return _this3.state.profileVariables[variable.name] && (_this3.state.profileVariables[variable.name].length > 0 || _this3.state.profileVariables[variable.name].label);
          }).map(function (variable) {
            return _react2.default.createElement(
              'div',
              { key: variable.name + '-profile', className: _VariableSelect2.default.row, onClick: _this3.handleClickVariable.bind(_this3, variable.name) },
              _react2.default.createElement(
                'div',
                { className: _VariableSelect2.default.td1 },
                variable.printable_value.text
              ),
              _react2.default.createElement(
                'div',
                { className: _VariableSelect2.default.td2 },
                _react2.default.createElement(
                  'div',
                  { className: _VariableSelect2.default.text },
                  _this3.state.profileVariables[variable.name].map ? _this3.state.profileVariables[variable.name].map(function (v) {
                    return v.label || v;
                  }).join(', ') : _this3.state.profileVariables[variable.name].label || _this3.state.profileVariables[variable.name]
                )
              ),
              _react2.default.createElement(
                'div',
                { className: _VariableSelect2.default.td3 },
                _react2.default.createElement(md.delete, { size: 30, onClick: _this3.handleDelete.bind(_this3, variable.name) })
              )
            );
          })
        )
      );
    }
  }]);
  return VariableSelect;
}(_react2.default.Component), _class3.propTypes = {
  variablesList: _propTypes2.default.array.isRequired,
  variables: _propTypes2.default.object.isRequired,
  addVariable: _propTypes2.default.func.isRequired,
  removeVariable: _propTypes2.default.func.isRequired,
  getVariables: _propTypes2.default.func.isRequired,
  variablesSelectSingle: _propTypes2.default.func.isRequired,
  variablesRefresh: _propTypes2.default.func.isRequired,
  multi: _propTypes2.default.bool.isRequired
}, _class3.defaultProps = {
  multi: false
}, _temp), (_applyDecoratedDescriptor(_class2.prototype, 'handleSelectVariable', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'handleSelectVariable'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, 'handleSelectValue', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'handleSelectValue'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, 'handleTagsChange', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'handleTagsChange'), _class2.prototype)), _class2)) || _class);
exports.default = VariableSelect;
module.exports = exports['default'];