'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _getOwnPropertyDescriptor = require('babel-runtime/core-js/object/get-own-property-descriptor');

var _getOwnPropertyDescriptor2 = _interopRequireDefault(_getOwnPropertyDescriptor);

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _desc, _value, _class, _class2, _temp;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactHelmet = require('react-helmet');

var _reactHelmet2 = _interopRequireDefault(_reactHelmet);

var _coreDecorators = require('core-decorators');

var _reactSticky = require('react-sticky');

var _components = require('components');

var _Balance = require('../../containers/Reports/Balance');

var _Balance2 = _interopRequireDefault(_Balance);

var _List = require('./List.scss');

var _List2 = _interopRequireDefault(_List);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
  var desc = {};
  Object['ke' + 'ys'](descriptor).forEach(function (key) {
    desc[key] = descriptor[key];
  });
  desc.enumerable = !!desc.enumerable;
  desc.configurable = !!desc.configurable;

  if ('value' in desc || desc.initializer) {
    desc.writable = true;
  }

  desc = decorators.slice().reverse().reduce(function (desc, decorator) {
    return decorator(target, property, desc) || desc;
  }, desc);

  if (context && desc.initializer !== void 0) {
    desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
    desc.initializer = undefined;
  }

  if (desc.initializer === void 0) {
    Object['define' + 'Property'](target, property, desc);
    desc = null;
  }

  return desc;
}

var RECORDS_PER_REQUEST = 100;
var REQUEST_RETRIES = 3;

var AdminList = (_class = (_temp = _class2 = function (_React$Component) {
  (0, _inherits3.default)(AdminList, _React$Component);

  function AdminList(props) {
    (0, _classCallCheck3.default)(this, AdminList);

    var _this = (0, _possibleConstructorReturn3.default)(this, (AdminList.__proto__ || (0, _getPrototypeOf2.default)(AdminList)).call(this, props));

    _this.state = {
      hasMoreRows: true,
      loading: false
    };

    _this.loadingTimeout = null;
    return _this;
  }

  (0, _createClass3.default)(AdminList, [{
    key: 'componentWillMount',
    value: function componentWillMount() {
      if (__CLIENT__) {
        if (this.props.refresh) {
          this.props.refresh();
          this.loadMoreRows();
        }
      }
    }
  }, {
    key: 'componentWillUnmount',
    value: function componentWillUnmount() {
      clearTimeout(this.loadingTimeout);
    }
  }, {
    key: 'setActive',
    value: function setActive(index, active, event) {
      event.preventDefault();
      if (active) {
        this.props.subaccountUnselectAll();
        this.props.select(index);
      } else {
        this.props.unselect(index);
      }
    }
  }, {
    key: 'loadMoreRows',
    value: function loadMoreRows() {
      var _this2 = this;

      var retries = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : REQUEST_RETRIES;

      this.setState((0, _extends3.default)({}, this.state, { loading: true }));
      this.loadingTimeout = setTimeout(function () {
        var start = _this2.props.list.length;
        var limit = RECORDS_PER_REQUEST;
        if (_this2.props.get && !_this2.props.isLoading && _this2.state.hasMoreRows) {
          return _this2.props.get({ start: start, limit: limit }).then(function (list) {
            var hasMoreRows = list.length !== 0;
            _this2.setState((0, _extends3.default)({}, _this2.state, {
              hasMoreRows: hasMoreRows,
              loading: false
            }));
          }).catch(function (err) {
            console.error('::List:loadMoreRows(' + (3 - retries) + ')', err);
            if (retries > 0) {
              setTimeout(function () {
                _this2.loadMoreRows(retries - 1);
              }, 1500);
            } else {
              _this2.setState((0, _extends3.default)({}, _this2.state, {
                hasMoreRows: false,
                loading: false
              }));
            }
          });
        }
      }, 500);
    }
  }, {
    key: 'isSelecting',
    value: function isSelecting() {
      return this.props.selected.length !== 0;
    }
  }, {
    key: 'refreshRows',
    value: function refreshRows() {
      this.props.refresh();
    }
  }, {
    key: 'renderHeader',
    value: function renderHeader() {
      var mobileMode = this.props.mobileMode;

      var background = this.isSelecting() ? { background: _List2.default.contextNavBackground } : { background: '#FFF' };
      if (mobileMode) {
        return null;
      }
      return _react2.default.createElement(_components.GlobalTabs, { activeStyle: background });
    }
  }, {
    key: 'renderNav',
    value: function renderNav() {
      return null;
    }
  }, {
    key: 'renderRight',
    value: function renderRight() {
      return _react2.default.createElement(_Balance2.default, null);
    }
  }, {
    key: 'render',
    value: function render() {
      var _this3 = this;

      return _react2.default.createElement(
        _reactSticky.StickyContainer,
        null,
        this.title ? _react2.default.createElement(_reactHelmet2.default, { title: this.title }) : null,
        this.renderHeader(),
        _react2.default.createElement(
          _reactSticky.Sticky,
          { style: { zIndex: 100 } },
          function (props) {
            return _this3.renderNav(props);
          }
        ),
        _react2.default.createElement(
          _components.AdminMenuContainer,
          { rightColumn: this.renderRight(), mobileMode: this.props.mobileMode },
          this.state.loading && this.props.list.length === 0 ? _react2.default.createElement(_components.VirtualListItemLoad, null) : _react2.default.createElement(
            'div',
            { className: _List2.default.listmain },
            this.renderInfiniteList()
          )
        )
      );
    }
  }]);
  return AdminList;
}(_react2.default.Component), _class2.propTypes = {
  mobileMode: _propTypes2.default.bool
}, _class2.defaultProps = {
  mobileMode: false
}, _temp), (_applyDecoratedDescriptor(_class.prototype, 'setActive', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class.prototype, 'setActive'), _class.prototype), _applyDecoratedDescriptor(_class.prototype, 'loadMoreRows', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class.prototype, 'loadMoreRows'), _class.prototype), _applyDecoratedDescriptor(_class.prototype, 'refreshRows', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class.prototype, 'refreshRows'), _class.prototype)), _class);
exports.default = AdminList;
module.exports = exports['default'];