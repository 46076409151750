'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _dec2, _class, _class2, _temp;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactDatetime = require('react-datetime');

var _reactDatetime2 = _interopRequireDefault(_reactDatetime);

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _Report = require('./Report.scss');

var _Report2 = _interopRequireDefault(_Report);

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _reduxConnect = require('redux-connect');

var _reactRedux = require('react-redux');

var _themeEnabled = require('helpers/themeEnabled');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ReportFilter = (_dec = (0, _reduxConnect.asyncConnect)([{
  key: 'account',
  promise: function promise(_ref) {
    var _ref$store = _ref.store,
        dispatch = _ref$store.dispatch,
        getState = _ref$store.getState;

    var state = getState();
    if (!state.account.data.reach) {
      return dispatch(getAccount());
    }
    return null;
  }
}]), _dec2 = (0, _reactRedux.connect)(function (state) {
  return {
    account: state.account.data,
    status: state.status.data,
    reach: state.account.data.reach
  };
}), _dec(_class = _dec2(_class = (_temp = _class2 = function (_React$Component) {
  (0, _inherits3.default)(ReportFilter, _React$Component);

  function ReportFilter(props) {
    (0, _classCallCheck3.default)(this, ReportFilter);

    var _this = (0, _possibleConstructorReturn3.default)(this, (ReportFilter.__proto__ || (0, _getPrototypeOf2.default)(ReportFilter)).call(this, props));

    _this.state = {
      charge_type: null
    };
    return _this;
  }

  (0, _createClass3.default)(ReportFilter, [{
    key: 'handleDateChange',
    value: function handleDateChange(cycle, startDate, endDate) {
      this.props.onDateChange(cycle, startDate, endDate);
    }
  }, {
    key: 'componentDidMount',
    value: function componentDidMount() {
      try {
        if (this.props.reach.billing.charge_type) {
          this.setState({ charge_type: this.props.reach.billing.charge_type });
        }
      } catch (e) {
        console.log('could no get prepaid info', e);
      }
    }
  }, {
    key: 'render',
    value: function render() {
      var _props = this.props,
          startDate = _props.startDate,
          endDate = _props.endDate,
          type = _props.type;

      var startDate1 = (0, _moment2.default)(this.props.status && this.props.status.current_package ? this.props.status.current_package.valid_since : null);
      var startDate3 = (0, _moment2.default)(this.props.status && this.props.status.current_package ? this.props.status.current_package.valid_since : null);
      var endDate2 = (0, _moment2.default)(this.props.status && this.props.status.current_package ? this.props.status.current_package.valid_thru : null);
      var today = (0, _moment2.default)();
      var prepaid = this.state.charge_type == 'PREPAID';
      var classesTheme = (0, _themeEnabled.getThemeEnabledStyle)(_Report2.default);
      return _react2.default.createElement(
        'div',
        { className: classesTheme },
        _react2.default.createElement(
          'div',
          { className: _Report2.default.filter },
          _react2.default.createElement(
            'div',
            { className: _Report2.default.datePicker },
            !prepaid ? _react2.default.createElement(
              'div',
              null,
              _react2.default.createElement(
                'div',
                { className: _Report2.default.title },
                'Filtrar por ciclo'
              ),
              _react2.default.createElement(
                'div',
                { className: _Report2.default.col },
                _react2.default.createElement(
                  'a',
                  { className: _Report2.default.item, onClick: this.handleDateChange.bind(this, (0, _translations2.default)('LAST DAY'), (0, _moment2.default)(startDate1).startOf('day'), (0, _moment2.default)(endDate2).endOf('day')) },
                  (0, _translations2.default)('LAST DAY')
                ),
                _react2.default.createElement(
                  'a',
                  { className: _Report2.default.item, onClick: this.handleDateChange.bind(this, (0, _translations2.default)('LAST WEEK'), (0, _moment2.default)(startDate1).subtract(1, 'month').startOf('day'), (0, _moment2.default)(startDate1).subtract(1, 'ms').endOf('day')) },
                  (0, _translations2.default)('LAST WEEK')
                ),
                _react2.default.createElement(
                  'a',
                  { className: _Report2.default.item, onClick: this.handleDateChange.bind(this, (0, _translations2.default)('LAST MONTH'), (0, _moment2.default)(startDate1).subtract(2, 'month').startOf('day'), (0, _moment2.default)(startDate1).subtract(1, 'month').subtract(1, 'ms').endOf('day')) },
                  (0, _translations2.default)('LAST MONTH')
                ),
                _react2.default.createElement(
                  'a',
                  { className: _Report2.default.item, onClick: this.handleDateChange.bind(this, (0, _translations2.default)('LAST YEAR'), (0, _moment2.default)(startDate1).subtract(3, 'month').startOf('day'), (0, _moment2.default)(startDate1).subtract(2, 'month').subtract(1, 'ms').endOf('day')) },
                  (0, _translations2.default)('LAST YEAR')
                )
              )
            ) : null,
            type !== "balance" ? _react2.default.createElement(
              'div',
              null,
              _react2.default.createElement(
                'div',
                { className: _Report2.default.title },
                'Otros filtros'
              ),
              _react2.default.createElement(
                'div',
                { className: _Report2.default.col },
                _react2.default.createElement(
                  'a',
                  { className: _Report2.default.item, onClick: this.handleDateChange.bind(this, (0, _translations2.default)('THIS DAY'), (0, _moment2.default)(today).startOf('day'), (0, _moment2.default)(today).endOf('day')) },
                  (0, _translations2.default)('THIS DAY')
                ),
                _react2.default.createElement(
                  'a',
                  { className: _Report2.default.item, onClick: this.handleDateChange.bind(this, (0, _translations2.default)('THIS WEEK'), (0, _moment2.default)(today).startOf('week'), (0, _moment2.default)(today).endOf('week')) },
                  (0, _translations2.default)('THIS WEEK')
                ),
                _react2.default.createElement(
                  'a',
                  { className: _Report2.default.item, onClick: this.handleDateChange.bind(this, (0, _translations2.default)('THIS MONTH'), (0, _moment2.default)(today).startOf('month'), (0, _moment2.default)(today).endOf('month')) },
                  (0, _translations2.default)('THIS MONTH')
                ),
                type == "harassment" ? _react2.default.createElement(
                  'a',
                  { className: _Report2.default.item, onClick: this.handleDateChange.bind(this, (0, _translations2.default)('Mes Anterior'), (0, _moment2.default)(today).subtract(1, 'month').startOf('month'), (0, _moment2.default)(today).subtract(1, 'month').endOf('month')) },
                  (0, _translations2.default)('Mes anterior')
                ) : _react2.default.createElement(
                  'a',
                  { className: _Report2.default.item, onClick: this.handleDateChange.bind(this, (0, _translations2.default)('Último trimestre'), (0, _moment2.default)(today).subtract(2, 'month').startOf('month'), (0, _moment2.default)(today).subtract(1, 'ms').endOf('month')) },
                  (0, _translations2.default)('Último trimestre')
                )
              )
            ) : null
          ),
          type == "balance" ? _react2.default.createElement(
            'div',
            { className: _Report2.default.date },
            _react2.default.createElement(
              'div',
              { className: _Report2.default.row },
              _react2.default.createElement(
                'div',
                { className: _Report2.default.dateField },
                _react2.default.createElement(
                  'div',
                  { className: _Report2.default.inputLabel },
                  (0, _translations2.default)('Filtrar por fecha')
                ),
                _react2.default.createElement(_reactDatetime2.default, { value: startDate, onChange: this.handleDateChange.bind(this, null) })
              )
            )
          ) : _react2.default.createElement(
            'div',
            { className: _Report2.default.date },
            _react2.default.createElement(
              'div',
              { className: _Report2.default.row },
              _react2.default.createElement(
                'div',
                { className: _Report2.default.dateField },
                _react2.default.createElement(
                  'div',
                  { className: _Report2.default.inputLabel },
                  (0, _translations2.default)('Filtrar por fecha, desde')
                ),
                _react2.default.createElement(_reactDatetime2.default, { value: startDate, onChange: this.handleDateChange.bind(this, null) })
              ),
              _react2.default.createElement(
                'div',
                { className: _Report2.default.dateField },
                _react2.default.createElement(
                  'div',
                  { className: _Report2.default.inputLabel },
                  (0, _translations2.default)('TO')
                ),
                _react2.default.createElement(_reactDatetime2.default, { value: endDate, onChange: this.handleDateChange.bind(this, null, null) })
              )
            )
          )
        )
      );
    }
  }]);
  return ReportFilter;
}(_react2.default.Component), _class2.propTypes = {
  type: _propTypes2.default.string,
  startDate: _propTypes2.default.object.isRequired,
  endDate: _propTypes2.default.object.isRequired,
  onDateChange: _propTypes2.default.func.isRequired
}, _temp)) || _class) || _class);
exports.default = ReportFilter;
module.exports = exports['default'];