'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.WHATSAPP = exports.HTML = exports.MEDIA_OPTIONS = exports.NPS = exports.BANDWIDTH = exports.MEDIA_QUESTION = exports.GENDER = exports.AGE = exports.MULTIPLE_CHOICE = exports.YES_NO = exports.RANGE = exports.OPEN = exports.OPTIONS = undefined;

var _getOwnPropertyDescriptor = require('babel-runtime/core-js/object/get-own-property-descriptor');

var _getOwnPropertyDescriptor2 = _interopRequireDefault(_getOwnPropertyDescriptor);

var _stringify = require('babel-runtime/core-js/json/stringify');

var _stringify2 = _interopRequireDefault(_stringify);

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _extends3 = require('babel-runtime/helpers/extends');

var _extends4 = _interopRequireDefault(_extends3);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _class, _desc, _value, _class2, _class3, _temp;
// import { Thumbnail } from './Media'
// import GalleryModal from './GalleryModal'


var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactRedux = require('react-redux');

var _coreDecorators = require('core-decorators');

var _reduxConnect = require('redux-connect');

var _draftJs = require('draft-js');

var _components = require('components');

var _interaction = require('redux/modules/interaction');

var _Question = require('./Question.scss');

var _Question2 = _interopRequireDefault(_Question);

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
  var desc = {};
  Object['ke' + 'ys'](descriptor).forEach(function (key) {
    desc[key] = descriptor[key];
  });
  desc.enumerable = !!desc.enumerable;
  desc.configurable = !!desc.configurable;

  if ('value' in desc || desc.initializer) {
    desc.writable = true;
  }

  desc = decorators.slice().reverse().reduce(function (desc, decorator) {
    return decorator(target, property, desc) || desc;
  }, desc);

  if (context && desc.initializer !== void 0) {
    desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
    desc.initializer = undefined;
  }

  if (desc.initializer === void 0) {
    Object['define' + 'Property'](target, property, desc);
    desc = null;
  }

  return desc;
}

var jumpTypes = {
  NEXT: 'NEXT',
  END_OF_INTERACTION: 'END_OF_INTERACTION',
  QUESTION: 'QUESTION',
  CUSTOM_URL: 'CUSTOM_URL'
};

var CONFIGURATION = {
  minQuestions: 1,
  maxQuestionLength: 160,
  maxHtmlQuestionLength: 8000,
  questionTypes: [// Use this to disable question types
  'OPTIONS', 'OPEN', 'RANGE', 'YES_NO', 'MULTIPLE_CHOICE',
  // 'AGE',
  // 'GENDER',
  // 'BANDWIDTH',
  'WHATSAPP', 'HTML', 'NPS']
};

var OPTIONS = exports.OPTIONS = 'OPTIONS';
var OPEN = exports.OPEN = 'OPEN';
var RANGE = exports.RANGE = 'RANGE';
var YES_NO = exports.YES_NO = 'YES_NO';
var MULTIPLE_CHOICE = exports.MULTIPLE_CHOICE = 'MULTIPLE_CHOICE';
var AGE = exports.AGE = 'AGE';
var GENDER = exports.GENDER = 'GENDER';
var MEDIA_QUESTION = exports.MEDIA_QUESTION = 'MEDIA_QUESTION';
var BANDWIDTH = exports.BANDWIDTH = 'BANDWIDTH';
var NPS = exports.NPS = 'NPS';
var MEDIA_OPTIONS = exports.MEDIA_OPTIONS = 'MEDIA_OPTIONS';
var HTML = exports.HTML = 'HTML';
var WHATSAPP = exports.WHATSAPP = 'WHATSAPP';

var md = {
  delete: require('react-icons/lib/md/delete'),
  add: require('react-icons/lib/md/add')
};

var Question = (_dec = (0, _reactRedux.connect)(function (state) {
  return {
    questions: state.interaction.questions || [],
    channels: state.interaction.configuration ? state.interaction.configuration.interaction_channels : [],
    account: state.account.data,
    questionRegex: state.questionregex
  };
}, function (dispatch, ownProps) {
  return {
    changeQuestionProperty: function changeQuestionProperty(property, value) {
      var val = value.target ? value.target.value : value;
      dispatch((0, _interaction.updateInteractionQuestion)(ownProps.question.question_number, property, val));
    },
    changeQuestionOption: function changeQuestionOption(optionIndex, event) {
      dispatch((0, _interaction.updateInteractionOption)(ownProps.question.question_number, optionIndex, 'text', event.target.value));
    },
    addOption: function addOption() {
      dispatch((0, _interaction.addOption)(ownProps.question.question_number));
    },
    deleteOption: function deleteOption(optionNum) {
      dispatch((0, _interaction.deleteOption)(ownProps.question.question_number, optionNum));
    },
    removeQuestion: function removeQuestion() {
      dispatch((0, _interaction.deleteQuestion)(ownProps.question.question_number - 1));
    },
    changeQuestionOptions: function changeQuestionOptions(options) {
      dispatch((0, _interaction.updateInteractionOptions)(ownProps.question.question_number, options));
    },
    changeQuestionType: function changeQuestionType(responseType, responseAlternativeType) {
      dispatch((0, _interaction.updateInteractionType)(ownProps.question.question_number, responseType, responseAlternativeType));
    },
    changeQuestionJump: function changeQuestionJump(event) {
      var jump = event.target.value;
      if (jump !== jumpTypes.NEXT) {
        var next = {};
        if (jump === jumpTypes.END_OF_INTERACTION) {
          next.type = jumpTypes.END_OF_INTERACTION;
          next.question_uid = null;
        } else if (jump === jumpTypes.CUSTOM_URL) {
          next.type = jumpTypes.CUSTOM_URL;
          next.question_uid = null;
        } else {
          next.type = jumpTypes.QUESTION;
          next.question_uid = jump;
        }
        dispatch((0, _interaction.updateInteractionQuestion)(ownProps.question.question_number, 'next', next));
      } else {
        dispatch((0, _interaction.updateInteractionQuestion)(ownProps.question.question_number, 'next', null));
      }
    },
    changeOptionJump: function changeOptionJump(optionIndex, event) {
      var jump = event.target.value;
      if (jump !== jumpTypes.NEXT) {
        var next = {};
        if (jump === jumpTypes.END_OF_INTERACTION) {
          next.type = jumpTypes.END_OF_INTERACTION;
          next.question_uid = null;
        } else {
          next.type = jumpTypes.QUESTION;
          next.question_uid = jump;
        }
        dispatch((0, _interaction.updateInteractionOption)(ownProps.question.question_number, optionIndex, 'next', next));
      } else {
        dispatch((0, _interaction.updateInteractionOption)(ownProps.question.question_number, optionIndex, 'next', null));
      }
    },
    updateInteractionQuestionsSms: function updateInteractionQuestionsSms() {
      dispatch((0, _interaction.updateInteractionQuestionsSms)());
    }
  };
}), _dec(_class = (_class2 = (_temp = _class3 = function (_React$Component) {
  (0, _inherits3.default)(Question, _React$Component);

  function Question(props) {
    (0, _classCallCheck3.default)(this, Question);

    var _this = (0, _possibleConstructorReturn3.default)(this, (Question.__proto__ || (0, _getPrototypeOf2.default)(Question)).call(this, props));

    _this.questionTypesTranslations = {
      OPTIONS: (0, _translations2.default)('OPTIONS'),
      OPEN: (0, _translations2.default)('OPEN'),
      RANGE: (0, _translations2.default)('RANGE'),
      YES_NO: (0, _translations2.default)('YES_NO'),
      MULTIPLE_CHOICE: (0, _translations2.default)('MULTIPLE_CHOICE'),
      AGE: (0, _translations2.default)('AGE'),
      GENDER: (0, _translations2.default)('GENDER'),
      HTML: (0, _translations2.default)('HTML'),
      BANDWIDTH: (0, _translations2.default)('BANDWIDTH'),
      NPS: (0, _translations2.default)('NPS'),
      MEDIA_OPTIONS: (0, _translations2.default)('MEDIA OPTIONS'),
      WHATSAPP: (0, _translations2.default)('WHATSAPP')
    };

    var s3 = 'https://s3.amazonaws.com/public-im-resources';
    _this.questionTypesImages = {
      OPTIONS: s3 + '/QuestionSVGs/WYSIWYG-SVG-01.svg',
      OPEN: s3 + '/QuestionSVGs/WYSIWYG-SVG-02.svg',
      RANGE: s3 + '/QuestionSVGs/WYSIWYG-SVG-03.svg',
      YES_NO: s3 + '/QuestionSVGs/WYSIWYG-SVG-04.svg',
      MULTIPLE_CHOICE: s3 + '/QuestionSVGs/WYSIWYG-SVG-05.svg',
      AGE: s3 + '/QuestionSVGs/WYSIWYG-SVG-06.svg',
      WHATSAPP: s3 + '/QuestionSVGs/WYSIWYG-SVG-07.svg',
      HTML: s3 + '/QuestionSVGs/WYSIWYG-SVG-08.svg',
      NPS: s3 + '/QuestionSVGs/WYSIWYG-SVG-09.svg',
      GENDER: s3 + '/QuestionSVGs/WYSIWYG-SVG-10.svg',
      BANDWIDTH: s3 + '/QuestionSVGs/WYSIWYG-SVG-11.svg',
      MEDIA_OPTIONS: s3 + '/QuestionSVGs/WYSIWYG-SVG-12.svg'
    };

    _this.questionType = _this.getQuestionType(_this.props.question.response_type, _this.props.question.response_alternative_type, CONFIGURATION.questionTypes);

    _this.state = {
      editorState: _draftJs.EditorState.createEmpty()
    };

    _this.editorReferece = null;
    _this.optionsReference = null;
    return _this;
  }

  (0, _createClass3.default)(Question, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      var _this2 = this;

      if (__CLIENT__) {
        setTimeout(function () {
          if (_this2.props.question.config && _this2.props.question.config.rawContent) {
            var rawContent = JSON.parse(_this2.props.question.config.rawContent);
            var editorState = _draftJs.EditorState.createWithContent((0, _draftJs.convertFromRaw)(rawContent));
            _this2.setState({
              editorState: editorState
            });
          } else {
            var _rawContent = {
              entityMap: {},
              blocks: [{
                key: '18ql9',
                text: _this2.props.question.text,
                type: 'unstyled',
                depth: 0,
                inlineStyleRanges: [],
                entityRanges: []
              }]
            };
            var _editorState = _draftJs.EditorState.createWithContent((0, _draftJs.convertFromRaw)(_rawContent));
            _this2.setState({
              editorState: _editorState
            });
          }
        }, 500);
      }
    }
  }, {
    key: 'componentDidUpdate',
    value: function componentDidUpdate(prevProps) {
      var curRegex = this.props.question["question_regex_uid"];
      var regexList = this.props.questionRegex.list;
      if (curRegex == null && regexList.length > 0) {
        this.setDefaultQuestionRegex(this.props.questionRegex.list);
      }
    }
  }, {
    key: 'setDefaultQuestionRegex',
    value: function setDefaultQuestionRegex(questionRegex) {
      for (var index in questionRegex) {
        var regex = questionRegex[index];
        if (regex["default"] == true && this.props.question["question_regex_uid"] == null) this.props.changeQuestionProperty("question_regex_uid", regex["_id"]);
      }
    }
  }, {
    key: 'getQuestionType',
    value: function getQuestionType(responseType, responseAlternativeType) {
      var questionType = responseAlternativeType || responseType;
      if (CONFIGURATION.questionTypes.indexOf(questionType) !== -1) {
        return questionType;
      }
      return OPEN;
    }
  }, {
    key: 'setQuestionType',
    value: function setQuestionType(value) {
      var questionType = value;
      if (CONFIGURATION.questionTypes.indexOf(questionType) === -1) {
        return;
      }
      var responseType = null;
      var responseAlternativeType = null;
      switch (questionType) {
        case OPTIONS:
          responseType = OPTIONS;
          responseAlternativeType = null;
          break;
        case RANGE:
          responseType = RANGE;
          responseAlternativeType = null;
          break;
        case YES_NO:
          responseType = OPTIONS;
          responseAlternativeType = YES_NO;
          break;
        case AGE:
          responseType = AGE;
          responseAlternativeType = null;
          break;
        case GENDER:
          responseType = OPTIONS;
          responseAlternativeType = GENDER;
          break;
        case NPS:
          responseType = RANGE;
          responseAlternativeType = NPS;
          break;
        case MEDIA_OPTIONS:
          responseType = OPTIONS;
          responseAlternativeType = MEDIA_OPTIONS;
          break;
        case MEDIA_QUESTION:
          responseType = OPEN;
          responseAlternativeType = MEDIA_QUESTION;
          break;
        case HTML:
          responseType = OPEN;
          responseAlternativeType = HTML;
          break;
        case WHATSAPP:
          responseType = OPEN;
          responseAlternativeType = WHATSAPP;
          break;
        case MULTIPLE_CHOICE:
          responseType = MULTIPLE_CHOICE;
          responseAlternativeType = null;
          break;
        default:
          responseType = OPEN;
          responseAlternativeType = null;
      }
      this.props.changeQuestionType(responseType, responseAlternativeType);
      this.questionType = this.getQuestionType(responseType, responseAlternativeType);

      if (questionType === OPTIONS || questionType === MULTIPLE_CHOICE) {
        this.props.changeQuestionOptions([{ text: '' }]);
      }
      if (questionType === YES_NO) {
        this.props.changeQuestionOptions([{
          text: 'Si'
        }, {
          text: 'No'
        }]);
      }
      if (questionType === GENDER) {
        this.props.changeQuestionOptions([{
          text: 'Hombre'
        }, {
          text: 'Mujer'
        }]);
      }
      if (questionType === HTML || questionType === WHATSAPP) {
        this.props.changeQuestionProperty('config', {
          buttonText: questionType === HTML ? 'Siguiente' : 'Continuar en Whatsapp',
          buttonLink: questionType === HTML ? '' : ' ',
          buttonMessage: (0, _translations2.default)('WHATSAPP DEFAULT MESSAGE')
        });
      }
      if (questionType === AGE) {
        this.props.changeQuestionProperty('age_min', 0);
        this.props.changeQuestionProperty('age_max', 99);
      }
      if (questionType === RANGE) {
        this.props.changeQuestionProperty('range_min', 0);
        this.props.changeQuestionProperty('range_max', 99);
      }
      if (questionType === NPS) {
        this.props.changeQuestionProperty('text', (0, _translations2.default)('DEFAULT NPS QUESTION'));
      } else {
        this.props.changeQuestionProperty('text', '');
        this.setState({
          editorState: _draftJs.EditorState.createEmpty()
        });
      }
    }
  }, {
    key: 'getQuestionLenght',
    value: function getQuestionLenght() {
      var len = this.props.question.text ? this.props.question.text.length : 0;
      if (this.props.question.options) {
        this.props.question.options.forEach(function (option) {
          if (option.text) {
            len += option.text.length || 0;
          }
        });
      }
      return len;
    }
  }, {
    key: 'setEditorReference',
    value: function setEditorReference(ref) {
      this.editorReferece = ref;
    }
  }, {
    key: 'setOptionsReference',
    value: function setOptionsReference(ref) {
      this.optionsReference = ref;
    }
  }, {
    key: 'changeQuestion',
    value: function changeQuestion(property) {
      var num = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var event = arguments[2];

      if (property === OPTIONS) {
        this.props.changeQuestionOption(num, event);
      } else {
        this.props.changeQuestionProperty(property, event);
      }
    }
  }, {
    key: 'changeQuestionConfig',
    value: function changeQuestionConfig(extraprop, value) {
      this.props.changeQuestionProperty('config', (0, _extends4.default)({}, this.props.question.config, (0, _defineProperty3.default)({}, extraprop, value)));
    }
  }, {
    key: 'handleChangeQuestionType',
    value: function handleChangeQuestionType(event) {
      this.setQuestionType(event.target.value);
    }
  }, {
    key: 'handleOptionKeyPress',
    value: function handleOptionKeyPress(event) {
      if (event.key === 'Enter') {
        this.props.addOption();
      }
    }
  }, {
    key: 'handleOptionKeyDown',
    value: function handleOptionKeyDown(option, optionNum, event) {
      if (event.keyCode === 8) {
        if (option.text.length === 0) {
          this.props.deleteOption(optionNum);
        }
      }
    }
  }, {
    key: 'handleAddOption',
    value: function handleAddOption() {
      this.props.addOption();
    }
  }, {
    key: 'handleRangeChange',
    value: function handleRangeChange(range, event) {
      var value = 0;
      try {
        value = parseInt(event.target.value, 10);
      } catch (e) {
        console.error('Invalid number');
      }
      this.props.changeQuestionProperty(range, value);
    }
  }, {
    key: 'handleQuestionConfig',
    value: function handleQuestionConfig(extraprop, e) {
      this.changeQuestionConfig(extraprop, e.target.value);
    }
  }, {
    key: 'questionJump',
    value: function questionJump(question, jumpableOptions) {
      return _react2.default.createElement(
        'div',
        { className: 'question-jump' },
        _react2.default.createElement(
          'div',
          { className: 'type' },
          (0, _translations2.default)('JUMP'),
          ':'
        ),
        _react2.default.createElement(
          'div',
          { className: 'select' },
          _react2.default.createElement(
            'select',
            {
              className: 'browser-default',
              value: function () {
                if (question.next && question.next.type) {
                  return question.next.type === jumpTypes.QUESTION ? question.next.question_uid : question.next.type;
                }
                return jumpTypes.NEXT;
              }(),
              onChange: this.props.changeQuestionJump
            },
            jumpableOptions
          )
        )
      );
    }
  }, {
    key: 'range',
    value: function range(question, jumpableOptions) {
      return _react2.default.createElement(
        'div',
        null,
        _react2.default.createElement(
          'div',
          { className: 'question-range options-container' },
          _react2.default.createElement(
            'div',
            { className: 'input-field option' },
            _react2.default.createElement(
              'div',
              { className: 'option-row' },
              _react2.default.createElement(
                'label',
                null,
                'Desde'
              ),
              _react2.default.createElement('input', { type: 'number', onChange: this.handleRangeChange.bind(this, 'range_min'), value: question.range_min })
            )
          ),
          _react2.default.createElement(
            'div',
            { className: 'input-field option' },
            _react2.default.createElement(
              'div',
              { className: 'option-row' },
              _react2.default.createElement(
                'label',
                null,
                'Hasta'
              ),
              _react2.default.createElement('input', { type: 'number', onChange: this.handleRangeChange.bind(this, 'range_max'), value: question.range_max })
            )
          )
        ),
        _react2.default.createElement(
          'div',
          { className: 'question-jump' },
          _react2.default.createElement(
            'div',
            { className: 'type' },
            (0, _translations2.default)('JUMP'),
            ':'
          ),
          _react2.default.createElement(
            'div',
            { className: 'select' },
            _react2.default.createElement(
              'select',
              { className: 'browser-default',
                value: function () {
                  if (question.next && question.next.type) {
                    return question.next.type === jumpTypes.QUESTION ? question.next.question_uid : question.next.type;
                  }
                  return jumpTypes.NEXT;
                }(),
                onChange: this.props.changeQuestionJump
              },
              jumpableOptions
            )
          )
        )
      );
    }
  }, {
    key: 'optionsJumps',
    value: function optionsJumps(question, jumpableOptions) {
      var _this3 = this;

      var optionsDiv = question.options.map(function (option, optionNum) {
        return _react2.default.createElement(
          'div',
          { className: 'input-field option', key: 'option-jump-key-' + optionNum },
          _react2.default.createElement(
            'div',
            { className: 'option-row' },
            _react2.default.createElement(
              'label',
              { className: 'active' },
              option.text ? optionNum + 1 + ' ' + option.text : (0, _translations2.default)('OPTION') + ' ' + (optionNum + 1),
              ' ',
              _react2.default.createElement(
                'span',
                { className: 'lower' },
                ' ',
                (0, _translations2.default)('JUMP'),
                ':'
              )
            ),
            _this3.optionJump(option, jumpableOptions, _this3.props.changeOptionJump.bind(_this3, optionNum))
          )
        );
      });

      return _react2.default.createElement(
        'div',
        { className: 'options-container' },
        optionsDiv
      );
    }
  }, {
    key: 'options',
    value: function options() {
      var _this4 = this;

      var questionType = this.questionType;
      var question = this.props.question;

      var optionsDiv = question.options.map(function (option, optionNum) {
        return _react2.default.createElement(
          'div',
          { className: _Question2.default.option, key: 'option-key-' + optionNum },
          _react2.default.createElement(
            'label',
            { htmlFor: 'option-' + optionNum, className: _Question2.default.label },
            optionNum + 1
          ),
          _react2.default.createElement('input', {
            autoFocus: true,
            id: 'option-' + optionNum,
            type: 'text',
            className: _Question2.default.input,
            value: option.text,
            onChange: _this4.changeQuestion.bind(_this4, OPTIONS, optionNum),
            onKeyPress: _this4.handleOptionKeyPress,
            onKeyDown: _this4.handleOptionKeyDown.bind(_this4, option, optionNum)
          }),
          _react2.default.createElement(md.delete, { onClick: _this4.props.deleteOption.bind(_this4, optionNum), className: _Question2.default.remove, size: 25 })
        );
      });

      return _react2.default.createElement(
        'div',
        { className: _Question2.default.options },
        _react2.default.createElement(
          'div',
          { id: 'options', className: _Question2.default.optionsScroll },
          optionsDiv
        ),
        questionType === YES_NO || questionType === GENDER ? null : _react2.default.createElement(
          'div',
          { className: _Question2.default.addOption, onClick: this.handleAddOption },
          _react2.default.createElement(md.add, { size: 20 }),
          (0, _translations2.default)('ADD OPTION')
        )
      );
    }
  }, {
    key: 'optionJump',
    value: function optionJump(question, jumpableOptions, valueChanged) {
      return _react2.default.createElement(
        'div',
        { className: 'option-jump select' },
        _react2.default.createElement(
          'select',
          {
            className: 'browser-default',
            value: function () {
              if (question.next && question.next.type) {
                return question.next.type === jumpTypes.QUESTION ? question.next.question_uid : question.next.type;
              }
              return jumpTypes.NEXT;
            }(),
            onChange: valueChanged
          },
          jumpableOptions
        )
      );
    }
  }, {
    key: 'buttonJump',
    value: function buttonJump(question, jumpableOptions) {
      var questionType = this.questionType;
      var extra = question.config;
      if (!extra) {
        return null;
      }

      return _react2.default.createElement(
        'div',
        { className: 'question-jump options-container' },
        _react2.default.createElement(
          'div',
          { className: 'input-field option' },
          _react2.default.createElement(
            'div',
            { className: 'option-row' },
            _react2.default.createElement(
              'label',
              { className: 'active' },
              (0, _translations2.default)('BUTTON TEXT')
            ),
            _react2.default.createElement('input', { id: 'button-text-' + question.question_number, type: 'text', className: 'validate option-input', value: extra.buttonText, onChange: this.handleQuestionConfig.bind(this, 'buttonText') })
          )
        ),
        questionType === HTML && _react2.default.createElement(
          'div',
          { className: 'type' },
          (0, _translations2.default)('JUMP'),
          ':'
        ),
        questionType === HTML ? _react2.default.createElement(
          'div',
          { className: 'select' },
          _react2.default.createElement(
            'select',
            { className: 'browser-default',
              value: function () {
                if (question.next && question.next.type) {
                  var questionNextType = jumpTypes.NEXT;
                  if (question.next.type === jumpTypes.QUESTION) {
                    questionNextType = question.next.question_uid;
                  } else if (question.next.type === jumpTypes.END_OF_INTERACTION && question.config.buttonLink) {
                    questionNextType = jumpTypes.CUSTOM_URL;
                  } else {
                    questionNextType = question.next.type;
                  }
                  return questionNextType;
                }
                return jumpTypes.NEXT;
              }(),
              onChange: this.props.changeQuestionJump
            },
            _react2.default.createElement(
              'option',
              { value: jumpTypes.CUSTOM_URL },
              (0, _translations2.default)('CUSTOM URL')
            ),
            jumpableOptions
          )
        ) : null,
        questionType === HTML && question.next && (question.next.type === jumpTypes.CUSTOM_URL || question.next.type === jumpTypes.END_OF_INTERACTION && question.config.buttonLink) && _react2.default.createElement(
          'div',
          { className: 'input-field option' },
          _react2.default.createElement(
            'div',
            { className: 'option-row' },
            _react2.default.createElement(
              'label',
              { className: 'active' },
              (0, _translations2.default)('CUSTOM URL')
            ),
            _react2.default.createElement('input', { type: 'text', className: 'validate option-input', value: extra.buttonLink, onChange: this.handleQuestionConfig.bind(this, 'buttonLink'), placeholder: 'www.tuempresa.com' })
          )
        ),
        questionType === WHATSAPP && _react2.default.createElement(
          'div',
          { className: 'input-field option' },
          _react2.default.createElement(
            'div',
            { className: 'option-row' },
            _react2.default.createElement(
              'label',
              { className: 'active' },
              (0, _translations2.default)('WHATSAPP PHONE')
            ),
            _react2.default.createElement('input', { type: 'text', className: 'validate option-input', value: extra.buttonLink, onChange: this.handleQuestionConfig.bind(this, 'buttonLink') })
          ),
          _react2.default.createElement(
            'div',
            { className: 'option-row' },
            _react2.default.createElement(
              'label',
              { className: 'active' },
              (0, _translations2.default)('WHATSAPP MESSAGE')
            ),
            _react2.default.createElement('textarea', { type: 'text', className: 'validate option-textarea', value: extra.buttonMessage, onChange: this.handleQuestionConfig.bind(this, 'buttonMessage'), placeholder: 'mensaje default' })
          )
        )
      );
    }
  }, {
    key: 'handleEditorStateChange',
    value: function handleEditorStateChange(editorState) {
      var _this5 = this;

      this.setState({
        editorState: editorState
      }, function () {
        var contentState = _this5.state.editorState.getCurrentContent();
        var rawContent = (0, _stringify2.default)((0, _draftJs.convertToRaw)(contentState));

        if (_this5.editorReferece) {
          if (_this5.questionType === HTML || _this5.questionType === WHATSAPP) {
            var text = _this5.editorReferece.editor.innerHTML;
            _this5.changeQuestion('text', null, text);
            _this5.changeQuestionConfig('rawContent', rawContent);
          } else {
            var _text = _this5.editorReferece.editor.innerText.replace(/^\s+|\s+$/g, '');
            _this5.changeQuestion('text', null, _text);
            _this5.changeQuestionConfig('rawContent', rawContent);
          }
        }
      });
    }
  }, {
    key: 'rendeWys',
    value: function rendeWys() {
      var ui = this.props.account.reach && this.props.account.reach.ui ? this.props.account.reach.ui : {};
      var header = ui.header || '<header style="background-color: #FFF; display: flex; justify-content: center; align-items: center;"><img src="http://www.tigobusiness.com.gt/sites/tbd.com.gt/files/logo.png" background-color: "#FFF";></header>';
      var background = ui.background || '#FFF';
      var extra = {
        buttonText: this.props.question.config && this.props.question.config.buttonText || 'Siguiente',
        buttonLink: this.props.question.config && this.props.question.config.buttonLink || ''
      };
      var buttonText = extra.buttonText,
          buttonLink = extra.buttonLink;

      var buttonColor = ui.highlight || (this.questionType === 'HTML' ? "#FE5621" : "#25d366");

      var defaultButtonHtml = '\n    <section style="\n      display: flex;\n      padding: 5px 0 0 0;\n      height: 50px;\n      width: 100%;\n      align-items: baseline;\n      justify-content: center;\n      background: ' + background + ';\n    ">\n      <a target=_BLANK href="' + buttonLink + '"\n        onClick="function() { document.getElementById(\'button-text-' + this.props.question.question_number + '\') }"\n        style="\n          background: ' + buttonColor + ';\n          border: none;\n          padding: 5px 20px;\n          color: #FFF;\n          cursor: text;\n          width: 100%;\n          margin: 0 20px;\n          border-radius: 5px;\n          text-align: center;\n          max-width: 400px;\n        "\n      >' + buttonText + '</a>\n    </section>';

      return _react2.default.createElement(
        'div',
        { className: _Question2.default.wysiwyg },
        _react2.default.createElement(_components.WYSIWYG, {
          editorReference: this.setEditorReference,
          editorState: this.state.editorState,
          onChange: this.handleEditorStateChange,
          header: header,
          buttonHtml: defaultButtonHtml
        })
      );
    }
  }, {
    key: 'renderJumps',
    value: function renderJumps() {
      var questionType = this.questionType;
      var questions = this.props.questions;

      var jumpableOptions = [_react2.default.createElement(
        'option',
        { key: jumpTypes.NEXT, value: jumpTypes.NEXT },
        (0, _translations2.default)('NEXT')
      )];
      questions.slice(this.props.question.question_number + 1).forEach(function (jumpableQuestion, index) {
        jumpableOptions.push(_react2.default.createElement(
          'option',
          { key: index, value: jumpableQuestion._id },
          (0, _translations2.default)('QUESTION'),
          ' ',
          jumpableQuestion.question_number
        ));
      });
      jumpableOptions.push(_react2.default.createElement(
        'option',
        { key: jumpTypes.END_OF_INTERACTION, value: jumpTypes.END_OF_INTERACTION },
        (0, _translations2.default)('END')
      ));

      if (questionType === HTML || questionType === WHATSAPP) {
        return _react2.default.createElement(
          'div',
          { className: 'third third_html' },
          _react2.default.createElement(
            'h1',
            null,
            (0, _translations2.default)('CONFIG HTML QUESTION')
          ),
          this.buttonJump(this.props.question, jumpableOptions)
        );
      }
      return _react2.default.createElement(
        'div',
        { className: 'third' },
        _react2.default.createElement(
          'h1',
          null,
          (0, _translations2.default)('CONFIG QUESTION')
        ),
        questionType === OPEN || questionType === NPS || questionType === AGE ? this.questionJump(this.props.question, jumpableOptions) : null,
        questionType === OPTIONS || questionType === YES_NO || questionType === GENDER || questionType === MULTIPLE_CHOICE ? this.optionsJumps(this.props.question, jumpableOptions, questionType) : null,
        questionType === RANGE ? this.range(this.props.question, jumpableOptions) : null
      );
    }
  }, {
    key: 'renderRegex',
    value: function renderRegex() {
      var _this6 = this;

      var questionRegex = this.props.questionRegex.list;
      var regexOptions = [];
      var description = "";

      for (var index in questionRegex) {
        var regex = questionRegex[index];
        if (regex["_id"] == this.props.question["question_regex_uid"]) description = regex["description"];
        regexOptions.push(_react2.default.createElement(
          'option',
          { key: index, value: regex["_id"] },
          ' ',
          regex["name"],
          ' '
        ));
      }

      return _react2.default.createElement(
        'div',
        { className: 'third' },
        _react2.default.createElement(
          'h1',
          null,
          (0, _translations2.default)('ANSWER TYPE')
        ),
        _react2.default.createElement(
          'div',
          { className: 'question-regex' },
          _react2.default.createElement(
            'div',
            { className: 'select' },
            _react2.default.createElement(
              'select',
              {
                className: 'browser-default',
                value: this.props.question["question_regex_uid"],
                onChange: function onChange(event) {
                  return _this6.props.changeQuestionProperty("question_regex_uid", event.target.value);
                }
              },
              regexOptions
            )
          )
        ),
        _react2.default.createElement(
          'p',
          { className: 'regex-description' },
          description
        )
      );
    }
  }, {
    key: 'renderQuestionSelector',
    value: function renderQuestionSelector() {
      var _this7 = this;

      if (this.props.channels.includes("SMS") && this.questionType === "HTML") {
        this.props.updateInteractionQuestionsSms();
        this.questionType = OPEN;
        this.setState({ editorState: _draftJs.EditorState.createEmpty() });
      }
      var questionType = this.questionType;
      var questionTypeOptions = [];
      CONFIGURATION.questionTypes.forEach(function (questionTypeIter) {
        if (questionTypeIter === HTML || questionTypeIter === WHATSAPP) {
          if (_this7.props.channels.indexOf('SMS') > -1) {
            return;
          }
        }
        questionTypeOptions.push(_react2.default.createElement(
          'option',
          { key: 'option-' + questionTypeIter, value: questionTypeIter },
          _this7.questionTypesTranslations[questionTypeIter]
        ));
      });
      return _react2.default.createElement(
        'div',
        { className: _Question2.default.selective },
        _react2.default.createElement(
          'div',
          { className: _Question2.default.type },
          (0, _translations2.default)('QUESTION NUMBER'),
          ' ',
          this.props.question.question_number,
          ':'
        ),
        _react2.default.createElement(
          'div',
          { className: _Question2.default.selectquestion },
          _react2.default.createElement(
            'div',
            { className: 'select' },
            _react2.default.createElement(
              'select',
              { onChange: this.handleChangeQuestionType, value: questionType },
              questionTypeOptions
            )
          )
        ),
        _react2.default.createElement(
          'div',
          { className: _Question2.default.actionbuttons },
          _react2.default.createElement(
            'div',
            { className: _Question2.default.button, onClick: this.props.removeQuestion.bind(this, this.props.question.question_number) },
            _react2.default.createElement(md.delete, { size: 25 })
          )
        )
      );
    }
  }, {
    key: 'renderPlanTextPreview',
    value: function renderPlanTextPreview() {
      var questionType = this.questionType;
      var text = this.props.question.text;

      var hasOptions = questionType === OPTIONS || questionType === YES_NO || questionType === GENDER || questionType === MULTIPLE_CHOICE;

      return _react2.default.createElement(
        'div',
        { className: _Question2.default.plaintext },
        _react2.default.createElement(
          _components.PlainText,
          {
            editorReference: this.setEditorReference,
            optionsReference: this.setOptionsReference,
            editorState: this.state.editorState,
            onChange: this.handleEditorStateChange,
            style: { height: hasOptions ? '100px' : '200px' },
            placeholder: (0, _translations2.default)('QUESTION TEXT'),
            getQuestionLength: this.getQuestionLenght,
            hideCounter: true
          },
          hasOptions ? this.options() : null
        )
      );
    }
  }, {
    key: 'render',
    value: function render() {
      return _react2.default.createElement(
        'li',
        { className: _Question2.default.question },
        _react2.default.createElement(
          'div',
          { className: _Question2.default.header },
          this.renderQuestionSelector()
        ),
        _react2.default.createElement('div', { className: _Question2.default.left }),
        _react2.default.createElement(
          'div',
          { className: _Question2.default.preview },
          this.questionType === HTML || this.questionType === WHATSAPP ? this.rendeWys() : this.renderPlanTextPreview()
        ),
        _react2.default.createElement(
          'div',
          { className: _Question2.default.right },
          this.renderJumps(),
          this.questionType === OPEN ? this.renderRegex() : null
        )
      );
    }
  }]);
  return Question;
}(_react2.default.Component), _class3.propTypes = {
  question: _propTypes2.default.object,
  questions: _propTypes2.default.array.isRequired,
  changeQuestionType: _propTypes2.default.func.isRequired,
  changeQuestionOptions: _propTypes2.default.func.isRequired,
  changeQuestionProperty: _propTypes2.default.func.isRequired,
  changeQuestionJump: _propTypes2.default.func.isRequired,
  addOption: _propTypes2.default.func.isRequired,
  deleteOption: _propTypes2.default.func.isRequired,
  changeOptionJump: _propTypes2.default.func.isRequired,
  removeQuestion: _propTypes2.default.func.isRequired,
  changeQuestionOption: _propTypes2.default.func.isRequired
}, _temp), (_applyDecoratedDescriptor(_class2.prototype, 'getQuestionLenght', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'getQuestionLenght'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, 'setEditorReference', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'setEditorReference'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, 'setOptionsReference', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'setOptionsReference'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, 'handleChangeQuestionType', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'handleChangeQuestionType'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, 'handleOptionKeyPress', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'handleOptionKeyPress'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, 'handleAddOption', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'handleAddOption'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, 'handleEditorStateChange', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'handleEditorStateChange'), _class2.prototype)), _class2)) || _class);
exports.default = Question;