'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.featureEnabled = featureEnabled;
exports.getAmountFeaturesEnabled = getAmountFeaturesEnabled;

var _clientStore = require('./clientStore.js');

var _clientStore2 = _interopRequireDefault(_clientStore);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function featureEnabled(feature) {

    var result = false;
    var current_account = null;
    if (__CLIENT__) {
        try {
            var store = (0, _clientStore2.default)();
            current_account = store.getState().auth.current_account;
            if (current_account) {
                result = typeof current_account.features !== 'undefined' ? current_account.features.includes(feature) : false;
            }
        } catch (e) {}
    }

    return result;
}

function getAmountFeaturesEnabled(features) {
    var featuresEnabled = 0;
    features.map(function (feature) {
        if (featureEnabled(feature)) featuresEnabled++;
        return feature;
    });
    return featuresEnabled;
}