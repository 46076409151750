'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _getOwnPropertyDescriptor = require('babel-runtime/core-js/object/get-own-property-descriptor');

var _getOwnPropertyDescriptor2 = _interopRequireDefault(_getOwnPropertyDescriptor);

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _class, _desc, _value, _class2, _class3, _temp;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _coreDecorators = require('core-decorators');

var _reactRedux = require('react-redux');

var _account = require('redux/modules/account');

var _reduxConnect = require('redux-connect');

var _reactColor = require('react-color');

var _themeEnabled = require('helpers/themeEnabled');

var _reactDropzoneS3Uploader = require('react-dropzone-s3-uploader');

var _reactDropzoneS3Uploader2 = _interopRequireDefault(_reactDropzoneS3Uploader);

var _ApiClient = require('../../helpers/ApiClient');

var _ApiClient2 = _interopRequireDefault(_ApiClient);

var _AccountCustomizer = require('./AccountCustomizer.scss');

var _AccountCustomizer2 = _interopRequireDefault(_AccountCustomizer);

var _components = require('components');

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _Allow = require('../Allow/Allow');

var _Allow2 = _interopRequireDefault(_Allow);

var _useBasename = require('../../helpers/useBasename');

var _useBasename2 = _interopRequireDefault(_useBasename);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
  var desc = {};
  Object['ke' + 'ys'](descriptor).forEach(function (key) {
    desc[key] = descriptor[key];
  });
  desc.enumerable = !!desc.enumerable;
  desc.configurable = !!desc.configurable;

  if ('value' in desc || desc.initializer) {
    desc.writable = true;
  }

  desc = decorators.slice().reverse().reduce(function (desc, decorator) {
    return decorator(target, property, desc) || desc;
  }, desc);

  if (context && desc.initializer !== void 0) {
    desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
    desc.initializer = undefined;
  }

  if (desc.initializer === void 0) {
    Object['define' + 'Property'](target, property, desc);
    desc = null;
  }

  return desc;
}

function getSignedUrl(file, callback) {
  var client = new _ApiClient2.default((0, _useBasename2.default)('/s3'));
  var params = {
    objectName: file.name,
    contentType: file.type
  };

  client.get('/sign', { params: params }).then(function (data) {
    callback(data);
  }).catch(function (error) {
    console.error(error);
  });
}

var AccountCustomizer = (_dec = (0, _reactRedux.connect)(function (state) {
  return { account: state.account.data };
}, { updateAccount: _account.updateAccount }), _dec(_class = (_class2 = (_temp = _class3 = function (_React$Component) {
  (0, _inherits3.default)(AccountCustomizer, _React$Component);

  function AccountCustomizer(props) {
    (0, _classCallCheck3.default)(this, AccountCustomizer);

    var _this = (0, _possibleConstructorReturn3.default)(this, (AccountCustomizer.__proto__ || (0, _getPrototypeOf2.default)(AccountCustomizer)).call(this, props));

    _this.state = {
      url: '',
      tabSelected: 1
    };
    return _this;
  }

  (0, _createClass3.default)(AccountCustomizer, [{
    key: 'componentWillMount',
    value: function componentWillMount() {
      var _this2 = this;

      var ui = this.props.account.reach.ui || {};
      var stateUi = {
        background: ui.background || '#f5f5f5',
        image: ui.image || 'http://ubiquo.io/images/logo.png',
        url: ui.url || 'http://www.google.com.gt',
        buttonText: ui.buttonText || 'Finalizar',
        highlight: ui.highlight || '#008CD4'
      };
      setTimeout(function () {
        _this2.setState((0, _extends3.default)({}, _this2.state, stateUi, {
          header: _this2.buildTemplate(stateUi.background, stateUi.image),
          preview: _this2.buildPreview(stateUi.url, stateUi.buttonText)
        }));
      });
    }
  }, {
    key: 'buildTemplate',
    value: function buildTemplate(background, image) {
      return '<header style=\'background-color: ' + background + '; display: flex; width: 100%; justify-content: center; align-items: center; padding: 12px 0;\'><img src="' + image + '"; background-color: ' + background + ';></header>';
    }
  }, {
    key: 'buildPreview',
    value: function buildPreview(url, buttonText) {
      var href = void 0;
      if (!url.startsWith('http://')) {
        href = 'http://' + url;
      }
      return '<a style=\'color: white; font-size: 11px; background-color: black; border-radius: 4px; padding: 13px; width: 120px; display: block; text-align: center;\' href="' + href + '" target="_blank"> ' + buttonText + ' </a>';
    }
  }, {
    key: 'handleTabChange',
    value: function handleTabChange(tabSelected, event) {
      event.preventDefault();
      event.stopPropagation();
      this.setState((0, _extends3.default)({}, this.state, {
        tabSelected: tabSelected
      }));
    }
  }, {
    key: 'handleInputChange',
    value: function handleInputChange(event) {
      this.setState((0, _extends3.default)({}, this.state, {
        url: event.target.value,
        preview: this.buildPreview(event.target.value, this.state.buttonText)
      }));
    }
  }, {
    key: 'handleClick',
    value: function handleClick() {
      var template = this.buildTemplate(this.state.background, this.state.image);
      var account = this.props.account;
      if (!account.reach.ui) {
        account.reach.ui = {};
      }
      account.reach.ui.header = template;
      account.reach.ui.url = this.state.url;
      account.reach.ui.background = this.state.background;
      account.reach.ui.image = this.state.image;
      account.reach.ui.buttonText = this.state.buttonText;
      account.reach.ui.highlight = this.state.highlight;
      return this.props.updateAccount(account);
    }
  }, {
    key: 'handleChangeComplete',
    value: function handleChangeComplete(color) {
      this.setState((0, _extends3.default)({}, this.state, {
        background: color.hex,
        header: this.buildTemplate(color.hex, this.state.image)
      }));
    }
  }, {
    key: 'handleChangeHighlight',
    value: function handleChangeHighlight(color) {
      this.setState((0, _extends3.default)({}, this.state, {
        highlight: color.hex
      }));
    }
  }, {
    key: 'handleChangeButton',
    value: function handleChangeButton(event) {
      this.setState((0, _extends3.default)({}, this.state, {
        buttonText: event.target.value,
        preview: this.buildPreview(this.state.url, event.target.value)
      }));
    }
  }, {
    key: 'handleImageChange',
    value: function handleImageChange(e) {
      this.setState((0, _extends3.default)({}, this.state, {
        image: e.fileUrl,
        header: this.buildTemplate(this.state.background, e.publicUrl)
      }));
    }
  }, {
    key: 'render',
    value: function render() {
      var s3Url = 'https://reach-public.s3.amazonaws.com/';
      var styleColor = { backgroundColor: this.state.background };
      var uploadImage = require('containers/Account/drag.svg');

      var classesTheme = (0, _themeEnabled.getThemeEnabledStyle)(_AccountCustomizer2.default);

      return _react2.default.createElement(
        'div',
        { className: classesTheme },
        _react2.default.createElement(
          'div',
          { className: _AccountCustomizer2.default.flexRow },
          _react2.default.createElement(
            'a',
            { href: '#header', style: { borderBottomStyle: this.state.tabSelected === 1 ? 'solid' : 'none' }, onClick: this.handleTabChange.bind(this, 1) },
            ' ',
            (0, _translations2.default)('HEADER PREVIEW'),
            ' '
          ),
          _react2.default.createElement(
            'a',
            { href: '#highlights', style: { borderBottomStyle: this.state.tabSelected === 2 ? 'solid' : 'none' }, onClick: this.handleTabChange.bind(this, 2) },
            ' ',
            (0, _translations2.default)('HIGHLIGHTS PREVIEW'),
            ' '
          ),
          _react2.default.createElement(
            'a',
            { href: '#button', style: { borderBottomStyle: this.state.tabSelected === 3 ? 'solid' : 'none' }, onClick: this.handleTabChange.bind(this, 3) },
            ' ',
            (0, _translations2.default)('BUTTON PREVIEW'),
            ' '
          )
        ),
        _react2.default.createElement(
          'div',
          { id: 'header', className: _AccountCustomizer2.default.mainTab, style: { display: this.state.tabSelected === 1 ? 'block' : 'none' } },
          _react2.default.createElement(
            'div',
            { className: _AccountCustomizer2.default.wrapper },
            _react2.default.createElement(
              'div',
              { className: _AccountCustomizer2.default.aside1 },
              _react2.default.createElement(
                'div',
                { className: _AccountCustomizer2.default.asideTop },
                _react2.default.createElement(
                  'a',
                  { className: _AccountCustomizer2.default.headerText },
                  (0, _translations2.default)('ACCOUNT HEADER COLOR')
                ),
                _react2.default.createElement('div', { className: _AccountCustomizer2.default.colorSampler, style: styleColor })
              ),
              _react2.default.createElement(
                'div',
                { className: _AccountCustomizer2.default.backgroundAside3 },
                _react2.default.createElement(_reactColor.BlockPicker, { triangle: 'hide', colors: ['#83174B', '#FC433E', '#FD852F', '#FEDB32', '#39CA49', '#42CCCB', '#1376D6', '#F5F5F5', '#DDDDDD', '#111111'], color: this.state.background, onChangeComplete: this.handleChangeComplete }),
                _react2.default.createElement('div', null)
              )
            ),
            _react2.default.createElement(
              'div',
              { className: _AccountCustomizer2.default.aside2 },
              _react2.default.createElement('img', { className: _AccountCustomizer2.default.svg, src: (0, _useBasename2.default)('/static/phone.svg'), alt: '' }),
              _react2.default.createElement('div', { className: _AccountCustomizer2.default.headerPreview, dangerouslySetInnerHTML: { __html: this.state.header } }),
              _react2.default.createElement(
                'div',
                { className: _AccountCustomizer2.default.welcome },
                _react2.default.createElement(
                  'h3',
                  null,
                  (0, _translations2.default)('WELCOME')
                ),
                _react2.default.createElement(
                  'h2',
                  null,
                  (0, _translations2.default)('WELCOME EXAMPLE')
                )
              ),
              _react2.default.createElement(
                'div',
                { className: _AccountCustomizer2.default.highlightPreview },
                _react2.default.createElement(
                  'div',
                  { className: _AccountCustomizer2.default.option, style: { backgroundColor: this.state.highlight, color: '#FFF' } },
                  ' ',
                  (0, _translations2.default)('OPTION1'),
                  ' '
                ),
                _react2.default.createElement(
                  'div',
                  { className: _AccountCustomizer2.default.option },
                  ' ',
                  (0, _translations2.default)('OPTION2'),
                  ' '
                ),
                _react2.default.createElement(
                  'div',
                  { className: _AccountCustomizer2.default.option },
                  ' ',
                  (0, _translations2.default)('OPTION3'),
                  ' '
                )
              ),
              _react2.default.createElement('a', { className: _AccountCustomizer2.default.next, style: { backgroundColor: this.state.highlight } })
            ),
            _react2.default.createElement(
              'div',
              { className: _AccountCustomizer2.default.aside3 },
              _react2.default.createElement(
                'div',
                { className: _AccountCustomizer2.default.asideTop },
                _react2.default.createElement('img', { src: this.state.image, className: _AccountCustomizer2.default.logoPreview }),
                _react2.default.createElement(
                  'div',
                  { className: _AccountCustomizer2.default.textAside3 },
                  _react2.default.createElement(
                    'a',
                    { className: _AccountCustomizer2.default.titleAside3 },
                    (0, _translations2.default)('ACCOUNT HEADER LOGO')
                  ),
                  _react2.default.createElement(
                    'div',
                    { className: _AccountCustomizer2.default.subTextAside3 },
                    _react2.default.createElement(
                      'a',
                      { className: _AccountCustomizer2.default.subText1 },
                      ' ',
                      (0, _translations2.default)('ACCOUNT HEADER LOGO FORMAT')
                    ),
                    _react2.default.createElement(
                      'a',
                      { className: _AccountCustomizer2.default.subText2 },
                      (0, _translations2.default)('ACCOUNT HEADER LOGO SIZE')
                    )
                  )
                )
              ),
              _react2.default.createElement(
                'div',
                { className: _AccountCustomizer2.default.backgroundAside3 },
                _react2.default.createElement(_reactDropzoneS3Uploader2.default, {
                  className: _AccountCustomizer2.default.dragAndDrop,
                  s3Url: s3Url,
                  upload: {
                    getSignedUrl: getSignedUrl,
                    accept: 'image/*',
                    uploadRequestHeaders: {},
                    contentDisposition: 'auto'
                  },
                  onProgress: function onProgress(e) {
                    return console.info('onprogress', e);
                  },
                  onError: function onError(e) {
                    return console.info('onError', e);
                  },
                  onFinish: this.handleImageChange
                }),
                _react2.default.createElement(
                  'div',
                  { className: _AccountCustomizer2.default.textAside3 },
                  _react2.default.createElement(
                    'a',
                    { className: _AccountCustomizer2.default.subText3 },
                    (0, _translations2.default)('ACCOUNT HEADER LOGO UPLOAD')
                  ),
                  _react2.default.createElement(
                    'div',
                    { className: _AccountCustomizer2.default.subTextAside3 },
                    _react2.default.createElement(
                      'a',
                      { className: _AccountCustomizer2.default.subText4 },
                      (0, _translations2.default)('ACCOUNT HEADER LOGO FORMAT')
                    ),
                    _react2.default.createElement(
                      'a',
                      { className: _AccountCustomizer2.default.subText5 },
                      (0, _translations2.default)('ACCOUNT HEADER LOGO SIZE')
                    )
                  )
                )
              )
            )
          )
        ),
        _react2.default.createElement(
          'div',
          { id: 'highlights', className: _AccountCustomizer2.default.mainTab, style: { display: this.state.tabSelected === 2 ? 'block' : 'none' } },
          _react2.default.createElement(
            'div',
            { className: _AccountCustomizer2.default.wrapper },
            _react2.default.createElement(
              'div',
              { className: _AccountCustomizer2.default.aside2 },
              _react2.default.createElement('img', { className: _AccountCustomizer2.default.svg, src: (0, _useBasename2.default)('/static/phone.svg'), alt: '' }),
              _react2.default.createElement('div', { className: _AccountCustomizer2.default.headerPreview, dangerouslySetInnerHTML: { __html: this.state.header } }),
              _react2.default.createElement(
                'div',
                { className: _AccountCustomizer2.default.welcome },
                _react2.default.createElement(
                  'h3',
                  null,
                  (0, _translations2.default)('WELCOME')
                ),
                _react2.default.createElement(
                  'h2',
                  null,
                  (0, _translations2.default)('WELCOME EXAMPLE')
                )
              ),
              _react2.default.createElement(
                'div',
                { className: _AccountCustomizer2.default.highlightPreview },
                _react2.default.createElement(
                  'div',
                  { className: _AccountCustomizer2.default.option, style: { backgroundColor: this.state.highlight, color: '#FFF' } },
                  ' ',
                  (0, _translations2.default)('OPTION1'),
                  ' '
                ),
                _react2.default.createElement(
                  'div',
                  { className: _AccountCustomizer2.default.option },
                  ' ',
                  (0, _translations2.default)('OPTION2'),
                  ' '
                ),
                _react2.default.createElement(
                  'div',
                  { className: _AccountCustomizer2.default.option },
                  ' ',
                  (0, _translations2.default)('OPTION3'),
                  ' '
                )
              ),
              _react2.default.createElement('a', { className: _AccountCustomizer2.default.next, style: { backgroundColor: this.state.highlight } })
            ),
            _react2.default.createElement(
              'div',
              { className: _AccountCustomizer2.default.aside4 },
              _react2.default.createElement(
                'div',
                { className: _AccountCustomizer2.default.asideTop },
                _react2.default.createElement(
                  'a',
                  { className: _AccountCustomizer2.default.headerText },
                  (0, _translations2.default)('ACCOUNT HIGHLIGHT COLOR')
                ),
                _react2.default.createElement('div', { className: _AccountCustomizer2.default.colorSampler, style: { backgroundColor: this.state.highlight } })
              ),
              _react2.default.createElement(
                'div',
                { className: _AccountCustomizer2.default.backgroundAside3 },
                _react2.default.createElement(_reactColor.BlockPicker, { triangle: 'hide', colors: ['#F70070', '#FC433E', '#FD852F', '#FEDB32', '#39CA49', '#42CCCB', '#1376D6', '#6B0CED', '#666666', '#111111'], color: this.state.highlight, onChangeComplete: this.handleChangeHighlight }),
                _react2.default.createElement('div', null)
              )
            )
          )
        ),
        _react2.default.createElement(
          'div',
          { id: 'button', className: _AccountCustomizer2.default.mainTab, style: { display: this.state.tabSelected === 3 ? 'block' : 'none' } },
          _react2.default.createElement(
            'div',
            { className: _AccountCustomizer2.default.wrapper },
            _react2.default.createElement(
              'div',
              { className: _AccountCustomizer2.default.aside2 },
              _react2.default.createElement('img', { className: _AccountCustomizer2.default.svg, src: (0, _useBasename2.default)('/static/phone.svg'), alt: '' }),
              _react2.default.createElement('div', { className: _AccountCustomizer2.default.headerPreview, dangerouslySetInnerHTML: { __html: this.state.header } }),
              _react2.default.createElement(
                'div',
                { className: _AccountCustomizer2.default.flex },
                _react2.default.createElement(
                  'h3',
                  null,
                  (0, _translations2.default)('THANKS')
                ),
                _react2.default.createElement(
                  'h2',
                  null,
                  (0, _translations2.default)('THANKS MESSAGE')
                )
              ),
              _react2.default.createElement('div', { className: _AccountCustomizer2.default.button, dangerouslySetInnerHTML: { __html: this.state.preview } })
            ),
            _react2.default.createElement(
              'div',
              { className: _AccountCustomizer2.default.aside4 },
              _react2.default.createElement(
                'a',
                { className: _AccountCustomizer2.default.titleText },
                (0, _translations2.default)('ACCOUNT BUTTON')
              ),
              _react2.default.createElement(
                'div',
                { className: _AccountCustomizer2.default.urlMain },
                _react2.default.createElement(
                  'div',
                  { className: _AccountCustomizer2.default.urlText },
                  _react2.default.createElement(
                    'a',
                    null,
                    (0, _translations2.default)('ACCOUNT URL')
                  )
                ),
                _react2.default.createElement('input', { type: 'text', className: _AccountCustomizer2.default.urlInput, value: this.state.url, onChange: this.handleInputChange })
              ),
              _react2.default.createElement(
                'div',
                { className: _AccountCustomizer2.default.urlMain },
                _react2.default.createElement(
                  'div',
                  { className: _AccountCustomizer2.default.urlText },
                  _react2.default.createElement(
                    'a',
                    null,
                    (0, _translations2.default)('ACCOUNT URL  BUTTON')
                  )
                ),
                _react2.default.createElement('input', { type: 'text', maxLength: '30', className: _AccountCustomizer2.default.urlInput, value: this.state.buttonText, onChange: this.handleChangeButton })
              )
            )
          )
        ),
        _react2.default.createElement(
          'div',
          { className: _AccountCustomizer2.default.footer },
          _react2.default.createElement(
            _Allow2.default,
            { permissions: ['account:configs:channels:interaction'] },
            _react2.default.createElement(_components.Button, { className: _AccountCustomizer2.default.btnSuccess, onClick: this.handleClick, name: (0, _translations2.default)('APPLY CHANGES') })
          )
        )
      );
    }
  }]);
  return AccountCustomizer;
}(_react2.default.Component), _class3.defaultProps = {
  account: {
    reach: {
      ui: {}
    }
  }
}, _temp), (_applyDecoratedDescriptor(_class2.prototype, 'handleTabChange', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'handleTabChange'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, 'handleInputChange', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'handleInputChange'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, 'handleClick', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'handleClick'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, 'handleChangeComplete', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'handleChangeComplete'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, 'handleChangeHighlight', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'handleChangeHighlight'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, 'handleChangeButton', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'handleChangeButton'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, 'handleImageChange', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'handleImageChange'), _class2.prototype)), _class2)) || _class);
exports.default = AccountCustomizer;
module.exports = exports['default'];