'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actions = undefined;
exports.default = reducer;
exports.getAccount = getAccount;
exports.getCallback = getCallback;
exports.postCallback = postCallback;
exports.getAccounts = getAccounts;
exports.deleteAccounts = deleteAccounts;
exports.accountsSelect = accountsSelect;
exports.accountsUnselectAll = accountsUnselectAll;
exports.updateAccount = updateAccount;

var _apiAction = require('../apiAction');

var _apiAction2 = _interopRequireDefault(_apiAction);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// ACTIONS


var actions = exports.actions = new _apiAction2.default('ACCOUNT', [_apiAction.ACTION_TYPES.DATA, _apiAction.ACTION_TYPES.TRIM, _apiAction.ACTION_TYPES.APPEND, _apiAction.ACTION_TYPES.SELECT]);

// REDUCER


var initialState = {
  loading: false,
  status: actions.DATA,
  data: {}
};

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  return actions.getStateForAction(state, action);
}

// ACTION CREATORS


function getAccount() {
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.get('/accounts');
    }
  };
}

function getCallback() {
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.get('/account/callback');
    }
  };
}

function postCallback(data) {
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.put('/account/callback', {
        data: data
      });
    }
  };
}

// accounts


function getAccounts(params) {
  return {
    types: [actions.APPEND, actions.APPEND_SUCCESS, actions.APPEND_FAIL],
    promise: function promise(client) {
      return client.get('/accounts', {
        params: params
      });
    }
  };
}

function deleteAccounts(data) {
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.del('/accounts', {
        data: data
      });
    }
  };
}

function accountsSelect(index) {
  return {
    type: actions.SELECT,
    index: index
  };
}

function accountsUnselectAll(index) {
  return {
    type: actions.SELECT_OFF,
    index: index
  };
}

function updateAccount(account) {
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.put('/backoffice/accounts', {
        data: account,
        params: { application: 'reach' }
      });
    }
  };
}