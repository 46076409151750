'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _dec2, _class, _class2, _temp;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _reduxConnect = require('redux-connect');

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _numbro = require('numbro');

var _numbro2 = _interopRequireDefault(_numbro);

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _components = require('components');

var _status = require('redux/modules/status');

var _reports = require('redux/modules/reports');

var _Reports = require('../Reports/Reports.scss');

var _Reports2 = _interopRequireDefault(_Reports);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// fix this

// 629GQZ

var md = {
  filter: require('react-icons/lib/md/filter-list')
};

var BalanceReport = (_dec = (0, _reduxConnect.asyncConnect)([{
  key: 'account',
  promise: function promise(_ref) {
    var _ref$store = _ref.store,
        dispatch = _ref$store.dispatch,
        getState = _ref$store.getState;

    var state = getState();
    if (!state.account.data.account_id) {
      return dispatch((0, _status.getAccountStatus)());
    }
    return null;
  }
}]), _dec2 = (0, _reactRedux.connect)(function (state) {
  return {
    mobileMode: state.mobile.mobileMode,
    loading: state.reports.loading,
    data: state.reports.list
  };
}, {
  clearReportsData: _reports.clearReportsData,
  getResellerBalanceReport: _reports.getResellerBalanceReport
}), _dec(_class = _dec2(_class = (_temp = _class2 = function (_AdminReportList) {
  (0, _inherits3.default)(BalanceReport, _AdminReportList);

  function BalanceReport(props) {
    (0, _classCallCheck3.default)(this, BalanceReport);

    var _this = (0, _possibleConstructorReturn3.default)(this, (BalanceReport.__proto__ || (0, _getPrototypeOf2.default)(BalanceReport)).call(this, props));

    _this.state = (0, _extends3.default)({}, _this.state, {
      startDate: (0, _moment2.default)(_this.props.account.current_package.valid_since),
      endDate: (0, _moment2.default)(_this.props.account.current_package.valid_thru)
    });

    _this.format = '4a';

    _this.requested = false;

    _this.getResellerBalanceReport = _this.props.getResellerBalanceReport.bind(_this);
    return _this;
  }

  (0, _createClass3.default)(BalanceReport, [{
    key: 'componentWillMount',
    value: function componentWillMount() {
      if (__CLIENT__) {
        this.handleDateChange(this.state.startDate, this.state.endDate);
      }
    }
  }, {
    key: 'onDateChange',
    value: function onDateChange() {
      this.props.clearReportsData();
      this.getResellerBalanceReport(this.state.startDate, this.state.endDate);
    }
  }, {
    key: 'renderNav',
    value: function renderNav(stickyprops) {
      return _react2.default.createElement(_components.Nav, { style: stickyprops.style });
    }
  }, {
    key: 'renderContent',
    value: function renderContent() {
      var number_width = 200;

      var data = this.props.data;

      var columns = [{
        Header: (0, _translations2.default)('ACCOUNT NAME'),
        accessor: 'sub_account_name',
        width: '1f'
      }, {
        Header: (0, _translations2.default)('ASSIGNED MESSAGES'),
        accessor: 'total_credit_limit',
        width: number_width,
        Cell: function Cell(props) {
          return _react2.default.createElement(
            'span',
            { className: 'number' },
            (0, _numbro2.default)(props.value).format()
          );
        }
      }, {
        Header: (0, _translations2.default)('CONSUMED MESSAGES'),
        accessor: 'total_charges',
        width: number_width,
        Cell: function Cell(props) {
          return _react2.default.createElement(
            'span',
            { className: 'number' },
            (0, _numbro2.default)(props.value).format()
          );
        }
      }, {
        Header: (0, _translations2.default)('AVAILABLE MESSAGES'),
        id: 'available_messages',
        width: number_width,
        accessor: function accessor(d) {
          return d.total_credit_limit - d.total_charges;
        },
        Cell: function Cell(props) {
          return _react2.default.createElement(
            'span',
            { className: 'number' },
            ' ',
            (0, _numbro2.default)(props.value).format(),
            ' '
          );
        }
      }];

      /*
      if (!this.requested) {
        return (
          <div className={styles.reports}>
            <Loading />
          </div>
        )
      }
      */

      return _react2.default.createElement(
        'div',
        { className: _Reports2.default.reportsReseller + ' ' + _Reports2.default.reportHeight },
        _react2.default.createElement(
          'button',
          { className: _Reports2.default.filterButton, onClick: this.handleFilterToggle },
          _react2.default.createElement(md.filter, { size: 20 }),
          this.state.startDate.format('MMM D'),
          ' - ',
          this.state.endDate.format('MMM D')
        ),
        _react2.default.createElement(_components.Table, {
          data: data,
          columns: columns,
          isLoading: this.props.loading
        })
      );
    }
  }]);
  return BalanceReport;
}(_components.AdminReportList), _class2.propTypes = {
  mobileMode: _propTypes2.default.bool
}, _temp)) || _class) || _class);
exports.default = BalanceReport;
module.exports = exports['default'];