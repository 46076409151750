'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StudiesAll = exports.StudiesStopped = exports.StudiesNotstarted = exports.StudiesInprogress = exports.default = exports.StudyTabs = undefined;

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _class, _temp, _dec, _class2, _dec2, _class3, _dec3, _class4, _dec4, _dec5, _class5;
// import { getVariables, variablesSelect, variablesUnselect, variablesUnselectAll } from 'redux/modules/variables'


var _components = require('components');

var _studies = require('redux/modules/studies');

var _auth = require('redux/modules/auth');

var _Allow = require('../Allow/Allow');

var _Allow2 = _interopRequireDefault(_Allow);

var _reactHelmet = require('react-helmet');

var _reactHelmet2 = _interopRequireDefault(_reactHelmet);

var _reactRouter = require('react-router');

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _reduxConnect = require('redux-connect');

var _reactRedux = require('react-redux');

var _account = require('redux/modules/account');

var _themeEnabled = require('helpers/themeEnabled');

var _sessionPermissions = require('../../helpers/sessionPermissions');

var _Studies5 = require('./Studies.scss');

var _Studies6 = _interopRequireDefault(_Studies5);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Infinite = function Infinite(props) {
  return _react2.default.createElement(
    'div',
    null,
    props.children
  );
};

var md = {
  search: require('react-icons/lib/md/search'),
  add: require('react-icons/lib/md/add'),
  file: require('react-icons/lib/md/attachment')

};

var StudyTabs = exports.StudyTabs = function StudyTabs() {
  return _react2.default.createElement(_components.Tabs, { simple: true, alt: true,
    options: [{
      'href': '/studies/',
      'title': (0, _translations2.default)('ALL STUDIES'),
      'is_index': true
    }, {
      'href': '/studies/inprogress',
      'title': (0, _translations2.default)('STUDIES IN PROGRESS')
    }, {
      'href': '/studies/notstarted',
      'title': (0, _translations2.default)('STUDIES NOT STARTED')
    }, {
      'href': '/studies/stopped',
      'title': (0, _translations2.default)('STUDIES STOPPED')
    }]
  });
};

var Studies = (_temp = _class = function (_List) {
  (0, _inherits3.default)(Studies, _List);

  function Studies(props) {
    (0, _classCallCheck3.default)(this, Studies);

    var _this = (0, _possibleConstructorReturn3.default)(this, (Studies.__proto__ || (0, _getPrototypeOf2.default)(Studies)).call(this, props));

    _this.title = (0, _translations2.default)('STUDIES');
    _this.statusTranslations = {
      'IN_PROGRESS': (0, _translations2.default)('IN_PROGRESS'),
      'PAUSED': (0, _translations2.default)('PAUSED'),
      'APPROVED': (0, _translations2.default)('APPROVED'),
      'FINISHED': (0, _translations2.default)('FINISHED'),
      'EXPIRED': (0, _translations2.default)('EXPIRED'),
      'ABORTED': (0, _translations2.default)('ABORTED'),
      'NOT_STARTED': (0, _translations2.default)('NOT_STARTED'),
      'STOPPED': (0, _translations2.default)('STOPPED')
    };
    _this.status = null;
    return _this;
  }

  (0, _createClass3.default)(Studies, [{
    key: 'getExtraParams',
    value: function getExtraParams() {
      return {
        status: this.status
      };
    }
  }, {
    key: 'getLink',
    value: function getLink(item) {
      var openIf = ['NOT_STARTED'];
      var resultsIf = ['IN_PROGRESS', 'FINISHED', 'EXPIRED', 'ABORTED', 'STOPPED'];
      if (resultsIf.indexOf(item.status) > -1) {
        return {
          pathname: '/studies/results',
          query: { uid: item._id },
          state: { modal: true, returnTo: '/studies', modalSize: { width: '800px', height: '600px' }, resultType: 'STUDY' }
        };
      } else if (openIf.indexOf(item.status) > -1) {
        return {
          pathname: '/studies/study/edit',
          query: { uid: item._id },
          state: { modal: true, returnTo: '/studies', fullModal: true }
        };
      }
      return null;
    }
  }, {
    key: 'renderNav',
    value: function renderNav() {
      var search = this.props.location.query.search;

      var props = this.props;

      if (!props.account) {
        return null;
      }

      var is_open = props.account.account_type == "Subaccount" && props.account.is_default_subaccount && props.account.reach_enabled || props.account.account_type != "Subaccount";
      return _react2.default.createElement(
        _components.Nav,
        { key: 'Nav', title: (0, _translations2.default)('MY STUDIES'), alt: true },
        _react2.default.createElement(
          'div',
          { className: 'search' },
          _react2.default.createElement('input', { type: 'text',
            placeholder: (0, _translations2.default)('SEARCH PLACEHOLDER'),
            value: this.state.search || '',
            ref: 'search',
            onChange: this.handleSearch,
            onKeyPress: this.handleSearch
          }),
          _react2.default.createElement(md.search, null),
          _react2.default.createElement(
            'span',
            { className: 'clear', onClick: this.handleClear, style: this.state.search ? {} : { display: 'none' } },
            '\xD7'
          )
        )
      );
    }
  }, {
    key: 'renderTabs',
    value: function renderTabs() {
      return _react2.default.createElement(StudyTabs, null);
    }
  }, {
    key: 'renderListHeader',
    value: function renderListHeader() {
      var classesTheme = (0, _themeEnabled.getThemeEnabledStyle)(_Studies6.default);
      return _react2.default.createElement(
        'div',
        { className: classesTheme },
        _react2.default.createElement(
          'div',
          { className: _Studies6.default.listheader },
          _react2.default.createElement('div', { className: _Studies6.default.handle }),
          _react2.default.createElement(
            'div',
            { className: _Studies6.default.name },
            'Nombre'
          ),
          _react2.default.createElement(
            'div',
            { className: _Studies6.default.search },
            this.renderNav()
          )
        )
      );
    }
  }, {
    key: 'renderInfiniteList',
    value: function renderInfiniteList() {
      var _this2 = this;

      var hidden = { display: 'none' };
      var visible = { display: 'block' };
      var infiniteStyle = this.props.isLoading || this.props.list.length > 0 ? visible : hidden;
      var linkstyle = this.props.isLoading || this.props.list.length > 0 ? hidden : visible;
      var props = this.props;
      var is_open = props.account.account_type == "Subaccount" && props.account.is_default_subaccount && props.account.reach_enabled || props.account.account_type != "Subaccount";

      if (__SERVER__) {
        return _react2.default.createElement(_components.VirtualListItemLoad, null);
      }

      return _react2.default.createElement(
        'div',
        null,
        _react2.default.createElement(
          'div',
          { style: infiniteStyle },
          _react2.default.createElement(_reactHelmet2.default, { title: 'Administrar Flujos' }),
          _react2.default.createElement(
            Infinite,
            {
              refresh: this.refreshRows,
              next: this.loadMoreRows,
              hasMore: this.state.hasMoreRows
            },
            this.props.list.map(function (item, index) {
              return _react2.default.createElement(_components.VirtualListItemStudy, {
                key: item._id,
                index: index,
                name: item.name,
                label: _this2.statusTranslations[item.status],
                labelClass: item.status,
                panelSource: item.configuration.panel_source,
                link: (0, _sessionPermissions.sessionPermissions)('study:view') ? _this2.getLink(item) : null
              });
            })
          )
        ),
        _react2.default.createElement(_components.Linkholder, {
          style: linkstyle,
          to: {
            pathname: is_open ? '/studies/choose' : '/studies/open',
            state: { modal: true, returnTo: '/studies/', fullModal: !is_open, modalSize: { width: '400px' } }
          },
          src: '/static/empty/studies.svg',
          text: (0, _translations2.default)('NO STUDIES FOUND'),
          button: (0, _translations2.default)('CREATE A NEW STUDY')
        })
      );
    }
  }]);
  return Studies;
}(_components.List), _class.propTypes = {
  mobileMode: _propTypes2.default.bool,
  isLoading: _propTypes2.default.bool.isRequired,
  get: _propTypes2.default.func.isRequired,
  list: _propTypes2.default.array.isRequired
}, _class.defaultProps = {
  selected: []
}, _temp);
exports.default = Studies;
var StudiesInprogress = exports.StudiesInprogress = (_dec = (0, _reactRedux.connect)(function (state) {
  return {
    mobileMode: state.mobile.mobileMode,
    list: state.studies.list,
    isLoading: state.studies.loading,
    account: state.auth.current_account
  };
}, {
  get: _studies.getStudies,
  refresh: _studies.studiesRefresh
}), _dec(_class2 = function (_Studies) {
  (0, _inherits3.default)(StudiesInprogress, _Studies);

  function StudiesInprogress(props) {
    (0, _classCallCheck3.default)(this, StudiesInprogress);

    var _this3 = (0, _possibleConstructorReturn3.default)(this, (StudiesInprogress.__proto__ || (0, _getPrototypeOf2.default)(StudiesInprogress)).call(this, props));

    _this3.status = 'IN_PROGRESS';
    return _this3;
  }

  return StudiesInprogress;
}(Studies)) || _class2);
var StudiesNotstarted = exports.StudiesNotstarted = (_dec2 = (0, _reactRedux.connect)(function (state) {
  return {
    mobileMode: state.mobile.mobileMode,
    list: state.studies.list,
    isLoading: state.studies.loading,
    account: state.auth.current_account
  };
}, {
  get: _studies.getStudies,
  refresh: _studies.studiesRefresh
}), _dec2(_class3 = function (_Studies2) {
  (0, _inherits3.default)(StudiesNotstarted, _Studies2);

  function StudiesNotstarted(props) {
    (0, _classCallCheck3.default)(this, StudiesNotstarted);

    var _this4 = (0, _possibleConstructorReturn3.default)(this, (StudiesNotstarted.__proto__ || (0, _getPrototypeOf2.default)(StudiesNotstarted)).call(this, props));

    _this4.status = 'NOT_STARTED';
    return _this4;
  }

  return StudiesNotstarted;
}(Studies)) || _class3);
var StudiesStopped = exports.StudiesStopped = (_dec3 = (0, _reactRedux.connect)(function (state) {
  return {
    mobileMode: state.mobile.mobileMode,
    list: state.studies.list,
    isLoading: state.studies.loading,
    account: state.auth.current_account
  };
}, {
  get: _studies.getStudies,
  refresh: _studies.studiesRefresh
}), _dec3(_class4 = function (_Studies3) {
  (0, _inherits3.default)(StudiesStopped, _Studies3);

  function StudiesStopped(props) {
    (0, _classCallCheck3.default)(this, StudiesStopped);

    var _this5 = (0, _possibleConstructorReturn3.default)(this, (StudiesStopped.__proto__ || (0, _getPrototypeOf2.default)(StudiesStopped)).call(this, props));

    _this5.status = 'STOPPED';
    return _this5;
  }

  return StudiesStopped;
}(Studies)) || _class4);
var StudiesAll = exports.StudiesAll = (_dec4 = (0, _reduxConnect.asyncConnect)([{
  key: 'account',
  promise: function promise(_ref) {
    var _ref$store = _ref.store,
        dispatch = _ref$store.dispatch,
        getState = _ref$store.getState;

    var state = getState();
    if (!state.account.data.reach) {
      return dispatch((0, _account.getAccount)());
    }
    return null;
  }
}, {
  key: "auth",
  promise: function promise(_ref2) {
    var _ref2$store = _ref2.store,
        dispatch = _ref2$store.dispatch,
        getState = _ref2$store.getState;

    if (!(0, _auth.isLoaded)(getState())) {
      return dispatch((0, _auth.load)());
    }
    return null;
  }
}]), _dec5 = (0, _reactRedux.connect)(function (state) {
  return {
    mobileMode: state.mobile.mobileMode,
    list: state.studies.list,
    isLoading: state.studies.loading,
    account: state.auth.current_account
  };
}, {
  get: _studies.getStudies,
  refresh: _studies.studiesRefresh
}), _dec4(_class5 = _dec5(_class5 = function (_Studies4) {
  (0, _inherits3.default)(StudiesAll, _Studies4);

  function StudiesAll() {
    (0, _classCallCheck3.default)(this, StudiesAll);
    return (0, _possibleConstructorReturn3.default)(this, (StudiesAll.__proto__ || (0, _getPrototypeOf2.default)(StudiesAll)).apply(this, arguments));
  }

  return StudiesAll;
}(Studies)) || _class5) || _class5);