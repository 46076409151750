'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StaffMessagesReceived = exports.Staff = undefined;

var _StaffMessages = require('./StaffMessages.jsx');

Object.defineProperty(exports, 'StaffMessagesReceived', {
  enumerable: true,
  get: function get() {
    return _StaffMessages.StaffMessagesReceived;
  }
});

var _StaffMessages2 = _interopRequireDefault(_StaffMessages);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.Staff = _StaffMessages2.default;