'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.actions = undefined;
exports.default = reducer;
exports.getLanding = getLanding;
exports.getShortLinks = getShortLinks;
exports.getShortLinkDetail = getShortLinkDetail;
exports.getShortLinkReport = getShortLinkReport;
exports.getShortLinkFiles = getShortLinkFiles;
exports.selectShortLink = selectShortLink;
exports.unselectShortLink = unselectShortLink;
exports.unselectAllShortLinks = unselectAllShortLinks;
exports.trimShortLink = trimShortLink;
exports.refreshShortLink = refreshShortLink;
exports.createShortLinks = createShortLinks;
exports.deleteShortLinks = deleteShortLinks;
exports.updateShortLinks = updateShortLinks;
exports.updateShortLinkStatus = updateShortLinkStatus;
exports.getShortLinkStats = getShortLinkStats;

var _apiAction = require('../apiAction');

var _apiAction2 = _interopRequireDefault(_apiAction);

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var actions = exports.actions = new _apiAction2.default('SHORTLINKS', [_apiAction.ACTION_TYPES.DATA, _apiAction.ACTION_TYPES.APPEND, _apiAction.ACTION_TYPES.TRIM, _apiAction.ACTION_TYPES.SELECT]);

var initialState = {
    loading: false,
    status: actions.APPEND,
    list: [],
    selected: [],
    data: {}
};

function reducer() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
    var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    return actions.getStateForAction(state, action);
}

function getLanding(id) {
    return {
        types: [actions.APPEND, actions.APPEND_SUCCESS, actions.APPEND_FAIL],
        promise: function promise(client) {
            return client.get('/landing/' + id);
        }
    };
}

function getShortLinks(states) {
    return {
        types: [actions.APPEND, actions.APPEND_SUCCESS, actions.APPEND_FAIL],
        promise: function promise(client) {
            return client.get('/short_link', { states: states });
        }
    };
}

function getShortLinkDetail(id) {
    return {
        types: [actions.APPEND, actions.APPEND_SUCCESS, actions.APPEND_FAIL],
        promise: function promise(client) {
            return client.get('/short_link/detail?url_id=' + id);
        }
    };
}

function getShortLinkReport(startDate, endDate, shortLink) {
    return {
        types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
        promise: function promise(client) {
            return client.get('short_link/report', {
                params: {
                    shortlink: shortLink,
                    from: startDate.valueOf(),
                    to: endDate.valueOf()
                }
            });
        }
    };
}

function getShortLinkFiles(params) {
    return {
        types: [actions.APPEND, actions.APPEND_SUCCESS, actions.APPEND_FAIL],
        promise: function promise(client) {
            return client.get('/short_link/files', { params: params });
        }
    };
}

function selectShortLink(index) {
    return {
        type: actions.SELECT,
        index: index
    };
}

function unselectShortLink(index) {
    return {
        type: actions.SELECT_OFF,
        index: index
    };
}

function unselectAllShortLinks() {
    return {
        type: actions.SELECT_NONE
    };
}

function trimShortLink(count) {
    return {
        type: actions.TRIM,
        count: count
    };
}

function refreshShortLink() {
    return {
        type: actions.TRIM,
        count: 0
    };
}

function createShortLinks(data) {

    return {
        types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
        promise: function promise(client) {
            return client.post('/short_link', { data: data });
        }
    };
}

function deleteShortLinks(promotion_uid) {
    return {
        types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
        promise: function promise(client) {
            return client.del('/promotion', {
                params: { promotion_uid: promotion_uid }
            });
        }
    };
}

function updateShortLinks(params, data) {
    var start = void 0,
        end = '';
    if (data.start_date) {
        start = (0, _moment2.default)(data.start_date).format();
        end = (0, _moment2.default)(data.end_date).format();
        data.start_date = start;
        data.end_date = end;
    }
    return {
        types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
        promise: function promise(client) {
            return client.put('/short_link', {
                params: params,
                data: data
            });
        }
    };
}

function updateShortLinkStatus(params) {
    return {
        types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
        promise: function promise(client) {
            return client.put('/short_link', {
                params: params
            });
        }
    };
}

function getShortLinkStats(params) {
    console.log(params);
    return {
        types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
        promise: function promise(client) {
            return client.get('short_link/stats', { params: params });
        }
    };
}