'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});
exports.default = undefined;

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactWebTabs = require('react-web-tabs');

var _reactRouter = require('react-router');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = require('./CustomTabs.scss');

var CustomTabs = function (_Component) {
	(0, _inherits3.default)(CustomTabs, _Component);

	function CustomTabs(props) {
		(0, _classCallCheck3.default)(this, CustomTabs);

		var _this = (0, _possibleConstructorReturn3.default)(this, (CustomTabs.__proto__ || (0, _getPrototypeOf2.default)(CustomTabs)).call(this, props));

		_this.state = {
			activeTab: 0
		};
		return _this;
	}

	(0, _createClass3.default)(CustomTabs, [{
		key: 'componentDidMount',
		value: function componentDidMount() {
			if (this.props.activeRoute.single) {
				// this.setState({ activeTab: 0 })
				this.onActiveTab(0);
			} else if (this.props.activeRoute.file) {
				this.onActiveTab(2);
				// this.setState({ activeTab: 2 })
			}
		}
	}, {
		key: 'onActiveTab',
		value: function onActiveTab(index) {
			// if (this.props.activeRoute.single) {
			// 	<Link to={'/push/single'} />
			// } else if (this.props.activeRoute.file) {
			// 	<Link to={'/push/file'} />
			// }
			// this.props.history.push('/push/file')
			// if (index == 0) {
			// 	window.location.href="/push/single"
			// } else if (index == 2) {
			// 	window.location.href="/push/file"
			// }
			// window.location.href="pagelink"
			this.setState({ activeTab: index });
		}
	}, {
		key: 'getDefault',
		value: function getDefault() {
			if (this.props.activeRoute.single) {
				return 'single';
			} else if (this.props.activeRoute.file) {
				return 'file';
			}
		}
	}, {
		key: 'render',
		value: function render() {
			var _this2 = this;

			return _react2.default.createElement(
				_reactWebTabs.Tabs,
				{ defaultTab: this.getDefault(), className: styles.container },
				_react2.default.createElement(
					_reactWebTabs.TabList,
					{ className: styles.header },
					this.props.configuration.map(function (componentToRender, index) {
						return _react2.default.createElement(
							_reactWebTabs.Tab,
							{
								key: componentToRender.key,
								disabled: componentToRender.disable,
								tabFor: componentToRender.key,
								className: _this2.state.activeTab === index ? styles.isActive : null,
								onClick: function onClick() {
									return _this2.onActiveTab(index);
								} },
							componentToRender.title
						);
					})
				),
				this.props.configuration.map(function (componentToRender) {
					return _react2.default.createElement(
						_reactWebTabs.TabPanel,
						{
							tabId: componentToRender.key },
						componentToRender.component
					);
				})
			);
		}
	}]);
	return CustomTabs;
}(_react.Component);

exports.default = CustomTabs;
module.exports = exports['default'];