'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _class, _class2, _temp;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactRedux = require('react-redux');

var _interaction = require('redux/modules/interaction');

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _Campaign = require('./Campaign.scss');

var _Campaign2 = _interopRequireDefault(_Campaign);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Name = (_dec = (0, _reactRedux.connect)(function (state) {
  return { name: state.interaction.name };
}, function (dispatch) {
  return {
    onInputStateUpdated: function onInputStateUpdated(property, event) {
      dispatch((0, _interaction.updateInteraction)(property, event.target.value));
    }
  };
}), _dec(_class = (_temp = _class2 = function (_React$Component) {
  (0, _inherits3.default)(Name, _React$Component);

  function Name(props) {
    (0, _classCallCheck3.default)(this, Name);

    var _this = (0, _possibleConstructorReturn3.default)(this, (Name.__proto__ || (0, _getPrototypeOf2.default)(Name)).call(this, props));

    _this.onInputStateUpdated = props.onInputStateUpdated.bind(_this, 'name');
    return _this;
  }

  (0, _createClass3.default)(Name, [{
    key: 'render',
    value: function render() {
      return _react2.default.createElement(
        'div',
        { className: _Campaign2.default.block },
        _react2.default.createElement(
          'div',
          { className: _Campaign2.default.name },
          _react2.default.createElement(
            'div',
            { className: _Campaign2.default.inputField },
            _react2.default.createElement('input', {
              id: 'campaing-name',
              type: 'text',
              maxLength: '48',
              value: this.props.name,
              onChange: this.onInputStateUpdated,
              className: _Campaign2.default.input
            }),
            _react2.default.createElement(
              'label',
              { className: _Campaign2.default.label, htmlFor: 'campaing-name' },
              (0, _translations2.default)('CAMPAIGN NAME')
            )
          )
        )
      );
    }
  }]);
  return Name;
}(_react2.default.Component), _class2.propTypes = {
  name: _propTypes2.default.string.isRequired,
  onInputStateUpdated: _propTypes2.default.func.isRequired
}, _temp)) || _class);
exports.default = Name;
module.exports = exports['default'];