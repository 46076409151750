'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _getOwnPropertyDescriptor = require('babel-runtime/core-js/object/get-own-property-descriptor');

var _getOwnPropertyDescriptor2 = _interopRequireDefault(_getOwnPropertyDescriptor);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _dec2, _class, _desc, _value, _class2, _class3, _temp;

var _components = require('components');

var _interaction = require('redux/modules/interaction');

var _studies = require('redux/modules/studies');

var _auth = require('redux/modules/auth');

var _Allow = require('../Allow/Allow');

var _reactCopyToClipboard = require('react-copy-to-clipboard');

var _reactCopyToClipboard2 = _interopRequireDefault(_reactCopyToClipboard);

var _reactRouter = require('react-router');

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _reduxConnect = require('redux-connect');

var _coreDecorators = require('core-decorators');

var _reactRedux = require('react-redux');

var _qrs = require('redux/modules/qrs');

var _themeEnabled = require('helpers/themeEnabled');

var _util = require('util');

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _reachHistory = require('../../helpers/reachHistory');

var _sessionPermissions = require('../../helpers/sessionPermissions');

var _Study = require('./Study.scss');

var _Study2 = _interopRequireDefault(_Study);

var _useBasename = require('../../helpers/useBasename');

var _useBasename2 = _interopRequireDefault(_useBasename);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
  var desc = {};
  Object['ke' + 'ys'](descriptor).forEach(function (key) {
    desc[key] = descriptor[key];
  });
  desc.enumerable = !!desc.enumerable;
  desc.configurable = !!desc.configurable;

  if ('value' in desc || desc.initializer) {
    desc.writable = true;
  }

  desc = decorators.slice().reverse().reduce(function (desc, decorator) {
    return decorator(target, property, desc) || desc;
  }, desc);

  if (context && desc.initializer !== void 0) {
    desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
    desc.initializer = undefined;
  }

  if (desc.initializer === void 0) {
    Object['define' + 'Property'](target, property, desc);
    desc = null;
  }

  return desc;
}

var md = {
  assignment: require('react-icons/lib/md/assignment'),
  stop: require('react-icons/lib/md/stop'),
  copy: require('react-icons/lib/md/note-add'),
  link: require('react-icons/lib/md/link'),
  download: require('react-icons/lib/md/cloud-download'),
  share: require('react-icons/lib/md/call-made'),
  arrowBack: require('react-icons/lib/md/arrow-back')
};

var panelSources = {
  DATA_STORE: (0, _translations2.default)('PANEL STUDY'),
  OPEN: (0, _translations2.default)('OPEN STUDY')
};

var Results = (_dec = (0, _reduxConnect.asyncConnect)([{
  key: 'interaction',
  promise: function promise(_ref) {
    var query = _ref.location.query,
        dispatch = _ref.store.dispatch;
    var uid = query.uid;

    if (uid) {
      return dispatch((0, _interaction.getInteraction)(uid));
    }
    return dispatch((0, _reachHistory.push)('/studies/'));
  }
}, {
  key: 'results',
  promise: function promise(_ref2) {
    var query = _ref2.location.query,
        dispatch = _ref2.store.dispatch;
    var uid = query.uid;

    if (uid) {
      return dispatch((0, _interaction.getInteractionResults)(uid, 'STUDY'));
    }
    return null;
  }
}, {
  key: "auth",
  promise: function promise(_ref3) {
    var _ref3$store = _ref3.store,
        dispatch = _ref3$store.dispatch,
        getState = _ref3$store.getState;

    if (!(0, _auth.isLoaded)(getState())) {
      return dispatch((0, _auth.load)());
    }
    return null;
  }
}]), _dec2 = (0, _reactRedux.connect)(function (state) {
  return {
    mobileMode: state.mobile.mobileMode,
    interaction: state.interaction,
    user: state.auth.data.user,
    studies: state.studies.list
  };
}, { requestStop: _interaction.requestStop, push: _reachHistory.push, getQrs: _qrs.getQrs, pushState: _reachHistory.push, getStudies: _studies.getStudies, studiesRefresh: _studies.studiesRefresh }), _dec(_class = _dec2(_class = (_class2 = (_temp = _class3 = function (_React$Component) {
  (0, _inherits3.default)(Results, _React$Component);

  function Results(props) {
    (0, _classCallCheck3.default)(this, Results);

    var _this = (0, _possibleConstructorReturn3.default)(this, (Results.__proto__ || (0, _getPrototypeOf2.default)(Results)).call(this, props));

    _this.questionTypesTranslations = {
      OPTIONS: (0, _translations2.default)('OPTIONS'),
      OPEN: (0, _translations2.default)('OPEN'),
      RANGE: (0, _translations2.default)('RANGE'),
      YES_NO: (0, _translations2.default)('YES_NO'),
      MULTIPLE_CHOICE: (0, _translations2.default)('MULTIPLE_CHOICE'),
      AGE: (0, _translations2.default)('AGE'),
      GENDER: (0, _translations2.default)('GENDER'),
      HTML: (0, _translations2.default)('HTML'),
      BANDWIDTH: (0, _translations2.default)('BANDWIDTH'),
      NPS: (0, _translations2.default)('NPS'),
      MEDIA_OPTIONS: (0, _translations2.default)('MEDIA OPTIONS'),
      WHATSAPP: (0, _translations2.default)('WHATSAPP')
    };

    _this.state = {
      active: null,
      qr: {},
      qrNew: {}
    };
    _this.props.studiesRefresh();
    _this.props.getStudies();
    return _this;
  }

  (0, _createClass3.default)(Results, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      this.getDetail();
      this.render();
    }
  }, {
    key: 'getDetail',
    value: function getDetail() {
      var _this2 = this;

      var filters = {
        qr_id: this.props.interaction.configuration.public_web_access.qr_id,
        study_uid: this.props.interaction._id
      };
      if (filters.qr_id) {
        this.props.getQrs(filters).then(function (qr) {
          _this2.setState({
            qr: qr[0] || {}
          });
        });
      }
    }
  }, {
    key: 'getMessage',
    value: function getMessage(messageType) {
      var units = this.props.results.real_time.workflow_units;
      return units.find(function (s) {
        return s.action.name === messageType;
      });
    }
  }, {
    key: 'getQuestions',
    value: function getQuestions() {
      var units = this.props.results.real_time.workflow_units;
      return units.filter(function (s) {
        return s.action.action_type === 'question';
      }).map(function (s) {
        return {
          type: s.stats.type,
          alternative_type: s.stats.alternative_type,
          content: s.action.content,
          stats: s.stats
        };
      });
    }
  }, {
    key: 'getInvitationMessage',
    value: function getInvitationMessage() {
      if (this.props.interaction && this.props.interaction.configuration && this.props.interaction.configuration.messages && this.props.interaction.configuration.messages.invitation.enabled) {
        return {
          action: {
            action_type: 'message',
            content: this.props.interaction.configuration.messages.invitation.body,
            jumps: [],
            name: 'invitation',
            stats: {
              alternative_type: '',
              reminders_count: 0,
              sent_count: 0,
              type: ''
            }
          },
          name: 'invitation',
          stats: {
            alternative_type: '',
            reminders_count: 0,
            sent_count: 0,
            type: ''
          }
        };
      }
      return null;
    }
  }, {
    key: 'handleStop',
    value: function handleStop() {
      var _this3 = this;

      this.props.requestStop(this.props.interaction._id).then(function () {
        _this3.props.push({
          pathname: '/success',
          state: {
            goTo: '/studies/',
            message: (0, _translations2.default)('STUDY STOP SUCCESS'),
            modal: true,
            returnTo: '/studies/'
          }
        });
      });
    }
  }, {
    key: 'handleClose',
    value: function handleClose() {
      this.setState({
        active: null
      });
    }
  }, {
    key: 'handleTabChange',
    value: function handleTabChange(value, event) {
      this.setState({
        active: this.state.active === value ? null : value
      });
    }
  }, {
    key: 'sendingChannelButton',
    value: function sendingChannelButton() {
      if (this.props.interaction.configuration.panel_source === 'OPEN') {
        var shareUrl = this.props.interaction.configuration.public_web_access ? this.props.interaction.configuration.public_web_access.short_uri || this.props.interaction.configuration.public_web_access.full_uri : null;
        var logo = void 0;
        if (shareUrl) {
          var sendingChannel = this.props.interaction.configuration.sending_channels[0];
          switch (sendingChannel) {
            case 'WHATSAPP':
              logo = require('components/Study/svg/whatsapp.svg');
              return this.renderShareButton(sendingChannel, logo, (0, _translations2.default)('WHATSAPP'));
            case 'FACEBOOK':
              logo = require('components/Study/svg/facebook.svg');
              return this.renderShareButton(sendingChannel, logo, (0, _translations2.default)('FACEBOOK'));
            case 'TWITTER':
              logo = require('components/Study/svg/twitter.svg');
              return this.renderShareButton(sendingChannel, logo, (0, _translations2.default)('TWITTER'));
            default:
              return _react2.default.createElement(
                'a',
                { onClick: this.handleTabChange.bind(this, 'COPY'), className: _Study2.default.action + ' ' + (this.state.active === 'COPY' ? _Study2.default.active : '') + ' ' + _Study2.default.copy },
                _react2.default.createElement(
                  _reactCopyToClipboard2.default,
                  {
                    text: shareUrl,
                    onCopy: function onCopy() {
                      return alert((0, _translations2.default)('COPIED TO CLIPBOARD'));
                    } },
                  _react2.default.createElement(
                    'div',
                    { className: _Study2.default.copyicon },
                    _react2.default.createElement(
                      'div',
                      { className: _Study2.default.icon },
                      _react2.default.createElement(md.link, { size: 25, color: '#FFF' })
                    ),
                    _react2.default.createElement(
                      'span',
                      { className: _Study2.default.sub },
                      (0, _translations2.default)('COPY TO CLIPBOARD SHORT')
                    )
                  )
                )
              );
          }
        }
      } else if (this.props.interaction.configuration.panel_source == 'WEBHOOK') {
        var _logo = require('components/Study/svg/apissmall.svg');
        return this.renderShareButton('API', _logo, (0, _translations2.default)('API'));
      }
      return null;
    }
  }, {
    key: 'getFileName',
    value: function getFileName(filePath) {
      if (filePath) {
        var name = filePath.substring(filePath.lastIndexOf("/") + 1);
        var path = (0, _useBasename2.default)('/downloads/qr-files/' + name);
        return path;
      }
    }
  }, {
    key: 'sendingChannelDialog',
    value: function sendingChannelDialog() {
      if (this.state.active === 'WHATSAPP' || this.state.active === 'FACEBOOK' || this.state.active === 'TWITTER') {
        var shareUrl = this.props.interaction.configuration.public_web_access ? this.props.interaction.configuration.public_web_access.short_uri || this.props.interaction.configuration.public_web_access.full_uri : null;
        var someCustomText = encodeURIComponent((0, _translations2.default)('SHARE LINK CUSTOM TEXT'));
        var logo = void 0;
        var link = void 0;
        if (shareUrl) {
          var sendingChannel = this.props.interaction.configuration.sending_channels[0];
          switch (sendingChannel) {
            case 'WHATSAPP':
              logo = '/static/share/whatsapp.svg';
              link = 'https://api.whatsapp.com/send?text=' + someCustomText + '  ' + shareUrl;
              return this.renderShareDialog(logo, link, (0, _translations2.default)('WHATSAPP'));
            case 'FACEBOOK':
              logo = '/static/share/facebook.svg';
              link = 'https://www.facebook.com/sharer/sharer.php?u=' + shareUrl + '&display=popup';
              return this.renderShareDialog(logo, link, (0, _translations2.default)('FACEBOOK'));
            case 'TWITTER':
              logo = '/static/share/twitter.svg';
              link = 'https://twitter.com/home?status=' + someCustomText + ' ' + shareUrl;
              return this.renderShareDialog(logo, link, (0, _translations2.default)('TWITTER'));
            default:
              return null;
          }
        }
      } else if (this.state.active === 'API') {
        var _logo2 = '/static/share/apislogo.svg';
        var baseUrl = window.location ? window.location.origin : 'http://comunicador.tigo.com.gt';
        var _link = baseUrl + '/webhooks/studies/' + this.props.interaction.external_uid + '/profile/add';
        return this.renderShareDialog(_logo2, _link, (0, _translations2.default)('API'), true);
      }
      return null;
    }
  }, {
    key: 'getLink',
    value: function getLink(item) {
      var list = this.props.studies;
      var interaction = list.find(function (obj) {
        return obj._id === item;
      });
      if (interaction) {
        var openIf = ['NOT_STARTED'];
        var resultsIf = ['IN_PROGRESS', 'FINISHED', 'EXPIRED', 'ABORTED', 'STOPPED'];
        if (resultsIf.indexOf(interaction.status) > -1) {
          return {
            pathname: '/studies/results',
            query: { uid: item },
            state: { modal: true, returnTo: '/studies', modalSize: { width: '800px', height: '600px' }, resultType: 'STUDY' }
          };
        } else if (openIf.indexOf(interaction.status) > -1) {
          return {
            pathname: '/studies/study/edit',
            query: { uid: item },
            state: { modal: true, returnTo: '/studies', fullModal: true }
          };
        }
      }
      return null;
    }
  }, {
    key: 'renderMessage',
    value: function renderMessage(messageUnit) {
      if (!messageUnit) {
        return null;
      }
      var style = require('./Study.scss');
      return _react2.default.createElement(
        'tr',
        { key: messageUnit.name, className: style.message },
        _react2.default.createElement(
          'td',
          { className: style.questionName },
          messageUnit.action.content
        ),
        _react2.default.createElement(
          'td',
          { className: style.subtitle },
          (0, _translations2.default)('SENT MESSAGES'),
          ': ',
          messageUnit.stats.sent_count
        )
      );
    }
  }, {
    key: 'renderQuestion',
    value: function renderQuestion(question, index) {
      var style = require('./Study.scss');
      var phone = require('./phone.svg');

      if (question.type === 'OPEN') {
        return _react2.default.createElement(
          'tr',
          { key: index, className: style.result },
          _react2.default.createElement(
            'td',
            { className: style.questionName },
            _react2.default.createElement(
              'div',
              { className: style.flex },
              _react2.default.createElement(
                'h1',
                null,
                '#',
                index
              ),
              question.alternative_type === 'WHATSAPP' || question.alternative_type === 'HTML' ? _react2.default.createElement(_components.HtmlPreview, { html: question.content }) : _react2.default.createElement(
                'h2',
                null,
                question.content
              )
            )
          ),
          _react2.default.createElement(
            'td',
            null,
            _react2.default.createElement(_components.TagCloudChart, { key: index, question: question })
          )
        );
      }
      var plotType = void 0;
      var colors = void 0;
      switch (question.type) {
        case 'OPTIONS':
          plotType = 'pie';
          switch (question.stats.alternative_type) {
            case 'YES_NO':
              colors = ['#9AEA9A', '#EA9A9A'];
              break;
            case 'GENDER':
              colors = ['#139CF4', '#EF41A6'];
              break;
            default:
              break;
          }
          break;
        case 'RANGE':
          if (question.stats.alternative_type == 'NPS') {
            plotType = 'nps';
            colors = ['#ed1212', '#eed51b', '#44b909'];
            break;
          } else {
            plotType = 'column';
            colors = ['blue', 'blue', 'blue', 'blue', 'blue', 'blue'];
            break;
          }
        case 'AGE':
          plotType = 'area';
          break;
        case 'MULTIPLE_CHOICE':
          plotType = 'pie';
          break;
        default:
          break;
      }

      switch (plotType) {
        case 'pie':
          return _react2.default.createElement(
            'tr',
            { key: index, className: style.result },
            _react2.default.createElement(
              'td',
              { className: style.questionName },
              _react2.default.createElement(
                'div',
                { className: style.flex },
                _react2.default.createElement(
                  'h1',
                  null,
                  '#',
                  index
                ),
                _react2.default.createElement(
                  'h2',
                  null,
                  question.content
                )
              )
            ),
            _react2.default.createElement(
              'td',
              null,
              _react2.default.createElement(_components.PieChart, { question: question, plotType: plotType, colors: colors })
            )
          );
        case 'area':
          return _react2.default.createElement(
            'tr',
            { key: index, className: style.result },
            _react2.default.createElement(
              'td',
              { className: style.questionName },
              _react2.default.createElement(
                'div',
                { className: style.flex },
                _react2.default.createElement(
                  'h1',
                  null,
                  '#',
                  index
                ),
                _react2.default.createElement(
                  'h2',
                  null,
                  question.content
                )
              )
            ),
            _react2.default.createElement(
              'td',
              null,
              _react2.default.createElement(_components.AreaChart, { question: question, plotType: plotType })
            )
          );
        case 'column':
          return _react2.default.createElement(
            'tr',
            { key: index, className: style.result },
            _react2.default.createElement(
              'td',
              { className: style.questionName },
              _react2.default.createElement(
                'div',
                { className: style.flex },
                _react2.default.createElement(
                  'h1',
                  null,
                  '#',
                  index
                ),
                _react2.default.createElement(
                  'h2',
                  null,
                  question.content
                )
              )
            ),
            _react2.default.createElement(
              'td',
              null,
              _react2.default.createElement(_components.PieChart, { question: question, plotType: plotType, colors: colors, showOptions: false, size: 250 })
            )
          );
        case 'nps':
          return _react2.default.createElement(
            'tr',
            { key: index, className: style.result },
            _react2.default.createElement(
              'td',
              { className: style.questionName },
              _react2.default.createElement(
                'div',
                { className: style.flex },
                _react2.default.createElement(
                  'h1',
                  null,
                  '#',
                  index
                ),
                _react2.default.createElement(
                  'h2',
                  null,
                  question.content
                )
              )
            ),
            _react2.default.createElement(
              'td',
              null,
              _react2.default.createElement(_components.NpsChart, { question: question, plotType: plotType, colors: colors, showOptions: false, size: 250 })
            )
          );
        default:
          return null;
      }
    }
  }, {
    key: 'renderQuestions',
    value: function renderQuestions() {
      var _this4 = this;

      return this.getQuestions().map(function (s, index) {
        return _this4.renderQuestion(s, index);
      });
    }
  }, {
    key: 'renderResults',
    value: function renderResults() {
      var values = [this.renderMessage(this.getMessage('Invitación'))].concat(this.renderMessage(this.getMessage('Bienvenida'))).concat(this.renderQuestions()).concat([this.renderMessage(this.getMessage('Despedida'))]).filter(function (item) {
        return item !== null;
      });

      return _react2.default.createElement(
        'table',
        { className: _Study2.default.table },
        _react2.default.createElement(
          'tbody',
          { className: _Study2.default.tbody },
          values
        )
      );
    }
  }, {
    key: 'renderHooksKpi',
    value: function renderHooksKpi() {
      return _react2.default.createElement(
        'table',
        { className: _Study2.default.table },
        _react2.default.createElement(
          'tbody',
          { className: _Study2.default.tbody },
          _react2.default.createElement(
            'tr',
            { className: _Study2.default.message },
            _react2.default.createElement(
              'td',
              { className: _Study2.default.questionName },
              (0, _translations2.default)('WEBHOOK CALLS INTERACTIONS')
            ),
            _react2.default.createElement(
              'td',
              { className: _Study2.default.subtitle },
              this.props.results.external_stats.interaction_trigger_calls
            )
          ),
          _react2.default.createElement(
            'tr',
            { className: _Study2.default.message },
            _react2.default.createElement(
              'td',
              { className: _Study2.default.questionName },
              (0, _translations2.default)('WEBHOOK CALLS INTERACTIONS ERROR')
            ),
            _react2.default.createElement(
              'td',
              { className: _Study2.default.subtitle },
              this.props.results.external_stats.interaction_trigger_calls_err
            )
          ),
          _react2.default.createElement(
            'tr',
            { className: _Study2.default.message },
            _react2.default.createElement(
              'td',
              { className: _Study2.default.questionName },
              (0, _translations2.default)('WEBHOOK CALLS QUESTION')
            ),
            _react2.default.createElement(
              'td',
              { className: _Study2.default.subtitle },
              this.props.results.external_stats.question_trigger_calls
            )
          ),
          _react2.default.createElement(
            'tr',
            { className: _Study2.default.message },
            _react2.default.createElement(
              'td',
              { className: _Study2.default.questionName },
              (0, _translations2.default)('WEBHOOK CALLS QUESTION ERROR')
            ),
            _react2.default.createElement(
              'td',
              { className: _Study2.default.subtitle },
              this.props.results.external_stats.question_trigger_calls_err
            )
          )
        )
      );
    }
  }, {
    key: 'renderShareButton',
    value: function renderShareButton(tab, logo, text) {
      return _react2.default.createElement(
        'a',
        { onClick: this.handleTabChange.bind(this, tab), target: 'no-openner _blank', className: _Study2.default.action + '  ' + (this.state.active === tab ? _Study2.default.active : '') },
        _react2.default.createElement('div', { className: _Study2.default.icon, dangerouslySetInnerHTML: { __html: logo } }),
        _react2.default.createElement(
          'span',
          { className: _Study2.default.sub },
          text
        )
      );
    }
  }, {
    key: 'renderShareDialog',
    value: function renderShareDialog(logo, link, text) {
      var showLink = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;

      return _react2.default.createElement(
        'div',
        { className: _Study2.default.dialog + ' ' + _Study2.default.chare },
        _react2.default.createElement('img', { className: _Study2.default.img, src: logo }),
        _react2.default.createElement(
          'div',
          { className: _Study2.default.label },
          text
        ),
        !showLink ? _react2.default.createElement(
          'a',
          { className: _Study2.default.button, target: '_blank', href: link },
          (0, _translations2.default)('SHARE')
        ) : _react2.default.createElement(
          'div',
          { className: _Study2.default.url },
          _react2.default.createElement(
            'div',
            { className: _Study2.default.textarea },
            _react2.default.createElement('textarea', { readOnly: true, className: _Study2.default.title, defaultValue: link })
          ),
          _react2.default.createElement(
            _reactCopyToClipboard2.default,
            {
              text: link,
              onCopy: function onCopy() {
                return alert((0, _translations2.default)('COPIED TO CLIPBOARD'));
              }
            },
            _react2.default.createElement(
              'div',
              { className: _Study2.default.button },
              _react2.default.createElement(
                'span',
                { className: _Study2.default.sub },
                (0, _translations2.default)('COPY TO CLIPBOARD')
              )
            )
          )
        )
      );
    }
  }, {
    key: 'renderDuplicateDialog',
    value: function renderDuplicateDialog() {
      return _react2.default.createElement(
        'div',
        { className: _Study2.default.dialog + ' ' + _Study2.default.duplicate },
        _react2.default.createElement('img', { src: (0, _useBasename2.default)('/static/share/copy.svg'), alt: '', className: _Study2.default.img }),
        _react2.default.createElement(
          'div',
          { className: _Study2.default.label },
          (0, _translations2.default)('DUPLICATE THIS STUDY')
        ),
        _react2.default.createElement(
          _reactRouter.Link,
          {
            className: _Study2.default.button,
            to: {
              pathname: '/studies/study/copy',
              query: { copy: this.props.interaction._id },
              state: { modal: true, returnTo: '/studies/', fullModal: true }
            }
          },
          _react2.default.createElement(
            'span',
            { className: _Study2.default.sub },
            (0, _translations2.default)('DUPLICATE LINK')
          )
        )
      );
    }
  }, {
    key: 'renderDownloadDialog',
    value: function renderDownloadDialog(url) {
      return _react2.default.createElement(
        'div',
        { className: _Study2.default.dialog + ' ' + _Study2.default.download },
        _react2.default.createElement('img', { src: (0, _useBasename2.default)('/static/share/descargar.svg'), alt: '', className: _Study2.default.img }),
        _react2.default.createElement(
          'div',
          { className: _Study2.default.label },
          (0, _translations2.default)('DOWNLOAD THIS STUDY')
        ),
        _react2.default.createElement(
          'a',
          { className: _Study2.default.button, target: '_blank', href: url },
          (0, _translations2.default)('DOWNLOAD')
        )
      );
    }
  }, {
    key: 'renderStopDialog',
    value: function renderStopDialog() {
      return _react2.default.createElement(
        'div',
        { className: _Study2.default.dialog + ' ' + _Study2.default.stop },
        _react2.default.createElement('img', { src: (0, _useBasename2.default)('/static/share/stop.svg'), className: _Study2.default.img }),
        _react2.default.createElement(
          'div',
          { className: _Study2.default.label },
          (0, _translations2.default)('STOP THIS STUDY')
        ),
        _react2.default.createElement(
          'a',
          { className: _Study2.default.button, onClick: this.handleStop },
          (0, _translations2.default)('STOP')
        )
      );
    }
  }, {
    key: 'renderQr',
    value: function renderQr(qr) {
      if (!qr.name) {
        return _react2.default.createElement('div', null);
      }
      return _react2.default.createElement(
        'div',
        { className: _Study2.default.containerQr },
        _react2.default.createElement(
          'div',
          { className: _Study2.default.titleqr },
          ' ',
          (0, _translations2.default)("STUDY QR")
        ),
        qr.urls.new_url ? _react2.default.createElement(
          'div',
          { className: _Study2.default.errorBanner },
          ' ',
          (0, _translations2.default)("NO VALID INTERACTION QR")
        ) : null,
        _react2.default.createElement(
          'div',
          { className: _Study2.default.container },
          _react2.default.createElement(
            'div',
            { className: _Study2.default.detail },
            _react2.default.createElement(
              'div',
              { className: _Study2.default.text },
              ' ',
              qr.name ? qr.name : (0, _translations2.default)('NO NAME QR'),
              ' '
            ),
            _react2.default.createElement(
              'h2',
              null,
              ' ',
              (0, _translations2.default)('FIRST_NAME'),
              ' '
            )
          ),
          _react2.default.createElement(
            'div',
            { className: _Study2.default.detail },
            _react2.default.createElement(
              'div',
              { className: _Study2.default.text },
              ' ',
              qr.short_url,
              ' '
            ),
            _react2.default.createElement(
              'h2',
              null,
              ' ',
              (0, _translations2.default)('SHORT URL'),
              ' '
            )
          ),
          _react2.default.createElement(
            'div',
            { className: _Study2.default.detail },
            _react2.default.createElement(
              'div',
              { className: _Study2.default.text },
              ' ',
              qr.urls ? qr.urls.visits : (0, _translations2.default)('No results yet'),
              ' '
            ),
            _react2.default.createElement(
              'h2',
              null,
              ' ',
              (0, _translations2.default)('VISITS'),
              ' '
            )
          )
        ),
        qr.urls.new_url ? null : _react2.default.createElement(
          'div',
          { className: _Study2.default.footerDownload },
          _react2.default.createElement(
            'a',
            { className: _Study2.default.download, target: '_blank', href: this.getFileName(qr.path_s3), download: true },
            ' ',
            (0, _translations2.default)('DOWNLOAD QR'),
            ' '
          ),
          _react2.default.createElement(
            'div',
            { className: _Study2.default.detail },
            ' ',
            (0, _translations2.default)('DOWNLOAD QR DETAIL'),
            ' '
          )
        )
      );
    }
  }, {
    key: 'renderShare',
    value: function renderShare() {
      var whatsapp = require('components/Study/svg/whatsapp.svg');
      var facebook = require('components/Study/svg/facebook.svg');
      var twitter = require('components/Study/svg/twitter.svg');
      var qr = this.state.qr;
      var someCustomText = encodeURIComponent((0, _translations2.default)('SHARE LINK CUSTOM TEXT'));
      var linkWhatsapp = 'https://api.whatsapp.com/send?text=' + someCustomText + '  ' + qr.short_url;
      var linkFacebook = 'https://www.facebook.com/sharer/sharer.php?u=' + qr.short_url + '&display=popup';
      var linkTwitter = 'https://twitter.com/home?status=' + someCustomText + ' ' + qr.short_url;
      var shareUrl = this.props.interaction.configuration.public_web_access ? this.props.interaction.configuration.public_web_access.short_uri || this.props.interaction.configuration.public_web_access.full_uri : null;
      var open = this.props.interaction.configuration.panel_source === 'OPEN';

      return _react2.default.createElement(
        'div',
        { className: _Study2.default.shareQr },
        _react2.default.createElement(
          'div',
          { className: _Study2.default.imgContainer },
          _react2.default.createElement(
            'div',
            null,
            _react2.default.createElement(
              'div',
              { className: _Study2.default.titleqr },
              ' ',
              (0, _translations2.default)("SENDING CHANNELS")
            ),
            _react2.default.createElement(
              'div',
              { className: _Study2.default.container },
              _react2.default.createElement(
                'a',
                { className: _Study2.default.link, href: linkWhatsapp, target: '_blank' },
                _react2.default.createElement('div', { className: _Study2.default.icon, dangerouslySetInnerHTML: { __html: whatsapp } }),
                _react2.default.createElement(
                  'h1',
                  null,
                  ' Whatsapp '
                )
              ),
              _react2.default.createElement(
                'a',
                { className: _Study2.default.link, href: linkFacebook, target: '_blank' },
                _react2.default.createElement('div', { className: _Study2.default.icon, dangerouslySetInnerHTML: { __html: facebook } }),
                _react2.default.createElement(
                  'h1',
                  null,
                  ' Facebook '
                )
              ),
              _react2.default.createElement(
                'a',
                { className: _Study2.default.link, href: linkTwitter, target: '_blank' },
                _react2.default.createElement('div', { className: _Study2.default.icon, dangerouslySetInnerHTML: { __html: twitter } }),
                _react2.default.createElement(
                  'h1',
                  null,
                  ' Twitter '
                )
              ),
              open && shareUrl ? _react2.default.createElement(
                'a',
                { className: _Study2.default.link },
                _react2.default.createElement(
                  _reactCopyToClipboard2.default,
                  {
                    text: shareUrl,
                    onCopy: function onCopy() {
                      return alert((0, _translations2.default)('COPIED TO CLIPBOARD'));
                    }
                  },
                  _react2.default.createElement(
                    'div',
                    null,
                    _react2.default.createElement(
                      'div',
                      { className: _Study2.default.link },
                      _react2.default.createElement(md.link, { size: 70 })
                    ),
                    _react2.default.createElement(
                      'h1',
                      null,
                      (0, _translations2.default)('COPY TO CLIPBOARD')
                    )
                  )
                )
              ) : null
            ),
            this.renderQr(qr)
          ),
          qr.urls ? qr.urls.new_url ? _react2.default.createElement(
            'div',
            { className: _Study2.default.footerDownload },
            _react2.default.createElement(
              'h1',
              null,
              ' ',
              (0, _translations2.default)('NO VALID INTERACTION QR'),
              ' '
            )
          ) : _react2.default.createElement('img', { src: this.getFileName(qr.path_s3) }) : null
        )
      );
    }
  }, {
    key: 'render',
    value: function render() {
      var interaction = this.props.interaction;

      var results = this.props.results;
      var hidden = { display: 'none' };
      var visible = { display: 'block' };

      var open = this.props.interaction.configuration.panel_source === 'OPEN';
      var sentSurveys = results.external_stats.surveys_sent || results.real_time.workflow_units.map(function (u) {
        return u.name === 'Invitación' ? u.stats.sent_count : 0;
      }).reduce(function (a, b) {
        return a + b;
      }, 0);
      var completedSurveys = results.completed.workflow_units[results.completed.workflow_units.length - 2].stats.sent_count || 0;
      var sentMessages = results.external_stats.sent_messages; // results.real_time.workflow_units.map(u => u.stats.sent_count || 0).reduce((a, b) => a + b, 0)
      var receivedMessages = results.external_stats.received_messages; // results.real_time.workflow_units.map(u => u.stats.answers ? u.stats.answers.total : 0).reduce((a, b) => a + b, 0)
      var receivedCalls = results.external_stats.api_calls;
      // const totalAnswers = results.real_time.workflow_units.map(u => (u.name !== 'Invitación' && u.stats.answers) ? u.stats.answers.total : 0).reduce((a, b) => a + b, 0)
      var scheduledFor = (0, _moment2.default)(interaction.scheduled_for);
      var runningUntil = interaction.goal.duration ? (0, _moment2.default)(scheduledFor).add(interaction.goal.duration, 'millisecond') : null;
      var shareIf = ['FINISHED', 'EXPIRED', 'ABORTED', 'STOPPED'];

      var classesTheme = (0, _themeEnabled.getThemeEnabledStyle)(_Study2.default);
      return _react2.default.createElement(
        'div',
        { className: classesTheme },
        _react2.default.createElement(
          'div',
          { className: _Study2.default.results },
          _react2.default.createElement(
            'div',
            { className: _Study2.default.bar },
            _react2.default.createElement(
              'div',
              { className: _Study2.default.icon, onClick: this.props.router.goBack },
              _react2.default.createElement(md.arrowBack, { size: 20, color: '#FFF' })
            ),
            _react2.default.createElement(
              'div',
              { className: _Study2.default.title },
              _react2.default.createElement(
                'h1',
                null,
                interaction.name
              ),
              _react2.default.createElement(
                'h2',
                null,
                panelSources[interaction.configuration.panel_source] + ' ' + (0, _translations2.default)('CREATED ON') + ' ' + (0, _moment2.default)(interaction.created_on).format('YYYY/MM/DD')
              )
            )
          ),
          _react2.default.createElement(
            'div',
            { className: _Study2.default.main },
            this.state.active && _react2.default.createElement(
              'div',
              { className: _Study2.default.close, onClick: this.handleClose },
              '\xD7'
            ),
            _react2.default.createElement(
              'div',
              { className: _Study2.default.actions },
              this.props.interaction.configuration.panel_source === 'OPEN' && shareIf.indexOf(this.props.interaction.status) === -1 ? _react2.default.createElement(
                'a',
                { onClick: this.handleTabChange.bind(this, 'SHARE'), className: _Study2.default.action },
                _react2.default.createElement(
                  'div',
                  { className: _Study2.default.icon },
                  _react2.default.createElement(md.share, { size: 25 })
                ),
                _react2.default.createElement(
                  'span',
                  { className: _Study2.default.sub },
                  (0, _translations2.default)('SHARE')
                )
              ) : null,
              shareIf.indexOf(this.props.interaction.status) === -1 ? this.sendingChannelButton() : null,
              (0, _sessionPermissions.sessionPermissions)('study:duplicate') ? _react2.default.createElement(
                'a',
                { onClick: this.handleTabChange.bind(this, 'DUPLI'), className: _Study2.default.action + ' ' + (this.state.active === 'DUPLI' ? _Study2.default.active : '') + ' ' + _Study2.default.duplicate },
                _react2.default.createElement(
                  'div',
                  { className: _Study2.default.icon },
                  _react2.default.createElement(md.copy, { size: 25, color: '#FFF' })
                ),
                _react2.default.createElement(
                  'span',
                  { className: _Study2.default.sub },
                  (0, _translations2.default)('DUPLICATE')
                )
              ) : null,
              (0, _sessionPermissions.sessionPermissions)('study:download:results') ? _react2.default.createElement(
                'a',
                { onClick: this.handleTabChange.bind(this, 'DOWNLOAD'), className: _Study2.default.action + ' ' + (this.state.active === 'DOWNLOAD' ? _Study2.default.active : '') + '  ' + _Study2.default.download },
                _react2.default.createElement(
                  'div',
                  { className: _Study2.default.icon },
                  _react2.default.createElement(md.download, { size: 25, color: '#FFF' })
                ),
                _react2.default.createElement(
                  'span',
                  { className: _Study2.default.sub },
                  (0, _translations2.default)('DOWNLOAD')
                )
              ) : null,
              (0, _sessionPermissions.sessionPermissions)('study:stop') ? shareIf.indexOf(this.props.interaction.status) === -1 ? _react2.default.createElement(
                'a',
                { onClick: this.handleTabChange.bind(this, 'STOP'), className: _Study2.default.action + ' ' + (this.state.active === 'STOP' ? _Study2.default.active : '') + '  ' + _Study2.default.strop },
                _react2.default.createElement(
                  'div',
                  { className: _Study2.default.icon },
                  _react2.default.createElement(md.stop, { size: 25 })
                ),
                _react2.default.createElement(
                  'span',
                  { className: _Study2.default.sub },
                  (0, _translations2.default)('STOP')
                )
              ) : null : null
            ),
            this.state.active === 'SHARE' && this.renderShare(),
            this.state.active === 'DUPLI' && this.renderDuplicateDialog(interaction.configuration.public_web_access.short_uri, '/static/share/copy.svg', (0, _translations2.default)('COPY LINK')),
            this.state.active === 'DOWNLOAD' && this.renderDownloadDialog((0, _useBasename2.default)('/api/studies/' + this.props.interaction._id + '/download')),
            this.state.active === 'STOP' && this.renderStopDialog(),
            this.sendingChannelDialog(),
            _react2.default.createElement(
              'div',
              { className: _Study2.default.dates },
              _react2.default.createElement('div', { className: _Study2.default.date }),
              _react2.default.createElement(
                'div',
                { className: _Study2.default.date },
                _react2.default.createElement(
                  'h1',
                  null,
                  (0, _translations2.default)('END DATE'),
                  ': ',
                  runningUntil === null ? (0, _translations2.default)('NO DATE') : runningUntil.format('DD/MM HH:mm')
                )
              )
            ),
            _react2.default.createElement(
              'div',
              { className: _Study2.default.kpis },
              !open && _react2.default.createElement(
                'div',
                { className: _Study2.default.kpi },
                _react2.default.createElement(
                  'h1',
                  null,
                  sentSurveys
                ),
                _react2.default.createElement(
                  'h2',
                  null,
                  (0, _translations2.default)('SENT SURVEYS')
                )
              ),
              _react2.default.createElement(
                'div',
                { className: _Study2.default.kpi },
                _react2.default.createElement(
                  'h1',
                  null,
                  completedSurveys
                ),
                _react2.default.createElement(
                  'h2',
                  null,
                  (0, _translations2.default)('COMPLETED SURVEYS')
                )
              ),
              this.props.interaction.configuration.panel_source !== 'WEBHOOK' ? _react2.default.createElement(
                'div',
                { className: _Study2.default.kpi },
                _react2.default.createElement(
                  'h1',
                  null,
                  sentMessages
                ),
                _react2.default.createElement(
                  'h2',
                  null,
                  (0, _translations2.default)('SENT MESSAGES')
                )
              ) : _react2.default.createElement(
                'div',
                { className: _Study2.default.kpi },
                _react2.default.createElement(
                  'h1',
                  null,
                  receivedCalls
                ),
                _react2.default.createElement(
                  'h2',
                  null,
                  (0, _translations2.default)('API CALLS RECEIVED')
                )
              ),
              _react2.default.createElement(
                'div',
                { className: _Study2.default.kpi },
                _react2.default.createElement(
                  'h1',
                  null,
                  receivedMessages
                ),
                _react2.default.createElement(
                  'h2',
                  null,
                  (0, _translations2.default)('RECEIVED RESPONSES')
                )
              )
            ),
            _react2.default.createElement(
              'div',
              { className: _Study2.default.mainResults, style: receivedMessages > 0 || completedSurveys > 0 ? visible : hidden },
              this.renderResults()
            ),
            this.props.results.external_stats.interaction_trigger_calls > 0 && this.props.results.external_stats.interaction_trigger_calls_err > 0 && this.props.results.external_stats.question_trigger_calls > 0 && this.props.results.external_stats.question_trigger_calls_err > 0 && _react2.default.createElement(
              'div',
              { className: _Study2.default.mainResults },
              this.renderHooksKpi()
            ),
            _react2.default.createElement(_components.Linkholder, {
              style: (receivedMessages > 0 || completedSurveys > 0) > 0 ? hidden : visible,
              src: (0, _useBasename2.default)('/static/empty/results.svg'),
              text: (0, _translations2.default)('NO ANSWERS YET')
            })
          )
        )
      );
    }
  }]);
  return Results;
}(_react2.default.Component), _class3.propTypes = {
  interaction: _propTypes2.default.object.isRequired,
  results: _propTypes2.default.object.isRequired,
  requestStop: _propTypes2.default.func.isRequired
}, _temp), (_applyDecoratedDescriptor(_class2.prototype, 'handleStop', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'handleStop'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, 'handleClose', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'handleClose'), _class2.prototype)), _class2)) || _class) || _class);
exports.default = Results;
module.exports = exports['default'];