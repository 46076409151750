'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _getOwnPropertyDescriptor = require('babel-runtime/core-js/object/get-own-property-descriptor');

var _getOwnPropertyDescriptor2 = _interopRequireDefault(_getOwnPropertyDescriptor);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _class, _desc, _value, _class2, _class3, _temp;

var _promotion = require('redux/modules/promotion');

var _reports = require('redux/modules/reports');

var _Countdown = require('./Countdown');

var _Countdown2 = _interopRequireDefault(_Countdown);

var _reactHelmet = require('react-helmet');

var _reactHelmet2 = _interopRequireDefault(_reactHelmet);

var _reactRouter = require('react-router');

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _coreDecorators = require('core-decorators');

var _reactRedux = require('react-redux');

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _reachHistory = require('../../helpers/reachHistory');

var _sessionPermissions = require('../../helpers/sessionPermissions');

var _PromotionDetail = require('./PromotionDetail.scss');

var _PromotionDetail2 = _interopRequireDefault(_PromotionDetail);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
  var desc = {};
  Object['ke' + 'ys'](descriptor).forEach(function (key) {
    desc[key] = descriptor[key];
  });
  desc.enumerable = !!desc.enumerable;
  desc.configurable = !!desc.configurable;

  if ('value' in desc || desc.initializer) {
    desc.writable = true;
  }

  desc = decorators.slice().reverse().reduce(function (desc, decorator) {
    return decorator(target, property, desc) || desc;
  }, desc);

  if (context && desc.initializer !== void 0) {
    desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
    desc.initializer = undefined;
  }

  if (desc.initializer === void 0) {
    Object['define' + 'Property'](target, property, desc);
    desc = null;
  }

  return desc;
}

var md = {
  arrowBack: require('react-icons/lib/md/arrow-back'),
  stop: require('react-icons/lib/md/block'),
  active: require('react-icons/lib/md/power-settings-new'),
  inactive: require('react-icons/lib/md/highlight-off'),
  delete: require('react-icons/lib/md/delete-forever'),
  edit: require('react-icons/lib/md/create'),
  download: require('react-icons/lib/md/cloud-download')
};

var PromotionDetail = (_dec = (0, _reactRedux.connect)(function (state) {
  return {
    mobileMode: state.mobile.mobileMode,
    promotions: state.promotions.list,
    user: state.auth.data.user
  };
}, { updatePromotionStatus: _promotion.updatePromotionStatus, getPromotions: _promotion.getPromotions, push: _reachHistory.push, sendTransactionReportLogRequest: _reports.sendTransactionReportLogRequest,
  getPromotionStats: _promotion.getPromotionStats }), _dec(_class = (_class2 = (_temp = _class3 = function (_React$Component) {
  (0, _inherits3.default)(PromotionDetail, _React$Component);

  function PromotionDetail(props) {
    (0, _classCallCheck3.default)(this, PromotionDetail);

    var _this = (0, _possibleConstructorReturn3.default)(this, (PromotionDetail.__proto__ || (0, _getPrototypeOf2.default)(PromotionDetail)).call(this, props));

    _this.handleInformation = function () {
      var promotions = _this.props.promotions;

      if (promotions.length > 0 && _this.state.data === null) {
        var promo = _this.props.promotions.filter(function (promo) {
          return promo._id === _this.props.params.promotion_uid;
        });
        _this.setState({ data: promo[0] }, function () {
          if (_this.state.data) {
            var promotion_uid = _this.props.params.promotion_uid;

            var from = _moment2.default.utc().startOf('day').valueOf();
            var to = _moment2.default.utc().endOf('day').valueOf();
            var params = {
              promotion_uid: promotion_uid,
              from: from,
              to: to
            };
            _this.props.getPromotionStats(params).then(function (stats) {
              _this.setState({
                stats: stats
              });
            });
          }
        });
      }
    };

    _this.renderActions = function () {
      var data = _this.state.data;

      var actionActive = void 0,
          actionInActive = void 0,
          actionstop = void 0,
          actionDelete = void 0,
          actionEdit = void 0,
          actionDownload = void 0;
      if (data.status === 'ACTIVE') {
        if ((0, _sessionPermissions.sessionPermissions)('promotions:pause')) {
          actionInActive = _react2.default.createElement(
            _reactRouter.Link,
            { onClick: _this.handleTabChange.bind(_this, 'INACTIVE'), className: _PromotionDetail2.default.action + ' ' + (_this.state.active === 'INACTIVE' ? _PromotionDetail2.default.active : '') + '  ' + _PromotionDetail2.default.download },
            _react2.default.createElement(
              'div',
              { className: _PromotionDetail2.default.icon },
              _react2.default.createElement(md.inactive, { size: 25, color: '#FFF' })
            ),
            _react2.default.createElement(
              'span',
              { className: _PromotionDetail2.default.sub },
              (0, _translations2.default)('INACTIVED')
            )
          );
        }
      }

      if (data.status === 'ACTIVE' || data.status === 'INACTIVE') {
        if ((0, _sessionPermissions.sessionPermissions)('promotions:stop')) {
          actionstop = _react2.default.createElement(
            _reactRouter.Link,
            { onClick: _this.handleTabChange.bind(_this, 'STOP'), className: _PromotionDetail2.default.action + ' ' + (_this.state.active === 'STOP' ? _PromotionDetail2.default.active : '') + '  ' + _PromotionDetail2.default.strop },
            _react2.default.createElement(
              'div',
              { className: _PromotionDetail2.default.icon },
              _react2.default.createElement(md.stop, { size: 25 })
            ),
            _react2.default.createElement(
              'span',
              { className: _PromotionDetail2.default.sub },
              (0, _translations2.default)('STOP')
            )
          );
        }
      }

      if (data.status === 'NEW' || data.status === 'INACTIVE') {
        if ((0, _sessionPermissions.sessionPermissions)('promotions:start')) {
          actionActive = _react2.default.createElement(
            _reactRouter.Link,
            { onClick: _this.handleTabChange.bind(_this, 'ACTIVE'), className: _PromotionDetail2.default.action + ' ' + (_this.state.active === 'ACTIVE' ? _PromotionDetail2.default.active : '') + ' ' + _PromotionDetail2.default.duplicate },
            _react2.default.createElement(
              'div',
              { className: _PromotionDetail2.default.icon },
              _react2.default.createElement(md.active, { size: 25, color: '#FFF' })
            ),
            _react2.default.createElement(
              'span',
              { className: _PromotionDetail2.default.sub },
              (0, _translations2.default)('ACTIVED')
            )
          );
        }
      }

      if (data.status === 'NEW') {
        if ((0, _sessionPermissions.sessionPermissions)('promotions:delete')) {
          actionDelete = _react2.default.createElement(
            _reactRouter.Link,
            { onClick: _this.handleTabChange.bind(_this, 'DELETED'), className: _PromotionDetail2.default.action + ' ' + (_this.state.active === 'DELETED' ? _PromotionDetail2.default.active : '') + '  ' + _PromotionDetail2.default.strop },
            _react2.default.createElement(
              'div',
              { className: _PromotionDetail2.default.icon },
              _react2.default.createElement(md.delete, { size: 25 })
            ),
            _react2.default.createElement(
              'span',
              { className: _PromotionDetail2.default.sub },
              (0, _translations2.default)('DELETED')
            )
          );
        }

        if ((0, _sessionPermissions.sessionPermissions)('promotions:edit')) {
          actionEdit = _react2.default.createElement(
            _reactRouter.Link,
            { to: '/promotion/edit/' + data._id, className: _PromotionDetail2.default.action + ' ' + (_this.state.active === 'EDIT' ? _PromotionDetail2.default.active : '') + '  ' + _PromotionDetail2.default.strop },
            _react2.default.createElement(
              'div',
              { className: _PromotionDetail2.default.icon },
              _react2.default.createElement(md.edit, { size: 25 })
            ),
            _react2.default.createElement(
              'span',
              { className: _PromotionDetail2.default.sub },
              (0, _translations2.default)('EDIT')
            )
          );
        }
      }

      if ((0, _sessionPermissions.sessionPermissions)('promotions:download:transactions')) {
        actionDownload = _react2.default.createElement(
          _reactRouter.Link,
          { onClick: _this.handleDownload.bind(_this), className: '' + _PromotionDetail2.default.action },
          _react2.default.createElement(
            'div',
            { className: _PromotionDetail2.default.icon },
            _react2.default.createElement(md.download, { size: 25 })
          ),
          _react2.default.createElement(
            'span',
            { className: _PromotionDetail2.default.sub },
            (0, _translations2.default)('DOWNLOAD')
          )
        );
      }

      return _react2.default.createElement(
        'div',
        { className: _PromotionDetail2.default.actions },
        actionActive,
        actionInActive,
        actionstop,
        actionDelete,
        actionEdit,
        actionDownload
      );
    };

    _this.statusTranslations = {
      'ACTIVE': (0, _translations2.default)('ACTIVE'),
      'INACTIVE': (0, _translations2.default)('INACTIVE'),
      'NEW': (0, _translations2.default)('NEW'),
      'STOP': (0, _translations2.default)('PROMOTION STOPPED'),
      'COMPLETE': (0, _translations2.default)('PROMOTION COMPLETE'),
      'DELETED': (0, _translations2.default)('DELETED')
    };
    if (_this.props.promotions.length === 0) _this.props.getPromotions();
    _this.state = {
      active: null,
      data: null,
      stats: null
    };
    return _this;
  }

  (0, _createClass3.default)(PromotionDetail, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      this.handleInformation();
    }
  }, {
    key: 'componentDidUpdate',
    value: function componentDidUpdate() {
      this.handleInformation();
    }
  }, {
    key: 'handleAction',
    value: function handleAction() {
      var _this2 = this;

      var params = { 'promotion_uid': this.state.data._id };
      var status = this.state.active;
      this.props.updatePromotionStatus(params, status).then(function () {
        _this2.props.push({
          pathname: '/success',
          state: {
            goTo: '/promotions/',
            message: (0, _translations2.default)(status + ' PROMOTION SUCCESS'),
            modal: true,
            returnTo: '/promotions/'
          }
        });
      });
    }
  }, {
    key: 'handleClose',
    value: function handleClose() {
      this.setState({
        active: null
      });
    }
  }, {
    key: 'handleTabChange',
    value: function handleTabChange(value, event) {
      this.setState({
        active: this.state.active === value ? null : value
      });
    }
  }, {
    key: 'handleDownload',
    value: function handleDownload() {
      var _this3 = this;

      var startDate = this.state.data.start_date;
      var event_id = this.props.params.promotion_uid;
      return this.props.sendTransactionReportLogRequest(startDate, { event_id: event_id }, _reports.reportTypes.PROMOTION).then(function () {
        _this3.props.push({
          pathname: '/success',
          state: {
            goTo: '/promotions/',
            message: (0, _translations2.default)('REPORT REQUESTED'),
            modal: true,
            returnTo: '/promotions/'
          }
        });
      }).catch(function (error) {
        _this3.props.push({
          pathname: '/fail',
          state: {
            goTo: '/promotions/',
            message: (0, _translations2.default)('DOWNLOAD FILE ERROR'),
            modal: true,
            returnTo: '/promotions/'
          }
        });
      });
    }
  }, {
    key: 'renderDialog',
    value: function renderDialog(message, label) {
      return _react2.default.createElement(
        'div',
        { className: label === 'ACTIVED' ? _PromotionDetail2.default.dialog + ' ' + _PromotionDetail2.default.start : _PromotionDetail2.default.dialog + ' ' + _PromotionDetail2.default.stop },
        _react2.default.createElement('img', { src: label === 'ACTIVED' ? "/static/share/start.svg" : "/static/share/stop.svg", className: _PromotionDetail2.default.img }),
        _react2.default.createElement(
          'div',
          { className: _PromotionDetail2.default.label },
          (0, _translations2.default)(message)
        ),
        _react2.default.createElement(
          _reactRouter.Link,
          { className: _PromotionDetail2.default.button, onClick: this.handleAction },
          (0, _translations2.default)(label)
        )
      );
    }
  }, {
    key: 'renderStats',
    value: function renderStats() {

      var countdown = { to: '' };
      var stats = { today_sent_messages: '', total_sent_messages: '' };

      var _state$data = this.state.data,
          status = _state$data.status,
          end_date = _state$data.end_date;


      if (status === 'ACTIVE') {
        countdown.to = end_date;
      }

      if (this.state.stats) {
        stats.today_sent_messages = this.state.stats.today_sent_messages;
        stats.total_sent_messages = this.state.stats.total_sent_messages;
      }

      return _react2.default.createElement(
        'div',
        { className: _PromotionDetail2.default.statsContainer },
        _react2.default.createElement(
          'div',
          { className: _PromotionDetail2.default.statsData },
          _react2.default.createElement(
            'h3',
            null,
            stats.total_sent_messages
          ),
          _react2.default.createElement(
            'span',
            null,
            '' + (0, _translations2.default)('TOTAL SENT MESSAGES')
          )
        ),
        _react2.default.createElement(
          'div',
          { className: _PromotionDetail2.default.statsData },
          _react2.default.createElement(
            'h3',
            null,
            stats.today_sent_messages
          ),
          _react2.default.createElement(
            'span',
            null,
            '' + (0, _translations2.default)('TODAY SENT MESSAGES')
          )
        ),
        _react2.default.createElement(
          'div',
          { className: _PromotionDetail2.default.statsData },
          _react2.default.createElement(_Countdown2.default, { date: countdown.to }),
          _react2.default.createElement(
            'span',
            null,
            '' + (0, _translations2.default)('TIME LEFT')
          )
        )
      );
    }
  }, {
    key: 'render',
    value: function render() {
      var hidden = { display: 'none' };
      var visible = { display: 'block' };
      var data = this.state.data;

      if (data) {
        return _react2.default.createElement(
          'div',
          { className: _PromotionDetail2.default.results },
          _react2.default.createElement(_reactHelmet2.default, { title: 'Detalle de la promoci\xF3n' }),
          _react2.default.createElement(
            'div',
            { className: _PromotionDetail2.default.bar },
            _react2.default.createElement(
              'div',
              { className: _PromotionDetail2.default.icon, onClick: this.props.router.goBack },
              _react2.default.createElement(md.arrowBack, { size: 20, color: '#FFF' })
            ),
            _react2.default.createElement(
              'div',
              { className: _PromotionDetail2.default.title },
              _react2.default.createElement(
                'h1',
                null,
                data ? data.name : 'Cargando'
              ),
              _react2.default.createElement(
                'h2',
                null,
                data ? data.description : null
              )
            )
          ),
          _react2.default.createElement(
            'div',
            { className: _PromotionDetail2.default.main, style: data ? visible : hidden },
            this.renderStats(),
            _react2.default.createElement('hr', null),
            this.state.active && _react2.default.createElement(
              'div',
              { className: _PromotionDetail2.default.close, onClick: this.handleClose },
              '\xD7'
            ),
            this.renderActions(),
            this.state.active === 'ACTIVE' && this.renderDialog('ACTIVE PROMOTION', 'ACTIVED'),
            this.state.active === 'INACTIVE' && this.renderDialog('INACTIVE PROMOTION', 'INACTIVED'),
            this.state.active === 'STOP' && this.renderDialog('STOP PROMOTION', 'STOP'),
            this.state.active === 'DELETED' && this.renderDialog('DELETE PROMOTION', 'DELETED'),
            _react2.default.createElement(
              'div',
              { className: _PromotionDetail2.default.kpis },
              _react2.default.createElement(
                'div',
                { className: _PromotionDetail2.default.panel },
                _react2.default.createElement(
                  'div',
                  { className: _PromotionDetail2.default.kpi },
                  _react2.default.createElement(
                    'div',
                    { className: _PromotionDetail2.default.title },
                    (0, _translations2.default)('KEYWORD PROMOTION') + ': '
                  ),
                  _react2.default.createElement(
                    'div',
                    { className: _PromotionDetail2.default.value },
                    data.keyword
                  )
                ),
                _react2.default.createElement(
                  'div',
                  { className: _PromotionDetail2.default.kpi },
                  _react2.default.createElement(
                    'div',
                    { className: _PromotionDetail2.default.title },
                    (0, _translations2.default)('ACTION TEXT PROMOTION') + ': '
                  ),
                  _react2.default.createElement(
                    'div',
                    { className: _PromotionDetail2.default.value },
                    data.action.text
                  )
                )
              ),
              _react2.default.createElement(
                'div',
                { className: _PromotionDetail2.default.panel },
                _react2.default.createElement(
                  'div',
                  { className: _PromotionDetail2.default.kpi },
                  _react2.default.createElement(
                    'div',
                    { className: _PromotionDetail2.default.title },
                    (0, _translations2.default)('START DATE') + ': '
                  ),
                  _react2.default.createElement(
                    'div',
                    { className: _PromotionDetail2.default.value },
                    (0, _moment2.default)(data.start_date).format('YYYY-MM-DD HH:mm A')
                  )
                ),
                _react2.default.createElement(
                  'div',
                  { className: _PromotionDetail2.default.kpi },
                  _react2.default.createElement(
                    'div',
                    { className: _PromotionDetail2.default.title },
                    (0, _translations2.default)('END DATE') + ': '
                  ),
                  _react2.default.createElement(
                    'div',
                    { className: _PromotionDetail2.default.value },
                    (0, _moment2.default)(data.end_date).format('YYYY-MM-DD HH:mm A')
                  )
                ),
                _react2.default.createElement(
                  'div',
                  { className: _PromotionDetail2.default.kpi },
                  _react2.default.createElement(
                    'div',
                    { className: _PromotionDetail2.default.label + ' ' + _PromotionDetail2.default[data.status] },
                    this.statusTranslations[data.status]
                  )
                )
              )
            ),
            _react2.default.createElement('hr', null),
            _react2.default.createElement(
              'div',
              { className: _PromotionDetail2.default.detail },
              'Creado por ' + data.created_by + ' el ' + (0, _moment2.default)(data.created_on).format('MM/DD/YYYY HH:MM')
            )
          )
        );
      }
      return _react2.default.createElement('div', null);
    }
  }]);
  return PromotionDetail;
}(_react2.default.Component), _class3.propTypes = {
  promotions: _propTypes2.default.array.isRequired,
  updatePromotionStatus: _propTypes2.default.func.isRequired
}, _temp), (_applyDecoratedDescriptor(_class2.prototype, 'handleAction', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'handleAction'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, 'handleClose', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'handleClose'), _class2.prototype)), _class2)) || _class);
exports.default = PromotionDetail;
module.exports = exports['default'];