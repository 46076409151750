'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _getOwnPropertyDescriptor = require('babel-runtime/core-js/object/get-own-property-descriptor');

var _getOwnPropertyDescriptor2 = _interopRequireDefault(_getOwnPropertyDescriptor);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _class, _desc, _value, _class2;

var _components = require('components');

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _coreDecorators = require('core-decorators');

var _reactRedux = require('react-redux');

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _reachHistory = require('../../helpers/reachHistory');

var _Confirmation = require('./Confirmation.scss');

var _Confirmation2 = _interopRequireDefault(_Confirmation);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
  var desc = {};
  Object['ke' + 'ys'](descriptor).forEach(function (key) {
    desc[key] = descriptor[key];
  });
  desc.enumerable = !!desc.enumerable;
  desc.configurable = !!desc.configurable;

  if ('value' in desc || desc.initializer) {
    desc.writable = true;
  }

  desc = decorators.slice().reverse().reduce(function (desc, decorator) {
    return decorator(target, property, desc) || desc;
  }, desc);

  if (context && desc.initializer !== void 0) {
    desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
    desc.initializer = undefined;
  }

  if (desc.initializer === void 0) {
    Object['define' + 'Property'](target, property, desc);
    desc = null;
  }

  return desc;
}

var md = {
  arrowBack: require('react-icons/lib/md/arrow-back')
};
var Confirmation = (_dec = (0, _reactRedux.connect)(function (state) {
  return {};
}, {
  push: _reachHistory.push
}), _dec(_class = (_class2 = function (_React$Component) {
  (0, _inherits3.default)(Confirmation, _React$Component);

  function Confirmation(props) {
    (0, _classCallCheck3.default)(this, Confirmation);

    var _this = (0, _possibleConstructorReturn3.default)(this, (Confirmation.__proto__ || (0, _getPrototypeOf2.default)(Confirmation)).call(this, props));

    if (_this.props.data) {
      _this.state = _this.props.data;
    }
    _this.state = {
      deleteMessage: ""
    };
    return _this;
  }

  (0, _createClass3.default)(Confirmation, [{
    key: 'componentDidMount',
    value: function componentDidMount() {}
  }, {
    key: 'handleConfirmationChange',
    value: function handleConfirmationChange(e) {
      this.setState({ name: e.target.value });
    }
  }, {
    key: 'renderDetails',
    value: function renderDetails() {
      var date = (0, _moment2.default)(this.props.location.state.date).locale('es').format('MMMM Do YYYY, h:mm a');

      if (this.state != undefined) {
        return _react2.default.createElement(
          'div',
          { className: _Confirmation2.default.eventDetail },
          _react2.default.createElement(
            'div',
            { className: _Confirmation2.default.messageDetail },
            _react2.default.createElement(
              'p',
              null,
              this.props.location.state.data
            )
          ),
          _react2.default.createElement(
            'div',
            { className: _Confirmation2.default.dateSubtitle },
            _react2.default.createElement(
              'p',
              null,
              date
            )
          )
        );
      }
      return "";
    }
  }, {
    key: 'deleteTaks',
    value: function deleteTaks() {
      var _this2 = this;

      this.props.location.state.deleteTaskHandler(this.props.location.state.taskId).then(function (info) {
        if (info.name != undefined) {
          info = (0, _translations2.default)("DELETED TASK") + " " + info.name;
        } else {
          info = (0, _translations2.default)("ERROR DETAIL") + " " + info.error;
        }
        _this2.setState({
          deleteMessage: info
        });
      });
    }
  }, {
    key: 'render',
    value: function render() {
      var _this3 = this;

      return _react2.default.createElement(
        _react2.default.Fragment,
        null,
        _react2.default.createElement(
          'div',
          { className: _Confirmation2.default.bar },
          _react2.default.createElement(
            'div',
            { className: _Confirmation2.default.icon, onClick: this.props.router.goBack },
            _react2.default.createElement(md.arrowBack, { size: 20, color: '#FFF' })
          )
        ),
        _react2.default.createElement(
          'div',
          { className: _Confirmation2.default.confirmationContainer },
          _react2.default.createElement(
            'div',
            { className: _Confirmation2.default.messageTitle },
            (0, _translations2.default)('MESSAGE_CONFIRMATION')
          ),
          this.renderDetails(),
          _react2.default.createElement(_components.Button, {
            name: (0, _translations2.default)('DELETE TASK'),
            className: _Confirmation2.default.readyButton,
            onClick: function onClick(e) {
              return _this3.deleteTaks();
            }
          }),
          _react2.default.createElement(
            'div',
            { className: _Confirmation2.default.deleteConfirmation },
            _react2.default.createElement(
              'p',
              null,
              this.state.deleteMessage
            )
          )
        )
      );
    }
  }]);
  return Confirmation;
}(_react2.default.Component), (_applyDecoratedDescriptor(_class2.prototype, 'handleConfirmationChange', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'handleConfirmationChange'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, 'renderDetails', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'renderDetails'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, 'deleteTaks', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'deleteTaks'), _class2.prototype)), _class2)) || _class);
exports.default = Confirmation;
module.exports = exports['default'];