'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _getOwnPropertyDescriptor = require('babel-runtime/core-js/object/get-own-property-descriptor');

var _getOwnPropertyDescriptor2 = _interopRequireDefault(_getOwnPropertyDescriptor);

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _desc, _value, _class, _class2, _temp;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactHelmet = require('react-helmet');

var _reactHelmet2 = _interopRequireDefault(_reactHelmet);

var _coreDecorators = require('core-decorators');

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _components = require('components');

var _List = require('./List.scss');

var _List2 = _interopRequireDefault(_List);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
  var desc = {};
  Object['ke' + 'ys'](descriptor).forEach(function (key) {
    desc[key] = descriptor[key];
  });
  desc.enumerable = !!desc.enumerable;
  desc.configurable = !!desc.configurable;

  if ('value' in desc || desc.initializer) {
    desc.writable = true;
  }

  desc = decorators.slice().reverse().reduce(function (desc, decorator) {
    return decorator(target, property, desc) || desc;
  }, desc);

  if (context && desc.initializer !== void 0) {
    desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
    desc.initializer = undefined;
  }

  if (desc.initializer === void 0) {
    Object['define' + 'Property'](target, property, desc);
    desc = null;
  }

  return desc;
}

var ReportList = (_class = (_temp = _class2 = function (_React$Component) {
  (0, _inherits3.default)(ReportList, _React$Component);

  function ReportList(props) {
    (0, _classCallCheck3.default)(this, ReportList);

    var _this = (0, _possibleConstructorReturn3.default)(this, (ReportList.__proto__ || (0, _getPrototypeOf2.default)(ReportList)).call(this, props));

    var today = new Date();

    _this.state = (0, _extends3.default)({
      filtering: true
    }, _this.getMonthDateRange(today));
    return _this;
  }

  (0, _createClass3.default)(ReportList, [{
    key: 'getMonthDateRange',
    value: function getMonthDateRange(today) {
      var startDate = (0, _moment2.default)(today).startOf('month');
      var endDate = (0, _moment2.default)(startDate).endOf('month');
      return { startDate: startDate, endDate: endDate };
    }
  }, {
    key: 'onDateChange',
    value: function onDateChange() {
      console.error('not implemented');
    }
  }, {
    key: 'handleFilterToggle',
    value: function handleFilterToggle() {
      this.setState((0, _extends3.default)({}, this.state, {
        filtering: !this.state.filtering
      }));
    }
  }, {
    key: 'handleDateChange',
    value: function handleDateChange(startDate, endDate) {
      if (startDate && endDate) {
        this.setState((0, _extends3.default)({}, this.state, {
          startDate: startDate,
          endDate: endDate
        }), this.onDateChange);
      } else if (startDate) {
        this.setState((0, _extends3.default)({}, this.state, {
          startDate: startDate
        }), this.onDateChange);
      } else if (endDate) {
        this.setState((0, _extends3.default)({}, this.state, {
          endDate: endDate
        }), this.onDateChange);
      }
    }
  }, {
    key: 'renderNav',
    value: function renderNav() {
      return null;
    }
  }, {
    key: 'renderTabs',
    value: function renderTabs() {
      return null;
    }
  }, {
    key: 'renderInfiniteList',
    value: function renderInfiniteList() {
      return null;
    }
  }, {
    key: 'renderFilter',
    value: function renderFilter() {
      return _react2.default.createElement(_components.ReportFilter, { type: 'FILTER', startDate: this.state.startDate, endDate: this.state.endDate, onDateChange: this.handleDateChange });
    }
  }, {
    key: 'render',
    value: function render() {
      return _react2.default.createElement(
        'div',
        { className: _List2.default.reportCotainer },
        _react2.default.createElement(_reactHelmet2.default, { title: (0, _translations2.default)('REPORTS') }),
        this.renderNav(),
        this.renderFilter(),
        this.renderContent()
      );
    }
  }]);
  return ReportList;
}(_react2.default.Component), _class2.propTypes = {
  mobileMode: _propTypes2.default.bool
}, _class2.defaultProps = {
  mobileMode: false
}, _temp), (_applyDecoratedDescriptor(_class.prototype, 'handleFilterToggle', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class.prototype, 'handleFilterToggle'), _class.prototype), _applyDecoratedDescriptor(_class.prototype, 'handleDateChange', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class.prototype, 'handleDateChange'), _class.prototype)), _class);
exports.default = ReportList;
module.exports = exports['default'];