'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = undefined;

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _dec2, _class;

var _messages = require('redux/modules/messages');

var _auth = require('redux/modules/auth');

var _Allow = require('../../containers/Allow/Allow');

var _Allow2 = _interopRequireDefault(_Allow);

var _components = require('components');

var _reactHelmet = require('react-helmet');

var _reactHelmet2 = _interopRequireDefault(_reactHelmet);

var _reactRouter = require('react-router');

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _reduxConnect = require('redux-connect');

var _reactRedux = require('react-redux');

var _account = require('redux/modules/account');

var _reachHistory = require('../../helpers/reachHistory');

var _NewMessageTemplate = require('./NewMessageTemplate.scss');

var _NewMessageTemplate2 = _interopRequireDefault(_NewMessageTemplate);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var NewMessageTemplate = (_dec = (0, _reduxConnect.asyncConnect)([{
    key: 'account',
    promise: function promise(_ref) {
        var _ref$store = _ref.store,
            dispatch = _ref$store.dispatch,
            getState = _ref$store.getState;

        var state = getState();
        if (!state.account.data.reach) {
            return dispatch((0, _account.getAccount)());
        }
        return null;
    }
}, {
    key: "auth",
    promise: function promise(_ref2) {
        var _ref2$store = _ref2.store,
            dispatch = _ref2$store.dispatch,
            getState = _ref2$store.getState;

        if (!(0, _auth.isLoaded)(getState())) {
            return dispatch((0, _auth.load)());
        }
        return null;
    }
}]), _dec2 = (0, _reactRedux.connect)(function (state) {
    return {
        account: state.auth.current_account,
        user: state.auth.data.user
    };
}, { getAccount: _account.getAccount, createTemplateSms: _messages.createTemplateSms, duplicateTemplate: _messages.duplicateTemplate, pushState: _reachHistory.push }), _dec(_class = _dec2(_class = function (_React$Component) {
    (0, _inherits3.default)(NewMessageTemplate, _React$Component);

    function NewMessageTemplate(props) {
        (0, _classCallCheck3.default)(this, NewMessageTemplate);

        var _this = (0, _possibleConstructorReturn3.default)(this, (NewMessageTemplate.__proto__ || (0, _getPrototypeOf2.default)(NewMessageTemplate)).call(this, props));

        _this.props.templateInfo;
        _this.state = {
            duplicate: false,
            variable: '',
            variablesList: [],
            data: {
                name: null,
                account_uid: _this.props.account.account_uid,
                properties: {
                    content: '',
                    variables: []
                },
                "created_by": _this.props.user.username || null,
                "status": "INACTIVE",
                type: 'SMS'

            }

        };
        return _this;
    }

    (0, _createClass3.default)(NewMessageTemplate, [{
        key: 'componentDidMount',
        value: function componentDidMount() {
            if (this.props.location.pathname == '/messages/templates/duplicate') {
                try {
                    var _duplicateTemplate = this.props.location.state.duplicateTemplateData;
                    if (_duplicateTemplate) {
                        this.setState({ duplicate: true });
                        var nameTemplate = _duplicateTemplate.name;
                        var propertiesTemplate = _duplicateTemplate.properties;
                        var variables = _duplicateTemplate.properties.variables;
                        this.setState({
                            variablesList: variables,
                            data: { name: nameTemplate,
                                properties: propertiesTemplate,
                                account_uid: this.props.account.account_uid,
                                "created_by": "usuario@correo.com",
                                "status": "INACTIVE",
                                type: 'SMS'
                            }
                        });
                    }
                } catch (e) {
                    console.log('error');
                }
            }
        }
    }, {
        key: 'handleName',
        value: function handleName(event) {
            var data = this.state.data;
            data['name'] = event.target.value;
            this.setState({ data: data });
        }
    }, {
        key: 'handleDescription',
        value: function handleDescription(event) {
            var data = this.state.data;
            data['description'] = event.target.value;
            this.setState({ data: data });
        }
    }, {
        key: 'handleContent',
        value: function handleContent(event) {
            var data = this.state.data;
            data.properties['content'] = event.target.value;
            this.setState({ data: data });
        }
    }, {
        key: 'newVariable',
        value: function newVariable(event) {
            var variable = event.target.value;
            this.setState({ variable: variable });
        }
    }, {
        key: 'addVariable',
        value: function addVariable(variable) {
            var correctedVariable = variable.replace(/[^a-zA-Z0-9]/gi, '_');
            if (this.state.variable != '') {
                this.state.data.properties.variables.push(correctedVariable);
                this.setState({ variable: '' });
                this.state.variablesList.push(correctedVariable);
            } else {
                console.log('ingrese una variable');
            }
        }
    }, {
        key: 'onSubmit',
        value: function onSubmit() {
            var _this2 = this;

            var data = this.state.data;

            if (data.name != null && data.account_uid != null && data.properties.content != '') {
                if (this.state.duplicate) {

                    this.props.duplicateTemplate(data).then(function () {
                        _this2.props.pushState({
                            pathname: '/success',
                            state: {
                                goTo: '/messages/templates',
                                message: (0, _translations2.default)('DUPLICATED TEMPLATE'),
                                goToModal: false,
                                returnTo: '/messages/templates'
                            }
                        });
                    }).catch(function (e) {
                        console.error('error', e);
                    });
                } else {

                    this.props.createTemplateSms(data).then(function () {
                        _this2.props.pushState({
                            pathname: '/success',
                            state: {
                                goTo: '/messages/templates',
                                message: (0, _translations2.default)('CREATED TEMPLATE'),
                                goToModal: false,
                                returnTo: '/messages/templates'
                            }
                        });
                    }).catch(function (e) {
                        console.error('error', e);
                    });
                }
            } else {
                console.log('complete todos los datos para continuar', data);
            }
        }
    }, {
        key: 'addVariableToContent',
        value: function addVariableToContent(variable) {
            var newContent = this.state.data.properties.content + '{' + variable + '}';
            var data = this.state.data;
            data.properties['content'] = newContent;
            this.setState({ data: data });
        }
    }, {
        key: 'renderTableData',
        value: function renderTableData() {
            var _this3 = this;

            return this.state.variablesList.map(function (variable) {
                if (_this3.state.variablesList != [null]) {
                    return _react2.default.createElement(
                        'tr',
                        { className: _NewMessageTemplate2.default.trTable },
                        _react2.default.createElement(
                            _components.Button,
                            { onClick: function onClick() {
                                    return _this3.addVariableToContent(variable);
                                }, className: _NewMessageTemplate2.default.variableButton, name: variable },
                            variable
                        )
                    );
                } else {
                    return null;
                }
            });
        }
    }, {
        key: 'render',
        value: function render() {
            var _this4 = this;

            var variable = this.state.variable;
            var lenght = this.state.data.properties.content.length;

            return _react2.default.createElement(
                'div',
                { className: _NewMessageTemplate2.default.main },
                _react2.default.createElement(_reactHelmet2.default, { title: (0, _translations2.default)('NEW TEMPLATE SMS') }),
                _react2.default.createElement(
                    'div',
                    { className: _NewMessageTemplate2.default.body },
                    _react2.default.createElement(
                        'div',
                        { className: _NewMessageTemplate2.default.info },
                        _react2.default.createElement(
                            'div',
                            { className: _NewMessageTemplate2.default.name },
                            _react2.default.createElement(
                                'div',
                                { className: _NewMessageTemplate2.default.title },
                                (0, _translations2.default)('TEMPLATE NAME')
                            ),
                            _react2.default.createElement('input', { className: _NewMessageTemplate2.default.textarea,
                                value: this.state.data.name,
                                onChange: function onChange(e) {
                                    return _this4.handleName(e);
                                }
                            })
                        )
                    ),
                    _react2.default.createElement(
                        'div',
                        { className: _NewMessageTemplate2.default.content },
                        _react2.default.createElement(
                            'div',
                            { className: _NewMessageTemplate2.default.variables },
                            _react2.default.createElement(
                                'div',
                                { className: _NewMessageTemplate2.default.variablesAdd },
                                _react2.default.createElement(
                                    'div',
                                    { className: _NewMessageTemplate2.default.title },
                                    'Variables'
                                ),
                                _react2.default.createElement(
                                    'div',
                                    { className: _NewMessageTemplate2.default.add },
                                    _react2.default.createElement('input', { className: _NewMessageTemplate2.default.variableInput,
                                        value: variable,
                                        onChange: function onChange(value) {
                                            return _this4.newVariable(value);
                                        } }),
                                    _react2.default.createElement(_components.Button, { name: '+',
                                        className: _NewMessageTemplate2.default.addButton,
                                        onClick: function onClick() {
                                            return _this4.addVariable(_this4.state.variable);
                                        } })
                                )
                            ),
                            _react2.default.createElement(
                                'div',
                                { className: _NewMessageTemplate2.default.variablesTable },
                                _react2.default.createElement(
                                    'div',
                                    { className: _NewMessageTemplate2.default.tableCont },
                                    _react2.default.createElement(
                                        'table',
                                        { className: _NewMessageTemplate2.default.table },
                                        _react2.default.createElement(
                                            'tbody',
                                            { className: _NewMessageTemplate2.default.TBody },
                                            this.renderTableData()
                                        )
                                    )
                                )
                            ),
                            _react2.default.createElement(
                                'div',
                                { className: _NewMessageTemplate2.default.send },
                                _react2.default.createElement(
                                    _reactRouter.Link,
                                    { to: '/messages/templates' },
                                    _react2.default.createElement(_components.Button, { name: (0, _translations2.default)('RETURN'),
                                        className: _NewMessageTemplate2.default.buttonSend
                                    })
                                ),
                                _react2.default.createElement(
                                    _Allow2.default,
                                    { permissions: ['channels:sms:template:create'] },
                                    _react2.default.createElement(_components.Button, { name: (0, _translations2.default)('SAVE'),
                                        className: _NewMessageTemplate2.default.buttonSave,
                                        onClick: function onClick() {
                                            return _this4.onSubmit();
                                        }
                                    })
                                )
                            )
                        ),
                        _react2.default.createElement(
                            'div',
                            { className: _NewMessageTemplate2.default.textContent },
                            _react2.default.createElement(
                                'div',
                                { className: _NewMessageTemplate2.default.title },
                                ' ',
                                (0, _translations2.default)('MESSAGE'),
                                ' '
                            ),
                            _react2.default.createElement('textarea', { className: _NewMessageTemplate2.default.textContentArea,
                                value: this.state.data.properties.content,
                                onChange: function onChange(e) {
                                    return _this4.handleContent(e);
                                }
                            }),
                            _react2.default.createElement(
                                'div',
                                { className: _NewMessageTemplate2.default.disclamer },
                                _react2.default.createElement(
                                    'div',
                                    null,
                                    (0, _translations2.default)('CHARACTERS'),
                                    ': ',
                                    lenght,
                                    ' '
                                ),
                                _react2.default.createElement(
                                    'div',
                                    { className: _NewMessageTemplate2.default.disclamerText },
                                    (0, _translations2.default)('CHARACTERS MAY VARY DEPENDING ON VARIABLES')
                                )
                            )
                        )
                    )
                )
            );
        }
    }]);
    return NewMessageTemplate;
}(_react2.default.Component)) || _class) || _class);
exports.default = NewMessageTemplate;
module.exports = exports['default'];