'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _class, _temp;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _reactDatetime = require('react-datetime');

var _reactDatetime2 = _interopRequireDefault(_reactDatetime);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var MultiDatepicker = (_temp = _class = function (_React$Component) {
  (0, _inherits3.default)(MultiDatepicker, _React$Component);

  function MultiDatepicker(props) {
    (0, _classCallCheck3.default)(this, MultiDatepicker);

    var _this = (0, _possibleConstructorReturn3.default)(this, (MultiDatepicker.__proto__ || (0, _getPrototypeOf2.default)(MultiDatepicker)).call(this, props));

    _this.styles = require('./Datepicker.scss');
    _this.state = {
      datetime: (0, _moment2.default)()
    };
    return _this;
  }

  (0, _createClass3.default)(MultiDatepicker, [{
    key: 'handleChange',
    value: function handleChange(date) {
      this.setState((0, _extends3.default)({}, this.state, {
        datetime: date
        /*
        date: date.format('YYYY-MM-DD'),
        time: date.format('hh:mm A')
        */
      }));
      this.props.onChange(date);
    }
  }, {
    key: 'getIndex',
    value: function getIndex(index) {
      if (index < 10 && index >= 0) {
        return index;
      }
      var i = index % 10;
      return i;
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      var styles = this.styles;
      var selectedDates = this.props.selectedDates;
      var colors = this.props.colors;
      var len = selectedDates.length;
      var currentSelectedDate = this.props.currentDate;
      var render = {
        renderDay: function renderDay(props, currentDate, selectedDate) {
          var i = selectedDates.indexOf(currentDate.format('YYYY-MM-DD'));
          var index = i === -1 ? _this2.getIndex(len) : _this2.getIndex(i);
          var selected = selectedDate.format('YYYY-MM-DD');
          var current = currentDate.format('YYYY-MM-DD');
          var date = current === currentSelectedDate ? true : false;
          var color = colors[index];

          if (i > -1 || date) {
            return _react2.default.createElement(
              'td',
              (0, _extends3.default)({}, props, { style: { background: '' + color, borderRadius: '4px', color: '#FFF' } }),
              ' ',
              currentDate.date()
            );
          } else {
            return _react2.default.createElement(
              'td',
              props,
              ' ',
              currentDate.date()
            );
          }
        } };

      return _react2.default.createElement(
        'div',
        { className: styles.wrapper },
        !this.props.compact ? _react2.default.createElement(
          'div',
          { className: styles.title },
          _react2.default.createElement(
            'div',
            { className: styles.super },
            this.state.datetime.format('YYYY-MM-DD')
          ),
          _react2.default.createElement(
            'div',
            { className: styles.main },
            this.state.datetime.format('hh:mm A')
          )
        ) : null,
        _react2.default.createElement(_reactDatetime2.default, {
          className: styles.datepicker + ' ' + (this.props.compact ? styles.compact : ''),
          input: false,
          value: this.state.datetime,
          onChange: this.handleChange.bind(this),
          selectedDates: this.props.selectedDates,
          colors: this.props.colors,
          dateFormat: "YYYY-MM-DD",
          renderDay: render.renderDay,
          timeFormat: this.props.timeFormat,
          isValidDate: function isValidDate(current) {
            if (_this2.props.isAfter) {
              return current.isAfter(_reactDatetime2.default.moment().subtract(1, 'day'));
            } else {
              return current.isBefore(_reactDatetime2.default.moment().endOf('day'));
            }
          }
        })
      );
    }
  }]);
  return MultiDatepicker;
}(_react2.default.Component), _class.propTypes = {
  onChange: _propTypes2.default.func.isRequired,
  compact: _propTypes2.default.bool,
  dates: _propTypes2.default.array,
  renderDay: _propTypes2.default.func,
  selectedDates: _propTypes2.default.array,
  colors: _propTypes2.default.array,
  currentDate: _propTypes2.default.string
}, _class.defaultProps = {
  timeFormat: true,
  isAfter: true
}, _temp);
exports.default = MultiDatepicker;
module.exports = exports['default'];