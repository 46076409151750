'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.PolicyTabs = undefined;

var _getOwnPropertyDescriptor = require('babel-runtime/core-js/object/get-own-property-descriptor');

var _getOwnPropertyDescriptor2 = _interopRequireDefault(_getOwnPropertyDescriptor);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _class, _desc, _value, _class2, _class3, _temp;

var _components = require('components');

var _policy = require('redux/modules/policy');

var _Allow = require('../Allow/Allow');

var _Allow2 = _interopRequireDefault(_Allow);

var _reactRouter = require('react-router');

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _coreDecorators = require('core-decorators');

var _reactRedux = require('react-redux');

var _themeEnabled = require('helpers/themeEnabled');

var _mobile = require('redux/modules/mobile');

var _reachHistory = require('../../helpers/reachHistory');

var _sessionPermissions = require('../../helpers/sessionPermissions');

var _Reseller = require('./Reseller.scss');

var _Reseller2 = _interopRequireDefault(_Reseller);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
  var desc = {};
  Object['ke' + 'ys'](descriptor).forEach(function (key) {
    desc[key] = descriptor[key];
  });
  desc.enumerable = !!desc.enumerable;
  desc.configurable = !!desc.configurable;

  if ('value' in desc || desc.initializer) {
    desc.writable = true;
  }

  desc = decorators.slice().reverse().reduce(function (desc, decorator) {
    return decorator(target, property, desc) || desc;
  }, desc);

  if (context && desc.initializer !== void 0) {
    desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
    desc.initializer = undefined;
  }

  if (desc.initializer === void 0) {
    Object['define' + 'Property'](target, property, desc);
    desc = null;
  }

  return desc;
}

var md = {
  search: require('react-icons/lib/md/search'),
  person: require('react-icons/lib/md/person'),
  personAdd: require('react-icons/lib/md/person-add'),
  message: require('react-icons/lib/md/email'),
  delete: require('react-icons/lib/md/delete'),
  tag: require('react-icons/lib/md/label'),
  upload: require('react-icons/lib/md/file-upload'),
  moreVert: require('react-icons/lib/md/more-vert'),
  gesture: require('react-icons/lib/md/gesture'),
  inbox: require('react-icons/lib/md/inbox')
};

var PolicyTabs = exports.PolicyTabs = function PolicyTabs() {
  return _react2.default.createElement(_components.Tabs, { simple: true, alt: true,
    options: [{
      'href': '/reseller/policies/',
      'title': (0, _translations2.default)('MIS POLITICAS'),
      'is_index': true
    }]
  });
};

var PolicyList = (_dec = (0, _reactRedux.connect)(function (state) {
  return {
    mobileMode: state.mobile.mobileMode,
    selected: state.subaccounts.selected,
    list: state.policy.list,
    isLoading: state.subaccounts.loading
  };
}, {
  get: _policy.getPolicies,
  select: _policy.policiesSelect,
  unselect: _policy.policiesUnselect,
  refresh: _policy.policiesRefresh,
  policiesUnselectAll: _policy.policiesUnselectAll,
  deletePolicy: _policy.deletePolicy,
  modal: _mobile.modal,
  push: _reachHistory.push
}), _dec(_class = (_class2 = (_temp = _class3 = function (_AdminList) {
  (0, _inherits3.default)(PolicyList, _AdminList);

  function PolicyList(props) {
    (0, _classCallCheck3.default)(this, PolicyList);

    var _this = (0, _possibleConstructorReturn3.default)(this, (PolicyList.__proto__ || (0, _getPrototypeOf2.default)(PolicyList)).call(this, props));

    _this.title = (0, _translations2.default)('POLICIES');
    return _this;
  }

  (0, _createClass3.default)(PolicyList, [{
    key: 'deleteSelected',
    value: function deleteSelected() {
      var _this2 = this;

      var ids = this.props.selected.map(function (pos) {
        return _this2.props.list[pos].id;
      });
      this.props.deleteContacts(ids).then(function () {
        _this2.beginSearch('');
      });
    }
  }, {
    key: 'sendSelected',
    value: function sendSelected() {
      var _this3 = this;

      this.props.push({
        pathname: '/contacts/addMessage',
        state: {
          modal: true,
          modalSize: { height: '500px', width: '575px' },
          returnTo: '/contacts/',
          contactsSelected: this.props.selected.map(function (index) {
            return _this3.props.list[index];
          })
        }
      });
    }
  }, {
    key: 'addTagsToSelected',
    value: function addTagsToSelected() {
      var _this4 = this;

      this.props.push({
        pathname: '/contacts/addTags',
        state: {
          modal: true,
          modalSize: { height: '500px', width: '830px' },
          returnTo: '/contacts/',
          // contactsSelected: this.props.list.filter((contact, index) => (this.props.selected.indexOf(index) > -1))
          contactsSelected: this.props.selected.map(function (index) {
            return _this4.props.list[index];
          })
        }
      });
    }
  }, {
    key: 'renderNav',
    value: function renderNav(stickyprops) {
      if (this.isSelecting()) {
        var countMessage = this.props.selected.length === 1 ? (0, _translations2.default)('CONTACT SELECTED') : (0, _translations2.default)('CONTACTS SELECTED');
        return _react2.default.createElement(
          _components.ContextNav,
          {
            key: 'ContextNav',
            selectedElementsCount: this.props.selected.length + '  ' + countMessage,
            unselect: this.props.contactsUnselectAll
          },
          _react2.default.createElement(
            _reactRouter.Link,
            { className: 'action left', onClick: this.sendSelected },
            (0, _translations2.default)('VIEW'),
            _react2.default.createElement(md.message, { size: 20 })
          ),
          _react2.default.createElement(
            _reactRouter.Link,
            { className: 'action left', onClick: this.addTagsToSelected },
            (0, _translations2.default)('EDITAR'),
            _react2.default.createElement(md.tag, { size: 20 })
          ),
          _react2.default.createElement(
            _reactRouter.Link,
            { className: 'action right', onClick: this.deleteSelected },
            (0, _translations2.default)('DELETE'),
            _react2.default.createElement(md.delete, { size: 20 })
          )
        );
      }

      var classesTheme = (0, _themeEnabled.getThemeEnabledStyle)(_Reseller2.default);

      return _react2.default.createElement(
        'div',
        { className: classesTheme },
        _react2.default.createElement(
          'div',
          { className: _Reseller2.default.containerTitle },
          _react2.default.createElement(
            _components.Nav,
            { key: 'Nav', title: (0, _translations2.default)('RESELLER') },
            _react2.default.createElement(
              _Allow2.default,
              { permissions: ['reseller:policies:antispam:create'] },
              _react2.default.createElement(
                _reactRouter.Link,
                {
                  className: _Reseller2.default.title,
                  to: {
                    pathname: '/reseller/policy/new',
                    state: { modal: true, returnTo: "/reseller", modalSize: { width: '700px', height: '400px' } }
                  } },
                (0, _translations2.default)('ADD POLICY'),
                _react2.default.createElement(md.inbox, { size: 20 })
              )
            )
          )
        )
      );
    }
  }, {
    key: 'renderTabs',
    value: function renderTabs() {
      return _react2.default.createElement(PolicyTabs, null);
    }
  }, {
    key: 'renderInfiniteList',
    value: function renderInfiniteList() {
      var _this5 = this;

      var hidden = { display: 'none' };
      var visible = { display: 'block' };
      var infiniteStyle = this.props.isLoading || this.props.list.length > 0 ? visible : hidden;
      var linkstyle = this.props.isLoading || this.props.list.length > 0 ? hidden : visible;

      return _react2.default.createElement(
        'div',
        null,
        _react2.default.createElement(
          'div',
          { style: infiniteStyle },
          _react2.default.createElement(
            _components.Infinite,
            {
              refresh: this.refreshRows,
              next: this.loadMoreRows,
              hasMore: this.state.hasMoreRows
            },
            this.props.list.map(function (item, index) {
              return _react2.default.createElement(
                _Allow2.default,
                { permissions: ['reseller:policies:antispam:list'] },
                _react2.default.createElement(_components.VirtualListItemPolicy, {
                  key: item._id,
                  index: index,
                  policy_name: item.policy_name,
                  policy_type: item.policy_type,
                  mobileMode: _this5.props.mobileMode,
                  active: item.active || false,
                  setActive: _this5.setActive.bind(_this5),
                  link: (0, _sessionPermissions.sessionPermissions)('reseller:policies:antispam:view') ? {
                    pathname: '/reseller/policy',
                    state: { modal: !_this5.props.mobileMode, returnTo: _this5.props.location.pathname, policy: item }
                  } : null,
                  selecting: _this5.isSelecting()
                })
              );
            })
          )
        ),
        _react2.default.createElement(_components.Linkholder, {
          style: linkstyle,
          to: {
            pathname: '/reseller/policy/edit',
            state: { modal: true, modalSize: { width: '412px', height: '600px' }, returnTo: '/reseller/policies' }
          },
          src: '/static/empty/contacts.svg',
          text: (0, _translations2.default)('NO POLICY FOUND'),
          button: (0, _translations2.default)('ADD A NEW POLICY')
        })
      );
    }
  }]);
  return PolicyList;
}(_components.AdminList), _class3.propTypes = {
  mobileMode: _propTypes2.default.bool,
  selected: _propTypes2.default.array.isRequired,
  list: _propTypes2.default.array.isRequired,
  isLoading: _propTypes2.default.bool.isRequired,
  get: _propTypes2.default.func.isRequired,
  select: _propTypes2.default.func.isRequired,
  unselect: _propTypes2.default.func.isRequired,
  refresh: _propTypes2.default.func.isRequired,
  policiesUnselectAll: _propTypes2.default.func.isRequired,
  deletePolicy: _propTypes2.default.func.isRequired,
  location: _propTypes2.default.object.isRequired,
  push: _propTypes2.default.func.isRequired
}, _temp), (_applyDecoratedDescriptor(_class2.prototype, 'deleteSelected', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'deleteSelected'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, 'sendSelected', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'sendSelected'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, 'addTagsToSelected', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'addTagsToSelected'), _class2.prototype)), _class2)) || _class);
exports.default = PolicyList;