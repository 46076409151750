'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _getOwnPropertyDescriptor = require('babel-runtime/core-js/object/get-own-property-descriptor');

var _getOwnPropertyDescriptor2 = _interopRequireDefault(_getOwnPropertyDescriptor);

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _extends3 = require('babel-runtime/helpers/extends');

var _extends4 = _interopRequireDefault(_extends3);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _class, _desc, _value, _class2;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _coreDecorators = require('core-decorators');

var _reactRedux = require('react-redux');

var _account = require('redux/modules/account');

var _reduxConnect = require('redux-connect');

var _reactColor = require('react-color');

var _reactDropzoneS3Uploader = require('react-dropzone-s3-uploader');

var _reactDropzoneS3Uploader2 = _interopRequireDefault(_reactDropzoneS3Uploader);

var _ApiClient = require('../../helpers/ApiClient');

var _ApiClient2 = _interopRequireDefault(_ApiClient);

var _AccountCallback = require('./AccountCallback.scss');

var _AccountCallback2 = _interopRequireDefault(_AccountCallback);

var _components = require('components');

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _sessionPermissions = require('../../helpers/sessionPermissions');

var _themeEnabled = require('helpers/themeEnabled');

var _Allow = require('../Allow/Allow');

var _Allow2 = _interopRequireDefault(_Allow);

var _useBasename = require('../../helpers/useBasename');

var _useBasename2 = _interopRequireDefault(_useBasename);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
  var desc = {};
  Object['ke' + 'ys'](descriptor).forEach(function (key) {
    desc[key] = descriptor[key];
  });
  desc.enumerable = !!desc.enumerable;
  desc.configurable = !!desc.configurable;

  if ('value' in desc || desc.initializer) {
    desc.writable = true;
  }

  desc = decorators.slice().reverse().reduce(function (desc, decorator) {
    return decorator(target, property, desc) || desc;
  }, desc);

  if (context && desc.initializer !== void 0) {
    desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
    desc.initializer = undefined;
  }

  if (desc.initializer === void 0) {
    Object['define' + 'Property'](target, property, desc);
    desc = null;
  }

  return desc;
}

var AccountCallback = (_dec = (0, _reactRedux.connect)(function (state) {
  return { account: state.account.data };
}, { updateAccount: _account.updateAccount }), _dec(_class = (_class2 = function (_React$Component) {
  (0, _inherits3.default)(AccountCallback, _React$Component);

  function AccountCallback(props) {
    (0, _classCallCheck3.default)(this, AccountCallback);

    var _this = (0, _possibleConstructorReturn3.default)(this, (AccountCallback.__proto__ || (0, _getPrototypeOf2.default)(AccountCallback)).call(this, props));

    var config = props.account.config || {};

    _this.state = {
      url: config.triggerCallback,
      hookOnQuestionnaire: config.triggerOnQuestionnaire || false,
      hookOnQuestion: config.triggerOnQuestion || false
    };
    return _this;
  }

  (0, _createClass3.default)(AccountCallback, [{
    key: 'handleSave',
    value: function handleSave() {
      var newAccount = (0, _extends4.default)({}, this.props.account, {
        config: {
          triggerCallback: this.state.url,
          triggerOnQuestionnaire: this.state.hookOnQuestionnaire,
          triggerOnQuestion: this.state.hookOnQuestion
        }
      });
      return this.props.updateAccount(newAccount);
    }
  }, {
    key: 'handleInputChange',
    value: function handleInputChange(event) {
      this.setState((0, _extends4.default)({}, this.state, {
        url: event.target.value
      }));
    }
  }, {
    key: 'handleCheckboxChange',
    value: function handleCheckboxChange(prop) {
      this.setState((0, _extends4.default)({}, this.state, (0, _defineProperty3.default)({}, prop, !this.state[prop])));
    }
  }, {
    key: 'render',
    value: function render() {
      var classesTheme = (0, _themeEnabled.getThemeEnabledStyle)(_AccountCallback2.default);
      return _react2.default.createElement(
        'div',
        { className: classesTheme },
        _react2.default.createElement(
          'div',
          { className: _AccountCallback2.default.callback },
          _react2.default.createElement(
            'div',
            { className: _AccountCallback2.default.userinput },
            _react2.default.createElement(
              'h1',
              { className: _AccountCallback2.default.bluetext },
              (0, _translations2.default)('CALLBACK URL')
            ),
            _react2.default.createElement('input', { readOnly: (0, _sessionPermissions.sessionPermissions)('account:configs:callback:edit') ? false : true,
              placeholder: 'ej. http://example.com/api',
              className: _AccountCallback2.default.input, type: 'text',
              value: this.state.url, onChange: this.handleInputChange }),
            _react2.default.createElement(
              'h1',
              { className: _AccountCallback2.default.bluetext },
              (0, _translations2.default)('I WANT TO BE NOTIFIED')
            ),
            _react2.default.createElement(
              'label',
              { className: _AccountCallback2.default.label },
              _react2.default.createElement('input', { disabled: (0, _sessionPermissions.sessionPermissions)('account:configs:callback:edit') ? false : true,
                type: 'checkbox', defaultChecked: this.state.hookOnQuestionnaire, onChange: this.handleCheckboxChange.bind(this, 'hookOnQuestionnaire') }),
              '\xA0',
              (0, _translations2.default)('COMPLETED QUESTIONNAIRE')
            ),
            _react2.default.createElement(
              'label',
              { className: _AccountCallback2.default.label },
              _react2.default.createElement('input', { disabled: (0, _sessionPermissions.sessionPermissions)('account:configs:callback:edit') ? false : true,
                type: 'checkbox', defaultChecked: this.state.hookOnQuestion, onChange: this.handleCheckboxChange.bind(this, 'hookOnQuestion') }),
              '\xA0',
              (0, _translations2.default)('SOMEONE ANSWERS A QUESTION')
            ),
            _react2.default.createElement(
              'div',
              { className: _AccountCallback2.default.buttons },
              _react2.default.createElement(
                'a',
                { target: '_blank', href: 'https://comunicador.tigo.com.gt/tutoriales/webhook.html', className: _AccountCallback2.default.button1 },
                (0, _translations2.default)('CONFIGURATION GUIDE')
              ),
              _react2.default.createElement(
                _Allow2.default,
                { permissions: ['account:configs:callback:edit'] },
                _react2.default.createElement(
                  'div',
                  { className: _AccountCallback2.default.button2, onClick: this.handleSave },
                  (0, _translations2.default)('SAVE')
                )
              )
            )
          ),
          _react2.default.createElement(
            'div',
            { className: _AccountCallback2.default.image },
            _react2.default.createElement('img', { src: (0, _useBasename2.default)('/static/callback2.svg'), alt: '' })
          )
        )
      );
    }
  }]);
  return AccountCallback;
}(_react2.default.Component), (_applyDecoratedDescriptor(_class2.prototype, 'handleSave', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'handleSave'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, 'handleInputChange', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'handleInputChange'), _class2.prototype)), _class2)) || _class);
exports.default = AccountCallback;
module.exports = exports['default'];