'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _class, _class2, _temp;

var _reactHelmet = require('react-helmet');

var _reactHelmet2 = _interopRequireDefault(_reactHelmet);

var _reactRouter = require('react-router');

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactDom = require('react-dom');

var _reactDom2 = _interopRequireDefault(_reactDom);

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _config = require('config');

var _config2 = _interopRequireDefault(_config);

var _reactRedux = require('react-redux');

var _reachHistory = require('../../helpers/reachHistory');

var _Login = require('./Login.scss');

var _Login2 = _interopRequireDefault(_Login);

var _auth = require('redux/modules/auth');

var _useBasename = require('../../helpers/useBasename');

var _useBasename2 = _interopRequireDefault(_useBasename);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var VerifyEmail = (_dec = (0, _reactRedux.connect)(function (state) {
  return { auth: state.auth, isMobile: state.mobile.isMobile };
}, {
  pushState: _reachHistory.push,
  verifyEmail: _auth.verifyAccountEmailToken
}), _dec(_class = (_temp = _class2 = function (_React$Component) {
  (0, _inherits3.default)(VerifyEmail, _React$Component);

  function VerifyEmail(props) {
    (0, _classCallCheck3.default)(this, VerifyEmail);

    var _this = (0, _possibleConstructorReturn3.default)(this, (VerifyEmail.__proto__ || (0, _getPrototypeOf2.default)(VerifyEmail)).call(this, props));

    _this.state = {
      loading: true,
      user: {}
    };
    return _this;
  }

  (0, _createClass3.default)(VerifyEmail, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      var _this2 = this;

      setTimeout(function () {
        _this2.props.verifyEmail(_this2.props.params.token).then(function (response) {
          window.location = '/';
        }).catch(function () {
          alert('Token expirado');
          window.location = '/';
        });
      }, 6000);
    }
  }, {
    key: 'renderForm',
    value: function renderForm() {
      return _react2.default.createElement(
        'div',
        { className: _Login2.default.emailFormWrapper },
        _react2.default.createElement(
          'div',
          { className: _Login2.default.emailForm },
          _react2.default.createElement(
            'p',
            { className: _Login2.default.title },
            (0, _translations2.default)('EMAIL VERIFY')
          ),
          _react2.default.createElement('img', { className: _Login2.default.svg, width: '250px', src: (0, _useBasename2.default)('/static/emailVerify.svg'), alt: '' }),
          _react2.default.createElement(
            'p',
            { className: _Login2.default.text },
            (0, _translations2.default)('EMAIL VERIFY TEXT')
          )
        )
      );
    }
  }, {
    key: 'render',
    value: function render() {
      return _react2.default.createElement(
        'div',
        {
          className: _Login2.default.login,
          style: {
            width: '100vw',
            height: '100vh'
          }
        },
        _react2.default.createElement(_reactHelmet2.default, { title: 'Email' }),
        this.renderForm()
      );
    }
  }]);
  return VerifyEmail;
}(_react2.default.Component), _class2.propTypes = {
  auth: _propTypes2.default.object,
  isMobile: _propTypes2.default.bool
}, _temp)) || _class);
exports.default = VerifyEmail;
module.exports = exports['default'];