'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.MessageTabs = undefined;

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _class;

var _components = require('components');

var _messages = require('redux/modules/messages');

var _reactRouter = require('react-router');

var _Messages2 = require('../../containers/Messages/Messages');

var _Messages3 = _interopRequireDefault(_Messages2);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _coreDecorators = require('core-decorators');

var _reactRedux = require('react-redux');

var _mobile = require('redux/modules/mobile');

var _reachHistory = require('../../helpers/reachHistory');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var md = {
  search: require('react-icons/lib/md/search'),
  message: require('react-icons/lib/md/email'),
  messageRead: require('react-icons/lib/md/drafts')
};

var MessageTabs = exports.MessageTabs = function MessageTabs(props) {
  return _react2.default.createElement(_components.Tabs, {
    simple: true,
    options: [{
      href: '/staff/messages/',
      title: (0, _translations2.default)('SENT'),
      is_index: true
    }]
  });
};

var StaffMessages = function (_Messages) {
  (0, _inherits3.default)(StaffMessages, _Messages);

  function StaffMessages(props) {
    (0, _classCallCheck3.default)(this, StaffMessages);

    var _this = (0, _possibleConstructorReturn3.default)(this, (StaffMessages.__proto__ || (0, _getPrototypeOf2.default)(StaffMessages)).call(this, props));

    _this.url = 'staff/messages';
    return _this;
  }

  (0, _createClass3.default)(StaffMessages, [{
    key: 'renderTabs',
    value: function renderTabs() {
      return _react2.default.createElement(MessageTabs, null);
    }
  }]);
  return StaffMessages;
}(_Messages3.default);

var StaffMessagesSent = (_dec = (0, _reactRedux.connect)(function (state) {
  return {
    mobileMode: state.mobile.mobileMode,
    selected: state.messagesSent.selected,
    list: state.messagesSent.list,
    isLoading: state.messagesSent.loading,
    auth: state.auth
  };
}, {
  get: _messages.getTransactions,
  select: _messages.messageSelect,
  unselect: _messages.messageUnselect,
  messagesUnselectAll: _messages.messagesUnselectAll,
  refresh: _messages.sentMessagesRefresh
}), _dec(_class = function (_StaffMessages) {
  (0, _inherits3.default)(StaffMessagesSent, _StaffMessages);

  function StaffMessagesSent(props) {
    (0, _classCallCheck3.default)(this, StaffMessagesSent);

    var _this2 = (0, _possibleConstructorReturn3.default)(this, (StaffMessagesSent.__proto__ || (0, _getPrototypeOf2.default)(StaffMessagesSent)).call(this, props));

    _this2.messageType = _messages.messageTypes.SENT;
    _this2.menuType = 'STAFF';
    return _this2;
  }

  return StaffMessagesSent;
}(StaffMessages)) || _class);
exports.default = StaffMessagesSent;