'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _class, _temp;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _Preview = require('./Preview.scss');

var _Preview2 = _interopRequireDefault(_Preview);

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _useBasename = require('../../helpers/useBasename');

var _useBasename2 = _interopRequireDefault(_useBasename);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Html = (_temp = _class = function (_React$Component) {
  (0, _inherits3.default)(Html, _React$Component);

  function Html() {
    (0, _classCallCheck3.default)(this, Html);
    return (0, _possibleConstructorReturn3.default)(this, (Html.__proto__ || (0, _getPrototypeOf2.default)(Html)).apply(this, arguments));
  }

  (0, _createClass3.default)(Html, [{
    key: 'render',
    value: function render() {
      return _react2.default.createElement(
        'span',
        { className: _Preview2.default.previewText + ' ' + this.props.className },
        this.props.text,
        _react2.default.createElement('img', { className: _Preview2.default.background, src: (0, _useBasename2.default)('/static/logo_reach.png'), alt: '' }),
        _react2.default.createElement('div', { className: _Preview2.default.preview, dangerouslySetInnerHTML: { __html: this.props.html } })
      );
    }
  }]);
  return Html;
}(_react2.default.Component), _class.propTypes = {
  text: _propTypes2.default.string,
  className: _propTypes2.default.string,
  html: _propTypes2.default.string.isRequired
}, _class.defaultProps = {
  text: (0, _translations2.default)('PREVIEW'),
  className: ""
}, _temp);
exports.default = Html;
module.exports = exports['default'];