'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actions = undefined;
exports.default = reducer;
exports.getTags = getTags;
exports.getTagsRefactor = getTagsRefactor;
exports.tagsSelect = tagsSelect;
exports.tagsUnselect = tagsUnselect;
exports.tagsUnselectAll = tagsUnselectAll;
exports.incrementTag = incrementTag;
exports.incrementTagFileCount = incrementTagFileCount;
exports.decrementTag = decrementTag;
exports.addTagToContact = addTagToContact;
exports.createTagsRefactor = createTagsRefactor;
exports.deleteTagFromContact = deleteTagFromContact;
exports.addTagListToContactList = addTagListToContactList;
exports.tagsRefresh = tagsRefresh;

var _apiAction = require('../apiAction');

var _apiAction2 = _interopRequireDefault(_apiAction);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// ACTIONS


var actions = exports.actions = new _apiAction2.default('TAGS', [_apiAction.ACTION_TYPES.DATA, _apiAction.ACTION_TYPES.APPEND, _apiAction.ACTION_TYPES.TRIM, _apiAction.ACTION_TYPES.SELECT]);

// REDUCER


var initialState = {
  loading: false,
  status: actions.APPEND,
  list: [],
  selected: [],
  data: {}
};

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  return actions.getStateForAction(state, action);
}

// ACTION CREATORS


function getTags(params) {
  return {
    types: [actions.APPEND, actions.APPEND_SUCCESS, actions.APPEND_FAIL],
    promise: function promise(client) {
      return client.get('/tags', {
        params: params
      });
    }
  };
}

function getTagsRefactor(params) {
  return {
    types: [actions.APPEND, actions.APPEND_SUCCESS, actions.APPEND_FAIL],
    promise: function promise(client) {
      return client.get('/tag?search=&enable=true', {
        params: params
      });
    }
  };
}

function tagsSelect(index) {
  return {
    type: actions.SELECT,
    index: index
  };
}

function tagsUnselect(index) {
  return {
    type: actions.SELECT_OFF,
    index: index
  };
}

function tagsUnselectAll() {
  return {
    type: actions.SELECT_NONE
  };
}

function incrementTag(tag, tagId) {
  return {
    types: [actions.APPEND, actions.APPEND_SUCCESS, actions.APPEND_FAIL],
    promise: function promise(client) {
      return client.put('/tag/' + tagId + '/inc/contact/count', {
        data: { name: tag }
      });
    }
  };
}

function incrementTagFileCount(tag, tagId) {
  return {
    types: [actions.APPEND, actions.APPEND_SUCCESS, actions.APPEND_FAIL],
    promise: function promise(client) {
      return client.put('/tag/' + tagId + '/inc/file/count', {
        data: { name: tag }
      });
    }
  };
}

function decrementTag(tag, tagId) {
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.put('/tag/' + tagId + '/dec/contact/count', { data: { name: tag } });
    }
  };
}

function addTagToContact(contactId, tag) {
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.post('/contacts/' + contactId + '/tags', { data: [tag] });
    }
  };
}

function createTagsRefactor(params) {
  return {
    types: [actions.APPEND, actions.APPEND_SUCCESS, actions.APPEND_FAIL],
    promise: function promise(client) {
      return client.post('/tag', {
        data: params
      });
    }
  };
}

function deleteTagFromContact(contactId, tag) {
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.del('/contacts/' + contactId + '/tags', { data: [tag] });
    }
  };
}

function addTagListToContactList(contactListIds, tagList) {
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.post('/contacts/tags', {
        data: {
          tags: tagList,
          contact_uids: contactListIds
        }
      });
    }
  };
}

function tagsRefresh() {
  return {
    type: actions.TRIM,
    count: 0
  };
}