'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Loading = require('./Loading.scss');

var _Loading2 = _interopRequireDefault(_Loading);

var _fileLoad = require('./file-load.svg');

var _fileLoad2 = _interopRequireDefault(_fileLoad);

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _useBasename = require('../../helpers/useBasename');

var _useBasename2 = _interopRequireDefault(_useBasename);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FileLoading = function FileLoading(params) {
  return _react2.default.createElement(
    'div',
    { className: _Loading2.default.spinnerContainer },
    _react2.default.createElement('img', { src: (0, _useBasename2.default)('/static/file-load.svg'), alt: '' }),
    _react2.default.createElement(
      'h2',
      { className: _Loading2.default.text },
      (0, _translations2.default)('UPLOADING FILE')
    )
  );
};

exports.default = FileLoading;
module.exports = exports['default'];