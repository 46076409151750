'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.redirectToUrlBeforeReload = redirectToUrlBeforeReload;
function redirectToUrlBeforeReload(account) {
  var urlBeforeReload = sessionStorage.getItem("urlBeforeReload");

  if (urlBeforeReload) {
    var isReseller = account.account_type === 'Reseller';
    var isMessageFile = urlBeforeReload.includes('messages/files');
    var isMessage = urlBeforeReload.includes('messages');

    if (!isReseller || isMessageFile || !isMessage) {
      window.location = urlBeforeReload;
    }
    sessionStorage.removeItem("urlBeforeReload");
  }
}