'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SubAccountEdit = exports.default = undefined;

var _getOwnPropertyDescriptor = require('babel-runtime/core-js/object/get-own-property-descriptor');

var _getOwnPropertyDescriptor2 = _interopRequireDefault(_getOwnPropertyDescriptor);

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _extends4 = require('babel-runtime/helpers/extends');

var _extends5 = _interopRequireDefault(_extends4);

var _getIterator2 = require('babel-runtime/core-js/get-iterator');

var _getIterator3 = _interopRequireDefault(_getIterator2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _dec2, _class, _desc, _value, _class2;

var _themeEnabled = require('helpers/themeEnabled');

var _Allow = require('../Allow/Allow');

var _Allow2 = _interopRequireDefault(_Allow);

var _components = require('components');

var _reactDatetime = require('react-datetime');

var _reactDatetime2 = _interopRequireDefault(_reactDatetime);

var _reactHelmet = require('react-helmet');

var _reactHelmet2 = _interopRequireDefault(_reactHelmet);

var _reactRouter = require('react-router');

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _policy = require('redux/modules/policy');

var _reduxConnect = require('redux-connect');

var _coreDecorators = require('core-decorators');

var _reactRedux = require('react-redux');

var _account = require('redux/modules/account');

var _status = require('redux/modules/status');

var _reports = require('redux/modules/reports');

var _partner = require('../../helpers/partner');

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _subaccounts = require('redux/modules/subaccounts');

var _reachHistory = require('../../helpers/reachHistory');

var _sessionPermissions = require('../../helpers/sessionPermissions');

var _Reseller = require('./Reseller.scss');

var _Reseller2 = _interopRequireDefault(_Reseller);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
  var desc = {};
  Object['ke' + 'ys'](descriptor).forEach(function (key) {
    desc[key] = descriptor[key];
  });
  desc.enumerable = !!desc.enumerable;
  desc.configurable = !!desc.configurable;

  if ('value' in desc || desc.initializer) {
    desc.writable = true;
  }

  desc = decorators.slice().reverse().reduce(function (desc, decorator) {
    return decorator(target, property, desc) || desc;
  }, desc);

  if (context && desc.initializer !== void 0) {
    desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
    desc.initializer = undefined;
  }

  if (desc.initializer === void 0) {
    Object['define' + 'Property'](target, property, desc);
    desc = null;
  }

  return desc;
}

var md = {
  edit: require('react-icons/lib/md/mode-edit'),
  delete: require('react-icons/lib/md/delete'),
  arrowBack: require('react-icons/lib/md/arrow-back')
};

var SubAccount = function (_React$Component) {
  (0, _inherits3.default)(SubAccount, _React$Component);

  function SubAccount() {
    (0, _classCallCheck3.default)(this, SubAccount);
    return (0, _possibleConstructorReturn3.default)(this, (SubAccount.__proto__ || (0, _getPrototypeOf2.default)(SubAccount)).apply(this, arguments));
  }

  (0, _createClass3.default)(SubAccount, [{
    key: 'renderEditNav',
    value: function renderEditNav() {
      var classesTheme = (0, _themeEnabled.getThemeEnabledStyle)(_Reseller2.default);
      return _react2.default.createElement(
        'div',
        { className: classesTheme },
        _react2.default.createElement(
          'div',
          { className: _Reseller2.default.modalnav },
          _react2.default.createElement(
            _reactRouter.Link,
            {
              to: {
                pathname: '/reseller/subaccount/edit',
                state: {
                  modal: true,
                  returnTo: '/reseller/',
                  account: this.account
                }
              },
              className: _Reseller2.default.leftbutton
            },
            _react2.default.createElement(md.edit, { size: 20 }),
            _react2.default.createElement(
              'span',
              null,
              (0, _translations2.default)('EDIT ACCOUNT')
            )
          ),
          _react2.default.createElement(
            'h1',
            { className: _Reseller2.default.centertitle },
            (0, _translations2.default)('ACCOUNT SUMMARY')
          )
        )
      );
    }
  }, {
    key: 'renderCreateNav',
    value: function renderCreateNav() {
      var classesTheme = (0, _themeEnabled.getThemeEnabledStyle)(_Reseller2.default);
      return _react2.default.createElement(
        'div',
        { className: classesTheme },
        _react2.default.createElement(
          'div',
          { className: _Reseller2.default.modalnav },
          _react2.default.createElement(
            'h1',
            { className: _Reseller2.default.centertitle },
            (0, _translations2.default)('ACCOUNT SUMMARY')
          )
        )
      );
    }
  }, {
    key: 'render',
    value: function render() {
      if (!this.props.location || !this.props.location.state || !this.props.location.state.account) {
        return 'Error';
      }
      var classesTheme = (0, _themeEnabled.getThemeEnabledStyle)(_Reseller2.default);
      var account = this.props.location.state.account;
      var charge_type = account.reach.reach.billing.charge_type;
      return _react2.default.createElement(
        'div',
        { className: classesTheme },
        _react2.default.createElement(
          'div',
          { className: _Reseller2.default.modalnav },
          _react2.default.createElement(
            'div',
            { className: _Reseller2.default.icon, onClick: this.props.router.goBack },
            _react2.default.createElement(md.arrowBack, { size: 20, color: '#FFF' })
          ),
          _react2.default.createElement(
            'h1',
            { className: _Reseller2.default.centertitle },
            (0, _translations2.default)('ACCOUNT SUMMARY')
          ),
          _react2.default.createElement(
            _Allow2.default,
            { permissions: ['reseller:subaccount:edit'] },
            charge_type == 'PREPAID' ? _react2.default.createElement(
              'div',
              { className: _Reseller2.default.center },
              _react2.default.createElement(
                _reactRouter.Link,
                {
                  className: _Reseller2.default.linkholderCharges,
                  to: {
                    pathname: '/reseller/subaccount/charges',
                    state: { account: account }
                  }
                },
                _react2.default.createElement(
                  'div',
                  { className: _Reseller2.default.sub },
                  (0, _translations2.default)("CHARGES")
                )
              )
            ) : null,
            _react2.default.createElement(
              'div',
              { className: _Reseller2.default.center },
              _react2.default.createElement(
                _reactRouter.Link,
                {

                  className: _Reseller2.default.linkholder,
                  to: {
                    pathname: '/reseller/subaccount/edit',
                    state: {
                      modal: true,
                      returnTo: '/reseller/',
                      modalSize: {
                        width: '370px',
                        height: '540px'
                      },
                      account: account, charge_type: charge_type
                    }
                  }
                },
                _react2.default.createElement(md.edit, { className: _Reseller2.default.icon, size: 20 }),
                _react2.default.createElement(
                  'div',
                  { className: _Reseller2.default.sub },
                  (0, _translations2.default)('EDIT')
                )
              )
            )
          )
        ),
        _react2.default.createElement(
          'div',
          { className: _Reseller2.default.body },
          _react2.default.createElement(
            'h3',
            null,
            (0, _translations2.default)('ACOUNT NAME')
          ),
          _react2.default.createElement(
            'p',
            null,
            account.status.account_name
          ),
          _react2.default.createElement(
            'h3',
            null,
            (0, _translations2.default)('ACCOUNT SMS SHORT NAME')
          ),
          _react2.default.createElement(
            'p',
            null,
            account.status.sms_short_name
          ),
          _react2.default.createElement(
            'h3',
            null,
            (0, _translations2.default)('ACCOUNT SUBSCRIPTION TYPE')
          ),
          _react2.default.createElement(
            'p',
            null,
            account.status.subscription_type
          ),
          _react2.default.createElement(
            'h3',
            null,
            (0, _translations2.default)('ACCOUNT VALID SINCE')
          ),
          _react2.default.createElement(
            'p',
            null,
            account.status.valid_since
          ),
          _react2.default.createElement(
            'h3',
            null,
            (0, _translations2.default)('ACCOUNT SMS LIMIT')
          ),
          _react2.default.createElement(
            'p',
            null,
            account.csm.subaccount_sms_limit
          )
        )
      );
    }
  }]);
  return SubAccount;
}(_react2.default.Component);

exports.default = SubAccount;
var SubAccountEdit = exports.SubAccountEdit = (_dec = (0, _reduxConnect.asyncConnect)([{
  key: 'account',
  promise: function promise(_ref) {
    var _ref$store = _ref.store,
        dispatch = _ref$store.dispatch,
        getState = _ref$store.getState;

    var state = getState();
    if (!state.account.data.account_id) {
      return dispatch((0, _status.getAccountStatus)());
    }
    return null;
  }
}]), _dec2 = (0, _reactRedux.connect)(function (state) {
  return {
    subaccounts: state.subaccounts.list,
    auth: state.auth
  };
}, {
  postSubaccount: _subaccounts.postSubaccount,
  putSubaccount: _subaccounts.putSubaccount,
  getSubAccountPolicies: _policy.getSubAccountPolicies,
  asignSubAccountPolicies: _policy.asignSubAccountPolicies,
  getPolicies: _policy.getPolicies,
  pushState: _reachHistory.push,
  getAccount: _account.getAccount,
  getAllBalanceByDate: _reports.getAllBalanceByDate
}), _dec(_class = _dec2(_class = (_class2 = function (_React$Component2) {
  (0, _inherits3.default)(SubAccountEdit, _React$Component2);

  function SubAccountEdit(props) {
    (0, _classCallCheck3.default)(this, SubAccountEdit);

    var _this2 = (0, _possibleConstructorReturn3.default)(this, (SubAccountEdit.__proto__ || (0, _getPrototypeOf2.default)(SubAccountEdit)).call(this, props));

    _this2.handle_route_change = function (value, source_uid) {
      var warning = null;
      var temporal_routers_limits = _this2.state.routers_limits;
      var total_limit_value = temporal_routers_limits.map(function (element) {
        if (element._id === source_uid) {
          element.credit_limit = value;
        }
        return element.credit_limit;
      }).reduce(function (prev_value, current_val) {
        return prev_value + current_val;
      });

      if (_this2.state.account.subaccount_sms_limit < total_limit_value) {
        warning = _react2.default.createElement(
          'div',
          null,
          _react2.default.createElement(
            'span',
            { style: { color: 'red', fontStyle: 'bold' } },
            'Error al asignar saldo!'
          )
        );
      }
      _this2.setState({ routers_limits: temporal_routers_limits, warning: warning });
    };

    _this2.render_routers = function () {
      var routers_limits = _this2.state.routers_limits;

      var route_view = [];
      if (routers_limits && routers_limits.length > 1) {
        routers_limits.map(function (route_limit) {
          route_view.push(_react2.default.createElement(
            'div',
            null,
            _react2.default.createElement(
              'h3',
              null,
              route_limit.name
            ),
            _react2.default.createElement('input', { type: 'number',
              value: _this2.state.routers_limits.find(function (temp) {
                return temp._id == route_limit._id;
              }).credit_limit,
              onChange: function onChange(event) {
                return _this2.handle_route_change(Number(event.target.value), route_limit._id);
              } }),
            _this2.state.warning
          ));
        });
      }
      return route_view;
    };

    var id = null;
    var account_id = null;
    var required_fields = ["account_name", "sms_short_name"];
    if (_this2.props.location.state.account) {
      var state_account = _this2.props.location.state.account;
      account_id = _this2.props.location.state.account.status.account_id;
      id = state_account.reach._id;
      _this2.account = {
        subscription_type: state_account.status.subscription_type,
        account_name: state_account.status.account_name,
        client_name: state_account.status.account_name,
        sms_short_name: state_account.status.sms_short_name,
        valid_since: state_account.status.valid_since,
        subaccount_sms_limit: state_account.csm.subaccount_sms_limit,
        shared_store: state_account.reach.shared_store,
        charge_type: _this2.props.location.state.charge_type,
        partner: state_account.reach.partner,
        user_email: '',
        user_first_name: '',
        user_last_name: '',
        required_fields: required_fields,
        router: state_account.reach.router,
        edited: true
      };
      _this2.policy = {
        "policy_uid": '',
        "account_id": account_id
      };
    } else {
      required_fields.push("user_email");
      _this2.account = {
        edited: false,
        subscription_type: 'OPTOUT',
        charge_type: 'POSTPAID',
        account_name: '',
        client_name: '',
        sms_short_name: '',
        valid_since: (0, _moment2.default)().format('YYYY-MM-DD HH:mm:ss'),
        subaccount_sms_limit: 0,
        user_email: '',
        user_first_name: '',
        user_last_name: '',
        shared_store: true,
        required_fields: required_fields
      };
      _this2.policy = {};
    }

    var valid = {};
    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = (0, _getIterator3.default)(required_fields), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var field = _step.value;

        valid[field] = true;
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }

    _this2.state = {
      id: id,
      account: _this2.account,
      policy: _this2.policy,
      policy_options: null,
      account_id: _this2.account_id,
      title: "",
      valid: valid,
      reachAccount: null,
      routers_limits: [],
      balances: null,
      disponibles: [],
      warning: null
    };

    _this2.initialAccount = _this2.state.account;
    _this2.saved = false;
    return _this2;
  }

  (0, _createClass3.default)(SubAccountEdit, [{
    key: 'routerWillLeave',
    value: function routerWillLeave() {
      if (this.state.account != this.initialAccount && !this.saved) {
        return confirm((0, _translations2.default)("LEAVE WITHOUT SAVING"));
      }
      return true;
    }
  }, {
    key: 'componentDidMount',
    value: function componentDidMount() {
      var _this3 = this;

      this.unregisterRouteHook = this.props.router.setRouteLeaveHook(this.props.route, this.routerWillLeave);

      if (this.state.id) {
        var disponibles = [];
        this.state.account.router.router_sources.map(function (route) {
          var temporales = { _id: route._id, name: route.name, credit_limit: route.credit_limit };
          disponibles.push(temporales);
        });
        this.setState({ routers_limits: disponibles, title: (0, _translations2.default)('EDIT ACCOUNT') });
      } else {
        this.props.getAccount().then(function (reachAccount) {
          var disponibles = [];
          if (reachAccount) {
            reachAccount.router.router_sources.map(function (route) {
              var temporales = { _id: route._id, name: route.name, credit_limit: 0 };
              disponibles.push(temporales);
            });
          }
          _this3.setState({ title: (0, _translations2.default)('CREATE ACCOUNT'), routers_limits: disponibles });
        });
      }
    }
  }, {
    key: 'renderPrepaidCharge',
    value: function renderPrepaidCharge() {
      var account = this.state.account;
      var smsBalance = this.props.account.current_package.credit_base_limit;
      this.props.subaccounts.forEach(function (subaccount) {
        smsBalance -= subaccount.csm.subaccount_sms_limit;
      });
      if (!this.account.edited) {
        return _react2.default.createElement(
          'div',
          null,
          _react2.default.createElement(
            'h3',
            null,
            (0, _translations2.default)("INITIAL CHARGE")
          ),
          _react2.default.createElement(
            'div',
            { className: _Reseller2.default.limit },
            _react2.default.createElement('input', { type: 'number', value: account.subaccount_sms_limit, onChange: this.handleConfigurationChange.bind(this, "subaccount_sms_limit") }),
            this.props.subaccounts.length > 0 && smsBalance > 0 ? _react2.default.createElement(
              'span',
              null,
              ' ',
              (0, _translations2.default)("OF"),
              ' ',
              smsBalance
            ) : _react2.default.createElement(
              'span',
              null,
              '\xA0'
            )
          ),
          _react2.default.createElement(
            'h3',
            null,
            (0, _translations2.default)("CREDIT DATE")
          ),
          _react2.default.createElement(
            'div',
            { className: _Reseller2.default.date },
            _react2.default.createElement(_reactDatetime2.default, { value: account.valid_since, onChange: this.validSince.bind(this) })
          )
        );
      }
    }
  }, {
    key: 'handleConfigurationChange',
    value: function handleConfigurationChange(key, event) {
      var target = event.target;
      var value = target.type === 'checkbox' ? target.checked : target.value;
      if (this.state.routers_limits.length == 1 && key == "subaccount_sms_limit") {
        this.state.routers_limits[0].credit_limit = Number(value);
      }
      this.setState((0, _extends5.default)({}, this.state, {
        account: (0, _extends5.default)({}, this.state.account, (0, _defineProperty3.default)({}, key, value))
      }));
    }
  }, {
    key: 'handlePolicyConfigurationChange',
    value: function handlePolicyConfigurationChange(key, event) {
      this.setState({
        policy: (0, _extends5.default)({}, this.state.policy, (0, _defineProperty3.default)({}, key, event.target.value))
      });
    }
  }, {
    key: 'verifyRequiredFields',
    value: function verifyRequiredFields() {
      var required_fields = this.state.account.required_fields;
      var valid_form = true;

      var _iteratorNormalCompletion2 = true;
      var _didIteratorError2 = false;
      var _iteratorError2 = undefined;

      try {
        for (var _iterator2 = (0, _getIterator3.default)(required_fields), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
          var field = _step2.value;

          var field_is_valid = this.checkText(field);
          valid_form = valid_form && field_is_valid;
        }
      } catch (err) {
        _didIteratorError2 = true;
        _iteratorError2 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion2 && _iterator2.return) {
            _iterator2.return();
          }
        } finally {
          if (_didIteratorError2) {
            throw _iteratorError2;
          }
        }
      }

      return valid_form;
    }
  }, {
    key: 'save',
    value: function save() {
      var _this4 = this;

      var valid_form = this.verifyRequiredFields();
      if (!valid_form) return;

      if (this.state.id == null) {

        var data = (0, _extends5.default)({}, this.state.account, { router_sources: this.state.routers_limits });
        return this.props.postSubaccount(data).then(function (result) {
          _this4.saved = true;
          _this4.props.pushState({
            pathname: '/success',
            state: {
              goTo: '/reseller/',
              message: (0, _translations2.default)('SUBACCOUNT CREATE SUCCESS'),
              goToModal: false,
              modal: true,
              returnTo: '/reseller/'
            }
          });
        }).catch(function (e) {
          _this4.saved = true;
          console.error('== Could not create new subaccount ==', e);
          _this4.props.pushState({
            pathname: '/fail',
            state: {
              goTo: '/reseller/',
              goToModal: false,
              message: e.message,
              modal: true,
              returnTo: '/reseller/'
            }
          });
        });
      } else {
        var _data = (0, _extends5.default)({}, this.state.account, { router_sources: this.state.routers_limits });
        return this.props.putSubaccount(this.state.id, _data).then(function (result) {
          _this4.props.asignSubAccountPolicies(_this4.state.policy).then(function (result) {
            _this4.saved = true;
            _this4.props.pushState({
              pathname: '/success',
              state: { goTo: '/reseller/', message: (0, _translations2.default)('SUBACCOUNT UPDATE SUCCESS'), goToModal: false,
                modal: true, returnTo: '/reseller/' }
            });
          });
        });
      }
    }
  }, {
    key: 'renderUser',
    value: function renderUser(account) {
      return _react2.default.createElement(
        'div',
        { className: _Reseller2.default.body },
        _react2.default.createElement(
          'h3',
          null,
          (0, _translations2.default)('USER DETAIL')
        ),
        _react2.default.createElement(
          'h3',
          null,
          (0, _translations2.default)('EMAIL'),
          ' ',
          _react2.default.createElement(
            'div',
            { className: _Reseller2.default.requiredFieldMessage },
            '*'
          )
        ),
        _react2.default.createElement(
          'p',
          null,
          _react2.default.createElement('input', { type: 'email', value: account.user_email, onBlur: this.checkText.bind(this, "user_email"),
            onChange: this.handleConfigurationChange.bind(this, "user_email") }),
          _react2.default.createElement(
            'span',
            null,
            this.state.valid.user_email ? '' : _react2.default.createElement(
              'div',
              { className: _Reseller2.default.requiredFieldMessage },
              (0, _translations2.default)('REQUIRED FIELD')
            )
          )
        ),
        _react2.default.createElement(
          'h3',
          null,
          (0, _translations2.default)('FIRST NAME')
        ),
        _react2.default.createElement(
          'p',
          null,
          _react2.default.createElement('input', { type: 'text', value: account.first_name, onChange: this.handleConfigurationChange.bind(this, "user_first_name") })
        ),
        _react2.default.createElement(
          'h3',
          null,
          (0, _translations2.default)('LAST NAME')
        ),
        _react2.default.createElement(
          'p',
          null,
          _react2.default.createElement('input', { type: 'text', value: account.last_name, onChange: this.handleConfigurationChange.bind(this, "user_last_name") })
        )
      );
    }
  }, {
    key: 'renderComponentPolicy',
    value: function renderComponentPolicy(policy) {
      return _react2.default.createElement(
        'div',
        { className: _Reseller2.default.body },
        !this.state.policy_options && this.renderPoliciesOptions(),
        !this.state.policy_options && this.renderPolicyAsignation(this.state.account_id),
        _react2.default.createElement(
          'h3',
          null,
          (0, _translations2.default)('ACCOUNT POLICY')
        ),
        _react2.default.createElement(
          'p',
          null,
          _react2.default.createElement(
            'select',
            { value: policy.policy_uid, onChange: this.handlePolicyConfigurationChange.bind(this, "policy_uid") },
            this.state.policy_options
          )
        )
      );
    }
  }, {
    key: 'renderPoliciesOptions',
    value: function renderPoliciesOptions() {
      var _this5 = this;

      var options = [];
      this.props.getPolicies().then(function (policies) {
        options.push(_react2.default.createElement(
          'option',
          { key: -1, value: 0 },
          ' '
        ));
        policies.forEach(function (e, i) {
          options.push(_react2.default.createElement(
            'option',
            { key: i, value: e._id },
            e.policy_name
          ));
        });
        _this5.setState({
          policy_options: options
        });
      });
    }
  }, {
    key: 'renderPolicyAsignation',
    value: function renderPolicyAsignation(id) {
      var _this6 = this;

      this.props.getSubAccountPolicies().then(function (list) {
        list.accounts.forEach(function (e) {
          if (e.account_id == id) {
            list.policies.forEach(function (p) {
              if (e.policies[0] == p._id) {
                _this6.setState({
                  policy: {
                    "policy_uid": p._id,
                    "account_id": e.account_id
                  }
                });
                return;
              }
            });
            return;
          }
        });
      });
    }
  }, {
    key: 'renderEditNav',
    value: function renderEditNav() {
      return _react2.default.createElement(
        'div',
        { className: _Reseller2.default.modalnav },
        _react2.default.createElement(
          'div',
          { className: _Reseller2.default.icon, onClick: this.props.router.goBack },
          _react2.default.createElement(md.arrowBack, { size: 20, color: '#FFF' })
        ),
        _react2.default.createElement(
          'h1',
          { className: _Reseller2.default.centertitle },
          this.state.title
        )
      );
    }
  }, {
    key: 'renderCreateNav',
    value: function renderCreateNav() {
      return _react2.default.createElement(
        'div',
        { className: _Reseller2.default.modalnav },
        _react2.default.createElement(
          'div',
          { className: _Reseller2.default.icon, onClick: this.props.router.goBack },
          _react2.default.createElement(md.arrowBack, { size: 20, color: '#FFF' })
        ),
        _react2.default.createElement(
          'h1',
          { className: _Reseller2.default.centertitle },
          this.state.title
        )
      );
    }
  }, {
    key: 'checkText',
    value: function checkText(key) {
      var valid = this.state.valid;
      var account = this.state.account;
      valid[key] = account[key] ? true : false;

      this.setState({ valid: valid });
      return valid[key];
    }
  }, {
    key: 'validSince',
    value: function validSince(date) {
      this.setState({ account: (0, _extends5.default)({}, this.state.account, { valid_since: date }) });
    }
  }, {
    key: 'render',
    value: function render() {
      var account = this.state.account;
      var policy = this.state.policy;

      var smsBalance = this.props.account.current_package.credit_base_limit;
      this.props.subaccounts.forEach(function (subaccount) {
        smsBalance -= subaccount.csm.subaccount_sms_limit;
      });
      var partner = (0, _partner.getPartnerByHost)();
      var classesTheme = (0, _themeEnabled.getThemeEnabledStyle)(_Reseller2.default);
      var objAsset = (0, _themeEnabled.getAssetStyle)();
      var illustrationTheme = objAsset.form;
      var showSubscriptionTypes = !(partner == "tigo" || this.account.partner == "tigo" || this.props.auth.current_account.partner == "tigo");
      account.subscription_type = partner == 'tigo' || this.account.partner == 'tigo' || this.props.auth.current_account.partner == 'tigo' ? 'CLEAR' : account.subscription_type;
      return _react2.default.createElement(
        'div',
        { className: classesTheme },
        _react2.default.createElement(
          'div',
          { className: _Reseller2.default.main },
          _react2.default.createElement(_reactHelmet2.default, { title: this.state.title }),
          !this.state.id ? this.renderCreateNav() : this.renderEditNav(),
          _react2.default.createElement(
            'div',
            { className: _Reseller2.default.container },
            _react2.default.createElement(
              'div',
              { className: _Reseller2.default.right },
              _react2.default.createElement(
                'div',
                { className: _Reseller2.default.body },
                _react2.default.createElement(
                  'h3',
                  null,
                  (0, _translations2.default)('ACCOUNT NAME'),
                  ' ',
                  _react2.default.createElement(
                    'div',
                    { className: _Reseller2.default.requiredFieldMessage },
                    '*'
                  )
                ),
                _react2.default.createElement(
                  'p',
                  null,
                  _react2.default.createElement('input', { type: 'text', value: account.account_name, onBlur: this.checkText.bind(this, "account_name"),
                    onChange: this.handleConfigurationChange.bind(this, "account_name") }),
                  _react2.default.createElement(
                    'span',
                    null,
                    this.state.valid.account_name ? '' : _react2.default.createElement(
                      'div',
                      { className: _Reseller2.default.requiredFieldMessage },
                      (0, _translations2.default)('REQUIRED FIELD')
                    )
                  )
                ),
                _react2.default.createElement(
                  'h3',
                  null,
                  (0, _translations2.default)('ACCOUNT SMS SHORT NAME'),
                  ' ',
                  _react2.default.createElement(
                    'div',
                    { className: _Reseller2.default.requiredFieldMessage },
                    '*'
                  ),
                  ' '
                ),
                _react2.default.createElement(
                  'p',
                  null,
                  _react2.default.createElement('input', { type: 'text', value: account.sms_short_name, onBlur: this.checkText.bind(this, "sms_short_name"),
                    onChange: this.handleConfigurationChange.bind(this, "sms_short_name") }),
                  _react2.default.createElement(
                    'span',
                    null,
                    this.state.valid.sms_short_name ? '' : _react2.default.createElement(
                      'div',
                      { className: _Reseller2.default.requiredFieldMessage },
                      (0, _translations2.default)('REQUIRED FIELD')
                    )
                  )
                ),
                _react2.default.createElement(
                  'h3',
                  null,
                  (0, _translations2.default)('ACCOUNT SUBSCRIPTION TYPE')
                ),
                _react2.default.createElement(
                  'p',
                  null,
                  _react2.default.createElement(
                    'select',
                    { value: account.subscription_type, onChange: this.handleConfigurationChange.bind(this, "subscription_type") },
                    showSubscriptionTypes && [_react2.default.createElement(
                      'option',
                      { value: 'OPTOUT' },
                      'OPTOUT'
                    ), _react2.default.createElement(
                      'option',
                      { value: 'OPTIN' },
                      'OPTIN'
                    )],
                    _react2.default.createElement(
                      'option',
                      { value: 'CLEAR' },
                      'CLEAR'
                    )
                  )
                ),
                _react2.default.createElement(
                  _Allow2.default,
                  { permissions: ['reseller:prepaid:subaccount:create'] },
                  !this.account.edited ? _react2.default.createElement(
                    'div',
                    { style: { width: '100%' } },
                    _react2.default.createElement(
                      'h3',
                      null,
                      (0, _translations2.default)("PAYMENT TYPE")
                    ),
                    _react2.default.createElement(
                      'p',
                      null,
                      _react2.default.createElement(
                        'select',
                        { value: account.charge_type, onChange: this.handleConfigurationChange.bind(this, "charge_type") },
                        _react2.default.createElement(
                          'option',
                          { value: 'POSTPAID' },
                          (0, _translations2.default)('POSTPAID')
                        ),
                        _react2.default.createElement(
                          'option',
                          { value: 'PREPAID' },
                          (0, _translations2.default)('PREPAID')
                        )
                      )
                    )
                  ) : null
                ),
                account.charge_type == 'POSTPAID' ? _react2.default.createElement(
                  'div',
                  null,
                  _react2.default.createElement(
                    'h3',
                    null,
                    (0, _translations2.default)('ACCOUNT SMS LIMIT')
                  ),
                  _react2.default.createElement(
                    'div',
                    { className: _Reseller2.default.limit },
                    _react2.default.createElement('input', { type: 'number', value: account.subaccount_sms_limit, onChange: this.handleConfigurationChange.bind(this, "subaccount_sms_limit") }),
                    this.props.subaccounts.length > 0 && smsBalance > 0 ? _react2.default.createElement(
                      'span',
                      null,
                      ' de ',
                      smsBalance
                    ) : _react2.default.createElement(
                      'span',
                      null,
                      '\xA0'
                    )
                  ),
                  this.render_routers()
                ) : this.renderPrepaidCharge()
              ),
              !this.state.id && this.renderUser(account),
              this.state.id && this.renderComponentPolicy(policy),
              _react2.default.createElement(
                'div',
                { className: _Reseller2.default.body },
                _react2.default.createElement('br', null),
                _react2.default.createElement('br', null),
                _react2.default.createElement(_components.Button, { onClick: this.save.bind(this), name: (0, _translations2.default)('SUBACCOUNT SAVE'), className: _Reseller2.default.button })
              )
            ),
            _react2.default.createElement('img', { className: _Reseller2.default.image, src: illustrationTheme })
          )
        )
      );
    }
  }]);
  return SubAccountEdit;
}(_react2.default.Component), (_applyDecoratedDescriptor(_class2.prototype, 'routerWillLeave', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'routerWillLeave'), _class2.prototype)), _class2)) || _class) || _class);