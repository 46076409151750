'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ContactsAddMessage = exports.default = undefined;

var _getOwnPropertyDescriptor = require('babel-runtime/core-js/object/get-own-property-descriptor');

var _getOwnPropertyDescriptor2 = _interopRequireDefault(_getOwnPropertyDescriptor);

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _class, _desc, _value, _class2, _class3, _temp, _dec2, _class4, _desc2, _value2, _class5, _class6, _temp2;

var _components = require('components');

var _tags = require('redux/modules/tags');

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _coreDecorators = require('core-decorators');

var _reactRedux = require('react-redux');

var _contacts = require('redux/modules/contacts');

var _reachHistory = require('../../helpers/reachHistory');

var _reduxForm = require('redux-form');

var _messages = require('redux/modules/messages');

var _ContactsTags = require('./ContactsTags.scss');

var _ContactsTags2 = _interopRequireDefault(_ContactsTags);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
  var desc = {};
  Object['ke' + 'ys'](descriptor).forEach(function (key) {
    desc[key] = descriptor[key];
  });
  desc.enumerable = !!desc.enumerable;
  desc.configurable = !!desc.configurable;

  if ('value' in desc || desc.initializer) {
    desc.writable = true;
  }

  desc = decorators.slice().reverse().reduce(function (desc, decorator) {
    return decorator(target, property, desc) || desc;
  }, desc);

  if (context && desc.initializer !== void 0) {
    desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
    desc.initializer = undefined;
  }

  if (desc.initializer === void 0) {
    Object['define' + 'Property'](target, property, desc);
    desc = null;
  }

  return desc;
}

var md = {
  search: require('react-icons/lib/md/search'),
  person: require('react-icons/lib/md/person'),
  arrowBack: require('react-icons/lib/md/arrow-back'),
  personAdd: require('react-icons/lib/md/add'),
  close: require('react-icons/lib/md/clear'),
  tag: require('react-icons/lib/md/label'),
  attach: require('react-icons/lib/md/attach-file'),
  message: require('react-icons/lib/md/email'),
  delete: require('react-icons/lib/md/delete')
};

var ContactsAddTags = (_dec = (0, _reactRedux.connect)(function (state) {
  return {
    mobileMode: state.mobile.mobileMode,
    selected: state.tags.selected,
    list: state.tags.list,
    isLoading: state.tags.loading
  };
}, {
  pushState: _reachHistory.push,
  get: _tags.getTagsRefactor,
  select: _tags.tagsSelect,
  unselect: _tags.tagsUnselect,
  refresh: _tags.tagsRefresh,
  tagsUnselectAll: _tags.tagsUnselectAll,
  contactsRefresh: _contacts.contactsRefresh,
  addTagListToContactList: _tags.addTagListToContactList
}), _dec(_class = (_class2 = (_temp = _class3 = function (_List) {
  (0, _inherits3.default)(ContactsAddTags, _List);

  function ContactsAddTags(props) {
    (0, _classCallCheck3.default)(this, ContactsAddTags);

    var _this = (0, _possibleConstructorReturn3.default)(this, (ContactsAddTags.__proto__ || (0, _getPrototypeOf2.default)(ContactsAddTags)).call(this, props));

    _this.state = (0, _extends3.default)({}, _this.state, {
      selecting: false,
      newTag: ''
    });
    return _this;
  }

  (0, _createClass3.default)(ContactsAddTags, [{
    key: 'getContactsSelected',
    value: function getContactsSelected() {
      if (!this.props.location.state || !this.props.location.state.contactsSelected || this.props.location.state.contactsSelected.length === 0) {
        console.error('no contacts provided');
        return this.props.pushState('/contacts');
      }
      return this.props.location.state.contactsSelected;
    }
  }, {
    key: 'handleNewTag',
    value: function handleNewTag(event) {
      this.setState((0, _extends3.default)({}, this.state, {
        newTag: event.target.value
      }));
    }
  }, {
    key: 'handleSelectionActivate',
    value: function handleSelectionActivate() {
      this.setState((0, _extends3.default)({}, this.state, {
        selecting: true
      }));
    }
  }, {
    key: 'handleSelectionDeactivate',
    value: function handleSelectionDeactivate() {
      this.setState((0, _extends3.default)({}, this.state, {
        selecting: false
      }));
    }
  }, {
    key: 'handleUnselectAll',
    value: function handleUnselectAll() {
      this.props.tagsUnselectAll();
    }
  }, {
    key: 'addTagsToContacts',
    value: function addTagsToContacts() {
      var _this2 = this;

      var contactsSelected = this.getContactsSelected();
      var newTag = this.state.newTag ? [this.state.newTag] : [];
      var tagList = newTag.concat(this.props.selected.map(function (s) {
        return _this2.props.list[s].name;
      }));
      this.props.addTagListToContactList(contactsSelected.map(function (c) {
        return c.id;
      }), tagList).then(function () {
        _this2.props.contactsRefresh();
        _this2.props.pushState('/contacts');
      });
    }
  }, {
    key: 'renderSelector',
    value: function renderSelector() {
      var _this3 = this;

      return _react2.default.createElement(
        'div',
        { className: _ContactsTags2.default.selector },
        this.renderSearchBar(),
        _react2.default.createElement('input', { className: _ContactsTags2.default.newTag, onChange: this.handleNewTag, value: this.state.newTag, placeholder: (0, _translations2.default)('ADD NEW TAG') }),
        this.props.list.map(function (item, index) {
          if (item.name) {
            return _react2.default.createElement(_components.VirtualListItemSlim, {
              key: item.name,
              index: index,
              text: item.name,
              icon: _react2.default.createElement(md.tag, { size: 20, className: _ContactsTags2.default.listicon }),
              mobileMode: _this3.props.mobileMode,
              active: item.active,
              setActive: _this3.setActive.bind(_this3),
              modal: true
            });
          }
          return null;
        })
      );
    }
  }, {
    key: 'renderTagsSelected',
    value: function renderTagsSelected() {
      var _this4 = this;

      var newTag = this.state.newTag ? [this.state.newTag] : [];
      var tagList = newTag.concat(this.props.selected.map(function (s) {
        return _this4.props.list[s].name;
      }));
      return tagList.map(function (s) {
        return _react2.default.createElement(
          'div',
          { key: s, className: _ContactsTags2.default.contact },
          _react2.default.createElement(md.tag, { size: 14, className: _ContactsTags2.default.icon }),
          s
        );
      });
    }
  }, {
    key: 'renderContactsSelected',
    value: function renderContactsSelected() {
      var contactsSelected = this.getContactsSelected();
      return contactsSelected.map(function (c) {
        return _react2.default.createElement(
          'div',
          { key: c.id, className: _ContactsTags2.default.contact },
          _react2.default.createElement(md.person, { size: 14, className: _ContactsTags2.default.icon }),
          c.msisdn || c.email
        );
      });
    }
  }, {
    key: 'renderSearchBar',
    value: function renderSearchBar() {
      return _react2.default.createElement(
        'div',
        { className: _ContactsTags2.default.search },
        _react2.default.createElement('input', {
          className: _ContactsTags2.default.searchInput,
          type: 'text',
          placeholder: (0, _translations2.default)('TAG SEARCH PLACEHOLDER'),
          value: this.state.search || '',
          onChange: this.handleSearch,
          onKeyPress: this.handleSearch
        }),
        _react2.default.createElement(md.search, { className: _ContactsTags2.default.searchIcon })
      );
    }
  }, {
    key: 'render',
    value: function render() {
      if (this.state.selecting) {
        return _react2.default.createElement(
          'div',
          { className: _ContactsTags2.default.message },
          _react2.default.createElement(_components.MobileBar, { title: (0, _translations2.default)('SELECT CONTACTS'), extraAction: this.handleSelectionDeactivate, extraIcon: md.close }),
          this.renderSelector(),
          _react2.default.createElement(
            'div',
            { className: _ContactsTags2.default.selectorActions },
            _react2.default.createElement(
              'button',
              { className: _ContactsTags2.default.selectorAction, onClick: this.handleUnselectAll },
              (0, _translations2.default)('CLEAR SELECTION')
            ),
            _react2.default.createElement(
              'button',
              { className: _ContactsTags2.default.selectorAction, onClick: this.handleSelectionDeactivate },
              (0, _translations2.default)('SELECT')
            )
          )
        );
      }

      var selectedClassNames = _ContactsTags2.default.selected + ' ' + (this.props.mobileMode ? _ContactsTags2.default.mobile : '');
      return _react2.default.createElement(
        'div',
        { className: _ContactsTags2.default.message },
        _react2.default.createElement(_components.MobileBar, { returnTo: '/messages/' }),
        _react2.default.createElement(
          'div',
          { className: _ContactsTags2.default.main },
          this.props.mobileMode ? null : this.renderSelector(),
          _react2.default.createElement(
            'div',
            { className: _ContactsTags2.default.right },
            _react2.default.createElement(
              'div',
              { className: selectedClassNames },
              _react2.default.createElement(
                'span',
                { className: _ContactsTags2.default.contactsLabel },
                (0, _translations2.default)('CONTACTS SHORT'),
                ':'
              ),
              this.renderContactsSelected()
            ),
            _react2.default.createElement(
              'div',
              { className: _ContactsTags2.default.tagsArea },
              _react2.default.createElement(
                'span',
                { className: _ContactsTags2.default.tagsLabel },
                (0, _translations2.default)('TAGS SHORT'),
                ':'
              ),
              this.renderTagsSelected()
            ),
            _react2.default.createElement(
              'div',
              { className: _ContactsTags2.default.actionContainer },
              this.props.mobileMode ? _react2.default.createElement(md.personAdd, { onClick: this.handleSelectionActivate, size: 30, className: _ContactsTags2.default.select }) : null,
              _react2.default.createElement(_components.Button, { color: 'orange', onClick: this.addTagsToContacts, name: (0, _translations2.default)('ADD TAGS'), className: _ContactsTags2.default.addButton })
            )
          )
        )
      );
    }
  }]);
  return ContactsAddTags;
}(_components.List), _class3.propTypes = {
  mobileMode: _propTypes2.default.bool.isRequired,
  select: _propTypes2.default.func.isRequired,
  unselect: _propTypes2.default.func.isRequired,
  list: _propTypes2.default.array.isRequired,
  refresh: _propTypes2.default.func.isRequired,
  get: _propTypes2.default.func.isRequired,
  tagsUnselectAll: _propTypes2.default.func.isRequired,
  isLoading: _propTypes2.default.bool.isRequired,
  selected: _propTypes2.default.array.isRequired,
  contactsRefresh: _propTypes2.default.func.isRequired,
  addTagListToContactList: _propTypes2.default.func.isRequired,
  pushState: _propTypes2.default.func.isRequired,
  location: _propTypes2.default.object.isRequired
}, _temp), (_applyDecoratedDescriptor(_class2.prototype, 'handleNewTag', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'handleNewTag'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, 'handleSelectionActivate', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'handleSelectionActivate'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, 'handleSelectionDeactivate', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'handleSelectionDeactivate'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, 'handleUnselectAll', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'handleUnselectAll'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, 'addTagsToContacts', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'addTagsToContacts'), _class2.prototype)), _class2)) || _class);
exports.default = ContactsAddTags;


var MessageForm = (0, _reduxForm.reduxForm)({
  form: 'message'
})(_components.MessageFormContainer);

var ContactsAddMessage = exports.ContactsAddMessage = (_dec2 = (0, _reactRedux.connect)(function (state) {
  return {
    mobileMode: state.mobile.mobileMode
  };
}, {
  push: _reachHistory.push,
  sendMessage: _messages.messageSendMultiple
}), _dec2(_class4 = (_class5 = (_temp2 = _class6 = function (_List2) {
  (0, _inherits3.default)(ContactsAddMessage, _List2);

  function ContactsAddMessage(props) {
    (0, _classCallCheck3.default)(this, ContactsAddMessage);

    var _this5 = (0, _possibleConstructorReturn3.default)(this, (ContactsAddMessage.__proto__ || (0, _getPrototypeOf2.default)(ContactsAddMessage)).call(this, props));

    _this5.state = (0, _extends3.default)({}, _this5.state, {
      selecting: false
    });
    _this5.formData = null;
    return _this5;
  }

  (0, _createClass3.default)(ContactsAddMessage, [{
    key: 'sendMessage',
    value: function sendMessage(resetForm, form) {
      var _this6 = this;

      var contacts = this.getContactsSelected();
      var list = contacts.map(function (s) {
        return s.msisdn;
      });
      var message = form.message;
      this.setState((0, _extends3.default)({}, this.state, {
        sending: true
      }), function () {
        _this6.props.sendMessage(list, message, null).then(function () {
          _this6.setState((0, _extends3.default)({}, _this6.state, {
            sending: false
          }));
          _this6.props.push({
            pathname: '/success',
            state: { goTo: '/messages/', goToModal: false,
              message: (0, _translations2.default)('MESSAGE SEND SUCCESS'),
              modal: true, returnTo: '/messages/' }
          });
          resetForm();
        }).catch(function (e) {
          console.error('Could not send message', e);
          _this6.setState((0, _extends3.default)({}, _this6.state, {
            sending: false
          }));
          _this6.props.push({
            pathname: '/fail',
            state: { goTo: '/messages/', goToModal: false,
              message: e.message,
              modal: !_this6.props.mobileMode, returnTo: '/messages/' }
          });
        });
      });
    }
  }, {
    key: 'getContactsSelected',
    value: function getContactsSelected() {
      if (!this.props.location.state || !this.props.location.state.contactsSelected || this.props.location.state.contactsSelected.length === 0) {
        console.error('no contacts provided');
        return this.props.push('/contacts');
      }
      return this.props.location.state.contactsSelected;
    }
  }, {
    key: 'renderContactsSelected',
    value: function renderContactsSelected() {
      var contactsSelected = this.getContactsSelected();
      return contactsSelected.map(function (c) {
        return _react2.default.createElement(
          'div',
          { key: c.id, className: _ContactsTags2.default.contact },
          _react2.default.createElement(md.person, { size: 14, className: _ContactsTags2.default.icon }),
          c.msisdn
        );
      });
    }
  }, {
    key: 'render',
    value: function render() {
      var selectedClassNames = _ContactsTags2.default.selected + ' ' + (this.props.mobileMode ? _ContactsTags2.default.mobile : '');
      return _react2.default.createElement(
        'div',
        { className: _ContactsTags2.default.message },
        _react2.default.createElement(_components.MobileBar, { returnTo: '/messages/' }),
        _react2.default.createElement(
          'div',
          { className: _ContactsTags2.default.main },
          _react2.default.createElement(
            'div',
            { className: _ContactsTags2.default.center },
            _react2.default.createElement(
              'div',
              { className: selectedClassNames },
              _react2.default.createElement(
                'span',
                { className: _ContactsTags2.default.contactsLabel },
                (0, _translations2.default)('CONTACTS SHORT'),
                ':'
              ),
              this.renderContactsSelected()
            ),
            _react2.default.createElement(MessageForm, { postMessage: this.sendMessage, key: '2', loading: this.state.sending })
          )
        )
      );
    }
  }]);
  return ContactsAddMessage;
}(_components.List), _class6.propTypes = {
  mobileMode: _propTypes2.default.bool.isRequired,
  push: _propTypes2.default.func.isRequired,
  location: _propTypes2.default.object.isRequired
}, _temp2), (_applyDecoratedDescriptor(_class5.prototype, 'sendMessage', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class5.prototype, 'sendMessage'), _class5.prototype)), _class5)) || _class4);