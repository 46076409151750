'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _class; /* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/first */


var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRouter = require('react-router');

var _reactRedux = require('react-redux');

var _reactReduxLoadingBar = require('react-redux-loading-bar');

var _Allow = require('../Allow/Allow');

var _Allow2 = _interopRequireDefault(_Allow);

var _Dashboard = require('./Dashboard.scss');

var _Dashboard2 = _interopRequireDefault(_Dashboard);

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// const icon = {
//   add_file: require('./svg/add_file.svg'),
//   received_messages: require('./svg/received_messages.svg'),
//   sent_messages: require('./svg/sent_messages.svg'),
//   scheduled_messages: require('./svg/scheduled_messages.svg'),
//   file_list: require('./svg/file_list.svg'),
//   add_contact: require('./svg/add_contact.svg'),
//   admin_contacts: require('./svg/contacts.svg'),
//   report: require('./svg/report.svg'),
//   conversation:require('./svg/conversation.svg'),
//   link:require('./svg/link.svg')
// }

var icon = {
  add_file: require('../../../static/notificame/dashboard/add_file.svg'),
  received_messages: require('../../../static/notificame/dashboard/sent_messages.svg'),
  sent_messages: require('../../../static/notificame/dashboard/sent_messages.svg'),
  scheduled_messages: require('../../../static/notificame/dashboard/scheduled_messages.svg'),
  file_list: require('../../../static/notificame/dashboard/sent_messages.svg'),
  add_contact: require('../../../static/notificame/dashboard/add_file.svg'),
  admin_contacts: require('../../../static/notificame/dashboard/report.svg'),
  report: require('../../../static/notificame/dashboard/report.svg'),
  conversation: require('../../../static/notificame/dashboard/sent_messages.svg'),
  link: require('../../../static/link.svg'),
  help: require('../../../static/help.svg')
};

var TigoDashboard = (_dec = (0, _reactRedux.connect)(null, {
  hideLoading: _reactReduxLoadingBar.hideLoading
}), _dec(_class = function (_React$Component) {
  (0, _inherits3.default)(TigoDashboard, _React$Component);

  function TigoDashboard() {
    (0, _classCallCheck3.default)(this, TigoDashboard);
    return (0, _possibleConstructorReturn3.default)(this, (TigoDashboard.__proto__ || (0, _getPrototypeOf2.default)(TigoDashboard)).apply(this, arguments));
  }

  (0, _createClass3.default)(TigoDashboard, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      this.props.hideLoading();
    }
  }, {
    key: 'render',
    value: function render() {
      var titleStyle = {
        fontSize: '16px',
        margin: '12px 10px',
        fontWeight: '500'
      };

      return _react2.default.createElement(
        'div',
        { className: _Dashboard2.default.tigoDashboard },
        _react2.default.createElement(
          _Allow2.default,
          { permissions: ['transactions:list:outgoing'] },
          _react2.default.createElement(
            'p',
            { style: titleStyle },
            'Mensajes'
          )
        ),
        _react2.default.createElement(
          'div',
          { className: _Dashboard2.default.container },
          _react2.default.createElement(
            _Allow2.default,
            { features: ['sms'], permissions: ['channels:sms:send:file'] },
            _react2.default.createElement(
              _reactRouter.Link,
              { to: '/messages/addFile', className: _Dashboard2.default.widget },
              _react2.default.createElement('div', { className: _Dashboard2.default.icon, dangerouslySetInnerHTML: { __html: icon.add_file } }),
              _react2.default.createElement(
                'div',
                { className: _Dashboard2.default.text },
                (0, _translations2.default)('NEW_MESSAGE_FROM_FILE')
              )
            )
          ),
          _react2.default.createElement(
            _Allow2.default,
            { permissions: ['transactions:list:outgoing'] },
            _react2.default.createElement(
              _reactRouter.Link,
              { to: '/messages/', className: _Dashboard2.default.widget },
              _react2.default.createElement('div', { className: _Dashboard2.default.icon, dangerouslySetInnerHTML: { __html: icon.sent_messages } }),
              _react2.default.createElement(
                'div',
                { className: _Dashboard2.default.text },
                (0, _translations2.default)('MESSAGES_SENT')
              )
            )
          ),
          _react2.default.createElement(
            _Allow2.default,
            { permissions: ['transactions:list:incoming'] },
            _react2.default.createElement(
              _reactRouter.Link,
              { to: '/messages/received', className: _Dashboard2.default.widget },
              _react2.default.createElement('div', { className: _Dashboard2.default.icon, dangerouslySetInnerHTML: { __html: icon.received_messages } }),
              _react2.default.createElement(
                'div',
                { className: _Dashboard2.default.text },
                (0, _translations2.default)('MESSAGES_RECEIVED')
              )
            )
          ),
          _react2.default.createElement(
            _Allow2.default,
            { permissions: ['transactions:scheduled:list'] },
            _react2.default.createElement(
              _reactRouter.Link,
              { to: '/messages/calendar', className: _Dashboard2.default.widget },
              _react2.default.createElement('div', { className: _Dashboard2.default.icon, dangerouslySetInnerHTML: { __html: icon.scheduled_messages } }),
              _react2.default.createElement(
                'div',
                { className: _Dashboard2.default.text },
                'Programados'
              )
            )
          ),
          _react2.default.createElement(
            _Allow2.default,
            { features: ['conversation'] },
            _react2.default.createElement(
              'a',
              { href: '#', className: _Dashboard2.default.widget, onClick: function onClick() {
                  return window.location.replace('/v5/conversations');
                } },
              _react2.default.createElement('div', { className: _Dashboard2.default.icon, dangerouslySetInnerHTML: { __html: icon.conversation } }),
              _react2.default.createElement(
                'div',
                { className: _Dashboard2.default.text },
                'Conversaciones'
              )
            )
          )
        ),
        _react2.default.createElement(
          'div',
          { style: { display: 'flex', gap: '20px' } },
          _react2.default.createElement(
            _Allow2.default,
            { features: ['shortlink'], permissions: ['short:link:list'] },
            _react2.default.createElement(
              'div',
              null,
              _react2.default.createElement(
                'p',
                { style: titleStyle },
                'Enlaces'
              ),
              _react2.default.createElement(
                'div',
                { className: _Dashboard2.default.container },
                _react2.default.createElement(
                  _reactRouter.Link,
                  { to: '/short_link', className: _Dashboard2.default.widget },
                  _react2.default.createElement('div', { className: _Dashboard2.default.icon, dangerouslySetInnerHTML: { __html: icon.link } }),
                  _react2.default.createElement(
                    'div',
                    { className: _Dashboard2.default.text },
                    (0, _translations2.default)('LINKS')
                  )
                )
              )
            )
          ),
          _react2.default.createElement(
            'div',
            null,
            _react2.default.createElement(
              _Allow2.default,
              { permissions: ['transactions:list:outgoing'] },
              _react2.default.createElement(
                'p',
                { style: titleStyle },
                'Archivos'
              )
            ),
            _react2.default.createElement(
              'div',
              { className: _Dashboard2.default.container },
              _react2.default.createElement(
                _Allow2.default,
                { permissions: ['transactions:files:list'] },
                _react2.default.createElement(
                  'a',
                  { href: '#', className: _Dashboard2.default.widget, onClick: function onClick() {
                      return window.location.replace('/v5/messages/files');
                    } },
                  _react2.default.createElement('div', { className: _Dashboard2.default.icon, dangerouslySetInnerHTML: { __html: icon.file_list } }),
                  _react2.default.createElement(
                    'div',
                    { className: _Dashboard2.default.text },
                    'Listado'
                  )
                )
              )
            )
          )
        ),
        _react2.default.createElement(
          'div',
          { style: { display: 'flex', gap: '20px' } },
          _react2.default.createElement(
            'div',
            null,
            _react2.default.createElement(
              _Allow2.default,
              { permissions: ['contacts:list'] },
              _react2.default.createElement(
                'p',
                { style: titleStyle },
                'Clientes'
              )
            ),
            _react2.default.createElement(
              'div',
              { className: _Dashboard2.default.container },
              _react2.default.createElement(
                _Allow2.default,
                { permissions: ['contacts:list'] },
                _react2.default.createElement(
                  _reactRouter.Link,
                  { to: '/contacts', className: _Dashboard2.default.widget },
                  _react2.default.createElement('div', { className: _Dashboard2.default.icon, dangerouslySetInnerHTML: { __html: icon.admin_contacts } }),
                  _react2.default.createElement(
                    'div',
                    { className: _Dashboard2.default.text },
                    (0, _translations2.default)('MANAGE_CONTACTS')
                  )
                )
              ),
              _react2.default.createElement(
                _Allow2.default,
                { permissions: ['contacts:create:file'] },
                _react2.default.createElement(
                  _reactRouter.Link,
                  { to: '/contacts/upload', className: _Dashboard2.default.widget },
                  _react2.default.createElement('div', { className: _Dashboard2.default.icon, dangerouslySetInnerHTML: { __html: icon.add_file } }),
                  _react2.default.createElement(
                    'div',
                    { className: _Dashboard2.default.text },
                    'Agregar clientes por archivo'
                  )
                )
              ),
              _react2.default.createElement(
                _Allow2.default,
                { permissions: ['contacts:create:individual'] },
                _react2.default.createElement(
                  _reactRouter.Link,
                  { to: '/contacts/add', className: _Dashboard2.default.widget },
                  _react2.default.createElement('div', { className: _Dashboard2.default.icon, dangerouslySetInnerHTML: { __html: icon.add_contact } }),
                  _react2.default.createElement(
                    'div',
                    { className: _Dashboard2.default.text },
                    'Agregar cliente'
                  )
                )
              )
            )
          ),
          _react2.default.createElement(
            'div',
            null,
            _react2.default.createElement(
              _Allow2.default,
              { permissions: ['account:reports:balance:view'] },
              _react2.default.createElement(
                'p',
                { style: titleStyle },
                'Reportes'
              )
            ),
            _react2.default.createElement(
              'div',
              { className: _Dashboard2.default.container },
              _react2.default.createElement(
                _Allow2.default,
                { permissions: ['account:reports:balance:view'] },
                _react2.default.createElement(
                  _reactRouter.Link,
                  { to: '/reports', className: _Dashboard2.default.widget },
                  _react2.default.createElement('div', { className: _Dashboard2.default.icon, dangerouslySetInnerHTML: { __html: icon.report } }),
                  _react2.default.createElement(
                    'div',
                    { className: _Dashboard2.default.text },
                    'Generales'
                  )
                )
              ),
              _react2.default.createElement(
                _Allow2.default,
                { permissions: ['account:reports:balance:view'] },
                _react2.default.createElement(
                  _reactRouter.Link,
                  { to: '/reports/dailyHarassment', className: _Dashboard2.default.widget },
                  _react2.default.createElement('div', { className: _Dashboard2.default.icon, dangerouslySetInnerHTML: { __html: icon.report } }),
                  _react2.default.createElement(
                    'div',
                    { className: _Dashboard2.default.text },
                    'De Hostigamiento'
                  )
                )
              )
            )
          )
        ),
        (process.env.REACT_APP_HELP_URL || process.env.REACT_APP_USER_HELP_URL) && _react2.default.createElement(
          'p',
          { style: titleStyle },
          'Ayuda'
        ),
        (process.env.REACT_APP_HELP_URL || process.env.REACT_APP_USER_HELP_URL) && _react2.default.createElement(
          'div',
          { className: _Dashboard2.default.container },
          process.env.REACT_APP_HELP_URL && _react2.default.createElement(
            'div',
            { className: _Dashboard2.default.widget, onClick: function onClick() {
                return window.open(process.env.REACT_APP_HELP_URL, '_blank');
              }, style: { cursor: 'pointer' } },
            _react2.default.createElement('div', { className: _Dashboard2.default.icon, dangerouslySetInnerHTML: { __html: icon.help } }),
            _react2.default.createElement(
              'div',
              { className: _Dashboard2.default.text },
              'Manual de t\xE9cnico'
            )
          ),
          process.env.REACT_APP_USER_HELP_URL && _react2.default.createElement(
            'div',
            { className: _Dashboard2.default.widget, onClick: function onClick() {
                return window.open(process.env.REACT_APP_USER_HELP_URL, '_blank');
              }, style: { cursor: 'pointer' } },
            _react2.default.createElement('div', { className: _Dashboard2.default.icon, dangerouslySetInnerHTML: { __html: icon.help } }),
            _react2.default.createElement(
              'div',
              { className: _Dashboard2.default.text },
              'Manual usuario'
            )
          )
        )
      );
    }
  }]);
  return TigoDashboard;
}(_react2.default.Component)) || _class);
exports.default = TigoDashboard;
module.exports = exports['default'];