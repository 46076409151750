'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _dec2, _class, _class2, _temp;

var _interaction = require('redux/modules/interaction');

var _components = require('components');

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _reduxConnect = require('redux-connect');

var _reactRedux = require('react-redux');

var _reachHistory = require('../../helpers/reachHistory');

var _studies = require('redux/modules/studies');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Schedule = (_dec = (0, _reduxConnect.asyncConnect)([{
  key: 'interaction',
  promise: function promise(_ref) {
    var query = _ref.location.query,
        _ref$store = _ref.store,
        dispatch = _ref$store.dispatch,
        getState = _ref$store.getState;

    var state = getState();
    var uid = query.uid;

    if (uid) {
      if (!state.interaction || state.interaction.uid !== uid) {
        return dispatch((0, _interaction.getInteraction)(uid));
      }
    } else {
      return dispatch((0, _reachHistory.push)('/studies/'));
    }
  }
}]), _dec2 = (0, _reactRedux.connect)(function (state) {
  return {
    mobileMode: state.mobile.mobileMode,
    interaction: state.interaction
  };
}, { requestStart: _interaction.requestStart, requestSave: _interaction.requestSave, pushState: _reachHistory.push, studiesRefresh: _studies.studiesRefresh, updateInteraction: _interaction.updateInteraction }), _dec(_class = _dec2(_class = (_temp = _class2 = function (_React$Component) {
  (0, _inherits3.default)(Schedule, _React$Component);

  function Schedule() {
    (0, _classCallCheck3.default)(this, Schedule);
    return (0, _possibleConstructorReturn3.default)(this, (Schedule.__proto__ || (0, _getPrototypeOf2.default)(Schedule)).apply(this, arguments));
  }

  (0, _createClass3.default)(Schedule, [{
    key: 'componentWillUnmount',
    value: function componentWillUnmount() {
      this.props.studiesRefresh();
    }
  }, {
    key: 'start',
    value: function start() {
      var _this2 = this;

      if (!this.props.interaction.loading) {
        return this.props.requestSave(this.props.interaction, 'STUDY').then(function (interaction) {
          return _this2.props.requestStart(interaction._id).then(function (startedInteraction) {
            var sendingChannels = startedInteraction.configuration.sending_channels;
            if (sendingChannels.indexOf('FACEBOOK') > -1) {
              _this2.props.pushState({
                pathname: '/success',
                state: { goTo: '/studies/share/',
                  goToQuery: { uid: startedInteraction._id, social: 'FACEBOOK' },
                  message: (0, _translations2.default)('STUDY SCHEDULING SUCCESS'), goToModal: !_this2.props.mobileMode,
                  modal: !_this2.props.mobileMode, returnTo: '/studies/',
                  modalSize: { width: '500px', height: '500px' }
                }
              });
            } else if (sendingChannels.indexOf('TWITTER') > -1) {
              _this2.props.pushState({
                pathname: '/success',
                state: { goTo: '/studies/share/',
                  goToQuery: { uid: startedInteraction._id, social: 'TWITTER' },
                  message: (0, _translations2.default)('STUDY SCHEDULING SUCCESS'), goToModal: !_this2.props.mobileMode,
                  modal: !_this2.props.mobileMode, returnTo: '/studies/',
                  modalSize: { width: '500px', height: '500px' }
                }
              });
            } else {
              _this2.props.pushState({
                pathname: '/success',
                state: { goTo: '/studies/', message: (0, _translations2.default)('STUDY SCHEDULING SUCCESS'), goToModal: false,
                  modal: !_this2.props.mobileMode, returnTo: '/studies/',
                  modalSize: { width: '500px', height: '500px' }
                }
              });
            }
          }).catch(function (error) {
            return console.error(error);
          });
        });
      }
    }
  }, {
    key: 'handleDateChange',
    value: function handleDateChange(e) {
      this.props.updateInteraction('scheduled_for', e.format());
    }
  }, {
    key: 'render',
    value: function render() {
      var styles = require('./Study.scss');

      return _react2.default.createElement(
        'div',
        { className: styles.schedule },
        _react2.default.createElement(_components.Datepicker, { onChange: this.handleDateChange.bind(this) }),
        _react2.default.createElement(
          'div',
          { className: styles.button, onClick: this.start.bind(this) },
          (0, _translations2.default)('BEGIN STUDY AT THIS DATE')
        )
      );
    }
  }]);
  return Schedule;
}(_react2.default.Component), _class2.propTypes = {
  mobileMode: _propTypes2.default.bool.isRequired,
  interaction: _propTypes2.default.object.isRequired,
  updateInteraction: _propTypes2.default.func.isRequired,
  requestStart: _propTypes2.default.func.isRequired,
  requestSave: _propTypes2.default.func.isRequired,
  pushState: _propTypes2.default.func.isRequired,
  studiesRefresh: _propTypes2.default.func.isRequired
}, _temp)) || _class) || _class);
exports.default = Schedule;
module.exports = exports['default'];