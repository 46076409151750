'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var styles = require('./Tags.scss');

var md = {
  more: require('react-icons/lib/md/more-vert')
};

var SimpleTagList = function SimpleTagList(props) {
  return _react2.default.createElement(
    'div',
    { className: styles.simpleTags },
    props.tags.slice(0, props.numTags).map(function (tag, index) {
      if (tag && typeof tag === 'string') {
        return _react2.default.createElement(
          'div',
          { key: index, className: props.longWidth ? styles.simpleTagLong : styles.simpleTag, onClick: props.onClick ? props.onClick.bind(null, tag) : null },
          _react2.default.createElement(
            'span',
            { title: tag },
            tag
          )
        );
      }
      return null;
    }),
    props.tags.length > props.numTags ? _react2.default.createElement(
      'div',
      { className: styles.simpleTagsSub },
      _react2.default.createElement(
        'div',
        { className: styles.simpleTagsMore },
        props.summary || _react2.default.createElement(md.more, { size: 14 })
      ),
      _react2.default.createElement(
        'div',
        { className: styles.simpleTagsDrop },
        props.tags.slice(props.numTags).map(function (tag, index) {
          return typeof tag === 'string' ? _react2.default.createElement(
            'span',
            { key: index, className: styles.simpleTagSub, onClick: props.onClick ? props.onClick.bind(null, tag) : null },
            tag
          ) : null;
        })
      )
    ) : null
  );
};

SimpleTagList.propTypes = {
  tags: _propTypes2.default.array.isRequired,
  numTags: _propTypes2.default.number.isRequired,
  onClick: _propTypes2.default.func,
  longWidth: _propTypes2.default.bool
};

exports.default = SimpleTagList;
module.exports = exports['default'];