'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NewContactSelector = exports.default = undefined;

var _getOwnPropertyDescriptor = require('babel-runtime/core-js/object/get-own-property-descriptor');

var _getOwnPropertyDescriptor2 = _interopRequireDefault(_getOwnPropertyDescriptor);

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _dec2, _class, _desc, _value, _class2, _class3, _temp, _dec3, _class4, _class5, _temp2;

var _baseui = require('baseui');

var _components = require('components');

var _reduxForm = require('redux-form');

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _contact = require('redux/modules/contact');

var _themeEnabled = require('helpers/themeEnabled');

var _Allow = require('../Allow/Allow');

var _Allow2 = _interopRequireDefault(_Allow);

var _reactHelmet = require('react-helmet');

var _reactHelmet2 = _interopRequireDefault(_reactHelmet);

var _reactRouter = require('react-router');

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _styletronEngineAtomic = require('styletron-engine-atomic');

var _styletronReact = require('styletron-react');

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _reduxConnect = require('redux-connect');

var _coreDecorators = require('core-decorators');

var _reactRedux = require('react-redux');

var _contacts = require('redux/modules/contacts');

var _account = require('redux/modules/account');

var _reachHistory = require('../../helpers/reachHistory');

var _StyledPhoneInput = require('../../components/StyledPhoneInput/StyledPhoneInput');

var _StyledPhoneInput2 = _interopRequireDefault(_StyledPhoneInput);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
  var desc = {};
  Object['ke' + 'ys'](descriptor).forEach(function (key) {
    desc[key] = descriptor[key];
  });
  desc.enumerable = !!desc.enumerable;
  desc.configurable = !!desc.configurable;

  if ('value' in desc || desc.initializer) {
    desc.writable = true;
  }

  desc = decorators.slice().reverse().reduce(function (desc, decorator) {
    return decorator(target, property, desc) || desc;
  }, desc);

  if (context && desc.initializer !== void 0) {
    desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
    desc.initializer = undefined;
  }

  if (desc.initializer === void 0) {
    Object['define' + 'Property'](target, property, desc);
    desc = null;
  }

  return desc;
}

var md = {
  send: require('react-icons/lib/md/send'),
  arrowBack: require('react-icons/lib/md/arrow-back'),
  person: require('react-icons/lib/md/person'),
  file: require('react-icons/lib/md/insert-drive-file')
};

var AddContact = (_dec = (0, _reduxConnect.asyncConnect)([{
  key: 'account',
  promise: function promise(_ref) {
    var _ref$store = _ref.store,
        dispatch = _ref$store.dispatch,
        getState = _ref$store.getState;

    var state = getState();
    if (!state.account.data.account_id) {
      return dispatch((0, _account.getAccount)());
    }
    return null;
  }
}]), _dec2 = (0, _reactRedux.connect)(function (state) {
  return {
    mobileMode: state.mobile.mobileMode,
    contact: state.contact,
    account: state.account.data
  };
}, {
  getAccount: _account.getAccount,
  createContact: _contact.createContact,
  push: _reachHistory.push,
  clearContact: _contact.clearContact,
  contactsRefresh: _contacts.contactsRefresh
}), _dec(_class = _dec2(_class = (_class2 = (_temp = _class3 = function (_React$Component) {
  (0, _inherits3.default)(AddContact, _React$Component);

  function AddContact(props) {
    (0, _classCallCheck3.default)(this, AddContact);

    var _this = (0, _possibleConstructorReturn3.default)(this, (AddContact.__proto__ || (0, _getPrototypeOf2.default)(AddContact)).call(this, props));

    _this.style = require('./Contact.scss');
    _this.state = {
      status: 'PRISTINE',
      monitoring: false,
      msisdn: '',
      incorrectMsisdn: false,
      countries: [],
      dialCode: ''
    };
    return _this;
  }

  (0, _createClass3.default)(AddContact, [{
    key: 'componentWillMount',
    value: function componentWillMount() {
      this.props.clearContact();
    }
  }, {
    key: 'componentDidMount',
    value: function componentDidMount() {
      var _this2 = this;

      this.props.getAccount().then(function (account) {
        if (account && account.available_countries && account.available_countries.length >= 1) {
          _this2.setState({
            account: account,
            countries: account.available_countries
          });
        }
      });
    }
  }, {
    key: 'validateEmail',
    value: function validateEmail(email) {
      return (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/.test(email)
      );
    }
  }, {
    key: 'validateText',
    value: function validateText(text) {
      return (/^[\w ]+$/.test(text)
      );
    }
  }, {
    key: 'handleSubmit',
    value: function handleSubmit(reset, form) {
      var _this3 = this;

      if (form.email == null && this.state.msisdn == "") {
        this.setState((0, _extends3.default)({}, this.state, {
          status: 'ERROR',
          errorMessage: (0, _translations2.default)('WRONG DATA')
        }));
        throw new _reduxForm.SubmissionError({ _error: (0, _translations2.default)('WRONG DATA') });
      }

      if (form.email) {
        if (!this.validateEmail(form.email)) {
          this.setState((0, _extends3.default)({}, this.state, {
            status: 'ERROR',
            errorMessage: (0, _translations2.default)('WRONG EMAIL')
          }));
          throw new _reduxForm.SubmissionError({ _error: (0, _translations2.default)('WRONG EMAIL') });
        }
      }

      this.setState((0, _extends3.default)({}, this.state, {
        status: 'OK'
      }));

      var variables = [];

      if (form.gender) {
        variables.push({
          key: 'gender',
          value: form.gender
        });
      }

      if (form.name) {
        variables.push({
          key: 'name',
          value: form.name
        });
      }

      if (form.last_name) {
        variables.push({
          key: 'last_name',
          value: form.last_name
        });
      }

      if (form.email) {
        variables.push({
          key: 'email',
          value: form.email
        });
      }

      var country_code = this.state.dialCode;
      var msisdn = this.state.msisdn;

      this.props.createContact(form, variables, country_code, msisdn).then(function (contact) {
        _this3.props.contactsRefresh();
        _this3.props.push({
          pathname: '/success',
          state: {
            goTo: '/contacts/' + contact.profile_uid,
            goToModal: !_this3.props.mobileMode,
            message: (0, _translations2.default)('CONTACT ADD SUCCESS'),
            modal: true,
            returnTo: '/contacts/'
          }
        });
        reset();
      }).catch(function (response) {
        _this3.setState((0, _extends3.default)({}, _this3.state, {
          incorrectMsisdn: true,
          status: 'ERROR',
          errorMessage: response.message
        }));
      });
    }
  }, {
    key: 'updateMsisdn',
    value: function updateMsisdn(value, country, e, formattedValue) {
      var dialCode = country.dialCode;
      var msisdn = value;
      this.setState({ msisdn: msisdn, incorrectMsisdn: false, dialCode: dialCode });
    }
  }, {
    key: 'updateCountry',
    value: function updateCountry(e) {
      var country = e.option;
      this.setState({ country: country });
    }
  }, {
    key: 'render',
    value: function render() {
      var styles = this.style;
      var classesTheme = (0, _themeEnabled.getThemeEnabledStyle)(styles);
      return _react2.default.createElement(
        'div',
        { className: classesTheme },
        _react2.default.createElement(
          'div',
          { className: styles.container_new },
          _react2.default.createElement(_reactHelmet2.default, { title: "Agregar contacto" }),
          _react2.default.createElement(_components.MobileBar, { returnTo: '/contacts/' }),
          _react2.default.createElement(
            'div',
            { className: styles.container_flex },
            _react2.default.createElement(
              'div',
              { className: styles.center_horizontal },
              this.state.status === 'ERROR' ? _react2.default.createElement(
                'span',
                { className: styles.error },
                this.state.errorMessage
              ) : null,
              this.state.countries.length > 0 ? _react2.default.createElement(NewContactForm, {
                createContact: this.handleSubmit, contact: this.props.contact,
                msisdn: this.state.msisdn, incorrectMsisdn: this.state.incorrectMsisdn,
                updateMsisdn: this.updateMsisdn, updateCountry: this.updateCountry,
                countries: this.state.countries,
                dialCode: this.state.dialCode
              }) : null
            )
          )
        )
      );
    }
  }]);
  return AddContact;
}(_react2.default.Component), _class3.propTypes = {
  getAccount: _propTypes2.default.func.isRequired,
  createContact: _propTypes2.default.func.isRequired,
  push: _propTypes2.default.func.isRequired,
  mobileMode: _propTypes2.default.bool.isRequired,
  contact: _propTypes2.default.object.isRequired,
  clearContact: _propTypes2.default.func.isRequired,
  contactsRefresh: _propTypes2.default.func.isRequired
}, _temp), (_applyDecoratedDescriptor(_class2.prototype, 'handleSubmit', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'handleSubmit'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, 'updateMsisdn', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'updateMsisdn'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, 'updateCountry', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'updateCountry'), _class2.prototype)), _class2)) || _class) || _class);
exports.default = AddContact;


var NewContactFormContainer = function NewContactFormContainer(props) {
  // TODO: Move to own component?
  var handleSubmit = props.handleSubmit,
      createContact = props.createContact,
      reset = props.reset,
      incorrectMsisdn = props.incorrectMsisdn,
      msisdn = props.msisdn,
      countries = props.countries,
      updateMsisdn = props.updateMsisdn,
      updateCountry = props.updateCountry;

  var styles = require('./Contact.scss');
  var engine = new _styletronEngineAtomic.Client();
  var objAsset = (0, _themeEnabled.getAssetStyle)();
  var illustrationTheme = objAsset.form;
  return _react2.default.createElement(
    'div',
    { className: styles.main },
    _react2.default.createElement(
      'form',
      { onSubmit: handleSubmit(createContact.bind(undefined, reset)), className: styles.compose },
      _react2.default.createElement(
        'label',
        { className: styles.label, htmlFor: 'msisdn' },
        (0, _translations2.default)('PHONE NUMBER'),
        ':'
      ),
      _react2.default.createElement(_StyledPhoneInput2.default, {
        countries: countries,
        value: msisdn,
        onChange: updateMsisdn,
        placeholder: (0, _translations2.default)('MSISDN PLACEHOLDER'),
        error: incorrectMsisdn,
        isAddContact: true
      }),
      _react2.default.createElement(
        'label',
        { className: styles.label, htmlFor: 'email' },
        (0, _translations2.default)('EMAIL'),
        ':'
      ),
      _react2.default.createElement(_reduxForm.Field, { id: 'email', name: 'email', className: styles.message, component: 'input', type: 'email', placeholder: (0, _translations2.default)('EMAIL PLACEHOLDER') }),
      _react2.default.createElement(
        'span',
        { className: styles.countryCode },
        (0, _translations2.default)('INCLUDE EMAIL')
      ),
      _react2.default.createElement(
        'label',
        { className: styles.label, htmlFor: 'name' },
        (0, _translations2.default)('NAME'),
        ':'
      ),
      _react2.default.createElement(_reduxForm.Field, { id: 'name', name: 'name', className: styles.message, component: 'input', type: 'text', placeholder: (0, _translations2.default)('NAME PLACEHOLDER') }),
      _react2.default.createElement(
        'label',
        { className: styles.label, htmlFor: 'last_name' },
        (0, _translations2.default)('LAST NAME'),
        ':'
      ),
      _react2.default.createElement(_reduxForm.Field, { id: 'last_name', name: 'last_name', className: styles.message, component: 'input', type: 'text', placeholder: (0, _translations2.default)('LASTNAME PLACEHOLDER') }),
      _react2.default.createElement(
        'label',
        { className: styles.label, htmlFor: 'gender' },
        (0, _translations2.default)('GENDER'),
        ':'
      ),
      _react2.default.createElement(
        _reduxForm.Field,
        { id: 'gender', name: 'gender', component: 'select', className: styles.message },
        _react2.default.createElement(
          'option',
          { value: '' },
          (0, _translations2.default)('GENDER PLACEHOLDER')
        ),
        _react2.default.createElement(
          'option',
          { value: 'hombre' },
          (0, _translations2.default)('MALE')
        ),
        _react2.default.createElement(
          'option',
          { value: 'mujer' },
          (0, _translations2.default)('FEMALE')
        )
      ),
      _react2.default.createElement(
        'label',
        { className: styles.label, htmlFor: 'monitoring' },
        (0, _translations2.default)('MONITORING'),
        ':'
      ),
      _react2.default.createElement(_reduxForm.Field, { id: 'monitoring', name: 'monitoring', component: 'input', className: styles.check, type: 'checkbox' }),
      props.contact.loading ? _react2.default.createElement(
        'div',
        { className: styles.createLoading },
        (0, _translations2.default)('CREATING CONTACT'),
        '...'
      ) : _react2.default.createElement(
        'button',
        { type: 'submit', className: styles.create },
        (0, _translations2.default)('CREATE NEW CONTACT')
      )
    ),
    _react2.default.createElement('img', { className: styles.image, src: illustrationTheme })
  );
};

NewContactFormContainer.propTypes = {
  reset: _propTypes2.default.func.isRequired,
  createContact: _propTypes2.default.func.isRequired,
  handleSubmit: _propTypes2.default.func.isRequired,
  contact: _propTypes2.default.object.isRequired
};

var NewContactForm = (0, _reduxForm.reduxForm)({
  form: 'chat'
})(NewContactFormContainer);

var NewContactSelector = exports.NewContactSelector = (_dec3 = (0, _reactRedux.connect)(function (state) {
  return { mobileMode: state.mobile.mobileMode, contact: state.contact };
}), _dec3(_class4 = (_temp2 = _class5 = function (_React$Component2) {
  (0, _inherits3.default)(NewContactSelector, _React$Component2);

  function NewContactSelector() {
    (0, _classCallCheck3.default)(this, NewContactSelector);
    return (0, _possibleConstructorReturn3.default)(this, (NewContactSelector.__proto__ || (0, _getPrototypeOf2.default)(NewContactSelector)).apply(this, arguments));
  }

  (0, _createClass3.default)(NewContactSelector, [{
    key: 'render',
    value: function render() {
      var styles = require('./Contact.scss');

      return _react2.default.createElement(
        'div',
        { className: styles.buttonWrapper },
        _react2.default.createElement(_components.MobileBar, { returnTo: '/contacts/' }),
        _react2.default.createElement(
          _components.ButtonModal,
          null,
          _react2.default.createElement(
            'h1',
            null,
            (0, _translations2.default)('ADD CONTACTS PROMPT')
          ),
          _react2.default.createElement(
            _Allow2.default,
            { permissions: ['contacts:create:individual'] },
            _react2.default.createElement(
              _reactRouter.Link,
              {
                className: 'action',
                to: {
                  pathname: '/contacts/add',
                  state: { modal: true, returnTo: '/contacts/', modalSize: { width: '412px', height: '522px' } }
                }
              },
              _react2.default.createElement(md.person, { size: 30, color: '#616161' }),
              _react2.default.createElement(
                'h2',
                null,
                (0, _translations2.default)('ADD CONTACT SINGLE')
              )
            )
          ),
          _react2.default.createElement(
            _Allow2.default,
            { permissions: ['contacts:create:file'] },
            _react2.default.createElement(
              _reactRouter.Link,
              {
                className: 'action',
                to: {
                  pathname: '/contacts/upload',
                  state: { modal: true, returnTo: '/contacts/', modalSize: { width: '830px', height: '500px' } }
                }
              },
              _react2.default.createElement(md.file, { size: 30, color: '#616161' }),
              _react2.default.createElement(
                'h2',
                null,
                (0, _translations2.default)('ADD CONTACT FILE')
              )
            )
          )
        )
      );
    }
  }]);
  return NewContactSelector;
}(_react2.default.Component), _class5.propTypes = {
  mobileMode: _propTypes2.default.bool.isRequired
}, _temp2)) || _class4);