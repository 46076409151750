'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _stringify = require('babel-runtime/core-js/json/stringify');

var _stringify2 = _interopRequireDefault(_stringify);

var _promise = require('babel-runtime/core-js/promise');

var _promise2 = _interopRequireDefault(_promise);

var _superagent = require('superagent');

var _superagent2 = _interopRequireDefault(_superagent);

var _config = require('../config');

var _config2 = _interopRequireDefault(_config);

var _SecureSignature = require('./SecureSignature');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

require('superagent-django-csrf');

function formatUrl(apiPath, path) {
  var adjustedPath = path[0] !== '/' ? '/' + path : path;
  if (__SERVER__) {
    // Prepend host and port of the API server to the path.
    return 'http://' + _config2.default.apiHost + ':' + _config2.default.apiPort + adjustedPath;
  }
  // Prepend `/api` to relative URL, to proxy to API server.
  return apiPath + adjustedPath;
}

var callMethod = function callMethod(apiPath, method, req, res, path) {
  var _ref = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : {},
      params = _ref.params,
      data = _ref.data,
      timeout = _ref.timeout;

  return new _promise2.default(function (resolve, reject) {
    var url = formatUrl(apiPath, path);
    var request = _superagent2.default[method](url);
    if (params) {
      request.query(params);
    }

    if (__SERVER__ && req.get('cookie')) {
      request.set('cookie', req.get('cookie'));
    }

    if (data) {
      request.send(data);
    }

    if (timeout) {
      request.timeout({ response: timeout });
    }

    console.log('[SUPERAGENT][REQUEST]', method.toUpperCase(), url);
    console.log('[SUPERAGENT][REQUEST]', 'path:', path);
    console.log('query:', params);
    console.log('data:', data);
    console.log('timeout:', timeout);

    return request.end(function (err) {
      var _ref2 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
          status = _ref2.status,
          body = _ref2.body,
          header = _ref2.header;

      console.log('[SUPERAGENT][RESPONSE]', method.toUpperCase(), url);
      if (__SERVER__ && header && header['set-cookie']) {
        res.append('set-cookie', header['set-cookie'][0]);
      }
      console.log('status:', status);
      if (err) {
        console.log('err:', err);
      }

      if (status == 401) {
        var redirect = body.redirectUrl ? body.redirectUrl : './';
        window.localStorage.setItem("afterCheckPage", window.location.href);
        window.localStorage.setItem("account", body.account);
        window.location = redirect;
      }

      console.log('body:', body);

      if (path == '/login/' && (method == 'post' || method == 'get') || path == '/changeaccount/') {

        var result = body;
        delete result.csrf;

        var contentString = (0, _stringify2.default)(result);
        var hmac = (0, _SecureSignature.makeDigestHmac)(contentString, header['x-date'], process.env.REACT_APP_SECRET_KEY_HMAC);
        var hmac_bff = header['x-signature'];

        if (hmac == hmac_bff) {
          return resolve(body);
        } else {
          return reject('Error');
        }
      }

      return err ? reject(body || err) : resolve(body);
    });
  });
};

var ApiClient = function () {
  function ApiClient(apiPath, req, res) {
    (0, _classCallCheck3.default)(this, ApiClient);

    this.get = callMethod.bind(null, apiPath, 'get', req, res);
    this.post = callMethod.bind(null, apiPath, 'post', req, res);
    this.put = callMethod.bind(null, apiPath, 'put', req, res);
    this.patch = callMethod.bind(null, apiPath, 'patch', req, res);
    this.del = callMethod.bind(null, apiPath, 'del', req, res);
  }

  (0, _createClass3.default)(ApiClient, [{
    key: 'empty',
    value: function empty() {}
  }]);
  return ApiClient;
}();

exports.default = ApiClient;
module.exports = exports['default'];