'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _getOwnPropertyDescriptor = require('babel-runtime/core-js/object/get-own-property-descriptor');

var _getOwnPropertyDescriptor2 = _interopRequireDefault(_getOwnPropertyDescriptor);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _class, _desc, _value, _class2;

var _reactHelmet = require('react-helmet');

var _reactHelmet2 = _interopRequireDefault(_reactHelmet);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _components = require('components');

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _coreDecorators = require('core-decorators');

var _reactRedux = require('react-redux');

var _schedulerTasks = require('redux/modules/schedulerTasks');

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _reachHistory = require('../../helpers/reachHistory');

var _Message = require('./Message.scss');

var _Message2 = _interopRequireDefault(_Message);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
  var desc = {};
  Object['ke' + 'ys'](descriptor).forEach(function (key) {
    desc[key] = descriptor[key];
  });
  desc.enumerable = !!desc.enumerable;
  desc.configurable = !!desc.configurable;

  if ('value' in desc || desc.initializer) {
    desc.writable = true;
  }

  desc = decorators.slice().reverse().reduce(function (desc, decorator) {
    return decorator(target, property, desc) || desc;
  }, desc);

  if (context && desc.initializer !== void 0) {
    desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
    desc.initializer = undefined;
  }

  if (desc.initializer === void 0) {
    Object['define' + 'Property'](target, property, desc);
    desc = null;
  }

  return desc;
}

var md = {
  arrowBack: require('react-icons/lib/md/arrow-back')
};

var ScheduledDetail = (_dec = (0, _reactRedux.connect)(null, {
  push: _reachHistory.push,
  deleteTask: _schedulerTasks.deleteTask
}), _dec(_class = (_class2 = function (_React$Component) {
  (0, _inherits3.default)(ScheduledDetail, _React$Component);

  function ScheduledDetail() {
    (0, _classCallCheck3.default)(this, ScheduledDetail);
    return (0, _possibleConstructorReturn3.default)(this, (ScheduledDetail.__proto__ || (0, _getPrototypeOf2.default)(ScheduledDetail)).apply(this, arguments));
  }

  (0, _createClass3.default)(ScheduledDetail, [{
    key: 'handleStop',
    value: function handleStop() {
      var _this2 = this;

      var detail = this.props.location.state.detail;

      this.props.deleteTask(detail.taskUid, (0, _moment2.default)(detail.start).toISOString(), detail.eventId).then(function () {
        _this2.props.push({
          pathname: '/success',
          state: { goTo: '/messages/', goToModal: false,
            message: (0, _translations2.default)('SCHEDULE DELETE SUCCESS'),
            modal: true, returnTo: '/messages/' }
        });
      }).catch(function (e) {
        console.error('Could not delete message', e);
        _this2.props.push({
          pathname: '/fail',
          state: { goTo: '/messages/', goToModal: false,
            message: e.message,
            modal: true, returnTo: '/messages/' }
        });
      });
    }
  }, {
    key: 'render',
    value: function render() {
      var detail = this.props.location.state.detail;


      return _react2.default.createElement(
        'div',
        { className: _Message2.default.confirmationContainer },
        _react2.default.createElement(_reactHelmet2.default, { title: "Detalle de mensaje calendarizado" }),
        _react2.default.createElement(
          'div',
          { className: _Message2.default.bar },
          _react2.default.createElement(
            'div',
            { className: _Message2.default.icon, onClick: this.props.router.goBack },
            _react2.default.createElement(md.arrowBack, { size: 20, color: '#FFF' })
          )
        ),
        _react2.default.createElement(
          'h1',
          { className: _Message2.default.messageTitle },
          (0, _translations2.default)('SCHEDULED MESSAGE'),
          ': ',
          detail.title
        ),
        _react2.default.createElement(
          'div',
          null,
          _react2.default.createElement(
            'div',
            null,
            _react2.default.createElement(
              'div',
              { className: _Message2.default.messageBefore },
              (0, _translations2.default)('SCHEDULED START')
            ),
            ' ',
            _react2.default.createElement(
              'div',
              { className: _Message2.default.messageDetail },
              (0, _moment2.default)(detail.start).format('DD/MM HH:mm')
            )
          ),
          _react2.default.createElement(
            'div',
            null,
            _react2.default.createElement(
              'div',
              { className: _Message2.default.messageBefore },
              (0, _translations2.default)('SCHEDULED END')
            ),
            ' ',
            _react2.default.createElement(
              'div',
              { className: _Message2.default.messageDetail },
              (0, _moment2.default)(detail.end).format('DD/MM HH:mm')
            )
          ),
          _react2.default.createElement(
            'div',
            null,
            _react2.default.createElement(
              'div',
              { className: _Message2.default.messageBefore },
              (0, _translations2.default)('SCHEDULED STATUS')
            ),
            ' ',
            _react2.default.createElement(
              'div',
              { className: _Message2.default.messageDetail },
              (0, _translations2.default)(detail.status)
            )
          )
        ),
        detail.status !== 'EXECUTED' ? _react2.default.createElement(
          'div',
          { className: _Message2.default.footer },
          _react2.default.createElement(
            'button',
            { onClick: this.handleStop, className: _Message2.default.readyButton },
            (0, _translations2.default)('SCHEDULED STOP')
          )
        ) : null
      );
    }
  }]);
  return ScheduledDetail;
}(_react2.default.Component), (_applyDecoratedDescriptor(_class2.prototype, 'handleStop', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'handleStop'), _class2.prototype)), _class2)) || _class);
exports.default = ScheduledDetail;
module.exports = exports['default'];