'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.actions = undefined;
exports.default = reducer;
exports.getPromotions = getPromotions;
exports.selectPromotions = selectPromotions;
exports.unselectPromotions = unselectPromotions;
exports.unselectAllPromotions = unselectAllPromotions;
exports.trimPromotions = trimPromotions;
exports.refreshPromotions = refreshPromotions;
exports.createPromotions = createPromotions;
exports.deletePromotions = deletePromotions;
exports.updatePromotions = updatePromotions;
exports.updatePromotionStatus = updatePromotionStatus;
exports.getPromotionStats = getPromotionStats;

var _apiAction = require('../apiAction');

var _apiAction2 = _interopRequireDefault(_apiAction);

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var actions = exports.actions = new _apiAction2.default('PROMOTIONS', [_apiAction.ACTION_TYPES.DATA, _apiAction.ACTION_TYPES.APPEND, _apiAction.ACTION_TYPES.TRIM, _apiAction.ACTION_TYPES.SELECT]);

var initialState = {
    loading: false,
    status: actions.APPEND,
    list: [],
    selected: [],
    data: {}
};

function reducer() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
    var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    var response = actions.getStateForAction(state, action);
    var promotion = [];
    if (response.status === "PROMOTIONS_APPEND_SUCCESS") {
        promotion = response.list.map(function (promo) {
            if (promo.status === 'ACTIVE' && (0, _moment2.default)().isAfter((0, _moment2.default)(promo.end_date))) {
                promo.status = "COMPLETE";
            }
            return promo;
        });
        response.list = promotion;
    }
    return response;
}

function getPromotions(states) {
    return {
        types: [actions.APPEND, actions.APPEND_SUCCESS, actions.APPEND_FAIL],
        promise: function promise(client) {
            return client.get('/promotion', { states: states });
        }
    };
}

function selectPromotions(index) {
    return {
        type: actions.SELECT,
        index: index
    };
}

function unselectPromotions(index) {
    return {
        type: actions.SELECT_OFF,
        index: index
    };
}

function unselectAllPromotions() {
    return {
        type: actions.SELECT_NONE
    };
}

function trimPromotions(count) {
    return {
        type: actions.TRIM,
        count: count
    };
}

function refreshPromotions() {
    return {
        type: actions.TRIM,
        count: 0
    };
}

function createPromotions(data) {

    var startDate = new Date(data.start_date);
    var endDate = new Date(data.end_date);

    data.start_date = _moment2.default.utc(startDate).format();
    data.end_date = _moment2.default.utc(endDate).format();

    return {
        types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
        promise: function promise(client) {
            return client.post('/promotion', { data: data });
        }
    };
}

function deletePromotions(promotion_uid) {
    return {
        types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
        promise: function promise(client) {
            return client.del('/promotion', {
                params: { promotion_uid: promotion_uid }
            });
        }
    };
}

function updatePromotions(params, data) {
    var start = void 0,
        end = '';
    if (data.start_date) {
        start = (0, _moment2.default)(data.start_date).format();
        end = (0, _moment2.default)(data.end_date).format();
        data.start_date = start;
        data.end_date = end;
    }
    return {
        types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
        promise: function promise(client) {
            return client.put('/promotion', {
                params: params,
                data: data
            });
        }
    };
}

function updatePromotionStatus(params, status) {
    return {
        types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
        promise: function promise(client) {
            return client.put('/promotion/' + status, {
                params: params
            });
        }
    };
}

function getPromotionStats(params) {
    return {
        types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
        promise: function promise(client) {
            return client.get('promotion/stats', {
                params: params
            });
        }
    };
}