'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actions = undefined;
exports.default = reducer;
exports.getAccountStatus = getAccountStatus;
exports.getCredentials = getCredentials;
exports.updateCredentials = updateCredentials;

var _apiAction = require('../apiAction');

var _apiAction2 = _interopRequireDefault(_apiAction);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// ACTIONS


var actions = exports.actions = new _apiAction2.default('ACCOUNT_STATUS', [_apiAction.ACTION_TYPES.DATA]);

// REDUCER


var initialState = {
  loading: false,
  status: actions.DATA,
  data: {}
};

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  return actions.getStateForAction(state, action);
}

// ACTION CREATORS

function getAccountStatus() {
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.get('/account/status');
    }
  };
}

function getCredentials() {
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.get('/account/credentials');
    }
  };
}

function updateCredentials() {
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.put('/account/change_credentials');
    }
  };
}