'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _values = require('babel-runtime/core-js/object/values');

var _values2 = _interopRequireDefault(_values);

var _keys = require('babel-runtime/core-js/object/keys');

var _keys2 = _interopRequireDefault(_keys);

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

exports.ExternalLinkList = ExternalLinkList;
exports.generateCSV = generateCSV;

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ExternalLinkList() {
  var result = {
    base_url: "",
    header_img: url + 'logo.png',
    commerce_url: 'tuempresa',
    tigotab: '',
    amazon_s3: 'http://reach-public.s3.amazonaws.com',
    example_api: 'http://example.com/api',
    url_tutoriales: ''

  };
  return result;
}

function generateCSV(tags, phoneNumber) {
  // Crear la cabecera del CSV con los nombres de las tags
  var header = ['telefono'].concat((0, _toConsumableArray3.default)((0, _keys2.default)(tags))).join(',');

  // Crear la segunda fila con el número de teléfono y los valores de muestra de las tags
  var secondRow = [phoneNumber].concat((0, _toConsumableArray3.default)((0, _values2.default)(tags).map(function (tag) {
    return tag.sample;
  }))).join(',');

  // Unir la cabecera y la segunda fila para formar el CSV
  var csv = [header, secondRow].join('\n');

  // Crear un Blob con los datos del CSV
  var csvBlob = new Blob([csv], { type: 'text/csv' });

  // Crear un enlace para descargar el archivo CSV
  var url = URL.createObjectURL(csvBlob);
  var link = document.createElement('a');
  link.href = url;
  link.download = 'csm_landing.csv';
  link.click();
}