'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actions = undefined;
exports.default = reducer;
exports.getVariables = getVariables;
exports.variablesSelect = variablesSelect;
exports.variablesUnselect = variablesUnselect;
exports.variablesUnselectAll = variablesUnselectAll;
exports.variablesSelectSingle = variablesSelectSingle;
exports.addVarToContact = addVarToContact;
exports.deleteVarFromContact = deleteVarFromContact;
exports.variablesRefresh = variablesRefresh;

var _apiAction = require('../apiAction');

var _apiAction2 = _interopRequireDefault(_apiAction);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// ACTIONS


var actions = exports.actions = new _apiAction2.default('VARIABLES', [_apiAction.ACTION_TYPES.DATA, _apiAction.ACTION_TYPES.TRIM, _apiAction.ACTION_TYPES.APPEND, _apiAction.ACTION_TYPES.SELECT]);

// REDUCER


var initialState = {
  loading: true,
  status: actions.APPEND,
  list: [],
  selected: []
};

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  return actions.getStateForAction(state, action);
}

// ACTION CREATORS


function getVariables(params) {
  return {
    types: [actions.APPEND, actions.APPEND_SUCCESS, actions.APPEND_FAIL],
    promise: function promise(client) {
      return client.get('/variables/classes', {
        params: params
      });
    }
  };
}

function variablesSelect(index) {
  return {
    type: actions.SELECT,
    index: index
  };
}

function variablesUnselect(index) {
  return {
    type: actions.SELECT_OFF,
    index: index
  };
}

function variablesUnselectAll() {
  return {
    type: actions.SELECT_NONE
  };
}

function variablesSelectSingle(index) {
  return {
    type: actions.SELECT_SINGLE,
    index: index
  };
}

function addVarToContact(id, key, value) {
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.post('/contacts/variable', {
        data: {
          profile_uids: [id],
          variable: {
            key: key,
            value: value
          }
        }
      });
    }
  };
}

function deleteVarFromContact(id, key, value) {
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.del('/contacts/variable', {
        data: {
          profile_uids: [id],
          variable: key,
          value: value
        }
      });
    }
  };
}

function variablesRefresh() {
  return {
    type: actions.TRIM,
    count: 0
  };
}