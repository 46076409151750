'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = undefined;

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _class, _temp;
// import 'react-table/react-table.css'


var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactTable = require('react-table');

var _reactTable2 = _interopRequireDefault(_reactTable);

var _Table = require('./Table.scss');

var _Table2 = _interopRequireDefault(_Table);

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Table = (_temp = _class = function (_React$Component) {
    (0, _inherits3.default)(Table, _React$Component);

    function Table() {
        (0, _classCallCheck3.default)(this, Table);
        return (0, _possibleConstructorReturn3.default)(this, (Table.__proto__ || (0, _getPrototypeOf2.default)(Table)).apply(this, arguments));
    }

    (0, _createClass3.default)(Table, [{
        key: 'render',
        value: function render() {
            var _this2 = this;

            return _react2.default.createElement(
                'div',
                { className: _Table2.default.table },
                _react2.default.createElement(_reactTable2.default, {
                    data: this.props.data,
                    columns: this.props.columns,
                    loading: this.props.isLoading,
                    showPageSizeOptions: false,
                    defaultPageSize: this.props.pageSize ? this.props.pageSize : 10,
                    resizable: false,
                    sortable: false,
                    previousText: (0, _translations2.default)('PREVIOUS'),
                    nextText: (0, _translations2.default)('NEXT'),
                    loadingText: (0, _translations2.default)('LOADING'),
                    noDataText: (0, _translations2.default)('NO ROWS FOUND'),
                    pageText: (0, _translations2.default)('PAGE'),
                    ofText: (0, _translations2.default)('OF'),
                    rowsText: (0, _translations2.default)('ROWS'),
                    getTdProps: function getTdProps(state, rowInfo, column, instance) {
                        return {
                            onClick: function onClick(e, handleOriginal) {
                                _this2.props.onClick(state, rowInfo, column, instance);
                                if (handleOriginal) {
                                    handleOriginal();
                                }
                            }
                        };
                    }
                })
            );
        }
    }]);
    return Table;
}(_react2.default.Component), _class.propTypes = {
    data: _propTypes2.default.array.isRequired,
    columns: _propTypes2.default.array.isRequired,
    isLoading: _propTypes2.default.bool.isRequired,
    pageSize: _propTypes2.default.number,
    onClick: _propTypes2.default.func
}, _temp);
exports.default = Table;
module.exports = exports['default'];