'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _getOwnPropertyDescriptor = require('babel-runtime/core-js/object/get-own-property-descriptor');

var _getOwnPropertyDescriptor2 = _interopRequireDefault(_getOwnPropertyDescriptor);

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _keys = require('babel-runtime/core-js/object/keys');

var _keys2 = _interopRequireDefault(_keys);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _desc, _value, _class, _class2, _temp;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactDropzone = require('react-dropzone');

var _reactDropzone2 = _interopRequireDefault(_reactDropzone);

var _coreDecorators = require('core-decorators');

var _papaparse = require('papaparse');

var _papaparse2 = _interopRequireDefault(_papaparse);

var _components = require('components');

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _themeEnabled = require('helpers/themeEnabled');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
  var desc = {};
  Object['ke' + 'ys'](descriptor).forEach(function (key) {
    desc[key] = descriptor[key];
  });
  desc.enumerable = !!desc.enumerable;
  desc.configurable = !!desc.configurable;

  if ('value' in desc || desc.initializer) {
    desc.writable = true;
  }

  desc = decorators.slice().reverse().reduce(function (desc, decorator) {
    return decorator(target, property, desc) || desc;
  }, desc);

  if (context && desc.initializer !== void 0) {
    desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
    desc.initializer = undefined;
  }

  if (desc.initializer === void 0) {
    Object['define' + 'Property'](target, property, desc);
    desc = null;
  }

  return desc;
}

var md = {
  error: require('react-icons/lib/md/error'),
  error_outline: require('react-icons/lib/md/error-outline'),
  check: require('react-icons/lib/md/check'),
  done: require('react-icons/lib/md/done'),
  find_in_page: require('react-icons/lib/md/find-in-page')
};

var fileHeaderSMS = ['msisdn', 'telefono', 'Telefono', 'teléfono', 'Teléfono', 'phone_number', 'phone', 'MSISDN', 'TELEFONO'];

var fileMessage = ['MENSAJE', 'MENSAJES', 'MESSAGE', 'MESSAGES'];

var fileHeaderEmail = ['Email', 'email', 'mail', 'Correo', 'correo'];

var fileHeaderToken = ['Token', 'token', 'push'];

var delimiters = [{
  name: 'FILE_COLUMN_DELIMITER_AUTOMATIC',
  value: ''
}, {
  name: 'FILE_COLUMN_DELIMITER_COLON',
  value: ','
}, {
  name: 'FILE_COLUMN_DELIMITER_SEMICOLON',
  value: ';'
}];

var DropZone = (_class = (_temp = _class2 = function (_React$Component) {
  (0, _inherits3.default)(DropZone, _React$Component);

  function DropZone(props) {
    (0, _classCallCheck3.default)(this, DropZone);

    var _this = (0, _possibleConstructorReturn3.default)(this, (DropZone.__proto__ || (0, _getPrototypeOf2.default)(DropZone)).call(this, props));

    _this.styles = require('./DropZone.scss');
    _this.state = {
      files: [],
      percentage: 0,
      state: 'WAITING', // PARSING | WAITING | UPLOADING | ERROR | DONE
      delimiter: ''
    };
    return _this;
  }

  (0, _createClass3.default)(DropZone, [{
    key: 'onDrop',
    value: function onDrop(files) {
      var _this2 = this;

      this.setState({
        state: 'PARSING',
        files: files
      }, function () {
        _this2.parseFiles();
      });
    }
  }, {
    key: 'onSelect',
    value: function onSelect(e) {
      this.setState({
        delimiter: e.target.value
      });
    }
  }, {
    key: 'onOpenClick',
    value: function onOpenClick() {
      this.refs.dropzone.open();
    }
  }, {
    key: 'onCloseClick',
    value: function onCloseClick() {
      this.props.onFileAdded(null);
    }
  }, {
    key: 'checkShortlinkMessage',
    value: function checkShortlinkMessage(fields) {
      var values = [];
      fields.map(function (field) {
        field = field.replace(/[^a-z^A-Z^0-9\^_]+/g, '_').toUpperCase();
        fileMessage.map(function (name) {
          if (name == field) values.push(true);
        });
      });
      if (values.length > 0) {
        return true;
      }
      return false;
    }
  }, {
    key: 'parseFiles',
    value: function parseFiles() {
      var _this3 = this;

      if (this.state.files.length > 0) {
        _papaparse2.default.parse(this.state.files[0], {
          header: true,
          skipEmptyLines: true,
          delimiter: this.state.delimiter,
          preview: 10,
          complete: function complete(results) {
            var hasErrors = false;
            var filetype = '';
            if (results.errors.length > 0) {
              if (results.errors.length === 1 && results.errors[0].code === 'UndetectableDelimiter') {
                hasErrors = false;
              } else {
                hasErrors = true;
                console.error('This file failed the initial parse');
                console.table(results.errors);
              }
            }
            if (!results.meta.fields || !results.meta.fields.length > 0 || _this3.checkShortlinkMessage(results.meta.fields) && _this3.props.shortlink) {
              hasErrors = true;
              console.error('File seems to have no headers');
            } else {
              console.log('*****', _this3.props);
              console.log('Delimeter ', results.meta.delimiter);
              _this3.props.readFileData ? _this3.props.readFileData(results.data) : null;
              var firstHeader = (0, _keys2.default)(results.data[0])[0];
              if (fileHeaderSMS.indexOf(firstHeader) !== -1) {
                filetype = 'msisdn';
              } else if (fileHeaderEmail.indexOf(firstHeader) !== -1) {
                filetype = 'email';
              } else if (fileHeaderToken.indexOf(firstHeader) !== -1) {
                filetype = 'token';
              } else {
                hasErrors = true;
                console.error('File has no valid header!');
              }
            }

            if (hasErrors) {
              _this3.setState({
                state: 'ERROR'
              });
            } else {
              var data = new FormData();
              data.append('file_type', filetype);
              data.append('delimiter', results.meta.delimiter);
              var filename = 'base.csv';
              var fileError = false;

              _this3.state.files.forEach(function (file) {
                filename = file.name;
                data.append('file', file);
              });

              _this3.setState((0, _extends3.default)({}, _this3.state, {
                state: 'UPLOADING'
              }), function () {
                if (!fileError) {
                  _this3.props.onFileAdded(filename, data);

                  _this3.setState((0, _extends3.default)({}, _this3.state, {
                    state: 'DONE'
                  }));
                }
              });
            }
          }
        });
      } else {
        this.retry();
      }
    }
  }, {
    key: 'retry',
    value: function retry() {
      this.setState({
        files: [],
        percentage: 0,
        state: 'WAITING'
      });
    }
  }, {
    key: 'renderDropZone',
    value: function renderDropZone() {
      var objAsset = (0, _themeEnabled.getAssetStyle)();
      var iconDownload = objAsset.download;

      var classNames = this.styles.normal + ' ' + (this.props.mobileMode ? ' ' + this.styles.mobile : '');
      return _react2.default.createElement(
        'div',
        null,
        _react2.default.createElement(
          'div',
          { className: this.styles.delimiterSelect },
          _react2.default.createElement(
            'span',
            { className: this.styles.delimiterSelectLabel },
            (0, _translations2.default)('SELECT DELIMITER')
          ),
          _react2.default.createElement(
            'select',
            { value: this.state.delimiter, onChange: this.onSelect.bind(this) },
            delimiters.map(function (delimiter, index) {
              return _react2.default.createElement(
                'option',
                { key: index, value: delimiter.value },
                (0, _translations2.default)(delimiter.name)
              );
            })
          )
        ),
        _react2.default.createElement(
          _reactDropzone2.default,
          {
            ref: 'dropzone',
            onDrop: this.onDrop.bind(this),
            className: classNames,
            disablePreview: true,
            activeClassName: this.styles.active
          },
          _react2.default.createElement(
            'div',
            { className: this.styles.dropText },
            _react2.default.createElement('img', { src: iconDownload, className: this.styles.dropIcon, alt: 'dropIcon' }),
            _react2.default.createElement(
              'div',
              null,
              _react2.default.createElement(
                'div',
                null,
                _react2.default.createElement(
                  'b',
                  null,
                  (0, _translations2.default)('DROP FILE TEXT')
                )
              ),
              (0, _translations2.default)('UPLOAD FILE TEXT')
            ),
            this.props.hideUploadButton ? null : _react2.default.createElement(
              'div',
              { className: this.styles.button },
              (0, _translations2.default)('UPLOAD FILE BUTTON')
            )
          )
        )
      );
    }
  }, {
    key: 'renderUploading',
    value: function renderUploading() {
      return _react2.default.createElement(_components.Loading, null);
    }
  }, {
    key: 'renderError',
    value: function renderError() {
      return _react2.default.createElement(
        'div',
        { className: this.styles.retry },
        _react2.default.createElement(md.error, { size: 100, color: 'red' }),
        _react2.default.createElement(
          'div',
          null,
          _react2.default.createElement(
            'div',
            null,
            _react2.default.createElement(
              'b',
              null,
              (0, _translations2.default)('INVALID FILE')
            )
          ),
          (0, _translations2.default)('INVALID FILE ERROR')
        ),
        _react2.default.createElement(
          'div',
          { className: this.styles.button, onClick: this.retry.bind(this) },
          (0, _translations2.default)('RETRY UPLOAD BUTTON')
        )
      );
    }
  }, {
    key: 'renderDone',
    value: function renderDone() {
      return _react2.default.createElement(
        'div',
        { className: this.styles.done },
        _react2.default.createElement(md.done, { size: 100, color: '#0C7732' }),
        _react2.default.createElement(
          'div',
          null,
          _react2.default.createElement(
            'div',
            null,
            _react2.default.createElement(
              'b',
              null,
              (0, _translations2.default)('UPLOAD COMPLETED')
            )
          ),
          (0, _translations2.default)('UPLOAD AGAIN')
        ),
        _react2.default.createElement(
          'a',
          { className: this.styles.reupload, onClick: this.retry.bind(this) },
          (0, _translations2.default)('UPLOAD AGAIN BUTTON')
        )
      );
    }
  }, {
    key: 'renderParsing',
    value: function renderParsing() {
      return _react2.default.createElement(
        'div',
        { className: this.styles.parsing },
        _react2.default.createElement(md.find_in_page, { size: 100, color: '#B3D5BF' }),
        _react2.default.createElement(
          'div',
          { className: this.styles.text },
          _react2.default.createElement(
            'div',
            null,
            _react2.default.createElement(
              'b',
              null,
              (0, _translations2.default)('PARSING FILE'),
              '...'
            )
          )
        ),
        _react2.default.createElement(
          'div',
          null,
          _react2.default.createElement(_components.Loading, null)
        )
      );
    }
  }, {
    key: 'render',
    value: function render() {
      var rendering = null;

      switch (this.state.state) {
        case 'WAITING':
          rendering = this.renderDropZone.bind(this);
          break;
        case 'PARSING':
          rendering = this.renderParsing.bind(this);
          break;
        case 'UPLOADING':
          rendering = this.renderUploading.bind(this);
          break;
        case 'DONE':
          rendering = this.renderDone.bind(this);
          break;
        case 'ERROR':
        default:
          rendering = this.renderError.bind(this);
          break;
      }
      var fileDropZoneStyle = this.props.isModal ? this.styles.fileDropZoneModal : this.styles.fileDropZone;
      return _react2.default.createElement(
        'div',
        { className: fileDropZoneStyle },
        rendering()
      );
    }
  }]);
  return DropZone;
}(_react2.default.Component), _class2.propTypes = {
  onFileAdded: _propTypes2.default.func.isRequired,
  mobileMode: _propTypes2.default.bool.isRequired,
  isModal: _propTypes2.default.bool,
  hideUploadButton: _propTypes2.default.bool
}, _temp), (_applyDecoratedDescriptor(_class.prototype, 'parseFiles', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class.prototype, 'parseFiles'), _class.prototype)), _class);
exports.default = DropZone;
module.exports = exports['default'];