'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _dec2, _class;

var _ProfilingResults2 = require('containers/Profiling/ProfilingResults');

var _ProfilingResults3 = _interopRequireDefault(_ProfilingResults2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reduxConnect = require('redux-connect');

var _reactRedux = require('react-redux');

var _profiling = require('redux/modules/profiling');

var _reachHistory = require('../../../helpers/reachHistory');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var MerakiResults = (_dec = (0, _reduxConnect.asyncConnect)([{
  key: 'results',
  promise: function promise(_ref) {
    var query = _ref.location.query,
        dispatch = _ref.store.dispatch;
    var uid = query.uid;

    if (uid) {
      return dispatch((0, _profiling.getMerakiProfilingResults)(uid));
    }
    return dispatch((0, _reachHistory.push)('/integrations/meraki/'));
  }
}]), _dec2 = (0, _reactRedux.connect)(function (state) {
  return {
    results: state.profilingResults.data
  };
}, { push: _reachHistory.push, getMerakiProfilingResults: _profiling.getMerakiProfilingResults }), _dec(_class = _dec2(_class = function (_ProfilingResults) {
  (0, _inherits3.default)(MerakiResults, _ProfilingResults);

  function MerakiResults() {
    (0, _classCallCheck3.default)(this, MerakiResults);
    return (0, _possibleConstructorReturn3.default)(this, (MerakiResults.__proto__ || (0, _getPrototypeOf2.default)(MerakiResults)).apply(this, arguments));
  }

  return MerakiResults;
}(_ProfilingResults3.default)) || _class) || _class);
exports.default = MerakiResults;
module.exports = exports['default'];