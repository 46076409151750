'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _class, _temp;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _reactDatetime = require('react-datetime');

var _reactDatetime2 = _interopRequireDefault(_reactDatetime);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Datepicker = (_temp = _class = function (_React$Component) {
  (0, _inherits3.default)(Datepicker, _React$Component);

  function Datepicker(props) {
    (0, _classCallCheck3.default)(this, Datepicker);

    var _this = (0, _possibleConstructorReturn3.default)(this, (Datepicker.__proto__ || (0, _getPrototypeOf2.default)(Datepicker)).call(this, props));

    _this.styles = require('./Datepicker.scss');
    _this.state = {
      datetime: (0, _moment2.default)()
    };
    return _this;
  }

  (0, _createClass3.default)(Datepicker, [{
    key: 'handleChange',
    value: function handleChange(date) {
      this.setState({
        datetime: date
        /*
        date: date.format('YYYY-MM-DD'),
        time: date.format('hh:mm A')
        */
      });
      this.props.onChange(date);
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      var styles = this.styles;
      return _react2.default.createElement(
        'div',
        { className: styles.wrapper },
        _react2.default.createElement(_reactDatetime2.default, {
          className: styles.datepicker + ' ' + (this.props.compact ? styles.compact : ''),
          input: false,
          value: this.props.selectedDate ? (0, _moment2.default)('' + this.props.selectedDate, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD') : this.state.datetime,
          onChange: this.handleChange.bind(this),
          dateFormat: "YYYY-MM-DD",
          timeFormat: this.props.timeFormat,
          isValidDate: function isValidDate(current) {
            if (_this2.props.isAfter) {
              return current.isAfter(_reactDatetime2.default.moment().subtract(1, 'day'));
            } else if (_this2.props.afterDate) {
              return current.isAfter(_this2.props.afterDate.moment().add(1, 'day'));
            }
            return current.isBefore(_reactDatetime2.default.moment().endOf('day'));
          }
        })
      );
    }
  }]);
  return Datepicker;
}(_react2.default.Component), _class.propTypes = {
  onChange: _propTypes2.default.func.isRequired,
  compact: _propTypes2.default.bool
}, _class.defaultProps = {
  timeFormat: true,
  isAfter: true
}, _temp);
exports.default = Datepicker;
module.exports = exports['default'];