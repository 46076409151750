'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = undefined;

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _dec2, _class, _class2, _temp;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _MessageByUsers = require('./MessageByUsers.scss');

var _MessageByUsers2 = _interopRequireDefault(_MessageByUsers);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _components = require('components');

var _reactRedux = require('react-redux');

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _reduxConnect = require('redux-connect');

var _reports = require('redux/modules/reports');

var _status = require('redux/modules/status');

var _themeEnabled = require('helpers/themeEnabled');

var _numberFormat = require('../../helpers/numberFormat');

var _numberFormat2 = _interopRequireDefault(_numberFormat);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var md = {
    pieChart: require('react-icons/lib/md/pie-chart'),
    download: require('react-icons/lib/md/arrow-downward')
};

var BalanceHistory = (_dec = (0, _reduxConnect.asyncConnect)([{
    key: 'account_status',
    promise: function promise(_ref) {
        var _ref$store = _ref.store,
            dispatch = _ref$store.dispatch,
            getState = _ref$store.getState;

        var state = getState();
        if (!state.status.data.account_id) {
            return dispatch((0, _status.getAccountStatus)());
        }
        return null;
    }
}]), _dec2 = (0, _reactRedux.connect)(function (state) {
    return {
        account_status: state.status.data,
        account: state.auth
    };
}, {
    getBalanceHistory: _reports.getBalanceHistory
}), _dec(_class = _dec2(_class = (_temp = _class2 = function (_Component) {
    (0, _inherits3.default)(BalanceHistory, _Component);

    function BalanceHistory(props) {
        (0, _classCallCheck3.default)(this, BalanceHistory);

        var _this = (0, _possibleConstructorReturn3.default)(this, (BalanceHistory.__proto__ || (0, _getPrototypeOf2.default)(BalanceHistory)).call(this, props));

        _this.state = {
            data: []
        };
        return _this;
    }

    (0, _createClass3.default)(BalanceHistory, [{
        key: 'componentDidMount',
        value: function componentDidMount() {
            var _this2 = this;

            var account_id = this.props.account.current_account.account_id;
            var now = (0, _moment2.default)();
            if (account_id) this.props.getBalanceHistory(account_id).then(function (value) {
                value.map(function (balance) {
                    balance.credit_base_limit = (0, _numberFormat2.default)(balance.credit_base_limit, 0);
                    balance.balance = (0, _numberFormat2.default)(balance.balance, 0);
                    if (now > (0, _moment2.default)(balance.valid_thru)) {
                        balance.balance = 0;
                    }
                });
                _this2.setState({ data: value });
            });
        }
    }, {
        key: 'renderChart',
        value: function renderChart() {
            var kpis = [];
            if (this.state.data.length > 0) {
                kpis = this.state.data.map(function (value) {
                    return { name: (0, _moment2.default)(value.valid_since).format("DD/MM/YYYY"), y: value.charges };
                });
            }
            var assets = (0, _themeEnabled.getAssetStyle)();
            var values = [{
                "showInLegend": false,
                "data": kpis.reverse(),
                "dataLabels": {
                    "enabled": false,
                    "color": 'black',
                    "format": '{point.y: ,.0f}',
                    "style": {
                        "fontSize": '16px',
                        "fontFamily": 'Verdana, sans-serif'
                    }
                },
                "color": assets.colorChart
            }];
            return _react2.default.createElement(
                'div',
                { className: _MessageByUsers2.default.chart },
                _react2.default.createElement(_components.GenericBarChart, { series: values, height: 240, title: '', titley: "Total Enviados", tooltip: false })
            );
        }
    }, {
        key: 'renderTable',
        value: function renderTable() {
            var columns = [{
                Header: "Desde",
                accessor: 'valid_since',
                width: '1f',
                Cell: function Cell(props) {
                    return _react2.default.createElement(
                        'span',
                        null,
                        (0, _moment2.default)(props.value).format('DD/MM/YYYY')
                    );
                }
            }, {
                Header: "Hasta",
                accessor: 'valid_thru',
                width: '1f',
                Cell: function Cell(props) {
                    return _react2.default.createElement(
                        'span',
                        null,
                        (0, _moment2.default)(props.value).format('DD/MM/YYYY')
                    );
                }
            }, {
                Header: "Contratados",
                accessor: 'credit_base_limit',
                width: '1f'
            }, {
                Header: "Utilizados",
                width: '1f',
                Cell: function Cell(props) {
                    return _react2.default.createElement(
                        'span',
                        null,
                        (0, _numberFormat2.default)(props.original.charges - props.original.refunds, 0)
                    );
                }
            }, {
                Header: "Disponibles",
                accessor: 'balance',
                width: '1f'
            }];

            return _react2.default.createElement(
                'div',
                { className: _MessageByUsers2.default.table },
                _react2.default.createElement(_components.Table, {
                    className: _MessageByUsers2.default.table,
                    data: this.state.data,
                    columns: columns,
                    isLoading: false
                })
            );
        }
    }, {
        key: 'renderContent',
        value: function renderContent() {
            return _react2.default.createElement(
                'div',
                { className: _MessageByUsers2.default.body },
                _react2.default.createElement(
                    'div',
                    { className: _MessageByUsers2.default.reportContent },
                    _react2.default.createElement(
                        'div',
                        { className: _MessageByUsers2.default.content },
                        this.renderChart()
                    )
                ),
                _react2.default.createElement(
                    'div',
                    { className: _MessageByUsers2.default.reportContent },
                    _react2.default.createElement(
                        'div',
                        { className: _MessageByUsers2.default.content },
                        this.renderTable()
                    )
                )
            );
        }
    }, {
        key: 'download',
        value: function download() {
            console.log('downloading');
        }
    }, {
        key: 'render',
        value: function render() {
            var classesTheme = (0, _themeEnabled.getThemeEnabledStyle)(_MessageByUsers2.default);
            return _react2.default.createElement(
                'div',
                { className: classesTheme },
                _react2.default.createElement(
                    'div',
                    { className: _MessageByUsers2.default.bar },
                    _react2.default.createElement(
                        'div',
                        { className: _MessageByUsers2.default.icon },
                        _react2.default.createElement(md.pieChart, { size: 20, color: '#FFF', onClick: this.download })
                    ),
                    _react2.default.createElement(
                        'div',
                        { className: _MessageByUsers2.default.title },
                        'Balance de la cuenta'
                    )
                ),
                this.renderContent()
            );
        }
    }]);
    return BalanceHistory;
}(_react.Component), _class2.propTypes = {
    account: _propTypes2.default.object
}, _temp)) || _class) || _class);
exports.default = BalanceHistory;
module.exports = exports['default'];