'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _getOwnPropertyNames = require('babel-runtime/core-js/object/get-own-property-names');

var _getOwnPropertyNames2 = _interopRequireDefault(_getOwnPropertyNames);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _class, _class2, _temp;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactRedux = require('react-redux');

var _interaction = require('redux/modules/interaction');

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _featureEnabled = require('helpers/featureEnabled');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var InteractionChannels = (_dec = (0, _reactRedux.connect)(function (state) {
  return {
    account: state.auth.current_account,
    channels: state.interaction.configuration.interaction_channels
  };
}, { updateInteractionConfig: _interaction.updateInteractionConfig }), _dec(_class = (_temp = _class2 = function (_React$Component) {
  (0, _inherits3.default)(InteractionChannels, _React$Component);

  function InteractionChannels(props) {
    (0, _classCallCheck3.default)(this, InteractionChannels);

    var _this = (0, _possibleConstructorReturn3.default)(this, (InteractionChannels.__proto__ || (0, _getPrototypeOf2.default)(InteractionChannels)).call(this, props));

    _this.state = {};

    _this.channelSVGs = {
      sms: require('./svg/sms.svg'),
      html: require('./svg/html.svg'),
      app: require('./svg/apps.svg'),
      email: require('./svg/email.svg'),
      facebook: require('./svg/facebook.svg'),
      twitter: require('./svg/twitter.svg'),
      whatsapp: require('./svg/whatsapp.svg'),
      telegram: require('./svg/telegram.svg')
    };
    _this.channelTranslations = {
      SMS: (0, _translations2.default)('SMS'),
      HTML: (0, _translations2.default)('WEB'),
      IM: (0, _translations2.default)('IM'),
      APP: (0, _translations2.default)('APP'),
      EMAIL: (0, _translations2.default)('EMAIL'),
      FACEBOOK: (0, _translations2.default)('FACEBOOK'),
      TWITTER: (0, _translations2.default)('TWITTER'),
      WHATSAPP: (0, _translations2.default)('WHATSAPP'),
      TELEGRAM: (0, _translations2.default)('TELEGRAM')
    };

    _this.selectedChannels = { SMS: false, HTML: false, WHATSAPP: false, TELEGRAM: false, APP: false };

    _this.enabledChannels = {
      SMS: props.account.account_type == "Subaccount" && props.account.is_default_subaccount && props.account.reach_enabled || props.account.account_type != "Subaccount",
      HTML: true,
      WHATSAPP: false,
      TELEGRAM: false,
      APP: false
    };
    return _this;
  }

  (0, _createClass3.default)(InteractionChannels, [{
    key: 'handleChannelChange',
    value: function handleChannelChange(clickedChannel, event) {
      var _this2 = this;

      event.preventDefault();
      var tmpChannels = this.selectedChannels;
      tmpChannels[clickedChannel] = !this.selectedChannels[clickedChannel];
      var channels = [];
      (0, _getOwnPropertyNames2.default)(this.selectedChannels).forEach(function (channelType) {
        if (_this2.selectedChannels[channelType]) {
          channels.push(channelType);
        }
      });
      if (channels.length > 0) {
        this.selectedChannels = tmpChannels;
        this.props.updateInteractionConfig('interaction_channels', channels);
        if (channels.indexOf('SMS') > -1) {
          this.props.updateInteractionConfig('sending_channels', ['SMS']);
        } else {
          this.props.updateInteractionConfig('keyword', "");
        }
      }
      this.props.defineInvitation(null, this.props.invitation.keyword, channels);
    }
  }, {
    key: 'channel',
    value: function channel(channelType) {
      var channelChange = this.handleChannelChange.bind(this, channelType);
      if (this.enabledChannels[channelType]) {
        var href = '#channel-' + channelType;
        return _react2.default.createElement(
          'a',
          { href: href, 'data-channel': channelType, className: this.selectedChannels[channelType] ? 'active' : '', onClick: channelChange },
          _react2.default.createElement('div', { className: 'img', dangerouslySetInnerHTML: { __html: this.channelSVGs[channelType.toLowerCase()] } }),
          _react2.default.createElement(
            'div',
            { className: 'txt' },
            this.channelTranslations[channelType]
          )
        );
      }
      return _react2.default.createElement(
        'a',
        { className: 'disabled' },
        _react2.default.createElement('div', { className: 'img', dangerouslySetInnerHTML: { __html: this.channelSVGs[channelType.toLowerCase()] } }),
        _react2.default.createElement(
          'div',
          { className: 'txt' },
          channelType
        )
      );
    }
  }, {
    key: 'render',
    value: function render() {
      var channels = this.props.channels;

      for (var c = 0; c < channels.length; c += 1) {
        this.selectedChannels[channels[c]] = true;
      }

      return _react2.default.createElement(
        'div',
        { className: 'block edit material-shadow' },
        _react2.default.createElement(
          'h5',
          null,
          (0, _translations2.default)('INTERACTION CHANNELS')
        ),
        _react2.default.createElement(
          'section',
          { className: 'interaction_channels channels' },
          _react2.default.createElement(
            'ul',
            { className: 'channels-row' },
            _react2.default.createElement(
              'li',
              { className: 'channels-cell channels-cell--multiple' },
              this.channel('SMS')
            ),
            _react2.default.createElement(
              'li',
              { className: 'channels-cell channels-cell--multiple' },
              this.channel('HTML')
            ),
            (0, _featureEnabled.featureEnabled)('whatsapp') ? _react2.default.createElement(
              'li',
              { className: 'channels-cell channels-cell--multiple' },
              this.channel('WHATSAPP')
            ) : null,
            (0, _featureEnabled.featureEnabled)('telegram') ? _react2.default.createElement(
              'li',
              { className: 'channels-cell channels-cell--multiple' },
              this.channel('TELEGRAM')
            ) : null,
            (0, _featureEnabled.featureEnabled)('push') ? _react2.default.createElement(
              'li',
              { className: 'channels-cell channels-cell--multiple' },
              this.channel('APP')
            ) : null
          )
        )
      );
    }
  }]);
  return InteractionChannels;
}(_react2.default.Component), _class2.propTypes = {
  updateInteractionConfig: _propTypes2.default.func.isRequired,
  channels: _propTypes2.default.array.isRequired
}, _temp)) || _class);
exports.default = InteractionChannels;
module.exports = exports['default'];