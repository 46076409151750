'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = createStore;

var _redux = require('redux');

var _reactRouterRedux = require('react-router-redux');

var _reactReduxLoadingBar = require('react-redux-loading-bar');

var _reduxDevtoolsExtension = require('redux-devtools-extension');

var _logrocket = require('logrocket');

var _logrocket2 = _interopRequireDefault(_logrocket);

var _clientMiddleware = require('./middleware/clientMiddleware');

var _clientMiddleware2 = _interopRequireDefault(_clientMiddleware);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/* eslint global-require:0 */

function createStore(history, client, data) {
  // Sync dispatched route actions to the history
  var reduxRouterMiddleware = (0, _reactRouterRedux.routerMiddleware)(history);

  var middleware = [(0, _clientMiddleware2.default)(client), reduxRouterMiddleware, (0, _reactReduxLoadingBar.loadingBarMiddleware)({
    promiseTypeSuffixes: ['DATA', 'SUCCESS', 'FAIL']
  }), _logrocket2.default.reduxMiddleware()];

  var finalCreateStore = void 0;
  if (__DEVELOPMENT__ && __CLIENT__ && __DEVTOOLS__) {
    var _require = require('redux-devtools'),
        persistState = _require.persistState;

    finalCreateStore = (0, _reduxDevtoolsExtension.composeWithDevTools)(_redux.applyMiddleware.apply(undefined, middleware), persistState(window.location.href.match(/[?&]debug_session=([^&]+)\b/)))(_redux.createStore);
  } else {
    finalCreateStore = _redux.applyMiddleware.apply(undefined, middleware)(_redux.createStore);
  }

  var reducer = require('./modules/reducer');
  var store = finalCreateStore(reducer, data);

  if (__DEVELOPMENT__ && module.hot) {
    module.hot.accept('./modules/reducer', function () {
      store.replaceReducer(require('./modules/reducer'));
    });
  }

  return store;
}
module.exports = exports['default'];