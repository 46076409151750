'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MODE_DESKTOP = exports.MODE_MOBILE = exports.SET_AUTO = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

exports.default = reducer;
exports.mobileMode = mobileMode;
exports.desktopMode = desktopMode;
exports.setAuto = setAuto;
exports.modal = modal;

var _isMobile = require('is-mobile');

var _isMobile2 = _interopRequireDefault(_isMobile);

var _reachHistory = require('../../helpers/reachHistory');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SET_AUTO = exports.SET_AUTO = 'SET_AUTO';
var MODE_MOBILE = exports.MODE_MOBILE = 'MODE_MOBILE';
var MODE_DESKTOP = exports.MODE_DESKTOP = 'MODE_DESKTOP';

var initialState = {
  mobileMode: false,
  isMobile: false
};

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case SET_AUTO:
      return {
        mobileMode: action.mobile,
        isMobile: action.mobile
      };
    case MODE_MOBILE:
      return (0, _extends3.default)({}, state, {
        mobileMode: true
      });
    case MODE_DESKTOP:
      return (0, _extends3.default)({}, state, {
        mobileMode: false
      });
    default:
      return state;
  }
}

function mobileMode() {
  return {
    type: MODE_MOBILE
  };
}

function desktopMode() {
  return {
    type: MODE_DESKTOP
  };
}

function setAuto() {
  return {
    type: SET_AUTO,
    mobile: (0, _isMobile2.default)()
  };
}

function modal(pathname) {
  var returnTo = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

  if (!returnTo) {
    return (0, _reachHistory.push)({ pathname: pathname, state: { modal: true } });
  }
  return (0, _reachHistory.push)({ pathname: pathname, state: { modal: true, returnTo: returnTo } });
}