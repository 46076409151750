'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WizardFooter = exports.default = undefined;

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _class, _class2, _temp;

var _reactRouter = require('react-router');

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _reactRedux = require('react-redux');

var _reachHistory = require('../../helpers/reachHistory');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function onNext() {
  alert('next');return true;
}
function onBack() {
  alert('back');return true;
}

var Wizard = (_dec = (0, _reactRedux.connect)(null, {
  pushState: _reachHistory.push
}), _dec(_class = (_temp = _class2 = function (_React$Component) {
  (0, _inherits3.default)(Wizard, _React$Component);

  function Wizard() {
    (0, _classCallCheck3.default)(this, Wizard);
    return (0, _possibleConstructorReturn3.default)(this, (Wizard.__proto__ || (0, _getPrototypeOf2.default)(Wizard)).apply(this, arguments));
  }

  (0, _createClass3.default)(Wizard, [{
    key: 'renderOptions',
    value: function renderOptions() {
      var style = require('./Wizard.scss');
      return this.props.options.map(function (s, index) {
        return _react2.default.createElement(
          _reactRouter.Link,
          { to: s.href, className: style.bartab, activeClassName: style.active,
            onlyActiveOnIndex: s.is_index, key: index },
          s.title
        );
      });
    }
  }, {
    key: 'render',
    value: function render() {
      var style = require('./Wizard.scss');
      return _react2.default.createElement(
        'div',
        { className: style.wizard },
        this.renderOptions(),
        _react2.default.createElement(Wizardfooter, null)
      );
    }
  }]);
  return Wizard;
}(_react2.default.Component), _class2.propTypes = {
  options: _propTypes2.default.array.isRequired,
  location: _propTypes2.default.string.isRequired,
  pushState: _propTypes2.default.func.isRequired,
  children: _propTypes2.default.any.isRequired
}, _class2.defaultProps = {
  options: [{
    // 'href': '/wizard',
    'href': {
      pathname: '/wizard',
      state: { modal: true, returnTo: '/wizard' }
    },
    'title': (0, _translations2.default)('CONTACTS'),
    'is_index': true,
    'onNext': onNext,
    'onBack': onBack
  }, {
    // 'href': '/wizard/tags',
    'href': {
      pathname: '/wizard/tags',
      state: { modal: true, returnTo: '/wizard' }
    },
    'title': (0, _translations2.default)('TAGS'),
    'is_index': false,
    'onNext': onNext,
    'onBack': onBack
  }, {
    // 'href': '/wizard/variables',
    'href': {
      pathname: '/wizard/variables',
      state: { modal: true, returnTo: '/wizard' }
    },
    'title': (0, _translations2.default)('VARIABLES'),
    'is_index': false,
    'onNext': onNext,
    'onBack': onBack
  }],
  location: '',
  pushState: function pushState() {
    return null;
  },
  children: []
}, _temp)) || _class);
exports.default = Wizard;
var WizardFooter = exports.WizardFooter = function WizardFooter(props) {
  var style = require('./Wizard.scss');
  return _react2.default.createElement(
    'div',
    { className: style.footer },
    _react2.default.createElement(
      'div',
      { className: style.left },
      _react2.default.createElement(
        _reactRouter.Link,
        { className: style.button, onClick: props.goBack },
        (0, _translations2.default)('WIZARD BACK')
      )
    ),
    _react2.default.createElement(
      'div',
      { className: style.right },
      _react2.default.createElement(
        _reactRouter.Link,
        { className: style.button, onClick: props.goNext },
        (0, _translations2.default)('WIZARD NEXT')
      )
    )
  );
};

WizardFooter.propTypes = {
  goBack: _propTypes2.default.func,
  goNext: _propTypes2.default.func
};