'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = undefined;

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Template = require('./Template1.scss');

var _Template2 = _interopRequireDefault(_Template);

var _reactDropzoneS3Uploader = require('react-dropzone-s3-uploader');

var _reactDropzoneS3Uploader2 = _interopRequireDefault(_reactDropzoneS3Uploader);

var _reactDropzone = require('react-dropzone');

var _reactDropzone2 = _interopRequireDefault(_reactDropzone);

var _reactColor = require('react-color');

var _components = require('components');

var _useBasename = require('../../../helpers/useBasename');

var _useBasename2 = _interopRequireDefault(_useBasename);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var md = {
    build: require('react-icons/lib/md/settings')
};

var Template1 = function (_React$Component) {
    (0, _inherits3.default)(Template1, _React$Component);

    function Template1(props) {
        (0, _classCallCheck3.default)(this, Template1);

        var _this = (0, _possibleConstructorReturn3.default)(this, (Template1.__proto__ || (0, _getPrototypeOf2.default)(Template1)).call(this, props));

        _this.handleChangeComplete = function (where, color) {
            if (where == 'header') {
                _this.setState({ headerColor: color.hex });
            } else if (where == 'content') {
                _this.setState({ contentColor: color.hex });
            } else if (where == 'footer') {
                _this.setState({ footerColor: color.hex });
            }
        };

        _this.handleClick = function (type) {
            var where = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';

            var options = void 0;

            if (type == 'logo') {
                options = _react2.default.createElement(
                    'div',
                    null,
                    _react2.default.createElement(
                        'h1',
                        null,
                        type
                    )
                );
            } else if (type == 'content') {
                options = _react2.default.createElement(
                    'div',
                    null,
                    _react2.default.createElement(
                        'div',
                        null,
                        'Escribe el contenido del correo'
                    ),
                    _react2.default.createElement(
                        'div',
                        null,
                        _react2.default.createElement('textarea', { rows: '10', className: _Template2.default.textArea })
                    )
                );
            } else if (type == 'footer') {
                options = _react2.default.createElement(
                    'div',
                    null,
                    _react2.default.createElement(
                        'div',
                        null,
                        'Escribe el footer del correo'
                    ),
                    _react2.default.createElement(
                        'div',
                        null,
                        _react2.default.createElement('textarea', { rows: '10', className: _Template2.default.textArea })
                    )
                );
            } else if (type == 'color') {

                options = _react2.default.createElement(
                    'div',
                    null,
                    _react2.default.createElement(
                        'div',
                        null,
                        'Selecciona un color de fondo'
                    ),
                    _react2.default.createElement(
                        'div',
                        { className: _Template2.default.blockPicker },
                        _react2.default.createElement(_reactColor.BlockPicker, { onChangeComplete: _this.handleChangeComplete.bind(_this, where) })
                    )
                );
            }
            _this.setState({ options: options });
        };

        _this.state = {
            headerColor: '#f47373',
            contentColor: '#cccccc',
            footerColor: '#dce775'
        };
        return _this;
    }

    (0, _createClass3.default)(Template1, [{
        key: 'render',
        value: function render() {

            return _react2.default.createElement(
                'div',
                { className: _Template2.default.mainContainer },
                _react2.default.createElement(
                    'div',
                    { className: _Template2.default.template },
                    _react2.default.createElement(
                        'div',
                        { className: _Template2.default.modalTitle, style: { backgroundColor: this.state.headerColor } },
                        _react2.default.createElement(
                            'div',
                            { className: _Template2.default.icon },
                            _react2.default.createElement(md.build, { size: 25, onClick: this.handleClick.bind(this, 'color', 'header') })
                        ),
                        _react2.default.createElement(
                            'div',
                            null,
                            _react2.default.createElement('img', { className: _Template2.default.noLogo, src: (0, _useBasename2.default)('/static/mailLogo.png'),
                                onClick: this.handleClick.bind(this, 'logo') })
                        )
                    ),
                    _react2.default.createElement(
                        'div',
                        { className: _Template2.default.modalContent, style: { backgroundColor: this.state.contentColor } },
                        _react2.default.createElement(
                            'div',
                            { className: _Template2.default.icon },
                            _react2.default.createElement(md.build, { size: 25, onClick: this.handleClick.bind(this, 'color', 'content') })
                        ),
                        _react2.default.createElement(
                            'div',
                            { onClick: this.handleClick.bind(this, 'content') },
                            _react2.default.createElement(
                                'h1',
                                null,
                                'CONTENIDO'
                            )
                        )
                    ),
                    _react2.default.createElement(
                        'div',
                        { className: _Template2.default.modalFooter, style: { backgroundColor: this.state.footerColor } },
                        _react2.default.createElement(
                            'div',
                            { className: _Template2.default.icon },
                            _react2.default.createElement(md.build, { size: 25, onClick: this.handleClick.bind(this, 'color', 'footer') })
                        ),
                        _react2.default.createElement(
                            'div',
                            { onClick: this.handleClick.bind(this, 'footer') },
                            _react2.default.createElement(
                                'h1',
                                null,
                                'FOOTER'
                            )
                        )
                    )
                ),
                _react2.default.createElement(
                    'div',
                    { className: _Template2.default.menu },
                    _react2.default.createElement(
                        'h1',
                        null,
                        'MENU'
                    ),
                    this.state.options ? this.state.options : null,
                    this.state.options ? _react2.default.createElement(_components.Button, { className: _Template2.default.addButton, color: 'blue', name: 'Agregar'
                    }) : null
                )
            );
        }
    }]);
    return Template1;
}(_react2.default.Component);

exports.default = Template1;
module.exports = exports['default'];