'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// TODO: this tabs should hold both Generic and TigoTabs

var styles = require('./Tabs.scss');

var md = {
  help: require('react-icons/lib/md/help')
};

var GlobalTabs = function GlobalTabs(props) {
  return _react2.default.createElement(
    'div',
    { className: styles.tigotabs },
    _react2.default.createElement(
      'a',
      { className: styles.tigotab + ' ' + styles.tigotabActive, style: (0, _extends3.default)({}, props.activeStyle), href: 'http://www.tigo.com.gt', target: '_blank' },
      'TIGO'
    ),
    _react2.default.createElement(
      'a',
      { className: styles.tigotab, href: 'http://www.tigo.com.gt', target: '_blank' },
      '\xA0'
    ),
    _react2.default.createElement(
      'a',
      { className: styles.tigotab, href: 'http://www.tigo.com.gt', target: '_blank' },
      '\xA0'
    ),
    _react2.default.createElement(
      'a',
      { href: 'https://comunicador.tigo.com.gt/tutoriales/', target: 'blank', className: styles.help },
      _react2.default.createElement(md.help, { size: 15, className: styles.icon }),
      ' ',
      _react2.default.createElement(
        'span',
        { className: styles.label },
        'Ayuda'
      )
    )
  );
};

GlobalTabs.propTypes = {
  activeStyle: _propTypes2.default.object.isRequired
};

exports.default = GlobalTabs;
module.exports = exports['default'];