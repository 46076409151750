'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.resultActions = exports.fetchActions = undefined;
exports.profilingReducer = profilingReducer;
exports.profilingStatsReducer = profilingStatsReducer;
exports.getProfilingJobs = getProfilingJobs;
exports.getProfilingResults = getProfilingResults;
exports.getMerakiProfilingJobs = getMerakiProfilingJobs;
exports.getMerakiProfilingResults = getMerakiProfilingResults;
exports.setMerakiUnits = setMerakiUnits;
exports.getMeraki = getMeraki;
exports.stopMerakiProfiling = stopMerakiProfiling;
exports.profilingRefresh = profilingRefresh;

var _apiAction = require('../apiAction');

var _apiAction2 = _interopRequireDefault(_apiAction);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// ACTIONS


var fetchActions = exports.fetchActions = new _apiAction2.default('PROFILING', [_apiAction.ACTION_TYPES.DATA, _apiAction.ACTION_TYPES.APPEND, _apiAction.ACTION_TYPES.TRIM]);

var resultActions = exports.resultActions = new _apiAction2.default('PROFILINGSTATS', [_apiAction.ACTION_TYPES.DATA, _apiAction.ACTION_TYPES.TRIM]);

// REDUCER


var initialState = function initialState(actions) {
  return {
    loading: false,
    status: actions.APPEND,
    list: [],
    data: {}
  };
};

function profilingReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState(fetchActions);
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  return fetchActions.getStateForAction(state, action);
}

function profilingStatsReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState(resultActions);
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  return resultActions.getStateForAction(state, action);
}

function getProfilingJobs(start, limit) {
  var actions = fetchActions;
  return {
    types: [actions.APPEND, actions.APPEND_SUCCESS, actions.APPEND_FAIL],
    promise: function promise(client) {
      return client.get('/profiling/job_descriptions', {
        params: {
          start: start,
          limit: limit
        }
      });
    }
  };
}

function getProfilingResults(jobDescriptionUid) {
  var actions = resultActions;
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.get('/profiling/job_descriptions/' + jobDescriptionUid + '/stats');
    }
  };
}

function getMerakiProfilingJobs(start, limit) {
  var actions = fetchActions;
  return {
    types: [actions.APPEND, actions.APPEND_SUCCESS, actions.APPEND_FAIL],
    promise: function promise(client) {
      return client.get('/meraki/job_descriptions', {
        params: {
          start: start,
          limit: limit
        }
      });
    }
  };
}

function getMerakiProfilingResults(jobDescriptionUid) {
  var actions = resultActions;
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.get('/meraki/job_descriptions/' + jobDescriptionUid + '/stats');
    }
  };
}

function setMerakiUnits(name, keyword, units, message) {
  var actions = fetchActions;
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.post('/meraki/job_descriptions', {
        data: {
          name: name,
          units: units,
          keyword: keyword,
          messages: {
            welcome: {
              message: message
            }
          }
        }
      });
    }
  };
}

function getMeraki() {
  var actions = fetchActions;
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.get('/meraki');
    }
  };
}

function stopMerakiProfiling(jobDescriptionUid) {
  var actions = fetchActions;
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.post('/meraki/job_descriptions/' + jobDescriptionUid + '/close', {
        data: {}
      });
    }
  };
}

function profilingRefresh() {
  return {
    type: fetchActions.TRIM,
    count: 0
  };
}