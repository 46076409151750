'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = getClientStore;

var _ApiClient = require('./ApiClient');

var _ApiClient2 = _interopRequireDefault(_ApiClient);

var _reachHistory = require('../helpers/reachHistory');

var _config = require('../config');

var _config2 = _interopRequireDefault(_config);

var _create = require('../redux/create');

var _create2 = _interopRequireDefault(_create);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var storeObj = null;

function getClientStore() {
  if (__CLIENT__ && storeObj == null) {
    try {
      var client = new _ApiClient2.default(_config2.default.basename + '/api');
      var store = (0, _create2.default)(_reachHistory.browserHistory, client, window.__data);
      storeObj = store;
    } catch (e) {
      console.error('Unable to initialize store', e);
    }
  }
  return storeObj;
}
module.exports = exports['default'];