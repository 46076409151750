'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NewStudySelector = exports.StudyShare = exports.ApiStudy = exports.OpenStudy = exports.default = undefined;

var _getOwnPropertyDescriptor = require('babel-runtime/core-js/object/get-own-property-descriptor');

var _getOwnPropertyDescriptor2 = _interopRequireDefault(_getOwnPropertyDescriptor);

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _dec2, _class, _desc, _value, _class2, _class3, _temp, _dec3, _dec4, _class4, _dec5, _dec6, _class5, _dec7, _dec8, _class6, _class7, _temp2, _dec9, _class8, _class9, _temp3;

var _components = require('components');

var _Study = require('components/Study');

var _interaction = require('redux/modules/interaction');

var _questionRegex = require('redux/modules/questionRegex');

var _qrs = require('redux/modules/qrs');

var _studies = require('redux/modules/studies');

var _auth = require('redux/modules/auth');

var _Allow = require('../Allow/Allow');

var _Allow2 = _interopRequireDefault(_Allow);

var _reactCopyToClipboard = require('react-copy-to-clipboard');

var _reactCopyToClipboard2 = _interopRequireDefault(_reactCopyToClipboard);

var _reactHelmet = require('react-helmet');

var _reactHelmet2 = _interopRequireDefault(_reactHelmet);

var _ImageQr = require('./ImageQr');

var _ImageQr2 = _interopRequireDefault(_ImageQr);

var _reactRouter = require('react-router');

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _QrList = require('./QrList');

var _QrList2 = _interopRequireDefault(_QrList);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactDom = require('react-dom');

var _reactDom2 = _interopRequireDefault(_reactDom);

var _reactColor = require('react-color');

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _reduxConnect = require('redux-connect');

var _coreDecorators = require('core-decorators');

var _reactRedux = require('react-redux');

var _featureEnabled = require('../../helpers/featureEnabled');

var _account = require('redux/modules/account');

var _themeEnabled = require('helpers/themeEnabled');

var _reports = require('redux/modules/reports');

var _reachHistory = require('../../helpers/reachHistory');

var _Study2 = require('./Study.scss');

var _Study3 = _interopRequireDefault(_Study2);

var _useBasename = require('../../helpers/useBasename');

var _useBasename2 = _interopRequireDefault(_useBasename);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
  var desc = {};
  Object['ke' + 'ys'](descriptor).forEach(function (key) {
    desc[key] = descriptor[key];
  });
  desc.enumerable = !!desc.enumerable;
  desc.configurable = !!desc.configurable;

  if ('value' in desc || desc.initializer) {
    desc.writable = true;
  }

  desc = decorators.slice().reverse().reduce(function (desc, decorator) {
    return decorator(target, property, desc) || desc;
  }, desc);

  if (context && desc.initializer !== void 0) {
    desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
    desc.initializer = undefined;
  }

  if (desc.initializer === void 0) {
    Object['define' + 'Property'](target, property, desc);
    desc = null;
  }

  return desc;
}

var md = {
  add: require('react-icons/lib/md/assignment'),
  add2: require('react-icons/lib/md/assignment-ind'),
  arrowBack: require('react-icons/lib/md/arrow-back'),
  search: require('react-icons/lib/md/search')
};

var Study = (_dec = (0, _reduxConnect.asyncConnect)([{
  key: "auth",
  promise: function promise(_ref) {
    var _ref$store = _ref.store,
        dispatch = _ref$store.dispatch,
        getState = _ref$store.getState;

    if (!(0, _auth.isLoaded)(getState())) {
      return dispatch((0, _auth.load)());
    }
    return null;
  }
}, {
  key: 'interaction',
  promise: function promise(_ref2) {
    var query = _ref2.location.query,
        dispatch = _ref2.store.dispatch;
    var copy = query.copy,
        uid = query.uid;

    if (copy) {
      return dispatch((0, _interaction.getInteractionCopy)(copy));
    } else if (uid) {
      return dispatch((0, _interaction.getInteraction)(uid));
    }
    return dispatch((0, _interaction.getNewInteraction)());
  }
}]), _dec2 = (0, _reactRedux.connect)(function (state) {
  return {
    mobileMode: state.mobile.mobileMode,
    interaction: state.interaction,
    account: state.account,
    list: state.qrs.list,
    studies: state.studies.list,
    selected: state.qrs.selected,
    questionRegex: state.questionregex
  };
}, {
  getAccount: _account.getAccount,
  getTotalContacts: _reports.getTotalContacts,
  requestDelete: _interaction.requestDelete,
  requestStart: _interaction.requestStart,
  requestSave: _interaction.requestSave,
  pushState: _reachHistory.push,
  studiesRefresh: _studies.studiesRefresh,
  getReservedKeywords: _interaction.getReservedKeywords,
  updateInteraction: _interaction.updateInteraction,
  getQrs: _qrs.getQrs,
  refreshQrs: _qrs.refreshQrs,
  selectQrs: _qrs.selectQrs,
  unselectAllQrs: _qrs.unselectAllQrs,
  updateInteractionConfig: _interaction.updateInteractionConfig,
  getStudies: _studies.getStudies,
  getAccountQuestionRegex: _questionRegex.getAccountQuestionRegex,
  refreshQuestionRegex: _questionRegex.refreshQuestionRegex,
  getRewardBatches: _interaction.getRewardBatches
}), _dec(_class = _dec2(_class = (_class2 = (_temp = _class3 = function (_React$Component) {
  (0, _inherits3.default)(Study, _React$Component);

  function Study(props) {
    (0, _classCallCheck3.default)(this, Study);

    var _this = (0, _possibleConstructorReturn3.default)(this, (Study.__proto__ || (0, _getPrototypeOf2.default)(Study)).call(this, props));

    _this.defineInvitation = function (message, keyword) {
      var channels = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _this.props.interaction.configuration.interaction_channels;

      var InvitationMessage = _this.state.invitation.InvitationMessage;
      if (message != null) InvitationMessage = message.body;

      if (keyword === null) keyword = _this.state.invitation.keyword;

      var fullInvitationWeb = "";
      var fullInvitationSms = "";

      //Update WEB invitations
      if (channels.indexOf('HTML') > -1) fullInvitationWeb += (0, _translations2.default)("VISIT") + " " + (0, _translations2.default)('SHARE URL PREFIX') + (0, _translations2.default)("UNKNOWN");

      //Update SMS invitation
      if (channels.indexOf('SMS') > -1) {
        if (channels.indexOf('HTML') > -1) fullInvitationSms += "\n" + (0, _translations2.default)("OR");
        fullInvitationSms += (0, _translations2.default)("RESPOND") + (keyword ? keyword : "") + (0, _translations2.default)("TO START");
      }

      var fullInvitation = InvitationMessage + "\n\n" + fullInvitationWeb + fullInvitationSms;

      var invitation = {
        keyword: keyword,
        fullInvitationSms: fullInvitationSms,
        fullInvitationWeb: fullInvitationWeb,
        InvitationMessage: InvitationMessage,
        fullInvitation: fullInvitation
      };

      _this.setState({ invitation: invitation });
    };

    _this.statusTranslations = {
      'IN_PROGRESS': (0, _translations2.default)('IN_PROGRESS'),
      'PAUSED': (0, _translations2.default)('PAUSED'),
      'APPROVED': (0, _translations2.default)('APPROVED'),
      'FINISHED': (0, _translations2.default)('FINISHED'),
      'EXPIRED': (0, _translations2.default)('EXPIRED'),
      'ABORTED': (0, _translations2.default)('ABORTED'),
      'NOT_STARTED': (0, _translations2.default)('NOT_STARTED'),
      'STOPPED': (0, _translations2.default)('STOPPED'),
      'ACTIVE': (0, _translations2.default)('ACTIVE STATUS')
    };

    _this.state = {
      study_type: _this.props.study_type || _interaction.STUDY_INVITATION,
      copy: _this.props.location.query.copy,
      notification: '',
      keywords: [],
      newQr: 1,
      changeQr: false,
      qr: {},
      background: '#FFF',
      foreground: '#FFF',
      interactionFlag: false,
      invitation: {
        keyword: "",
        fullInvitationSms: "o Responde  para iniciar",
        fullInvitationWeb: "visita" + (0, _translations2.default)('SHARE URL PREFIX') + "######",
        InvitationMessage: _this.props.interaction.configuration.messages.invitation.body,
        fullInvitation: ""
      },
      paramsIframe: {},
      batches: []

      //  this.props.refreshQrs()
    };_this.props.refreshQrs();
    _this.props.getQrs();
    _this.props.getStudies();
    _this.props.refreshQuestionRegex();
    _this.props.getAccountQuestionRegex();

    if (_this.props.interaction.configuration.public_web_access) {
      if (_this.props.location.query.copy !== _this.props.interaction._id && _this.props.interaction._id && _this.props.interaction.configuration.public_web_access.qr_id) {
        var filter = {
          qr_id: _this.props.interaction.configuration.public_web_access.qr_id,
          study_uid: _this.props.interaction._id
        };

        _this.props.getQrs(filter).then(function (qr) {
          _this.validateQrInteraction(qr[0]);
          _this.setState({
            qr: qr[0]
          });
        });
        _this.props.getQrs();

        var study_type = _this.props.interaction._id;
        var uid = _this.props.interaction.configuration.public_web_access.qr_id;
      } else {
        _this.props.updateInteractionConfig('public_web_access', {});
      }
    }
    return _this;
  }

  (0, _createClass3.default)(Study, [{
    key: 'validateQrInteraction',
    value: function validateQrInteraction(qr) {
      if (qr._id && this.props.interaction._id && qr.urls.new_url) {
        var public_web_access = {};
        this.props.updateInteractionConfig('public_web_access', public_web_access);
      }
    }
  }, {
    key: 'componentDidMount',
    value: function componentDidMount() {
      var _this2 = this;

      var iframePath = this.getUrlIframe();
      this.setState({
        paramsIframe: iframePath
      });

      this.props.getReservedKeywords().then(function (keywords) {
        _this2.setState((0, _extends3.default)({}, _this2.state, {
          keywords: keywords
        }));
      });
      // TODO: make assync connect work
      if (!this.props.account.data.account_id) {
        this.props.getAccount().then(function () {});
      }
      this.props.getRewardBatches().then(function (batches) {
        console.log('batches', batches);
        _this2.setState((0, _extends3.default)({}, _this2.state, {
          batches: batches
        }));
      });
    }
  }, {
    key: 'componentWillReceiveProps',
    value: function componentWillReceiveProps(nextProps) {
      //update the state of qr selected
      if (nextProps.selected != this.props.selected) {
        this.sendSelectedQR(nextProps.selected);
      }
    }
  }, {
    key: 'componentWillUnmount',
    value: function componentWillUnmount() {
      this.props.studiesRefresh();
    }
  }, {
    key: 'getTitle',
    value: function getTitle() {
      var title = (0, _translations2.default)('NEW STUDY');
      if (this.props.interaction.name) {
        title = this.props.interaction.name;
      }
      if (this.props.params.copy) {
        title = (0, _translations2.default)('STUDY COPY');
      }
      return title;
    }
  }, {
    key: 'qrDetail',
    value: function qrDetail() {
      var qr_detail = { name: this.props.interaction.name, color: this.state.foreground, background: this.state.background };
      this.props.updateInteraction('qr_detail', qr_detail);
    }
  }, {
    key: 'componentDidUpdate',
    value: function componentDidUpdate() {
      if (this.state.newQr === 1 && (this.state.background !== '#FFF' || this.state.foreground !== '#FFF' || this.props.selected.length > 0)) {
        this.setState({
          background: '#FFF',
          foreground: '#FFF',
          interactionFlag: false
        });
        this.props.updateInteraction('qr_detail', {});
        var webAccess = this.props.interaction.configuration.public_web_access;
        delete webAccess.qr_id;
        this.props.updateInteractionConfig('public_web_access', webAccess);
        this.props.unselectAllQrs();
      }
    }
  }, {
    key: 'getInteractionStatus',
    value: function getInteractionStatus(id) {
      var list = this.props.studies;
      var interaction = list.find(function (obj) {
        return obj._id === id;
      });
      if (interaction) {
        return interaction.status;
      }
    }
  }, {
    key: 'selectQr',
    value: function selectQr(index, status) {
      this.props.unselectAllQrs();
      this.props.selectQrs(index);
      var warning = ['ACTIVE', 'NOT_STARTED', 'IN_PROGRESS'];

      if (warning.indexOf(status) === -1) {
        //alert(_('SELECT USED QR ALERT'));
        this.setState((0, _extends3.default)({}, this.state, {
          interactionFlag: false
        }));
      } else {
        this.setState((0, _extends3.default)({}, this.state, {
          interactionFlag: true
        }));
      }
    }
  }, {
    key: 'sendSelectedQR',
    value: function sendSelectedQR(selected) {
      var _this3 = this;

      if (selected.length > 0) {
        var qr = selected.map(function (index) {
          return _this3.props.list[index];
        });
        var short_url = qr[0].short_url;
        var _id = qr[0]._id;
        var webAccess = this.props.interaction.configuration.public_web_access;
        webAccess.qr_id = _id;
        var qrDetail = { short_url: short_url };
        this.props.updateInteraction('qr_detail', qrDetail);
        this.props.updateInteractionConfig('public_web_access', webAccess);
      }
    }
  }, {
    key: 'handleStop',
    value: function handleStop() {
      var _this4 = this;

      if (this.props.interaction._id) {
        this.props.requestDelete(this.props.interaction._id).then(function () {
          _this4.props.pushState({ pathname: '/studies' });
        });
      } else {
        this.props.pushState({ pathname: '/studies' });
      }
    }
  }, {
    key: 'correctInteraction',
    value: function correctInteraction(interaction) {
      var corrected = interaction;
      corrected.configuration.messages.invitation.optins = [];
      var html = interaction.configuration.interaction_channels.indexOf('HTML');
      if (html > -1) {
        corrected.configuration.messages.invitation.optins.push('Visita [url]');
      }
      if (interaction.configuration.interaction_channels.indexOf('SMS') > -1) {
        var mensaje = 'Responde ' + interaction.configuration.keyword + ' para iniciar';
        if (html > -1) {
          mensaje = 'o ' + mensaje;
        }
        corrected.configuration.messages.invitation.optins.push(mensaje);
      }
      corrected.configuration.messages.invitation.message = corrected.configuration.messages.invitation.body + '\n\n' + corrected.configuration.messages.invitation.optins.join('\no ');
      if (this.props.interaction.configuration.panel_source === 'OPEN' && interaction.configuration.interaction_channels.indexOf('SMS') > -1) {
        corrected.configuration.allow_join_by_keyword = true;
      } else {
        corrected.configuration.allow_join_by_keyword = false;
      }
      if (interaction.goal && interaction.goal.filter_from_question_uid === 'NOFILTER') {
        delete corrected['goal']['filter_from_question_uid'];
      }
      interaction.questions.forEach(function (question, index) {
        if (question.response_type === 'RANGE' && question.response_alternative_type === 'NPS') {
          corrected.questions[index].range_max = 10;
          corrected.questions[index].range_min = 0;
        }
        if (question.next && question.next.type === 'CUSTOM_URL') {
          question.next.type = 'END_OF_INTERACTION';
        }
        if (question.response_alternative_type === 'HTML' || question.response_alternative_type === 'WHATSAPP') {
          var questionType = question.response_alternative_type;
          var extra = question.config;
          var buttonText = extra.buttonText;

          var buttonColor = questionType === 'HTML' ? '#FE5621' : '#25d366';
          var buttonLink = questionType === 'HTML' ? extra.buttonLink.startsWith('http') ? extra.buttonLink : 'http://' + extra.buttonLink : 'whatsapp://send?phone=' + extra.buttonLink + '&text=' + extra.buttonMessage;

          var defaultButtonHtml = '\n        <section style="\n          background: #FFF;\n          display: flex;\n          height: 50px;\n          width: 100%;\n          align-items: center;\n          justify-content: center;\n        ">\n          <a target=_BLANK data-buttonlink="' + extra.buttonLink + '" href="' + buttonLink + '" style="\n            background: ' + buttonColor + ';\n            border: none;\n            padding: 10px 20px;\n            color: #FFF;\n            cursor: pointer;\n            width: 100%;\n            margin: 0 20px;\n            border-radius: 5px;\n            text-align: center;\n            max-width: 400px;\n          " data-buttontext="' + buttonText + '"\n            data-buttonmessage="' + extra.buttonMessage + '"\n          >' + buttonText + '</a>\n        </section>';

          // Evita duplicar el botón en ciertos casos
          question.text = question.text.replace(/<section[^>]*>(.|\n)*?<\/section>/, '') + defaultButtonHtml;
        }
      });
      var config = this.props.account.data.config || {};
      if (config.triggerCallback) {
        if (config.triggerOnQuestionnaire) {
          corrected.configuration.external_integration = {
            type: 'INTERACTION_RESULT',
            url: config.triggerCallback,
            http_method: 'POST'
          };
        }
        if (config.triggerOnQuestion) {
          corrected.questions = corrected.questions.map(function (q) {
            q.external_integration = {
              type: 'QUESTION_RESULT',
              url: config.triggerCallback,
              http_method: 'POST'
            };
            return q;
          });
        }
      }
      return corrected;
    }
  }, {
    key: 'isValid',
    value: function isValid() {
      var _this5 = this;

      var open = this.props.interaction.configuration.panel_source === 'OPEN';

      if (this.props.interaction.configuration.interaction_channels.indexOf('SMS') !== -1) {
        if (this.state.keywords.filter(function (r) {
          return r.keyword === '' + _this5.props.interaction.configuration.keyword;
        }).length > 0) {
          this.setState((0, _extends3.default)({}, this.state, {
            notification: {
              field: 'keyword'
            }
          }));
          return false;
        }
      }
      if (!open && this.props.interaction.configuration.messages.invitation.body.trim() === '') {
        this.setState((0, _extends3.default)({}, this.state, {
          notification: {
            field: 'invitationmessage'
          }
        }));
        return false;
      }
      if (this.props.interaction.configuration.messages.completed.message.trim() === '') {
        this.setState((0, _extends3.default)({}, this.state, {
          notification: {
            field: 'completedmessage'
          }
        }));
        return false;
      }
      if (this.props.interaction.configuration.sending_channels.indexOf('EMAIL') > -1 && !this.props.interaction.configuration.messages.invitation.config.email_subject) {
        this.setState((0, _extends3.default)({}, this.state, {
          notification: {
            field: 'subject'
          }
        }));
        return false;
      }
      return true;
    }
  }, {
    key: 'newQr',
    value: function newQr(value) {
      this.setState((0, _extends3.default)({}, this.state, {
        newQr: value
      }));
    }
  }, {
    key: 'changeQr',
    value: function changeQr() {
      this.setState((0, _extends3.default)({}, this.state, {
        changeQr: true
      }));
    }
  }, {
    key: 'handleChangeColors',
    value: function handleChangeColors(color) {
      this.setState((0, _extends3.default)({}, this.state, {
        background: color.hex
      }));
      this.qrDetail();
    }
  }, {
    key: 'handleChangeForeground',
    value: function handleChangeForeground(color) {
      this.setState((0, _extends3.default)({}, this.state, {
        foreground: color.hex
      }));
      this.qrDetail();
    }
  }, {
    key: 'save',
    value: function save() {
      var _this6 = this;

      if (!this.props.interaction.loading) {
        return this.props.requestSave(this.correctInteraction(this.props.interaction), 'STUDY', this.state.copy, this.state.study_type).then(function () {
          _this6.props.pushState({
            pathname: '/save',
            state: {
              goTo: '/studies/',
              message: (0, _translations2.default)('STUDY SAVE SUCCESS'),
              goToModal: false,
              modal: true,
              returnTo: '/studies/',
              modalSize: { width: '500px', height: '500px' }
            }
          });
        }).catch(function (e) {
          console.error('Could not save study');
          _this6.setState((0, _extends3.default)({}, _this6.state, {
            notification: e
          }));
          _reactDom2.default.findDOMNode(_this6).scrollTop = 0;
        });
      }
    }
  }, {
    key: 'start',
    value: function start() {
      var _this7 = this;

      if (!this.props.interaction.loading && this.isValid()) {
        return this.props.requestSave(this.correctInteraction(this.props.interaction), 'STUDY', this.state.copy, this.state.study_type).then(function (interaction) {
          return _this7.props.requestStart(interaction.interaction._id).then(function (startedInteraction) {
            var sendingChannels = startedInteraction.configuration.sending_channels;
            var getModalState = function getModalState(type) {
              return {
                pathname: '/success',
                state: {
                  goTo: '/studies/share/',
                  goToQuery: { uid: startedInteraction._id, social: type },
                  message: (0, _translations2.default)('STUDY START SUCCESS'),
                  goToModal: true,
                  modal: true,
                  returnTo: '/studies/',
                  modalSize: { width: '500px', height: '500px' }
                }
              };
            };

            if (sendingChannels.length === 0) {
              _this7.props.pushState(getModalState('NONE'));
            } else if (sendingChannels.indexOf('FACEBOOK') > -1) {
              _this7.props.pushState(getModalState('FACEBOOK'));
            } else if (sendingChannels.indexOf('TWITTER') > -1) {
              _this7.props.pushState(getModalState('TWITTER'));
            } else if (sendingChannels.indexOf('WHATSAPP') > -1) {
              _this7.props.pushState(getModalState('WHATSAPP'));
            } else if (_this7.props.interaction.configuration.panel_source === 'WEBHOOK') {
              _this7.props.pushState(getModalState('API'));
            } else {
              _this7.props.pushState({
                pathname: '/success',
                state: {
                  goTo: '/studies/',
                  message: (0, _translations2.default)('STUDY START SUCCESS'),
                  goToModal: false,
                  modal: true,
                  returnTo: '/studies/',
                  modalSize: { width: '500px', height: '500px' }
                }
              });
            }
          });
        } // requestSave.then
        ) // requestStart.then
        .catch(function (e) {
          console.error('Error saving study');
          _this7.setState((0, _extends3.default)({}, _this7.state, {
            notification: e
          }));
          _reactDom2.default.findDOMNode(_this7).scrollTop = 0;
        });
      } else if (!this.isValid()) {
        console.error('Interaction was invalid');
        _reactDom2.default.findDOMNode(this).scrollTop = 0;
      }
    }
  }, {
    key: 'schedule',
    value: function schedule() {
      var _this8 = this;

      if (!this.props.interaction.loading) {
        return this.props.requestSave(this.props.interaction, 'STUDY', this.state.copy, this.state.study_type).then(function (interaction) {
          _this8.props.pushState({
            pathname: '/studies/schedule/',
            query: { uid: interaction._id },
            state: {
              modal: true,
              returnTo: '/studies/',
              modalSize: { width: '360px', height: '590px' }
            }
          });
        }).catch(function (error) {
          return console.error(error);
        });
      }
      return null;
    }
  }, {
    key: 'renderHeader',
    value: function renderHeader() {
      return !this.props.mobileMode ? _react2.default.createElement(_components.GlobalTabs, { activeStyle: {} }) : null;
    }
  }, {
    key: 'renderNav',
    value: function renderNav(stickyprops) {
      return _react2.default.createElement(_components.Nav, { title: this.getTitle(), style: stickyprops.style });
    }
  }, {
    key: 'renderQRSelector',
    value: function renderQRSelector(edit) {
      var _this9 = this;

      return _react2.default.createElement(
        'div',
        { className: _Study3.default.qr },
        _react2.default.createElement(
          'h1',
          null,
          ' ',
          (0, _translations2.default)('ASOCIATED QR'),
          ' '
        ),
        _react2.default.createElement(
          'div',
          { className: _Study3.default.container },
          edit ? _react2.default.createElement(
            _reactRouter.Link,
            { className: _Study3.default.button, onClick: this.newQr.bind(this, 1), style: { background: this.state.newQr == 1 ? _Study3.default.primary : '#FFF', color: this.state.newQr == 1 ? '#FFF' : _Study3.default.primary } },
            (0, _translations2.default)('NO QR')
          ) : null,
          _react2.default.createElement(
            _reactRouter.Link,
            { className: _Study3.default.button, onClick: this.newQr.bind(this, 2), style: { background: this.state.newQr == 2 ? _Study3.default.primary : '#FFF', color: this.state.newQr == 2 ? '#FFF' : _Study3.default.primary } },
            (0, _translations2.default)('NEW QR')
          ),
          _react2.default.createElement(
            _reactRouter.Link,
            { className: _Study3.default.button, onClick: this.newQr.bind(this, 3), style: { background: this.state.newQr == 3 ? _Study3.default.primary : '#FFF', color: this.state.newQr == 3 ? '#FFF' : _Study3.default.primary } },
            (0, _translations2.default)('SELECT QR')
          )
        ),
        this.state.newQr === 1 ? _react2.default.createElement(
          'div',
          { className: _Study3.default.new },
          _react2.default.createElement(
            'h2',
            null,
            ' ',
            (0, _translations2.default)('NO QR MESSAGE'),
            ' '
          )
        ) : this.state.newQr === 2 ? _react2.default.createElement(
          'div',
          { className: _Study3.default.new },
          _react2.default.createElement(
            'div',
            { className: _Study3.default.wrapper },
            this.state.background === '#FFF' && this.state.foreground === '#FFF' ? _react2.default.createElement(
              'div',
              { className: _Study3.default.selectColors },
              _react2.default.createElement(
                'div',
                { className: _Study3.default.text },
                ' ',
                (0, _translations2.default)('SELECT QR COLORS TEXT'),
                ' '
              ),
              _react2.default.createElement(
                'div',
                { className: _Study3.default.detail },
                ' ',
                (0, _translations2.default)('SELECT QR COLORS DETAIL'),
                ' '
              )
            ) : _react2.default.createElement(
              'div',
              { style: { backgroundColor: this.state.background } },
              _react2.default.createElement(_ImageQr2.default, { foreground: this.state.foreground })
            ),
            _react2.default.createElement(
              'div',
              { className: _Study3.default.colors },
              _react2.default.createElement(
                'h1',
                null,
                ' ',
                (0, _translations2.default)('SELECT COLOR BACKGROUND'),
                ' '
              ),
              _react2.default.createElement(_reactColor.TwitterPicker, { triangle: 'hide', colors: ['#83174B', '#FC433E', '#FD852F', '#FEDB32', '#39CA49', '#42CCCB', '#1376D6', '#F5F5F5', '#DDDDDD', '#111111'], color: this.state.background, onChangeComplete: this.handleChangeColors }),
              _react2.default.createElement('div', null),
              _react2.default.createElement(
                'h1',
                null,
                ' ',
                (0, _translations2.default)('SELECT COLOR FOREGROUND'),
                ' '
              ),
              _react2.default.createElement(_reactColor.TwitterPicker, { triangle: 'hide', colors: ['#83174B', '#FC433E', '#FD852F', '#FEDB32', '#39CA49', '#42CCCB', '#1376D6', '#F5F5F5', '#DDDDDD', '#111111'], color: this.state.foreground, onChangeComplete: this.handleChangeForeground }),
              _react2.default.createElement('div', null)
            )
          )
        ) : _react2.default.createElement(
          'div',
          null,
          this.state.interactionFlag ? _react2.default.createElement(
            'div',
            { className: _Study3.default.warningMessage },
            _react2.default.createElement(
              'div',
              { className: _Study3.default.text },
              ' ',
              (0, _translations2.default)('SELECT USED QR ALERT DETAIL'),
              ' '
            ),
            _react2.default.createElement(
              'div',
              { className: _Study3.default.detail },
              ' ',
              (0, _translations2.default)('SELECT USED QR ALERT'),
              ' '
            )
          ) : null,
          _react2.default.createElement(
            'div',
            { className: _Study3.default.select },
            this.props.list.length > 0 ? this.props.list.map(function (item, index) {
              return _react2.default.createElement(_components.VirtualListItemQr, {
                key: index,
                index: index,
                name: item.name,
                url: item.short_url,
                status: _this9.getInteractionStatus(item.urls.study_uid),
                label: _this9.statusTranslations[_this9.getInteractionStatus(item.urls.study_uid)],
                onClick: function onClick() {
                  return _this9.selectQr(index, _this9.getInteractionStatus(item.urls.study_uid));
                },
                selected: _this9.props.selected
              });
            }) : _react2.default.createElement(
              'h1',
              null,
              ' ',
              (0, _translations2.default)('NO QRS')
            )
          )
        )
      );
    }
  }, {
    key: 'getFileName',
    value: function getFileName(filePath) {
      if (filePath) {
        var name = filePath.substring(filePath.lastIndexOf("/") + 1);
        var path = (0, _useBasename2.default)('/downloads/qr-files/' + name);
        return path;
      }
    }
  }, {
    key: 'renderQR',
    value: function renderQR() {
      var _this10 = this;

      if (this.state.qr._id && this.props.interaction._id) {
        if (!this.state.qr.urls.new_url) {
          return _react2.default.createElement(
            'section',
            { className: _Study3.default.section },
            _react2.default.createElement(
              'div',
              { className: _Study3.default.footerDownload },
              _react2.default.createElement(
                'h1',
                null,
                ' ',
                (0, _translations2.default)('ASOCIATED QR'),
                ' '
              ),
              _react2.default.createElement('img', { src: this.getFileName(this.state.qr.path_s3) }),
              _react2.default.createElement(
                'a',
                { className: _Study3.default.download, target: '_blank', href: this.getFileName(this.state.qr.path_s3), download: true },
                ' ',
                (0, _translations2.default)('DOWNLOAD QR'),
                ' '
              ),
              _react2.default.createElement(
                'div',
                { className: _Study3.default.detail },
                ' ',
                (0, _translations2.default)('DOWNLOAD QR DETAIL'),
                ' '
              )
            )
          );
        }
        return _react2.default.createElement(
          'section',
          { className: _Study3.default.section },
          this.state.changeQr ? this.renderQRSelector(false) : _react2.default.createElement(
            'div',
            { className: _Study3.default.footerDownload },
            _react2.default.createElement(
              'h1',
              null,
              ' ',
              (0, _translations2.default)('ASOCIATED QR'),
              ' '
            ),
            _react2.default.createElement(
              'h1',
              { className: _Study3.default.errorMessage },
              ' ',
              (0, _translations2.default)('NO VALID INTERACTION QR')
            ),
            _react2.default.createElement(
              'button',
              { className: _Study3.default.change, onClick: function onClick() {
                  return _this10.changeQr();
                } },
              ' ',
              (0, _translations2.default)('CHANGE QR'),
              ' '
            )
          )
        );
      }

      return _react2.default.createElement(
        'section',
        { className: _Study3.default.section },
        this.renderQRSelector(true)
      );
    }
  }, {
    key: 'renderBreadcrumbs',
    value: function renderBreadcrumbs() {
      return _react2.default.createElement(_components.Breadcrumbs, { items: [{
          to: '/studies/',
          text: (0, _translations2.default)('STUDIES')
        }, {
          to: this.props.location.pathname,
          text: this.getTitle()
        }] });
    }
  }, {
    key: 'renderRight',
    value: function renderRight() {
      return null;
    }
  }, {
    key: 'getUrlIframe',
    value: function getUrlIframe() {

      if (this.props.location.query.uid != null) {
        // var url = "http://"+config.v5Host+":"+config.v5Port+"/v5/notificationModal"
        var url = "/v5/notificationModal";
        var params = this.getQryParamsNoti();
        url = url + params;
        return url;
      } else {
        return null;
      }
    }
  }, {
    key: 'getQryParamsNoti',
    value: function getQryParamsNoti() {
      var qryParams = "";
      var params = {};
      params.referenceId = this.props.location.query.uid;
      params.referenceName = this.getTitle();
      params.tipoId = "6262e6e32e87ac8f06da1841"; //Tipo Study
      params.tipoName = this.state.study_type;

      //params to query params url string
      for (var key in params) {
        if (params.hasOwnProperty(key)) {
          qryParams += (qryParams == "" ? "?" : "&") + key + "=" + params[key];
        }
      }
      qryParams = qryParams.replace(/ /g, "%20");
      return qryParams;
    }
  }, {
    key: 'render',
    value: function render() {

      if (!this.props.interaction || !this.props.interaction.configuration || !this.props.interaction.configuration.panel_source) {
        return 'Error';
      }

      var open = this.props.interaction.configuration.panel_source === 'OPEN';
      var panels = open ? 'OPEN' : 'DATA_STORE';
      var channels = open ? ['NONE', 'FACEBOOK', 'TWITTER', 'WHATSAPP'] : ['SMS', 'APP', 'EMAIL', 'TELEGRAM'];
      var messages = open ? ['completed'] : ['invitation', 'completed'];

      var errorMessage = (0, _translations2.default)('GENERIC INTERACTION ERROR');
      switch (this.state.notification.field) {
        case 'name':
          errorMessage = (0, _translations2.default)('ERROR IN NAME FIELD');
          break;
        case 'invitationmessage':
          errorMessage = (0, _translations2.default)('ERROR IN INVITATION MESSAGE FIELD');
          break;
        case 'completedmessage':
          errorMessage = (0, _translations2.default)('ERROR IN COMPLETED MESSAGE FIELD');
          break;
        case 'subject':
          errorMessage = (0, _translations2.default)('ERROR IN SUBJECT FIELD');
          break;
        default:
          if (this.state.notification.message === 'INVALID KEYWORD') {
            errorMessage = (0, _translations2.default)('ERROR IN KEYWORD FIELD');
          } else if (this.state.notification.message) {
            errorMessage = this.state.notification.message;
          } else {
            // console.error('Error message from api was blank')
          }
      }

      var classesTheme = (0, _themeEnabled.getThemeEnabledStyle)(_Study3.default);
      return _react2.default.createElement(
        'div',
        { className: classesTheme },
        _react2.default.createElement(
          'article',
          { className: _Study3.default.study, id: 'study', ref: 'study' },
          _react2.default.createElement(_components.MobileBar, { returnTo: '/studies/' }),
          _react2.default.createElement(_reactHelmet2.default, { title: open ? (0, _translations2.default)('NEW INTERACTION') : (0, _translations2.default)('NEW STUDY') }),
          this.state.notification ? _react2.default.createElement(
            'label',
            { className: _Study3.default.error },
            errorMessage
          ) : null,
          _react2.default.createElement(
            'section',
            { className: _Study3.default.section },
            _react2.default.createElement(_Study.Name, null)
          ),
          _react2.default.createElement(
            'section',
            { className: _Study3.default.section },
            _react2.default.createElement(_Study.InteractionChannels, { invitation: this.state.invitation, defineInvitation: this.defineInvitation })
          ),
          _react2.default.createElement(
            'section',
            { className: _Study3.default.section },
            _react2.default.createElement(_Study.Questionnaire, null)
          ),
          !this.props.api && _react2.default.createElement(
            'section',
            { key: 1, className: _Study3.default.section },
            _react2.default.createElement(_Study.Panel, { panels: panels, reservedKeywords: this.state.keywords })
          ),
          this.props.interaction.configuration.panel_source === 'OPEN' && this.props.interaction.configuration.interaction_channels.indexOf('HTML') === -1 ? null : _react2.default.createElement(
            'section',
            { key: 2, className: _Study3.default.section },
            _react2.default.createElement(_Study.SendingChannels, { channelTypes: channels })
          ),
          _react2.default.createElement(
            'section',
            { key: 3, className: _Study3.default.section },
            _react2.default.createElement(_Study.Messages, { defineInvitation: this.defineInvitation, invitation: this.state.invitation, messageTypes: messages, reservedKeywords: this.state.keywords, noTitle: true })
          ),
          _react2.default.createElement(
            'section',
            { key: 4, className: _Study3.default.section },
            this.state.batches ? _react2.default.createElement(_Study.Configuration, { batches: this.state.batches }) : null
          ),
          this.props.interaction.configuration.panel_source === 'OPEN' && this.props.interaction.configuration.interaction_channels.indexOf('HTML') > -1 ? this.renderQR() : null,
          _react2.default.createElement(
            _Allow2.default,
            { permissions: ['notification:admin'] },
            this.state.paramsIframe ? _react2.default.createElement(
              'section',
              { key: 5, className: _Study3.default.section },
              _react2.default.createElement('iframe', { src: this.state.paramsIframe,
                'class': _Study3.default.iframeStudy })
            ) : null
          ),
          _react2.default.createElement(
            'div',
            { className: _Study3.default.footer },
            _react2.default.createElement(
              _Allow2.default,
              { permissions: ['study:edit'] },
              _react2.default.createElement(_components.Button, { onClick: this.save, name: (0, _translations2.default)('STUDY SAVE'), className: _Study3.default.buttonSave })
            ),
            _react2.default.createElement(
              _Allow2.default,
              { permissions: ['study:start'] },
              _react2.default.createElement(_components.Button, { onClick: this.start, name: (0, _translations2.default)('STUDY START'), className: _Study3.default.buttonStart })
            )
          )
        )
      );
    }
  }]);
  return Study;
}(_react2.default.Component), _class3.propTypes = {
  params: _propTypes2.default.object.isRequired,
  interaction: _propTypes2.default.object.isRequired,
  location: _propTypes2.default.object.isRequired,
  mobileMode: _propTypes2.default.bool.isRequired,
  requestStart: _propTypes2.default.func.isRequired,
  requestSave: _propTypes2.default.func.isRequired,
  pushState: _propTypes2.default.func.isRequired,
  requestDelete: _propTypes2.default.func.isRequired,
  studiesRefresh: _propTypes2.default.func.isRequired,
  getReservedKeywords: _propTypes2.default.func.isRequired,
  api: _propTypes2.default.bool,
  account: _propTypes2.default.object.isRequired
}, _class3.defaultProps = {
  api: false
}, _temp), (_applyDecoratedDescriptor(_class2.prototype, 'handleStop', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'handleStop'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, 'handleChangeColors', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'handleChangeColors'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, 'handleChangeForeground', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'handleChangeForeground'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, 'save', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'save'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, 'start', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'start'), _class2.prototype)), _class2)) || _class) || _class);
exports.default = Study;
var OpenStudy = exports.OpenStudy = (_dec3 = (0, _reduxConnect.asyncConnect)([{
  key: "auth",
  promise: function promise(_ref3) {
    var _ref3$store = _ref3.store,
        dispatch = _ref3$store.dispatch,
        getState = _ref3$store.getState;

    if (!(0, _auth.isLoaded)(getState())) {
      return dispatch((0, _auth.load)());
    }
    return null;
  }
}, {
  key: 'interaction',
  promise: function promise(_ref4) {
    var query = _ref4.location.query,
        _ref4$store = _ref4.store,
        dispatch = _ref4$store.dispatch,
        getState = _ref4$store.getState;

    var state = getState();
    var copy = query.copy,
        uid = query.uid;

    if (copy) {
      if (!state.interaction || state.interaction.uid !== uid) {
        var interaction = (0, _interaction.getInteractionCopy)(uid);
        return dispatch(interaction);
      }
    } else if (uid) {
      return dispatch((0, _interaction.getInteraction)(uid));
    }
    return dispatch((0, _interaction.getNewInteraction)('STUDY', 'OPEN'));
  }
}]), _dec4 = (0, _reactRedux.connect)(function (state) {
  return {
    mobileMode: state.mobile.mobileMode,
    interaction: state.interaction
  };
}, { getAccount: _account.getAccount, requestStart: _interaction.requestStart, requestSave: _interaction.requestSave, pushState: _reachHistory.push }), _dec3(_class4 = _dec4(_class4 = function (_React$Component2) {
  (0, _inherits3.default)(OpenStudy, _React$Component2);

  function OpenStudy() {
    (0, _classCallCheck3.default)(this, OpenStudy);
    return (0, _possibleConstructorReturn3.default)(this, (OpenStudy.__proto__ || (0, _getPrototypeOf2.default)(OpenStudy)).apply(this, arguments));
  }

  (0, _createClass3.default)(OpenStudy, [{
    key: 'render',
    value: function render() {
      var props = (0, _extends3.default)({}, this.props, { study_type: _interaction.STUDY_OPEN });
      return _react2.default.createElement(Study, (0, _extends3.default)({}, props, { open: true }));
    }
  }]);
  return OpenStudy;
}(_react2.default.Component)) || _class4) || _class4);
var ApiStudy = exports.ApiStudy = (_dec5 = (0, _reduxConnect.asyncConnect)([{
  key: "auth",
  promise: function promise(_ref5) {
    var _ref5$store = _ref5.store,
        dispatch = _ref5$store.dispatch,
        getState = _ref5$store.getState;

    if (!(0, _auth.isLoaded)(getState())) {
      return dispatch((0, _auth.load)());
    }
    return null;
  }
}, {
  key: 'interaction',
  promise: function promise(_ref6) {
    var query = _ref6.location.query,
        _ref6$store = _ref6.store,
        dispatch = _ref6$store.dispatch,
        getState = _ref6$store.getState;

    var state = getState();
    var copy = query.copy,
        uid = query.uid;

    if (copy) {
      if (!state.interaction || state.interaction.uid !== uid) {
        return dispatch((0, _interaction.getInteractionCopy)(uid));
      }
    } else if (uid) {
      return dispatch((0, _interaction.getInteraction)(uid));
    }
    return dispatch((0, _interaction.getNewInteraction)('STUDY', 'API'));
  }
}]), _dec6 = (0, _reactRedux.connect)(function (state) {
  return {
    mobileMode: state.mobile.mobileMode,
    interaction: state.interaction
  };
}, {
  getAccount: _account.getAccount, requestStart: _interaction.requestStart, requestSave: _interaction.requestSave, pushState: _reachHistory.push
}), _dec5(_class5 = _dec6(_class5 = function (_React$Component3) {
  (0, _inherits3.default)(ApiStudy, _React$Component3);

  function ApiStudy() {
    (0, _classCallCheck3.default)(this, ApiStudy);
    return (0, _possibleConstructorReturn3.default)(this, (ApiStudy.__proto__ || (0, _getPrototypeOf2.default)(ApiStudy)).apply(this, arguments));
  }

  (0, _createClass3.default)(ApiStudy, [{
    key: 'render',
    value: function render() {
      var props = (0, _extends3.default)({}, this.props, { study_type: _interaction.STUDY_API });
      return _react2.default.createElement(Study, (0, _extends3.default)({}, props, { api: true }));
    }
  }]);
  return ApiStudy;
}(_react2.default.Component)) || _class5) || _class5);
var StudyShare = exports.StudyShare = (_dec7 = (0, _reduxConnect.asyncConnect)([{
  key: 'interaction',
  promise: function promise(_ref7) {
    var query = _ref7.location.query,
        dispatch = _ref7.store.dispatch;
    var uid = query.uid;

    if (uid) {
      return dispatch((0, _interaction.getInteraction)(uid));
    }
    return dispatch((0, _reachHistory.push)('/studies/'));
  }
}]), _dec8 = (0, _reactRedux.connect)(function (state) {
  return {
    mobileMode: state.mobile.mobileMode,
    interaction: state.interaction
  };
}, { pushState: _reachHistory.push }), _dec7(_class6 = _dec8(_class6 = (_temp2 = _class7 = function (_React$Component4) {
  (0, _inherits3.default)(StudyShare, _React$Component4);

  function StudyShare(props) {
    (0, _classCallCheck3.default)(this, StudyShare);

    var _this13 = (0, _possibleConstructorReturn3.default)(this, (StudyShare.__proto__ || (0, _getPrototypeOf2.default)(StudyShare)).call(this, props));

    _this13.state = {
      copied: false
    };
    return _this13;
  }

  (0, _createClass3.default)(StudyShare, [{
    key: 'openFacebook',
    value: function openFacebook() {
      var shareUrl = this.props.interaction.configuration.public_web_access.short_uri || this.props.interaction.configuration.public_web_access.full_uri;
      window.open('https://www.facebook.com/sharer/sharer.php?u=' + shareUrl + '&display=popup', '_blank', 'width=700,height=500');
    }
  }, {
    key: 'openTwitter',
    value: function openTwitter() {
      var shareUrl = encodeURIComponent(this.props.interaction.configuration.public_web_access.short_uri || this.props.interaction.configuration.public_web_access.full_uri);
      var someCustomText = encodeURIComponent((0, _translations2.default)('TWITTER CUSTOM TEXT'));
      window.open('https://twitter.com/intent/tweet?text=' + someCustomText + '&url=' + shareUrl, '_blank', 'width=700,height=500');
    }
  }, {
    key: 'openWhatsapp',
    value: function openWhatsapp() {
      var shareUrl = encodeURIComponent(this.props.interaction.configuration.public_web_access.short_uri || this.props.interaction.configuration.public_web_access.full_uri);

      var someCustomText = encodeURIComponent((0, _translations2.default)('WHATSAPP CUSTOM TEXT'));
      window.open('https://api.whatsapp.com/send?text=' + someCustomText + '  ' + shareUrl, '_blank', 'width=700,height=500');
    }
  }, {
    key: 'renderFacebook',
    value: function renderFacebook() {

      var logo = require('components/Study/svg/facebook.svg');

      return _react2.default.createElement(
        'a',
        { onClick: this.openFacebook.bind(this), className: _Study3.default.facebook },
        _react2.default.createElement(
          'h1',
          { className: _Study3.default.title },
          this.props.interaction.name
        ),
        _react2.default.createElement('div', { className: _Study3.default.logo, dangerouslySetInnerHTML: { __html: logo } }),
        _react2.default.createElement(
          'h1',
          { className: _Study3.default.title },
          (0, _translations2.default)('SHARE TO FACEBOOK')
        )
      );
    }
  }, {
    key: 'renderTwitter',
    value: function renderTwitter() {
      var logo = require('components/Study/svg/twitter.svg');

      return _react2.default.createElement(
        'a',
        { onClick: this.openTwitter.bind(this), className: _Study3.default.twitter },
        _react2.default.createElement(
          'h1',
          { className: _Study3.default.title },
          this.props.interaction.name
        ),
        _react2.default.createElement('div', { className: _Study3.default.logo, dangerouslySetInnerHTML: { __html: logo } }),
        _react2.default.createElement(
          'h1',
          { className: _Study3.default.title },
          (0, _translations2.default)('SHARE TO TWITTER')
        )
      );
    }
  }, {
    key: 'renderWhatsapp',
    value: function renderWhatsapp() {
      var logo = require('components/Study/svg/whatsapp.svg');

      return _react2.default.createElement(
        'a',
        { onClick: this.openWhatsapp.bind(this), className: _Study3.default.whatsapp },
        _react2.default.createElement(
          'h1',
          { className: _Study3.default.title },
          this.props.interaction.name
        ),
        _react2.default.createElement('div', { className: _Study3.default.logo, dangerouslySetInnerHTML: { __html: logo } }),
        _react2.default.createElement(
          'h1',
          { className: _Study3.default.title },
          (0, _translations2.default)('SHARE TO WHATSAPP')
        )
      );
    }
  }, {
    key: 'renderDefault',
    value: function renderDefault() {
      var _this14 = this;

      var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'INTERACTION';

      if (type === 'INTERACTION') {
        if (!this.props.interaction || !this.props.interaction.configuration) {
          return null;
        }

        var logo = require('components/Study/svg/copy.svg');
        var shareUrl = this.props.interaction.configuration.public_web_access.short_uri || this.props.interaction.configuration.public_web_access.full_uri;

        return _react2.default.createElement(
          'a',
          { className: _Study3.default.default },
          _react2.default.createElement(
            'h1',
            { className: _Study3.default.title },
            this.props.interaction.name
          ),
          _react2.default.createElement('div', { className: _Study3.default.logo, dangerouslySetInnerHTML: { __html: logo } }),
          _react2.default.createElement(
            'div',
            { className: _Study3.default.textarea },
            _react2.default.createElement('textarea', { readOnly: true, className: _Study3.default.title, id: 'texttest', defaultValue: shareUrl })
          ),
          _react2.default.createElement(
            _reactCopyToClipboard2.default,
            {
              text: shareUrl,
              onCopy: function onCopy() {
                return _this14.setState({ copied: true });
              }
            },
            _react2.default.createElement(
              'button',
              null,
              (0, _translations2.default)('COPY TO CLIPBOARD')
            )
          ),
          this.state.copied ? _react2.default.createElement(
            'div',
            { style: { color: 'white', margin: '10px' } },
            (0, _translations2.default)('COPIED TO CLIPBOARD')
          ) : null
        );
      } else if (type === 'PROFILING') {
        return _react2.default.createElement(
          'h1',
          null,
          ' ',
          (0, _translations2.default)("CANT COPY URL, THIS IS NOT AN INTERACTION")
        );
      }
    }
  }, {
    key: 'renderApi',
    value: function renderApi() {
      var _this15 = this;

      if (!this.props.interaction || !this.props.interaction.configuration) {
        return null;
      }
      var styles = require('./Study.scss');
      var baseUrl = window.location ? window.location.origin : 'http://comunicador.tigo.com.gt';
      var shareUrl = baseUrl + '/webhooks/studies/' + this.props.interaction.external_uid + '/profile/add';

      return _react2.default.createElement(
        'a',
        { className: styles.api },
        _react2.default.createElement(
          'h1',
          { className: styles.title },
          this.props.interaction.name
        ),
        _react2.default.createElement('img', { className: styles.logo, src: (0, _useBasename2.default)('/static/share/apislogo.svg'), alt: '' }),
        _react2.default.createElement(
          'div',
          { className: styles.textarea },
          _react2.default.createElement('input', { readOnly: true, id: 'texttest', defaultValue: shareUrl })
        ),
        _react2.default.createElement(
          _reactCopyToClipboard2.default,
          {
            text: shareUrl,
            onCopy: function onCopy() {
              return _this15.setState({ copied: true });
            }
          },
          _react2.default.createElement(
            'button',
            null,
            (0, _translations2.default)('COPY API URL TO CLIPBOARD')
          )
        ),
        this.state.copied ? _react2.default.createElement(
          'div',
          {
            style: {
              color: 'white', margin: '10px', position: 'absolute', top: '482px'
            }
          },
          (0, _translations2.default)('COPIED TO CLIPBOARD')
        ) : null
      );
    }
  }, {
    key: 'render',
    value: function render() {
      var renderBlock = void 0;
      switch (this.props.location.query.social) {
        case 'TWITTER':
          renderBlock = this.renderTwitter.bind(this);
          break;
        case 'WHATSAPP':
          renderBlock = this.renderWhatsapp.bind(this);
          break;
        case 'FACEBOOK':
          renderBlock = this.renderFacebook.bind(this);
          break;
        case 'PROFILING':
          renderBlock = this.renderDefault.bind(this, 'PROFILING');
          break;
        case 'API':
          renderBlock = this.renderApi.bind(this);
          break;
        default:
          renderBlock = this.renderDefault.bind(this);
      }

      return _react2.default.createElement(
        'div',
        { className: _Study3.default.share },
        renderBlock()
      );
    }
  }]);
  return StudyShare;
}(_react2.default.Component), _class7.propTypes = {
  interaction: _propTypes2.default.object.isRequired,
  mobileMode: _propTypes2.default.bool.isRequired,
  pushState: _propTypes2.default.func.isRequired,
  location: _propTypes2.default.object.isRequired
}, _temp2)) || _class6) || _class6);
var NewStudySelector = exports.NewStudySelector = (_dec9 = (0, _reactRedux.connect)(function (state) {
  return { account: state.account.data, mobileMode: state.mobile.mobileMode };
}), _dec9(_class8 = (_temp3 = _class9 = function (_React$Component5) {
  (0, _inherits3.default)(NewStudySelector, _React$Component5);

  function NewStudySelector() {
    (0, _classCallCheck3.default)(this, NewStudySelector);
    return (0, _possibleConstructorReturn3.default)(this, (NewStudySelector.__proto__ || (0, _getPrototypeOf2.default)(NewStudySelector)).apply(this, arguments));
  }

  (0, _createClass3.default)(NewStudySelector, [{
    key: 'render',
    value: function render() {
      return _react2.default.createElement(
        _react2.default.Fragment,
        null,
        _react2.default.createElement(_components.MobileBar, { returnTo: '/studies/' }),
        _react2.default.createElement(
          _components.ButtonModal,
          null,
          _react2.default.createElement(
            'h1',
            null,
            (0, _translations2.default)('NEW STUDY PROMPT')
          ),
          _react2.default.createElement(
            _reactRouter.Link,
            {
              className: 'action',
              to: {
                pathname: '/studies/study/',
                state: { modal: !this.props.mobileMode, returnTo: '/studies', fullModal: true }
              }
            },
            _react2.default.createElement(md.add, { size: 30, color: '#616161' }),
            _react2.default.createElement(
              'h2',
              null,
              'Por invitaci\xF3n'
            )
          ),
          _react2.default.createElement(
            _reactRouter.Link,
            {
              className: 'action',
              to: {
                pathname: '/studies/open/',
                state: { modal: true, returnTo: '/studies', fullModal: true }
              }
            },
            _react2.default.createElement(md.add2, { size: 30, color: '#616161' }),
            _react2.default.createElement(
              'h2',
              null,
              (0, _translations2.default)('OPEN STUDY')
            )
          ),
          (0, _featureEnabled.featureEnabled)('webhooks') ? _react2.default.createElement(
            _reactRouter.Link,
            {
              className: 'action',
              to: {
                pathname: '/studies/api/',
                state: { modal: true, returnTo: '/studies', fullModal: true }
              }
            },
            _react2.default.createElement('img', { src: (0, _useBasename2.default)('/static/apisslate.svg') }),
            _react2.default.createElement(
              'h2',
              null,
              (0, _translations2.default)('API STUDY')
            )
          ) : null
        )
      );
    }
  }]);
  return NewStudySelector;
}(_react2.default.Component), _class9.propTypes = {
  mobileMode: _propTypes2.default.bool.isRequired,
  location: _propTypes2.default.object.isRequired,
  account: _propTypes2.default.object.isRequired
}, _temp3)) || _class8);