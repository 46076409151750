'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = undefined;

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _ColorPicker = require('./ColorPicker.scss');

var _ColorPicker2 = _interopRequireDefault(_ColorPicker);

var _reactColor = require('react-color');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ColorPicker = function (_React$Component) {
    (0, _inherits3.default)(ColorPicker, _React$Component);

    function ColorPicker(props) {
        (0, _classCallCheck3.default)(this, ColorPicker);

        var _this = (0, _possibleConstructorReturn3.default)(this, (ColorPicker.__proto__ || (0, _getPrototypeOf2.default)(ColorPicker)).call(this, props));

        _this.handleChangeComplete = function (color, event) {
            _this.props.handleData(color.hex);
            _this.setState({ background: color.hex });
        };

        _this.state = {
            background: '#ba68c8'
        };
        return _this;
    }

    (0, _createClass3.default)(ColorPicker, [{
        key: 'render',
        value: function render() {
            return _react2.default.createElement(
                'div',
                { className: _ColorPicker2.default.mainContainer },
                _react2.default.createElement(
                    'div',
                    { className: _ColorPicker2.default.customShowColors, style: { backgroundColor: '' + this.state.background } },
                    _react2.default.createElement(
                        'span',
                        null,
                        this.state.background
                    )
                ),
                _react2.default.createElement(
                    'div',
                    { className: _ColorPicker2.default.blockPicker },
                    _react2.default.createElement(_reactColor.TwitterPicker, { color: this.state.background, onChangeComplete: this.handleChangeComplete })
                ),
                _react2.default.createElement(
                    'div',
                    { className: _ColorPicker2.default.text },
                    _react2.default.createElement(
                        'a',
                        null,
                        'Selecciona un color de fondo '
                    )
                )
            );
        }
    }]);
    return ColorPicker;
}(_react2.default.Component);

exports.default = ColorPicker;
module.exports = exports['default'];