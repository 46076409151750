'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _themeEnabled = require('helpers/themeEnabled');

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _reduxForm = require('redux-form');

var _components = require('components');

var _string = require('helpers/string');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// import DropzoneField from '../DropZone/DropZone'

var md = {
  send: require('react-icons/lib/md/send')
};
// import Textarea from 'react-textarea-autosize'


var MessageTextarea = function MessageTextarea(props) {
  var styles = require('./Message.scss');
  var messageLength = 160;
  var messageLengthMultiple = 153;
  //const length = props.input.value.length
  var maxLength = props.maxLength ? props.maxLength : 612;

  var _getSmsPreview = (0, _string.getSmsPreview)(props.shortName, props.input.value, messageLength, messageLengthMultiple),
      _getSmsPreview2 = (0, _slicedToArray3.default)(_getSmsPreview, 2),
      previewText = _getSmsPreview2[0],
      length = _getSmsPreview2[1];

  return _react2.default.createElement(
    'div',
    { className: styles.messageInput },
    _react2.default.createElement(
      'textarea',
      (0, _extends3.default)({ name: 'message' }, props.input, { className: props.className, placeholder: (0, _translations2.default)('MESSAGE PLACEHOLDER'), onKeyUp: props.onTextAreaChange, maxLength: maxLength }),
      ' '
    ),
    _react2.default.createElement(
      'div',
      { className: styles.previewContainer },
      _react2.default.createElement(
        'label',
        { className: styles.previewLabel },
        (0, _translations2.default)('PREVIEW'),
        ':'
      ),
      _react2.default.createElement('div', { className: styles.previewText, dangerouslySetInnerHTML: { __html: previewText } })
    ),
    _react2.default.createElement(
      'span',
      { className: styles.counter + ' ' + (props.meta.pristine ? styles.pristine : '') },
      (0, _translations2.default)('CHARACTERS'),
      ': ',
      length,
      '\xA0',
      (0, _translations2.default)('MESSAGES'),
      ': ',
      Math.ceil(length / (length > messageLength ? messageLengthMultiple : messageLength))
    )
  );
};

MessageTextarea.propTypes = {
  input: _propTypes2.default.object.isRequired,
  meta: _propTypes2.default.object.isRequired,
  className: _propTypes2.default.string,
  onTextAreaChange: _propTypes2.default.func,
  maxLength: _propTypes2.default.number
};

var MessageFormContainer = function MessageFormContainer(props) {
  var handleSubmit = props.handleSubmit,
      pristine = props.pristine,
      reset = props.reset,
      postMessage = props.postMessage,
      isEdit = props.isEdit,
      selected = props.selected,
      maxLength = props.maxLength,
      shortName = props.shortName;

  var styles = require('./Message.scss');
  var submitting = props.submitting || props.loading || false;
  var pristineClass = pristine ? styles.pristine : '';
  var submittingClass = submitting ? styles.submitting : '';

  var showButton = function showButton() {
    if (isEdit == true) {
      return '';
    }
    return _react2.default.createElement(
      'button',
      {
        type: 'submit',
        disabled: !selected || pristine || submitting,
        className: styles.submit + ' ' + styles.right + ' ' + pristineClass + ' ' + submittingClass + ' ' + styles.now
      },
      submitting ? _react2.default.createElement('button', { className: styles.nowLoading, text: (0, _translations2.default)('SEND NOW') }) : (0, _translations2.default)('SEND NOW')
    );
  };

  var classesTheme = (0, _themeEnabled.getThemeEnabledStyle)(styles);

  return _react2.default.createElement(
    'div',
    { className: classesTheme },
    _react2.default.createElement(
      'form',
      { onSubmit: handleSubmit(postMessage.bind(null, reset)), className: styles.compose },
      _react2.default.createElement(_reduxForm.Field, { name: 'message', className: styles.message, value: props.value, shortName: shortName, onTextAreaChange: props.onTextAreaChange, maxLength: maxLength, component: MessageTextarea }),
      showButton()
    )
  );
};

MessageFormContainer.propTypes = {
  postMessage: _propTypes2.default.func.isRequired,
  loading: _propTypes2.default.bool,
  handleSubmit: _propTypes2.default.func.isRequired,
  pristine: _propTypes2.default.bool.isRequired,
  reset: _propTypes2.default.func.isRequired,
  submitting: _propTypes2.default.bool.isRequired,
  initialValues: _propTypes2.default.string,
  onTextAreaChange: _propTypes2.default.func
};

exports.default = (0, _reduxForm.reduxForm)({
  form: 'MessageForm'
})(MessageFormContainer);
module.exports = exports['default'];