'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actions = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

exports.default = reducer;
exports.getChildAccountUsers = getChildAccountUsers;
exports.createChildAccountUsers = createChildAccountUsers;
exports.deleteChildAccountUsers = deleteChildAccountUsers;
exports.updateChildAccountUsers = updateChildAccountUsers;
exports.selectChildAccountUsers = selectChildAccountUsers;
exports.unselectChildAccountUsers = unselectChildAccountUsers;
exports.unselectAllChildAccountUsers = unselectAllChildAccountUsers;
exports.trimChildAccountUsers = trimChildAccountUsers;
exports.refreshChildAccountUsers = refreshChildAccountUsers;

var _apiAction = require('../apiAction');

var _apiAction2 = _interopRequireDefault(_apiAction);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var actions = exports.actions = new _apiAction2.default('SUBACCOUNTUSERS', [_apiAction.ACTION_TYPES.DATA, _apiAction.ACTION_TYPES.TRIM, _apiAction.ACTION_TYPES.APPEND, _apiAction.ACTION_TYPES.SELECT]);

var initialState = {
  loading: false,
  status: actions.APPEND,
  list: [],
  selected: [],
  data: {}
};

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  return actions.getStateForAction(state, action);
}

function getChildAccountUsers(params) {
  return {
    types: [actions.APPEND, actions.APPEND_SUCCESS, actions.APPEND_FAIL],
    promise: function promise(client) {
      return client.get('/reseller/users/', {
        params: params
      });
    }
  };
}

function createChildAccountUsers(user, accountsRoles) {
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.post('/reseller/users/', {
        data: {
          user: user,
          accounts_roles: accountsRoles
        }
      });
    }
  };
}

function deleteChildAccountUsers(userUid, accountUid) {
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.del('/reseller/users/subaccount/' + userUid + '/' + accountUid);
    }
  };
}

function updateChildAccountUsers(userUid, user_data, subaccount_roles) {
  return {
    types: [actions.DATA, actions.DATA_SUCCESS, actions.DATA_FAIL],
    promise: function promise(client) {
      return client.put('/reseller/users/' + userUid, {
        data: (0, _extends3.default)({}, subaccount_roles, {
          user: user_data
        })
      });
    }
  };
}

function selectChildAccountUsers(index) {
  return {
    type: actions.SELECT,
    index: index
  };
}

function unselectChildAccountUsers(index) {
  return {
    type: actions.SELECT_OFF,
    index: index
  };
}

function unselectAllChildAccountUsers() {
  return {
    type: actions.SELECT_NONE
  };
}

function trimChildAccountUsers(count) {
  return {
    type: actions.TRIM,
    count: count
  };
}

function refreshChildAccountUsers() {
  return {
    type: actions.TRIM,
    count: 0
  };
}