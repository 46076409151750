'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.VariableInsert = undefined;

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _getOwnPropertyDescriptor = require('babel-runtime/core-js/object/get-own-property-descriptor');

var _getOwnPropertyDescriptor2 = _interopRequireDefault(_getOwnPropertyDescriptor);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _promise = require('babel-runtime/core-js/promise');

var _promise2 = _interopRequireDefault(_promise);

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _desc, _value, _class, _class2, _temp, _desc2, _value2, _class3, _class4, _temp2, _dec, _class5, _class6, _temp3;

var _draftJs = require('draft-js');

var _variables = require('redux/modules/variables');

var _reactDraftWysiwyg = require('react-draft-wysiwyg');

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _s3upload = require('react-s3-uploader/s3upload');

var _s3upload2 = _interopRequireDefault(_s3upload);

var _reactColor = require('react-color');

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _coreDecorators = require('core-decorators');

var _reactRedux = require('react-redux');

var _WYSIWYG = require('./WYSIWYG.scss');

var _WYSIWYG2 = _interopRequireDefault(_WYSIWYG);

var _useBasename = require('../../helpers/useBasename');

var _useBasename2 = _interopRequireDefault(_useBasename);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
  var desc = {};
  Object['ke' + 'ys'](descriptor).forEach(function (key) {
    desc[key] = descriptor[key];
  });
  desc.enumerable = !!desc.enumerable;
  desc.configurable = !!desc.configurable;

  if ('value' in desc || desc.initializer) {
    desc.writable = true;
  }

  desc = decorators.slice().reverse().reduce(function (desc, decorator) {
    return decorator(target, property, desc) || desc;
  }, desc);

  if (context && desc.initializer !== void 0) {
    desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
    desc.initializer = undefined;
  }

  if (desc.initializer === void 0) {
    Object['define' + 'Property'](target, property, desc);
    desc = null;
  }

  return desc;
}

var md = {
  colorpicker: require('react-icons/lib/md/colorize'),
  variable: require('react-icons/lib/md/label-outline'),
  delete: require('react-icons/lib/md/delete'),
  add: require('react-icons/lib/md/add')
};

function s3uploader(files, options) {
  return new _s3upload2.default((0, _extends3.default)({
    files: files
  }, options));
}

function uploadImageCallBack(file) {
  return new _promise2.default(function (resolve, reject) {
    var uploaderOptions = {
      signingUrl: (0, _useBasename2.default)('/s3/sign'),
      contentDisposition: 'auto',
      uploadRequestHeaders: {},
      onFinishS3Put: function onFinishS3Put(response) {
        resolve({
          data: {
            link: 'http://reach-public.s3.amazonaws.com/' + response.filename
          }
        });
      },
      onProgress: function onProgress(response) {
        console.log('progress', response);
      },
      onError: function onError(response) {
        console.log('progress', response);reject(response);
      }
    };

    s3uploader([file], uploaderOptions);
  });
}

var ColorPic = (_class = (_temp = _class2 = function (_React$Component) {
  (0, _inherits3.default)(ColorPic, _React$Component);

  function ColorPic(props) {
    (0, _classCallCheck3.default)(this, ColorPic);
    return (0, _possibleConstructorReturn3.default)(this, (ColorPic.__proto__ || (0, _getPrototypeOf2.default)(ColorPic)).call(this, props));
  }

  (0, _createClass3.default)(ColorPic, [{
    key: 'onChange',
    value: function onChange(color) {
      var onChange = this.props.onChange;

      onChange('color', color.hex);
    }
  }, {
    key: 'stopPropagation',
    value: function stopPropagation(event) {
      event.stopPropagation();
    }
  }, {
    key: 'renderModal',
    value: function renderModal() {
      var color = this.props.currentState.color;

      return _react2.default.createElement(
        'div',
        { onClick: this.stopPropagation, className: _WYSIWYG2.default.colorPicker },
        _react2.default.createElement(
          'div',
          { className: _WYSIWYG2.default.toolsText },
          (0, _translations2.default)('COLOR OPTIONS')
        ),
        _react2.default.createElement(_reactColor.SwatchesPicker, { disableAlpha: true, width: 240, triangle: 'hide', color: color, onChangeComplete: this.onChange })
      );
    }
  }, {
    key: 'render',
    value: function render() {
      var _props = this.props,
          expanded = _props.expanded,
          onExpandEvent = _props.onExpandEvent;

      var backgroundColor = this.props.currentState.color || "#000000";
      return _react2.default.createElement(
        'div',
        { className: this.props.config.className, onClick: onExpandEvent },
        _react2.default.createElement(
          'div',
          { className: 'rdw-color-picker rdw-option-wrapper' },
          _react2.default.createElement(md.colorpicker, { size: 20 })
        ),
        _react2.default.createElement('div', { className: 'rdw-color-picker-sample', style: { backgroundColor: backgroundColor } }),
        expanded ? this.renderModal() : null
      );
    }
  }]);
  return ColorPic;
}(_react2.default.Component), _class2.propTypes = {
  expanded: _propTypes2.default.bool,
  onExpandEvent: _propTypes2.default.func.isRequired,
  onChange: _propTypes2.default.func.isRequired,
  currentState: _propTypes2.default.object.isRequired,
  className: _propTypes2.default.string
}, _class2.defaultProps = {
  expanded: false,
  className: ''
}, _temp), (_applyDecoratedDescriptor(_class.prototype, 'onChange', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class.prototype, 'onChange'), _class.prototype)), _class);
var VariableInsert = exports.VariableInsert = (_class3 = (_temp2 = _class4 = function (_React$Component2) {
  (0, _inherits3.default)(VariableInsert, _React$Component2);

  function VariableInsert(props) {
    (0, _classCallCheck3.default)(this, VariableInsert);

    var _this2 = (0, _possibleConstructorReturn3.default)(this, (VariableInsert.__proto__ || (0, _getPrototypeOf2.default)(VariableInsert)).call(this, props));

    _this2.state = {
      expanded: false
    };
    return _this2;
  }

  (0, _createClass3.default)(VariableInsert, [{
    key: 'componentWillMount',
    value: function componentWillMount() {
      var modalHandler = this.props.modalHandler;

      modalHandler.registerCallBack(this.expandCollapse);
    }
  }, {
    key: 'componentWillUnmount',
    value: function componentWillUnmount() {
      var modalHandler = this.props.modalHandler;

      modalHandler.deregisterCallBack(this.expandCollapse);
    }
  }, {
    key: 'onExpandEvent',
    value: function onExpandEvent() {
      this.signalExpanded = !this.state.expanded;
    }
  }, {
    key: 'expandCollapse',
    value: function expandCollapse() {
      this.setState({
        expanded: this.signalExpanded
      });
      this.signalExpanded = false;
    }
  }, {
    key: 'doExpand',
    value: function doExpand() {
      this.setState({
        expanded: true
      });
    }
  }, {
    key: 'doCollapse',
    value: function doCollapse() {
      this.setState({
        expanded: false
      });
    }
  }, {
    key: 'insertVariable',
    value: function insertVariable(variable) {
      var _props2 = this.props,
          editorState = _props2.editorState,
          onChange = _props2.onChange;


      var entityKey = editorState.getCurrentContent().createEntity('MENTION', 'IMMUTABLE', { text: variable.printable_value.text, url: '#' }).getLastCreatedEntityKey();

      var contentState = _draftJs.Modifier.replaceText(editorState.getCurrentContent(), editorState.getSelection(), '[' + variable.printable_value.text + ']', editorState.getCurrentInlineStyle(), entityKey);
      onChange(_draftJs.EditorState.push(editorState, contentState, 'insert-characters'));
      this.doCollapse();
    }
  }, {
    key: 'renderModal',
    value: function renderModal() {
      var _this3 = this;

      return _react2.default.createElement(
        'div',
        { className: 'rdw-variable-modal' },
        _react2.default.createElement(
          'div',
          { className: 'rdw-variable-modal-header' },
          _react2.default.createElement(
            'span',
            { className: 'rdw-variable-modal-header-option' },
            (0, _translations2.default)('VARIABLE INSERT')
          )
        ),
        _react2.default.createElement(
          'div',
          { className: 'rdw-variable-modal-section' },
          this.props.variables.map(function (variable) {
            return _react2.default.createElement(
              'div',
              { className: 'rdw-variable', key: 'variable-' + variable.name, onClick: _this3.insertVariable.bind(_this3, variable) },
              variable.printable_value.text
            );
          })
        )
      );
    }
  }, {
    key: 'render',
    value: function render() {
      var _props3 = this.props,
          onExpandEvent = _props3.onExpandEvent,
          translations = _props3.translations;
      var expanded = this.state.expanded;


      return _react2.default.createElement(
        'div',
        {
          className: 'rdw-variable-wrapper',
          'aria-haspopup': 'true',
          'aria-label': 'rdw-emoji-control',
          'aria-expanded': expanded
        },
        _react2.default.createElement(
          'div',
          { className: this.props.className + ' rdw-variable-picker rdw-option-wrapper', onClick: this.onExpandEvent },
          _react2.default.createElement(md.variable, { size: 20 })
        ),
        expanded ? this.renderModal() : null
      );
    }
  }]);
  return VariableInsert;
}(_react2.default.Component), _class4.propTypes = {
  editorState: _propTypes2.default.object.isRequired,
  onChange: _propTypes2.default.func.isRequired,
  modalHandler: _propTypes2.default.object,
  config: _propTypes2.default.object,
  translations: _propTypes2.default.object
}, _temp2), (_applyDecoratedDescriptor(_class3.prototype, 'onExpandEvent', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class3.prototype, 'onExpandEvent'), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, 'expandCollapse', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class3.prototype, 'expandCollapse'), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, 'doExpand', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class3.prototype, 'doExpand'), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, 'doCollapse', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class3.prototype, 'doCollapse'), _class3.prototype)), _class3);
var WYSIWYG = (_dec = (0, _reactRedux.connect)(function (state) {
  return {
    variablesList: state.variables.list
  };
}, { getVariables: _variables.getVariables, variablesRefresh: _variables.variablesRefresh }), _dec(_class5 = (_temp3 = _class6 = function (_React$Component3) {
  (0, _inherits3.default)(WYSIWYG, _React$Component3);

  function WYSIWYG() {
    (0, _classCallCheck3.default)(this, WYSIWYG);
    return (0, _possibleConstructorReturn3.default)(this, (WYSIWYG.__proto__ || (0, _getPrototypeOf2.default)(WYSIWYG)).apply(this, arguments));
  }

  (0, _createClass3.default)(WYSIWYG, [{
    key: 'componentWillMount',
    value: function componentWillMount() {
      if (__CLIENT__) {
        this.props.variablesRefresh();
        this.props.getVariables();
      }
    }
  }, {
    key: 'componentDidMount',
    value: function componentDidMount() {
      // this.nv.addEventListener("paste", this.handlePaste);
      this.editorkey = 'quill-editor';
    }
  }, {
    key: 'componentWillUnmount',
    value: function componentWillUnmount() {
      // this.nv.removeEventListener("paste", this.handlePaste);
    }
  }, {
    key: 'componentDidCatch',
    value: function componentDidCatch(error, info) {
      console.error('Error in wysiwyg, trying not to crash');
      this.editorkey = 'please-dont-die';
    }

    /*
    handlePaste(e) {
      // e.preventDefault()
      // e.stopPropagation()
      const clipboard = e.clipboardData || window.clipboardData
      if (clipboard) {
        const { items } = clipboard
        // notas:
        // el ultimo elemento de items puede contener la imagen
        // hay que probar, linux no puede copiar y pegar archivos
        for(let i = 0; i < items.length; i++) {
          if (items[i].kind === "string") {
          } else {
          }
        }
      }
    }
    */

    /*
    handlePastedText(e, a, b, c) {
    }
    */

  }, {
    key: 'renderButton',
    value: function renderButton() {
      return _react2.default.createElement('div', { className: _WYSIWYG2.default.button, dangerouslySetInnerHTML: { __html: this.props.buttonHtml } });
    }
  }, {
    key: 'render',
    value: function render() {
      var _this5 = this,
          _React$createElement;

      if (__SERVER__) {
        return _react2.default.createElement(
          'div',
          { key: 'quill', className: _WYSIWYG2.default.quill },
          _react2.default.createElement('img', { className: _WYSIWYG2.default.phoneBackground, src: (0, _useBasename2.default)() + '/static/phone.svg' })
        );
      }

      var variables = this.props.variablesList.map(function (v) {
        return {
          text: v.printable_value.text,
          value: v.printable_value.text + ']'
        };
      });

      return _react2.default.createElement(
        'div',
        { key: 'quill', className: _WYSIWYG2.default.quill, ref: function ref(elem) {
            return _this5.nv = elem;
          } },
        _react2.default.createElement('img', { className: _WYSIWYG2.default.phoneBackground, src: (0, _useBasename2.default)() + '/static/phone.svg' }),
        _react2.default.createElement('div', { className: _WYSIWYG2.default.headerPreview, dangerouslySetInnerHTML: { __html: this.props.header } }),
        _react2.default.createElement(
          'div',
          { className: _WYSIWYG2.default.toolbarAdvanced },
          _react2.default.createElement(
            'div',
            { className: _WYSIWYG2.default.toolsText },
            (0, _translations2.default)('TOOLS')
          )
        ),
        _react2.default.createElement(_reactDraftWysiwyg.Editor, (_React$createElement = {
          key: this.editorkey,
          editorRef: this.props.editorReference,
          placeholder: this.props.placeholder,
          editorState: this.props.editorState,
          toolbarClassName: _WYSIWYG2.default.toolbar,
          wrapperClassName: _WYSIWYG2.default.wrapper,
          editorClassName: _WYSIWYG2.default.editor
        }, (0, _defineProperty3.default)(_React$createElement, 'placeholder', this.props.placeholder), (0, _defineProperty3.default)(_React$createElement, 'editorStyle', this.props.style), (0, _defineProperty3.default)(_React$createElement, 'onEditorStateChange', this.props.onChange), (0, _defineProperty3.default)(_React$createElement, 'toolbarCustomButtons', [_react2.default.createElement(VariableInsert, {
          editorState: this.props.editorState,
          className: _WYSIWYG2.default.variable,
          variables: this.props.variablesList,
          onChange: this.props.onChange
        })]), (0, _defineProperty3.default)(_React$createElement, 'localization', {
          locale: 'es',
          translations: {
            'components.controls.image.fileUpload': 'Subir',
            'generic.add': 'Insertar'
          }
        }), (0, _defineProperty3.default)(_React$createElement, 'toolbar', {
          options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image'],
          inline: {
            inDropdown: false,
            className: _WYSIWYG2.default.inline,
            options: ['bold', 'italic', 'underline']
          },
          blockType: {
            inDropdown: true,
            className: _WYSIWYG2.default.blockType,
            options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code']
          },
          fontSize: {
            className: _WYSIWYG2.default.fontSize,
            options: [8, 10, 12, 14, 16, 18, 24, 30, 48, 72]
          },
          fontFamily: {
            className: _WYSIWYG2.default.fontFamily
          },
          textAlign: {
            inDropdown: false,
            className: _WYSIWYG2.default.textAlign,
            options: ['left', 'center', 'right', 'justify']
          },
          list: {
            inDropdown: false,
            options: ['unordered', 'ordered'],
            unordered: {
              className: _WYSIWYG2.default.unordered
            },
            ordered: {
              className: _WYSIWYG2.default.ordered
            }
          },
          colorPicker: {
            component: ColorPic,
            className: _WYSIWYG2.default.colorPic
          },
          remove: {
            className: _WYSIWYG2.default.remove
          },
          link: {
            defaultTargetOption: '_blank',
            options: ['link'],
            link: {
              className: _WYSIWYG2.default.link
            }
          },
          embedded: {
            defaultSize: {
              height: 'auto',
              width: '100%'
            },
            width: { present: true, mandatory: false },
            height: { present: false, mandatory: false },
            className: _WYSIWYG2.default.embedded
          },
          image: {
            urlEnabled: true,
            uploadEnabled: true,
            alignmentEnabled: true,
            previewImage: true,
            uploadCallback: uploadImageCallBack,
            inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
            defaultSize: {
              height: 'auto',
              width: '100%'
            },
            className: _WYSIWYG2.default.image,
            width: { present: true, mandatory: false },
            height: { present: false, mandatory: false }
          },
          emoji: {
            className: _WYSIWYG2.default.emoji,
            emojis: ['😋', '😮', '😷', '😂', '😜', '🐶', '😀', '😌', '😏', '🙁']
          },
          history: {
            inDropdown: false,
            options: ['undo', 'redo']
          }
        }), (0, _defineProperty3.default)(_React$createElement, 'mention', {
          separator: ' ',
          trigger: '[',
          suggestions: variables
        }), _React$createElement)),
        this.props.children,
        _react2.default.createElement(
          'div',
          { className: _WYSIWYG2.default.advanced },
          _react2.default.createElement(
            'div',
            { className: _WYSIWYG2.default.textOptions },
            (0, _translations2.default)('TEXT OPTIONS')
          ),
          _react2.default.createElement(
            'div',
            { className: _WYSIWYG2.default.alignmentOptions },
            (0, _translations2.default)('ALIGN OPTIONS')
          )
        ),
        !!this.props.buttonHtml && this.renderButton()
      );
    }
  }]);
  return WYSIWYG;
}(_react2.default.Component), _class6.propTypes = {
  variablesRefresh: _propTypes2.default.func.isRequired,
  getVariables: _propTypes2.default.func.isRequired,
  // value: PropTypes.object.isRequired,
  // onChange: PropTypes.func.isRequired,
  placeholder: _propTypes2.default.string,
  children: _propTypes2.default.array,
  editorState: _propTypes2.default.object
}, _class6.defaultProps = {
  placeholder: '',
  editorState: null,
  children: []
}, _temp3)) || _class5);
exports.default = WYSIWYG;