'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _class, _temp;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactRouter = require('react-router');

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _themeEnabled = require('helpers/themeEnabled');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var md = {
  filter: require('react-icons/lib/md/filter-list')
};

var Tabs = (_temp = _class = function (_React$Component) {
  (0, _inherits3.default)(Tabs, _React$Component);

  function Tabs(props) {
    (0, _classCallCheck3.default)(this, Tabs);

    var _this = (0, _possibleConstructorReturn3.default)(this, (Tabs.__proto__ || (0, _getPrototypeOf2.default)(Tabs)).call(this, props));

    if (props.simple) {
      _this.styles = require('./SimpleTabs.scss');
    } else {
      _this.styles = require('./Tabs.scss');
    }
    return _this;
  }

  (0, _createClass3.default)(Tabs, [{
    key: 'renderChildren',
    value: function renderChildren() {
      var _this2 = this;

      var styles = this.styles;
      return this.props.options.map(function (s, index) {
        return _react2.default.createElement(
          _reactRouter.Link,
          { to: s.href, className: styles.bartab, activeClassName: styles.activebartab + ' ' + (_this2.props.alt ? styles.addon : null),
            onlyActiveOnIndex: s.is_index, key: index },
          s.title
        );
      });
    }
  }, {
    key: 'renderRight',
    value: function renderRight() {
      var _this3 = this;

      var styles = this.styles;
      if (this.props.right) {
        return this.props.right.map(function (s, index) {
          return _react2.default.createElement(
            _reactRouter.Link,
            { to: s.href, className: styles.right + ' ' + styles.bartab, activeClassName: styles.activebartab + ' ' + (_this3.props.alt ? styles.addon : null),
              onlyActiveOnIndex: s.is_index, key: index },
            s.title
          );
        });
      }
      return null;
    }
  }, {
    key: 'render',
    value: function render() {
      var styles = this.styles;
      var classesTheme = (0, _themeEnabled.getThemeEnabledStyle)(styles);
      return _react2.default.createElement(
        'div',
        { className: classesTheme },
        _react2.default.createElement(
          'div',
          { className: styles.bar },
          this.renderChildren(),
          this.renderRight(),
          this.props.onFilter ? _react2.default.createElement(
            'div',
            { className: this.styles.filter, onClick: this.props.onFilter },
            _react2.default.createElement(md.filter, { size: 18 }),
            _react2.default.createElement(
              'span',
              { className: this.styles.text },
              (0, _translations2.default)('APPLY FILTER')
            )
          ) : null
        )
      );
    }
  }]);
  return Tabs;
}(_react2.default.Component), _class.propTypes = {
  options: _propTypes2.default.array.isRequired,
  right: _propTypes2.default.array,
  simple: _propTypes2.default.bool,
  alt: _propTypes2.default.bool
}, _temp);
exports.default = Tabs;
module.exports = exports['default'];