'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _class, _class2, _temp;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactRedux = require('react-redux');

var _mobile = require('redux/modules/mobile');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var DeviceSwitcher = (_dec = (0, _reactRedux.connect)(function (state) {
  return { isMobile: state.mobile.mobileMode };
}, { setMobile: _mobile.mobileMode, setDesktop: _mobile.desktopMode }), _dec(_class = (_temp = _class2 = function (_React$Component) {
  (0, _inherits3.default)(DeviceSwitcher, _React$Component);

  function DeviceSwitcher() {
    (0, _classCallCheck3.default)(this, DeviceSwitcher);
    return (0, _possibleConstructorReturn3.default)(this, (DeviceSwitcher.__proto__ || (0, _getPrototypeOf2.default)(DeviceSwitcher)).apply(this, arguments));
  }

  (0, _createClass3.default)(DeviceSwitcher, [{
    key: 'handleDeviceChange',
    value: function handleDeviceChange() {
      if (this.props.isMobile) {
        this.props.setDesktop();
      } else {
        this.props.setMobile();
      }
    }
  }, {
    key: 'render',
    value: function render() {
      return _react2.default.createElement(
        'div',
        { className: this.props.className, onClick: this.handleDeviceChange.bind(this) },
        this.props.isMobile ? 'CHANGE TO DESKTOP VERSION' : 'CHANGE TO MOBILE VERSION'
      );
    }
  }]);
  return DeviceSwitcher;
}(_react2.default.Component), _class2.propTypes = {
  className: _propTypes2.default.string,
  isMobile: _propTypes2.default.bool,
  setDesktop: _propTypes2.default.func,
  setMobile: _propTypes2.default.func
}, _temp)) || _class);
exports.default = DeviceSwitcher;
module.exports = exports['default'];