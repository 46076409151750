'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ContextNav = exports.default = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _class, _class2, _temp, _class3, _temp2;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactRouter = require('react-router');

var _reactRedux = require('react-redux');

var _Nav = require('./Nav.scss');

var _Nav2 = _interopRequireDefault(_Nav);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// import config from 'config'


var md = {
  arrowBack: require('react-icons/lib/md/arrow-back'),
  menu: require('react-icons/lib/md/menu'),
  person: require('react-icons/lib/md/person-pin'),
  personAdd: require('react-icons/lib/md/settings-applications'),
  close: require('react-icons/lib/md/close')
};

var Nav = (_dec = (0, _reactRedux.connect)(function (state) {
  return {
    mobileMode: state.mobile.mobileMode,
    location: state.routing.locationBeforeTransitions,
    user: state.auth.data.user,
    accounts: state.auth.data.account_list
  };
}), _dec(_class = (_temp = _class2 = function (_React$Component) {
  (0, _inherits3.default)(Nav, _React$Component);

  function Nav() {
    (0, _classCallCheck3.default)(this, Nav);
    return (0, _possibleConstructorReturn3.default)(this, (Nav.__proto__ || (0, _getPrototypeOf2.default)(Nav)).apply(this, arguments));
  }

  (0, _createClass3.default)(Nav, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          mobileMode = _props.mobileMode,
          pathname = _props.location.pathname;

      var styles = require('./Nav.scss');
      if (!this.props.user || !this.props.accounts) {
        return _react2.default.createElement('nav', { className: styles.nav });
      }
      var username = this.props.user.username;
      var accounts = this.props.accounts;
      var account = accounts.find(function (a) {
        return a.is_active;
      });

      // const logo = this.props.dark ? '/static/logo--white.png' : '/static/logo.png'

      return _react2.default.createElement(
        'nav',
        { className: styles.nav + ' ' + this.props.className + ' ' + (this.props.dark ? styles.dark : null), style: (0, _extends3.default)({}, this.props.style) },
        _react2.default.createElement(
          'div',
          { className: styles.children },
          this.props.children
        )
      );
    }
  }]);
  return Nav;
}(_react2.default.Component), _class2.propTypes = {
  mobileMode: _propTypes2.default.bool,
  className: _propTypes2.default.string,
  location: _propTypes2.default.object,
  children: _propTypes2.default.any,
  title: _propTypes2.default.string,
  icon: _propTypes2.default.any.isRequired,
  user: _propTypes2.default.object,
  accounts: _propTypes2.default.array,
  alt: _propTypes2.default.bool,
  dark: _propTypes2.default.bool
}, _class2.defaultProps = {
  icon: _react2.default.createElement(md.person, { size: 30 })
}, _temp)) || _class);
exports.default = Nav;
var ContextNav = exports.ContextNav = (_temp2 = _class3 = function (_React$Component2) {
  (0, _inherits3.default)(ContextNav, _React$Component2);

  function ContextNav() {
    (0, _classCallCheck3.default)(this, ContextNav);
    return (0, _possibleConstructorReturn3.default)(this, (ContextNav.__proto__ || (0, _getPrototypeOf2.default)(ContextNav)).apply(this, arguments));
  }

  (0, _createClass3.default)(ContextNav, [{
    key: 'render',
    value: function render() {
      var styles = require('./Nav.scss');

      return _react2.default.createElement(
        'nav',
        { className: styles.contextnav, style: (0, _extends3.default)({}, this.props.style) },
        _react2.default.createElement(
          'div',
          { className: styles.elementsCount },
          '(',
          this.props.selectedElementsCount,
          ')'
        ),
        _react2.default.createElement(
          'div',
          { className: styles.children },
          this.props.children
        ),
        _react2.default.createElement(
          'div',
          { className: styles.close, onClick: this.props.unselect },
          _react2.default.createElement(md.close, { size: 25 })
        )
      );
    }
  }]);
  return ContextNav;
}(_react2.default.Component), _class3.propTypes = {
  mobileMode: _propTypes2.default.bool,
  className: _propTypes2.default.string,
  unselect: _propTypes2.default.func.isRequired,
  children: _propTypes2.default.any.isRequired,
  selectedElementsCount: _propTypes2.default.string.isRequired
}, _temp2);