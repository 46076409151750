'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _class, _temp;

var _Allow = require('../../containers/Allow/Allow');

var _Allow2 = _interopRequireDefault(_Allow);

var _Icon = require('./Icon');

var _Icon2 = _interopRequireDefault(_Icon);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _useBasename = require('../../helpers/useBasename');

var _useBasename2 = _interopRequireDefault(_useBasename);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var md = {
  checkCircle: require('react-icons/lib/md/check-circle')
};

var ContactCard = (_temp = _class = function (_React$Component) {
  (0, _inherits3.default)(ContactCard, _React$Component);

  function ContactCard(props) {
    (0, _classCallCheck3.default)(this, ContactCard);

    var _this = (0, _possibleConstructorReturn3.default)(this, (ContactCard.__proto__ || (0, _getPrototypeOf2.default)(ContactCard)).call(this, props));

    _this.styles = require('./Card.scss');
    return _this;
  }

  (0, _createClass3.default)(ContactCard, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          profile = _props.profile,
          msisdn = _props.msisdn;

      var name = msisdn || profile.email && profile.email.value;
      if (profile.first_name) {
        name = profile.first_name.value;
      }
      if (profile.first_name && profile.last_name) {
        name = profile.first_name.value + ' ' + profile.last_name.value;
      }
      return _react2.default.createElement(
        'div',
        { className: this.styles.huevos },
        _react2.default.createElement(_Icon2.default, { profile: profile }),
        _react2.default.createElement(
          'p',
          { style: { margin: '6px 0' } },
          name
        ),
        _react2.default.createElement(
          'div',
          { className: this.styles.conversationOptionsContainer },
          _react2.default.createElement(
            _Allow2.default,
            { features: ['sms'] },
            _react2.default.createElement(
              'div',
              { className: this.styles.activeChat },
              this.props.active.sms ? _react2.default.createElement('div', { className: this.styles.selectedConversation }) : _react2.default.createElement('div', { style: { height: '20px' } }),
              _react2.default.createElement('img', { name: 'sms', className: this.styles.conversationOptions, src: (0, _useBasename2.default)('/static/neutro.svg'), alt: '', onClick: this.props.onChange() })
            )
          ),
          _react2.default.createElement(
            _Allow2.default,
            { features: ['whatsapp'], permissions: ['channels:whatsapp:send:single'] },
            _react2.default.createElement(
              'div',
              { className: this.styles.activeChat },
              this.props.active.whatsapp ? _react2.default.createElement('div', { className: this.styles.selectedConversation }) : _react2.default.createElement('div', { style: { height: '20px' } }),
              _react2.default.createElement('img', { name: 'whatsapp', className: this.styles.conversationOptions, src: (0, _useBasename2.default)('/static/whatsapp.svg'), alt: '', onClick: this.props.onChange() })
            )
          )
        )
      );
    }
  }]);
  return ContactCard;
}(_react2.default.Component), _class.propTypes = {
  profile: _propTypes2.default.object.isRequired
}, _temp);
exports.default = ContactCard;
module.exports = exports['default'];