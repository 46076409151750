'use strict';

var _assign = require('babel-runtime/core-js/object/assign');

var _assign2 = _interopRequireDefault(_assign);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// require('babel-polyfill');
var path = require('path');

var _require = require('../package.json'),
    version = _require.version;

var environment = {
  development: {
    isProduction: false
  },
  production: {
    isProduction: true
  }
}[process.env.NODE_ENV || 'development'];

module.exports = (0, _assign2.default)({
  host: process.env.HOST || '127.0.0.1',
  port: process.env.PORT || 3000,
  devHost: process.env.DEVHOST || '127.0.0.1',
  devPort: process.env.DEVPORT || 3001,
  apiHost: process.env.APIHOST || '127.0.0.1',
  apiPort: process.env.APIPORT || 3182,
  v5Host: process.env.V5HOST || '127.0.0.1',
  v5Port: process.env.V5PORT || '80',
  instanceId: process.env.INSTANCEID || '',
  appHost: process.env.REACT_APP_HOST || '',
  basename: process.env.REACT_APP_BASENAME || '',
  switchAccountMenu: process.env.REACT_APP_SWITCH_ACCOUNT_MENU === 'true',
  allowSSO: process.env.REACT_APP_ALLOW_SSO === 'true',
  allow2FA: process.env.REACT_APP_ALLOW_2FA === 'true',
  regionalInstance: process.env.REACT_APP_REGIONAL_INSTANCE === 'true',
  version: version,
  lang: 'es',
  app: {
    title: 'Ubiquo',
    description: 'Mensajeria corporativa',
    head: {
      meta: [{ name: 'description', content: 'Mensajeria corporativa' }, { charset: 'utf-8' }, { property: 'og:site_name', content: 'Ubiquo' }, { property: 'og:image', content: '/static/comunicador.jpg' }, { property: 'og:locale', content: 'es_GT' }, { property: 'og:title', content: 'Ubiquo' }, { property: 'og:description', content: 'Mensajeria corporativa.' }, { property: 'og:card', content: 'summary' }, { property: 'og:site', content: '@ubiquolabs' }, { property: 'og:creator', content: '@ubiquolabs' }, { property: 'og:image:width', content: '200' }, { property: 'og:image:height', content: '200' }]
    }
  },
  theme: path.resolve(__dirname, './theme/tigo.json'),
  // theme: path.resolve(__dirname, './theme/default.json'),
  // logo: '/static/logo.png',  // esto puede ser un path absoluto, ej http://s3.amazon.com/logo.png
  // logo: 'http://celebrityhockeyclassics.com/wp-content/uploads/Logo-Placeholder.png',
  loginbg: '/static/loginbg.jpg',
  adminbg: '/static/adminbg.jpg',
  analytics: process.env.REACT_APP_GA4_TRACKING_ID || 'G-0NJDNYB2LZ'
  // loginbg: 'http://wallpapercave.com/wp/hN0TnNU.jpg'
}, environment);