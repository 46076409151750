'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _class;

var _components = require('components');

var _qrs = require('redux/modules/qrs');

var _interaction = require('redux/modules/interaction');

var _reactRouter = require('react-router');

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactDom = require('react-dom');

var _reactDom2 = _interopRequireDefault(_reactDom);

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _reduxConnect = require('redux-connect');

var _coreDecorators = require('core-decorators');

var _reactRedux = require('react-redux');

var _account = require('redux/modules/account');

var _reachHistory = require('../../helpers/reachHistory');

var _Study = require('./Study.scss');

var _Study2 = _interopRequireDefault(_Study);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var md = {
  search: require('react-icons/lib/md/search')
};

var QrList = (_dec = (0, _reactRedux.connect)(function (state) {
  return {
    selected: state.qrs.selected,
    list: state.qrs.list
  };
}, {
  getAccount: _account.getAccount,
  selectQrs: _qrs.selectQrs,
  getQrs: _qrs.getQrs,
  unselectAllQrs: _qrs.unselectAllQrs,
  updateInteraction: _interaction.updateInteraction,
  updateInteractionConfig: _interaction.updateInteractionConfig
}), _dec(_class = function (_List) {
  (0, _inherits3.default)(QrList, _List);

  function QrList(props) {
    (0, _classCallCheck3.default)(this, QrList);

    var _this = (0, _possibleConstructorReturn3.default)(this, (QrList.__proto__ || (0, _getPrototypeOf2.default)(QrList)).call(this, props));

    _this.statusTranslations = {
      'IN_PROGRESS': (0, _translations2.default)('IN_PROGRESS'),
      'PAUSED': (0, _translations2.default)('PAUSED'),
      'APPROVED': (0, _translations2.default)('APPROVED'),
      'FINISHED': (0, _translations2.default)('FINISHED'),
      'EXPIRED': (0, _translations2.default)('EXPIRED'),
      'ABORTED': (0, _translations2.default)('ABORTED'),
      'NOT_STARTED': (0, _translations2.default)('NOT_STARTED'),
      'STOPPED': (0, _translations2.default)('STOPPED'),
      'ACTIVE': (0, _translations2.default)('ACTIVE STATUS')
    };

    _this.state = {
      search: ''
    };

    _this.props.getQrs();
    return _this;
  }

  (0, _createClass3.default)(QrList, [{
    key: 'selectQr',
    value: function selectQr(index) {
      this.props.unselectAllQrs();
      this.props.selectQrs(index);
    }
  }, {
    key: 'renderNav',
    value: function renderNav() {
      var search = this.props.location.query.search;

      var props = this.props;

      return _react2.default.createElement(
        _components.Nav,
        { key: 'Nav', title: (0, _translations2.default)('MY STUDIES'), alt: true },
        _react2.default.createElement(
          'div',
          { className: 'search' },
          _react2.default.createElement('input', {
            type: 'text',
            placeholder: (0, _translations2.default)('SEARCH PLACEHOLDER'),
            value: this.state.search || '',
            ref: 'search',
            onChange: this.handleSearch,
            onKeyPress: this.handleSearch
          }),
          _react2.default.createElement(md.search, null),
          _react2.default.createElement(
            'span',
            { className: 'clear', onClick: this.handleClear, style: this.state.search ? {} : { display: 'none' } },
            '\xD7'
          )
        )
      );
    }
  }, {
    key: 'renderListHeader',
    value: function renderListHeader() {
      return _react2.default.createElement(
        'div',
        { className: _Study2.default.listheader },
        _react2.default.createElement('div', { className: _Study2.default.handle }),
        _react2.default.createElement(
          'div',
          { className: _Study2.default.name },
          'Nombre'
        ),
        _react2.default.createElement(
          'div',
          { className: _Study2.default.name },
          'URL'
        ),
        _react2.default.createElement(
          'div',
          { className: _Study2.default.name },
          'Estado de interacci\xF3n'
        ),
        _react2.default.createElement(
          'div',
          { className: _Study2.default.search },
          this.renderNav()
        )
      );
    }
  }, {
    key: 'renderInfiniteList',
    value: function renderInfiniteList() {
      var _this2 = this;

      var hidden = { display: 'none' };
      var visible = { display: 'block' };
      var infiniteStyle = this.props.isLoading || this.props.list.length > 0 ? visible : hidden;
      var props = this.props;

      return _react2.default.createElement(
        'div',
        null,
        _react2.default.createElement(
          'div',
          { style: infiniteStyle },
          _react2.default.createElement(
            _components.Infinite,
            {
              refresh: this.refreshRows,
              next: this.loadMoreRows,
              hasMore: this.state.hasMoreRows
            },
            this.props.list.map(function (item, index) {
              return _react2.default.createElement(_components.VirtualListItemQr, {
                key: index,
                index: index,
                name: item.name,
                url: item.short_url,
                status: 'ACTIVE',
                label: _this2.statusTranslations['ACTIVE'],
                onClick: function onClick() {
                  return _this2.selectQr(index);
                },
                selected: _this2.props.selected
              });
            })
          )
        )
      );
    }
  }]);
  return QrList;
}(_components.List)) || _class);
exports.default = QrList;
module.exports = exports['default'];