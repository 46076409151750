'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NewProfilingSelector = exports.default = undefined;

var _typeof2 = require('babel-runtime/helpers/typeof');

var _typeof3 = _interopRequireDefault(_typeof2);

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _getOwnPropertyNames = require('babel-runtime/core-js/object/get-own-property-names');

var _getOwnPropertyNames2 = _interopRequireDefault(_getOwnPropertyNames);

var _promise = require('babel-runtime/core-js/promise');

var _promise2 = _interopRequireDefault(_promise);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _dec2, _class, _class2, _temp, _dec3, _dec4, _class3, _class4, _temp2;

var _components = require('components');

var _reactSticky = require('react-sticky');

var _profiling = require('redux/modules/profiling');

var _Balance = require('../../containers/Reports/Balance');

var _Balance2 = _interopRequireDefault(_Balance);

var _reactHelmet = require('react-helmet');

var _reactHelmet2 = _interopRequireDefault(_reactHelmet);

var _reactRouter = require('react-router');

var _Contacts = require('../Contacts/Contacts');

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _reduxConnect = require('redux-connect');

var _coreDecorators = require('core-decorators');

var _reactRedux = require('react-redux');

var _mobile = require('redux/modules/mobile');

var _reachHistory = require('../../helpers/reachHistory');

var _workflows = require('redux/modules/workflows');

var _Profiling = require('./Profiling.scss');

var _Profiling2 = _interopRequireDefault(_Profiling);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var md = {
  settings: require('react-icons/lib/md/settings'),
  add: require('react-icons/lib/md/assignment'),
  add2: require('react-icons/lib/md/assignment-ind')
};

var Profiling = (_dec = (0, _reduxConnect.asyncConnect)([{
  key: 'list',
  promise: function promise(_ref) {
    var _ref$store = _ref.store,
        dispatch = _ref$store.dispatch,
        getState = _ref$store.getState;

    var state = getState();
    if (state.profiling.list.length === 0) {
      return dispatch((0, _profiling.getProfilingJobs)(0, 100));
    }
    return null;
  }
}]), _dec2 = (0, _reactRedux.connect)(function (state) {
  return {
    mobileMode: state.mobile.mobileMode,
    list: state.profiling.list,
    account: state.auth.current_account
  };
}, {
  getProfilingJobs: _profiling.getProfilingJobs,
  getProfilingResults: _profiling.getProfilingResults,
  modal: _mobile.modal,
  stopProfiling: _workflows.stopProfiling,
  push: _reachHistory.push
}), _dec(_class = _dec2(_class = (_temp = _class2 = function (_React$Component) {
  (0, _inherits3.default)(Profiling, _React$Component);

  function Profiling(props) {
    (0, _classCallCheck3.default)(this, Profiling);

    var _this = (0, _possibleConstructorReturn3.default)(this, (Profiling.__proto__ || (0, _getPrototypeOf2.default)(Profiling)).call(this, props));

    _this.title = (0, _translations2.default)('MY PROFILING');
    _this.statusTranslations = {
      'ACTIVE': (0, _translations2.default)('ACTIVE'),
      'PAUSED': (0, _translations2.default)('PAUSED'),
      'FINISHED': (0, _translations2.default)('FINISHED'),
      'EXPIRED': (0, _translations2.default)('EXPIRED'),
      'ABORTED': (0, _translations2.default)('ABORTED'),
      'NOT_STARTED': (0, _translations2.default)('NOT_STARTED')
    };

    _this.activeProfilings = _this.props.list.filter(function (item) {
      return item.status === 'ACTIVE';
    });

    _this.state = {
      loading: true,
      workflowUnits: []
    };
    return _this;
  }

  (0, _createClass3.default)(Profiling, [{
    key: 'componentWillMount',
    value: function componentWillMount() {
      var _this2 = this;

      if (__CLIENT__) {
        _promise2.default.all(this.props.list.map(function (profiling) {
          return _this2.props.getProfilingResults(profiling._id);
        })).then(function (values) {
          var units = {};
          values.forEach(function (each) {
            each.workflow_units.forEach(function (unit, index) {
              if (!units[unit.uid]) {
                units[unit.uid] = unit;
              } else {
                units[unit.uid] = _this2.sumInts(units[unit.uid], unit);
              }
            });
          });
          var workflowUnits = [];
          (0, _getOwnPropertyNames2.default)(units).forEach(function (each) {
            var unit = units[each];
            if (unit.stats && unit.stats.type && unit.stats.type !== 'OPTIN' && unit.stats.type !== 'WELCOME' && unit.stats.type !== 'MSISDN') {
              // WELCOME ?
              workflowUnits.push(unit);
            }
          });
          _this2.setState((0, _extends3.default)({}, _this2.state, {
            loading: false,
            workflowUnits: workflowUnits
          }));
        });
        /*
        .catch(reason => {
          console.error('ERROR IN ALL PROMISES', reason)
        })
        */
      }
    }
  }, {
    key: 'stopProfiling',
    value: function stopProfiling(open) {
      var _this3 = this;

      var t = open ? "OPEN" : "CLOSED";
      this.props.stopProfiling(t).then(function () {
        _this3.props.push({
          pathname: '/success',
          state: {
            goTo: '/profiling/',
            goToModal: false,
            message: (0, _translations2.default)('PROFILING STOP SUCCESS'),
            modal: true,
            returnTo: '/profiling/'
          }
        });
      });
    }
  }, {
    key: 'sumInts',
    value: function sumInts(obj1, obj2) {
      if (Array.isArray(obj1)) {
        if (obj1.length === obj2.length) {
          var newArray = [];
          for (var i in obj1) {
            if (obj2.hasOwnProperty(i)) {
              newArray[i] = this.sumInts(obj1[i], obj2[i]);
            }
          }
          return newArray;
        } else {
          if (obj1.length === 0 && obj2.length > 0) {
            return obj2;
          } else if (obj2.length === 0 && obj1.length > 0) {
            return obj1;
          } else if (obj1.length === 0 && obj2.length === 0) {
            return [];
          } else {
            console.error('Data mismatch');
            return obj1.concat(obj2);
          }
        }
      } else if ((typeof obj1 === 'undefined' ? 'undefined' : (0, _typeof3.default)(obj1)) === 'object') {
        var newObj = {};
        for (var property in obj1) {
          if (obj1.hasOwnProperty(property)) {
            if (obj2.hasOwnProperty(property)) {
              newObj[property] = this.sumInts(obj1[property], obj2[property]);
            } else {
              newObj[property] = obj1[property];
            }
          }
        }
        return newObj;
      } else if (typeof obj1 === 'number') {
        return obj1 + obj2;
      }
      return obj1;
    }
  }, {
    key: 'renderHeader',
    value: function renderHeader() {
      return _react2.default.createElement(_components.GlobalTabs, { activeStyle: { background: '#FFF' } });
    }
  }, {
    key: 'renderNav',
    value: function renderNav(stickyprops) {
      var props = this.props;
      var is_open = props.account.account_type == "Subaccount" && props.account.is_default_subaccount && props.account.reach_enabled || props.account.account_type != "Subaccount";
      return _react2.default.createElement(
        _components.Nav,
        { key: 'Nav', title: (0, _translations2.default)('MY PROFILING'), style: stickyprops.style },
        _react2.default.createElement(
          _reactRouter.Link,
          { className: 'action', to: {
              pathname: is_open ? '/profiling/choose' : '/profiling/open',
              state: { modal: true, returnTo: '/profiling', fullModal: !is_open, modalSize: { width: '300px', height: '526px' } }
            }, style: { marginLeft: 'auto' } },
          (0, _translations2.default)('PROFILING CONFIG'),
          _react2.default.createElement(md.settings, { size: 20 })
        )
      );
    }
  }, {
    key: 'renderTabs',
    value: function renderTabs() {
      return _react2.default.createElement(_Contacts.ContactTabs, null);
    }
  }, {
    key: 'renderQuestion',
    value: function renderQuestion(question, index) {
      var content = question.action.content;
      var type = question.stats.type;
      if (type === 'OPEN') {
        return _react2.default.createElement(
          'div',
          { key: 'tagcloud-' + index, className: _Profiling2.default.result },
          _react2.default.createElement(
            'div',
            { className: _Profiling2.default.questionName },
            content
          ),
          _react2.default.createElement(_components.TagCloudChart, { key: 'tagcloudchart-' + index, question: question })
        );
      }
      var plotType = void 0;
      var colors = void 0;
      switch (type) {
        case 'OPTIONS':
          plotType = 'pie';
          switch (question.stats.alternative_type) {
            case 'YES_NO':
              colors = ['#9AEA9A', '#EA9A9A'];
              break;
            case 'GENDER':
              colors = ['#139CF4', '#EF41A6'];
              break;
            default:
              break;
          }
          break;
        case 'RANGE':
        case 'AGE':
          plotType = 'area';
          break;
        case 'MULIPLE_CHOICE':
          plotType = 'column';
          break;
        default:
          break;
      }

      switch (plotType) {
        case 'pie':
          return _react2.default.createElement(
            'div',
            { key: index, className: _Profiling2.default.result },
            _react2.default.createElement(
              'div',
              { className: _Profiling2.default.questionName },
              content
            ),
            _react2.default.createElement(_components.PieChart, { question: question, plotType: plotType, colors: colors })
          );
        case 'area':
          return _react2.default.createElement(
            'div',
            { key: index, className: _Profiling2.default.result },
            _react2.default.createElement(
              'div',
              { className: _Profiling2.default.questionName },
              content
            ),
            _react2.default.createElement(_components.AreaChart, { question: question, plotType: plotType })
          );
        case 'column':
          return _react2.default.createElement(
            'div',
            { key: index, className: _Profiling2.default.result },
            _react2.default.createElement(
              'div',
              { className: _Profiling2.default.questionName },
              content
            ),
            _react2.default.createElement(_components.AreaChart, { question: question, plotType: plotType })
          );
        default:
          return null;
      }
    }
  }, {
    key: 'renderQuestions',
    value: function renderQuestions(questions) {
      var _this4 = this;

      return questions.map(function (s, index) {
        return _this4.renderQuestion(s, index);
      });
    }
  }, {
    key: 'renderContent',
    value: function renderContent() {
      if (this.state.workflowUnits.length === 0) {
        if (this.state.loading) {
          return null;
        } else {
          var props = this.props;
          var is_open = props.account.account_type == "Subaccount" && props.account.is_default_subaccount && props.account.reach_enabled || props.account.account_type != "Subaccount";
          return _react2.default.createElement(_components.Linkholder, {
            to: {
              pathname: is_open ? '/profiling/choose' : '/profiling/open',
              state: { modal: true, returnTo: '/profiling/', fullModal: !is_open, modalSize: { width: '400px', height: '500px' } }
            },
            src: '/static/empty/results.svg',
            text: (0, _translations2.default)('NO PROFILINGS RUNNING'),
            button: (0, _translations2.default)('CONFIGURE A NEW PROFILING')
          });
        }
      } else {
        return this.renderQuestions(this.state.workflowUnits);
      }
    }
  }, {
    key: 'renderRight',
    value: function renderRight() {
      return _react2.default.createElement(_Balance2.default, null);
    }
  }, {
    key: 'render',
    value: function render() {
      var _this5 = this;

      this.activeProfilings = this.props.list.filter(function (item) {
        return item.status === 'ACTIVE';
      });

      return _react2.default.createElement(
        _reactSticky.StickyContainer,
        null,
        _react2.default.createElement(_reactHelmet2.default, { title: (0, _translations2.default)('PROFILING') }),
        this.renderHeader(),
        _react2.default.createElement(
          _reactSticky.Sticky,
          { topOffset: 27 },
          function (props) {
            return _this5.renderNav(props);
          }
        ),
        _react2.default.createElement(
          _components.MenuContainer,
          { rightColumn: this.renderRight(), mobileMode: this.props.mobileMode },
          this.renderTabs(),
          this.props.location.state && this.props.location.state.msg && _react2.default.createElement(
            'div',
            { className: _Profiling2.default.msg },
            ' ',
            this.props.location.state.msg,
            ' '
          ),
          this.activeProfilings.map(function (p) {
            if (p.panel_source == 'OPEN') {
              if (p.public_web_access.enabled && p.public_web_access.short_uri) {
                return _react2.default.createElement(
                  'div',
                  { className: _Profiling2.default.url },
                  _react2.default.createElement(
                    'div',
                    { className: _Profiling2.default.running },
                    (0, _translations2.default)('OPEN PROFILING RUNNING')
                  ),
                  _react2.default.createElement(
                    'div',
                    { className: _Profiling2.default.shareLink },
                    (0, _translations2.default)('PUBLIC URL FOR PROFILING'),
                    ': ',
                    _react2.default.createElement('br', null),
                    ' ',
                    _react2.default.createElement('br', null),
                    ' ',
                    p.public_web_access.short_uri,
                    ' ',
                    _react2.default.createElement(
                      'a',
                      { className: _Profiling2.default.TestLink, target: '_blank', href: p.public_web_access.short_uri },
                      'Abrir en nueva pesta\xF1a'
                    )
                  ),
                  _react2.default.createElement(
                    'a',
                    { className: _Profiling2.default.stop, onClick: _this5.stopProfiling.bind(_this5, true) },
                    (0, _translations2.default)('STOP OPEN PROFILING RUNNING')
                  )
                );
              }
              return null;
            } else {
              return _react2.default.createElement(
                'div',
                { className: _Profiling2.default.url },
                _react2.default.createElement(
                  'div',
                  { className: _Profiling2.default.running },
                  (0, _translations2.default)('CLOSED PROFILING RUNNING')
                ),
                _react2.default.createElement(
                  'a',
                  { className: _Profiling2.default.stop, onClick: _this5.stopProfiling.bind(_this5, false) },
                  (0, _translations2.default)('STOP CLOSED PROFILING RUNNING')
                )
              );
            }
          }),
          this.renderContent()
        )
      );
    }
  }]);
  return Profiling;
}(_react2.default.Component), _class2.propTypes = {
  mobileMode: _propTypes2.default.bool,
  selected: _propTypes2.default.array,
  getProfilingResults: _propTypes2.default.func.isRequired,
  modal: _propTypes2.default.func.isRequired
}, _temp)) || _class) || _class);
exports.default = Profiling;
var NewProfilingSelector = exports.NewProfilingSelector = (_dec3 = (0, _reduxConnect.asyncConnect)([{
  key: 'profilings',
  promise: function promise(_ref2) {
    var _ref2$store = _ref2.store,
        dispatch = _ref2$store.dispatch,
        getState = _ref2$store.getState;

    var state = getState();
    if (state.profiling.list.length === 0) {
      return dispatch((0, _profiling.getProfilingJobs)(0, 5));
    }
    return null;
  }
}]), _dec4 = (0, _reactRedux.connect)(function (state) {
  return { account: state.auth.current_account, mobileMode: state.mobile.mobileMode };
}), _dec3(_class3 = _dec4(_class3 = (_temp2 = _class4 = function (_React$Component2) {
  (0, _inherits3.default)(NewProfilingSelector, _React$Component2);

  function NewProfilingSelector() {
    (0, _classCallCheck3.default)(this, NewProfilingSelector);
    return (0, _possibleConstructorReturn3.default)(this, (NewProfilingSelector.__proto__ || (0, _getPrototypeOf2.default)(NewProfilingSelector)).apply(this, arguments));
  }

  (0, _createClass3.default)(NewProfilingSelector, [{
    key: 'render',
    value: function render() {
      var props = this.props;
      this.activeProfilings = props.profilings ? props.profilings.filter(function (item) {
        return item.status === 'ACTIVE';
      }) : [];
      return _react2.default.createElement(
        'div',
        { style: { width: '100%', height: '100%' } },
        _react2.default.createElement(_components.MobileBar, { returnTo: '/profiling/' }),
        this.activeProfilings.length == 0 ? _react2.default.createElement(
          'h1',
          null,
          'EN EJECUCION'
        ) : _react2.default.createElement(
          _components.ButtonModal,
          null,
          _react2.default.createElement(
            'h1',
            null,
            (0, _translations2.default)('MY PROFILING')
          ),
          _react2.default.createElement(
            _reactRouter.Link,
            { className: 'action', to: {
                pathname: '/profiling/config',
                state: { modal: true, returnTo: '/profiling', fullModal: false }
              } },
            _react2.default.createElement(md.add, { size: 30, color: '#616161' }),
            _react2.default.createElement(
              'h2',
              null,
              'Por invitaci\xF3n'
            )
          ),
          _react2.default.createElement(
            _reactRouter.Link,
            { className: 'action', to: {
                pathname: '/profiling/config',
                state: { modal: true, returnTo: '/profiling', fullModal: false }
              } },
            _react2.default.createElement(md.add2, { size: 30, color: '#616161' }),
            _react2.default.createElement(
              'h2',
              null,
              'P\xFAblica'
            )
          )
        )
      );
    }
  }]);
  return NewProfilingSelector;
}(_react2.default.Component), _class4.propTypes = {
  mobileMode: _propTypes2.default.bool.isRequired,
  location: _propTypes2.default.object.isRequired
}, _temp2)) || _class3) || _class3);