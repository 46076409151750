"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.makeDigestHmac = makeDigestHmac;

var _cryptoJs = require("crypto-js");

var _cryptoJs2 = _interopRequireDefault(_cryptoJs);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function makeDigestHmac(message, date, key) {
    var canonicalString = date + toUnicode(message);
    canonicalString = canonicalString.replace(/ /g, "").replace(/\n/g, "");

    var hash = _cryptoJs2.default.HmacSHA256(canonicalString, key);
    var base64 = _cryptoJs2.default.enc.Base64.stringify(hash);
    return base64;
}

function toUnicode(input) {
    return input.split('').map(function (char) {
        var code = char.charCodeAt(0);
        if (code > 127) {
            return "\\u" + code.toString(16).padStart(4, '0');
        } else {
            return char;
        }
    }).join('');
}