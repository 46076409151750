'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = undefined;

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactModal = require('react-modal');

var _reactModal2 = _interopRequireDefault(_reactModal);

var _Templates = require('./Templates.scss');

var _Templates2 = _interopRequireDefault(_Templates);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '80vh',
        height: '70vh'
    },
    options: {
        cursor: 'pointer'
    }
};

var md = {
    search: require('react-icons/lib/md/search'),
    delete: require('react-icons/lib/md/delete'),
    edit: require('react-icons/lib/md/edit')
};

var CustomModal = function (_React$Component) {
    (0, _inherits3.default)(CustomModal, _React$Component);

    function CustomModal(props) {
        (0, _classCallCheck3.default)(this, CustomModal);

        var _this = (0, _possibleConstructorReturn3.default)(this, (CustomModal.__proto__ || (0, _getPrototypeOf2.default)(CustomModal)).call(this, props));

        _this.state = {
            modalIsOpen: false
        };

        _this.openModal = _this.openModal.bind(_this);
        _this.afterOpenModal = _this.afterOpenModal.bind(_this);
        _this.closeModal = _this.closeModal.bind(_this);

        return _this;
    }

    (0, _createClass3.default)(CustomModal, [{
        key: 'openModal',
        value: function openModal() {
            this.setState({ modalIsOpen: true });
        }
    }, {
        key: 'afterOpenModal',
        value: function afterOpenModal() {
            // references are now sync'd and can be accessed.
            this.subtitle.style.color = '#f00';
        }
    }, {
        key: 'closeModal',
        value: function closeModal() {
            this.setState({ modalIsOpen: false });
        }
    }, {
        key: 'render',
        value: function render() {
            var _this2 = this;

            return _react2.default.createElement(
                'div',
                { title: 'Previsualizar', style: customStyles.options },
                _react2.default.createElement(md.search, { size: 25, onClick: this.openModal }),
                _react2.default.createElement(
                    _reactModal2.default,
                    {
                        isOpen: this.state.modalIsOpen,
                        onAfterOpen: this.afterOpenModal,
                        onRequestClose: this.closeModal,
                        style: customStyles,
                        contentLabel: 'Example Modal' },
                    _react2.default.createElement(
                        'div',
                        { className: _Templates2.default.modal },
                        _react2.default.createElement(
                            'h2',
                            { ref: function ref(subtitle) {
                                    return _this2.subtitle = subtitle;
                                } },
                            'Preview Template 1'
                        ),
                        _react2.default.createElement(
                            'div',
                            { className: _Templates2.default.modalTittle },
                            _react2.default.createElement(
                                'h1',
                                null,
                                'TITULO'
                            )
                        ),
                        _react2.default.createElement(
                            'div',
                            { className: _Templates2.default.modalContent },
                            _react2.default.createElement(
                                'h1',
                                null,
                                'CONTENIDO'
                            )
                        ),
                        _react2.default.createElement(
                            'div',
                            { className: _Templates2.default.modalFooter },
                            _react2.default.createElement(
                                'h1',
                                null,
                                'FOOTER'
                            )
                        )
                    )
                )
            );
        }
    }]);
    return CustomModal;
}(_react2.default.Component);

exports.default = CustomModal;
module.exports = exports['default'];