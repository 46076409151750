'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _PromotionDetail = require('./PromotionDetail.scss');

var _PromotionDetail2 = _interopRequireDefault(_PromotionDetail);

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Countdown = function (_React$Component) {
  (0, _inherits3.default)(Countdown, _React$Component);

  function Countdown(props) {
    (0, _classCallCheck3.default)(this, Countdown);

    var _this = (0, _possibleConstructorReturn3.default)(this, (Countdown.__proto__ || (0, _getPrototypeOf2.default)(Countdown)).call(this, props));

    _this.state = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0
    };
    return _this;
  }

  (0, _createClass3.default)(Countdown, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      var _this2 = this;

      this.interval = setInterval(function () {
        var date = _this2.countDown(_this2.props.date);
        date ? _this2.setState(date) : _this2.stop();
      }, 1000);
    }
  }, {
    key: 'componentWillUnmount',
    value: function componentWillUnmount() {
      if (this.interval) {
        clearInterval(this.interval);
      }
    }
  }, {
    key: 'addZeros',
    value: function addZeros(value) {
      value = String(value);
      while (value.length < 2) {
        value = '0' + value;
      }
      if (isNaN(value)) {
        value = '00';
      }
      return value;
    }
  }, {
    key: 'stop',
    value: function stop() {
      console.log('stop process of countdown');
    }
  }, {
    key: 'countDown',
    value: function countDown(endDate) {

      var cstDate = new Date(endDate);
      var now = new Date();

      var diff = (Date.parse(cstDate) - Date.parse(now)) / 1000;

      if (diff <= 0) return false;

      var timeLeft = {
        years: 0,
        days: 0,
        hours: 0,
        min: 0,
        sec: 0
      };

      // calculate time difference between now and expected date
      if (diff >= 365.25 * 86400) {
        // 365.25 * 24 * 60 * 60
        timeLeft.years = Math.floor(diff / (365.25 * 86400));
        diff -= timeLeft.years * 365.25 * 86400;
      }
      if (diff >= 86400) {
        // 24 * 60 * 60
        timeLeft.days = Math.floor(diff / 86400);
        diff -= timeLeft.days * 86400;
      }
      if (diff >= 3600) {
        // 60 * 60
        timeLeft.hours = Math.floor(diff / 3600);
        diff -= timeLeft.hours * 3600;
      }
      if (diff >= 60) {
        timeLeft.min = Math.floor(diff / 60);
        diff -= timeLeft.min * 60;
      }
      timeLeft.sec = diff;

      return timeLeft;
    }
  }, {
    key: 'render',
    value: function render() {
      var countDown = this.state;

      return _react2.default.createElement(
        'div',
        { className: _PromotionDetail2.default.countdownWrapper },
        _react2.default.createElement(
          'span',
          { className: _PromotionDetail2.default.countdownItem },
          _react2.default.createElement(
            'strong',
            null,
            this.addZeros(countDown.days)
          ),
          _react2.default.createElement(
            'span',
            null,
            countDown.days === 1 ? '' + (0, _translations2.default)('DAY') : '' + (0, _translations2.default)('DAYS')
          )
        ),
        _react2.default.createElement(
          'span',
          { className: _PromotionDetail2.default.countdownItem },
          _react2.default.createElement(
            'strong',
            null,
            this.addZeros(countDown.hours)
          ),
          _react2.default.createElement(
            'span',
            null,
            '' + (0, _translations2.default)('HOURS')
          )
        ),
        _react2.default.createElement(
          'span',
          { className: _PromotionDetail2.default.countdownItem },
          _react2.default.createElement(
            'strong',
            null,
            this.addZeros(countDown.min)
          ),
          _react2.default.createElement(
            'span',
            null,
            '' + (0, _translations2.default)('MINUTES')
          )
        ),
        _react2.default.createElement(
          'span',
          { className: _PromotionDetail2.default.countdownItem },
          _react2.default.createElement(
            'strong',
            null,
            this.addZeros(countDown.sec)
          ),
          _react2.default.createElement(
            'span',
            null,
            '' + (0, _translations2.default)('SECONDS')
          )
        )
      );
    }
  }]);
  return Countdown;
}(_react2.default.Component);

exports.default = Countdown;
module.exports = exports['default'];