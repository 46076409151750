'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = undefined;

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Modal = require('./Modal.scss');

var _Modal2 = _interopRequireDefault(_Modal);

var _components = require('components');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var md = {
    build: require('react-icons/lib/md/clear'),
    build2: require('react-icons/lib/md/close')
};

var Modal = function (_React$Component) {
    (0, _inherits3.default)(Modal, _React$Component);

    function Modal(props) {
        (0, _classCallCheck3.default)(this, Modal);

        var _this = (0, _possibleConstructorReturn3.default)(this, (Modal.__proto__ || (0, _getPrototypeOf2.default)(Modal)).call(this, props));

        _this.getDataFromChildren = function (value) {
            _this.setState({ data: value });
        };

        _this.save = function () {
            _this.props.modifyDataTemplate(_this.state.data);
            _this.forceUpdate();
            if (_this.state.data !== null) {

                _this.props.close();
                _this.forceUpdate();
            }
        };

        _this.state = {
            data: null
        };
        return _this;
    }

    (0, _createClass3.default)(Modal, [{
        key: 'componentDidMount',
        value: function componentDidMount() {}
    }, {
        key: 'render',
        value: function render() {
            var _this2 = this;

            var children = _react2.default.Children.map(this.props.children, function (child) {
                if (child) {
                    return _react2.default.cloneElement(child, {
                        handleData: _this2.getDataFromChildren,
                        preservData: _this2.props.preservData,
                        propertyEdit: _this2.props.propertyEdit
                    });
                }
            });
            var renderTitle = void 0;

            switch (this.props.propertyEdit) {

                case 'title':
                    renderTitle = _react2.default.createElement(
                        'span',
                        null,
                        'Agregar texto'
                    );
                    break;

                case 'content':
                    renderTitle = _react2.default.createElement(
                        'span',
                        null,
                        'Agregar texto'
                    );
                    break;

                case 'contentFooter':
                    renderTitle = _react2.default.createElement(
                        'span',
                        null,
                        'Agregar texto'
                    );
                    break;

                case 'bgHeader':
                    renderTitle = _react2.default.createElement(
                        'span',
                        null,
                        'Agregar color'
                    );
                    break;

                case 'bgFooter':
                    renderTitle = _react2.default.createElement(
                        'span',
                        null,
                        'Agregar color'
                    );
                    break;

                case 'logo':
                    renderTitle = _react2.default.createElement(
                        'span',
                        null,
                        'Agregar logo'
                    );
                    break;
            }

            return _react2.default.createElement(
                'div',
                { className: _Modal2.default.container },
                _react2.default.createElement(
                    'div',
                    { className: _Modal2.default.actionButtons },
                    _react2.default.createElement(
                        'div',
                        { className: _Modal2.default.headerTitle },
                        renderTitle
                    ),
                    _react2.default.createElement(
                        'div',
                        { className: _Modal2.default.close },
                        _react2.default.createElement(md.build, { size: 45, onClick: function onClick() {
                                return _this2.props.close();
                            } })
                    )
                ),
                _react2.default.createElement(
                    'div',
                    { className: _Modal2.default.content },
                    children,
                    _react2.default.createElement('div', null)
                ),
                _react2.default.createElement(
                    'div',
                    { className: _Modal2.default.actionButtons },
                    _react2.default.createElement(_components.Button, { className: _Modal2.default.left, color: 'lightGreen', name: 'Cancelar',
                        onClick: function onClick() {
                            _this2.props.close();
                        } }),
                    _react2.default.createElement(_components.Button, { className: _Modal2.default.right, color: 'darkGreen', name: 'Guardar',
                        onClick: function onClick() {
                            return _this2.save();
                        } })
                )
            );
        }
    }]);
    return Modal;
}(_react2.default.Component);

exports.default = Modal;
module.exports = exports['default'];