'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _dec2, _class, _class2, _temp;
// import { connect } from 'react-redux'
// import { Nav, ReportList, Report } from 'components'


var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactDatetime = require('react-datetime');

var _reactDatetime2 = _interopRequireDefault(_reactDatetime);

var _reactRedux = require('react-redux');

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _reduxConnect = require('redux-connect');

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _Report = require('./Report.scss');

var _Report2 = _interopRequireDefault(_Report);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var AdminFilter = (_dec = (0, _reduxConnect.asyncConnect)([{
  key: 'account',
  promise: function promise(_ref) {
    var dispatch = _ref.store.dispatch;

    return dispatch(getAccount());
  }
}]), _dec2 = (0, _reactRedux.connect)(function (state) {
  return {
    account: state.account.data,
    status: state.status.data
  };
}), _dec(_class = _dec2(_class = (_temp = _class2 = function (_React$Component) {
  (0, _inherits3.default)(AdminFilter, _React$Component);

  function AdminFilter() {
    (0, _classCallCheck3.default)(this, AdminFilter);
    return (0, _possibleConstructorReturn3.default)(this, (AdminFilter.__proto__ || (0, _getPrototypeOf2.default)(AdminFilter)).apply(this, arguments));
  }

  (0, _createClass3.default)(AdminFilter, [{
    key: 'handleDateChange',
    value: function handleDateChange(startDate, endDate) {
      this.props.onDateChange(startDate, endDate);
    }
  }, {
    key: 'render',
    value: function render() {
      var _props = this.props,
          startDate = _props.startDate,
          endDate = _props.endDate;

      var startDate1 = (0, _moment2.default)(this.props.status.current_package.valid_since);
      var endDate2 = (0, _moment2.default)(this.props.status.current_package.valid_thru);
      return _react2.default.createElement(
        'div',
        { className: _Report2.default.filter },
        _react2.default.createElement(
          'h1',
          { className: _Report2.default.title },
          (0, _translations2.default)('APPLY FILTER')
        ),
        _react2.default.createElement(
          'div',
          { className: _Report2.default.datePicker },
          _react2.default.createElement(
            'div',
            { className: _Report2.default.col },
            _react2.default.createElement(
              'a',
              { className: _Report2.default.item, onClick: this.handleDateChange.bind(this, (0, _moment2.default)(startDate1).startOf('day'), (0, _moment2.default)(endDate2).endOf('day')) },
              (0, _translations2.default)('LAST DAY')
            )
          ),
          _react2.default.createElement(
            'div',
            { className: _Report2.default.col },
            _react2.default.createElement(
              'a',
              { className: _Report2.default.item, onClick: this.handleDateChange.bind(this, (0, _moment2.default)(startDate1).subtract(1, 'month').startOf('day'), (0, _moment2.default)(endDate2).subtract(1, 'month').endOf('day')) },
              (0, _translations2.default)('LAST WEEK')
            )
          ),
          _react2.default.createElement(
            'div',
            { className: _Report2.default.col },
            _react2.default.createElement(
              'a',
              { className: _Report2.default.item, onClick: this.handleDateChange.bind(this, (0, _moment2.default)(startDate1).subtract(2, 'month').startOf('day'), (0, _moment2.default)(endDate2).subtract(2, 'month').endOf('day')) },
              (0, _translations2.default)('LAST MONTH')
            )
          ),
          _react2.default.createElement(
            'div',
            { className: _Report2.default.col },
            _react2.default.createElement(
              'a',
              { className: _Report2.default.item, onClick: this.handleDateChange.bind(this, (0, _moment2.default)(startDate1).subtract(3, 'month').startOf('day'), (0, _moment2.default)(endDate2).subtract(3, 'month').endOf('day')) },
              (0, _translations2.default)('LAST YEAR')
            )
          )
        )
      );
    }
  }]);
  return AdminFilter;
}(_react2.default.Component), _class2.propTypes = {
  startDate: _propTypes2.default.object.isRequired,
  endDate: _propTypes2.default.object.isRequired,
  onDateChange: _propTypes2.default.func.isRequired,
  account: _propTypes2.default.object.isRequired,
  status: _propTypes2.default.object.isRequired
}, _temp)) || _class) || _class);
exports.default = AdminFilter;
module.exports = exports['default'];