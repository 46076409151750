'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PolicyEdit = exports.default = undefined;

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _extends3 = require('babel-runtime/helpers/extends');

var _extends4 = _interopRequireDefault(_extends3);

var _getOwnPropertyDescriptor = require('babel-runtime/core-js/object/get-own-property-descriptor');

var _getOwnPropertyDescriptor2 = _interopRequireDefault(_getOwnPropertyDescriptor);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _class, _desc, _value, _class2, _dec2, _class3;

var _themeEnabled = require('helpers/themeEnabled');

var _Allow = require('../Allow/Allow');

var _Allow2 = _interopRequireDefault(_Allow);

var _components = require('components');

var _reactHelmet = require('react-helmet');

var _reactHelmet2 = _interopRequireDefault(_reactHelmet);

var _reactRouter = require('react-router');

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _reduxConnect = require('redux-connect');

var _coreDecorators = require('core-decorators');

var _reactRedux = require('react-redux');

var _policy = require('redux/modules/policy');

var _status = require('redux/modules/status');

var _reachHistory = require('../../helpers/reachHistory');

var _sessionPermissions = require('../../helpers/sessionPermissions');

var _Reseller = require('./Reseller.scss');

var _Reseller2 = _interopRequireDefault(_Reseller);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
  var desc = {};
  Object['ke' + 'ys'](descriptor).forEach(function (key) {
    desc[key] = descriptor[key];
  });
  desc.enumerable = !!desc.enumerable;
  desc.configurable = !!desc.configurable;

  if ('value' in desc || desc.initializer) {
    desc.writable = true;
  }

  desc = decorators.slice().reverse().reduce(function (desc, decorator) {
    return decorator(target, property, desc) || desc;
  }, desc);

  if (context && desc.initializer !== void 0) {
    desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
    desc.initializer = undefined;
  }

  if (desc.initializer === void 0) {
    Object['define' + 'Property'](target, property, desc);
    desc = null;
  }

  return desc;
}

var md = {
  edit: require('react-icons/lib/md/mode-edit'),
  delete: require('react-icons/lib/md/delete'),
  arrowBack: require('react-icons/lib/md/arrow-back')
};

var Policy = (_dec = (0, _reactRedux.connect)(null, {
  deletePolicy: _policy.deletePolicy,
  pushState: _reachHistory.push
}), _dec(_class = (_class2 = function (_React$Component) {
  (0, _inherits3.default)(Policy, _React$Component);

  function Policy(props) {
    (0, _classCallCheck3.default)(this, Policy);

    var _this = (0, _possibleConstructorReturn3.default)(this, (Policy.__proto__ || (0, _getPrototypeOf2.default)(Policy)).call(this, props));

    _this.policy = _this.props.location.state.policy;
    return _this;
  }

  (0, _createClass3.default)(Policy, [{
    key: 'renderEditNav',
    value: function renderEditNav() {
      return _react2.default.createElement(
        'div',
        { className: _Reseller2.default.modalnav },
        _react2.default.createElement(
          _reactRouter.Link,
          { to: {
              pathname: '/reseller/policy/edit',
              state: { modal: true, returnTo: '/reseller/', policy: this.policy }
            }, className: _Reseller2.default.leftbutton },
          _react2.default.createElement(md.edit, { size: 30 }),
          _react2.default.createElement(
            'span',
            null,
            (0, _translations2.default)('EDIT POLICY')
          )
        ),
        _react2.default.createElement(
          'h1',
          { className: _Reseller2.default.centertitle },
          (0, _translations2.default)('POLICY SUMMARY')
        ),
        _react2.default.createElement(
          'a',
          { className: _Reseller2.default.rightbutton },
          _react2.default.createElement(md.delete, { size: 30 }),
          _react2.default.createElement(
            'span',
            null,
            (0, _translations2.default)('DELETE POLICY')
          )
        )
      );
    }
  }, {
    key: 'renderCreateNav',
    value: function renderCreateNav() {
      return _react2.default.createElement(
        'div',
        { className: _Reseller2.default.modalnav },
        _react2.default.createElement(
          'h1',
          { className: _Reseller2.default.centertitle },
          (0, _translations2.default)('POLICY SUMMARY')
        )
      );
    }
  }, {
    key: 'getPolicyDescription',
    value: function getPolicyDescription() {
      var description = "Enviar un máximo de ";
      var time_frame = this.policy.policy.time_frame;
      var max_number = this.policy.policy.max_number;

      max_number == 1 ? description += max_number + ' mensaje ' : description += max_number + ' mensajes ';

      description += "por cada período de ";

      switch (time_frame) {
        case 24:
          description += "1 día";break;
        case 168:
          description += "1 semana";break;
        case 720:
          description += "1 mes";break;
        default:
          description += time_frame + ' horas';break;
      }

      var description_p = _react2.default.createElement(
        'p',
        null,
        ' ',
        description,
        ' '
      );
      return description_p;
    }
  }, {
    key: 'delete',
    value: function _delete() {
      var _this2 = this;

      return this.props.deletePolicy(this.policy._id).then(function (result) {
        _this2.props.pushState("/reseller/policies");
      });
    }
  }, {
    key: 'render',
    value: function render() {
      var policy = this.policy;

      return _react2.default.createElement(
        'div',
        null,
        _react2.default.createElement(
          'div',
          { className: _Reseller2.default.modalnav },
          _react2.default.createElement(_reactHelmet2.default, { title: 'Pol\xEDticas' }),
          _react2.default.createElement(
            'div',
            { className: _Reseller2.default.back },
            _react2.default.createElement(
              'div',
              { className: _Reseller2.default.icon, onClick: this.props.router.goBack },
              _react2.default.createElement(md.arrowBack, { size: 20, color: '#FFF' })
            )
          ),
          _react2.default.createElement(
            'h1',
            { className: _Reseller2.default.centertitle },
            (0, _translations2.default)('POLICY SUMMARY')
          ),
          _react2.default.createElement(
            _Allow2.default,
            { permissions: ['reseller:policies:antispam:edit'] },
            _react2.default.createElement(
              _reactRouter.Link,
              { to: {
                  pathname: '/reseller/policy/edit',
                  state: { modal: true, returnTo: '/reseller/', policy: this.policy }
                }, className: _Reseller2.default.linkholder },
              _react2.default.createElement(md.edit, { size: 20 }),
              _react2.default.createElement(
                'span',
                null,
                (0, _translations2.default)('EDIT POLICY')
              )
            )
          ),
          _react2.default.createElement(
            _Allow2.default,
            { permissions: ['reseller:policies:antispam:delete'] },
            _react2.default.createElement(
              _reactRouter.Link,
              { className: _Reseller2.default.linkholder, onClick: this.delete },
              _react2.default.createElement(md.delete, { size: 20 }),
              _react2.default.createElement(
                'span',
                null,
                (0, _translations2.default)('DELETE POLICY')
              )
            )
          )
        ),
        _react2.default.createElement(
          'div',
          { className: _Reseller2.default.body },
          _react2.default.createElement(
            'h3',
            null,
            (0, _translations2.default)('POLICY NAME')
          ),
          _react2.default.createElement(
            'p',
            null,
            policy.policy_name
          ),
          _react2.default.createElement(
            'h3',
            null,
            (0, _translations2.default)('POLICY TYPE')
          ),
          _react2.default.createElement(
            'p',
            null,
            policy.policy_type
          ),
          _react2.default.createElement(
            'h3',
            null,
            (0, _translations2.default)('POLICY')
          ),
          this.getPolicyDescription()
        )
      );
    }
  }]);
  return Policy;
}(_react2.default.Component), (_applyDecoratedDescriptor(_class2.prototype, 'delete', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'delete'), _class2.prototype)), _class2)) || _class);
exports.default = Policy;
var PolicyEdit = exports.PolicyEdit = (_dec2 = (0, _reactRedux.connect)(null, {
  postPolicy: _policy.postPolicy,
  putPolicy: _policy.putPolicy,
  pushState: _reachHistory.push
}), _dec2(_class3 = function (_React$Component2) {
  (0, _inherits3.default)(PolicyEdit, _React$Component2);

  function PolicyEdit(props) {
    (0, _classCallCheck3.default)(this, PolicyEdit);

    var _this3 = (0, _possibleConstructorReturn3.default)(this, (PolicyEdit.__proto__ || (0, _getPrototypeOf2.default)(PolicyEdit)).call(this, props));

    var id = null;
    if (_this3.props.location.state.policy) {
      var state_policy = _this3.props.location.state.policy;
      id = state_policy._id;
      _this3.policy = {
        "policy_name": state_policy.policy_name,
        "policy_type": state_policy.policy_type,
        "time_frame": state_policy.policy.time_frame,
        "max_number": state_policy.policy.max_number
      };
    } else {
      _this3.policy = {
        "policy_name": "",
        "policy_type": "",
        "time_frame": 0,
        "max_number": 0
      };
    }
    _this3.state = {
      id: id,
      policy: _this3.policy,
      title: ""
    };
    return _this3;
  }

  (0, _createClass3.default)(PolicyEdit, [{
    key: 'handleConfigurationChange',
    value: function handleConfigurationChange(key, event) {
      this.setState({
        policy: (0, _extends4.default)({}, this.state.policy, (0, _defineProperty3.default)({}, key, event.target.value))
      });
    }
  }, {
    key: 'save',
    value: function save() {
      var _this4 = this;

      if (this.state.id == null) {
        return this.props.postPolicy(this.state.policy).then(function (result) {
          _this4.props.pushState("/reseller/policies");
        });
      } else {
        return this.props.putPolicy(this.state.id, this.state.policy).then(function (result) {
          _this4.props.pushState("/reseller/policies");
        });
      }
    }
  }, {
    key: 'getOptionsNumbers',
    value: function getOptionsNumbers() {
      var options = [];
      for (var i = 1; i < 51; i++) {
        options.push(_react2.default.createElement(
          'option',
          { key: i, value: i },
          i,
          ' mensajes'
        ));
      }
      return options;
    }
  }, {
    key: 'getOptionsFrame',
    value: function getOptionsFrame() {
      var options = [];
      for (var i = 1; i < 24; i++) {
        options.push(_react2.default.createElement(
          'option',
          { key: i, value: i },
          i,
          ' horas'
        ));
      }
      options.push(_react2.default.createElement(
        'option',
        { key: 24, value: '24' },
        '1 d\xEDa'
      ));
      options.push(_react2.default.createElement(
        'option',
        { key: 168, value: '168' },
        '1 semana'
      ));
      options.push(_react2.default.createElement(
        'option',
        { key: 720, value: '720' },
        '1 mes'
      ));
      return options;
    }
  }, {
    key: 'componentDidMount',
    value: function componentDidMount() {
      if (this.state.id) {
        this.setState({ title: (0, _translations2.default)('POLICY EDIT') });
      } else {
        this.setState({ title: (0, _translations2.default)('POLICY CREATE') });
      }
    }
  }, {
    key: 'render',
    value: function render() {
      var policy = this.state.policy;
      var classesTheme = (0, _themeEnabled.getThemeEnabledStyle)(_Reseller2.default);
      var objAsset = (0, _themeEnabled.getAssetStyle)();
      var illustrationTheme = objAsset.form;
      return _react2.default.createElement(
        'div',
        { className: classesTheme },
        _react2.default.createElement(
          'div',
          { className: _Reseller2.default.main },
          !this.state.id ? this.renderCreateNav() : this.renderEditNav(),
          _react2.default.createElement(
            'div',
            { className: _Reseller2.default.container },
            _react2.default.createElement(
              'div',
              { className: _Reseller2.default.right },
              _react2.default.createElement(
                'div',
                { className: _Reseller2.default.wrapper },
                _react2.default.createElement(
                  'div',
                  { className: _Reseller2.default.body },
                  _react2.default.createElement(
                    'h3',
                    null,
                    (0, _translations2.default)('POLICY NAME')
                  ),
                  _react2.default.createElement(
                    'p',
                    null,
                    _react2.default.createElement('input', { type: 'text', value: policy.policy_name, onChange: this.handleConfigurationChange.bind(this, "policy_name") })
                  ),
                  _react2.default.createElement(
                    'h3',
                    null,
                    (0, _translations2.default)('POLICY TYPE')
                  ),
                  _react2.default.createElement(
                    'p',
                    null,
                    _react2.default.createElement(
                      'select',
                      { value: policy.policy_type, onChange: this.handleConfigurationChange.bind(this, "policy_type") },
                      _react2.default.createElement(
                        'option',
                        { value: 'MAX NUMBER' },
                        'L\xEDmite de mensajes por contacto'
                      )
                    )
                  ),
                  _react2.default.createElement(
                    'h3',
                    null,
                    (0, _translations2.default)('POLICY')
                  ),
                  _react2.default.createElement(
                    'p',
                    null,
                    'Enviar un m\xE1ximo de',
                    _react2.default.createElement(
                      'select',
                      { value: policy.max_number, onChange: this.handleConfigurationChange.bind(this, "max_number") },
                      this.getOptionsNumbers()
                    ),
                    'por cada per\xEDodo de',
                    _react2.default.createElement(
                      'select',
                      { value: policy.time_frame, onChange: this.handleConfigurationChange.bind(this, "time_frame") },
                      this.getOptionsFrame()
                    )
                  )
                ),
                _react2.default.createElement(
                  'div',
                  { className: _Reseller2.default.body },
                  _react2.default.createElement('br', null),
                  _react2.default.createElement('br', null),
                  _react2.default.createElement(_components.Button, { onClick: this.save.bind(this), name: (0, _translations2.default)('POLICY SAVE'), className: _Reseller2.default.button })
                )
              )
            ),
            _react2.default.createElement('img', { src: illustrationTheme, className: _Reseller2.default.image })
          )
        )
      );
    }
  }, {
    key: 'renderEditNav',
    value: function renderEditNav() {
      return _react2.default.createElement(
        'div',
        { className: _Reseller2.default.modalnav },
        _react2.default.createElement(
          'div',
          { className: _Reseller2.default.icon, onClick: this.props.router.goBack },
          _react2.default.createElement(md.arrowBack, { size: 20, color: '#FFF' })
        ),
        _react2.default.createElement(
          'h1',
          { className: _Reseller2.default.centertitle },
          this.state.title
        )
      );
    }
  }, {
    key: 'renderCreateNav',
    value: function renderCreateNav() {
      return _react2.default.createElement(
        'div',
        { className: _Reseller2.default.modalnav },
        _react2.default.createElement(
          'div',
          { className: _Reseller2.default.icon, onClick: this.props.router.goBack },
          _react2.default.createElement(md.arrowBack, { size: 20 })
        ),
        _react2.default.createElement(
          'h1',
          { className: _Reseller2.default.centertitle },
          this.state.title
        )
      );
    }
  }]);
  return PolicyEdit;
}(_react2.default.Component)) || _class3);